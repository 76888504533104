//  时时彩
export default {
  plays: {
    0: {rule_id: 17000, area_id: 17000, play_name: 'DWD', play_cname: '定位胆', unit_price: 1, note_tag: 'count'},
    1: {
      rule_id: 17001,
      area_id: 17001,
      play_name: 'Q2ZXFS',
      play_cname: '前二直选复式',
      unit_price: 1,
      note_tag: 'sscDirect'
    },
    2: {
      rule_id: 17002,
      area_id: 17002,
      play_name: 'Q2ZXHZ',
      play_cname: '前二直选和值',
      unit_price: 1,
      note_tag: 'ssc2DirectSum'
    },
    3: {
      rule_id: 17003,
      area_id: 17003,
      play_name: 'Q2ZXKD',
      play_cname: '前二直选跨度',
      unit_price: 1,
      note_tag: 'ssc2Cross'
    },
    4: {
      rule_id: 17004,
      area_id: 17004,
      play_name: 'H2ZXFS',
      play_cname: '后二直选复式',
      unit_price: 1,
      note_tag: 'sscDirect'
    },
    5: {
      rule_id: 17005,
      area_id: 17005,
      play_name: 'H2ZXHZ',
      play_cname: '后二直选和值',
      unit_price: 1,
      note_tag: 'ssc2DirectSum'
    },
    6: {
      rule_id: 17006,
      area_id: 17006,
      play_name: 'H2ZXKD',
      play_cname: '后二直选跨度',
      unit_price: 1,
      note_tag: 'ssc2Cross'
    },
    7: {rule_id: 17007, area_id: 17007, play_name: 'Q2ZXFS', play_cname: '前二组选复式', unit_price: 1, note_tag: 'sscComb2'},
    8: {rule_id: 17008, area_id: 17008, play_name: 'Q2ZXHZ', play_cname: '前二组选和值', unit_price: 1, note_tag: 'ssc2Sum'},
    9: {
      rule_id: 17009,
      area_id: 17009,
      play_name: 'Q2ZXBD',
      play_cname: '前二组选包胆',
      unit_price: 1,
      note_tag: 'ssc2CombBaoDan'
    },
    10: {
      rule_id: 17010,
      area_id: 17010,
      play_name: 'H2ZXFS',
      play_cname: '后二组选复式',
      unit_price: 1,
      note_tag: 'sscComb2'
    },
    11: {rule_id: 17011, area_id: 17011, play_name: 'H2ZXHZ', play_cname: '后二组选和值', unit_price: 1, note_tag: 'ssc2Sum'},
    12: {
      rule_id: 17012,
      area_id: 17012,
      play_name: 'H2ZXBD',
      play_cname: '后二组选包胆',
      unit_price: 1,
      note_tag: 'ssc2CombBaoDan'
    },

    13: {
      rule_id: 17013,
      area_id: 17013,
      play_name: 'Q3ZXFS',
      play_cname: '前三直选复式',
      unit_price: 1,
      note_tag: 'sscDirect'
    },
    14: {
      rule_id: 17014,
      area_id: 17014,
      play_name: 'Q3ZXHZ',
      play_cname: '前三直选和值',
      unit_price: 1,
      note_tag: 'ssc3DirectSum'
    },
    15: {
      rule_id: 17015,
      area_id: 17015,
      play_name: 'Q3ZXKD',
      play_cname: '前三直选跨度',
      unit_price: 1,
      note_tag: 'ssc3Cross'
    },
    16: {
      rule_id: 17016,
      area_id: 17016,
      play_name: 'Q3ZXZH',
      play_cname: '前三直选组合',
      unit_price: 1,
      note_tag: 'ssc3DicectComb'
    },
    17: {
      rule_id: 17017,
      area_id: 17017,
      play_name: 'Q3ZXZ3',
      play_cname: '前三组选组三',
      unit_price: 1,
      note_tag: 'ssc3Comb3'
    },
    18: {
      rule_id: 17018,
      area_id: 17018,
      play_name: 'Q3ZXZ6',
      play_cname: '前三组选组六',
      unit_price: 1,
      note_tag: 'sscComb3'
    },
    19: {
      rule_id: 17019,
      area_id: 17019,
      play_name: 'Q3ZXZXHZ',
      play_cname: '前三组选和值',
      unit_price: 1,
      note_tag: 'ssc3Sum'
    },
    20: {
      rule_id: 17020,
      area_id: 17020,
      play_name: 'Q3ZXZXBD',
      play_cname: '前三组选包胆',
      unit_price: 1,
      note_tag: 'ssc3CombBaoDan'
    },
    21: {
      rule_id: 17021,
      area_id: 17021,
      play_name: 'Q3QTHZWS',
      play_cname: '前三其他和值尾数',
      unit_price: 1,
      note_tag: 'count'
    },
    22: {
      rule_id: 17022,
      area_id: 17022,
      play_name: 'Q3QTTSHM',
      play_cname: '前三其他特殊号码',
      unit_price: 1,
      note_tag: 'ssc3CombSpecial'
    },

    23: {
      rule_id: 17023,
      area_id: 17023,
      play_name: 'Z3ZXFS',
      play_cname: '中三直选复式',
      unit_price: 1,
      note_tag: 'sscDirect'
    },
    24: {
      rule_id: 17024,
      area_id: 17024,
      play_name: 'Z3ZXHZ',
      play_cname: '中三直选和值',
      unit_price: 1,
      note_tag: 'ssc3DirectSum'
    },
    25: {
      rule_id: 17025,
      area_id: 17025,
      play_name: 'Z3ZXKD',
      play_cname: '中三直选跨度',
      unit_price: 1,
      note_tag: 'ssc3Cross'
    },
    26: {
      rule_id: 17026,
      area_id: 17026,
      play_name: 'Z3ZXZH',
      play_cname: '中三直选组合',
      unit_price: 1,
      note_tag: 'ssc3DicectComb'
    },
    27: {
      rule_id: 17027,
      area_id: 17027,
      play_name: 'Z3ZXZ3',
      play_cname: '中三组选组三',
      unit_price: 1,
      note_tag: 'ssc3Comb3'
    },
    28: {
      rule_id: 17028,
      area_id: 17028,
      play_name: 'Z3ZXZ6',
      play_cname: '中三组选组六',
      unit_price: 1,
      note_tag: 'sscComb3'
    },
    29: {
      rule_id: 17029,
      area_id: 17029,
      play_name: 'Z3ZXZXHZ',
      play_cname: '中三组选和值',
      unit_price: 1,
      note_tag: 'ssc3Sum'
    },
    30: {
      rule_id: 17030,
      area_id: 17030,
      play_name: 'Z3ZXZXBD',
      play_cname: '中三组选包胆',
      unit_price: 1,
      note_tag: 'ssc3CombBaoDan'
    },
    31: {
      rule_id: 17031,
      area_id: 17031,
      play_name: 'Z3QTHZWS',
      play_cname: '中三其他和值尾数',
      unit_price: 1,
      note_tag: 'count'
    },
    32: {
      rule_id: 17032,
      area_id: 17032,
      play_name: 'Z3QTTSHM',
      play_cname: '中三其他特殊号码',
      unit_price: 1,
      note_tag: 'ssc3CombSpecial'
    },

    33: {
      rule_id: 17033,
      area_id: 17033,
      play_name: 'H3ZXFS',
      play_cname: '后三直选复式',
      unit_price: 1,
      note_tag: 'sscDirect'
    },
    34: {
      rule_id: 17034,
      area_id: 17034,
      play_name: 'H3ZXHZ',
      play_cname: '后三直选和值',
      unit_price: 1,
      note_tag: 'ssc3DirectSum'
    },
    35: {
      rule_id: 17035,
      area_id: 17035,
      play_name: 'H3ZXKD',
      play_cname: '后三直选跨度',
      unit_price: 1,
      note_tag: 'ssc3Cross'
    },
    36: {
      rule_id: 17036,
      area_id: 17036,
      play_name: 'H3ZXZH',
      play_cname: '后三直选组合',
      unit_price: 1,
      note_tag: 'ssc3DicectComb'
    },
    37: {
      rule_id: 17037,
      area_id: 17037,
      play_name: 'H3ZXZ3',
      play_cname: '后三组选组三',
      unit_price: 1,
      note_tag: 'ssc3Comb3'
    },
    38: {
      rule_id: 17038,
      area_id: 17038,
      play_name: 'H3ZXZ6',
      play_cname: '后三组选组六',
      unit_price: 1,
      note_tag: 'sscComb3'
    },
    39: {
      rule_id: 17039,
      area_id: 17039,
      play_name: 'H3ZXZXHZ',
      play_cname: '后三组选和值',
      unit_price: 1,
      note_tag: 'ssc3Sum'
    },
    40: {
      rule_id: 17040,
      area_id: 17040,
      play_name: 'H3ZXZXBD',
      play_cname: '后三组选包胆',
      unit_price: 1,
      note_tag: 'ssc3CombBaoDan'
    },
    41: {
      rule_id: 17041,
      area_id: 17041,
      play_name: 'H3QTHZWS',
      play_cname: '后三其他和值尾数',
      unit_price: 1,
      note_tag: 'count'
    },
    42: {
      rule_id: 17042,
      area_id: 17042,
      play_name: 'H3QTTSHM',
      play_cname: '后三其他特殊号码',
      unit_price: 1,
      note_tag: 'ssc3CombSpecial'
    },

    43: {
      rule_id: 17043,
      area_id: 17043,
      play_name: 'H4ZXFS',
      play_cname: '后四直选复式',
      unit_price: 1,
      note_tag: 'sscDirect'
    },
    44: {
      rule_id: 17044,
      area_id: 17044,
      play_name: '5XZXFS',
      play_cname: '五星直选复式',
      unit_price: 1,
      note_tag: 'sscDirect'
    },

    45: {
      rule_id: 17045,
      area_id: 17045,
      play_name: 'DXDSH2',
      play_cname: '大小单双后二',
      unit_price: 1,
      note_tag: 'sscDirect'
    },
    46: {
      rule_id: 17046,
      area_id: 17046,
      play_name: 'DXDSH3',
      play_cname: '大小单双后三',
      unit_price: 1,
      note_tag: 'sscDirect'
    },
    47: {
      rule_id: 17047,
      area_id: 17047,
      play_name: 'DXDSQ2',
      play_cname: '大小单双前二',
      unit_price: 1,
      note_tag: 'sscDirect'
    },
    48: {
      rule_id: 17048,
      area_id: 17048,
      play_name: 'DXDSQ3',
      play_cname: '大小单双前三',
      unit_price: 1,
      note_tag: 'sscDirect'
    },

    49: {
      rule_id: 17049,
      area_id: 17049,
      play_name: 'Q31MBDW',
      play_cname: '前三一码不定位',
      unit_price: 1,
      note_tag: 'sscComb1'
    },
    50: {
      rule_id: 17050,
      area_id: 17050,
      play_name: 'Q32MBDW',
      play_cname: '前三二码不定位',
      unit_price: 1,
      note_tag: 'sscComb2'
    },
    51: {
      rule_id: 17051,
      area_id: 17051,
      play_name: 'H31MBDW',
      play_cname: '后三一码不定位',
      unit_price: 1,
      note_tag: 'sscComb1'
    },
    52: {
      rule_id: 17052,
      area_id: 17052,
      play_name: 'H32MBDW',
      play_cname: '后三二码不定位',
      unit_price: 1,
      note_tag: 'sscComb2'
    },
    53: {
      rule_id: 17053,
      area_id: 17053,
      play_name: 'Q41MBDW',
      play_cname: '前四一码不定位',
      unit_price: 1,
      note_tag: 'sscComb1'
    },
    54: {
      rule_id: 17054,
      area_id: 17054,
      play_name: 'Q42MBDW',
      play_cname: '前四二码不定位',
      unit_price: 1,
      note_tag: 'sscComb2'
    },
    55: {
      rule_id: 17055,
      area_id: 17055,
      play_name: 'H41MBDW',
      play_cname: '后四一码不定位',
      unit_price: 1,
      note_tag: 'sscComb1'
    },
    56: {
      rule_id: 17056,
      area_id: 17056,
      play_name: 'H42MBDW',
      play_cname: '后四二码不定位',
      unit_price: 1,
      note_tag: 'sscComb2'
    },
    57: {
      rule_id: 17057,
      area_id: 17057,
      play_name: '5X1MBDW',
      play_cname: '五星一码不定位',
      unit_price: 1,
      note_tag: 'sscComb1'
    },
    58: {
      rule_id: 17058,
      area_id: 17058,
      play_name: '5X2MBDW',
      play_cname: '五星二码不定位',
      unit_price: 1,
      note_tag: 'sscComb2'
    },
    59: {
      rule_id: 17059,
      area_id: 17059,
      play_name: '5X3MBDW',
      play_cname: '五星三码不定位',
      unit_price: 1,
      note_tag: 'sscComb3'
    },

    60: {rule_id: 17060, area_id: 17060, play_name: 'ZX4', play_cname: '组选4', unit_price: 1, note_tag: 'qianN'},
    61: {rule_id: 17061, area_id: 17061, play_name: 'ZX6', play_cname: '组选6', unit_price: 1, note_tag: 'sscComb2'},
    62: {rule_id: 17062, area_id: 17062, play_name: 'ZX12', play_cname: '组选12', unit_price: 1, note_tag: 'sscComb12'},
    63: {rule_id: 17063, area_id: 17063, play_name: 'ZX24', play_cname: '组选24', unit_price: 1, note_tag: 'sscComb4'},
    64: {rule_id: 17064, area_id: 17064, play_name: 'ZX5', play_cname: '组选5', unit_price: 1, note_tag: 'qianN'},
    65: {rule_id: 17065, area_id: 17065, play_name: 'ZX10', play_cname: '组选10', unit_price: 1, note_tag: 'qianN'},
    66: {rule_id: 17066, area_id: 17066, play_name: 'ZX20', play_cname: '组选20', unit_price: 1, note_tag: 'sscComb12'},
    67: {rule_id: 17067, area_id: 17067, play_name: 'ZX30', play_cname: '组选30', unit_price: 1, note_tag: 'sscComb21'},
    68: {rule_id: 17068, area_id: 17068, play_name: 'ZX60', play_cname: '组选60', unit_price: 1, note_tag: 'sscComb13'},
    69: {rule_id: 17069, area_id: 17069, play_name: 'ZX120', play_cname: '组选120', unit_price: 1, note_tag: 'sscComb5'},
    70: {rule_id: 17070, area_id: 17070, play_name: '1FFS', play_cname: '一帆风顺', unit_price: 1, note_tag: 'sscComb1'},
    71: {rule_id: 17071, area_id: 17071, play_name: 'HSCS', play_cname: '好事成双', unit_price: 1, note_tag: 'sscComb1'},
    72: {rule_id: 17072, area_id: 17072, play_name: '3XBX', play_cname: '三星报喜', unit_price: 1, note_tag: 'sscComb1'},
    73: {rule_id: 17073, area_id: 17073, play_name: '4JFC', play_cname: '四季发财', unit_price: 1, note_tag: 'sscComb1'},
    74: {
      rule_id: 17074,
      area_id: 17074,
      play_name: 'Q2ZXDS',
      play_cname: '前二直选单式',
      unit_price: 1,
      simplex: 1,
      filterOnly: true,
      note_tag: 'zxds2star',
      numStart: 0,
      numEnd: 9,
      numCount: 2,
      repeat: 2,
      numWidth: 1
    },
    75: {
      rule_id: 17075,
      area_id: 17075,
      play_name: 'H2ZXDS',
      play_cname: '后二直选单式',
      unit_price: 1,
      simplex: 1,
      filterOnly: true,
      note_tag: 'zxds2star',
      numStart: 0,
      numEnd: 9,
      numCount: 2,
      repeat: 2,
      numWidth: 1
    },
    76: {
      rule_id: 17076,
      area_id: 17076,
      play_name: 'Q2ZUXDS',
      play_cname: '前二组选单式',
      unit_price: 1,
      simplex: 1,
      filterSingle: true,
      note_tag: 'comb2star',
      numStart: 0,
      numEnd: 9,
      numCount: 2,
      repeat: 0,
      numWidth: 1
    },
    77: {
      rule_id: 17077,
      area_id: 17077,
      play_name: 'H2ZUXDS',
      play_cname: '后二组选单式',
      unit_price: 1,
      simplex: 1,
      filterSingle: true,
      note_tag: 'comb2star',
      numStart: 0,
      numEnd: 9,
      numCount: 2,
      repeat: 0,
      numWidth: 1
    },
    78: {
      rule_id: 17078,
      area_id: 17078,
      play_name: 'Q3ZXDS',
      play_cname: '前三直选单式',
      unit_price: 1,
      simplex: 1,
      filterOnly: true,
      note_tag: 'zxds3star',
      numStart: 0,
      numEnd: 9,
      numCount: 3,
      repeat: 3,
      numWidth: 1
    },
    79: {
      rule_id: 17079,
      area_id: 17079,
      play_name: 'Z3ZXDS',
      play_cname: '中三直选单式',
      unit_price: 1,
      simplex: 1,
      filterOnly: true,
      note_tag: 'zxds3star',
      numStart: 0,
      numEnd: 9,
      numCount: 3,
      repeat: 3,
      numWidth: 1
    },
    80: {
      rule_id: 17080,
      area_id: 17080,
      play_name: 'H3ZXDS',
      play_cname: '后三直选单式',
      unit_price: 1,
      simplex: 1,
      filterOnly: true,
      note_tag: 'zxds3star',
      numStart: 0,
      numEnd: 9,
      numCount: 3,
      repeat: 3,
      numWidth: 1
    },
    81: {
      rule_id: 17081,
      area_id: 17081,
      play_name: 'H4ZXDS',
      play_cname: '后四直选单式',
      unit_price: 1,
      simplex: 1,
      filterOnly: true,
      note_tag: 'zxds4star',
      numStart: 0,
      numEnd: 9,
      numCount: 4,
      repeat: 4,
      numWidth: 1
    },
    82: {
      rule_id: 17082,
      area_id: 17082,
      play_name: 'H5ZXDS',
      play_cname: '五星直选单式',
      unit_price: 1,
      simplex: 1,
      filterOnly: true,
      note_tag: 'zxds5star',
      numStart: 0,
      numEnd: 9,
      numCount: 5,
      repeat: 5,
      numWidth: 1
    },
    //  任二
    83: {
      rule_id: 17083,
      area_id: 17083,
      play_name: 'ZXFS',
      play_cname: '任选二直选复式',
      unit_price: 1,
      note_tag: 'random2num'
    },
    84: {
      rule_id: 17084,
      area_id: 17084,
      play_name: 'ZXDS',
      play_cname: '任选二直选单式',
      filterOnly: true,
      unit_price: 1,
      note_tag: 'random2Zxds',
      simplex: 1,
      numStart: 0,
      numEnd: 9,
      numCount: 2,
      repeat: 2,
      numWidth: 1
    },
    85: {
      rule_id: 17085,
      area_id: 17085,
      play_name: 'ZXHZ',
      play_cname: '任选二直选和值',
      unit_price: 1,
      note_tag: 'r2DirectSum'
    },
    86: {
      rule_id: 17086,
      area_id: 17086,
      play_name: 'ZUXFS',
      play_cname: '任选二组选复式',
      unit_price: 1,
      note_tag: 'r2Combnum'
    },
    87: {
      rule_id: 17087,
      area_id: 17087,
      play_name: 'ZUXDS',
      play_cname: '任选二组选单式',
      unit_price: 1,
      note_tag: 'random2Zxds',
      filterSingle: true,
      simplex: 1,
      numStart: 0,
      numEnd: 9,
      numCount: 2,
      repeat: 0,
      numWidth: 1
    },
    88: {
      rule_id: 17088,
      area_id: 17088,
      play_name: 'ZUXHZ',
      play_cname: '任选二组选和值',
      unit_price: 1,
      note_tag: 'r2ssc2Sum'
    },
    //  任三
    89: {
      rule_id: 17089,
      area_id: 17089,
      play_name: 'ZXFS',
      play_cname: '任选三直选复式',
      unit_price: 1,
      note_tag: 'random3num'
    },
    90: {
      rule_id: 17090,
      area_id: 17090,
      play_name: 'ZXDS',
      play_cname: '任选三直选单式',
      filterOnly: true,
      unit_price: 1,
      note_tag: 'random3Zxds',
      simplex: 1,
      numStart: 0,
      numEnd: 9,
      numCount: 3,
      repeat: 3,
      numWidth: 1
    },
    91: {
      rule_id: 17091,
      area_id: 17091,
      play_name: 'ZXHZ',
      play_cname: '任选三直选和值',
      unit_price: 1,
      note_tag: 'r3DirectSum'
    },
    92: {rule_id: 17092, area_id: 17092, play_name: 'ZUSFS', play_cname: '任选三组三复式', unit_price: 1, note_tag: 'r3Comb3'},
    93: {
      rule_id: 17093,
      area_id: 17093,
      play_name: 'ZUSDS',
      play_cname: '任选三组三单式',
      unit_price: 1,
      note_tag: 'random3Zxds',
      r3z3ds: true,
      filterSingle: true,
      simplex: 1,
      numStart: 0,
      numEnd: 9,
      numCount: 3,
      repeat: 2,
      numWidth: 1
    },
    94: {rule_id: 17094, area_id: 17094, play_name: 'ZULFS', play_cname: '任选三组六复式', unit_price: 1, note_tag: 'r3Comb6'},
    95: {
      rule_id: 17095,
      area_id: 17095,
      play_name: 'ZULDS',
      play_cname: '任选三组六单式',
      unit_price: 1,
      note_tag: 'random3Zxds',
      filterSingle: true,
      simplex: 1,
      numStart: 0,
      numEnd: 9,
      numCount: 3,
      repeat: 0,
      numWidth: 1
    },
    96: {
      rule_id: 17096,
      area_id: 17096,
      play_name: 'ZUXHH',
      play_cname: '任选三组选混合',
      unit_price: 1,
      note_tag: 'random3Zxds',
      filterSingle: true,
      simplex: 1,
      numStart: 0,
      numEnd: 9,
      numCount: 3,
      repeat: 2,
      numWidth: 1
    },

    97: {
      rule_id: 17097,
      area_id: 17097,
      play_name: 'ZXFS',
      play_cname: '任选四直选复式',
      unit_price: 1,
      note_tag: 'random4num'
    },
    98: {
      rule_id: 17098,
      area_id: 17098,
      play_name: 'ZXDS',
      play_cname: '任选四直选单式',
      filterOnly: true,
      unit_price: 1,
      note_tag: 'random4Zxds',
      simplex: 1,
      numStart: 0,
      numEnd: 9,
      numCount: 4,
      repeat: 4,
      numWidth: 1
    },
    99: {
      rule_id: 17099,
      area_id: 17099,
      play_name: 'ZUX24',
      play_cname: '任选四组选24',
      unit_price: 1,
      note_tag: 'r4Comb24'
    },
    100: {
      rule_id: 17100,
      area_id: 17100,
      play_name: 'ZUX12',
      play_cname: '任选四组选12',
      unit_price: 1,
      note_tag: 'r4Comb12'
    },
    101: {rule_id: 17101, area_id: 17101, play_name: 'ZUX6', play_cname: '任选四组选6', unit_price: 1, note_tag: 'r4Comb2'},
    102: {rule_id: 17102, area_id: 17102, play_name: 'ZUX4', play_cname: '任选四组选4', unit_price: 1, note_tag: 'r4Comb4'},

    103: {
      rule_id: 54000,
      area_id: 54000,
      play_name: 'ZHP',
      play_cname: '整合盘',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    104: {
      rule_id: 54001,
      area_id: 54001,
      play_name: 'SZP',
      play_cname: '数字盘',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    105: {
      rule_id: 54002,
      area_id: 54002,
      play_name: 'LMP',
      play_cname: '两面盘',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    106: {
      rule_id: 54003,
      area_id: 54003,
      play_name: 'DIQ',
      play_cname: '第一球',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    107: {
      rule_id: 54004,
      area_id: 54004,
      play_name: 'D2Q',
      play_cname: '第二球',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    108: {
      rule_id: 54005,
      area_id: 54005,
      play_name: 'D3Q',
      play_cname: '第三球',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    109: {
      rule_id: 54006,
      area_id: 54006,
      play_name: 'D4Q',
      play_cname: '第四球',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    110: {
      rule_id: 54007,
      area_id: 54007,
      play_name: 'D5Q',
      play_cname: '第五球',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    }
  }
}
