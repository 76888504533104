// 本文件是10个租户所有的彩票玩法ID配置表，通过play_id找到所属彩种类型
export default {
  'LTS': {
    name: 'PC蛋蛋', // 游戏分类
    hallType: 2, // 购彩大厅type
    plays: [
      40, 41, 108, 300, 301, 302, 303, 304, 305, 306, 307, 308, 387, 389, 1400, 1401
    ] // 游戏分类包含play_id
  },
  'SC': {
    name: '北京赛车',
    hallType: 3,
    plays: [
      34, 57, 93, 94, 95, 96, 97, 98, 109, 113, 114, 115, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
      141, 142, 143, 144, 145, 146, 150, 151, 152, 156, 157, 158, 162, 163, 164, 168, 169, 170, 174, 175, 176, 180, 181, 182, 186, 187, 188, 192, 193, 194, 198, 199,
      260, 261, 262, 263, 264, 265, 266, 267, 268, 310, 320, 321, 322, 323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 385, 552, 562, 755,
      765, 776, 786
    ]
  },
  'SSC': {
    name: '时时彩',
    hallType: 4,
    plays: [
      17, 39, 43, 54, 55, 56, 78, 111, 112, 116, 117, 118, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291,
      292, 293, 294, 295, 296, 384, 625, 626, 627, 628, 629, 630, 631, 632, 633, 634, 635, 616, 617, 618, 619, 620, 621, 622, 623, 624
    ]
  },
  '115': {
    name: '11选5',
    hallType: 5,
    plays: [
      22, 27, 52, 53, 467, 573, 574, 575, 576, 577, 578, 579, 580, 581, 582, 583, 584, 585, 586, 587, 588, 589, 590, 591, 592, 593, 594, 595, 596, 597, 598, 599, 600, 601,
      602, 603, 604, 605, 606, 607, 608, 609, 610, 611, 612, 613, 614
    ]
  },
  'LHC': {
    name: '六合彩',
    hallType: 6,
    plays: [
      33, 42, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365, 366, 367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 464, 465, 466,
    ]
  },
  'K3': {
    name: '快3',
    hallType: 7,
    plays: [
      23, 37, 44, 45, 46, 47, 48, 49, 50, 51, 105, 106, 107, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250,
      251, 252, 253, 254, 255, 256, 424, 425, 426, 427, 428, 429, 430, 431, 432, 433, 434, 435, 436, 437, 438, 439, 440, 441, 442, 443, 444, 445, 446, 447, 448, 449,
      450, 451, 452, 453, 454, 455, 456, 457, 458, 459, 460, 461, 462, 463, 470, 471, 472, 473, 474, 475, 476, 477, 478, 479, 480, 481, 482, 483, 484, 485, 486, 487,
      488, 489, 715, 725, 735
    ]
  },
  'FT': {
    name: '幸运飞艇',
    hallType: 8,
    plays: [
      58, 83, 99, 100, 101, 102, 103, 104, 110, 153, 154, 155, 159, 160, 161, 165, 166, 167, 171, 172, 173, 177, 178, 179, 183, 184, 185, 189, 190, 191,
      195, 196, 197, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226,
      311, 312, 313, 743, 796, 806, 816
    ]
  },
  'JW': {
    name: '境外彩',
    hallType: 10,
    plays: [
      384, 385, 389, 387
    ]
  },
  'SAMEID': {
    name: '官方信用同ID集合',
    plays: [
      116, 117, 118, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292, 293, 294, 295, 296,
      99, 100, 101, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226,
      105, 106, 107, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256,
      635, 616, 617, 618, 619, 620, 621, 622, 623, 624, 625, 626, 627, 628, 629, 630, 631, 632, 633, 634
    ]
  },
}
