export default class helperKGQP {
  static QPGames = {
    2: '通比牛牛',
    3: '抢庄牛牛',
    4: '经典牛牛',
    5: '炸金花',
    6: '二八杠',
    7: '三公',
    8: '经典21点',
    9: '德州扑克',
    10: '百家乐',
    11: '牌九',
    12: '捕鱼',
    13: '梭哈',
    14: '十三水',
    16: '阿三猜数',
    17: '百人牛牛',
    18: '极速炸金花',
    19: '龙虎斗',
    20: '抢庄炸金花',
    21: '明牌炸金花',
    22: '百人骰宝',
    23: '抢红包',
    24: '红黑大战',
    25: '疯狂牛牛',
    26: '抢庄21点',
    27: '奔驰宝马',
    29: '森林舞会',
    30: '幸运五张',
    31: '疯狂马戏团',
    32: '飞禽走兽',
    33: '大话骰',
    34: '一元夺宝',
    35: '百人二八杠',
    36: '百人扎金花',
    37: '斗地主',
    38: '二人麻将',
    39: '红中麻将',
    40: '血战到底',
    41: '跑得快',
    43: '捕鱼大亨',
    1001: '寳石世界',
    1002: '戰棋',
    1003: '招財福袋',
    1004: '鬼王送財',
    1005: '金玉滿堂',
    1006: '豆腐屋奈奈致命诱惑',
    1007: '明日花潮吹升天',
    1008: '三上悠亚海滩甜心',
    1009: '神之乳Rion',
    1010: '旬果的淫乱教室',
    1011: '火热777',
    1012: '海盗传奇',
    1013: '我爱黑色会',
    1014: '天使萌萌哒',
    1015: '优的女仆贴身服务',
    1016: '千菜浴池旁的疯狂抽插',
    1017: '被凌辱的失学少女',
    1018: '贵妇的外遇初体验',
    1019: '发发发',
    1020: '招财福娃',
    1021: '关云长',
    1022: '不为人知の绝伦女帝',
    1023: '绝品人妻的完熟BODY',
    1024: '清纯公主的秘密',
    1025: '特训♡体欲少女',
    1026: '糖果传奇',
    1027: '蹦迪',
    1028: '武則天',
    1029: '麻將胡胡胡',
    1030: '愛爾蘭精靈',
    1031: '梅杜莎',
    1032: '天神宙斯',
    1033: '草原之王',
    1034: '阿拉丁',
    1035: '財神到'
  }

  static getKGQPGameName(id) {
    let name = ''
    if (this.QPGames[id]) {
      name = this.QPGames[id]
    }
    return name
  }

  static getKGQiPaiGameTypeNames() {
    return this.QPGames
  }
}
