import _115 from './115'
import MARKSIX from './MARKSIX'
import PK10JDC from './PK10JDC'
import SSC_TXFFC from './SSC_TXFFC'
import CQLHC from './CQLHC'
import PC00 from './PC00'
import SSC from './SSC'
import XYFT from './XYFT'
import K3 from './K3'
import FC3D from './FC3D'
import PK10 from './PK10'
import SSCJDC from './SSCJDC'
import YDC from './YDC'
import XNB from './XNB'
import QKL from './QKL'

// 玩法配置策略
export default {
  '115': _115,
  MARKSIX,
  'LHCJDC': MARKSIX,
  PK10JDC,
  'XYFTJDC': PK10JDC,
  SSC_TXFFC,
  'LHC': CQLHC,
  PC00,
  SSC,
  XYFT,
  K3,
  FC3D,
  PK10,
  SSCJDC,
  YDC,
  XNB,
  QKL,
}
