import $ from 'jquery';

import network from '../../network';
import {useTenantStore} from '../tenant';
import {useGameStore} from '../game';
import {isApp, isH5} from '/@/kernel-mn/utils';
import RNEvent from '/@/kernel-mn/utils/RNEvent';
import config from '/@/kernel-mn/config';
import {useHot} from '/@/kernel-mn/stores/useHot';
import {get} from '/@/kernel-mn/utils/storage';
import {LAN_TO_NUM} from '/@/hooks/common';
import i18n from '/@/languages';

export default {
  /**
   * 获取佣金列表数据
   */
  async reqCommissionList() {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    try {
      const res = await network.request({
        cmdId: 374032,
        data: {tid: tenantInfo.value.tid}
      });
      if (res && res.type === 'success') {
        this.commissionList = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取备用网址
   */
  async reqWebsiteInfo(payload) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      chan_code: payload.chan_code || 'WEB_CHECK',
      page_start: payload.page_start,
      num_per_page: payload.num_per_page,
      state: 1,
      proxy: tenantInfo.value.proxy
    };
    try {
      const res = await network.request({
        cmdId: 371137,
        data: data
      });
      if (res && res.type === 'success') {
        if (res.data) {
          if (payload.page_start < 2) {
            this.backupWebsites = [];
          }
          this.backupWebsites = this.backupWebsites.concat(Object.assign([], res.data.data)).sort((a, b) => a.weight - b.weight);
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 图片线路探测
   */
  reqImgsUrl(payload) {
    const tmpFunc = index => {
      if (index >= payload.length) {
        return;
      }
      let domain_addr = payload[index].domain_addr;
      domain_addr && !/\/$/.test(domain_addr) && (domain_addr += '/');
      $.ajax({
        url: `${domain_addr}test.txt`,
        dataType: 'jsonp',
        type: 'get',
        timeout: 3000,
        complete: req => {
          if (req.status === 200) {
            if (this.imgDomain !== domain_addr) {
              this.imgDomain = domain_addr;
            }
          } else {
            tmpFunc(index + 1);
          }
        }
      });
    };
    tmpFunc(0);
  },
  // 请求对联信息
  async reqCoupletConfig() {
    //由于此接口原生端APP未使用，固设备端字段（chan_con）只需判断H5和非H5即可 utils.isH5
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    try {
      const res = await network.request({
        cmdId: 383009,
        data: {
          tid: tenantInfo.value.tid,
          size: 10,
          chan_con: isH5() ? '2' : '1',
          language: LAN_TO_NUM(i18n.global.locale || window.localStorage.getItem('language')),
          proxy: tenantInfo.value.proxy
        }
      });
      if (res && res.type === 'success') {
        this.sideMenu = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async fetchDefaultConfig() {
    const RNEventInstance = RNEvent.getInstance();
    try {
      let domain_addr = location.origin;
      // @ts-ignore
      if (import.meta.env.DEV) {
        // @ts-ignore
        domain_addr = import.meta.env.VITE_API_DOMAIN_ADDR;
      }
      const res: any = await network.request({
        cmdId: 371130,
        data: {
          domain_addr: domain_addr,
          language: '0'
        },
        headerType: 1,
        inHeaders: {Tid: ''}
      });
      isApp() && RNEventInstance.sendNativeMsg('loaded371130');
      if (res.type === 'success') {
        this.tenantInfo = res.data;
        res.data?.listShow && this.setActiveConfiguration(res.data?.listShow); // 代理盘控制数据
        // 代理盘2的时候显示xmpp，其他的时候租户名
        if (+res.data.proxy === 2) {
          document.title = res.data.tenant.xmpp;
        } else {
          document.title = res.data.tenant.tenant_name;
        }
        this.tenantInfo.webtitle = res.data.tenant.tenant_name || '';
        const checkUrl = url => {
          if (/^http/.test(url)) {
            if (!/\/$/.test(url)) {
              return `${url}/`;
            } else {
              return url;
            }
          } else {
            return url;
          }
        };
        this.imgDomain = checkUrl(this.tenantInfo.imgs_domain && this.tenantInfo.imgs_domain[0] ? this.tenantInfo.imgs_domain[0].domain_addr : '');
        this.reqImgsUrl(this.tenantInfo.imgs_domain || []);
        this.standardTenant();
        useGameStore().reqConfigJson()
        // 设置chan_code
        config.channelCode = res.data.chan_code || '';
        this.setTheme(); // 设置主题

        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data || res);
      }
    } catch (e) {
      isApp() && RNEventInstance.sendNativeMsg(`error371130:${JSON.stringify(e || {})}`);
      return Promise.reject(e);
    }
  },
  /**
   * 请求租户注册联系人配置
   */
  async reqContactConfig() {
    if (this.sysRegAndLoginOptLoaded) return Promise.resolve(this.sysRegAndLoginOptLoaded);
    const tenantState = useTenantStore();
    try {
      const res = await network.request({
        cmdId: 371143,
        data: {
          tid: tenantState.tenantInfo.tid
        },
        headerType: 1
      });
      if (res && res.type === 'success') {
        this.setContactConfig(res.data);
        this.sysRegAndLoginOptLoaded = res.data || null; // 原始数据
        return Promise.resolve(res.data);
      } else {
        this.sysRegAndLoginOptLoaded = null;
        return Promise.reject(res);
      }
    } catch (e) {
      this.sysRegAndLoginOptLoaded = null;
      return Promise.reject(e);
    }
  },
  async reqAseq() {
    const RNEventInstance = RNEvent.getInstance();
    try {
      const res = await network.request({
        cmdId: 370433,
        data: [config.aseqId],
        headerType: 1,
        paramType: 2
      });
      if (res && res.type === 'success') {
        this.aseq = res.data;
        if (isApp() && this.aseq.magic_key) {
          RNEventInstance.sendNativeMsg('isIntercept:' + this.aseq.magic_key);
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
  // 请求租户设置功能显示与隐藏
  // pc端 	show_pc 	:0关闭    1开启
  // 移动端	show_mombile:0关闭    1开启
  // proxy: 0:主盘   1:代理盘1    2:代理盘2    3:代理盘3
  async reqActiveConfiguration(payload = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      proxy: tenantInfo.value.proxy,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 390830,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        if (res.data) {
          this.setActiveConfiguration(res.data);
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 用于请求后端接口动态翻译
   * text：['彩票', '棋牌'],数组
   * language：cn  是中文  en是英文  th是泰文  vi是越南文
   */
  async translationText(payload: any = {}) {
    // 发起请求
    let data = {
      text: payload.text,
      language: payload.language || get('language'),
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 3101343,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取虚拟货币地区汇率
   */
  async reqWithdrawCurrency() {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      option_code: 'EXCHANGE_RATE_OF_WITHDRAWAL_VIRTUAL_CURRENCY'
    };
    try {
      const res = await network.request({
        cmdId: 370730,
        data
      });
      if (res && res.type === 'success') {
        const value = Number(res.data.value);
        this.virtualCurrency = value;
        return Promise.resolve(value);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取银行卡
   */
  async reqNewBankList() {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    try {
      const res = await network.request({
        cmdId: 371234,
        data: [tenantInfo.value.tid],
        paramType: 2
      });
      if (res && res.type === 'success') {
        this.bankList = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // RN里的接口 获取版本是否更新
  async reqServers2(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    let data = {
      ...payload,
      aseq_id: config.aseqId,
      tid: tenantInfo.value.tid,
      chan_code: payload.chan_code || 'MWEB',
      clusters_id: tenantInfo.value.tenant.clusters_id,
      proxy: tenantInfo.value.proxy
    };
    try {
      const res = await network.request({
        cmdId: 371136,
        data: data,
        headerType: 1,
        inHeaders: {cust_id: ''}
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 获取vip升级禁用列表信息 */
  async reqProhibitList() {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    try {
      const res = await network.request({
        cmdId: 321539,
        data: {
          tid: tenantInfo.value.tid
        }
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 保存注册id */
  async modifyRegistrationID(payload) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    try {
      const res = await network.request({
        cmdId: 380511,
        data: {
          tid: tenantInfo.value.tid,
          user_id: userInfo.value.cust_id,
          registration_id: payload.registration_id
        }
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 查询IFSC india */
  async reqIFSC(payload = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    try {
      const res = await network.request({
        cmdId: 371632,
        data: {
          ...payload,
          tid: tenantInfo.value.tid
        }
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 维护界面数据
   * tid=3
   */
  async reqRepairData(payload = {}) {
    try {
      let domain_addr = location.origin;
      // @ts-ignore
      if (import.meta.env.DEV) {
        // @ts-ignore
        domain_addr = import.meta.env.VITE_API_DOMAIN_ADDR;
      }
      let data = {domain_addr, ...payload};
      const res = await network.request({
        cmdId: 371138,
        data,
        headerType: 1,
        inHeaders: {Tid: ''},
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  }
};
