import network from '../../network'
import {useHot} from '/@/kernel-mn/stores/useHot';
import {useUserStore} from "/@/kernel-mn/stores/user";
import {useTenantStore} from "/@/kernel-mn/stores/tenant";
import * as utils from "/@/kernel-mn/utils";
import config from "/@/kernel-mn/config";
import {isApp} from "/@/kernel-mn/utils";
import {setJSON} from "/@/kernel-mn/utils/storage";
import RNEvent from '/@/kernel-mn/utils/RNEvent';

export default {
  /**
   * 获取哈希的游戏列表
   */
  async haxiCollection(payload) {
    const {hotData} = useHot()
    const {userInfo, tenantInfo} = hotData
    const {play_collection, play_name, play_cname} = payload.data
    if (!payload.data.upper_id) {
      payload.data.upper_id = payload.data.upper_play_id
    }
    const data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      status: play_collection,
      play_name: play_name,
      play_cname: play_cname,
      msg: JSON.stringify(payload.data)
    }
    try {
      const res = await network.request({
        cmdId: 480023,
        data: data
      })
      if (res && res.type === 'success') {
        return Promise.resolve(res.data)
      } else {
        return Promise.reject(res.data)
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },
  /**
   * 获取哈希的游戏列表
   */
  async getGameList(data: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    data = {
      tid: tenantInfo.value.tid,
      ...data,
    }
    if (userInfo.value.cust_id) {
      data.cust_id = userInfo.value.cust_id
    }
    try {
      const res = await network.request({
        cmdId: 470001,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false,
      })
      if (res && res.type === 'success') {
        this.gameList = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 专属哈希项目的账号注册
   */
  async haxiRegister(data) {
    const RNEventInstance = RNEvent.getInstance()
    const userStore = useUserStore();
    const tenantState = useTenantStore()
    // 头像的地址
    const headURI = '/static/heads/head_' + utils.strPadLeft(utils.randomInteger(1, 60), 2) + '.jpg'
    if (userStore.deviceUDID) {
      data.app_key = userStore.deviceUDID
    }
    data = {
      ...data,
      acct_pwd: data.acctPwd ? utils.getp(data.acctPwd) : '',
      adm_code: '1',
      chan_code: data.chan_code || config.chanCode,
      client_id: 'react_client',
      contact_name: data.contact_name || '', // 真实会员名称
      cust_name: data.cust_name, // 会员账号
      domain_addr: window.location.host,
      email: data.email || '', // 注册邮箱
      facebook: data.facebook || '', // 注册的facebook
      gender: 0,
      grant_type: 'password',
      head: headURI,
      invite_code: data.inviteCode || data.invite_code || '', // 邀请码
      line: data.line || '', // line 邮箱
      link_addr: data.link_addr || '',
      mobile_phone: data.mobile_phone || '', // 联系电话
      nickname: data.nickname || '', // 昵称
      password: utils.getp(data.password.trim()),
      platform: utils.getBetPlatform(),
      proxy: tenantState.tenantInfo.proxy,
      qq: data.qq || '', // qq
      regist_from: utils.getHostName(tenantState.tenantInfo.domain_addr || ''),
      scope: 'read write',
      state: 1,
      tid: tenantState.tenantInfo.tid,
      whatsApp: data.whatsApp || '',
      zalo: data.zalo || '',
      verifycode: data.verifycode,
      verifycode_key: userStore.verifyCode.verifycode_key,
    }

    try {
      const res = await network.request({
        cmdId: 310129,
        data: data,
        headerType: 1,
        paramType: 1,
        isApiV2: true
      })
      if (res.type === 'success') {
        this.userInfo = res.data
        // @ts-ignore
        userStore.userInfo = res.data
        if (isApp()) {
          RNEventInstance.writeRecord('userInfo', JSON.stringify(res.data));
        } else {
          setJSON('userInfo', res.data)
        }
      } else {
        return Promise.reject(res)
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 修改钱包地址
   */
  async modifyHaxiAddress(data) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      hash_wallet_address: data.hash_wallet_address,
    }
    try {
      const res = await network.request({
        cmdId: 470004,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false,
      })
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取投注记录列表信息
   */
  async reqBetHistoryList(payload) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 470005
    // 参数:
    // tid、play_name、start_time、end_time(格式'2022-02-01 00:01:01')、
    // settle_satus(枚举字符类型 unsettle、settled)、
    // cust_code、page_start、num_per_page
    const data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_code: userInfo.value.cust_code,
      num_per_page: payload.num_per_page,
      page_start: payload.page_start,
      start_time: payload.start_time,
      end_time: payload.end_time,
      play_name: payload.play_name,
      // settle_satus: 'unsettle'
      // type_id: payload.type_id
    };
    try {
      const res = await network.request({
        // cmdId: 314502,
        cmdId: 470005,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取下级佣金
   */
  async reqLowerCommission(payload) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 470006
    // 说明：代理统计及佣金记录接口
    // 参数：
    // tid、start_time、end_time(格式'2022-02-01 00:01:01')、agent_code、page_start、num_per_page、level
    const data = {
      ...payload,
      tid: tenantInfo.value.tid,
      agent_code: userInfo.value.cust_code,
      // cust_code: userInfo.value.cust_id,
      num_per_page: payload.num_per_page,
      page_start: payload.page_start,
      start_time: payload.start_time,
      end_time: payload.end_time,
      level: payload.level
      // type_id: payload.type_id
    };
    try {
      const res = await network.request({
        cmdId: 470006,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  /**
   * 获取推广页面数据 刷单关于我们 资质介绍 机构 推广抽佣点位
   */
  async reqUpRates() {
    const userStore: any = useUserStore();
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      level: userStore.userInfo.cust_level
    }
    try {
      const res = await network.request({
        cmdId: 374040,
        data: data
      });
      if (res && res.type === 'success') {
        if (res.data) {
          const obj: any = res.data;
          obj.rebate_scale = obj.rebate_scale || ''
          const arr = obj.rebate_scale.split(',');
          this.promoteRates = {
            up_one_rate: arr[0] || "0%",
            up_two_rate: arr[1] || "0%",
            up_three_rate: arr[2] || "0%",
          }
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
}
