export const sportsIds = {
  1: '足球',
  2: '篮球',
  3: '网球',
  6: '田径',
  7: '羽毛球',
  8: '棒球',
  11: '拳击',
  13: '板球',
  15: '飞镖',
  18: '草地曲棍球',
  19: '美式足球',
  21: '高尔夫球',
  23: '手球',
  25: '冰上曲棍球',
  29: '赛车运动',
  31: '橄榄球',
  32: '帆船',
  34: '斯诺克/英式台球',
  36: '乒乓球',
  39: '虚拟足球',
  40: '排球',
  41: '水球',
  43: '虚拟篮球',
  44: '虚拟世界杯',
  45: '娱乐投注',
  46: '虚拟国家杯',
  47: '虚拟足球英国联赛',
  49: '虚拟足球西班牙友谊赛',
  51: '金融投注',
  52: '虚拟足球西班牙联赛',
  53: '虚拟足球意大利联赛'
};

export const market = {
  1: '早盘',
  2: '今日',
  3: '滚球'
};

export const oddsType = {
  1: '马来盘',
  2: '香港盘',
  3: '欧洲盘',
  4: '印尼盘'
};

export const comboSelection = {
  0: '单注',
  1: '三串四',
  2: '四串十一式',
  3: '五串二十六式',
  4: '六串五十七式',
  5: '七串一百二十式',
  6: '八串二百四十七式',
  7: '九串五百零二式',
  8: '十串一千零十三式',
  9: '两串一',
  10: '三串一',
  11: '四串一',
  12: '五串一',
  13: '六串一',
  14: '七串一',
  15: '八串一',
  16: '九串一',
  17: '十串一',
  18: '所有连串过关'
};

export const timeZones = {
  1: '-12:00',
  2: '-11:00',
  3: '-10:00',
  5: '-09:00',
  6: '-08:00',
  7: '-07:00',
  8: '-06:00',
  9: '-05:00',
  11: '-04:00',
  13: '-03:00',
  14: '-02:00',
  15: '-01:00',
  17: '+01:00',
  18: '+02:00',
  19: '+03:00',
  21: '+04:00',
  23: '+05:00',
  26: '+06:00',
  27: '+07:00',
  29: '+08:00',
  32: '+09:00',
  34: '+10:00',
  36: '+11:00',
  37: '+12:00'
};

// 请求限制 早盘 (5 分钟一次), 今日 (1 分钟一次), 滚球 (10 秒一次)
export const TIME_REQUEST_DIFF = {
  1: (5 * 60 + 14) * 1000, // 早盘
  2: 65 * 1000, // 今日
  3: 10 * 1000, // 滚球
  5: 70 * 1000, // 冠军
  market: 1 * 65 * 1000 // 相同参数1分钟只能请求一次, 本站暂时限制5分钟请求一次
};

// 体育菜单
export const GAME_MENU = [
  {label: 'm_gunqiu', key: 3},
  {label: 'm_jinri', key: 2},
  {label: 'm_zaopan', key: 1},
  {label: 'm_guanzhu', key: 4},
  {label: 'm_guanjun', key: 5}
];

// 时间转换
export const TIME_MAP = {
  // 所有体育
  '!LIVE': '进行中', // 没有滚球时间
  HT: '半场 00:00',// 休息
  // 足球
  '1H': '上半场',
  '2H': '下半场',
  // 篮球
  Q1: '第一节',
  Q2: '第二节',
  Q3: '第三节',
  Q4: '第四节',
  OT: '加时',
  // 网球
  S1: '第一盘',
  S2: '第二盘',
  S3: '第三盘',
  S4: '第四盘',
  S5: '第五盘',
  // 乒乓球
  G1: '第一局',
  G2: '第二局',
  G3: '第三局',
  G4: '第四局',
  G5: '第五局',
  // 板球
  '1INNS': '第一局',
  '2INNS': '第二局',
  S0: '加时'
};

// 中文对应英文的Map
export const CN_EN_MAP = {
  '英': 'Y',
  '美': 'M',
  '法': 'F',
  '日': 'R',
  '德': 'D',
  '意': 'Y',
  '中': 'Z',
  '南': 'N',
  '巴': 'B',
  '阿': 'A',
  '欧': 'O',
  '西': 'X',
  '女': 'N',
  '男': 'N',
  '瑞': 'R',
  '澳': 'A',
  '挪': 'N',
  '荷': 'H',
  '葡': 'P',
  '韩': 'H',
  '爱': 'A',
  '越': 'Y',
  '马': 'M',
  '新': 'X',
  '印': 'Y',
  '苏': 'S',
  '俱': 'J',
  '泰': 'T',
  '丹': 'D',
  '乌': 'W',
  '俄': 'E',
  '保': 'B',
  '冰': 'B',
  '厄': 'E',
  '哥': 'G',
  '墨': 'M',
  '威': 'W',
  '智': 'Z',
  '梦': 'M',
  '波': 'B',
  '玻': 'B',
  '罗': 'L',
  '芬': 'F',
  '奥': 'A',
  '比': 'B',
  '东': 'D',
  '孟': 'M',
  '白': 'B',
  '缅': 'M',
  '土': 'T',
  '国': 'G',
  '拉': 'L',
  '约': 'Y',
  '麒': 'Q',
  '加': 'J',
}

// 要显示的体育种类
export const GAMES = [1, 2, 3, 7, 36, 40, 25, 11, 19];
