/**
 * 线路
 */
import {defineStore} from 'pinia'
import xialuFetch from './xialuFetch'

export const useXianLuStore = defineStore({
  id: 'xianlu',
  state: () => {
    return {
      chat: [],
      pay: [],
      reqServers2Status: 0, // 0为数据请求中、1为数据请求成功、2为数据请求失败
    }
  },
  getters: {
    getChat: (state) => state.chat.sort((a, b) => b.weight - a.weight), // 线路排序
  },
  actions: {
    ...xialuFetch,
  }
})
