export const PlayExt = {
  1: {id: 1, args: [{id: 1, name: '大'}, {id: 2, name: '小'}, {id: 3, name: '单'}, {id: 4, name: '双'}]},
  2: {
    id: 2,
    args: [
      {id: 6, name: '清', tag: 'qin'},
      {id: 5, name: '偶', tag: 'ou'},
      {id: 4, name: '奇', tag: 'ji'},
      {id: 3, name: '小', tag: 'xiao'},
      {id: 2, name: '大', tag: 'da'},
      {id: 1, name: '全', tag: 'quan'}
    ]
  },
  3: {
    id: 3,
    name: '两面',
    args: [
      {id: 1, name: '单', tag: 'dan'},
      {id: 2, name: '双', tag: 'shuang'},
      {id: 3, name: '大', tag: 'da'},
      {id: 4, name: '小', tag: 'xiao'},
      {id: 5, name: '合单', tag: 'hedan'},
      {id: 6, name: '合双', tag: 'heshuang'},
      {id: 7, name: '家禽', tag: 'jiaqin'},
      {id: 8, name: '野兽', tag: 'yesou'},
      {id: 9, name: '尾大', tag: 'weida'},
      {id: 10, name: '尾小', tag: 'weixiao'},
      {id: 11, name: '合尾大', tag: 'heweida'},
      {id: 12, name: '合尾小', tag: 'heweixiao'}
    ]
  },
  4: {
    id: 4,
    name: '波色',
    args: [
      {id: 1, name: '红', tag: 'red'},
      {id: 2, name: '蓝', tag: 'blue'},
      {id: 3, name: '绿', tag: 'green'},
      {id: 4, name: '红单', tag: 'reddan'},
      {id: 5, name: '红双', tag: 'redshuang'},
      {id: 6, name: '红大', tag: 'redda'},
      {id: 7, name: '红小', tag: 'redxiao'},
      {id: 8, name: '蓝单', tag: 'bluedan'},
      {id: 9, name: '蓝双', tag: 'blueshuang'},
      {id: 10, name: '蓝大', tag: 'blueda'},
      {id: 11, name: '蓝小', tag: 'bluexiao'},
      {id: 12, name: '绿单', tag: 'greendan'},
      {id: 13, name: '绿双', tag: 'greenshuang'},
      {id: 14, name: '绿大', tag: 'greenda'},
      {id: 15, name: '绿小', tag: 'greenxiao'}

    ]
  },
  5: {
    id: 5,
    name: '头尾',
    args: [
      {id: 1, name: '0头', tag: '0head'},
      {id: 2, name: '1头', tag: '1head'},
      {id: 3, name: '2头', tag: '2head'},
      {id: 4, name: '3头', tag: '3head'},
      {id: 5, name: '4头', tag: '4head'},
      {id: 6, name: '0尾', tag: '0end'},
      {id: 7, name: '1尾', tag: '1end'},
      {id: 8, name: '2尾', tag: '2end'},
      {id: 9, name: '3尾', tag: '3end'},
      {id: 10, name: '4尾', tag: '4end'},
      {id: 11, name: '5尾', tag: '5end'},
      {id: 12, name: '6尾', tag: '6end'},
      {id: 13, name: '7尾', tag: '7end'},
      {id: 14, name: '8尾', tag: '8end'},
      {id: 15, name: '9尾', tag: '9end'}

    ]
  },
  6: {
    id: 6,
    name: '生肖',
    args: [
      {id: 1, name: '鼠', tag: '1'},
      {id: 2, name: '牛', tag: '2'},
      {id: 3, name: '虎', tag: '3'},
      {id: 4, name: '兔', tag: '4'},
      {id: 5, name: '龙', tag: '5'},
      {id: 6, name: '蛇', tag: '6'},
      {id: 7, name: '马', tag: '7'},
      {id: 8, name: '羊', tag: '8'},
      {id: 9, name: '猴', tag: '9'},
      {id: 10, name: '鸡', tag: '10'},
      {id: 11, name: '狗', tag: '11'},
      {id: 12, name: '猪', tag: '12'}
    ]
  }
}