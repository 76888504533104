// MN推广中心接口组
import {useHot} from "/@/kernel-mn/stores/useHot.ts";
import network from "/@/kernel-mn/network";

export default {
  // 代理制度数据
  async reqAgentZhiDu() {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data: any = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
    };
    try {
      const res = await network.request({
        cmdId: 600120,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 代理推广数据简介
  async reqAgentCenter() {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data: any = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
    };
    try {
      const res = await network.request({
        cmdId: 600119,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 代理 会员页面 - 业绩报表
  async reqAgentYeji(payload = {}) {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data: any = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload,
    };
    try {
      const res = await network.request({
        cmdId: 600121,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 代理 会员页面 - 下级会员业绩报表 child_id:下级会员id start_time end_time
  async reqAgentChildUsers(payload:any = {}) {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data: any = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload,
      child_id: payload.child_id || '',
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10,
    };
    try {
      const res = await network.request({
        cmdId: 600122,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 代理 会员页面 - 子会员业绩报表 child_id:下级会员id
  async reqAgentChildYeji(payload = {}) {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data: any = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload,
    };
    try {
      const res = await network.request({
        cmdId: 600123,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 代理 会员页面 - 下级会员游戏记录 start_time end_time type_id platform game_id cust_id为自定义用户id
  async reqAgentChildGameRecord(payload:any = {}) {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data: any = {
      tid: tenantInfo.value.tid,
      partner_name: userInfo.value.cust_name,
      ...payload,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10,
    };
    try {
      const res = await network.request({
        cmdId: 600125,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 代理 财务页面 - 优惠红利记录 startTime endTime partner_id:代理id receiveType:优惠类型
  async reqAgentYouhuiRecord(payload:any = {}) {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data: any = {
      tid: tenantInfo.value.tid,
      partner_id: userInfo.value.cust_id,
      ...payload,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10,
    };
    try {
      const res = await network.request({
        cmdId: 600124,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 代理 财务页面 - 代存记录 start_time end_time child_id:查找的id
  async reqAgentDaicunRecord(payload:any = {}) {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data: any = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10,
    };
    try {
      const res = await network.request({
        cmdId: 600118,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 代理 财务页面 - 佣金记录  datetime:月份时间戳[2024-08]
  async reqAgentFeeRecord(payload:any = {}) {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data: any = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload,
      datetime: payload.datetime,
    };
    try {
      const res = await network.request({
        cmdId: 600126,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 代理 财务页面 - 平台费用 start_time end_time play_ids: 1309,1401	// 游戏ID
  async reqAgentPingtaiFree(payload:any = {}) {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data: any = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10,
    };
    try {
      const res = await network.request({
        cmdId: 600127,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 代理 财务页面 - 会员报表 start_time end_time day_type:[1、今日 2、昨天 3、7日 4、30日 0 、最大和自定义]
  async reqAgentUserReport(payload:any = {}) {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data: any = {
      tid: tenantInfo.value.tid,
      partner_id: userInfo.value.cust_id,
      ...payload,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10,
    };
    try {
      const res = await network.request({
        cmdId: 600129,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 代理 财务页面 - 提成记录 start_time end_time play_ids 游戏ID，可传多个，逗号分割  需要传big_type
  // big_type 分类 2棋牌， 3电子， 4捕鱼，5真人，6体育，7彩票，8红包
  async reqAgentTichengReport(payload:any = {}) {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data: any = {
      tid: tenantInfo.value.tid,
      partner_id: userInfo.value.cust_id,
      ...payload,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10,
    };
    try {
      const res = await network.request({
        cmdId: 600128,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
};
