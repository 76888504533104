import network from '../../network';
import {useHot} from '/@/kernel-mn/stores/useHot';
import {get} from '/@/kernel-mn/utils/storage';
import {dayEndTimeNew, dayStartTimeNew, getDeviceType} from '/@/kernel-mn/utils';
import {useUserStore} from '/@/kernel-mn/stores/user';

export default {
  // 获取幸运注单活动说明
  async getActivityExplain() {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid
    };
    try {
      const res: any = await network.request({
        cmdId: 322937,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 查询幸运注单尾号
  async getTailNumList() {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid
    };
    try {
      const res: any = await network.request({
        cmdId: 322938,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 查询幸运注单
  async reqLuckNotes(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      tail_num: payload.tail_num,
      game_type: Number(payload.game_type)
    };
    try {
      const res: any = await network.request({
        cmdId: 322939,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 立即领取 幸运注单
  async getAward(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      oper_code: userInfo.value.cust_code
    };
    try {
      const res: any = await network.request({
        cmdId: 322940,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 幸运注单: 验证
  async reqXyzdVeri() {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res: any = await network.request({
        cmdId: 322956,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 请求首页banner
   * */
  async reqBanners(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 999,
      state: 1,
      message_id: payload.message_id || 12, // banner 为12
      sort_flag: 1,
      tid: tenantInfo.value.tid,
      proxy: tenantInfo.value.proxy,
      chan_con: payload.chan_con,
      apply: payload.apply,
      type: getDeviceType(),
      language: payload.language || Number(get('languageNum') || 0),
      ...payload
    };
    try {
      const res: any = await network.request({
        cmdId: 380232,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        this.setBanners(res.data.data || []);
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 获取公告
   * ma_id: payload.ma_id,
   * */
  async reqNewList(payload: any = {}, nosave?) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 15,
      state: 1,
      message_id: payload.message_id || 10, // 公告 为10, 17=游戏公告
      sort_flag: 1,
      tid: tenantInfo.value.tid,
      proxy: tenantInfo.value.proxy,
      chan_con: payload.chan_con,
      apply: payload.apply,
      type: getDeviceType(),
      vip_level: userInfo.value.cust_level || 0,
      language: payload.language || Number(get('languageNum') || 0),
      ...payload
    };
    try {
      const res: any = await network.request({
        cmdId: 380232,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (+data.message_id === 17) {
        this.setReqNewListFinish(true);
      }
      if (res && res.type === 'success') {
        let _data = res.data.data
        if (!nosave) {
          this.setNewLists(_data || []);
        }
        res.data.data = _data
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      if (+data.message_id === 17) {
        this.setReqNewListFinish(true);
      }
      return Promise.reject(error.data);
    }
  },
  // 获取公告+轮播图接
  async reqNewsAndBanner(payload: any = {news: {}, banners: {}}, nosave?) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    let {news, banners} = payload;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      news: {
        ...news,
        page_start: news.page_start || 1,
        num_per_page: news.num_per_page || 15,
        state: 1,
        message_id: news.message_id || 10, // 公告 为10
        sort_flag: 1,
        tid: tenantInfo.value.tid,
        proxy: tenantInfo.value.proxy,
        chan_con: news.chan_con,
        apply: news.apply,
        vip_level: userInfo.value.cust_level || 0,
        language: news.language || Number(get('languageNum') || 0)
      },
      banners: {
        ...banners,
        page_start: banners.page_start || 1,
        num_per_page: banners.num_per_page || 999,
        state: 1,
        message_id: banners.message_id || 12, // banner 为12
        sort_flag: 1,
        tid: tenantInfo.value.tid,
        proxy: tenantInfo.value.proxy,
        chan_con: banners.chan_con,
        apply: banners.apply,
        language: banners.language || Number(get('languageNum') || 0)
      }
    };
    try {
      const res: any = await network.request({
        cmdId: 381123,
        data: data,
        headerType: 1,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        if (!nosave) {
          this.setNewLists(res.data?.news?.data ?? []);
          this.setBanners(res.data?.banners?.data ?? []);
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 获取站内信未读数量
   * */
  async reqWebMessageUnread(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res: any = await network.request({
        cmdId: 375024,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        this.webNewsUnread = res.data.countAll - res.data.redCount;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 站内信 收件箱
   * */
  async reqWebNews(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res: any = await network.request({
        cmdId: 375010,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 站内信 我的消息列表
   * */
  async reqMyNews(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      begin_time: dayStartTimeNew(Date.now() - 30 * 24 * 3600 * 1000),
      end_time: dayEndTimeNew(Date.now() + 3600 * 24 * 1000 * 2),
      ...payload,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10,
      tid: tenantInfo.value.tid,
      receivor_id: userInfo.value.cust_id,
      message_id: 30,
      receivor_type: 1
    };
    try {
      const res: any = await network.request({
        cmdId: 380336,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 站内信 阅读消息列表
   * */
  async reqReadMsg(payload: any = {}) {
    const {hotData} = useHot();
    // eslint-disable-next-line no-unused-vars
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = [tenantInfo.value.tid, payload.message_inst_id, payload.part_mon, payload.state, 5];
    try {
      const res: any = await network.request({
        cmdId: 380310,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 站内信 设置我的消息
   * @param payload.type type1单个已读/删除 type2批量已读/批量删除
   */
  async setMyNews(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data: any = {
      tid: tenantInfo.value.tid,
      state: payload.state
    };
    if (payload.type === 1) {
      data.message_inst_id = payload.message_inst_id;
      data.part_mon = payload.part_mon;
    } else {
      data.receivor_id = userInfo.value.cust_id;
      data.begin_time = dayStartTimeNew(Date.now() - 30 * 24 * 3600 * 1000);
      data.end_time = dayEndTimeNew(Date.now() + 3600 * 24 * 1000 * 2);
      data.receivor_type = 1;
      data.message_id = 30;
    }
    try {
      const res: any = await network.request({
        cmdId: payload.type === 1 ? 380335 : 380334,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 站内信 发送消息
   */
  async sendMessage(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    let receivor = userInfo.value.cust_name;
    if (payload.data_type === 1) {
      receivor = payload.receivor;
    } else if (payload.data_type === 7) {
      receivor = 'system';
    }
    // 发起请求
    let data = {
      ...payload,
      theme: payload.theme,
      content: payload.content,
      data_type: payload.data_type,
      tid: tenantInfo.value.tid,
      from_user: userInfo.value.cust_name,
      addresseeName: receivor
    };
    try {
      const res: any = await network.request({
        cmdId: 375017,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 站内信 发送信息权限
   */
  async sendMessageAuth(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res: any = await network.request({
        cmdId: 375021,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 站内信 获取站内信详情
   */
  async reqMessageDetail(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      mail_id: payload.mail_id,
      put_cust_name: payload.put_cust_name,
      send_cust_name: payload.send_cust_name,
      tid: tenantInfo.value.tid
    };
    try {
      const res: any = await network.request({
        cmdId: 375023,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 站内信 设置收件箱信息状态
   */
  async setWebNews(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      type: payload.type,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    if (!payload.isAll) {
      data.mail_id = payload.mail_id;
      data.put_cust_name = payload.put_cust_name;
      data.send_cust_name = payload.send_cust_name;
    }
    try {
      const res: any = await network.request({
        cmdId: 375011,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  /*
   * 站内信 回复收件箱信息状态
   */
  async responseMessage(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      send_cust_name: payload.send_cust_name,
      put_cust_name: payload.put_cust_name,
      mail_id: payload.mail_id,
      reply_content: payload.reply_content,
      tid: tenantInfo.value.tid
    };
    try {
      const res: any = await network.request({
        cmdId: 375018,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  async postReadState(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;

    let data = [tenantInfo.value.tid, payload.message_inst_id, payload.part_mon, payload.state, 5];
    try {
      const res: any = await network.request({
        cmdId: 380310,
        data: data,
        paramType: 2
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  // 领取金币
  async getMsgCoin(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;

    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res: any = await network.request({
        cmdId: 390207,
        data: data,
        headerType: 2,
        paramType: 0,
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  /** 请求优惠活动提交申请查询
   * @param payload
   */
  async reqActiveformquery(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;

    let data = {
      tid: tenantInfo.value.tid,
      bulletin_id: payload.bulletin_id
    };

    let sendPayLoad = {
      cmdId: 380523,
      data: data,
      paramType: 0
    };
    try {
      const res: any = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        this.activeformquery = res;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  /**
   * 请求优惠活动提交申请
   * @param payload
   */
  async reqSubmitApplications(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    const {verifyCode} = useUserStore();

    const data = {
      tid: tenantInfo.value.tid,
      bulletin_id: payload.bulletin_id,
      cust_id: userInfo.value.cust_id,
      acct_id: userInfo.value.acct_id,
      cust_name: payload.cust_name,
      discount: payload.discount,
      content: payload.content,
      apply_amount: payload.apply_amount,
      state: payload.state,
      oper_code: payload.oper_code,
      verifycode: payload.verifycode,
      smsCode: payload.smsCode,
      phone_num: payload.phone_num,
      verifycode_key: verifyCode.verifycode_key || ''
    };

    let sendPayLoad = {
      cmdId: 380520,
      data: data,
      paramType: 1
    };

    try {
      const res: any = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  /** 请求优惠活动提交申请查询
   * @param @requires payload.page_start 起始页
   * @param @requires payload.num_per_page 每页条数
   * @param @requires payload.chan_con 渠道
   * @param payload.start_time 起始时间
   * @param payload.end_time 结束时间
   * @param payload.is_clear 是否清空本地数据(不清空则追加)
   * @param payload.key 本次请求的key，默认为default
   * @param payload.options 更多参数 JSON
   * @param payload.callback 回调 function (res, err) {}
   */
  async reqEnquiryActivityApplication(payload) {
    const {hotData} = useHot();
    const {userInfo} = hotData;

    let data = {
      applySpeed: 1,
      discount: payload.discount || '',
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 100,
      cust_name: payload.cust_name,
      content_type: payload.content_type || 0,
      tid: userInfo.value.tid
    };

    let sendPayLoad = {
      cmdId: 380521,
      data: data,
      paramType: 0
    };
    try {
      const res: any = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        this.enquiryActivityApplication = res;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 请求优惠活动下拉菜单查询 */
  async reqActivemenuquery(payload) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;

    const data = {
      tid: userInfo.value.tid,
      proxy: tenantInfo.value.proxy
    } as any;
    if (payload.apply) {
      data.apply = payload.apply;
    }
    let sendPayLoad = {
      cmdId: 380522,
      data: data,
      paramType: 0
    };
    try {
      const res: any = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 答题赢彩金：中奖名单
   */
  async reqDtycjZJ(payload) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      nums: payload.nums || 99999
    }
    let sendPayLoad = {
      cmdId: 393604,
      data: data,
      paramType: 0
    }
    try {
      const res: any = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 答题赢彩金：活动时间
   */
  async reqDtycjCS() {
    const {hotData} = useHot();
    const {userInfo, tenantInfo} = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    }
    let sendPayLoad = {
      cmdId: 393605,
      data: data,
      paramType: 0
    }
    try {
      const res: any = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 答题赢彩金：获取答题次数
   */
  async reqDtycjNum() {
    const {hotData} = useHot();
    const {userInfo, tenantInfo} = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    }
    let sendPayLoad = {
      cmdId: 393601,
      data: data,
      paramType: 0
    }
    try {
      const res: any = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        this.dtycj.datiNum = res.data.num
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 答题赢彩金: 获取题目
   */
  async reqDtycjTM(payload) {
    const {hotData} = useHot();
    const {userInfo, tenantInfo} = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      aseq_id: payload.aseq_id,
      nums: payload.nums || 99999
    }
    let sendPayLoad = {
      cmdId: 393602,
      data: data,
      paramType: 0
    }
    try {
      const res: any = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 答题赢彩金: 回答题目
   */
  async reqDtycjAnswer(payload) {
    const data = payload.arr
    const sendPayLoad = {
      cmdId: 393603,
      data: data,
      paramType: 1,
    }
    try {
      const res: any = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 答题赢彩金: 验证答题赢彩金
   */
  async reqDtycjVeri() {
    const {hotData} = useHot();
    const {userInfo, tenantInfo} = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
    }
    const sendPayLoad = {
      cmdId: 393606,
      data: data
    }
    try {
      const res: any = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
};
