import {defineStore} from 'pinia';
import balanceFetch from './balanceFetch'

export const useBalanceListStore = defineStore({
  id: 'balanceList',
  state: () => {
    return {
      balanceList: {}, // 1币币账户, 2期货账户, 3法币账户, 4信托账户
      xinTuoBits: [], // 信托账户 划转的币的列表
    };
  },
  getters: {},
  actions: {
    ...balanceFetch
  }
});
