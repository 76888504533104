/* eslint-disable */
/* 福彩3D */
export default {
  plays: {
    0: {
      rule_id: 753000,
      area_id: 753000,
      play_name: 'YZZH',
      play_cname: '一字组合',
      special: false,
      unit_price: 1,
      note_tag: 'ssc3CombSpecial',
      cmd_signal_ball: true,
      ball_odds: true
    },
    1: {
      rule_id: 753001,
      area_id: 753001,
      play_name: 'DXDSZH',
      play_cname: '大小单双质合',
      special: false,
      unit_price: 1,
      note_tag: 'ssc3CombSpecial',
      cmd_signal_ball: true,
      ball_odds: true
    },
    2: {
      rule_id: 753002,
      area_id: 753002,
      play_name: 'EZDW',
      play_cname: '二字组合',
      special: false,
      unit_price: 1,
      note_tag: 'ssc3CombSpecial',
      cmd_signal_ball: true,
      ball_odds: true
    },
    3: {
      rule_id: 753003,
      area_id: 753003,
      play_name: 'SZDW',
      play_cname: '三字组合',
      special: false,
      unit_price: 1,
      note_tag: 'ssc3CombSpecial',
      cmd_signal_ball: true,
      ball_odds: true
    },
    4: {
      rule_id: 753004,
      area_id: 753004,
      play_name: 'YZDW',
      play_cname: '一字定位',
      special: false,
      unit_price: 1,
      note_tag: 'ssc3CombSpecial',
      cmd_signal_ball: true,
      ball_odds: true
    },
    5: {
      rule_id: 753005,
      area_id: 753005,
      play_name: 'EZDW',
      play_cname: '二字定位',
      special: false,
      unit_price: 1,
      note_tag: 'random2num',
      cmd_signal_ball: true,
      ball_odds: true
    },
    6: {
      rule_id: 753006,
      area_id: 753006,
      play_name: 'SZDW',
      play_cname: '三字定位',
      special: false,
      unit_price: 1,
      note_tag: 'random3num',
      cmd_signal_ball: true,
      ball_odds: true
    },
    7: {
      rule_id: 753007,
      area_id: 753007,
      play_name: 'EZHS',
      play_cname: '二字和数',
      special: false,
      unit_price: 1,
      note_tag: 'ssc3CombSpecial',
      cmd_signal_ball: true,
      ball_odds: true
    },
    8: {
      rule_id: 753008,
      area_id: 753008,
      play_name: 'SZHS',
      play_cname: '三字和数',
      special: false,
      unit_price: 1,
      note_tag: 'ssc3CombSpecial',
      cmd_signal_ball: true,
      ball_odds: true
    },
    9: {
      rule_id: 753009,
      area_id: 753009,
      play_name: 'ZX3',
      play_cname: '组选三',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    10: {
      rule_id: 753010,
      area_id: 753010,
      play_name: 'ZX6',
      play_cname: '组选六',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    11: {
      rule_id: 753011,
      area_id: 753011,
      play_name: 'KD',
      play_cname: '跨度',
      special: false,
      unit_price: 1,
      note_tag: 'ssc3CombSpecial',
      cmd_signal_ball: true,
      ball_odds: true
    }
  }
}
