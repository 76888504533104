/**
 * 快三
 */

const wanfaTime = {
  // 这里的中文键不能用了 因为中文名可以自定义修改
  '安徽快3': '8:40~22:00, 20分钟一期，每天40期',
  '江苏快3': '8:30~22:10, 20分钟一期，每天41期',
  '湖北快3': '9:00~22:00, 20分钟一期，每天39期',
  '北京快3': '9:20~23:40, 20分钟一期，每天44期',
  '河北快3': '8:50~22:10, 20分钟一期，每天41期',
  '甘肃快3': '10:00~22:00, 20分钟一期，每天36期',
  '上海快3': '8:58~22:18, 20分钟一期，每天41期',
  '贵州快3': '9:10~22:10, 20分钟一期，每天39期',
  '分分快三': '00:00~23:59, 每1分钟一期，每天1440期',
  '分分快三(信)': '00:00~23:59, 每1分钟一期，每天1440期',
  '三分快三': '00:00~23:59, 每3分钟一期，每天480期',
  '三分快三(信)': '00:00~23:59, 每3分钟一期，每天480期',
  '五分快三': '00:00~23:59, 每5分钟一期，每天288期',
  '五分快三(信)': '00:00~23:59, 每5分钟一期，每天288期',
  '山西快三': '00:00~23:59, 每5分钟一期，每天288期',
  '云南快三': '00:00~23:59, 每3分钟一期，每天480期',
  '青海快三': '00:00~23:59, 每5分钟一期，每天288期',
  '四川快三': '00:00~23:59, 每5分钟一期，每天288期',
  '河南快三': '00:00~23:59, 每10分钟一期，每天144期',
  '宁夏快三': '00:00~23:59, 每10分钟一期，每天144期',
  '台湾快3': '00:00~23:59, 每1分钟一期，每天1440期',
  '新疆快3': '00:00~23:59, 20分钟一期，每天72期',
  '吉林快3': '00:00~23:59, 20分钟一期，每天72期',
  // 现优先使用如下匹配 没有则匹配上方中文
  'K31F': '00:00~23:59, 每1分钟一期，每天1440期', // 分分快三
  'K33F': '00:00~23:59, 每3分钟一期，每天480期', // 三分快三
  'K35F': '00:00~23:59, 每5分钟一期，每天288期', // 五分快三
  'TWK3': '00:00~23:59, 每1分钟一期，每天1440期', // 台湾快3
  'YNK33F': '00:00~23:59, 每3分钟一期，每天480期', // 云南快三
  'SCK35F': '00:00~23:59, 每5分钟一期，每天288期', // 四川快三
  'SXK35F': '00:00~23:59, 每5分钟一期，每天288期', // 山西快三
  'QHK35F': '00:00~23:59, 每5分钟一期，每天288期', // 青海快三
  'HNK310F': '00:00~23:59, 每10分钟一期，每天144期', // 河南快三
  'NXK310F': '00:00~23:59, 每10分钟一期，每天144期', // 宁夏快三
  'AHK3': '08:40~22:00, 每20分钟一期，每天40期', // 安徽快3
  'JSK3': '08:30~22:10, 每20分钟一期，每天41期', // 江苏快3
  'JLK3': '00:00~23:59, 每20分钟一期，每天72期', // 吉林快3
  'GXK3': '', // 广西快3
  'HUBK3': '09:00~22:00, 每20分钟一期，每天39期', // 湖北快3
  'BJK3': '09:20~23:40, 每20分钟一期，每天44期', // 北京快3
  'HEBK3': '08:50~22:10, 每20分钟一期，每天41期', // 河北快3
  'GSK3': '10:00~22:00, 每20分钟一期，每天36期', // 甘肃快3
  'SHK3': '08:58~22:18, 每20分钟一期，每天41期', // 上海快3
  'GZK3': '09:10~22:10, 每20分钟一期，每天39期', // 贵州快3
  'XJK3': '00:00~23:59, 每20分钟一期，每天72期', // 新疆快3
  'XT20MINU1': '00:00~23:59, 每20分钟一期，每天72期', // 系统快三1
  'XT20MINU2': '00:00~23:59, 每20分钟一期，每天72期', // 系统快三2
  'XT20MINU3': '00:00~23:59, 每20分钟一期，每天72期', // 系统快三3
  'XT20MINU4': '00:00~23:59, 每20分钟一期，每天72期', // 系统快三4
  'XT20MINU5': '00:00~23:59, 每20分钟一期，每天72期', // 系统快三5
}

export default {
  wanfaTime
}
