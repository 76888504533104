import helperIG from '../../helpers/helperIG';
import helperLMG from '../../helpers/helperLMG';

export default class helperAllGames {
  // SG电子
  static SGDZGameCode = {
    'S-CP03': '糖果泡泡2',
    'S-FF01': '欢乐农场极限路线',
    'S-CS03': '财神至尊极限路线',
    'S-RR01': '金兔速递',
    'S-CM02': '奇幻圣诞',
    'S-PO02': '京剧熊猫',
    'S-HV01': '万圣狂欢',
    'S-LK03': '金刚传奇极限路线',
    'S-SP04': '爆糖派对',
    'S-CG01': '黄金船长',
    'S-JW01': '疯狂大圣',
    'S-FM03': '水果狂热',
    'S-GP03': '黑金豹极限路线',
    'S-SC01': '宇宙历险记',
    'S-CS02': '财神',
    'S-FS02': '火7全开精选',
    'S-LK02': '运财鲤鱼精选',
    'S-JT03': '小丑宝藏精选',
    'S-TD01': '福虎驾到',
    'S-RH02': '皇家俱乐部',
    'S-VB01': '性感维加斯',
    'S-RM01': '罗马',
    'S-CP02': '糖果爆爆',
    'S-GR01': '冲锋牛仔',
    'S-WW02': '赢翻乐',
    'S-RK02': '皇家猫咪',
    'S-RC01': '富财神',
    'S-MT01': '拳霸',
    'S-PW03': '扑克全押',
    'S-RH01': '箭客茹宾',
    'S-LI03': '心动爱豆',
    'S-MK01': '魔法喵喵',
    'S-CB02': '奇彩糖糖',
    'S-KF01': '武龙传',
    'S-HQ01': '古城探秘',
    'S-LB01': '圣兽幻境',
    'S-MF01': '大胃王',
    'S-RW01': '狼人觉醒',
    'S-MG02': '玛雅宝石',
    'S-CB01': '疯狂炸弹',
    'S-FS01': '火7全开',
    'S-MG01': '巨富7',
    'S-JT02': '小丑宝藏',
    'S-DF03': '双炎至尊',
    'S-WC02': '旺财',
    'S-BM01': '埃及古宝',
    'S-MM01': '鼠来宝',
    'S-DE01': '龙飞凤舞',
    'S-BA01': '888',
    'S-LS02': '三福星',
    'S-HE01': '英雄',
    'S-SB01': '梦幻甜点',
    'S-DF02': '跳跳舞',
    'S-ML01': '神灯',
    'S-TP02': '三倍熊猫',
    'S-GP01': '黑金豹',
    'S-CH01': '朱大富',
    'S-GK01': '桃园三兄弟',
    'S-PG01': '四大财神',
    'S-CP01': '糖果泡泡',
    'S-GF01': '金武门',
    'S-LY02': '发发发2',
    'S-PW02': '旺旺公主',
    'S-WP02': '汪旺财',
    'S-FL02': '初恋',
    'S-FM02': '齐天大胜',
    'S-TZ01': '森林之王',
    'S-FG01': '新霸天下',
    'S-TW01': '虎大天王',
    'S-NT01': '海龙王',
    'S-ZE01': '天通雷神',
    'S-HY01': '猴爷到',
    'S-GC03': '金鸡',
    'S-WM03': '五福门SA',
    'S-FC03': '发大财SA',
    'S-DG04': '金龙赐福SA',
    'S-EG03': '斩五门SA',
    'S-GS04': '吉星SA',
    'S-IL03': '雪冰世界SA',
    'S-WC03': '旺财SA',
    'S-FO01': '一路发发',
    'S-BC01': '财神宝宝',
    'S-GL02': '潘金莲特别版',
    'S-PH02': '法老宝藏',
    'S-MR01': '美人鱼',
    'S-WP01': '旺宝',
    'S-PO01': '宝袋精灵',
    'S-IL02': '雪冰世界',
    'S-FD01': '五龙吐珠',
    'S-CS01': '财神888',
    'S-DF01': '青龙白虎',
    'S-GO01': '厨神',
    'S-LK01': '运财鲤鱼',
    'S-WM02': '五福门',
    'S-FC02': '发大财',
    'S-DG03': '金龙赐福',
    'S-EG02': '斩五门',
    'S-GS03': '吉星',
    'S-LY01': '发发发',
    'S-HF01': '极速王者',
    'S-LF01': '风生水起',
    'S-PK01': '海盗霸王',
    'S-CY01': '财源广进',
    'S-DX01': '大福小福',
    'S-BB01': '金狮拜年',
    'S-GW01': '黄金鲸鱼',
    'S-LM01': '超吉猫',
    'S-LC01': '天降财神',
    'S-SG02': '圣诞大礼',
    'A-HH01': '跳企来',
    'A-RB01': '小偷星星',
    'A-DN02': '德比夜赛',
    'A-GJ01': '西游金',
    'A-GL02': '积宝小精灵',
    'A-PF02': '单球致富',
    'A-SC02': '龙会',
    'A-SH02': '仓鼠忍者',
    'A-DB06': '三国龙舟战',
    'A-AP02': '动物奇园',
    'A-DB04': '职业赛单车',
    'A-DB05': '越野赛车',
    'A-DD02': '极速赛狗',
    'A-DE02': '德比直通',
    'A-JW02': '仙桃演义',
    'A-LB02': '幸运宝贝',
    'C-SD01': '骰碟',
    'C-RT01': '轮盘',
    'C-TH01': '泰国希洛骰子',
    'C-DT01': '龙虎',
    'C-TF03': '鱼虾蟹2',
    'C-BK01': '鱼虾鲎',
    'C-SB01': '骰宝',
    'C-PD01': '博登',
    'C-TF02': '泰国鱼虾蟹',
    'C-TF01': '三公',
    'C-PP01': '十三水',
    'C-BF01': '通比牛牛',
    'C-BB01': '抢庄牛牛',
    'C-TE01': '二八杠',
    'C-WT01': '炸金花',
    'F-ZP01': '玩翻丧尸',
    'F-AH01': '异形猎人',
    'F-SF01': '捕鱼天王',
    'F-SF02': '捕鱼大战'
  };
  // DB彩票
  static DBCPGames = {
    1: 'DB彩票',
    202: '-'
  };

  // DB棋牌
  static DBQiPaiGames = {
    '101': '炸金花',
    '102': '二十一点',
    '103': '推筒子',
    '105': '抢红包',
    '106': '红黑大战',
    '107': '百家乐',
    '108': '至尊百家乐',
    '109': '德州扑克',
    '110': '抢庄牛牛',
    '111': '看四张抢庄牛牛',
    '112': '十三水',
    '113': '抢庄牌九',
    '114': '龙虎斗',
    '115': '万人牛牛',
    '116': '跑得快',
    '117': '斗地主',
    '118': '万人骰宝',
    '119': '三公',
    '120': '梭哈',
    '121': '通比牛牛',
    '122': '二人麻将',
    '123': '极速炸金花',
    '124': '万人推筒子',
    '125': '二人广东麻将',
    '127': '森林舞会',
    '128': '暗宝',
    '129': '奔驰宝马',
    '130': '幸运骰子',
    '200': '海王捕鱼',
    '135': '万人水果机'
  };

  // PP真人
  static PPSXGameCode = {
    '210': '自动超级轮盘',
    '401': '真人百家乐1',
    '404': '真人百家乐2',
    '422f ': '真人百家乐3',
    '411': '真人百家乐5',
    '413': '真人百家乐6',
    '425': '真人百家乐7',
    '426b': '真人百家乐8',
    '436': '免佣百家乐1',
    '402': '极速百家乐1',
    '403': '极速百家乐2',
    '412': '极速百家乐3',
    '414': '极速百家乐5',
    '415': '极速百家乐6',
    '431': '极速百家乐7',
    '432': '极速百家乐8',
    '430': '极速百家乐9',
    '428': '极速百家乐10',
    '424f': '极速百家乐11',
    '421f': '极速百家乐12',
    '438f': '极速百家乐13',
    '405': '极速百家乐14',
    '427b': '极速百家乐15',
    '441': '极速百家乐16-韩语',
    '435': '免佣极速百家乐1',
    '439': '免佣极速百家乐2',
    '433': '超级8百家乐',
    '434': '好运6百家乐',
    '227': '蔚蓝-轮盘1',
    '201': '轮盘2',
    '206': '澳门-轮盘3',
    '221': '俄羅斯-轮盘4',
    '222': '德国-轮盘5',
    '224': '土耳其-轮盘6',
    '223': '意大利-轮盘7',
    '229': '印度-轮盘8',
    '545': '梅花-轮盘9',
    '230a20': '红宝石-轮盘10',
    '235': '荷兰-轮盘11',
    '233': '罗马尼亚-轮盘12',
    '234': '西班牙-轮盘14',
    '204': '超级轮盘',
    '225': '自动轮盘1',
    '203': '极速轮盘1',
    '205': '极速轮盘2',
    '240': 'PowerUp轮盘',
    '1401': '繁荣都市',
    '1101': '真人甜入心扉',
    '801': '超级幸运轮',
    '701': '超级骰宝',
    '1001': '龙虎',
    '1024': 'AndarBahar',
    '901': '唯一黑杰克',
    '902a9': '红宝石唯一二十一点2',
    '903': '唯一二十一点荷兰语3',
    '904': '唯一二十一点土耳其5',
    '521': '蔚蓝二十一点17',
    '522': '蔚蓝二十一点18',
    '523': '蔚蓝二十一点19',
    '524': '蔚蓝二十一点20',
    '525': '蔚蓝二十一点21',
    '526': '蔚蓝二十一点22',
    '527': '蔚蓝二十一点23',
    '528': '蔚蓝二十一点24',
    '529': '蔚蓝二十一点25',
    '530': '蔚蓝二十一点26',
    '539': '蔚蓝二十一点27',
    '538': '蔚蓝二十一点28',
    '537': '蔚蓝二十一点29',
    '536': '蔚蓝二十一点30',
    '535': '蔚蓝二十一点31',
    '540': '蔚蓝二十一点32',
    '511': '蔚蓝二十一点3',
    '512': '蔚蓝二十一点6',
    '513': '蔚蓝二十一点7',
    '514': '蔚蓝二十一点4',
    '515': '蔚蓝二十一点1',
    '516': '蔚蓝二十一点9',
    '517': '蔚蓝二十一点10',
    '518': '蔚蓝二十一点5',
    '519': '蔚蓝二十一点2',
    '520': '蔚蓝二十一点8',
    '301': '真人二十一点11',
    '302': '真人二十一点12',
    '303': '真人二十一点14',
    '304': '真人二十一点15',
    '305': '真人二十一点16',
    '541': '梅花二十一点33',
    '542': '梅花二十一点34',
    '543': '梅花二十一点35',
    '544': '梅花二十一点36',
    '562': '红宝石二十一点37',
    '563': '红宝石二十一点38',
    '564': '红宝石二十一点39',
    '565': '红宝石二十一点40',
    '555': '红宝石二十一点41',
    '556': '红宝石二十一点42',
    '557': '红宝石二十一点43',
    '558': '红宝石二十一点44',
    '559': '红宝石二十一点45',
    '560': '红宝石二十一点46',
    '561': '红宝石二十一点47',
    '551': '红宝石二十一点48',
    '552': '红宝石二十一点49',
    '553': '红宝石二十一点50',
    '554': '红宝石二十一点51',
    '594': '红宝石二十一点52',
    '595': '红宝石二十一点53',
    '596': '红宝石二十一点54',
    '597': '红宝石二十一点55',
    '593': '红宝石二十一点56',
    '598': '蔚蓝二十一点57',
    '599': '蔚蓝二十一点58',
    '600': '蔚蓝二十一点59',
    '601': '蔚蓝二十一点60',
    '602': '蔚蓝二十一点61',
    '603': '蔚蓝二十一点62',
    '590': '蔚蓝二十一点63',
    '546': 'VIP真人二十一点1-红宝石',
    '547': 'VIP真人二十一点2-红宝石',
    '548': 'VIP真人二十一点3-红宝石',
    '549': 'VIP真人二十一点4-红宝石',
    '550': 'VIP真人二十一点5-红宝石',
    '566': '极速二十一点1-红宝石',
    '567': '极速二十一点2-红宝石',
    '568': '极速二十一点3-红宝石',
    '569': '极速二十一点4-红宝石',
    '570': '极速二十一点5-红宝石',
    '591': '极速二十一点6-红宝石',
    '592': '极速二十一点7-红宝石',
    '604': '极速二十一点8-红宝石',
    '605': '极速二十一点9-红宝石',
    '606': '极速二十一点10-红宝石',
    '607': '极速二十一点11-蔚蓝',
    '608': '极速二十一点12-蔚蓝',
    '609': '极速二十一点14-蔚蓝'
  };
  // KA电子
  static KADZGameCode = {
    GoldenFishHunter: '千金捕鱼',
    PiggyBankMachine: '小猪扑满',
    JamesBond: '特务零零七',
    BionicHuman: '仿生人',
    SpiderGoblin: '蜘蛛精',
    WhiteNoseCat: '白鼻猫',
    KingOctopus: '章鱼王',
    GoldenDragon: '金龙捕鱼',
    PoseidonSecret: '海王秘宝',
    KAFishHunter: '爽爽捕鱼',
    AirCombat1942: '空战1942',
    SpaceCat: '天天打怪',
    ThreeKingdoms: '天天战三国',
    AnimalFishing: '浣熊钓鱼',
    FishingExpedition: '光头强钓鱼',
    MermaidHunter: '炮打美人鱼',
    ForceOfDragon: '降龙传说',
    AlterWorld: '大战异世界',
    TheDeepMonster: '深海巨鳄',
    CaiShenDao: '财神到',
    SpaceCowboy: '星际牛仔',
    FourDragonKings: '四海游龙',
    LegendOfErlang: '炮打二郎神',
    GoGoMagicDog: '魔斗汪星人',
    UnderseaBattle: '海底大作战',
    GoGoMagicCat: '魔斗喵星人',
    GiantFishHunter: '大海怪',
    IronChickenHunter: '炮打铁公鸡',
    ThreeHeadedDragon: '黄金三头龙',
    ZombieChicken: '爆打僵尸鸡',
    MonsterIsland: '魔物岛',
    MermaidWorld: '人鱼传说',
    OceanPrincess: '海霸姬',
    HappyFoodHunter: '海底捞',
    RobotWars: '机器人大战',
    MythicalBeast: '神兽捕鱼',
    SpecialOPS: '特战部队',
    HungryShark: '饥饿鲨',
    DevilBuster: '魔鬼克星',
    MagicWitches: '魔法女巫',
    FoodComa: '大胃王',
    UnderseaTreasure: '海底宝藏',
    OceanStarHunting: '海洋之星大作战',
    WorldOfLordElfKing: '魔域精灵王',
    NaughtyWitches: '捣蛋女巫',
    RabbitParty: '金兔满膛',
    HungrySharkCthulhu: '饥饿鲨邪神',
    MermaidLegend: '追渔物语',
    BonusMania: '疯狂奖金',
    Treasurebowl: '聚宝盆',
    GoldenBull: '牛转钱坤',
    FortuneGanesha: '幸运象神',
    Luck88: '金龙发发',
    KingOfDragon: '四海龙王',
    Cocorico: '公鸡咕咕咕',
    FireHit: '超级火焰',
    GoldenBall: '金球奖',
    JokerFruit: '王牌水果',
    FaCaiDestiny: '呖咕呖咕发大财',
    MilkGirl: '牛乳美眉',
    SnowQueen: '冰雪女王',
    QuadrupleDragons: '开龙发发发发',
    SuperShot: '超级旋转',
    RedCliff: '赤壁',
    QuickPlayCandy: '糖果快对',
    Speakeasy: '灯红酒绿',
    HouYi: '后羿射日',
    DrGeek: '神经博士',
    BonusManiaDeluxe: '疯狂奖金豪华版',
    WongTaiSin: '黄大仙',
    BlackRider: '黑骑士',
    Fastbreak: '快攻',
    WildVick: '熊孩子',
    ShockTower: '激爆电流',
    BoxingRoo: '拳击袋鼠',
    SuperBonusMania: '超级疯狂奖金',
    SkyForce: '空中部队',
    Mexicaliente: '火辣辣墨西哥',
    SnowGoddess: '青女',
    AliceInMegaLand: '爱丽丝幻境',
    LegendOfLegends: '英雄传说',
    ScaryClown: '恐怖小丑',
    CatAndMouse: '猫和老鼠',
    LadyRacer: '赛车女孩',
    IrishCoins: '圣帕特里克锁好运',
    RomanceInEngland: '浪漫英伦',
    LionVSShark: '狮鲨对决',
    BeautyTrap: '美人计',
    EuropeanRoulette: '欧式轮盘',
    FiveHeroes: '鸿运五虎',
    ThreeMonkeys: '猴猴猴',
    KaiFengFu: '开封府',
    LadyKAKA: '瓢虫卡卡',
    ChineseQuyi: '中国曲艺',
    GreatLuck: '招财福娃',
    TuanYuan: '团团圆圆',
    BeautifulLady: '唐伯虎点秋香',
    BunnyGirl: '萌兔美眉',
    SummerSamba: '夏日森巴',
    LoveAmongstWar: '薛平贵与王宝钏',
    YearOfTheRabbit: '银兔满堂',
    FortuneRabbit: '玉兔迎春锁好运',
    FiveFortuneGods: '五路财神',
    UpHellyAa: '维京火祭',
    Seediq: '赛德克',
    LuckyGod: '幸运财神',
    FiveSoundFortune: '财气五韵',
    OrientalBeast: '东方萌兽',
    GreatDoctor: '神医',
    PlagueDoctor: '鸟嘴医生',
    HolidayCarol: '圣诞颂歌锁好运',
    WealthToad: '招财金蟾',
    EmptyCity: '空城计',
    LazyRich: '慵懒时光',
    Geisha: '艺伎',
    FootballMania: '足球热',
    Sumo: '相扑力士',
    UnicornParty: '独角兽趴踢',
    Sinbad: '辛巴达',
    MysteryShoot: '神秘狙击场',
    PenaltyKick: '十二码罚球',
    LandOfDwarfs: '精灵国',
    SexyLips: '红唇美人',
    DragonInn: '龙门客栈',
    TheKingProducer: '金牌制作人',
    ShaolinSoccer: '少林足球',
    CheerleadingTeam: '啦啦队',
    FortuneFeast: '大丰收巨路发',
    RichFarm: '农场主',
    BonusPoker: '奖励扑克',
    DarkFamily: '黑暗家族',
    EvilPumpkin: '邪惡南瓜',
    FashionBones: '时尚骷髅',
    AnimalDojo: '动物道场',
    RichDiwali: '印度灯节',
    DwarfMiner: '矮人矿坑',
    GreatCourtyard: '金豪门',
    PricelessMuseum: '惊奇博物馆巨路发',
    WarOfThrones: '权力之战',
    MoulinRouge: '红磨坊',
    CatMuseum: '猫咪美术馆',
    HipHop: '嘻哈',
    HeroinesOfTheMarsh: '水浒三女将',
    Oktoberfest: '慕尼黑啤酒节',
    LuckyVideoPoker: '幸运电动扑克',
    SquidParty: '鱿鱼派对锁好运',
    ChivalrousGirl: '侠女',
    WhoLetTheDogsOut: '看门狗',
    BookOfMoon: '月之书巨路发',
    HareVSTortoise: '龟兔赛跑',
    ChainOfWild: '连环计',
    Hamlet: '哈姆雷特',
    ChaiGong: '济公',
    ApolloAndArtemis: '太阳神和月神',
    TigerGirl: '萌虎美眉',
    CreatorRa: '创造神拉',
    SecretAgent: '秘密特务',
    Seagull: '海鸥',
    GhostsFortune: '五鬼运财',
    ZombieExpert: '僵尸道长锁好运',
    WildJungle: '丛林之王',
    LegendOfAkihiro: '明宏传奇',
    TreasureCarp: '发财金鲤',
    CountySeat: '盗官记',
    SleepwalkingSheep: '梦游绵羊',
    GhostFestival: '鬼门开',
    DemonFox: '百变妖狐',
    SuperEnergy: '超级能源',
    CthulhuSecret: '克苏鲁秘密',
    FightingGirls: '格斗女孩',
    LuckyGem: '招财宝石巨路发',
    SuperDragonTiger: '超级龙虎斗',
    ChineseFeast: '满汉全席',
    DetectiveDee: '狄仁杰',
    BountyHunter: '赏金猎人',
    DragonTurtle: '龙龟',
    ChinesePastry: '宝味斋',
    CrazyWomensJail: '狂爆女子监狱',
    LegendOfAthena: '女神雅典娜',
    MagicQueen: '魔术女王',
    FantasyDragons: '龙之乡',
    SolomonsTreasure: '所罗门宝藏',
    DragonBoat2: '龙舟2锁好运',
    BigfootYeti: '大脚雪怪',
    HunterHelibu: '猎人海力布',
    ShaolinLegend: '少林传奇',
    MagicianHouse: '魔法师家',
    HanselAndGretel: '汉赛尔与葛丽特',
    TheBoyWhoCriedWolf: '狼来了',
    Shennong: '神农尝百草',
    AgentAngels: '特务娇娃',
    LuckyTumbler: '招财不倒翁',
    MidasTouch: '点石成金',
    Golden777: '金牌777',
    WildVick2: '熊孩子2冒险旅程',
    EasterEggParty: '彩蛋派对',
    FuShenlong: '福神龙',
    LuckyInn: '特级餐馆',
    MirrorHouse: '伯爵夫人',
    MonkeyManiac: '猴子也疯狂',
    WhaleWild: '鲸银岛',
    PiedPiper: '吹笛人',
    SuperSlime: '超能史莱姆',
    HongKong60s: '花样年华',
    Atlantide: '亚特兰提斯',
    ZashikiGod: '座敷童子',
    BonusVending: '疯狂贩卖机',
    Medusa: '梅杜莎',
    JadePower: '玉之力',
    CalabashBoys: '葫芦娃',
    AmericanBurger: '美式汉堡',
    SailorMan: '水手',
    OwlInForest: '猫头鹰',
    HappyIndianChef: '印度厨神',
    LoveInMemory: '成双成对',
    WhiteDeer: '白鹿传奇',
    FuLuShou: '三星报喜',
    FortuneBeauty: '绝代佳人巨路发',
    TreasureTiger: '金虎招财',
    YearOfTheTiger: '虎年吉祥',
    BollywoodRomance: '宝莱情缘',
    DaGuanYuan: '大观园',
    PoChiLam: '宝芝林',
    EmperorQin: '秦始皇',
    SantaBumbleBee: '圣诞大黄蜂集鸿运',
    LionKingAndEagleKing: '狮子王与鹫鹰王',
    EightTreasures: '八吉祥',
    GoGoMonsters: '怪兽大作战',
    HappyThanksgiving: '感恩节大餐',
    TreasureRaider: '萧十一郎',
    ToyWorld: '玩具世界',
    Cancan: '大腿舞',
    ChineseAncientTomb: '千年古墓',
    TreasureBowlMegaways: '聚宝盆巨路发',
    ButterflyLovers: '梁山伯与祝英台',
    WitchAcademy: '魔女学院',
    MuayThai: '泰拳',
    FortuneFu: '福满门',
    TheHappyPrince: '快乐王子',
    BookOfMummy: '神秘木乃伊',
    SkyJourney: '天空之旅',
    Ninja: '忍者',
    PandaFamily: '熊猫一族',
    MagicalStore: '神奇杂货店',
    WuZetian: '武后传奇',
    CharlottesWeb: '夏洛特的网',
    TheGuardOfHades: '牛头马面',
    CupidAndPsyche: '邱比特和赛姬',
    GoldenFish: '黄金鲤鱼',
    SweetMaid: '甜心女仆',
    MonkeyAndCrab: '猿蟹大战',
    Sweetopia: '甜蜜王国',
    TombHeroes: '神仙侠侣',
    WerewolfIsComing: '天黑请闭眼',
    PhoenixRising: '凤凰来仪',
    EarthGod: '土地公',
    Soldiers: '美国大兵',
    WestChamber: '西厢记',
    ArcticStorm: '北极风暴',
    MillenniumLove: '千年之恋',
    FlowerGoddessFestival: '花神会',
    WonWonCatching: '旺旺抓虫',
    LegendOfFoxSpirit: '狐仙情缘',
    MeerkatsFamily: '狐萌这一家',
    Meowfia: '猫黑帮',
    SecretOfOcean: '海洋传说',
    HolyBeast: '圣兽天下',
    BaseballFever: '棒球魂',
    MonsterParade: '怪兽狂欢',
    DiamondPower: '钻石力',
    Japanese7Heroes: '战国7大名',
    PersiaBonanza: '波斯宝藏巨路发',
    ThreeBandits: '三个强盗',
    LegendOfHeroes: '勇者传说',
    MythologicalCreatures: '神兽',
    Polaroid: '拍立得',
    Sante: '干杯',
    DoubleFortune: '好事成双',
    LuckyLucky: '开运招财',
    WelcomeFortune: '迎财神',
    LanternFestival: '元宵',
    AngryPiggies: '愤怒小猪',
    MagicApprentice: '魔法师学徒',
    Aladdin: '阿拉丁',
    FairyForestTale: '精灵之森',
    FireDragons: '龙之焰',
    MysteryAlchemy: '神秘炼金术',
    FortunePiggyBank: '猪元滚滚',
    Jingwei: '精卫填海',
    Pinata: '皮纳塔',
    AlexanderTheGreat: '亚历山大大帝',
    DragonsWay: '猛龙过江',
    RouranKhaganate: '柔然古国',
    RoyalDemeanor: '王者风范',
    WenDing: '问鼎',
    SevenHeroines: '巾帼7烈传',
    ChristmasCandy: '圣诞糖果',
    KungFuKaga: '功夫小子',
    XmasWishes: '欢乐圣诞节',
    Masquerade: '化装舞会',
    HuHuFighting: '唬力全开',
    DarkFortress: '暗黑要塞',
    BlockyBlocks2: '块块乐2',
    SuperShot2: '超级旋转2',
    LuckyCat: '招财福猫',
    JourneyToWest: '魔幻西游',
    SushiNinja: '寿司忍者',
    CandyStorm: '糖果风暴',
    Dracula: '德古拉',
    DragonsLegend: '九龙传说',
    FortuneGod: '财神爷报到',
    WuSong: '武松打虎',
    GreatVoyages: '郑和下西洋',
    AI: '人工智能',
    PumpkinWin: '万圣夜',
    TheDoorGods: '门神',
    YueFei: '岳飞',
    CandyMania: '疯狂糖果',
    CharmingSorceress: '迷人魔法师',
    LuckyCasino: '如意赌坊',
    MedalWinner: '大赢家',
    Emoji: '表情符号',
    SpaceStorm: '太空风暴',
    CuJu: '蹴鞠',
    TheGingerbreadLand: '姜饼人',
    RedBoy: '红孩儿',
    DragonBall: '龙珠',
    Ghostbuster: '天师钟馗',
    ChineseValentinesDay: '牛郎与织女',
    ImperialGirls: '花魁榜',
    LotusLamp: '宝莲灯',
    PartyGirl: '夜店美眉',
    GlacialEpoch: '冰河时期',
    XElements: '神秘元素',
    FluffyBuddy: '胆小毛毛怪',
    '777Vegas': '777拉斯维加斯',
    YunCaiTongZi: '运财童子',
    Giants: '巨人',
    VeggiesPlot: '蔬果乐园',
    Fantasy777: '梦幻777',
    GenghisKhan: '成吉思汗',
    LegendOfPaladin: '剑侠奇缘',
    HengandHa: '哼哈二将',
    LuckyPenguins: '幸运企鹅',
    DonQuixote: '唐吉诃德',
    HoneyMoney: '蜂蜜派对',
    ShadowPlay: '皮影戏',
    BlockyBlocks: '块块乐',
    Musketeers: '三剑客',
    Pets: '宠物当道',
    TheGoldenAx: '金银斧',
    WanFuJinAn: '万福金安',
    BigApple: '纽约客',
    WizardofOz: '奥兹历险记',
    HuYeh: '虎爷',
    WonWonRich: '旺旺来',
    HatSeller: '帽子商人',
    SunnyBikini: '阳光比基尼',
    TowerofBabel: '巴比伦之塔',
    ComeOnRhythm: '动次打次',
    SpringBlossom: '花季',
    SnowWhite: '白雪公主',
    Mazu: '妈祖',
    BumbleBee: '大黄蜂',
    ArcherRobinHood: '神箭手',
    GodofLove: '月下老人',
    LionDance: '舞狮',
    Frankenstein: '科学怪人',
    Nian: '年兽',
    TheGrandmaster: '一代宗师',
    GoldMagic: '黄金魔术',
    TaiChi: '太极',
    LoungeClub: '休闲俱乐部',
    Nvwa: '女娲补天',
    Pandora: '潘朵拉的盒子',
    FortuneLions: '石狮',
    WolfWarrior: '战狼',
    VolcanoAdventure: '火山历险',
    CatchTheThief: '抓小偷',
    ErlangShen: '二郎神',
    SiberianWolves: '西伯利亚狼',
    BombingFruit: '轰炸水果',
    Pinocchio: '小木偶',
    SevenWonders: '世界七大奇迹',
    ThirstyCrow: '口渴的乌鸦',
    Rarities: '稀世珍宝',
    Speed: '速度与激情',
    MuscleCars: '美式跑车',
    FourScholars: '四大才子',
    BullStampede: '奔牛',
    PeterPan: '彼得潘',
    PrimevalForest: '原始雨林',
    Nutcracker: '胡桃夹子',
    QuickPlayJewels: '钻石闪闪亮',
    Zorro: '蒙面侠佐罗',
    NineLucks: '九星连珠',
    ShoppingFiend: '购物狂',
    QuickPlayMahjong: '雀圣',
    XBomber: '炸弹特工',
    Robots: '机器人',
    JokerSlot: '王牌老虎机',
    UFO: '飞碟',
    EgyptianMythology: '埃及神话',
    Mermaid: '美人鱼',
    LeagueOfGods: '媚狐妲己',
    Cowboys: '西部牛仔',
    Vampire: '吸血鬼传说',
    OriginOfFire: '火之源',
    ThreeGods: '福禄寿报喜',
    EgyptianEmpress: '埃及艳后',
    BubbleDouble: '水果泡泡',
    WuGang: '吴刚伐桂',
    MoonGoddess: '嫦娥仙子',
    TripleDragons: '开龙发发发',
    RichSquire: '财多多',
    PartyGirlWays: '夜店美眉2',
    Baccarat: '百家乐',
    ChiYou: '蚩尤',
    WildWildBell: '摇铃铃',
    ThreeHeroes: '蜀汉三英豪',
    DragonBoat: '龙舟',
    WhiteSnakeLegend: '白蛇传',
    SnowLeopards: '雪豹',
    JellyMania: '果冻乐园',
    FastBlast: '极速旋转',
    Route66: '66号公路',
    ArtistStudio: '画家工作室',
    AfricaRun: '动物大迁徙',
    Apes: '猿人',
    Wencheng: '文成公主',
    Horoscope: '星座',
    CaiYuanGuangJin: '财源广进',
    BoyToys: '高富帅',
    LiveStreamingStar: '直播主',
    GoldenShanghai: '夜上海',
    Millionaires: '百万富翁',
    MissTiger: '虎妞',
    Tao: '炼丹术',
    YuGong: '愚公移山',
    Egypt: '神秘金字塔',
    CrazyCircus: '疯狂马戏团',
    PirateKing: '海贼王寻宝',
    HappyFarm: '活力庄园',
    Safari: '野外探险',
    Gem: '钻石闪亮亮',
    ThreeLittlePigs: '三只小猪',
    Viking: '维京时代',
    DeepSea: '深海之旅',
    Samurai: '武士道',
    Space: '太空旋转',
    Bakery: '香甜面包店',
    Cinderella: '灰姑娘的玻璃鞋',
    Wizardry: '魔法师的巫术',
    FairyDust: '妖精的尘埃',
    MayanGold: '玛雅宝藏',
    ZombieLand: '僵尸的世界',
    Mythic: '神话世纪',
    KungFu: '功夫传说',
    Knights: '中世纪骑士',
    Kitty: '猫咪生活',
    Ares: '战神之战',
    Jungle: '丛林冒险',
    Poseidon: '波塞顿的宝藏',
    FourBeauties: '四大美人',
    DayOfDead: '亡灵节',
    ChineseOpera: '中国戏曲',
    RedRidingHood: '小红帽',
    Flaming7: '火焰777',
    StockedBar: '不醉不归',
    Mushrooms: '迷幻蘑菇',
    SuperKeno: '超级基诺',
    SuperVideoPoker: '电动扑克',
    BaWangBieJi: '霸王别姬',
    Leprechauns: '魔法精灵',
    FantasyPark: '奇幻乐园',
    ImperialGuards: '大明锦衣卫',
    Neanderthals: '尼安德塔人',
    DragonGate: '鲤鱼跃龙门',
    Nezha: '哪吒',
    DaVinci: '发明家达芬奇',
    GoldRush: '加州淘金潮',
    Stonehenge: '巨石阵',
    SilkRoad: '丝路',
    LostRealm: '失落的王国',
    HuaMulan: '花木兰',
    FlowersFruitMountain: '花果山',
    DimSum: '中式点心',
    TaiwanBlackBear: '台湾黑熊',
    TRex: '霸王龙',
    Dreamcatcher: '捕梦网',
    Mahjong: '牌神',
    Polynesian: '南岛风情',
    WildAlaska: '荒野生存',
    FormosanBirds: '灵雀',
    AliceInWonderland: '梦游爱丽丝',
    Aurora: '极光',
    Enchanted: '魔幻世界',
    FruitParty: '水果派对',
    LandOfGold: '黄金之源',
    LastFantasy: '最终幻想曲',
    Pirate: '海盗船长',
    SNS: '社交网之友',
    Yamato: '宇宙战舰',
    LuxuryGarage: '豪华车库'
  };
  // NETENT电子
  static NEDZGameCode = {
    bloodsuckersj0r2: '吸血男爵™',
    fruitshop0000000: '水果商店™',
    gunsnroses000000: '枪炮玫瑰视频老虎机™',
    jackhammer000000: '杰克·汉墨™',
    reelrush00000000: '赏金冲刺™',
    starburst0000000: '星光四射™',
    twinspin00000000: '双子卷轴™',
    dazzle0000000000: '炫目宝石™',
    deadoralive20000: '亡命对决 2',
    divinemegaways00: '天赐好运™巨额路线™',
    eldorado00000000: '贡佐的探险™',
    finn000000000000: '精灵菲恩与漩涡转盘',
    jimihendrix00000: '吉米·亨德里克斯在线老虎机™',
    jumanji000000000: '勇敢者的游戏',
    motorhead0000000: '摩托头视频老虎机™',
    narcos0000000000: '缉毒特警™',
    ozzy000000000000: '奥齐·奥斯本视频老虎机',
    pyramid000000000: '金字塔：探索永生™',
    streetfighter2f1: '街头霸王™II：世界勇士老虎机',
    turnyourfortune0: '幸运旋转',
    twinspinmw000000: '双子卷轴巨额路线',
    deadoralive00000: '亡命对决™',
    fruitshopawaysr1: '水果商店™巨额路线™',
    hellskitchenr300: '戈登·拉姆齐地狱厨房',
    steamtower000000: '蒸汽之塔™',
    alohaj0r00000000: '阿罗哈！符号串赢奖™',
    alohaxmas0000000: '阿罗哈！圣诞™',
    jackandbeanstalk: '杰克和魔豆™',
    jinglespin000000: '圣诞铃铛转盘™',
    piggyriches00000: '财富小猪™',
    wildwildwest0000: '狂野西部：火车大劫案™',
    butterflystaxx00: '寻蝶记',
    romer20000000000: '罗马：黄金时代™',
    bloodsuckers2000: '吸血男爵II™',
    darkking00000000: '黑暗之王：禁忌财富',
    deadoralive2fb00: '亡命对决2™ 特色游戏购买',
    elements00000000: '元素：觉醒™',
    fruitshopchristm: '水果商店圣诞版™',
    gorillakingdom00: '猩猩王国™',
    hotline000000000: '热线',
    hotline2f1g10000: '热线2',
    irishpotluck0000: '爱尔兰幸运罐子™',
    rageoftheseas000: '怒海狂涛™',
    richesofmdgardf1: '米德加德的财富：出现并扩展™',
    serengetikings00: '塞伦盖蒂之王',
    victorious000000: '凯旋™',
    willyshotchillie: '威利的火热辣椒™',
    parthenonr100000: '帕台农神庙：探索永生™',
    ameriroulette3ne: '美式轮盘赌',
    asgardianstones0: '仙宫宝石',
    baccarat2ne00000: '百家乐',
    butterflystaxx20: '寻蝶记2',
    codexoffortune00: '财富法典™',
    dazzlemwr3000000: '炫目宝石™巨额路线™',
    doublestacks0000: '双重堆叠™',
    europroulette3ne: '欧式轮盘',
    fairyred00000000: '童话传说：小红帽',
    flowers000000000: '花朵™',
    frenchroulettene: '法式轮盘',
    goldentavern0000: '菲恩的金酒馆',
    highlights000000: '亚特兰蒂斯的秘密™',
    invisibleman0000: '隐身人™',
    jackhammer200000: '杰克·汉墨2™',
    jewelfruits00000: '水果转盘™',
    junglespirit0000: '丛林精灵：百搭互换',
    koiprincess00000: '锦鲤公主™',
    multipliermayhem: '竞速：多重狂欢™',
    scruffyduck00000: '邋遢鸭™',
    secretofthestone: '古岩的奥秘™',
    secretsofchristm: '圣诞秘密™',
    spacewars0000000: '太空战争™',
    starbursttremef1: '星光四射超极限',
    vikings000000000: '维京人视频老虎机',
    warlords00000000: '霸主：神力水晶™',
    wildwater0000000: '激流勇进™',
    wildworlds000000: '野蛮世界™',
    reefraiderr30000: '暗礁奇兵™',
    fruitblazef1r300: '水果闪耀',
    lostrelics000000: '失落遗迹™',
    blacklagoon00000: '黑礁生物™',
    godsofgold000000: '黄金之神：无限卷轴™',
    jokerpro00000000: '小丑专业版™',
    excalibur0000000: '神剑™',
    oceanstreasure00: '海洋宝藏™',
    cashnoire0000000: '现金争锋™',
    cashomatic000000: '马蒂克现金™',
    casinoholdemne00: '娱乐场德州扑克',
    discodanny000000: '迪斯可丹尼™',
    dracula000000000: '德古拉™',
    druidsdream00000: '德鲁伊之梦™',
    eggomatic0000000: '马蒂克神蛋™',
    fairyhansel00000: '童话传说：韩赛尔和格雷特™',
    fairymirror00000: '童话传说：魔镜™',
    football00000000: '足球冠军杯™',
    grandspinn000000: '盛大旋转™',
    halloweenjack000: '万圣节杰克™',
    jackpot600000000: '累积奖金6000™',
    jacksmultihandn1: 'J或更大加倍™',
    mythicmaiden0000: '神话少女™',
    magicmaidcafe000: '神奇女仆咖啡馆™',
    colossalpinatas0: '皮纳塔盛会™',
    fireflies0000000: '灯光™',
    kingofslots00000: '老虎机之王™',
    neonstaxx0000000: '霓虹星光™',
    riseofmaya000000: '玛雅崛起™',
    scudamore0000000: '斯库达摩尔的超级赌注',
    shangrila0000000: '香格里拉传奇：符号串赢奖™',
    silverback000000: '银背大猩猩黄金',
    gonzosgoldr30000: '贡佐的黄金',
    reelsteal0000000: '卷轴神偷™',
    roulette2advne00: '高级轮盘™',
    superstriker0000: '超级前锋™',
    sweetyhoneyfruit: '甜蜜水果™',
    thewolfsnight000: '狼祸™',
    trollpot00000000: '巨魔奖池 5000™',
    wildbazaar000000: '狂野集市™',
    wilderland000000: '荒野之地™',
    wingsofriches000: '财富之翼™',
    wolfcub000000000: '幼狼™',
    knightrider00000: '霹雳游侠',
    blackjack0h0i0j0: '二十一点经典',
    goldengrimoire00: '金色魔法书',
    spinsane00000000: '疯狂旋转',
    funkmaster000000: '杂鱼军团大师',
    spacewars2000000: '太空大战2力量指数',
    beehivebonanza00: '富贵蜂巢',
    corneliusf100000: '科尼利厄斯',
    superstarsf10000: '超级巨星',
    wondersofstmasf0: '聖誕奇蹟',
    cupcakesf1000000: '紙杯蛋糕',
    milkshakexxr96f1: '梦幻奶昔 超极限',
    letitburnr96f100: '尽情 燃烧 ',
    magicportals0000: '魔法门',
    twinspindeluxe00: '双子卷轴豪华版',
    wizards000000000: '魔法施放',
    bollywoodstory00: '宝莱坞故事',
    fortunerangers00: '财富游侠',
    berryburst000000: '爆裂浆果™',
    ghostpirates0000: '幽灵海盗™',
    wishmasteroct000: '许愿大师™',
    hiddencoiofegypt: '埃及金币™',
    wildturkey000000: '狂野火鸡™',
    santavsrudolf000: '圣诞老人与鲁道夫™',
    fruitsne00000000: '水果转盘™',
    longpao000000000: '龙袍™',
    twinhappiness000: '双子幸福™',
    stickers00000000: '贴纸™',
    archangels000000: '大天使：救赎™',
    wildotron3000000: '百搭机器人3000™',
    kingof3kingdoms0: '三国霸主™',
    robinhood0000000: '罗宾汉：换位财富™',
    whosthebride0000: '谁是新娘™',
    arcane0000000000: '奥术：卷轴大乱斗™',
    happyriches00000: '快乐财富™',
    strollingstaxx00: '巡回漫步：立方水果™',
    eastseadrgonking: '东海龙王™',
    witchcrafacademy: '魔法学院',
    themepark0000000: '主题公园财富门票',
    copycats00000000: '复制猫',
    flowerschristmas: '花朵圣诞版',
    bustersboesr96f1: '西部恶犬',
    塔可之怒超极限: '塔可之怒超极限',
    双子旋转超极限: '双子旋转超极限',
    monkeys000000000: '香蕉天堂！',
    berryburstmax000: '爆裂浆果最强版',
    swipe00000000000: '滑动翻滚',
    hooksheroes00000: '虎克船长的英雄'
  };
  // FC电子
  static FCDZGameCode = {
    '22016': '金钱豹',
    '22017': '三只小猪',
    '22018': '逛夜市',
    '22019': '熊猫龙舟',
    '22020': '大过年',
    '22021': '碰碰胡',
    '22022': '锦鲤跃钱',
    '22023': '大乐门',
    '22024': '龟兔赛车',
    '22026': '火锅派对',
    '22027': '开心夺宝',
    '22028': '寻宝奇航',
    '22029': '西部风云',
    '22030': '豪华金钱豹',
    '22031': '宇宙大战',
    '22032': '魔豆',
    '22034': '淘金乐',
    '22036': '富贵大亨',
    '22037': '巨海觅宝',
    '22038': '罗马竞技',
    '22039': '罗宾汉',
    '22040': '财富连连',
    '22041': '大过年2',
    '22042': '古墓秘宝',
    '22043': '蜜糖爆击',
    '22045': '合成与魔法',
    '22046': '财富列车',
    '22047': '神灯金灵',
    '22048': '疯狂野牛'
  };
  // TP电子
  static TPDZGameCode = {
    rg_207: '10X劲爆777',
    ds_3058: '发红包',
    rg_7: '糖果英雄',
    ds_3021: '海盗王',
    ds_3022: '爱丽丝',
    at2_036: '10x钻石7',
    va_509: '香蕉猴子王',
    va_508: '财运旺旺来',
    va_507: '黃金笑面佛',
    va_422: '凤凰传奇',
    ds_3014: '阿拉伯',
    at2_041: '钱滚钱',
    rg_205: '财神777',
    ds_3048: '冰果霸',
    ds_3051: '蔬香人家',
    ds_4004: '金蟾祖玛',
    rg_9: '我要当皇上',
    rg_10: '宝石嘉年华',
    rg_201: '劲爆777',
    va_506: '富贵财神',
    va_505: '财神报喜',
    ds_3011: '抢金库',
    ds_3016: '水果晶钻',
    ds_3017: '高尔夫',
    ds_3013: '嗨起来',
    ds_3004: '狮霸天下',
    ds_3008: '大糖盛世',
    ds_3029: '发财龙',
    at2_042: '埃及艳后',
    at2_043: '幸运星',
    at2_044: '糖果圣诞',
    at2_045: '麻将发了2',
    at2_046: '刮刮乐翻天',
    at2_047: '欢乐弹珠台',
    ds_4002: '射龙门',
    ds_3060: '七色宝钻',
    ds_3061: '给猫红包',
    ds_3062: '幕府将军',
    at2_055: '福娃报喜',
    hp_sGoldenWar: '狂热之战',
    ds_3019: '宙斯神',
    ds_3028: '水果Bar',
    ds_3035: '三倍猴哥',
    ds_3046: '小厨娘',
    ds_3047: '宝矿利',
    ds_3054: '拳力制霸',
    ds_3057: '你会胡',
    ds_3063: '印度神虎',
    ds_3003: '发起来',
    hp_sGoldenFa: '发发发',
    ds_3005: '大秘宝',
    ds_3006: '埃及神谕',
    ds_3007: '财神到',
    ds_3009: '发财狮',
    ds_3001: '钻石大亨',
    ds_3002: '鱼跃龙门',
    hp_sRomaII: '罗马竞技场II',
    rg_203: '劲爆龙龙龙',
    at2_049: '100x钻石7',
    hp_sClaFruit7: '经典水果7',
    at2_056: '恶龙宝藏',
    at2_057: '金爆鱼虾蟹',
    rg_12: '财神彩金',
    rg_8: '宝石彩金',
    rg_6: '宝石糖果派对',
    rg_202: '劲爆发发发',
    va_182: '长征财神',
    ds_3027: '马雅王',
    at2_048: '银行抢匪',
    va_510: '直式神龙寻宝3',
    ds_3059: '犇牛宝',
    va_513: '大秦皇',
    va_512: '招财进宝2',
    ds_3023: '石器原始人',
    ds_3044: '天外飞仙',
    ds_3045: '送钱鼠',
    rg_11: '財神派對',
    rg_206: '發財樹',
    ds_3038: '吕姬无双',
    ds_3039: '宝你发',
    ds_3040: '很多妹子',
    ds_3041: '更多妹子',
    ds_3042: '旺财神',
    ds_3043: '给猫金币',
    ds_3010: '大圣猴哥',
    ds_3012: '五圣兽',
    rg_209: '钱狮777',
    ds_4005: '跳跳跳',
    at2_023: '5x钻石7',
    at2_024: '爆金闪电7',
    at2_025: '爆金火焰7',
    at2_026: '爆金彩虹7',
    at2_033: '摇钱猪',
    at2_034: '寻龙夺宝',
    at2_035: '天降财神',
    at2_029: '爆金777',
    at2_031: '淘金熊猫',
    at2_032: '爆爆猪',
    ds_3030: '金猴爷',
    at2_019: '动物好朋友',
    at2_021: '开运舞狮7',
    at2_022: '鱼跃龙门7',
    at2_027: '祥龙戏珠7',
    at2_028: '福多多7',
    at2_007: '排球巨星',
    at2_009: '宝石矿坑',
    at2_010: '海盗宝藏',
    at2_011: '讨虎传',
    at2_013: '聂小倩',
    at2_014: '月亮派对',
    at2_015: '鱼多多',
    at2_016: '麻将发了',
    ds_3068: '世界波',
    va_504: '冰雪派对',
    va_511: '长征一号',
    va_501: '神龙寻宝1',
    va_502: '神龙寻宝2',
    ds_3015: '饿狼传说',
    ds_3018: '虎霸王',
    ds_3020: '德古拉',
    ds_3024: '雷霸龙',
    ds_3025: '马戏之王',
    ds_3026: '点石成金',
    ds_3031: '福神到',
    ds_3032: '熊猫侠',
    ds_3033: '马上发',
    ds_3034: '777',
    ds_3036: '赵云无双',
    ds_3037: '火凤凰',
    ds_4001: '梯子游戏',
    ds_4003: '淘金蛋',
    ds_3053: '大钻石',
    ds_3064: '龙来发',
    ds_3065: '犎牛暴起',
    ds_4013: '跳龙门',
    ds_4007: '疯狂龙珠',
    ds_3067: '钱滚钱2',
    ds_3069: '大警长',
    at2_001: '龙龙龙2',
    at2_002: '太空冒险',
    at2_003: '三倍狐猴',
    at2_004: '麻将发发发',
    at2_005: '幸运双连',
    at2_006: '招财进宝',
    at2_050: '十全金狮',
    at2_051: '百福金狮',
    at2_053: '宠物乐园',
    at2_054: '狗狗假期',
    at2_037: '极限火箭',
    at2_038: '泰拳争霸',
    at2_039: '斗鸡王者',
    at2_040: '超级爆金7II',
    va_503: '战神宙斯'
  };
  // RSG电子
  static RSGDZGameCode = {
    6: '法老王',
    5: '金鷄報喜',
    1: '泰有錢',
    3: '皇家777',
    2: '魔法石',
    4: '慾望城市',
    7: '阿里巴巴',
    8: '幸運水果',
    11: '虎克船長',
    19: '五龍爭霸',
    10: '動物叢林',
    21: '七十二變',
    12: '野蠻遊戲',
    14: '甜蜜糖果',
    16: '大力水手',
    23: '人魚傳說',
    17: '瘋狂博士',
    25: '竹林熊貓',
    24: '荒野水牛',
    26: '泰好運',
    27: '財神到',
    18: '永不停止',
    28: '行運一條龍',
    15: '烈焰轉輪',
    32: '礦工哥布林',
    31: '提金派對',
    33: '幸運拉霸',
    30: '龍王',
    37: '迪斯可之夜',
    38: '農場夜驚魂',
    36: '家犬先生',
    29: 'HUSA',
    35: '巫師商店',
    40: '福娃發發',
    41: '泰山',
    34: '非洲',
    42: '墨西哥辣椒',
    43: '金豬爆吉',
    300: '八爪天下海霸王',
    39: '武媚娘',
    50: '跳跳獅',
    44: '七起來',
    47: '果凍27',
    45: '女忍者',
    46: '忍者',
    49: '海神',
    53: '霓虹圓',
    48: '暴怒棕熊',
    55: '嗨起來',
    56: '西部牛仔',
    59: '秘林熊貓',
    2001: '能量外星人',
    90: '龍行天下',
    100: '羅馬競技場',
    76: '小熊王國',
    65: '太極',
    58: '賣火柴的小女孩',
    61: '黃金之書',
    60: '嘻哈金剛',
    66: '金色幸運草',
    51: '美杜莎',
    52: '美狄亞',
    73: '精靈射手',
    68: '巫師商店黃金版',
    70: '鼠來寶',
    78: '皇家7777',
    81: '龍王2',
    82: '法老王 II',
    112: '雷神之錘',
    111: '開心農場',
    72: '潑水節',
    113: '秦皇傳說',
    117: '麻將發了',
    114: '聚寶財神',
    119: '魔龍傳奇'
  };
  // EVOPLAY电子
  static EVOPLAYDZGameCode = {
    '85': '火线武力',
    '725': '冒险飞船',
    '5547': '动物探索',
    '5679': '德州奖金',
    '5789': '阿努比斯之月',
    '98': '亚特兰蒂斯',
    '10': '篮球联盟',
    '736': '战车',
    '5867': '神圣火焰',
    '5774': '血腥珠宝',
    '5493': '惊奇之旅',
    '5479': '狂野圣诞',
    '5847': '布袋卷轴',
    '5855': '布袋卷轴奖金购买',
    '5897': '辣椒的道',
    '220': '辣椒的道奖金购买',
    '296': '糖果梦',
    '5893': '糖果梦:甜蜜星球',
    '5907': '糖果梦:甜蜜星球奖金购买',
    '979': '魅惑女王',
    '22': '贺新年',
    '5815': '圣诞节之伸手',
    '5833': '圣诞节之伸手奖金购买',
    '25': '鬼船海盗',
    '5926': '倒塌的城堡',
    '5943': '倒塌的城堡奖金购买',
    '5799': '法老诅咒',
    '5805': '法老诅咒奖金购买',
    '5766': '运气循环',
    '5782': '运气循环奖金购买',
    '5829': '龙之酒馆',
    '5845': '龙之酒馆奖金购买',
    '742': '豚宝奇兵',
    '5452': '地城探险',
    '994': 'E.T.军团',
    '4': '埃及众神',
    '5758': '艾伦的财富',
    '82': '精灵公主',
    '101': '骷颅奇兵',
    '5762': '史诗传说',
    '5947': '扩展大师',
    '5659': '爆炸水果',
    '5641': '毛怪复仇者',
    '5760': '美食盛宴',
    '107': '足球女将',
    '5721': '森林梦境',
    '5727': '遗忘的寓言',
    '88': '夏日派对',
    '5791': '水果迪斯科',
    '5741': '水果新星',
    '5752': '水果超级新星',
    '5797': '水果超级新星100',
    '5823': '水果超级新星80',
    '5793': '水果超级新星30',
    '5775': '水果超级新星40',
    '5795': '水果超级新星60',
    '976': '水果盘',
    '5771': '匪徒之夜',
    '5773': '海妖之金',
    '5787': '海妖之金奖金购买',
    '5517': '幸运七',
    '5813': '幸运七特辑',
    '5902': '炙热火山',
    '5909': '炙热火山奖金购买',
    '5487': '饥饿之夜',
    '5740': '冰狂',
    '79': '藏宝任务',
    '5921': '心灵之火',
    '5551': '爱尔兰画卷',
    '5695': '果冻啾啾',
    '37': '珠宝人生',
    '5735': '愉快宝藏',
    '28': '美猴王',
    '5877': '多汁宝石',
    '5879': '多汁宝石奖金购买',
    '5485': '卡恩传奇',
    '94': '疯狂木乃伊',
    '988': '幸运女孩',
    '5903': '巨大运气',
    '5905': '巨大运气奖金购买',
    '5483': '迷宫:渴望权力',
    '229': '巨大鱼获',
    '5922': '巨大鱼获奖金购买',
    '5653': '午夜派对',
    '730': '怪物实验室',
    '5889': '钱币铸造机',
    '5911': '钱币铸造机奖金购买',
    '973': '神秘星球',
    '104': '舞娘俱乐部',
    '970': '死灵法师',
    '5737': '复活之夜',
    '5398': '努克世界',
    '728': '黄金年代',
    '5717': '浣熊传说',
    '76': '赤壁',
    '5913': '紅玫瑰殿堂',
    '1018': '驯兽宝贝',
    '5549': '乐透吧',
    '5569': '荷鲁斯的崛起',
    '19': '罗宾汉',
    '293': '罗宾逊',
    '982': '能源冲突',
    '5593': '火箭之星',
    '5919': '智利之轮',
    '5935': '智利之轮奖金购买',
    '5755': '命运符文',
    '5673': '海上旋转',
    '5527': '四季美人',
    '5778': '卢克斯之影',
    '5949': '拍卖',
    '5455': '思布林克',
    '5677': '冲浪区',
    '5763': '甜甜食糖',
    '991': '辛迪加',
    '13': '金钱暴',
    '5746': '死神殿',
    '5777': '死神殿獎金購買',
    '5868': '雷霆之殿',
    '95': '皇帝陵墓',
    '733': '史诗冲突',
    '16': '嬉游长城',
    '5871': '最大鱼获',
    '5873': '最大鱼获金购买',
    '719': '古老传说',
    '5749': '疯狂宝藏',
    '5742': '光之树',
    '5887': '光之树金购买',
    '5941': '宝藏狙击手:圣诞节',
    '5957': '宝藏狙击手:圣诞红利购买',
    '1003': '终极杀阵',
    '5801': '三重辣椒',
    '5759': '无限愿望',
    '722': '七十年代',
    '5738': '梦想之谷',
    '34': '赌城之夜',
    '5587': '经典老虎机',
    '5751': '狂野子彈',
    '5841': '野性霸主',
    '5857': '野性霸主奖金购买',
    '5901': '躲藏之狼',
    '5930': '躲藏之狼奖金购买',
    '5553': '德州扑克',
    '1006': '印度三张',
    '748': '绿洲扑克经典赛',
    '5669': '法国轮盘',
    '967': '百家乐777',
    '1009': '美国轮盘3D',
    '946': '欧洲轮盘',
    '745': '二十一点幸运七',
    '5849': '安达尔夜',
    '5525': '拆弹小组',
    '5776': '財富之輪',
    '5739': '基诺之书',
    '5748': '圣诞晚会',
    '5489': '骑驴扫弹',
    '5585': '水手猜奖',
    '1012': 'E.T.种族',
    '5937': 'Ethan Grand: 玛雅日记',
    '5891': '足球投注',
    '5687': '足球专家',
    '5928': '足球刮刮卡',
    '958': '四个王牌',
    '5875': '妖精逃跑',
    '964': '抛硬币',
    '5344': '高射手',
    '5865': '好运之卡',
    '5811': '幸运崩溃',
    '5545': '黑手党:辛迪加',
    '5899': '魔术之箱',
    '5523': '幸运轮',
    '5753': '迈罕',
    '5573': '踩地雷',
    '961': '比大小',
    '5761': '东方神秘',
    '5757': '霓虹形状',
    '230': '老西部',
    '5743': '帕钦美女',
    '5863': '帕特里克之魔法场',
    '5831': '点球系列',
    '5643': '点球大战',
    '5770': '弹球游戏',
    '5883': '自豪之战',
    '955': '寻找女王',
    '985': '剪刀石头布',
    '949': '骰吧',
    '5767': '扭转胜利',
    '5781': '拯救仓鼠',
    '5491': '刮刮卡',
    '952': '快演猜球',
    '5736': '时间之轮',
    '5750': '基诺圣诞猫'
  };
  // AFB体育
  static AFBTYGameCode = {
    Squash: '壁球',
    Soccer: '足球',
    'Soccer - Outright': '足球-优胜冠军',
    Basketball: '篮球',
    'Basketball-Outright': '篮球-优胜冠军',
    'Moto Sports - Outright': '汽车运动-优胜冠军',
    'US Football': '美式足球',
    Golf: '高尔夫',
    'Formula 1 - Driver Championship': '一級方程式-车手锦标赛',
    'Golf - Outright': '高尔夫-优胜冠军',
    Badminton: '羽毛球',
    Snooker: '撞球/桌球',
    Baseball: '棒球',
    'E-Sport': '电子竞技',
    'Snooker - Outright': '羽毛球-优胜冠军',
    'Ice Hockey': '冰球',
    'Tennis - Outright': '乒乓球-优胜冠军',
    'Moto Sports - Head to Head': '汽车运动-车手对车手',
    Tennis: '网球',
    Volleyball: '排球',
    'Badminton - Outright': '羽毛球-优胜冠军',
    Pool: '撞球',
    Cricket: '板球',
    'Rugby - Outright': '橄榄球-优胜冠军',
    'Formula 1 - Head to Head': '一級方程式-车手对车手',
    'Beach Soccer - Outright': '沙滩足球-优胜冠军',
    Rugby: '橄榄球',
    'Volleyball - Outright': '排球-优胜冠军',
    'Beach Soccer': '沙滩足球',
    Futsal: '室内足球',
    'Futsal - Outright': '室内足球-优胜冠军',
    'Ice Hockey - Outright': '冰球-优胜冠军',
    'Pool - Outright': '撞球-优胜冠军',
    Handball: '手球',
    'Table Tennis': '乒乓球',
    'US Football - Outright': '美式足球-优胜冠军',
    Darts: '投镖',
    Boxing: '拳击',
    'Darts - Outright': '投镖-优胜冠军',
    'Cycling - Outright': '自行车-优胜冠军',
    'Boxing - Outright': '拳击-优胜冠军',
    'Baseball - Outright': '棒球-优胜冠军',
    Olympic: '奥运',
    'Olympic - Outright': '奥运-优胜冠军',
    '4D Specials': '4D 游戏-1D&2D&3D',
    'HandBall - Outright': '手球-优胜冠军',
    'Cricket - Outright': '板球-优胜冠军',
    Financials: '股市',
    'Water Polo': '水球',
    'Entertainment- Outright': '综艺-优胜冠军',
    'Cycling - Head to Head': '自行车-车手对车手',
    'Water Polo - Outright': '水球-优胜冠军',
    Cycling: '自行车',
    Athletics: '竞技',
    'Athletics - Outright': '竞技-优胜冠军',
    'Winter Sport': '滑雪运动',
    'Winter Sport - Outright': '滑雪运动-优胜冠军',
    'Formula1-CC': '一级方程式',
    'Table Tennis-Outright': '乒乓球-优胜冠军',
    'Soccer - World Cup/Euro': ' 足球-世界杯',
    'Soccer - World Cup/Euro Outright': '足球-世界杯-优胜冠军',
    'FC 1D': '泰式千字-1D',
    'FC 2D': '泰式千字-2D',
    'FC 3D': '泰式千字-3D',
    'Huay Thai': '泰式千字',
    'Muay Thai': '泰拳',
    'BIG/SMALL': '泰式千字-BIG/SMALL'
  };
  // DB体育
  static DBTYGameCode = {}
  // PGSOFT电子
  static PGSOFTDZGameCode = {
    'leprechaun-riches': '爱尔兰精灵',
    'mahjong-ways': '麻将胡了',
    'mahjong-ways2': '麻将胡了2',
    'treasures-aztec': '寻宝黄金城',
    'lucky-neko': '招财猫',
    'double-fortune': '双囍临门',
    'the-great-icescape': '冰雪大冲关',
    'captains-bounty': '赏金船长',
    'cai-shen-wins': '赢财神',
    'ganesha-fortune': '福运象财神',
    'dreams-of-macau': '澳门壕梦',
    'queen-bounty': '赏金女王',
    'fortune-ox': '十倍金牛',
    'wild-bandito': '亡灵大盗',
    'ways-of-qilin': '麒麟送宝',
    'dragon-hatch': '寻龙探宝',
    'egypts-book-mystery': '埃及探秘宝典',
    'phoenix-rises': '凤凰传奇',
    'wild-fireworks': '火树赢花',
    'thai-river': '水上泰神奇',
    'bali-vacation': '巴厘之旅',
    'crypto-gold': '比特淘金',
    diaochan: '夜戏貂蝉',
    'fortune-gods': '横财来啦',
    'win-win-won': '旺旺旺',
    medusa2: '美杜莎II',
    'fortune-tree': '摇钱树',
    medusa: '美杜莎',
    'plushie-frenzy': '抓抓乐',
    'wizdom-wonders': '巫师之书',
    'gem-saviour': '宝石侠',
    'hood-wolf': '逆袭的小红帽',
    hotpot: '麻辣火锅',
    'dragon-legend': '鱼跃龙门',
    'mr-hallow-win': '万胜狂欢夜',
    'legend-of-hou-yi': '后羿射日',
    'prosperity-lion': '舞狮进宝',
    'hip-hop-panda': '嘻哈熊猫',
    'santas-gift-rush': '圣诞欢乐送',
    'gem-saviour-sword': '宝石侠-大宝剑',
    'symbols-of-egypt': '埃及寻宝',
    'emperors-favour': '皇上吉祥',
    'ganesha-gold': '象财神',
    'three-monkeys': '三只猴子',
    'jungle-delight': '水果丛林',
    'journey-to-the-wealth': '嘻游记',
    'flirting-scholar': '唐伯虎点秋香',
    'ninja-vs-samurai': '忍者vs武侍',
    'muay-thai-champion': '拳霸',
    'dragon-tiger-luck': '龙虎争霸',
    'fortune-mouse': '鼠鼠福福',
    'reel-love': '亲爱的',
    'gem-saviour-conquest': '宝石侠-宝藏征途',
    'shaolin-soccer': '少林足球',
    'candy-burst': '糖果连连爆',
    'bikini-paradise': '比基尼天堂',
    'genies-wishes': '阿拉丁神灯',
    'circus-delight': '欢乐嘉年华',
    'sct-cleopatra': '艳后之谜',
    'vampires-charm': '德古拉女爵',
    'jewels-prosper': '金玉满堂',
    'jack-frosts': '冰锋奇侠',
    'galactic-gems': '星旅淘金',
    'gdn-ice-fire': '冰火双娇',
    'opera-dynasty': '新国粹',
    'majestic-ts': '宝石传奇',
    'candy-bonanza': '糖心风暴',
    'heist-stakes': '霹雳神偷',
    'rise-of-apollo': '太阳神传说',
    'sushi-oishi': '寿司之道',
    'jurassic-kdm': '恐龙帝国',
    'mermaid-riches': '人鱼公主',
    groundhog: '地鼠总动员',
    'crypt-fortune': '丽影奇兵之探秘埃及',
    'sprmkt-spree': '超市大血拼',
    'buffalo-win': '美洲野牛',
    'lgd-monkey-kg': '美猴王传奇',
    'spirit-wonder': '百鬼夜行',
    'farm-invaders': '星际农村',
    'emoji-riches': '钞级表情包',
    'cocktail-nite': '夜醉佳人',
    'mask-carnival': '假面嘉年华',
    'oriental-pros': '江山美景图',
    'fortune-tiger': '虎虎生财',
    'garuda-gems': '神鹰宝石',
    'dest-sun-moon': '日月星辰',
    'btrfly-blossom': '蝶戀花',
    'rooster-rbl': '斗鸡',
    'queen-banquet': '韩宫御宴',
    battleground: '絕地大逃殺',
    'win-win-fpc': '发财鱼虾蟹',
    'lucky-piggy': '电玩金猪',
    'speed-winner': '极速赢家',
    'legend-perseus': '珀尔修斯传奇',
    'wild-coaster': '疯赚过山车',
    'wild-bounty-sd': '赏金大对决',
    'prosper-ftree': '黄金摇钱树',
    'totem-wonders': '图腾奇迹',
    'alchemy-gold': '煉金金',
    'diner-delights': '美食美刻',
    'asgardian-rs': '维京纪元',
    'midas-fortune': '点石成金',
    'fortune-rabbit': '金钱兔',
    'rave-party-fever': '电音派对'
  };
  // NEXTSPIN电子
  static NEXTSPINGameCode = {
    sLongX3: '龙龙龙',
    sCrazyMnky: '疯狂猴子',
    sDiamond7: '转转7',
    sHighwayB: '老司机',
    sClaFruit7: '经典水果7',
    sShkBoomX2: '蹦迪',
    sHolyGoat: '草泥马',
    sDblMnky: '双倍猴子',
    sJokerKing: '小丑王',
    sTriKfMnky: '功夫猴王',
    sMrHippo: '河马先生',
    sMayaQuest: '玛雅帝国',
    sDrgBlitz: '霹雳神龙',
    sCrazyMkDx: '疯狂猴子贵宾版',
    sLuckyGems: '幸运宝石',
    sTriMnky: '三倍猴子',
    sSuperFor: '喜从天降',
    s7Dragons: '七龙盛世',
    sTaiga88: '虎大哥88',
    aMnkyJump: '跳跳猴',
    sShkThaiX2: '泰会摇',
    sHoney888: '蜜糖888',
    s5ForStar: '运财五福星',
    sBuffK: '牛B',
    sLightnWmn: '电光女神',
    sHotSmash: '火焱爆发',
    sEternalFi: '永恆之火',
    sTwinkleIc: '冰冰乐',
    sPrinCrime: '小丑公主',
    sDolphDive: '海豚奇缘',
    sForestSec: '魔幻之森',
    sGolWest: '黃金西域',
    sCandyBona: '糖糖派对',
    sSweetLava: '熔岩精灵',
    sRickyTyco: '赌圣李奇',
    sForToad: '金蟾蛙',
    sFruitTyc: '水果大亨',
    sSoccerK: '黃金右腳',
    sOceanChes: '深海寻宝',
    aDonkiKong: '爆宝金刚',
    sRoma: '羅馬競技場',
    sCandyXmas: '糖糖圣诞派对',
    sGoldenFa: '发发发',
    fFishParad: '捕鱼天堂',
    sCaiShen: '财神到',
    sRomaII: '羅馬競技場II',
    sGoldenWar: '狂热之战'
  };
  // AESEXY真人
  static AESEXYGameCode = {
    'MX-LIVE-001': '经典百家',
    'MX-LIVE-002': '百家樂',
    'MX-LIVE-003': '保险百家',
    'MX-LIVE-006': '龙虎',
    'MX-LIVE-007': '骰宝',
    'MX-LIVE-009': '轮盘',
    'MX-LIVE-010': '红蓝骰',
    'MX-LIVE-012': 'Extra Andar Bahar',
    'MX-LIVE-014': '泰国骰宝',
    'MX-LIVE-015': '泰国鱼虾蟹',
    'MX-LIVE-016': 'Extra骰宝'
  };
  // PRETTY真人
  static PRETTYGameCode = {
    Baccarat: '百家乐',
    '4Point': '4点',
    Super6: '超6',
    CowCow: '牛牛',
    DragonTiger: '龙虎',
    Tips: '小费'
  };
  // AFB真人
  static AFBSXGameCode = {
    BACCARAT: '百家乐',
    SICBO: '骰宝',
    ROULETTE: '轮盘',
    DRAGON_TIGER: '龙虎',
    XOC_DIA: '色碟',
    BULL_BULL: '牛牛',
    BACCARAT_INSURANCE: '保险百家乐'
  };
  // DB真人
  static DBZRGameCode = {
    BACCARAT: '百家乐',
    SICBO: '骰宝',
    ROULETTE: '轮盘',
    DRAGON_TIGER: '龙虎',
    XOC_DIA: '色碟',
    BULL_BULL: '牛牛',
    BACCARAT_INSURANCE: '保险百家乐'
  };
  // AFB电子
  static AFBDZGameCode = {
    discoJockey: '唱片骑士',
    wildChapo: '野生查波',
    safari: '野生公园',
    alice: '爱丽斯',
    happyFruits: '水果机',
    threeKingdoms: '三国',
    journeyToTheWest: '西游记',
    godOfWealth: '财神',
    highroadKing: '公路之王',
    akira: '阿基拉',
    steamPunk: '蒸汽朋克',
    goodLuck: '好运来',
    wildWest: '狂野西部',
    goldenTemple: '黄金寺',
    pirateTreasure: '海盗的宝藏',
    luckyClover: '幸运四叶草',
    jumpForRichness: '一夜暴富',
    mysteriousPyramid: '探秘金字塔',
    fruitRipe: '果实熟了',
    millionaire: '百万富豪',
    bigBoss: '大老板',
    lucky777: '幸运777',
    luckyFrog: '幸运蛙',
    moneyTree: '摇钱树',
    sobekTreasure: '索贝克的宝藏',
    sweetLove: '甜蜜的爱',
    crazyBomb: '疯狂的炸弹',
    goldBlitz: '疯狂黄金',
    burgerKing: '汉堡王',
    candyCrush: '美味的甜点',
    richestWitch: '富有女巫',
    megaRich: '超级富豪',
    deepLoot: '深海探宝',
    wallowInMoney: '腰缠万贯',
    dice: '骰子',
    crazyFishing: '疯狂捕鱼',
    lasVegasNight: '拉斯维加斯之夜',
    christmasCarnivals: '圣诞嘉年华'
  };
  // SAGaming视讯
  static SAGSXGameCode = {
    bac: '百家乐',
    dtx: '龙虎',
    sicbo: '骰宝',
    rot: '轮盘',
    pokdeng: '博丁',
    andarbahar: '安达巴哈'
  };
  // MT电子
  static MTDZGameCode = {
    PTG2013: '招财推币机',
    PTG0060: '超级大亨',
    PTG0069: 'ATT金皇冠',
    PTG0001: '水果机',
    PTG0010: '水浒传',
    PTG0066: '大满贯水果机',
    PTG0068: '泰山闯天关2',
    PTG0083: '财神到',
    PTG0079: '不倒翁',
    PTG0087: '醉西游',
    PTG0090: '延禧攻略',
    PTG0095: '连环夺宝',
    PTG0098: '三只小猪',
    PTG0097: '招财猫',
    PTG0099: '欲望魅影',
    PTG0096: '绝地求生',
    PTG0101: '埃及艳后',
    PTG0103: '年年有鱼',
    PTG0105: '天空之神',
    PTG0107: '阿拉丁神灯',
    PTG0109: '武媚娘传奇',
    PTG0106: '亿万富翁',
    PTG0115: '萝卜跳一跳',
    PTG0117: '宫心计',
    PTG2001: '恭喜发财',
    PTG2002: '新潘金莲',
    PTG2003: '宫本武藏',
    PTG2004: '武则天',
    PTG2005: '发发发',
    PTG2007: '赏金船长',
    PTG2009: '爱尔兰精灵',
    PTG2010: '水浒传豪华版',
    PTG2011: '金钱豹水果机',
    PTG0118: '疯狂猴子',
    PTG2015: '金刚',
    PTG2016: '福星献舞',
    PTG2017: 'HUGA',
    PTG2018: '上海甜心',
    PTG2019: 'Buffalo Gold',
    PTG2020: '白蛇传',
    PTG2021: '笑弥勒'
  };
  // JOKER电子
  static JOKERGameCode = {
    c96w4rytnamte: '古罗马Deluxe',
    ue8mt39rhzpps: '诅咒Deluxe',
    zowjitp4zqhm1: '疯狂小动物Deluxe',
    bkxno75wqiigs: '拳击Deluxe',
    bsp1s4umpbwsh: '海盗Deluxe',
    b6nuk4djp3zsa: '亡靈節Deluxe',
    '7phhufritkfao': '抢劫Deluxe',
    sco3dugpambhy: '卡塔尔2022',
    pz7wsnombyroh: '巫师Deluxe',
    uygm7axgh91qk: '葉賢Deluxe',
    hj5cb8hhusjjg: '十二星座Deluxe',
    '69xaiyrbo4dae': '狂欢夜',
    '113qm5xnhxoqn': '阿拉丁神灯',
    kia1eetdryo1c: '爱丽思',
    kj4nqm3cfwtng: '亚马逊',
    ie9eti6w4zfcs: '古代神器',
    naagsa5ycfugq: '古埃及',
    '9fz79bw8o1nzw': '天使&恶魔',
    dwhsas15mbcih: '动物派对',
    wfo7bzs95uq7r: '弓箭手',
    '7rw3tfwz11kaw': '北极宝藏',
    sy1jp1qq39gc4: '乐爆彩',
    oqt9p9876m39y: '阿兹特克金字塔',
    j3wngk3efrzn6: '百家乐',
    gn1bc1kqj7gr4: '八卦',
    quofrdenycyyn: '八卦2',
    ne4gq55cpitgg: '魔豆',
    '3uim5ppkiqwk1': '鱼虾蟹',
    xq9ohbyf9m79o: '鸟王争霸:飞鼠御天',
    swt38osdadyhc: '海盗传奇',
    am1mxx7hzs3mg: '二十一点',
    bwwza4umpbwsh: '熊之舞',
    ioheiiqk3xrc1: '圣约之书',
    hf5hx8w9u1q3r: '圣约之书豪华版',
    ur8593z8hu17w: '火烧珍珠',
    tocki7xk7xwq1: '火焰珍珠宾果',
    '86burqb38a9ua': '武士道',
    zygj7oqga9nck: '大財神',
    numgk5mncpogn: '罗杰船长',
    bes8675wqiigs: '宝藏船长',
    qieoeyodyyyoc: '藏宝船长加强版',
    awn5jciusna5c: '藏宝船长连线',
    '1wb58azdhdo6c': '加勒比萨迦',
    r43yaw4okfmfq: '兑现或崩盘',
    n1ydr5mncpogn: '辣椒猎人',
    dkzdo35rcipfs: '中国',
    '7tccifcktqre1': '大富豪',
    k6p1webbrau1e: '財神到',
    iw7az1sm3drhh: '瘋狂水果派對',
    aij68ciusna5c: '哥伦布',
    '4akkze7ywgukq': '加密躁狂者',
    z7k6mqf3z495a: '加密躁狂者宾果',
    gu5rkuknepyrn: '恐龙猎人新王龙觉醒',
    rh8iwwntk3mie: '海豚世界',
    ateqfxp1sqamn: '海豚宝藏',
    '43bx3e7ywgukq': '海豚的珍珠豪华版',
    fqho1inijjfwo: '东海龙王',
    w4ypzw6o48mpq: '龙凤呈祥',
    '3yfmucpss64mk': '龍之火焰',
    dc7sh3dfmjpio: '龙虎',
    p4sygnaebr8y6: '龙传奇',
    '8u9r4tj48chd1': '炸彈王',
    igg7tisz4ukhw: '埃及艳后',
    o7f9ih8t6559e: '武則天',
    srd3xusx3ughr: '进入KTV',
    gz4weo1debywc: '極速發發發',
    x46x869a989x6: '发财财神',
    o3nxzh9o685xq: '飞龙在天',
    k9gz4ebbrau1e: '黄金双龙',
    fk9yoi4wkifrs: '50金狮',
    c1ubk8e1ncebn: '星際獵人',
    cgoty8o9doqtr: '怪物奇谈',
    '35wk8k7eigzk6': '海王',
    pqzthfyibefoo: '海王-金龍崛起',
    nr4sy5uxptssr: '海王2',
    ary5bxi9z165r: '海王2Ex - 好友吧',
    xkhy6baryz7xs: '海王2Ex - 新手',
    qq5ocdypyeboy: '海王2Ex-初手',
    g54rso4yefdrq: '海王2Ex-专手',
    kk8nqm3cfwtng: '海霸',
    '1jeqx59c7ztqg': '海王3',
    '4omkmmpnwqokn': '海绵宝宝',
    ijwp9i6w4zfcs: '龙宫',
    '8d7r1okge7nrk': '大圣闹海',
    b8rzo7uzqt4sw: '金蟾埔鱼',
    st5cmuqnaxycn: '开心捕鱼5',
    nzkseaudcbosc: '李逵劈鱼',
    wi17jwsu4de7c: '摇钱树',
    q15ykdenycyyn: '海王4凤凰传说',
    p91iknyjba8oa: '鱼虾蟹',
    cntbnge6dhqb4: '雷电猎人',
    p63ornyjba8oa: '渔人码头',
    ghxwwonbe3dwe: '五龙吐珠',
    s34czo6ya8dcq: '锦鲤报喜',
    j6j1rkbjfaz1a: '五虎将',
    s6xhiogba5dhe: '足球小子',
    dngdhamgc71gk: '热血足球',
    wykepsq659qp4: '四龙',
    rsjogw1ukbeic: '亚洲四虎',
    qxoindypyeboy: '艺妓',
    '1q36p58phmt6y': '阿拉丁',
    cxawn8y7nejty: '黄金蟹',
    '9w6aa6u5xbhzh': '金龍發發',
    ywozehuuqbazc: '黃金岛',
    '9mqe9bhroi78s': '金猴王',
    jpiuhpbifei1o: '金鸡报喜',
    '7ufj5fcktqre1': '金鲨',
    t656f48j75z6a: '伟大蓝海',
    '55hj8ghaugxj6': '笑彌勒',
    tyqk7hxs694xk: '快樂礦工 ',
    ebudnqj68h6d4: '欢乐节庆',
    jsguaktmfyw1h: '大力神',
    axt5pxf7sk35y: '高速之冠',
    nh9swadbc3use: '高速之冠连线',
    j9nzkkbjfaz1a: '荷鲁斯之眼',
    soojfuqnaxycn: '水果之夏',
    pirtanombyroh: '野蛮世界',
    yr1zy9u9xt6zr: '葫芦',
    ddpg1amgc71gk: '虫虫乐园',
    ahf5icfts455e: '金福幸运',
    de6g635rcipfs: '金瓶梅',
    gkubyu4cjibrg: '疯狂小丑',
    '8kzbot4rew7ds': '西游记',
    xbxy1yegyhnyk: '森林岛冒险记',
    satj3o6ya8dcq: '至尊珠宝',
    hhi3irrt14m3e: '包青天',
    '1ynmp4xq79sq4': '快乐彩',
    '9rhatbw8o1nzw': '经典德比赛马',
    q7xowyybyadoe: '功夫龙',
    wcaadzg74mj7y: '鹰夫人',
    xico36z59dh9h: '劳拉探秘',
    '1ru5x5zx7us6r': '闪电神',
    wtupmzq14xepn: '舞獅共舞',
    xmzfobaryz7xs: '海洋之王',
    wpu7pzg74mj7y: '吉鼓发财',
    dhdirsn3m3xia: '财神到',
    ef1uyxt98o6ur: '财神连线',
    '3hj4fkfji4z4a': '财神发发发2',
    '7f9h9fwz11kaw': '幸运女神护身符豪华版',
    '59d3fgr3u4x3a': '幸运熊猫',
    tbfxuhxs694xk: '财富熊猫',
    '7cz37fritkfao': '幸运鸡',
    sgkhbusx3ughr: '幸运轮盘',
    u3qp8s6a58xp6: '单人幸运轮盘',
    j8xs6ptzgo41w: '幸运转盘',
    jtid4pjkfcwn1: '魔术师之旅',
    '9xpa7brfxj7zo': '媽媽咪呀',
    tqi9778i7mi6o: '邁阿密',
    fdif1wksk7kfk: '搖錢樹',
    oajk3h9o685xq: '金库',
    k3anse3yrrunq: '西部警长',
    frgioi1zktkww: '决战天下',
    '1g46b5zx7us6r': '战无不胜',
    '9upe5bm4xph81': '齊天大聖',
    pfgtofoznokow: '齐天大圣2',
    mj6n6j3rrwpbs: '猴子爬树',
    mp71hjbfdepto: '猴子爬树3D',
    bcizh7dipjiso: '花木蘭',
    m94wkgy3daxta: '神秘之沙',
    cz3wgrounyetc: '海王宾果',
    '5ii9zgw5unc3h': '海王宝藏',
    '99bzr6d3x39za': '你属什么',
    '84igeq3a8r9d6': '金块猎人',
    kf41ymtxfos1r: '海洋天堂',
    '4tyxfmpnwqokn': '八角形宝石',
    gsttgo1debywc: '八角形宝石2',
    '79mafnrjt48aa': '潘金莲',
    eqducxb7ge3uy: '熊猫大侠',
    u6d7fsg355x7a: '黑豹的月亮',
    s77hiogba5dhe: '蟠桃会',
    d8cso3u8ct1iw: '鳳凰轉世888',
    i6mhf1gj358ha: '航海王',
    ykmny95hxi38g: '博町',
    hcu3p8r71kj3y: '超级巨星',
    gd3rn1kqj7gr4: '女王',
    '8nsbhokge7nrk': '尼罗河女王',
    d5qfgs4amfxf6: '瘋狂旋轉',
    pxhw1fyibefoo: 'RNG战争',
    i4rc816e388c6: '羅賓漢',
    '5m6k9j7rwspjs': '羅馬竞技场',
    '4d5kdkpqi6sk4': '热血探险记',
    xtpy4bx49xhx1: '探险非洲',
    ufc6t3z8hu17w: '圣诞惊喜',
    hxb3p8r71kj3y: '骰宝',
    z1pc5tp4zqhm1: '银子弹',
    ruufkzk1kpefn: '银子弹连线',
    ww3a8wsu4de7c: '夏日水果',
    hytjdgc6u6qj4: '泼水节',
    jbzd1cjsgh4dk: '斯巴达',
    hmnjx8hhusjjg: '速度女王',
    rwyiew1ukbeic: '超级赛马',
    znxcuoqga9nck: '超级龙王',
    '4za4jeidwno4c': '超龙3D',
    byz81hmsq748k: '至尊财神',
    dxxsh3dfmjpio: '太上老君',
    '856dgq3a8r9d6': '皇家同花顺',
    '5864tji8w113w': '泰国风情',
    rqaonzn7kjjiy: '四大发明',
    bzgza4umpbwsh: '三太子传奇',
    fwria11mjbrwh: '三国争霸',
    '8rqwot18etnuw': '雷神',
    xxwi1byf9m79o: '虚拟百家乐',
    foff4ikkjprr1: '水浒传',
    wjopxz6h5zjpg: '狼人',
    u17q53q45xcp1: '白蛇传',
    '1wt58azdhdo6c': '仙女百搭',
    q9gi4yybyadoe: '百搭大熊猫',
    pxcw1fyibefoo: '荒野守护者',
    itzp5iqk3xrc1: '百搭的精神',
    bmr8675wqiigs: '可爱女巫',
    c41bsraonrmbq: '玄圃联欢',
    '9ii7s6u5xbhzh': '世界之树',
    aodmmxp1sqamn: '招財進寶',
    wndpdsq659qp4: '僵尸国土',
    g8awyu1xjtgwr: '紅火暴擊',
    e4ndsc3ogr5dq: '焰龍88',
    m1xn4je4dccb1: '古代寶石',
    kqm1cmbpret1y: '小丑珠寶',
    qn3ynneqbhgy4: '三虎稱霸',
    knjnnkjqfhsn4: '888龍傳說',
    '5ypkuepgw61kk': '五行水 送福',
    yxdzc9d7qj3zy: '五行火 送福',
    '4eekxm7cistkg': '龍之領域',
    r8oiyz19mtqir: '幸運小丑',
    ha1jzrho1gmjq: '瑪雅寶石',
    '3erm9p7wssiks': '命運之火',
    y5n8sh5oqf58q: '虎穴',
    ofy9b9z99u69r: '火龍帝國',
    '93ine65axf986': '拳击',
    b5ggg45epfzg6: '超級星球',
    '6c5apdrpokbay': '财神兴盛',
    ipz77igi3mfho: '甜蜜冬季',
    b1cnw7mkppwg1: '玩命之徒',
    gqotnunpejbwy: '財富佳節',
    '6o5emdcnoqyen': '阿茲台克神廟',
    '5cx47jffukp3o': '八寶錢袋',
    uwf5zss55dc7h: '葉賢',
    '3fx69pizs144w': '幸运条纹',
    uafejs6a58xp6: '赏金猎人',
    nqyun5dpcjtsy: '网络竞赛',
    jzpssktmfyw1h: '十二星座',
    '7b6c7rcs16kjk': '海洋浪花',
    fn6yhwksk7kfk: '辣椒獵人 宾果',
    ezjsgctugyauc: '大財神 宾果',
    pd6rhresnhkbk: '少林',
    exesnxb7ge3uy: '鬼屋',
    cd4rcge6dhqb4: '亡靈節',
    wr5axzs95uq7r: '森林宝藏',
    hb4cpgc6u6qj4: '西方神話',
    c85wq8o9doqtr: '巫师',
    x5ikj69a989x6: '黃金獵者',
    '96k1k6d3x39za': '森林探险记',
    cuarr8e1ncebn: '熱帶水果',
    a7q65cfts455e: '拳击2',
    d4fyes4amfxf6: '凤凰',
    kdn8ckjqfhsn4: '法老之墓',
    qd1fcneqbhgy4: '无尽',
    kxyznmbpret1y: '魔法森林',
    ggutqu1xjtgwr: '绿洲',
    e5jgac3ogr5dq: '欢迎来到农场主的财富',
    mur8wje4dccb1: '天方夜谭',
    y6q14hdtq35ze: '海滨嘉年华',
    abkqpqp6z66m4: '圣诞老人工作坊',
    '5trxijc4uqcj1': '诅咒',
    '6jupbdhctsbeg': '古罗马',
    '4jdxbm7cistkg': '护身符',
    '47g95efbw4u4e': '抢劫',
    yqe1n9d7qj3zy: '三国2',
    '5bgx7epgw61kk': '皇后2',
    '6po7ddrpokbay': '爱丽丝移游仙境',
    ichauigi3mfho: '番 摊',
    '6topfdcnoqyen': '泰式比高低',
    wh6a5wgt45m7e: '百家乐宝贝',
    zwi3zqi5znh5h: '虚拟骰宝',
    emmgyx3h8w3dg: '决战篮球',
    iy6e7wqs49kpk: '百练赛-数字王',
    q5tfayayyrdyq: '虚拟轮盘',
    '5pr9ujffukp3o': '大小',
    q8uiqdoxbogor: '越南鱼虾蟹',
    owj48hzu6da9c: '足球狂热',
    rg5oqz19mtqir: '巴厘岛',
    h33c3rho1gmjq: '芝加哥街头',
    y4jnah5oqf58q: '黑帮',
    b4pde45epfzg6: '精灵2',
    texkt79w6ziqs: '悟空',
    '3pc6upfiiki4o': '疯狂小动物',
    orm4x9z99u69r: '英雄联盟',
    e9qs4cbtga5ue: '財神爺',
    c53raraonrmbq: '潘金莲2',
    '3jxqtp7wssiks': '新白娘子',
    ape6dxf7sk35y: '罗马传奇',
    '67s75yrbo4dae': '满者伯夷',
    '4bnxpkpqi6sk4': '海盗',
    uh4amsg355x7a: '水果乐园',
    thn5m48j75z6a: '超级幸运象',
    '4ib98eidwno4c': '生肖宾果'
  };
  // 完美真人
  static WMZRGameCode = {
    101: '百家乐',
    102: '龙虎',
    103: '轮盘',
    104: '骰宝',
    105: '牛牛',
    107: '番摊',
    108: '色碟',
    110: '鱼虾蟹',
    128: '安達巴哈'
  };
  // 立马高视讯
  static LMGGameNames = helperLMG.LMGGameNames;
  // IG电子
  static IgGameNames = helperIG.IgGameNames;
  // 开元棋牌
  static KYQiPaiGames = {
    '620': '德州扑克',
    '720': '二八杠',
    '830': '抢庄牛牛',
    '220': '炸金花',
    '860': '三公',
    '900': '押庄龙虎',
    '600': '21 点',
    '870': '通比牛牛',
    // '880': '欢乐红包', // 已下线
    '230': '极速炸金花',
    '730': '抢庄牌九',
    '630': '十三水',
    // '380': '幸运五张', // 已下线
    '610': '斗地主',
    // '390': '射龙门', // 已下线
    '910': '百家乐',
    '920': '森林舞会',
    '930': '百人牛牛',
    '1950': '万人炸金花',
    '650': '血流成河',
    '890': '看牌抢庄牛牛',
    '740': '二人麻将',
    '1350': '幸运转盘',
    '1940': '金鲨银鲨',
    '1960': '奔驰宝马',
    '1980': '百人骰宝',
    '1810': '单挑牛牛',
    '1990': '炸金牛',
    '1850': '押宝抢庄牛牛',
    '510': '红包捕鱼',
    '1660': '血战到底',
    '1355': '搏一搏',
    '1610': '幸运夺宝',
    '1970': '五星宏辉',
    '1860': '赌场扑克',
    '1370': '港式梭哈',
    '1690': '血战骰宝',
    '1930': '鱼虾蟹',
    '8130': '跑得快',
    '1890': '水果机',
    '520': '李逵劈鱼',
    '840': '疯狂抢庄牛牛',
    '950': '红黑大战'
  };
  static KYQiPaiGameRooms = {
    3600: '德州扑克新手房',
    3601: '德州扑克初级房',
    3602: '德州扑克中级房',
    3603: '德州扑克高级房',
    3700: '德州扑克财大气粗房',
    3701: '德州扑克腰缠万贯房',
    3702: '德州扑克挥金如土房',
    3703: '德州扑克富贵逼人房',
    7201: '二八杠体验房',
    7202: '二八杠初级房',
    7203: '二八杠中级房',
    7204: '二八杠高级房',
    7205: '二八杠至尊房',
    7206: '二八杠王者房',
    8301: '抢庄牛牛体验房',
    8302: '抢庄牛牛初级房',
    8303: '抢庄牛牛中级房',
    8304: '抢庄牛牛高级房',
    8305: '抢庄牛牛至尊房',
    8306: '抢庄牛牛王者房',
    2201: '炸金花体验房',
    2202: '炸金花初级房',
    2203: '炸金花中级房',
    2204: '炸金花高级房',
    8601: '三公体验房',
    8602: '三公初级房',
    8603: '三公中级房',
    8604: '三公高级房',
    8605: '三公至尊房',
    8606: '三公王者房',
    9001: '龙虎体验房',
    9002: '龙虎初级房',
    9003: '龙虎中级房',
    9004: '龙虎高级房',
    6001: '21点体验房',
    6002: '21点初级房',
    6003: '21点中级房',
    6004: '21点高级房',
    8701: '通比牛牛体验房',
    8702: '通比牛牛初级房',
    8703: '通比牛牛中级房',
    8704: '通比牛牛高级房',
    8705: '通比牛牛至尊房',
    8706: '通比牛牛王者房',
    8801: '欢乐红包体验房',
    8802: '欢乐红包初级房',
    8803: '欢乐红包中级房',
    8804: '欢乐红包高级房',
    2301: '极速炸金花新手房',
    2302: '极速炸金花初级房',
    2303: '极速炸金花中级房',
    2304: '极速炸金花高级房',
    7301: '抢庄牌九新手房',
    7302: '抢庄牌九初级房',
    7303: '抢庄牌九中级房',
    7304: '抢庄牌九高级房',
    7305: '抢庄牌九至尊房',
    7306: '抢庄牌九王者房',
    6101: '斗地主体验房',
    6102: '斗地主初级房',
    6103: '斗地主中级房',
    6104: '斗地主高级房',
    6301: '十三水常规场新手房',
    6302: '十三水常规场初级房',
    6303: '十三水常规场中级房',
    6304: '十三水常规场高级房',
    6305: '十三水极速场新手房',
    6306: '十三水极速场初级房',
    6307: '十三水极速场中级房',
    6308: '十三水极速场高级房',
    3801: '幸运五张体验房',
    3802: '幸运五张初级房',
    3803: '幸运五张中级房',
    3804: '幸运五张高级房',
    3901: '射龙门经典房',
    3902: '射龙门暴击房',
    9101: '百家乐体验房',
    9102: '百家乐初级房',
    9103: '百家乐中级房',
    9104: '百家乐高级房',
    9201: '森林舞会体验房',
    9202: '森林舞会初级房',
    9203: '森林舞会中级房',
    9204: '森林舞会高级房',
    9301: '百人牛牛体验房',
    9302: '百人牛牛初级房',
    9303: '百人牛牛中级房',
    9304: '百人牛牛高级房',
    19501: '万人炸金花体验房',
    19502: '万人炸金花初级房',
    19503: '万人炸金花中级房',
    19504: '万人炸金花高级房',
    6501: '血流成河体验房',
    6502: '血流成河初级房',
    6503: '血流成河中级房',
    6504: '血流成河高级房',
    8901: '看牌抢庄牛牛体验房',
    8902: '看牌抢庄牛牛初级房',
    8903: '看牌抢庄牛牛中级房',
    8904: '看牌抢庄牛牛高级房',
    8905: '看牌抢庄牛牛至尊房',
    8906: '看牌抢庄牛牛王者房',
    7401: '二人麻将体验房',
    7402: '二人麻将初级房',
    7403: '二人麻将中级房',
    7404: '二人麻将高级房',
    13501: '幸运转盘',
    13502: '幸运转盘',
    13503: '幸运转盘',
    19401: '金鲨银鲨体验房',
    19402: '金鲨银鲨初级房',
    19403: '金鲨银鲨中级房',
    19404: '金鲨银鲨高级房',
    19601: '奔驰宝马体验房',
    19602: '奔驰宝马初级房',
    19603: '奔驰宝马中级房',
    19604: '奔驰宝马高级房',
    19801: '百人骰宝体验房',
    19802: '百人骰宝初级房',
    19803: '百人骰宝中级房',
    19804: '百人骰宝高级房',
    18101: '单挑牛牛体验房',
    18102: '单挑牛牛初级房',
    18103: '单挑牛牛中级房',
    18104: '单挑牛牛高级房',
    18105: '单挑牛牛至尊房',
    18106: '单挑牛牛王者房',
    19901: '炸金牛体验房',
    19902: '炸金牛初级房',
    19903: '炸金牛中级房',
    19904: '炸金牛高级房',
    19905: '炸金牛至尊房',
    19906: '炸金牛王者房',
    18501: '押宝抢庄牛牛体验房',
    18502: '押宝抢庄牛牛初级房',
    18503: '押宝抢庄牛牛中级房',
    18504: '押宝抢庄牛牛高级房',
    18505: '押宝抢庄牛牛至尊房',
    18506: '押宝抢庄牛牛王者房',
    5101: '红包捕鱼初级房',
    5102: '红包捕鱼中级房',
    5103: '红包捕鱼高级房',
    16601: '血战到底体验房',
    16602: '血战到底初级房',
    16603: '血战到底中级房',
    16604: '血战到底高级房',
    19701: '五星宏辉体验房',
    19702: '五星宏辉初级房',
    19703: '五星宏辉中级房',
    19704: '五星宏辉高级房',
    18601: '赌场扑克体验房',
    18602: '赌场扑克初级房',
    18603: '赌场扑克中级房',
    18604: '赌场扑克高级房',
    13701: '港式梭哈新手房',
    13702: '港式梭哈初级房',
    13703: '港式梭哈中级房',
    13704: '港式梭哈高级房',
    16901: '血战骰宝体验房',
    16902: '血战骰宝初级房',
    16903: '血战骰宝中级房',
    16904: '血战骰宝高级房',
    18901: '水果机体验房',
    18902: '水果机初级房',
    18903: '水果机中级房',
    18904: '水果机高级房',
    16101: '白银宝箱房间',
    16102: '黄金宝箱房间',
    16103: '铂金宝箱房间',
    16104: '钻石宝箱房间',
    19301: '鱼虾蟹体验房',
    19302: '鱼虾蟹初级房',
    19303: '鱼虾蟹中级房',
    19304: '鱼虾蟹高级房',
    81301: '跑得快体验房',
    81302: '跑得快初级房',
    81303: '跑得快中级房',
    81304: '跑得快高级房'
  };
  // 乐游棋牌
  static LYQiPaiGameRooms = {
    '620_3600': '德州扑克新手房',
    '620_3601': '德州扑克初级房',
    '620_3602': '德州扑克中级房',
    '620_3603': '德州扑克高级房',
    '620_3700': '德州扑克财大气粗房',
    '620_3701': '德州扑克腰缠万贯房',
    '620_3702': '德州扑克挥金如土房',
    '620_3703': '德州扑克富贵逼人房',
    '720_7201': '二八杠新手房',
    '720_7202': '二八杠初级房',
    '720_7203': '二八杠中级房',
    '720_7204': '二八杠高级房',
    '720_7205': '二八杠至尊房',
    '720_7206': '二八杠王者房',
    '830_8301': '抢庄牛牛新手房',
    '830_8302': '抢庄牛牛初级房',
    '830_8303': '抢庄牛牛中级房',
    '830_8304': '抢庄牛牛高级房',
    '830_8305': '抢庄牛牛至尊房',
    '830_8306': '抢庄牛牛王者房',
    '830_8307': '抢庄牛牛咪牌场新手房',
    '830_8308': '抢庄牛牛咪牌场初级房',
    '830_8309': '抢庄牛牛咪牌场中级房',
    '830_8310': '抢庄牛牛咪牌场高级房',
    '830_8311': '抢庄牛牛咪牌场至尊房',
    '830_8312': '抢庄牛牛咪牌场王者房',
    '220_2201': '炸金花新手房',
    '220_2202': '炸金花初级房',
    '220_2203': '炸金花中级房',
    '220_2204': '炸金花高级房',
    '860_8601': '三公新手房',
    '860_8602': '三公初级房',
    '860_8603': '三公中级房',
    '860_8604': '三公高级房',
    '860_8605': '三公至尊房',
    '860_8606': '三公王者房',
    '900_9001': '龙虎斗新手房',
    '900_9002': '龙虎斗初级房',
    '900_9003': '龙虎斗中级房',
    '900_9004': '龙虎斗高级房',
    '600_6001': '21 点新手房',
    '600_6002': '21 点初级房',
    '600_6003': '21 点中级房',
    '600_6004': '21 点高级房',
    '600_6005': '21 点至尊房',
    '600_6006': '21 点王者房',
    '600_6007': '21 点单挑场新手房',
    '600_6008': '21 点单挑场初级房',
    '600_6009': '21 点单挑场中级房',
    '600_6010': '21 点单挑场高级房',
    '600_6011': '21 点单挑场至尊房',
    '600_6012': '21 点单挑场王者房',
    '870_8701': '通比牛牛新手房',
    '870_8702': '通比牛牛初级房',
    '870_8703': '通比牛牛中级房',
    '870_8704': '通比牛牛高级房',
    '870_8705': '通比牛牛至尊房',
    '870_8706': '通比牛牛王者房',
    '230_2301': '极速炸金花新手房',
    '230_2302': '极速炸金花初级房',
    '230_2303': '极速炸金花中级房',
    '230_2304': '极速炸金花高级房',
    '730_7301': '抢庄牌九新手房',
    '730_7302': '抢庄牌九初级房',
    '730_7303': '抢庄牌九中级房',
    '730_7304': '抢庄牌九高级房',
    '730_7305': '抢庄牌九至尊房',
    '730_7306': '抢庄牌九王者房',
    '610_6101': '斗地主新手房',
    '610_6102': '斗地主初级房',
    '610_6103': '斗地主中级房',
    '610_6104': '斗地主高级房',
    '610_6105': '斗地主至尊房',
    '610_6106': '斗地主王者房',
    '630_6301': '十三水常规场新手房',
    '630_6302': '十三水常规场初级房',
    '630_6303': '十三水常规场中级房',
    '630_6304': '十三水常规场高级房',
    '630_6305': '十三水极速场新手房',
    '630_6306': '十三水极速场初级房',
    '630_6307': '十三水极速场中级房',
    '630_6308': '十三水极速场高级房',
    '380_3801': '幸运五张新手房',
    '380_3802': '幸运五张初级房',
    '380_3803': '幸运五张中级房',
    '380_3804': '幸运五张高级房',
    '390_3901': '射龙门经典房',
    '390_3902': '射龙门暴击房',
    '890_8901': '看三张抢庄牛牛新手房',
    '890_8902': '看三张抢庄牛牛初级房',
    '890_8903': '看三张抢庄牛牛中级房',
    '890_8904': '看三张抢庄牛牛高级房',
    '890_8905': '看三张抢庄牛牛至尊房',
    '890_8906': '看三张抢庄牛牛王者房',
    '910_9101': '百家乐新手房',
    '910_9102': '百家乐初级房',
    '910_9103': '百家乐中级房',
    '910_9104': '百家乐高级房',
    '930_9301': '百人牛牛新手房',
    '930_9302': '百人牛牛初级房',
    '930_9303': '百人牛牛中级房',
    '930_9304': '百人牛牛高级房',
    '950_9501': '红黑大战新手房',
    '950_9502': '红黑大战初级房',
    '950_9503': '红黑大战中级房',
    '950_9504': '红黑大战高级房',
    '740_7400': '二人麻将体验房',
    '740_7401': '二人麻将新手房',
    '740_7402': '二人麻将初级房',
    '740_7403': '二人麻将中级房',
    '740_7404': '二人麻将高级房',
    '740_7405': '二人麻将至尊房',
    '920_9201': '森林舞会新手房',
    '920_9202': '森林舞会初级房',
    '920_9203': '森林舞会中级房',
    '920_9204': '森林舞会高级房',
    '510_5101': '捕鱼大作战渔村港口',
    '510_5102': '捕鱼大作战傲来渔场',
    '510_5103': '捕鱼大作战东海龙宫',
    '8150_81501': '看四张抢庄牛牛新手房',
    '8150_81502': '看四张抢庄牛牛初级房',
    '8150_81503': '看四张抢庄牛牛中级房',
    '8150_81504': '看四张抢庄牛牛高级房',
    '8150_81505': '看四张抢庄牛牛至尊房',
    '8150_81506': '看四张抢庄牛牛王者房',
    '8180_81801': '宝石消消乐',
    '8120_81201': '血战到底新手房',
    '8120_81202': '血战到底初级房',
    '8120_81203': '血战到底中级房',
    '8120_81204': '血战到底高级房',
    '8120_81205': '血战到底至尊房',
    '8120_81206': '血战到底王者房',
    '8160_81601': '癞子牛牛新手房',
    '8160_81602': '癞子牛牛初级房',
    '8160_81603': '癞子牛牛中级房',
    '8160_81604': '癞子牛牛高级房',
    '8160_81605': '癞子牛牛至尊房',
    '8160_81606': '癞子牛牛王者房',
    '8210_82101': '搏一搏',
    '8130_81301': '跑得快新手房',
    '8130_81302': '跑得快初级房',
    '8130_81303': '跑得快中级房',
    '8130_81304': '跑得快高级房',
    '8130_81305': '跑得快至尊房',
    '8130_81306': '跑得快王者房',
    '8190_81901': '万人推筒子新手房',
    '8190_81902': '万人推筒子初级房',
    '8190_81903': '万人推筒子中级房',
    '8190_81904': '万人推筒子高级房',
    '8200_82001': '抢庄选三张，三公场新手房',
    '8200_82002': '抢庄选三张，三公场初级房',
    '8200_82003': '抢庄选三张，三公场中级房',
    '8200_82004': '抢庄选三张，三公场高级房',
    '8200_82005': '抢庄选三张，三公场至尊房',
    '8200_82006': '抢庄选三张，三公场王者房',
    '8200_82007': '抢庄选三张，金花场新手房',
    '8200_82008': '抢庄选三张，金花场初级房',
    '8200_82009': '抢庄选三张，金花场中级房',
    '8200_82010': '抢庄选三张，金花场高级房',
    '8200_82011': '抢庄选三张，金花场至尊房',
    '8200_82012': '抢庄选三张，金花场王者房',
    '8220_82201': '抢红包 20 元房',
    '8220_82202': '抢红包 100 元房',
    '8220_82203': '抢红包 500 元房',
    '8220_82204': '抢红包 3000 元房',
    '8220_82205': '抢红包 15000 元房',
    '8220_82206': '抢红包 60000 元房',
    '8230_82301': '百万红包',
    '940_9401': '金鲨银鲨体验房',
    '940_9402': '金鲨银鲨初级房',
    '940_9403': '金鲨银鲨中级房',
    '940_9404': '金鲨银鲨高级房',
    '8260_82601': '头号玩家新手房',
    '8260_82602': '头号玩家初级房',
    '8260_82603': '头号玩家中级房',
    '8260_82604': '头号玩家高级房',
    '8260_82605': '头号玩家至尊房',
    '8260_82606': '头号玩家王者房',
    '8270_82701': '欢乐炸金花新手房',
    '8270_82702': '欢乐炸金花初级房',
    '8270_82703': '欢乐炸金花中级房',
    '8270_82704': '欢乐炸金花高级房',
    '8270_82705': '欢乐炸金花至尊房',
    '8270_82706': '欢乐炸金花王者房',
    '8280_82801': '好友包房抢庄牛牛',
    '8250_82501': '豪车漂移新手房',
    '8250_82502': '豪车漂移初级房',
    '8250_82503': '豪车漂移中级房',
    '8250_82504': '豪车漂移高级房',
    '8110_81101': '血流成河新手房',
    '8110_81102': '血流成河初级房',
    '8110_81103': '血流成河中级房',
    '8110_81104': '血流成河高级房',
    '8110_81105': '血流成河至尊房',
    '8110_81106': '血流成河王者房',
    '8310_83101': '财神到新手房',
    '8310_83102': '财神到初级房',
    '8310_83103': '财神到中级房',
    '8310_83104': '财神到高级房',
    '8310_83105': '财神到至尊房',
    '8310_83106': '财神到王者房',
    '8140_81401': '水果机新手房',
    '8140_81402': '水果机初级房',
    '8140_81403': '水果机中级房',
    '8140_81404': '水果机高级房',
    '8340_83401': '十点半新手房',
    '8340_83402': '十点半初级房',
    '8340_83403': '十点半中级房',
    '8340_83404': '十点半高级房',
    '8340_83405': '十点半至尊房',
    '8340_83406': '十点半王者房',
    '8320_83201': '黑红梅方新手房',
    '8320_83202': '黑红梅方初级房',
    '8320_83203': '黑红梅方中级房',
    '8320_83204': '黑红梅方高级房',
    '8330_83301': '极速赛车新手房',
    '8330_83302': '极速赛车初级房',
    '8330_83303': '极速赛车中级房',
    '8330_83304': '极速赛车高级房',
    '8410_84101': '赌场扑克新手房',
    '8410_84102': '赌场扑克初级房',
    '8410_84103': '赌场扑克中级房',
    '8410_84104': '赌场扑克高级房',
    '8410_84105': '赌场扑克至尊房',
    '8410_84106': '赌场扑克王者房',
    '8360_83601': '古怪猴子体验房',
    '8360_83602': '古怪猴子初级房',
    '8360_83603': '古怪猴子中级房',
    '8360_83604': '古怪猴子至尊房',
    '8360_83605': '古怪猴子王者房',
    '8440_84401': '港式梭哈新手房',
    '8440_84402': '港式梭哈初级房',
    '8440_84403': '港式梭哈中级房',
    '8440_84404': '港式梭哈高级房',
    '8420_82001': '上庄百人牛牛三倍场',
    '8420_82002': '上庄百人牛牛五倍场',
    '8420_82003': '上庄百人牛牛十倍场',
    '8510_85101': '大话骰斋劈双人新手房',
    '8510_85102': '大话骰斋劈双人初级房',
    '8510_85103': '大话骰斋劈双人中级房',
    '8510_85104': '大话骰斋劈双人高级房',
    '8510_85105': '大话骰斋劈双人至尊房',
    '8510_85106': '大话骰斋劈双人王者房',
    '8510_85107': '大话骰斋劈多人新手房',
    '8510_85108': '大话骰斋劈多人初级房',
    '8510_85109': '大话骰斋劈多人中级房',
    '8510_85110': '大话骰斋劈多人高级房',
    '8510_85111': '大话骰斋劈多人至尊房',
    '8510_85112': '大话骰斋劈多人王者房',
    '8450_84501': '骰宝抢庄牛牛新手房',
    '8450_84502': '骰宝抢庄牛牛初级房',
    '8450_84503': '骰宝抢庄牛牛中级房',
    '8450_84504': '骰宝抢庄牛牛高级房',
    '8450_84505': '骰宝抢庄牛牛至尊房',
    '8450_84506': '骰宝抢庄牛牛王者房',
    '8460_84601': '骰宝抢庄三公新手房',
    '8460_84602': '骰宝抢庄三公初级房',
    '8460_84603': '骰宝抢庄三公中级房',
    '8460_84604': '骰宝抢庄三公高级房',
    '8460_84605': '骰宝抢庄三公至尊房',
    '8460_84606': '骰宝抢庄三公王者房',
    '8470_84701': '骰宝通比三公新手房',
    '8470_84702': '骰宝通比三公初级房',
    '8470_84703': '骰宝通比三公中级房',
    '8470_84704': '骰宝通比三公高级房',
    '8470_84705': '骰宝通比三公至尊房',
    '8480_84801': '骰宝红黑单双新手房',
    '8480_84802': '骰宝红黑单双初级房',
    '8480_84803': '骰宝红黑单双中级房',
    '8480_84804': '骰宝红黑单双高级房',
    '8480_84805': '骰宝红黑单双至尊房',
    '8480_84806': '骰宝红黑单双王者房',
    '8490_84901': '骰宝血战到底新手房',
    '8490_84902': '骰宝血战到底初级房',
    '8490_84903': '骰宝血战到底中级房',
    '8490_84904': '骰宝血战到底高级房',
    '8490_84905': '骰宝血战到底至尊房',
    '8490_84906': '骰宝血战到底王者房',
    '8500_85001': '大话骰 067 双人新手房',
    '8500_85002': '大话骰 067 双人初级房',
    '8500_85003': '大话骰 067 双人中级房',
    '8500_85004': '大话骰 067 双人高级房',
    '8500_85005': '大话骰 067 双人至尊房',
    '8500_85006': '大话骰 067 双人王者房',
    '8500_85007': '大话骰 067 多人新手房',
    '8500_85008': '大话骰 067 多人初级房',
    '8500_85009': '大话骰 067 多人中级房',
    '8500_85010': '大话骰 067 多人高级房',
    '8500_85011': '大话骰 067 多人至尊房',
    '8500_85012': '大话骰 067 多人王者房',
    '8282_82821': '好友包房德州扑克',
    '8680_86801': '二人牛牛新手房',
    '8680_86802': '二人牛牛初级房',
    '8680_86803': '二人牛牛中级房',
    '8680_86804': '二人牛牛高级房',
    '8680_86805': '二人牛牛至尊房',
    '8680_86806': '二人牛牛王者房',
    '8620_86201': '骰子牛牛新手房',
    '8620_86202': '骰子牛牛初级房',
    '8620_86203': '骰子牛牛中级房',
    '8620_86204': '骰子牛牛高级房',
    '8620_86205': '骰子牛牛至尊房',
    '8620_86206': '骰子牛牛王者房'
  };
  static LYQiPaiGames = {
    '620': '德州扑克',
    '720': '二八杠',
    '830': '抢庄牛牛',
    '220': '炸金花',
    '860': '三公',
    '900': '龙虎斗',
    '600': '21 点', // 11
    '870': '通比牛牛',
    '230': '极速炸金花',
    '730': '抢庄牌九',
    '630': '十三水',
    '610': '斗地主',
    '890': '看三张抢庄牛牛',
    '910': '百家乐', // 11
    '950': '红黑大战',
    '740': '二人麻将',
    '930': '百人牛牛',
    '510': '捕鱼大作战', // 捕鱼类
    '8120': '血战到底',
    '920': '森林舞会',
    '8150': '看四张抢庄牛牛',
    '8180': '宝石消消乐', // 电子类
    '8160': '癞子牛牛',
    '8210': '搏一搏', // （无独立入口）
    '8130': '跑得快',
    '8190': '万人推筒子',
    '8200': '抢庄选三张',
    '8220': '抢红包',
    '8230': '百万红包', // （暂不开启）
    '940': '金鲨银鲨',
    '8260': '头号玩家',
    '8270': '欢乐炸金花',
    '8280': '好友包房',
    '8250': '豪车漂移',
    '8281': '好友包房抢庄牛牛',
    '8110': '血流成河',
    '8310': '财神到',
    '8140': '水果机',
    '8410': '赌场扑克',
    '8340': '十点半',
    '8330': '极速赛车',
    '8320': '黑红梅方',
    '8360': '古怪猴子',
    '8440': '港式梭哈',
    '8420': '上庄百人牛牛',
    '8510': '大话骰斋劈',
    '8450': '骰宝抢庄牛牛',
    '8460': '骰宝抢庄三公',
    '8470': '骰宝通比三公',
    '8480': '骰宝红黑单双',
    '8490': '骰宝血战到底',
    '8500': '大话骰067',
    '8282': '好友包房德州扑克',
    '8680': '二人牛牛',
    '8620': '骰子牛牛'
  };
  // 大富翁棋牌
  static DFWQiPaiGames = {
    220: '炸金花',
    600: '21点',
    630: '十三水',
    620: '德州扑克',
    680: '斗地主',
    720: '二八杠',
    730: '抢庄牌九',
    830: '抢庄牛牛',
    860: '三公',
    890: '看三张',
    900: '押庄龙虎',
    910: '百家乐',
    920: '森林舞会',
    3890: '看四张抢庄牛牛'
  };
  static DFWQiPaiGameRooms = {
    '2201': '炸金花新手房',
    '2202': '炸金花初级房',
    '2203': '炸金花中级房',
    '2204': '炸金花高级房',
    '3600': '德州扑克新手房',
    '3601': '德州扑克初级房',
    '3602': '德州扑克中级房',
    '3603': '德州扑克高级房',
    '6001': '21 点新手房',
    '6002': '21 点初级房',
    '6003': '21 点中级房',
    '6004': '21 点高级房',
    '6301': '十三水常规场新手房',
    '6302': '十三水常规场初级房',
    '6303': '十三水常规场中级房',
    '6304': '十三水常规场高级房',
    '6305': '十三水极速场新手房',
    '6306': '十三水极速场初级房',
    '6307': '十三水极速场中级房',
    '6308': '十三水极速场高级房',
    '6801': '斗地主新手房',
    '6802': '斗地主初级房',
    '6803': '斗地主中级房',
    '6804': '斗地主高级房',
    '6805': '斗地主王者房',
    '6806': '斗地主至尊房',
    '7201': '二八杠新手房',
    '7202': '二八杠初级房',
    '7203': '二八杠中级房',
    '7204': '二八杠高级房',
    '7205': '二八杠至尊房',
    '7206': '二八杠王者房',
    '7301': '抢庄牌九新手房',
    '7302': '抢庄牌九初级房',
    '7303': '抢庄牌九中级房',
    '7304': '抢庄牌九高级房',
    '7305': '抢庄牌九至尊房',
    '7306': '抢庄牌九王者房',
    '8301': '抢庄牛牛新手房',
    '8302': '抢庄牛牛初级房',
    '8303': '抢庄牛牛中级房',
    '8304': '抢庄牛牛高级房',
    '8305': '抢庄牛牛至尊场',
    '8306': '抢庄牛牛王者房',
    '8601': '三公新手房',
    '8602': '三公初级房',
    '8603': '三公中级房',
    '8604': '三公高级房',
    '8605': '三公至尊房',
    '8606': '三公王者房',
    '8901': '看三张抢庄牛牛新手房',
    '8902': '看三张抢庄牛牛初级房',
    '8903': '看三张抢庄牛牛中级房',
    '8904': '看三张抢庄牛牛高级房',
    '8905': '看三张抢庄牛牛至尊房',
    '8906': '看三张抢庄牛牛王者房',
    '9101': '百家乐新手房',
    '9102': '百家乐初级房',
    '9103': '百家乐中级房',
    '9104': '百家乐高级房',
    '9001': '龙虎新手房',
    '9002': '龙虎初级房',
    '9003': '龙虎中级房',
    '9004': '龙虎高级房',
    '9201': '森林舞会新手房',
    '9202': '森林舞会初级房',
    '9203': '森林舞会中级房',
    '9204': '森林舞会高级房',
    '38901': '看四张抢庄牛牛新手房',
    '38902': '看四张抢庄牛牛初级房',
    '38903': '看四张抢庄牛牛中级房',
    '38904': '看四张抢庄牛牛高级房',
    '38905': '看四张抢庄牛牛至尊房',
    '38906': '看四张抢庄牛牛王者房'
  };
  // 财神棋牌
  static CSQiPaiGames = {
    1: '斗地主',
    2: '二人麻将',
    49: '新版斗地主',
    3: '抢庄牛牛',
    4: '百人牛牛',
    5: '龙王捕鱼',
    6: '多财多福',
    7: '竞咪楚汉德州',
    8: '推筒子',
    9: '加倍斗地主',
    10: '保险楚汉德州',
    11: '血战到底',
    12: '炸金花',
    13: '必下德州',
    14: '百人三公',
    15: '十三水',
    19: '开心摇摇乐',
    20: '通比牛牛',
    22: '百家乐',
    23: '二八杠',
    24: '广东推倒胡',
    25: '二十一点',
    26: '广东鸡平胡',
    33: '经典抢庄牛牛',
    39: '跑得快',
    44: '龙虎斗',
    45: '牛牛大吃小',
    47: '开心翻翻乐',
    52: '看四张抢庄牛牛',
    994: '搏一搏',
    995: '幸运转盘',
    996: '开心翻翻乐jackpot',
    998: '竞咪楚汉福袋',
    999: 'JACKPOT'
  };
  // 欢乐棋牌
  static HLQiPaiGames = {
    ddz: '斗地主',
    dice: '大话骰',
    psz: '拼三张',
    qznn: '抢庄牛牛',
    brnn: '百人牛牛',
    qzsz: '抢庄三张',
    tbnn: '通比牛牛',
    ebg: '二八杠',
    zjh: '炸金花',
    sg: '三公',
    qzpj: '抢庄牌九',
    kpqznn: '看牌抢庄',
    xywz: '幸运五张',
    jdnn: '经典牛牛',
    xyesyd: '幸运 21 点',
    xyejs: '德州 2+3',
    sss: '十三水',
    hldn: '欢乐斗牛',
    hllh: '欢乐龙虎',
    qzesyd: '抢庄 21 点',
    dresyd: '多人 21 点',
    hhdz: '红黑大战',
    srtbnn: '四人通比牛牛',
    kszqznn: '看四张抢庄牛牛',
    bjl: '百家乐',
    znn: '诈牛牛',
    ermj: '二人麻将',
    // 'byb': '搏一搏',
    // 'activity': '节日活动',
    kszqzzjh: '看三张抢庄炸金花',
    bbnn: '百变牛牛'
  };
  // 天美棋牌
  static TMQiPaiGames = {
    7: '炸金花',
    8: '德州扑克',
    9: '极速炸金花',
    28: '跑得快',
    31: '抢庄牛牛',
    32: '骰子牛牛',
    104: '百人牛牛',
    108: '奔驰宝马',
    120: '二八杠',
    122: '百家乐',
    125: '龙虎斗',
    128: '红包来了',
    199: '经典斗地主',
    // 199: '房卡经典斗地主',
    10199: '经典斗地主比赛',
    200: '欢乐斗地主',
    // 200: '房卡欢乐斗地主',
    351: '二人麻将',
    352: '血流成河',
    1003: '通比牛牛',
    1004: '十三水',
    1005: '二十一点',
    2050: '南海捕鱼 3D'
  };
  static TMQiPaiGameRooms = {
    1084: '新手场',
    1085: '初级场',
    1086: '中级场',
    1087: '高级场',
    1089: '至尊场',
    1209: '天天有喜',
    1212: '初级场',
    1213: '中级场',
    1214: '高级场',
    1082: '初级场',
    1195: '中级场',
    1196: '高级场',
    1088: '初级场',
    1190: '初级场',
    1191: '中级场',
    1192: '高级场',
    1080: '初级场',
    1126: '中级场',
    1127: '高级场',
    1079: '初级场',
    1135: '体验场',
    1092: '新手场',
    1093: '初级场',
    1095: '中级场',
    1096: '高级场',
    1141: '体验场',
    1118: '初级场',
    1119: '中级场',
    1120: '高级场',
    1121: '至尊场',
    1137: '体验场',
    1114: '新手场',
    1115: '初级场',
    1116: '中级场',
    1117: '高级场',
    1134: '体验场',
    1097: '新手场',
    1098: '初级场',
    1099: '中级场',
    1100: '高级场',
    1101: '至尊场',
    1143: '体验场',
    1144: '新手场',
    1145: '初级场',
    1146: '中级场',
    1142: '高级场',
    1147: '至尊场',
    1138: '体验场',
    1090: '新手场',
    1111: '初级场',
    1112: '中级场',
    1113: '高级场',
    1130: '体验场',
    1065: '新手场',
    1066: '初级场',
    1067: '中级场',
    1068: '高级场',
    1131: '体验场',
    1069: '新手场',
    1070: '初级场',
    1071: '中级场',
    1072: '高级场',
    1139: '体验场',
    1122: '新手场',
    1123: '初级场',
    1124: '中级场',
    1125: '高级场',
    1140: '体验场',
    1106: '新手场',
    1108: '初级场',
    1109: '中级场',
    1110: '高级场',
    1107: '至尊场',
    1133: '体验场',
    1184: '新手场',
    1185: '初级场',
    1186: '中级场',
    1187: '高级场',
    1189: '至尊场',
    1153: '体验场',
    1148: '新手场',
    1149: '初级场',
    1150: '中级场',
    1151: '高级场',
    1152: '至尊场',
    1132: '体验场',
    1078: '新手场',
    1074: '初级场',
    1075: '中级场',
    1076: '高级场',
    1077: '至尊场',
    1136: '体验场',
    1094: '新手场',
    1102: '初级场',
    1103: '中级场',
    1104: '高级场',
    2328: '体验场',
    2329: '初级场',
    2330: '中级场',
    2331: '高级场',
    2333: '至尊场',
    2386: '新手场',
    2363: '新手场',
    2364: '中级场',
    2365: '高级场',
    2348: '初级场',
    2374: '体验场',
    2338: '中级场',
    2339: '高级场',
    2335: '新手场',
    2336: '初级场',
    2388: '体验场',
    2389: '体验场',
    2337: '新手场',
    2345: '初级场',
    2346: '中级场',
    2347: '高级场',
    2317: '新手场',
    2318: '初级场',
    2319: '中级场',
    2320: '高级场',
    2321: '至尊场',
    2322: '体验场',
    2376: '新手场',
    2377: '初级场',
    2378: '中级场',
    2379: '高级场',
    2380: '至尊场',
    2369: '体验场',
    2323: '初级场',
    2326: '初级场',
    2332: '初级场',
    2324: '初级场',
    2370: '中级场',
    2371: '高级场',
    2403: '初级场',
    2404: '中级场',
    2405: '高级场',
    2307: '二十一点',
    2334: '初级场',
    2308: '经典-房卡',
    2309: '体验场',
    2310: '初级场',
    2311: '中级场',
    2312: '高级场',
    2384: '新手场',
    2385: '新手场',
    2306: '欢乐-房卡',
    2313: '体验场',
    2314: '初级场',
    2315: '中级场',
    2316: '高级场',
    2375: '至尊场',
    2350: '体验场',
    2351: '新手场',
    2352: '初级场',
    2353: '中级场',
    2354: '高级场',
    2392: '高级场',
    2393: '体验场',
    2394: '新手场',
    2395: '初级场',
    2396: '中级场',
    2387: '体验场',
    2340: '新手场',
    2341: '初级场',
    2342: '中级场',
    2343: '高级场',
    2344: '至尊场',
    2356: '新手场',
    2357: '初级场',
    2358: '中级场',
    2359: '高级场',
    2390: '体验场',
    2360: '初级场',
    2361: '中级场',
    2362: '高级场',
    2355: '新手场',
    2373: '体验场',
    2327: '初级场',
    2325: '初级场',
    2349: '中级场',
    2381: '初级场',
    2382: '高级场',
    2383: '至尊场',
    2372: '体验场',
    2366: '5元挑战赛',
    2367: '5元定时赛',
    2368: '10元定时赛',
    2391: '10元挑战赛',
    2397: '20元挑战赛',
    2398: '万元挑战赛',
    2408: '高级场',
    2409: '中级场',
    2420: '初级场',
    2421: '中级场',
    2422: '高级场',
    2419: '天天有喜'
  };
  static THBYGameNames = {
    jcby: '金蟾捕鱼',
    dntg1: '大闹天宫1',
    dntg2: '大闹天宫2',
    lkby: '李逵劈鱼',
    dsby: '大圣捕鱼',
    xldb: '寻龙夺宝',
    jcbydlc: '金蟾海王2',
    dhlw: '3D捕鱼',
    bndr: '捕鸟达人'
  };
  // 天游棋牌
  static THQiPaiGames = {
    1002: '炸金花',
    1015: '十三水',
    1018: '德州扑克',
    1027: '百人牌九',
    1031: '百人牛牛',
    1034: '对战牛牛',
    1036: '通比牛牛',
    1037: '通杀牛牛',
    1038: '抢庄牌九',
    1042: '抢庄牛牛',
    1055: '看牌抢牛',
    1056: '运气牛牛',
    1062: '通比梭哈',
    1092: '极速炸金花',
    1097: '四人梭哈',
    1126: '十点半',
    1129: '百家乐',
    1130: '二八杠',
    2005: '龙虎斗',
    2018: '红包扫雷',
    2021: '三公',
    1201: '斗地主（5分钟赛）',
    1236: '水浒传',
    1101: '财神到',
    1121: '21点',
    1241: '跳起来',
    1235: '水果机',
    1238: '九线拉王',
    1115: '金蟾捕鱼',
    1117: '大闹天宫1',
    1118: '大闹天宫2',
    1120: '李逵劈鱼',
    1122: '大圣捕鱼',
    2020: '寻龙夺宝',
    1128: '金蟾海王2',
    2019: '3D捕鱼',
    2022: '捕鸟达人',
    6000: '土豪奖池',
    6001: '炸金花喜钱',
    6002: '牛牛喜金',
    ysz: '炸金花',
    ssz: '十三水',
    holdem: '德州扑克',
    lz2: '百人牌九',
    brnn: '百人牛牛',
    dznn: '对战牛牛',
    tbnn: '通比牛牛',
    jqnn: '通杀牛牛',
    two2p: '抢庄牌九',
    srnn: '抢庄牛牛',
    djnn: '看牌抢牛',
    bbnn: '运气牛牛',
    tbwz: '通比梭哈',
    TBSZ16: '极速炸金花',
    gswz2sr: '四人梭哈',
    tenthirty: '十点半',
    bjl: '百家乐',
    sr28g: '二八杠',
    lhdz: '龙虎斗',
    slhb: '红包扫雷',
    sg: '三公',
    ddz5: '斗地主（5分钟赛）',
    shz: '水浒传',
    csd: '财神到',
    esyd: '21点',
    tql: '跳起来',
    fruit: '水果机',
    jxlw: '九线拉王',
    jcby: '金蟾捕鱼',
    dntg1: '大闹天宫1',
    dntg2: '大闹天宫2',
    lkby: '李逵劈鱼',
    dsby: '大圣捕鱼',
    xldb: '寻龙夺宝',
    jcbydlc: '金蟾海王2',
    dhlw: '3D捕鱼',
    bndr: '捕鸟达人',
    znds: '土豪奖池',
    yszxj: '炸金花喜钱',
    hjnn: '牛牛喜金'
  };
  static THQiPaiGameRooms = {
    // 0: '自由场',
    // 1: '普通场',
    // 2: '周赛',
    // 3: '九人赛',
    // 4: '整点赛',
    // 5: '半点赛',
    // 6: '5分钟赛',
    // 7: '三人赛',
    // 8: '六人赛',
    // 9: '商盟赛',
    // 13: '练习场'
    0: '体验场1',
    1: '新手场1',
    2: '初级场1',
    3: '中级场1',
    4: '高级场1',
    5: '至尊场',
    6: '王者场',
    7: '超C',
    8: '超D',
    9: '超E',
    10: '体验场2',
    11: '新手场2',
    12: '初级场2',
    13: '中级场2',
    14: '高级场2',
    15: '超K',
    16: '超L',
    17: '超M',
    18: '超N',
    19: '超O',
    20: '体验场3',
    21: '新手场3',
    22: '初级场3',
    23: '中级场3',
    24: '高级场3',
    25: '超U',
    26: '超V',
    27: ' 超W',
    28: '超X',
    30: '体验场4',
    32: '初级场4',
    34: '高级场4',
    36: '超6',
    38: '超8',
    40: '超0',
    30494: '炸金花新手场',
    30495: '炸金花初级场',
    30496: '炸金花中级场',
    30497: '炸金花高级场',
    30470: '抢庄牛牛6张经典新手场',
    30471: '抢庄牛牛6张经典初级场',
    30472: '抢庄牛牛6张经典中级场',
    30473: '抢庄牛牛6张经典高级场',
    30474: '抢庄牛牛6张经典至尊场',
    30475: '抢庄牛牛6张经典王者场',
    30476: '抢庄牛牛6张百变王者场',
    30477: '抢庄牛牛6张百变新手场',
    30478: '抢庄牛牛6张百变初级场',
    30479: '抢庄牛牛6张百变中级场',
    30480: '抢庄牛牛6张百变高级场',
    30481: '抢庄牛牛6张百变至尊场',
    30482: '抢庄牛牛6张十倍新手场',
    30483: '抢庄牛牛6张十倍初级场',
    30484: '抢庄牛牛6张十倍中级场',
    30485: '抢庄牛牛6张十倍高级场',
    30486: '抢庄牛牛6张十倍至尊场',
    30487: '抢庄牛牛6张十倍王者场',
    30488: '抢庄牛牛6张三倍新手场',
    30489: '抢庄牛牛6张三倍初级场',
    30490: '抢庄牛牛6张三倍中级场',
    30491: '抢庄牛牛6张三倍高级场',
    30492: '抢庄牛牛6张三倍至尊场',
    30493: '抢庄牛牛6张三倍王者场',
    30101: '抢庄牛牛三倍新手场',
    30102: '抢庄牛牛三倍初级场',
    30103: '抢庄牛牛三倍中级场',
    30105: '抢庄牛牛三倍高级场',
    30106: '抢庄牛牛三倍至尊场',
    30107: '抢庄牛牛三倍王者场',
    30095: '抢庄牛牛十倍新手场',
    30096: '抢庄牛牛十倍初级场',
    30099: '抢庄牛牛十倍至尊场',
    30100: '抢庄牛牛十倍王者场',
    30498: '抢庄牛牛十倍中级场',
    30499: '抢庄牛牛十倍高级场',
    30500: '抢庄牛牛经典新手场',
    30501: '抢庄牛牛经典初级场',
    30502: '抢庄牛牛经典中级场',
    30503: '抢庄牛牛经典高级场',
    30504: '抢庄牛牛经典至尊场',
    30505: '抢庄牛牛经典王者场',
    30146: '抢庄牛牛百变新手场',
    30147: '抢庄牛牛百变初级场',
    30151: '抢庄牛牛百变王者场',
    30506: '抢庄牛牛百变中级场',
    30507: '抢庄牛牛百变高级场',
    30508: '抢庄牛牛百变至尊场',
    2027: '看牌抢牛经典看三张新手场',
    2028: '看牌抢牛经典看三张初级场',
    2029: '看牌抢牛经典看三张中级场',
    2200: '看牌抢牛经典看三张高级场',
    2201: '看牌抢牛经典看三张至尊场',
    2202: '看牌抢牛经典看三张王者场',
    2203: '看牌抢牛超变看四张新手场',
    2204: '看牌抢牛超变看四张初级场',
    2205: '看牌抢牛超变看四张中级场',
    2206: '看牌抢牛超变看四张高级场',
    2207: '看牌抢牛超变看四张至尊场',
    1261: '看牌抢牛超变看四张王者场',
    1262: '看牌抢牛刺激看四张新手场',
    1403: '看牌抢牛刺激看四张初级场',
    1404: '看牌抢牛刺激看四张中级场',
    1405: '看牌抢牛刺激看四张高级场',
    1406: '看牌抢牛刺激看四张至尊场',
    1407: '看牌抢牛刺激看四张王者场',
    1600: '通比牛牛经典新手场',
    1601: '通比牛牛经典初级场',
    1602: '通比牛牛经典中级场',
    1603: '通比牛牛经典高级场',
    1605: '通比牛牛经典至尊场',
    1606: '通比牛牛经典王者场',
    30154: '通比牛牛三倍新手场',
    30200: '通比牛牛三倍初级场',
    30232: '通比牛牛三倍中级场',
    30215: '通比牛牛三倍高级场',
    30330: '通比牛牛三倍至尊场',
    30400: '通比牛牛三倍王者场',
    30401: '通比牛牛十倍新手场',
    30402: '通比牛牛十倍初级场',
    30403: '通比牛牛十倍中级场',
    30404: '通比牛牛十倍高级场',
    30405: '通比牛牛十倍至尊场',
    30406: '通比牛牛十倍王者场',
    30407: '通比牛牛百变新手场',
    30420: '通比牛牛百变初级场',
    30421: '通比牛牛百变中级场',
    30422: '通比牛牛百变高级场',
    30423: '通比牛牛百变至尊场',
    30424: '通比牛牛百变王者场',
    30428: '通杀牛牛经典新手场',
    30429: '通杀牛牛经典初级场',
    30430: '通杀牛牛经典中级场',
    30431: '通杀牛牛经典高级场',
    30432: '通杀牛牛经典至尊场',
    30433: '通杀牛牛经典王者场',
    30434: '通杀牛牛三倍新手场',
    30435: '通杀牛牛三倍初级场',
    30436: '通杀牛牛三倍中级场',
    30437: '通杀牛牛三倍高级场',
    30438: '通杀牛牛三倍至尊场',
    30439: '通杀牛牛三倍王者场',
    30440: '通杀牛牛十倍新手场',
    30441: '通杀牛牛十倍初级场',
    30442: '通杀牛牛十倍中级场',
    30443: '通杀牛牛十倍高级场',
    30444: '通杀牛牛十倍至尊场',
    30445: '通杀牛牛十倍王者场',
    30446: '通杀牛牛百变新手场',
    30447: '通杀牛牛百变初级场',
    30448: '通杀牛牛百变中级场',
    30449: '通杀牛牛百变高级场',
    30450: '通杀牛牛百变至尊场',
    30451: '通杀牛牛百变王者场',
    30452: '运气牛牛三倍新手场',
    30453: '运气牛牛三倍初级场',
    30454: '运气牛牛三倍中级场',
    30455: '运气牛牛三倍高级场',
    30456: '运气牛牛三倍至尊场',
    30457: '运气牛牛三倍王者场',
    30458: '运气牛牛十倍新手场',
    30459: '运气牛牛十倍初级场',
    30460: '运气牛牛十倍中级场',
    30461: '运气牛牛十倍高级场',
    30462: '运气牛牛十倍至尊场',
    30463: '运气牛牛十倍王者场',
    30268: '运气牛牛百变新手场'
  };
  // 博乐棋牌
  static BLQiPaiGames = {
    mjxzdd: '博乐四川麻将-血战到底',
    mjxlch: '博乐四川麻将-血流成河',
    blnn: '博乐牛牛',
    sangong: '博乐三公',
    zjh: '博乐炸金花',
    dzmj: '博乐大众麻将',
    blackjack: '博乐21点',
    gdmj: '博乐广东麻将',
    hnmj: '博乐湖南转转麻将',
    hzmj: '博乐杭州麻将',
    hbmj: '博乐卡五星麻将',
    gyzjmj: '博乐贵阳捉鸡麻将',
    ermj: '博乐二人麻将',
    rbwar: '红黑大战',
    baccarat: '百家乐',
    dntg: '大闹天宫',
    sdxl: '神雕侠侣',
    cbzz: '赤壁之战',
    sdmn: '四大美女',
    bskg: '宝石矿工',
    rdsg: '热带水果',
    ayls: '暗夜猎手',
    gdzw: '格斗之王',
    bscs: '白蛇传说',
    ceby: '嫦娥奔月',
    zcjl: '招财锦鲤',
    wszw: '万兽之王',
    lucky7: 'Lucky7',
    csd: '财神到',
    xysg: '幸运水果',
    xcbs: '星尘宝石',
    sgws: '水果武士',
    slot: '老虎机',
    GJblackjack: '国际博乐21点',
    GJbaicao: '国际博乐百草',
    GJsuperbaicao: '国际博乐百草2',
    GJs13: '国际博乐13水',
    GJbaccarat: '国际百家乐'
  };
  static BLQiPaiGameRooms = {
    blnn: {
      '1001': '新手场',
      '1002': '初级场',
      '1003': '中级场',
      '1004': '高级场',
      '1005': '至尊场',
      '1006': '王者场'
    },
    sangong: {
      '1001': '新手场',
      '1002': '初级场',
      '1003': '中级场',
      '1004': '高级场',
      '1005': '王者场'
    },
    rbwar: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场'
    },
    baccarat: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    },
    mjxzdd: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    },
    mjxlch: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    },
    zjh: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    },
    dzmj: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    },
    blackjack: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    },
    gdmj: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    },
    hnmj: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    },
    hzmj: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    },
    hbmj: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    },
    gyzjmj: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    },
    ermj: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    },
    slot: {
      dntg: '大闹天宫',
      sdxl: '神雕侠侣',
      cbzz: '赤壁之战',
      sdmn: '四大美女',
      bskg: '宝石矿工',
      rdsg: '热带水果',
      ayls: '暗夜猎手',
      gdzw: '格斗之王',
      bscs: '白蛇传说',
      ceby: '嫦娥奔月',
      zcjl: '招财锦鲤',
      wszw: '万兽之王',
      lucky7: 'Lucky7',
      csd: '财神到',
      xysg: '幸运水果',
      xcbs: '星尘宝石',
      sgws: '水果武士'
    },
    GJblackjack: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    },
    GJbaicao: {
      '1001': '新手场',
      '1002': '初级场',
      '1003': '中级场',
      '1004': '高级场',
      '1005': '至尊场',
      '1006': '王者场'
    },
    GJsuperbaicao: {
      '1001': '新手场',
      '1002': '初级场',
      '1003': '中级场',
      '1004': '高级场',
      '1005': '至尊场',
      '1006': '王者场'
    },
    GJs13: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    },
    GJbaccarat: {
      '1001': '初级场',
      '1002': '中级场',
      '1003': '高级场',
      '1004': '土豪场'
    }
  };
  // 天境棋牌
  static TJQiPaiGames = {
    '134284034': '龙虎斗',
    '134284035': '骰宝',
    '134283522': '抢庄牛牛',
    '134349060': '明牌牛牛',
    '134349572': '百人牛牛',
    '134349313': '抢庄牌九',
    '134349061': '二八杠',
    '134349573': '森林舞会',
    '134349574': '百家乐',
    '134349062': '通比牛牛',
    '134349064': '三公',
    '134349063': '十三水',
    '134349575': '奔驰宝马',
    '134349065': '看牌牛牛',
    '134349576': '水果机',
    '134349577': '万人炸金花'
  };
  static TJQiPaiGameRooms = {
    '75': '单一房间',
    '78': '单一房间',
    '79': '新手房',
    '80': '初级房',
    '81': '高级房',
    '82': '尊享房',
    '87': '新手房',
    '88': '初级房',
    '89': '高级房',
    '90': '尊享房',
    '91': '新手房',
    '92': '初级房',
    '93': '高级房',
    '94': '尊享房',
    '95': '新手房',
    '96': '初级房',
    '97': '中级房',
    '98': '高级房',
    '99': '至尊房',
    '100': '王者房',
    '105': '新手房',
    '106': '初级房',
    '107': '中级房',
    '108': '高级房',
    '109': '至尊房',
    '110': '王者房',
    '101': '新手房',
    '102': '初级房',
    '103': '高级房',
    '104': '至尊房',
    '111': '新手房',
    '112': '初级房',
    '113': '高级房',
    '114': '至尊房',
    '115': '新手房',
    '116': '初级房',
    '117': '中级房',
    '118': '高级房',
    '119': '至尊房',
    '120': '王者房',
    '121': '新手房',
    '122': '初级房',
    '123': '中级房',
    '124': '高级房',
    '125': '至尊房',
    '126': '王者房',
    '127': '新手房',
    '128': '初级房',
    '129': '中级房',
    '130': '高级房',
    '131': '新手房',
    '132': '初级房',
    '133': '高级房',
    '134': '至尊房',
    '135': '新手房',
    '136': '初级房',
    '137': '中级房',
    '138': '高级房',
    '139': '至尊房',
    '140': '王者房',
    '141': '新手房',
    '142': '初级房',
    '143': '高级房',
    '144': '至尊房',
    '145': '新手房',
    '146': '初级房',
    '147': '高级房',
    '148': '至尊房'
  };
  // 新世界棋牌
  static XSJQiPaiGames = {
    '220': '炸金花',
    '230': '极速炸金花',
    '380': '幸运五张',
    '550': '抢庄骰宝',
    '600': '21点',
    '620': '德州扑克',
    '630': '十三水',
    '680': '斗地主',
    '720': '二八杠',
    '730': '抢庄牌九',
    '830': '抢庄牛牛',
    '860': '三公',
    '870': '通比牛牛',
    '900': '龙虎斗',
    '910': '百家乐',
    '920': '森林舞会',
    '930': '百人牛牛',
    '3930': '骰宝',
    '890': '看三张抢庄牛牛',
    '3890': '看四张抢庄牛牛',
    '3001': '梭哈牛牛',
    '3101': '疯狂点子牛',
    '3201': '彩金牛牛',
    '3301': '换三张牛牛',
    '999': '水果机'
  };
  static XSJQiPaiGameRooms = {
    '2201': '炸金花体验房',
    '2202': '炸金花初级房',
    '2203': '炸金花中级房',
    '2204': '炸金花高级房',
    '2301': '极速炸金花新手房',
    '2302': '极速炸金花初级房',
    '2303': '极速炸金花中级房',
    '2304': '极速炸金花高级房',
    '3801': '幸运五张体验房',
    '3802': '幸运五张初级房',
    '3803': '幸运五张中级房',
    '3804': '幸运五张高级房',
    '5501': '抢庄骰宝体验房',
    '5502': '抢庄骰宝初级房',
    '5503': '抢庄骰宝中级房',
    '5504': '抢庄骰宝高级房',
    '6001': '21点体验房',
    '6002': '21点初级房',
    '6003': '21点中级房',
    '6004': '21点高级房',
    '3600': '德州扑克新手房',
    '3601': '德州扑克初级房',
    '3602': '德州扑克中级房',
    '3603': '德州扑克高级房',
    '6301': '十三水常规场新手房',
    '6302': '十三水常规场初级房',
    '6303': '十三水常规场中级房',
    '6304': '十三水常规场高级房',
    '6305': '十三水极速场新手房',
    '6306': '十三水极速场初级房',
    '6307': '十三水极速场中级房',
    '6308': '十三水极速场高级房',
    '6801': '斗地主体验房',
    '6802': '斗地主初级房',
    '6803': '斗地主中级房',
    '6804': '斗地主高级房',
    '7201': '二八杠体验房',
    '7202': '二八杠初级房',
    '7203': '二八杠中级房',
    '7204': '二八杠高级房',
    '7205': '二八杠至尊房',
    '7301': '抢庄牌九新手房',
    '7302': '抢庄牌九初级房',
    '7303': '抢庄牌九中级房',
    '7304': '抢庄牌九高级房',
    '7305': '抢庄牌九至尊房',
    '8301': '抢庄牛牛体验房',
    '8302': '抢庄牛牛初级房',
    '8303': '抢庄牛牛中级房',
    '8304': '抢庄牛牛高级房',
    '8305': '抢庄牛牛至尊房',
    '8306': '抢庄牛牛王者房',
    '8601': '三公体验房',
    '8602': '三公初级房',
    '8603': '三公中级房',
    '8604': '三公高级房',
    '8605': '三公至尊房',
    '8701': '通比牛牛体验房',
    '8702': '通比牛牛初级房',
    '8703': '通比牛牛中级房',
    '8704': '通比牛牛高级房',
    '8705': '通比牛牛至尊房',
    '9001': '龙虎体验房',
    '9002': '龙虎初级房',
    '9003': '龙虎中级房',
    '9004': '龙虎高级房',
    '9101': '百家乐体验房',
    '9102': '百家乐初级房',
    '9103': '百家乐中级房',
    '9104': '百家乐高级房',
    '9201': '森林舞会体验房',
    '9202': '森林舞会初级房',
    '9203': '森林舞会中级房',
    '9204': '森林舞会高级房',
    '9301': '百人牛牛体验房',
    '9302': '百人牛牛初级房',
    '9303': '百人牛牛中级房',
    '9304': '百人牛牛高级房',
    '39301': '骰宝体验房',
    '39302': '骰宝初级房',
    '39303': '骰宝中级房',
    '39304': '骰宝高级房',
    '8901': '看三张抢庄牛牛体验房',
    '8902': '看三张抢庄牛牛初级房',
    '8903': '看三张抢庄牛牛中级房',
    '8904': '看三张抢庄牛牛高级房',
    '8905': '看三张抢庄牛牛至尊房',
    '8906': '看三张抢庄牛牛王者房',
    '31001': '夺宝',
    '38901': '看四张抢庄牛牛体验房',
    '38902': '看四张抢庄牛牛初级房',
    '38903': '看四张抢庄牛牛中级房',
    '38904': '看四张抢庄牛牛高级房',
    '38905': '看四张抢庄牛牛至尊房',
    '38906': '看四张抢庄牛牛高级房',
    '30011': '梭哈牛牛新手房',
    '30012': '梭哈牛牛初级房',
    '30013': '梭哈牛牛中级房',
    '30014': '梭哈牛牛高级房',
    '31011': '疯狂点子牛初级场',
    '31012': '疯狂点子牛中级场',
    '31013': '疯狂点子牛高级场',
    '31014': '疯狂点子牛至尊场',
    '31015': '疯狂点子牛王者场',
    '32011': '彩金牛牛初级场',
    '32012': '彩金牛牛中级场',
    '32013': '彩金牛牛高级场',
    '32014': '彩金牛牛至尊场',
    '32015': '彩金牛牛王者场'
  };
  // 百胜棋牌
  static WZQiPaiGames = {
    '745001': '745通比牛牛',
    '745002': '745抢庄牛牛',
    '745003': '745经典牛牛',
    '745004': '745炸金花',
    '745005': '745二八杠',
    '745006': '745三公',
    '745007': '745经典21点',
    '745008': '745德州扑克',
    '745009': '745百家乐',
    '745010': '745牌九',
    '745011': '745捕鱼',
    '745012': '	梭哈',
    '745013': '	十三水',
    '745014': '	阿三猜数',
    '745015': '	百人牛牛',
    '745016': '	极速炸金花',
    '745017': '	龙虎斗',
    '745018': '	抢庄炸金花',
    '745019': '	明牌炸金花',
    '745020': '	百人骰宝',
    '745021': '	抢红包',
    '745022': '	红黑大战',
    '745023': '	疯狂牛牛',
    '745024': '	抢庄21点',
    '745025': '	奔驰宝马',
    '745026': '	森林舞会',
    '745027': '	幸运五张',
    '745028': '	疯狂马戏团',
    '745029': '	飞禽走兽',
    '745030': '	大话骰',
    '745031': '	一元夺宝',
    '745032': '	百人二八杠',
    '745033': '	百人扎金花',
    '745034': '	斗地主',
    '745035': '	二人麻将',
    '745036': '	红中麻将',
    '745037': '	血战到底',
    '745038': '	跑得快',
    '745039': '	捕鱼大亨',
    '745040': '寳石世界',
    '745041': '戰棋',
    '745042': '招財福袋',
    '745043': '鬼王送財',
    '745044': '金玉滿堂',
    '745045': '豆腐屋奈奈致命诱惑',
    '745046': '明日花潮吹升天',
    '745047': '三上悠亚海滩甜心',
    '745048': '神之乳Rion',
    '745049': '旬果的淫乱教室',
    '745050': '火热777',
    '745051': '海盗传奇',
    '745052': '我爱黑色会',
    '745053': '天使萌萌哒',
    '745054': '优的女仆贴身服务',
    '745055': '千菜浴池旁的疯狂抽插',
    '745056': '被凌辱的失学少女',
    '745057': '贵妇的外遇初体验',
    '745058': '发发发',
    '745059': '招财福娃',
    '745060': '关云长',
    '745061': '不为人知の绝伦女帝',
    '745062': '绝品人妻的完熟BODY',
    '745063': '清纯公主的秘密',
    '745064': '特训♡体欲少女',
    '745065': '糖果传奇',
    '745066': '蹦迪',
    '745067': '武則天',
    '745068': '麻將胡胡胡',
    '745069': '愛爾蘭精靈',
    '745070': '梅杜莎',
    '745071': '天神宙斯',
    '745072': '草原之王',
    '745073': '阿拉丁',
    '745074': '財神到'
  };
  // 百胜捕鱼
  static BSBYGameNames = {
    1001: '3D千炮捕鱼王',
    1046: '3D海盗来了',
    1047: '3D美人鱼',
    1018: '3D捕鱼王中王',
    1048: '李逵劈鱼',
    1049: '大闹天宫',
    1050: '捕鱼达人'
  };
  // 百胜棋牌
  static BSQiPaiGames = {
    '888': '激情彩金',
    '1001': '3D千炮捕⻥王',
    '1021': '看牌抢庄牛牛',
    '1046': '3D海盗来了',
    '1004': '炸金花',
    '1009': '斗地主',
    '1047': '3D美⼈鱼',
    '1023': '血流成河',
    '1013': '跑得快',
    '1301': '看四张牛牛',
    '1025': '⼆⼈麻将',
    '1024': '翻倍血流',
    '1006': '抢庄牛牛',
    '1010': '德州扑克',
    '1018': '3D捕鱼王中王',
    '1015': '21点',
    '1008': '十三水',
    '1020': '百人牛牛',
    '1017': '十点半',
    '1003': '押庄龙虎',
    '1022': '奔驰宝马',
    '1012': '三公',
    '1011': '百家乐',
    '1007': '红黑大战',
    '1002': '百人推筒子',
    '1019': '百人百家乐',
    '1005': '通比牌九',
    '1016': '欧式轮盘',
    '1014': '极速炸金花',
    '1302': '3D抢庄牛牛',
    '1303': '3D炸金花',
    '1030': '德州扑克(前注场)',
    '1027': '癞子牛牛',
    '1028': '看三张抢庄牛牛',
    '1031': '极速斗地主',
    '1048': '李逵劈鱼',
    '1049': '大闹天宫',
    '1401': '3D圣城风云',
    '1402': '经典水果',
    '1403': '3D布法罗山谷',
    '1404': '3D黄金国度',
    '1405': '3D世界杯',
    '1501': '宝石消消消'
  };

  // AG视讯
  static AGShiXunGames = {
    // 'BAC': { '百家乐',
    1: '庄',
    2: '闲',
    3: '和',
    4: '庄对',
    5: '闲对',
    6: '大',
    7: '小',
    8: '庄保险',
    9: '闲保险',
    11: '庄免佣',
    12: '庄龙宝',
    13: '闲龙宝',
    14: '超级六',
    15: '任意对子',
    16: '完美对子',
    // },

    // 'CBAC': '包桌百家乐',
    // 'LINK': '多台',
    // 'DT': { //  '龙虎',
    21: '龙',
    22: '虎',
    23: '和(龙虎)',
    // },
    // 'SHB': {//  '骰宝',
    41: '大',
    42: '小',
    43: '单',
    44: '双',
    45: '全围',
    46: '围 1',
    47: '围 2',
    48: '围 3',
    49: '围 4',
    50: '围 5',
    51: '围 6',
    52: '单点 1',
    53: '单点 2',
    54: '单点 3',
    55: '单点 4',
    56: '单点 5',
    57: '单点 6',
    58: '对子 1',
    59: '对子 2',
    60: '对子 3',
    61: '对子 4',
    62: '对子 5',
    63: '对子 6',
    64: '组合 12',
    65: '组合 13',
    66: '组合 14',
    67: '组合 15',
    68: '组合 16',
    69: '组合 23',
    70: '组合 24',
    71: '组合 25',
    72: '组合 26',
    73: '组合 34',
    74: '组合 35',
    75: '组合 36',
    76: '组合 45',
    77: '组合 46',
    78: '组合 56',
    79: '和值 4',
    80: '和值 5',
    81: '和值 6',
    82: '和值 7',
    83: '和值 8',
    84: '和值 9',
    85: '和值 10',
    86: '和值 11',
    87: '和值 12',
    88: '和值 13',
    89: '和值 14',
    90: '和值 15',
    91: '和值 16',
    92: '和值 17',
    // },
    // 'ROU': {//  '轮盘',
    101: '直接注',
    102: '分注',
    103: '街注',
    104: '三数',
    105: '4个号码',
    106: '角注',
    107: '列注(列 1)',
    108: '列注(列 2)',
    109: '列注(列 3)',
    110: '线注',
    111: '打一',
    112: '打二',
    113: '打三',
    114: '红',
    115: '黑',
    116: '大',
    117: '小',
    118: '单',
    119: '双',
    // },
    // 'FT': {// '番摊',
    130: '1番',
    131: '2番',
    132: '3番',
    133: '4番',
    134: '1念2',
    135: '1念3',
    136: '1念4',
    137: '2念1',
    138: '2念3',
    139: '2念4',
    140: '3念1',
    141: '3念2',
    142: '3念4',
    143: '4念1',
    144: '4念2',
    145: '4念3',
    146: '角(1,2)',
    147: '单',
    148: '角(1,4)',
    149: '角(2,3)',
    150: '双',
    151: '角(3,4)',
    152: '1,2 四 通',
    153: '1,2 三 通',
    154: '1,3 四 通',
    155: '1,3 二 通',
    156: '1,4 三 通',
    157: '1,4 二 通',
    158: '2,3 四 通',
    159: '2,3 一 通',
    160: '2,4 三 通',
    161: '2,4 一 通',
    162: '3,4 二 通',
    163: '3,4 一 通',
    164: '三门(3,2,1)',
    165: '三门(2,1,4)',
    166: '三门(1,4,3)',
    167: '三门(4,3,2)',
    // },
    // 'LBAC': '竞咪百家乐',
    // 'ULPK': {//  '终极德州扑克',
    180: '底注+盲注',
    181: '一倍加注',
    182: '二倍加注',
    183: '三倍加注',
    184: '四倍加注',
    // },
    // 'SBAC': '保险百家乐',
    // 'NN': {//  '牛牛',
    211: '闲1平倍',
    212: '闲1翻倍',
    213: '闲2平倍',
    214: '闲2翻倍',
    215: '闲3平倍',
    216: '闲3翻倍',
    207: '庄1平倍',
    208: '庄1翻倍',
    209: '庄2平倍',
    210: '庄2翻倍',
    217: '庄3平倍',
    218: '庄3翻倍',
    // },
    // 'BJ': {//  '21 点',
    220: '底注',
    221: '分牌',
    222: '保险',
    223: '分牌保险',
    224: '加注',
    225: '分牌加注',
    226: '完美对子',
    227: '21+3',
    228: '旁注',
    229: '旁注分牌',
    230: '旁注保险',
    231: '旁注分牌保险',
    232: '旁注加注',
    233: '旁注分牌加注',
    // },
    // 'ZJH': {// '炸金花',
    260: '龙',
    261: '凤',
    262: '对8以上',
    263: '同花',
    264: '顺子',
    265: '豹子',
    266: '同花顺',
    1930: '鱼虾蟹',
    8130: '跑得快',
    1890: '水果机'
    // },
  };
  static AGSXGames = {
    BAC: '百家乐',
    CBAC: '包桌百家乐',
    LINK: '多台',
    DT: '龙虎',
    SHB: '骰宝',
    ROU: '轮盘',
    FT: '番摊',
    LBAC: '竞咪百家乐',
    ULPK: '终极德州扑克',
    SBAC: '保险百家乐',
    NN: '牛牛',
    BJ: '21 点',
    ZJH: '炸金花',
    SL1: '巴西世界杯',
    SL2: '疯狂水果店',
    SL3: '3D水族馆',
    PK_J: '视频扑克(杰克高手)',
    SL4: '极速赛车',
    PKBJ: '新视频扑克(杰克高手)',
    FRU: '水果拉霸',
    HUNTER: '捕鱼王',
    SLM1: '美女排球(沙滩排球)',
    SLM2: '运财羊(新年运财羊)',
    SLM3: '武圣传',
    SC01: '幸运老虎机',
    TGLW: '极速幸运轮',
    SLM4: '武则天',
    TGCW: '赌场战争',
    SB01: '太空漫游',
    SB02: '复古花园',
    SB03: '关东煮',
    SB04: '牧场咖啡',
    SB05: '甜一甜屋',
    SB06: '日本武士',
    SB07: '象棋老虎机',
    SB08: '麻将老虎机',
    SB09: '西洋棋老虎机',
    SB10: '开心农场',
    SB11: '夏日营地',
    SB12: '海底漫游',
    SB13: '鬼马小丑',
    SB14: '机动乐园',
    SB15: '惊吓鬼屋',
    SB16: '疯狂马戏团',
    SB17: '海洋剧场',
    SB18: '水上乐园',
    SB25: '土地神',
    SB26: '布袋和尚',
    SB27: '正财神',
    SB28: '武财神',
    SB29: '偏财神',
    SB19: '空中战争',
    SB20: '摇滚狂迷',
    SB21: '越野机车',
    SB22: '埃及奥秘',
    SB23: '欢乐时光',
    SB24: '侏罗纪',
    AV01: '性感女仆',
    XG01: '龙珠',
    XG02: '幸运8',
    XG03: '闪亮女郎',
    XG04: '金鱼',
    XG05: '中国新年',
    XG06: '海盗王',
    XG07: '鲜果狂热',
    XG08: '小熊猫',
    XG09: '大豪客',
    SB30: '灵猴献瑞',
    SB31: '天空守护者',
    PKBD: '百搭二王',
    PKBB: '红利百搭',
    SB32: '齐天大圣',
    SB33: '糖果碰碰乐',
    SB34: '冰河世界',
    FRU2: '水果拉霸2',
    TG01: '21点(电子游戏)',
    TG02: '百家乐(电子游戏)',
    TG03: '轮盘(电子游戏)',
    SB35: '欧洲列强争霸',
    SB36: '捕鱼王者',
    SB37: '上海百乐门',
    SB38: '竞技狂热',
    SB39: '太空水果',
    SB40: '秦始皇',
    TA01: '多手二十一点 低额投注',
    TA02: '多手二十一点',
    TA05: '1手二十一点',
    TA07: 'Hilo 低额投注',
    TA0C: '3手 Hilo 高额投注',
    TA0Z: '5手杰克高手',
    TA12: '1手杰克高手',
    TA17: '1手百搭小丑',
    TA18: '10手百搭小丑',
    TA1C: '1手百搭二王',
    TA1F: '50手百搭二王',
    TA0U: '经典轿车',
    TA0V: '星际大战',
    TA0W: '海盗夺宝',
    TA0X: '巴黎茶座',
    TA0Y: '金龙献宝',
    XG10: '龙舟竞渡',
    XG11: '中秋佳节',
    XG12: '韩风劲舞',
    XG13: '美女大格斗',
    XG14: '龙凤呈祥',
    XG16: '黄金对垒',
    TA0P: '怪兽食坊',
    TA0S: '足球竞赛',
    TA0L: '无法无天',
    TA0M: '法老秘密',
    TA0N: '烈火战车',
    TA0O: '捕猎季节',
    TA0Q: '日与夜',
    TA0R: '七大奇迹',
    TA0T: '珠光宝气',
    TA1O: '欧洲轮盘(移动版)',
    TA1L: '欧洲轮盘(桌面版)',
    SV41: '富贵金鸡',
    DTA0: '赛亚烈战',
    SX02: '街头烈战',
    SC03: '金拉霸',
    SB45: '猛龙传奇',
    DTAR: '英雄荣耀',
    DTB1: '快乐农庄',
    DTAM: '封神榜',
    DTAZ: '摇滚之夜',
    SB49: '金龙珠',
    YFP: '水果派对',
    YDZ: '德州牛仔',
    YBIR: '飞禽走兽',
    YMFD: '森林舞会多人版',
    YFD: '森林舞会',
    YBEN: '奔驰宝马',
    YHR: '极速赛马',
    YMFR: '水果拉霸多人版',
    YGS: '猜猜乐',
    YFR: '水果拉霸',
    YMGS: '猜猜乐多人版',
    YMBN: '百人牛牛',
    YGFS: '多宝水果拉霸',
    YJFS: '彩金水果拉霸',
    YMBI: '飞禽走兽多人版',
    YMBA: '牛牛对战',
    YMBZ: '奔驰宝马多人版'
  };
  // BBIN视讯
  static BBINShiXunGames = {
    '3001': '百家乐',
    '3002': '二八杠',
    '3003': '龙虎斗',
    '3005': '三公',
    '3006': '温州牌九',
    '3007': '轮盘',
    '3008': '骰宝',
    '3010': '德州扑克',
    '3011': '色碟',
    '3012': '牛牛',
    '3014': '无限21点',
    '3015': '番摊',
    '3016': '鱼虾蟹',
    '3017': '保险百家乐',
    '3018': '炸金花',
    '3019': '幸运转轮',
    '3020': '走地百家乐',
    '3021': 'HiLo',
    '3025': '原创百家乐',
    '3026': '原创龙虎斗',
    '3027': '原创保险百家乐',
    '3028': '原创轮盘',
    '3029': '原创炸金花',
    '3030': '原创三公',
    '3031': '原创牛牛',
    '3032': '原创色碟',
    '3033': '原创骰宝',
    '3034': '原创鱼虾蟹',
    '3036': '原创HiLo',
    '3038': '原创温州牌九',
    '3043': '原创21点+',
    '3047': '21点百家乐'
  };
  static BBINGameResult = {
    '3001': { 0: '庄', 1: '闲' },
    '3002': { 0: '庄门牌', 1: '上门牌', 2: '中门牌', 3: '下门牌' },
    '3003': { 0: '龙', 1: '虎' },
    '3005': { 0: '庄', 1: '闲一', 2: '闲二', 3: '闲三' },
    '3006': { 0: '闲一', 1: '闲二', 2: '闲三' },
    '3007': { 0: '' },
    '3008': { 0: '' },
    '3010': { 0: '庄家牌型', 1: '闲家牌型', 2: '公牌' },
    '3011': { 0: '' },
    '3012': { 0: '庄', 1: '闲一', 2: '闲二', 3: '闲三' },
    '3014': { 0: '庄', 1: '闲' },
    '3015': { 0: '' },
    '3016': { 0: '' },
    '3017': { 0: '庄', 1: '闲' },
    '3018': { 0: '龙牌型', 1: '凤牌型' },
    '3019': { 0: '' },
    '3020': { 0: '庄', 1: '闲' },
    '3021': { 0: '' }
  };
  static BBINbetDetails = {
    '3001': {
      1: '庄',
      2: '闲',
      3: '和',
      4: '庄对',
      5: '闲对',
      6: '大',
      7: '小',
      8: '庄单',
      9: '庄双',
      10: '闲单',
      11: '闲双',
      12: '任意对子',
      13: '完美对子',
      14: '庄(免佣)',
      15: '超級六(免佣)'
    },
    '3003': {
      1: '虎',
      2: '龙',
      3: '和',
      4: '虎单',
      5: '虎双',
      6: '龙单',
      7: '龙双',
      8: '虎黑',
      9: '虎红',
      10: '龙黑',
      11: '龙红'
    },
    '3005': {
      1: '闲 1 贏',
      2: '闲 1 輸',
      3: '闲 1 和',
      4: '闲 1 三公',
      5: '闲 1 对牌以上',
      6: '闲 2 贏',
      7: '闲 2 輸',
      8: '闲 2 和',
      9: '闲 2 三公',
      10: '闲 2 对牌以上',
      11: '闲 3 贏',
      12: '闲 3 輸',
      13: '闲 3 和',
      14: '闲 3 三公',
      15: '闲 3 对牌以上',
      16: '庄对牌以上'
    },
    '3006': {
      1: '順门贏',
      2: '闲家一輸',
      3: '出门贏',
      4: '闲家二輸',
      5: '到门贏',
      6: '闲家三輸'
    },
    '3007': {
      0: '直注(0)',
      1: '直注(1)',
      2: '直注(2)',
      3: '直注(3)',
      4: '直注(4)',
      5: '直注(5)',
      6: '直注(6)',
      7: '直注(7)',
      8: '直注(8)',
      9: '直注(9)',
      10: '直注(10)',
      11: '直注(11)',
      12: '直注(12)',
      13: '直注(13)',
      14: '直注(14)',
      15: '直注(15)',
      16: '直注(16)',
      17: '直注(17)',
      18: '直注(18)',
      19: '直注(19)',
      20: '直注(20)',
      21: '直注(21)',
      22: '直注(22)',
      23: '直注(23)',
      24: '直注(24)',
      25: '直注(25)',
      26: '直注(26)',
      27: '直注(27)',
      28: '直注(28)',
      29: '直注(29)',
      30: '直注(30)',
      31: '直注(31)',
      32: '直注(32)',
      33: '直注(33)',
      34: '直注(34)',
      35: '直注(35)',
      36: '直注(36)',
      37: '分注( 0,1 )',
      39: '分注( 0,3 )',
      40: '分注( 1,2 )',
      41: '分注( 1,4 )',
      42: '分注( 2,3 )',
      43: '分注( 2,5 )',
      44: '分注( 3,6 )',
      45: '分注( 4,5 )',
      46: '分注( 4,7 )',
      47: '分注( 5,6 )',
      48: '分注( 5,8 )',
      49: '分注( 6,9 )',
      50: '分注( 7,8 )',
      51: '分注( 7,10 )',
      52: '分注( 8,9 )',
      53: '分注( 8,11 )',
      54: '分注( 9,12 )',
      55: '分注( 10,11 )',
      56: '分注( 10,13 )',
      57: '分注( 11,12 )',
      58: '分注( 11,14 )',
      59: '分注( 12,15 )',
      60: '分注( 13,14 )',
      61: '分注( 13,16 )',
      62: '分注( 14,15 )',
      63: '分注( 14,17 )',
      64: '分注( 15,18 )',
      65: '分注( 16,17 )',
      66: '分注( 16,19 )',
      67: '分注( 17,18 )',
      68: '分注( 17,20 )',
      69: '分注( 18,21 )',
      70: '分注( 19,20 )',
      71: '分注( 19,22 )',
      72: '分注( 20,21 )',
      73: '分注( 20,23 )',
      74: '分注( 21,24 )',
      75: '分注( 22,23 )',
      76: '分注( 22,25 )',
      77: '分注( 23,24 )',
      78: '分注( 23,26 )',
      79: '分注( 24,27 )',
      80: '分注( 25,26 )',
      81: '分注( 25,28 )',
      82: '分注( 26,27 )',
      83: '分注( 26,29 )',
      84: '分注( 27,30 )',
      85: '分注( 28,29 )',
      86: '分注( 28,31 )',
      87: '分注( 29,30 )',
      88: '分注( 29,32 )',
      89: '分注( 30,33 )',
      90: '分注( 31,32 )',
      91: '分注( 31,34 )',
      92: '分注( 32,33 )',
      93: '分注( 32,35 )',
      94: '分注( 33,36 )',
      95: '分注( 34,35 )',
      96: '分注( 35,36 )',
      97: '街注(1,2,3)',
      98: '街注(4,5,6)',
      99: '街注(7,8,9)',
      100: '街注(10,11,12)',
      101: '街注(13,14,15)',
      102: '街注(16,17,18)',
      103: '街注(19,20,21)',
      104: '街注(22,23,24)',
      105: '街注(25,26,27)',
      106: '街注(28,29,30)',
      107: '街注(31,32,33)',
      108: '街注(34,35,36)',
      109: '三数(0,1,2)',
      110: '三数(0,2,3 )',
      111: '角注(1,2,4,5)',
      112: '角注(2,3,5,6)',
      113: '角注(4,5,7,8)',
      114: '角注(5,6,8,9)',
      115: '角注(7,8,10,11)',
      116: '角注(8,9,11,12)',
      117: '角注(10,11,13,14)',
      118: '角注(11,12,14,15)',
      119: '角注(13,14,16,17)',
      120: '角注(14,15,17,18)',
      121: '角注(16,17,19,20)',
      122: '角注(17,18,20,21)',
      123: '角注(19,20,22,23)',
      124: '角注(20,21,23,24)',
      125: '角注(22,23,25,26)',
      126: '角注(23,24,26,27)',
      127: '角注(25,26,28,29)',
      128: '角注(26,27,29,30)',
      129: '角注(28,29,31,32)',
      130: '角注(29,30,32,33)',
      131: '角注(31,32,34,35)',
      132: '角注(32,33,35,36)',
      133: '四个号码(0,1,2,3)',
      134: '线注(1,2,3,4,5,6)',
      135: '线注(4,5,6,7,8,9)',
      136: '线注(7,8,9,10,11,12)',
      137: '线注(10,11,12,13,14,15)',
      138: '线注(13,14,15,16,17,18)',
      139: '线注(16,17,18,19,20,21)',
      140: '线注(19,20,21,22,23,24)',
      141: '线注(22,23,24,25,26,27)',
      142: '线注(25,26,27,28,29,30)',
      143: '线注(28,29,30,31,32,33)',
      144: '线注(31,32,33,34,35,36)',
      145: '列注(1st)',
      146: '列注(2nd)',
      147: '列注(3th)',
      148: '打(1st)',
      149: '打(2nd)',
      150: '打(3th)',
      151: '红/黑(红)',
      152: '红/黑(黑)',
      153: '单/双(单)',
      154: '单/双(双)',
      155: '大/小 (1-18)',
      156: '大/小 (19-36)'
    },
    '3008': {
      1: '大/小(小)',
      2: '大/小(大)',
      4: '点数/4 点',
      5: '点数/5 点',
      6: '点数/6 点',
      7: '点数/7 点',
      8: '点数/8 点',
      9: '点数/9 点',
      10: '点数/10 点',
      11: '点数/11 点',
      12: '点数/12 点',
      13: '点数/13 点',
      14: '点数/14 点',
      15: '点数/15 点',
      16: '点数/16 点',
      17: '点数/17 点',
      18: '短牌(1,2)',
      19: '短牌(1,3)',
      20: '短牌(1,4)',
      21: '短牌(1,5)',
      22: '短牌(1,6)',
      23: '短牌(2,3)',
      24: '短牌(2,4)',
      25: '短牌(2,5)',
      26: '短牌(2,6)',
      27: '短牌(3,4)',
      28: '短牌(3,5)',
      29: '短牌(3,6)',
      30: '短牌(4,5)',
      31: '短牌(4,6)',
      32: '短牌(5,6)',
      33: '长牌(1,1)',
      34: '长牌(2,2)',
      35: '长牌(3,3)',
      36: '长牌(4,4)',
      37: '长牌(5,5)',
      38: '长牌(6,6)',
      39: '圆骰(1,1,1)',
      40: '圆骰(2,2,2)',
      41: '圆骰(3,3,3)',
      42: '圆骰(4,4,4)',
      43: '圆骰(5,5,5)',
      44: '圆骰(6,6,6)',
      45: '全圆',
      46: '三军(1)',
      47: '三军(2)',
      48: '三军(3)',
      49: '三军(4)',
      50: '三军(5)',
      51: '三军(6)',
      52: '单双（单）',
      53: '单双（双）'
    },
    '3010': {
      1: '底注',
      2: '翻牌',
      3: '转牌',
      4: '河牌',
      5: 'Bonus'
    },
    '3011': {
      1: '4 白',
      2: '4 红',
      3: '3 白 1 红',
      4: '3 红 1 白',
      5: '单',
      6: '双'
    },
    '3012': {
      1: '闲 1 平倍',
      2: '闲 1 翻倍',
      3: '闲 1 预扣额度',
      4: '闲 2 平倍',
      5: '闲 2 翻倍',
      6: '闲 2 预扣额度',
      7: '闲 3 平倍',
      8: '闲 3 翻倍',
      9: '闲 3 预扣额度'
    },
    '3014': {
      1: '闲',
      2: '分牌',
      3: '保险',
      4: '加倍'
    },
    '3015': {
      1: '1番',
      2: '2番',
      3: '3番',
      4: '4番',
      5: '1念2',
      6: '1念3',
      7: '1念4',
      8: '2念1',
      9: '2念3',
      10: '2念4',
      11: '3念1',
      12: '3念2',
      13: '3念4',
      14: '4念1',
      15: '4念2',
      16: '4念3',
      17: '1，2角',
      18: '2，3角',
      19: '3，4角',
      20: '4，1角',
      21: '2，3一通',
      22: '2，4一通',
      23: '3，4一通',
      24: '1，3二通',
      25: '1，4二通',
      26: '3，4二通',
      27: '1，2三通',
      28: '1，4三通',
      29: '2，4三通',
      30: '1，2四通',
      31: '1，3四通',
      32: '2，3四通',
      33: '三门（4，3，2）',
      34: '三门（1，4，3）',
      35: '三门（2，1，4）',
      36: '三门（3，2，1）',
      37: '单',
      38: '双'
    },
    '3016': {
      1: '大/小(小)',
      2: '大/小(大)',
      4: '点数/4 点',
      5: '点数/5 点',
      6: '点数/6 点',
      7: '点数/7 点',
      8: '点数/8 点',
      9: '点数/9 点',
      10: '点数/10 点',
      11: '点数/11 点',
      12: '点数/12 点',
      13: '点数/13 点',
      14: '点数/14 点',
      15: '点数/15 点',
      16: '点数/16 点',
      17: '点数/17 点',
      18: '指定单色(绿)',
      19: '指定单色(蓝)',
      20: '指定单色(红)',
      21: '指定双色(绿)',
      22: '指定双色(蓝)',
      23: '指定双色(红)',
      24: '指定三色(绿)',
      25: '指定三色(蓝)',
      26: '指定三色(红)',
      27: '任意三色',
      28: '圆骰(1,1,1)',
      29: '圆骰(2,2,2)',
      30: '圆骰(3,3,3)',
      31: '圆骰(4,4,4)',
      32: '圆骰(5,5,5)',
      33: '圆骰(6,6,6)',
      34: '全圆',
      35: '三军(1)',
      36: '三军(2)',
      37: '三军(3)',
      38: '三军(4)',
      39: '三军(5)',
      40: '三军(6)',
      41: '单/双(单)',
      42: '单/双(双)'
    },
    '3017': {
      1: '庄',
      2: '闲',
      3: '和',
      4: '庄对',
      5: '闲对',
      6: '大',
      7: '小',
      16: '庄保险',
      17: '闲保险'
    },
    '3018': {
      1: '龙',
      2: '凤',
      4: '对 9 以上',
      8: '順子',
      16: '同花',
      32: '同花順',
      64: '豹子'
    },
    '3019': {
      1: '1',
      2: '3',
      3: '5',
      4: '16',
      5: '24',
      6: '50 (银)',
      7: '50 (金)'
    },
    '3020': {
      1: '庄',
      2: '闲',
      3: '和',
      4: '庄对',
      5: '闲对',
      6: '大',
      7: '小',
      8: '庄单',
      9: '庄双',
      10: '闲单',
      11: '闲双',
      12: '任意对子',
      13: '完美对子',
      14: '庄同花',
      15: '闲同花'
    },
    '3021': {
      1: '高',
      2: '相同',
      3: '低',
      4: '2/3/4/5',
      5: '6/7/8/9',
      6: 'J/Q/K/A',
      7: '红',
      8: '黑',
      9: '单',
      10: '双'
    }
  };
  // DG视讯
  static DGShiXunGames = {
    '1': '百家乐',
    '998': '会员红包',
    '3': '龙虎',
    '4': '轮盘',
    '5': '骰宝',
    '7': '牛牛',
    '8': '竞咪百家乐',
    '11': '炸金花',
    '12': '极速骰宝',
    '14': '色碟'
  };
  // BG视讯
  static BGShiXunGames = {
    1: '传统百家乐',
    2: '轮盘',
    3: '骰宝',
    4: '龙虎',
    7: '极速百家乐',
    10: '共咪百家乐',
    11: '多彩百家乐',
    13: '牛牛',
    14: '炸金花'
  };

  // JDB电子
  static JDBDianZiGames = {
    0: '老虎机',
    7: '捕鱼机',
    9: '街机',
    12: '电子彩票',
    18: '棋牌'
  };
  static JDBDianZiGameNames = {
    8001: '幸运龙',
    8002: '唐伯虎点秋香',
    8003: '变脸',
    8004: '悟空',
    8005: '骆马大冒险',
    8006: '台湾黑熊',
    8007: '幸运麟',
    8014: '招财狮',
    8015: '月光秘宝',
    8016: '上班族狂想曲',
    8017: '过新年',
    8018: '拿破仑',
    8019: '文房四宝',
    8020: '芝麻开门',
    8021: '黄金香蕉帝国',
    8022: '麻雀无双',
    8023: '奥林匹亚神庙',
    8024: '水晶王国',
    8025: '神偷妙贼',
    8026: '热舞教父',
    8027: '料理厨王',
    8028: '幸运淘金鼠',
    8029: '奇幻糖果岛',
    8030: '疯狂科学家',
    8031: '金饺子',
    8034: '金钱侠',
    8035: '幸运凤',
    8036: '龙王',
    8037: '魔术秀',
    8044: '江山美人',
    8046: '关公',
    8047: '变脸2',
    8048: '芝麻开门2',
    8049: '唐伯虎点秋香2',
    8050: '马上有钱',
    8051: '喜洋羊',
    14001: '斗鸡',
    14002: '玛雅大冒险',
    14003: '屌丝熊猫',
    14004: '塞尔达传说',
    14005: '包大人',
    14006: '亿万富翁',
    14007: '一拳超人',
    14008: '神龙大侠',
    14010: '飞龙在天',
    14011: '银河护卫队',
    14012: '街头霸王',
    14013: '春宵苦短',
    14015: '星球大战',
    14016: '王牌特工',
    14017: '少女前线',
    14018: '妲己',
    14019: '宝石物语',
    14020: '魔法乳神',
    14021: '钱滚钱',
    14022: '采矿土豪',
    14023: '赌王扑克',
    14025: '幸运赛车',
    14026: '发大财',
    14027: '好运777',
    14029: '东方神兽',
    14030: '三倍金刚',
    14033: '飞鸟派对',
    14034: '狗来富',
    14035: '龙舞',
    14036: '超级牛B',
    14038: '埃及夺宝',
    14039: '开运夺宝',
    14040: '七海夺宝',
    14042: '聚宝盆',
    15001: '金鸡报囍',
    15002: '齐天大圣',
    15004: '火牛阵',
    15005: '幸运福娃',
    15006: '印加帝国',
    15009: '忍者大进击',
    15010: '熊猫厨王',
    15011: '后羿传',
    15012: '五福临门',
    15013: '九尾狐',
    7001: '龙王捕鱼',
    7002: '龙王捕鱼2',
    7003: '财神捕鱼',
    7004: '五龙捕鱼',
    9001: '小玛莉',
    9002: '新年快乐',
    9003: '飞禽走兽',
    9004: '啤酒大亨',
    9006: '花果山传奇',
    9007: '超激发水果盘',
    12001: '财神宾果',
    12002: '金鸡福彩',
    12003: '快乐六星彩',
    18001: '通比牛牛',
    18002: '抢庄牛牛',
    18004: '押庄射龙门',
    18006: '押庄龙虎斗',
    18008: '通比德州斗牛',
    18010: '金葫芦5PK',
    18011: '幸运星5PK',
    18012: '百变抢庄牛牛',
    18013: '通比六牛',
    18014: '抢庄六牛',
    18015: '千变百人牛牛',
    18016: '极速炸金花',
    18017: '抢庄三公',
    18018: '德州抢庄斗牛',
    14055: '金刚',
    14047: '富豪哥'
  };
  // CQ9电子
  static CQ9DianZiGames = {
    '182': '雷神2',
    BA203: '疯狂赚钞票',
    BU04: '齐天大圣',
    AKshanghairespins: '上海再次旋转',
    '52': '跳高高',
    '7': '跳起来',
    '10': '五福临门',
    '99': '跳更高',
    '50': '鸿福齐天',
    '31': '武圣',
    '111': '飞起来',
    '64': '宙斯',
    '205': '蹦迪',
    '179': '跳高高2',
    '24': '跳起来2',
    '69': '发财神',
    '137': '直式蹦迪',
    '127': '直式武圣',
    '83': '火之女王',
    '89': '雷神',
    '138': '跳过来',
    '105': '单手跳高高',
    '15': '金鸡报喜',
    '9': '钟馗运财',
    '203': '嗨起来',
    '180': '金鸡报囍3',
    '153': '六颗糖',
    '117': '东方神起',
    '147': '花开富贵',
    '154': '宙斯他爹',
    '8': '甜蜜蜜',
    '160': '发财神2',
    '29': '水世界',
    '133': '直式洪福齐天',
    '140': '火烧连环船2',
    '115': '冰雪女王',
    '79': '变色龙',
    '125': '直式宙斯',
    '60': '丛林舞会',
    '58': '金鸡报囍2',
    '128': '转大钱',
    '113': '飞天财神',
    '173': '666',
    '72': '好运年年',
    '74': '聚宝盆',
    '163': '哪吒再临',
    '108': '直式跳更高',
    '33': '火烧连环船',
    '109': '单手跳起來',
    '143': '发财福娃',
    '116': '梦游仙境',
    '131': '直式发财神',
    '161': '大力神',
    '152': '双飞',
    '46': '狼月',
    '16': '五行',
    '201': '拳霸',
    '5': '金大款',
    '1': '钻石水果王',
    '157': '五形拳',
    '123': '直式五福临门',
    '56': '黯夜公爵',
    '3': '血之吻',
    '67': '赚金蛋',
    '12': '金玉满堂',
    '150': '寿星大发',
    '202': '舞媚娘',
    '57': '神兽争霸',
    '1010': '五福临门JP',
    '23': '金元宝',
    '78': '阿波罗',
    '144': '钻更多',
    '142': '火神',
    '149': '龙舟',
    '44': '豪华水果王',
    '54': '火草泥马',
    '139': '直式火烧连环船',
    '4': '森林泰后',
    '122': '印加祖玛',
    '93': '世界杯明星',
    '136': '奔跑吧猛兽',
    '121': '直式跳起来2',
    '68': '悟空偷桃',
    '17': '祥狮献瑞',
    '39': '飞天',
    '20': '发发发',
    '70': '万饱龙',
    '114': '钻饱宝',
    '55': '魔龙传奇',
    '146': '九连宝灯',
    '118': '老司机',
    '26': '777',
    '104': '海滨消消乐',
    '19': '风火轮',
    '63': '寻龙诀',
    '134': '家里有矿',
    '130': '偷金妹子',
    '6': '1945',
    '102': '水果派对',
    '145': '印金工厂',
    '112': '盗法老墓',
    '77': '火凤凰',
    '81': '金银岛',
    '148': '有如神柱',
    '86': '牛逼快跑',
    '1074': '聚宝盆JP',
    '41': '水球大战',
    '124': '锁象无敌',
    '62': '非常钻',
    '221': '狄仁杰四大天王',
    '42': '福尔摩斯',
    AR11: '五鬼运财',
    '2': '棋圣',
    '66': '火爆777',
    '48': '莲',
    '35': '疯狂哪咤',
    '100': '宾果消消消',
    '18': '雀王',
    AR13: '汪财进宝',
    '61': '天天吃豆',
    AR39: '僵尸的宝藏',
    '135': '直式金鸡报喜',
    '132': '再喵一个',
    '30': '三国序',
    '38': '舞力全开',
    '11': '梦游仙境2',
    '103': '宝石配对',
    '88': '金喜鹊桥',
    '53': '来电99',
    AR41: '斗地主升级版',
    '129': '直式金鸡报囍2',
    '40': '镖王争霸',
    '49': '寂寞星球',
    '101': '星星消消乐',
    '47': '法老宝藏',
    '87': '集电宝',
    AR03: '此地无银300两',
    AR23: '五运',
    '45': '超级发',
    AR14: '囍',
    '98': '世界杯全明星',
    BA202: '美女与野兽',
    '84': '奇幻魔术',
    '34': '地鼠战役',
    '80': '传奇海神',
    '76': '旺旺旺',
    '43': '恭贺新禧',
    '51': '嗨爆大马戏',
    AR01: '妙笔生财',
    AR02: '喵财进宝',
    '13': '樱花妹子',
    AR12: '8级台风',
    '27': '魔法世界',
    '96': '足球宝贝',
    '126': '转珠猪',
    '1067': '赚金蛋JP',
    '59': '夏日猩情',
    '25': '扑克拉霸',
    '32': '通天神探狄仁杰',
    AR24: '麻将小福星',
    '204': '百宝箱',
    AR08: '点石成金',
    AR16: '守株待兔',
    '94': '世界杯球衣',
    '141': '圣诞来啦',
    AR04: '斗地主经典版',
    AR21: '鹊桥',
    AR05: '鱼跃龙门',
    '95': '世界杯球鞋',
    '14': '绝赢巫师',
    AS08: '黄金玛雅',
    '28': '食神',
    '97': '世界杯球场',
    AR09: '隔壁王二不曾偷',
    AR20: '宝莲灯',
    AR18: '财运亨通',
    AR29: '富贵金鸡',
    AR25: '八仙传奇',
    AR17: '马戏团连连发',
    '92': '2018世界杯',
    '36': '夜店大亨',
    AR22: '妈祖传奇',
    AR15: '爱琴海',
    AR26: '生肖传奇',
    AR06: '丛林宝藏',
    '22': '庶务西游二课',
    AU02: '丧尸大餐',
    '21': '野狼传说',
    AS01: '运气靴',
    AR28: '贪吃猫',
    AU01: '喵喵',
    AS04: '财富幽灵',
    AU03: '火烧办公处',
    AS33: '猪的运气',
    AS03: '驯龙高手',
    AS10: '幸运3',
    AS17: '塞特的宝藏',
    AR37: '幸运传奇',
    AR07: '功夫小神通',
    AS18: '疯狂软糖',
    '65': '足球世界杯',
    AS02: '疯狂马戏团',
    AU06: '厕所囧境',
    AU05: '异外来客',
    AS19: '圣诞故事',
    AS09: '好莱坞宠物',
    AS20: '死亡女王',
    '165': '豪运十倍',
    AB3: '皇金渔场',
    AT01: '一炮捕鱼',
    AT04: '雷电战机',
    AB1: '皇金渔场2'
  };
  // CQ9捕鱼
  static CQ9BYGameNames = {
    AB3: '皇金渔场',
    AT01: '一炮捕鱼',
    AT04: '雷电战机',
    AB1: '皇金渔场2'
  };
  // DT电子
  static DTDianZiGames = {
    dragonball: '赛亚烈战',
    sd: '灌篮大师',
    kof: '格斗之魂',
    san: '赤壁之战',
    naruto: 'NINJA',
    sd5: '灌篮大师2',
    doraemon3x5: '哆啦A梦',
    onepiece: '海盗无双',
    seiya: '圣域传说',
    tgow: '财神到',
    newyear: '新年到',
    watermargin: '武松传',
    tlod: '封神榜',
    jtw: '西游降妖',
    whitesnake: '白蛇传',
    dnp: '龙凤呈祥',
    crystal: '英雄荣耀',
    fls: '福禄寿',
    fourss: '四圣兽',
    casino: '3D老虎机',
    football: '传奇之路',
    rocknight: '摇滚之夜',
    bluesea: '深蓝海域',
    circus: '疯狂马戏团',
    dash: '四驱英雄',
    bikini: '比基尼派对',
    crusader: '圣战骑士',
    crossing: '十字路口',
    hiphopman: '嘻哈侠',
    piratetreasure: '海盗宝藏',
    shogun: '幕府风云',
    merryxmas: '圣诞快乐',
    fourbeauty: '倾城四美',
    sweethouse: '甜蜜糖果屋',
    opera: '曲艺大秀场',
    vampire: '夜影传说',
    zombie: '丧尸来袭',
    perfectdet: '全能侦探',
    bigred: '满堂红',
    wrathofthor: '雷霆神域',
    pethouse: '萌宠小窝',
    guhuozai: '热血街头',
    boxingarena: '铁拳擂台',
    fantasyforest: '精灵王国',
    egyptian: '埃及帝国',
    hotpotfeast: '火锅盛宴',
    magician: '大魔术师',
    greatgatsby: '大亨小传',
    galaxywars: '银河战争',
    easyrider: '逍遥骑士',
    shokudo: '深夜食堂',
    train: '寻宝之旅',
    france: '法兰西风情',
    lovefighters: '仙侠情缘',
    genie: '阿拉丁愿望',
    garden: '小小大花园',
    jungle: '丛林探险',
    legendary: '荣耀风云',
    alchemist: '疯狂炼金师',
    dollmachine: '3D娃娃机',
    tombshadow: '古潼京',
    clash: '乱斗三国',
    cheekyemojis: '魔性表情包',
    icefire: '冰火之歌',
    whackamole: '地鼠嘎嘣脆',
    magicbean: '魔豆传奇',
    restaurant: '美味西餐厅',
    casinomagnates: '赌场大亨',
    fortuneNewYear5x25: '财神过年',
    secretdate: '秘密约会'
  };

  // PT电子
  static PTDianZiGames = {
    hb: '夜间外出', // 一夜奇遇
    ashadv: '仙境冒险',
    aogs: '神的时代',
    ftsis: '神灵时代:命运姐妹',
    furf: '神的时代:雷霆4神',
    zeus: '神的时:奥林匹斯之国王', // : 奥林匹斯之国王
    ashamw: '狂野亚马逊',
    arc: '弓兵', // | 弓箭手
    gtsatq: '亚特兰蒂斯女王',
    bl: '海滨嘉年华',
    bt: '百慕大三角', // | 百慕达三角洲
    bob: '奖金熊',
    ashbob: '杰克与魔豆',
    bfb: '野牛闪电战',
    ct: '船长的宝藏',
    ctiv: '拉斯维加斯的猫', // | 猫赌神
    catqc: '猫女王',
    chao: '超级888',
    chl: '樱桃之恋', // | 樱花之恋
    ashcpl: '美女船长',
    cm: '中国厨房',
    gtscbl: '牛仔和外星人',
    c7: '疯狂之七', // | 疯狂7
    gtsdrdv: '大胆的戴夫和荷鲁斯之眼', // | 胆大戴夫和拉之眼
    dt: '沙漠宝藏',
    dnr: '海豚礁',
    dlm: '情圣博士', // | 恋爱专家
    eas: '惊喜复活节', // | 复活节大惊喜
    ashfta: '白雪公主',
    fcgz: '翡翠公主',
    gtsflzt: '飞龙在天',
    fkmj: '疯狂麻将',
    ftg: '五虎将',
    gtsfc: '足球狂欢节',
    fbr: '终极足球', // | 足球规则
    fow: '惊异之林', // | 森林的奇迹
    frtf: '幸运5', // | 五个海盗
    fxf: '诙谐财富', // | 狐狸的宝藏
    foy: '青春之泉',
    fdt: '戴图理的神奇七', // | 弗兰克.戴图理 : 神奇7
    fdtjg: '戴图理的神奇七大奖', // | 弗兰克.戴图理 : 神奇7奖池版
    fmn: '水果狂', // | 狂热水果
    ashfmf: '幸运月',
    fff: '酷炫水果农场', // | 水果农场
    fnfrj: '趣味水果大奖', // | 时尚水果
    fm: '古怪猴子',
    ges: '艺伎故事',
    gemq: '宝石女王', // | 宝石皇后
    glrj: '角斗士彩池游戏', // | 角斗士累积奖池
    glg: '黄金版游戏', // | 黄金游戏
    gos: '黄金之旅', // | 黄金巡回赛
    bib: '湛蓝深海',
    hlf: '万圣节财富',
    hlf2: '万圣节财富2',
    hh: '鬼屋',
    ashhotj: '丛林心脏', // | 丛林之心
    heavru: '武则天',
    hk: '高速公路之王', // | 高速之王
    gts50: '热力宝石',
    hotktv: '火热KTV',
    gtsir: '浮冰流', // | 极地冒险
    aztec: '印加帝国头奖', // | 印加大奖
    jpgt: '奖金巨人',
    gtsjxb: '吉祥8',
    jqw: '金钱蛙',
    kkg: '无敌金刚', // | 金刚-世界的第八大奇迹
    lndg: '金土地',
    ght_a: '烈焰钻石',
    kfp: '六福兽',
    '7bal': '真人7座百家乐',
    rofl: '实况法式轮盘赌', // | 真人法式轮盘
    longlong: '龙龙龙',
    lm: '疯狂乐透',
    mgstk: '神奇的栈',
    gtsmrln: '玛丽莲梦露',
    bal: '迷你百家乐', // | 真人百家乐
    mcb: 'Cash back先生', // | 返利先生
    nk: '海王星王国',
    nian: '年年有余',
    nc_bal: '无抽水真人迷你百家乐',
    pmn: '黑豹之月', // | 豹月
    pgv: '企鹅假期',
    pst: '法老王的秘密',
    paw: '小猪与狼',
    pnp: '粉红豹',
    gtspor: '非常幸运', // | 巨额财富
    phot: '热紫', // | 紫红水果屋
    qnw: '权杖女王',
    ririjc: '日日进财',
    ririshc: '日日生財',
    rky: '洛基传奇', // | 洛奇
    rol: '真人轮盘赌', // | 轮盘
    sfh: '热带动物园',
    gtssmbr: '巴西森宝',
    ssp: '圣诞奇迹', // | 圣诞老人的惊喜
    samz: '亚马逊的秘密',
    shmst: '夏洛克的秘密', // | 神秘夏洛克
    sx: '四象',
    sis: '沉默的武士',
    sib: '银弹',
    ashsbd: '辛巴达的黄金之旅',
    spud: '黄金农场',
    sol: '好运连胜', // | 好运来
    cnpr: '甜蜜派对',
    tpd2: '泰国天堂',
    gtsgme: '大明帝国',
    lvb: '爱之船',
    donq: '堂吉诃德的财富', // | 富有的唐吉可德
    tmqd: '三剑客和女王', // | 三剑客和女王的钻石
    topg: '壮志凌云',
    trpmnk: '三倍猴子',
    er: '开心假期',
    vcstd: '豪华的开心假期', // | 开心假期加强版
    gts52: '疯狂维京海盗', // | 狂躁的海盗
    whk: '白狮王', // | 怀特王
    gtswg: '赌徒', // | 狂野赌徒
    ashwgaa: '疯狂赌徒2:北极探险', // : 北极探险
    wis: '我心狂野', // | 狂野精灵
    gtswng: '黄金翅膀', // | 纯金翅膀
    wlg: '舞龙',
    wlcsh: '五路财神',
    zcjb: '招财进宝',
    zcjbjp: '招财进宝彩池游戏', // | 招财进宝彩池
    zctz: '招财童子',
    aeolus: '众神时代:风暴之神TM',
    legwld: '野外宝藏',
    ashicv: '冰穴',
    ashhof: '边境之心',
    fmjp: '古怪猴子彩池',
    grbjp: '湛蓝深海彩池TM',
    yclong: '云从龙',
    xufe: '玄圃联欢',
    popc: '幸运盖尔',
    pisa: '武士元素',
    phtd: '超级法老王宝藏',
    jnglg: '丛林巨兽',
    epa: '猿人传奇',
    drgch: '龙之战士',
    swl: '旋转与胜利',
    gpas_lotj_pop: '捷豹的传说',
    gpas_fiesta_pop: '记忆盛宴',
    gpas_rothr_pop: '隐秘境界的突袭',
    gpas_satsumo_pop: '相扑君的逆袭',
    pop_sw_yxlb_skw: '英雄吕布',
    pop_sw_dr_skw: '龙的财富',
    wotp: '凤凰之道',
    ccccny: '币币币',
    tigc: '虎爪',
    anwild: '狂野蟒蛇',
    gpas_panthpays_pop: '发财黑豹',
    gpas_elady_pop: '嫦娥奔月',
    gpas_xjinfu_pop: '金福幸运',
    gpas_squeen_pop: '天之女王',
    gpas_tigertdp_pop: '青龙,白虎,朱雀,玄武',
    gpas_fatchoy_pop: '发财,财神',
    pop_sw_jqw_ab_jp_skw: '金钱蛙头奖',
    pop_sw_rm_ab_jp_skw: '狂热重舞狮将池',
    pop_shctz_ab_jp_skw: '招财童子头奖',
    rodzl: '美式轮盘',
    cspljpt: '真人赌场扑克',
    qrol: '量子轮盘赌',
    gpas_dbond_pop: '龙门传说',
    gpas_jflong_pop: '金福龙',
    gpas_scqueen_pop: '法老王的女儿',
    gpas_sjungle_pop: '野蛮丛林',
    ljxy: '龙驾祥云',
    pop_sw_ar_skw: '阿兹特克送福',
    pop_sw_fr_skw: '五行火送福',
    pop_sw_mr_skw: '五行金送福',
    pop_sw_rr_skw: '河船送福',
    pop_sw_wrl_skw: '五行水送福',
    huhl: '真人足智多谋',
    gpas_bwizard_pop: '蓝精灵',
    gpas_tsgift_pop: '财神兴盛',
    gpas_tstacks_pop: '老虎堆',
    gpas_nsshen_pop: '你属什么',
    whk2: '白色王者2',
    mnkmn: '疯狂猴子'
  };

  // MG
  static MGGames = {
    SMG_titaniumLiveGames_Sicbo: '骰宝',
    SMG_titaniumLiveGames_Roulette: '轮盘',
    SMG_titaniumLiveGames_MP_Baccarat_Playboy: '百家乐-花花公子',
    SMG_titaniumLiveGames_MP_Baccarat: '百家乐',
    SMG_titaniumLiveGames_Baccarat: '龙虎百家乐',
    SMG_titaniumLiveGames_BaccaratNC: '免佣金百家乐',
    SMG_titaniumLiveGames_BaccaratplayboyNC: '免佣金百家乐-花花公子',
    SMG_titaniumLiveGames_Baccarat_Playboy: '龙虎百家乐-花花公子',
    SMG_108Heroes: '108好汉',
    SMG_108heroesMultiplierFortunes: '一零八好汉 乘数财富',
    SMG_5ReelDrive: '侠盗猎车手',
    SMG_acesAndEights: 'A&8红利5PK',
    SMG_acesAndFaces: '经典5PK',
    SMG_actionOpsSnowAndSable: '秘密行动-雪诺和塞布尔',
    SMG_adventurePalace: '冒险丛林(HD)',
    SMG_ageOfDiscovery: '大航海时代',
    SMG_agentJaneBlonde: '城市猎人',
    SMG_agentjaneblondereturns: '特工简.布隆德归来',
    SMG_alaskanFishing: '阿拉斯加垂钓',
    SMG_americanRouletteGold: '美式轮盘',
    SMG_ancientFortunesZeus: 'Zeus古代财富',
    SMG_ariana: '爱丽娜',
    SMG_asianBeauty: '亚洲风情',
    SMG_atlanticCityBlackjackGold: '金牌大西洋城21点',
    SMG_avalon: '阿瓦隆',
    SMG_badmintonHero: '热血羽毛球',
    SMG_barBarBlackSheep5Reel: '黑绵羊咩咩叫',
    SMG_BarsAndStripes: '条条纹纹 ',
    SMG_basketballStar: '篮球巨星',
    SMG_basketballStarDeluxe: '篮球巨星豪华版',
    SMG_battleRoyale: '大逃杀',
    SMG_beachBabes: '沙滩女孩',
    SMG_beautifulBones: '美丽骷髅',
    SMG_bigKahuna: '森林之王',
    SMG_bigTop: '马戏团',
    SMG_bikiniParty: '比基尼派对',
    SMG_bonusDeucesWild: '超级百搭二王',
    SMG_boogieMonsters: '摇滚怪兽',
    SMG_bookOfOz: 'Oz之书',
    SMG_bookieOfOdds: '好运经纪人',
    SMG_breakAway: '冰球突破',
    SMG_breakAwayDeluxe: '冰球突破豪华版',
    SMG_breakDaBank: '银行抢匪',
    SMG_breakDaBankAgain: '银行抢匪2',
    SMG_bridesmaids: '伴娘我最大',
    SMG_bullseyeGameshow: '正中红心',
    SMG_burningDesire: '燃烧的慾望',
    SMG_bushTelegraph: '丛林快讯',
    SMG_bustTheBank: '抢银行',
    SMG_candyDreams: '梦果子乐园',
    SMG_carnaval: '狂欢节',
    SMG_cashCrazy: '疯狂现金',
    SMG_cashOfKingdoms: '富贵王国',
    SMG_CashSplash5Reel: '现金飞溅(5线)',
    SMG_cashapillar: '昆虫派对',
    SMG_cashoccino: '现金咖啡',
    SMG_cashville: '现金威乐',
    SMG_castleBuilder2: '城堡建筑师2',
    SMG_centreCourt: '网球冠军',
    SMG_classic243: '经典243​',
    SMG_coolBuck5Reel: '运财酷儿',
    SMG_coolWolf: '酷派狼人',
    SMG_couchPotato: '慵懒土豆',
    SMG_crazyChameleons: '疯狂变色龙',
    SMG_cricketStar: '板球明星',
    SMG_CrystalRift: '水晶裂谷',
    SMG_deckTheHalls: '圣诞大餐',
    SMG_decoDiamonds: '德科钻石',
    SMG_deucesWild: '百搭二王',
    SMG_diamondEmpire: '钻石帝国',
    SMG_dolphinCoast: '海豚海岸',
    SMG_dolphinQuest: '寻访海豚',
    SMG_doubleDoubleBonus: '四倍红利5PK',
    SMG_doubleWammy: '双倍惊喜',
    SMG_dragonDance: '舞龙',
    SMG_dragonShard: '神龙碎片',
    SMG_dragonz: '幸运龙宝贝',
    SMG_dreamDate: '夢幻邂逅',
    SMG_eaglesWings: '疾风老鹰',
    SMG_emotiCoins: '表情金币 ',
    SMG_emperorOfTheSea: '青龙出海',
    SMG_europeanBlackjackGold: '金牌欧洲21点',
    SMG_europeanRoulette: '欧洲轮盘(Gold)',
    SMG_exoticCats: '异域狂兽',
    SMG_fishParty: '海底派对',
    SMG_footballStar: '足球之巅',
    SMG_forbiddenThrone: '禁忌的皇权',
    'SMG_fortuneGirl ': '金库甜心',
    SMG_fortunium: '财富之都',
    SMG_frozenDiamonds: ' 急冻钻石​',
    SMG_fruitBlast: '水果大爆发',
    SMG_fruitVSCandy: '水果vs糖果',
    SMG_gemsOdyssey: '宝石奥德赛',
    SMG_giantRiches: '巨人财富',
    SMG_girlsWithGunsJungleHeat: '美女枪手丛林激战',
    SMG_gnomeWood: '矮木头',
    SMG_goldFactory: '黄金工厂',
    SMG_goldenEra: '黄金时代',
    SMG_goldenPrincess: '黄金公主',
    SMG_gopherGold: '黄金地鼠',
    'SMG_halloween ': '万圣节',
    SMG_halloweenies: '万圣节派对',
    SMG_HappyHolidays: '快乐假日',
    SMG_happyMonsterClaw: '开心娃娃机',
    SMG_harveys: '哈维斯的晚餐',
    SMG_hellboy: '地狱怪客',
    SMG_highSociety: '上流社会',
    SMG_highlander: '挑战者',
    SMG_hitman: '终极杀手',
    SMG_hollyJollyPenguins: '圣诞企鹅',
    SMG_HoundHotel: '酷犬酒店',
    SMG_huangdiTheYellowEmperor: '轩辕帝传',
    SMG_isis: '伊西斯',
    SMG_jacksOrBetter: '对J高手5PK',
    SMG_jewelQuestRiches: '宝石探秘财富加倍',
    SMG_jungleJimElDorado: '丛林吉姆黄金国​',
    SMG_jurassicWorld: '侏罗纪世界',
    SMG_karaokeParty: ' K歌乐韵',
    SMG_kathmandu: '加德满都',
    SMG_kingTusk: '大象之王',
    SMG_kingsOfCash: '现金之王',
    SMG_KittyCabana: '凯蒂小屋',
    SMG_ladiesNite: '淑女派对',
    SMG_ladyInRed: '红衣女郎',
    SMG_laraCroftTemplesAndTombs: '罗拉卡芙特之神庙古墓',
    SMG_legendKeno: '传奇keno',
    SMG_lifeOfRiches: '富裕人生',
    SMG_lionsPride: '狮子的自尊',
    SMG_liquidGold: '液态黄金',
    SMG_loaded: '幸运嘻哈',
    SMG_lostVegas: '迷失拉斯维加斯',
    SMG_luchaLegends: '摔角传奇',
    SMG_luckyfirecracker: '招财鞭炮',
    SMG_luckyKoi: '幸运锦鲤',
    SMG_luckyLeprechaun: '幸运妖精',
    SMG_luckyLittleGods: '宝贝财神',
    SMG_luckyTwins: '幸运双星',
    SMG_luckyZodiac: '幸运生肖',
    SMG_madHatters: '疯狂帽匠',
    SMG_MajorMillions5Reel: '百万富翁5线',
    SMG_mayanPrincess: '玛雅公主',
    SMG_megaMoneyMultiplier: '巨额现金乘数',
    SMG_megaMoneyRush: '巨款大冲击',
    SMG_MegaMoolah: '百万钞票',
    SMG_mermaidsMillions: '百万美人鱼',
    SMG_mobyDickOnlineSlot: '大白鲸',
    SMG_monsterWheels: '怪物赛车',
    SMG_munchkins: '小小矮人',
    SMG_mysticDreams: '神秘的梦',
    SMG_oinkCountryLove: '呼噜噜爱上乡下 ',
    SMG_ourDaysA: '有你的校园',
    SMG_partyIsland: '派对岛屿',
    SMG_peekABoo5Reel: '躲猫猫',
    SMG_pistoleras: '持枪王者',
    SMG_playboy: '花花公子',
    SMG_playboyGold: 'Playboy 黄金',
    SMG_pokeTheGuy: '进击的猿人',
    SMG_pollenParty: '花粉之国',
    SMG_prettyKitty: '漂亮猫咪',
    SMG_purePlatinum: '白金俱乐部',
    SMG_reelGems: '宝石之轮',
    SMG_reelSpinner: '旋转大战',
    SMG_reelStrike: '海洋争夺',
    SMG_ReelTalent: '真正高手',
    SMG_reelThunder: '雷霆风暴',
    SMG_relicSeekers: '探陵人',
    SMG_retroReels: '经典老虎机',
    SMG_retroReelsDiamondGlitz: '鑽石浮华',
    SMG_retroReelsExtremeHeat: '酷热经典',
    SMG_rhymingReelsGeorgiePorgie: '乔治与柏志',
    SMG_rhymingReelsHeartsAndTarts: '女皇之心',
    SMG_rivieraRiches: '瑞维拉财宝',
    SMG_robinOfSherwoodOnlineSlot: '舍伍德的罗宾',
    SMG_romanovRiches: '罗曼诺夫财富',
    SMG_rugbyStar: '橄榄球明星',
    SMG_santaPaws: '冰雪圣诞村',
    SMG_santasWildRide: '圣诞老人的百搭摩托车',
    SMG_scrooge: '小气财神',
    SMG_secretAdmirer: '暗恋',
    SMG_secretRomance: '秘密爱慕者',
    SMG_shanghaiBeauty: '上海美女',
    SMG_shogunofTime: '时界门之将军',
    SMG_shoot: '射门高手',
    SMG_showdownSaloon: '对决沙龙',
    SMG_silverFang: '银狼',
    SMG_silverLioness4x: '银色雌狮4x',
    SMG_sixAcrobats: '杂技群英会',
    SMG_soManyMonsters: '好多怪兽',
    SMG_soMuchCandy: '好多糖果',
    SMG_soMuchSushi: '好多寿司',
    SMG_springBreak: '春假时光',
    SMG_stardust: '星尘',
    SMG_starlightKiss: '星光之吻',
    SMG_stashOfTheTitans: '泰坦帝国',
    SMG_sterlingSilver: '纯银',
    SMG_sugarParade: '糖果游行',
    SMG_summerHoliday: '暑假',
    SMG_summertime: '暑假时光',
    SMG_sunQuest: '追寻太阳',
    SMG_sunTide: '太阳征程​',
    SMG_supeItUp: '增强马力',
    SMG_sureWin: '必胜',
    SMG_tallyHo: '狐狸爵士',
    SMG_tarzan: '泰山传奇',
    SMG_tastyStreet: '妹妹很饿',
    SMG_theFinerReelsOfLife: '至尊人生',
    SMG_theGrandJourney: '探险之旅',
    SMG_theGreatAlbini: '伟大魔术师',
    SMG_theHeatIsOn: '极速抢钱',
    SMG_thePhantomOfTheOpera: '歌剧魅影',
    SMG_theRatPack: '鼠帮',
    SMG_theTwistedCircus: '奇妙马戏团',
    SMG_thunderstruck: '雷神',
    SMG_thunderstruck2: '雷霆万钧2',
    SMG_tigersEye: '虎眼',
    SMG_titansOfTheSunHyperion: '太阳神之许珀里翁',
    SMG_titansOfTheSunTheia: '太阳神之忒伊亚',
    SMG_tombRaider: '古墓奇兵',
    SMG_RubyTombRaiderII: '古墓奇兵2',
    SMG_treasureNile: '尼罗河宝藏',
    SMG_treasurePalace: '宝藏宫殿',
    SMG_untamedGiantPanda: '野生熊猫',
    SMG_vegasStripBlackjackGold: '黄金拉斯维加斯大道',
    SMG_vinylCountdown: '黑胶热舞',
    SMG_voila: '哈啰巴黎',
    SMG_wackyPanda: '囧囧熊猫',
    SMG_whatAHoot: '猫头鹰乐园',
    SMG_wickedTalesDarkRed: '黑暗故事:神秘深红',
    SMG_wildOrient: '东方珍兽',
    SMG_wildScarabs: '百搭圣甲虫',
    SMG_winSumDimSum: '开心点心',
    SMG_zombieHoard: '丧尸来袭',
    SMG_RubyThousandIslands: '千岛湖',
    SMG_3CardPoker: '3张牌扑克',
    SMG_3empires: '逐鹿三国',
    SMG_5ReelDriveV90: '侠盗猎车手 V90',
    SMG_AcesfacesPwrPoker: '经典5PK(多组牌)',
    SMG_AlaskanFishingV90: '阿拉斯加垂钓 V90',
    SMG_UntamedGiantPandav90: '野生熊猫',
    SMG_untamedwolfpack: '野狼',
    SMG_UntamedWolfPackV90: '野狼',
    SMG_VinylCountDownV90: '黑胶热舞 V90',
    SMG_RubyWasabiSan: '旋转寿司',
    SMG_westernfrontier: '西部边境',
    SMG_RubyWhackAJackpot: '打地鼠',
    SMG_WhatAHootV90: '猫头鹰乐园 V90',
    SMG_WhatonEarth: '征服者入侵',
    SMG_RubyWheelOfRiches: '财富轮盘',
    SMG_WheelOfWealthSEV90: '财富之轮',
    SMG_WildCatch: '海底捞鱼',
    SMG_RubyWildChampions: '动物冠军',
    SMG_wwizards: '巫师梅林',
    SMG_RubyWitchesWealth: '女巫宝藏',
    SMG_RubyWorldCupMania: '疯狂世界盃',
    SMG_WowPot: '惊爆奖金',
    SMG_zebra: '搞笑斑马',
    SMG_jungleJimAndTheLostSphinx: '丛林吉姆失落的狮身人面像',
    SMG_luckyBachelors: '幸运单身族',
    SFB_LiveFireDice: '火焰快骰豪华版',
    SMG_immortalRomance: '不朽情缘',
    SMG_ImmortalRomancev90: '不朽情缘v90',
    SMG_Galacticons: '迷走星球',
    SMG_RubyGameSetAndScratch: '网球刮刮乐',
    SMG_geniesgems: '精灵宝石',
    SMG_Germinator: '细菌对对碰',
    SMG_GiftRap: '饶舌礼物',
    SMG_GirlsWithGunsV90: '美女抢手丛林激战 V90',
    SMG_girlswithgunsfrozenDawn: '女孩与枪(寒冷的黎明)',
    SMG_GladiatorsGold: '金角斗士',
    SMG_goblinsgold: '黄金哥布林',
    SMG_RubyGoldCoast: '黄金海岸',
    SMG_GoldFactoryV90: '黄金工厂V90',
    SMG_gdragon: '黄金龙',
    SMG_RubyGoldenGhouls: '怪物足球',
    SMG_RubyGoodToGo: '幸运赛车',
    SMG_GopherGoldV90: '黄金地鼠 V90',
    SMG_RubyGrand7s: '777大哥大',
    SMG_RubyGrannyPrix: '阿嬷赛车',
    SMG_GreatGriffin: '伟大的狮鹫兽',
    SMG_GungPow: '财炮连连',
    SMG_RubyHairyFairies: '怪怪小精灵',
    SMG_RubyIWHalloweenies: '万圣节派对 IW',
    SMG_RubyHandToHandCombat: '剪刀石头布',
    SMG_RubyHappyNewYear: '新年快乐',
    SMG_RubyHeavyMetal: '重金属',
    SMG_Hexaline: '彩色蜂窝',
    SMG_HighFive: '幸运连线',
    SMG_HighLimitBaccarat: '高限额百家乐',
    SMG_hohoho: '圣诞节狂欢',
    SMG_HoHoHoV90: '圣诞节狂欢 V90',
    SMG_RubyMHHoldemHigh: '德洲扑克(多组牌)',
    SMG_HotasHades: '地府烈焰',
    SMG_HotInk: '刺青酒店',
    SMG_HotInkV90: '刺青酒店 V90',
    SMG_RubyHotShot: '热门打击',
    SMG_InItToWinIt: '富豪生活',
    SMG_IWCardSelectorCashapillar: '刮刮乐玩家 - Cashapillar',
    'SMG_IWCardSelector DawnoftheBread': '刮刮乐玩家 - Dawn of the Bread',
    SMG_IWCardSelectorFreezingFuzzballs: '刮刮乐玩家 - Freezing Fuzzballs',
    SMG_IWCardSelectorGrannyPrix: '刮刮乐玩家 - Granny Prix',
    SMG_IWCardSelectorMumbaiMagic: '刮刮乐玩家 - Mumbai Magic',
    SMG_IWCardSelectorPharaohsGems: '刮刮乐玩家 - Pharaohs Gems',
    SMG_IWCardSelectorSlamFunk: '刮刮乐玩家 - Slam Funk',
    'SMG_IWCardSelectorSuper Zeroes': '刮刮乐玩家 - Super Zeroes',
    SMG_IWCardSelectorTurtleyAwesome: '刮刮乐玩家 - Turtley Awesome',
    SMG_IWCardSelectorWhackaJackpot: '刮刮乐玩家 - Whack-a-Jackpot',
    SMG_IWCardSelectorWildChampions: '刮刮乐玩家 - Wild Champions',
    SMG_IWCardSelectorBeerFest: '刮刮乐玩家- Beer Fest',
    SMG_IWCardSelectorBigBreak: '刮刮乐玩家 - Big Break',
    SMG_IWCardSelectorFoamyFortunes: '刮刮乐玩家- Foamy Fortunes',
    SMG_IWCardSelectorGoldenGhouls: '刮刮乐玩家- Golden Ghouls',
    SMG_IWCardSelectorHairyFairies: '刮刮乐玩家- Hairy Fairies',
    SMG_IWCardSelectorHalloweenies: '刮刮乐玩家- Halloweenies',
    SMG_IrishEyes: '爱尔兰之眼',
    SMG_RRJackAndJill: '杰克与吉儿',
    SMG_RubyJackintheBox: '小丑杰克',
    SMG_JackpotDeuces: '彩金二王',
    SMG_jexpress: '奖金快递',
    SMG_Jackspwrpoker: '对J高手5PK(多组牌)',
    SMG_JasonAndTheGoldenFleece: '金毛骑士团',
    SMG_jesters: '欢乐小丑',
    SMG_RubyJewelThief: '珠宝大盗',
    SMG_JewelsOfTheOrient: '东方之珠',
    SMG_RubyJingleBells: '欢乐圣诞',
    SMG_Jokerpok: '双倍小丑百搭5PK',
    SMG_JokerPwrPoker: '小丑扑克',
    SMG_joyofsix: '欢乐骰子乐',
    SMG_RubyJungleJim: '丛林吉姆',
    SMG_jurassicbr: '侏儸纪彩金 (超级)',
    SMG_jurassicjackpot: '侏儸纪彩金',
    SMG_jurassicpark: '侏儸纪公园',
    SMG_KaratePig: '空手道猪',
    SMG_KaratePigv90: '空手道猪 V90',
    SMG_RubyKashatoa: '火山弹珠台',
    SMG_Keno: '快乐彩',
    SMG_RubyKillerClubs: '杀手俱乐部',
    SMG_KingCashaLot: '富裕的国王',
    SMG_LadiesNiteV90: '淑女派对 V90',
    SMG_LeaguesOfFortune: '富翁联盟',
    SMG_RubyLegacy: '遗产争夺',
    SMG_LegendOfOlympus: '奥林帕斯山的传说',
    SMG_lions: '万兽之王',
    SMG_LooseCannon: '海盗王',
    SMG_LotsofLoot: '现金累积(3线)',
    SMG_LotsaLoot5Reel: '现金累积(5线)',
    SMG_LouisianaDouble: '路易斯安那5PK',
    SMG_LoveBugs: '爱虫',
    SMG_LuckyCharmer: '幸运魔术师',
    SMG_LuckyKoiV90: '幸运锦鲤 V90',
    SMG_LuckyLeprechaunsLoot: '幸运小妖',
    SMG_RubyLuckyNumbers: '幸运数字',
    SMG_LuckyWitch: '幸运女巫',
    SMG_LuckyWitchv90: '幸运女巫 V90',
    SMG_MagicBoxes: '魔术箱',
    SMG_magiccharms: '神秘的诱惑',
    SMG_RubyMagicSpell: '魔法学园',
    SMG_majorMillions: '百万富翁',
    SMG_MaxDamageSlot: '外星大袭击',
    SMG_mayanbingo: '玛雅宾果',
    SMG_MegaMoolahIsis: '百万伊西斯',
    SMG_MSBreakDaBankAgain: '多台-银行抢匪2',
    SMG_MSBreakDaBankAgainV90: '多台-银行抢匪2 V90',
    SMG_MerlinsMillions: '梅林的百万奖金',
    SMG_MermaidsMillionsV90: '百万美人鱼 V90',
    SMG_RubyMochaOrange: '橘子摩卡',
    SMG_MonkeyKeno: ' 猴子基诺',
    SMG_monkeys: '猴子的宝藏',
    SMG_MonsterMania: '怪兽大进击',
    SMG_MoonshineV90: '白日梦',
    SMG_MugshotMadness: '面具007',
    SMG_MH3CardPokerGold: '3张牌扑克(多组牌)',
    SMG_HighSpeedPoker: '三张扑克(多组牌)',
    SMG_RubyMHPerfectPairs: '完美欧洲21点',
    SMG_MultiWheelRouletteGold: '複式轮盘',
    SMG_RubyMumbaiMagic: '孟买水晶球',
    SMG_MysticDreamsv90: '神秘的梦 V90',
    SMG_ninjamagic: '忍者法宝',
    SMG_octopays: '海底总动员',
    SMG_OctopaysV90: '海底总动员 V90',
    SMG_RubyOffsideAndSeek: '越位罚球',
    SMG_RROldKingColeV90: '老国王柯尔',
    SMG_OrientalFortune: '东方之旅',
    'SMG_ParadiseFound ': '寻找天堂',
    SMG_partytime: '派对时刻',
    SMG_RubyPeekaBoo: '抓鬼躲猫猫',
    SMG_PenguinSplash: '企鹅家族',
    SMG_phantomcash: '幻影现金',
    SMG_PharaohBingo: '法老宾果',
    SMG_pharaohs: '法老王的财富',
    SMG_RubyPharaohsGems: '法老王的宝物',
    SMG_PiggyFortunes: '三隻小猪',
    SMG_pirates: '海盗天堂',
    SMG_RubyPlunderTheSea: '掠夺海洋',
    SMG_PokerPursuit: '扑克追击',
    SMG_PollenNation: '蜜蜂乐园',
    SMG_PremierRacing: '超级赛马',
    SMG_RubyPremierRoulette: '总统轮盘',
    SMG_PremierRouletteDE: '鑽石总统轮盘',
    SMG_PremierTrotting: '快步马驾车赛',
    SMG_RubyPrimePropertyV90: '优质房仲',
    SMG_rabbitinthehat: '魔术兔',
    SMG_RacingForPinks: '为粉红而战',
    SMG_RacingForPinksV90: '为粉红而战 V90',
    SMG_RamessesRiches: '拉美西斯宝藏',
    SMG_RubyRapidReels: '钱海遨游',
    SMG_RedDog: '红狗',
    SMG_redhotdevil: '炙热魔鬼',
    SMG_ReelGemsV90: '宝石之轮 V90',
    SMG_ReelStrikeV90: '海洋争夺 V90',
    SMG_royce: '劳斯莱斯',
    SMG_RubyRingsnRoses: '玫瑰之戒',
    SMG_RiverofRiches: '金字塔的财富',
    SMG_robojack: '机器人杰克',
    SMG_RockTheBoat: '摇滚航道',
    SMG_romanriches: '罗马富豪',
    SMG_Roulette: '欧洲轮盘',
    SMG_RubyRoyalDerby: '皇家赛马',
    SMG_RubySamuraiSevens: '七武士',
    SMG_Scratch: '刮刮乐',
    SMG_SecretSanta: '神秘圣诞老人',
    SMG_Serenity: '宁静',
    SMG_oceans: '幸运海洋',
    SMG_Sicbo: '骰宝',
    SMG_SilverFangV90: '银狼 V90',
    SMG_3CardPokerGold: '3张牌扑克(Gold)',
    SMG_RubySixShooterLooter: '强劫西部银行',
    SMG_RubySixShooterLooterGold: '强劫西部银行(Gold)',
    SMG_RubySizzlingScorpions: '刺热蝎子',
    SMG_SkullDuggery: '神鬼奇航',
    SMG_RubySlamFunk: '放克篮球',
    SMG_RubySoccerSafari: '动物足球',
    SMG_RubySonicBoom: '音速战机',
    SMG_SovereignOfTheSevenSeas: '七海的主权',
    SMG_RubySpaceEvader: '外星探险',
    SMG_RubySpaceEvaderGold: '外星探险(Gold)',
    SMG_Spectacular: '华丽剧场',
    SMG_RubySpellBound: '魔法阵',
    SMG_Spingo: '义大利轮盘',
    SpringBreakV90: '春假时光 V90',
    SMG_StarlightKissV90: '星光之吻 V90',
    SMG_StarscapeV90: '星际争霸战',
    SMG_SterlingSilver3D: '纯银3D',
    SMG_SunQuestV90: '追寻太阳 V90',
    SMG_RubySuperBonusBingo: '超级宾果',
    SMG_RubySuperZeroes: '超级零英雄',
    SMG_SweetHarvest: '大丰收',
    SMG_TensorBetter: '对十天王5PK',
    SMG_TensorBetterPwrPoker: '对十天王5PK(多組牌)',
    SMG_TheBermudaMysteries: '神秘的百慕达',
    SMG_TheFinerReelsOfLifeV90: '至尊人生 V90',
    SMG_RubyThreeWheeler: '三转轮',
    SMG_ThroneOfEgypt: '埃及王朝',
    SMG_ThroneOfEgyptv90: '埃及王朝 V90',
    SMG_ThunderStruck2V90: '雷霆万钧2 V90',
    SMG_ThunderStruckV90: '雷神 V90',
    SMG_TigerMoon: '老虎月亮',
    SMG_TombRaiderV90: '古墓奇兵 V90',
    'SMG_TootinCarMan ': '发财法老',
    SMG_RubyTotemTreasureV90: '宝藏图腾',
    SMG_Triangulation: '彩色三角',
    SMG_trickortreat: '不给糖就捣蛋',
    SMG_TripleMagic: '三重魔力',
    SMG_RubyTriplePocketPoker: '换牌德扑',
    SMG_Tunzamunni: '图拉姆尼',
    SMG_RubyTurtleyAwesome: '冲浪海龟',
    SMG_RubyTwister: '扭转世界',
    SMG_UntamedBengalTiger: '野性孟加拉虎',
    SMG_UntamedBengalTigerV90: '野性孟加拉虎',
    SMG_UntamedCrownedEagle: '狂野之鹰',
    SMG_UntamedCrownedEagleV90: '狂野之鹰',
    SMG_RubyAllAces: 'All Aces 超级红利5PK',
    SMG_RubyAllAmerican: '美式经典5PK',
    SMG_ArcticAgents: '北极特务',
    SMG_ArcticFortune: '北极祕宝',
    SMG_RubyAstronomical: '天王星',
    SMG_Avalon2: '阿瓦隆2',
    SMG_AvalonV90: '阿瓦隆 V90',
    SMG_Baccarat: '百家乐',
    SMG_BaccaratGold: '咪牌百家乐',
    SMG_RubyBallisticBingo: '弹道宾果',
    SMG_RubyBarBarBlackSheep: '咩咩黑羊',
    SMG_BarsAndStripesV90: '条条纹纹 V90',
    SMG_RubyBeerFest: '啤酒节',
    SMG_Belissimo: '贝利西餐厅',
    SMG_big5: '丛林五霸',
    SMG_RubyBigBreak: '丛林早餐',
    SMG_RubyIWBigBreak: '冲浪度假',
    SMG_BigChef: '大厨师',
    SMG_RubyBigKahunaSnakesAndLadders: '森林之王-蛇和梯子',
    SMG_BigKahunav90: '森林之王 V90',
    SMG_BigTopV90: '马戏团 V90',
    SMG_RubyBingoBonanza: '致富宾果',
    SMG_BlackjackBonanza: '21点矿坑',
    SMG_Bobby7s: '幸运鲍比',
    SMG_RubyBonusPoker: '双倍红利5PK',
    SMG_RubyBonusPokerDeluxe: '红利5PK',
    SMG_BootyTime: '宝藏时间',
    SMG_RubyBowledOver: '保龄球',
    SMG_BreakAwayV90: '冰球突破',
    SMG_RubyBreakDaBankAgainV90: '银行抢匪2 V90',
    SMG_Bridezilla: '新娘瑞拉',
    SMG_BubbleBonanza: '泡泡矿坑',
    SMG_RubyBullsEye: '命中红星',
    SMG_RubyBunnyBoiler: '兔子逃脱',
    SMG_RubyBunnyBoilerGold: '兔子逃脱(Gold)',
    SMG_RubyBurningDesireV90: '燃烧的慾望 V90',
    SMG_BushTelegraphV90: '丛林快讯 V90',
    SMG_ButterFlies: '蝴蝶仙境',
    SMG_RubyCabinFever: '松鼠幼稚园',
    SMG_RubyCaptainCash: '现金船长',
    SMG_RubyCardClimber: '晋级扑克',
    SMG_Carnavalv90: '狂欢节 V90',
    SMG_CashClams: '贝壳大亨',
    SMG_CashSplash: '现金飞溅(3线)',
    SMG_CashanovaV90: '金鸡',
    SMG_RubyIWCashapillar: '昆虫派对 IW',
    SMG_CashapillarV90: '昆虫派对 V90',
    SMG_CastleBuilder: '城堡建筑师',
    SMG_RubyChainMail: '锁子甲',
    SMG_Chainmailnew: '锁子甲',
    SMG_CherryRed: '樱桃红',
    SMG_RubyChiefsFortune: '酋长的财富',
    SMG_chiefsmagic: '魔术酋长',
    SMG_RubyCityofGold: '黄金城市',
    SMG_coolbuck: '酷巴克',
    SMG_cosmicc: '宇宙猫',
    SMG_crackerjack: '红利炮竹',
    SMG_RubyCrazy80s: '疯狂80年代',
    SMG_CrazyChameleonsV90: '疯狂变色龙 V90',
    SMG_crocs: '疯狂鳄鱼',
    SMG_Crocodopolis: '魔鳄大帝',
    SMG_CrownAndAnchor: '皇冠骰子',
    SMG_RubyCryptCrusade: '地穴探索',
    SMG_RubyCryptCrusadeGold: '地穴探索(Gold)',
    SMG_RubyCutesyPie: '海派甜心',
    SMG_ProgCyberstud: '加勒比海',
    SMG_Cyberstud: '加勒比海扑克',
    SMG_RubyDawnOfTheBread: '恐怖麵包',
    SMG_RubyDeckTheHallsV90: '圣诞大餐 V90',
    SMG_DeucesJokerPwrPoker: '百搭二王与小丑 (多组)',
    SMG_DeucesandJoker: '百搭二王与小丑',
    SMG_DeucesWildPwrPoker: '百搭二王(多组)',
    SMG_RubyDiamondDeal: '鑽石交易',
    SMG_RubyDiamond7s: '幸运鑽石',
    SMG_DinoMight: '迪诺魔法门',
    SMG_DoctorLove: '爱情医生',
    SMG_RubyDogfather: '警犬教父',
    SMG_RubyDonDeal: '内线交易',
    SMG_RubyDoubleBonusPoker: '双倍红利5PK',
    SMG_RubyDoubleDose: '双倍剂量',
    SMG_DoubleJoker: '小丑百搭5PK',
    SMG_DoubleJokerPwrPoker: '小丑百搭5PK(多组牌)',
    SMG_dm: '双倍魔术',
    SMG_DrWattsUp: '瓦特博士',
    SMG_RubyDragonsFortune: '圣龙赐福',
    SMG_ElectricDiva: '电音歌后',
    SMG_RubyElementals: '元素超人',
    SMG_EnchantedMermaid: '魔法美人鱼',
    SMG_EnchantedWoods: '魔法森林',
    SMG_RubyFairyRing: '妖精之环',
    SMG_fan7: '炫目缤纷',
    SMG_FatLadySings: '丰满歌手',
    SMG_FireHawk: '烈火雄鹰',
    SMG_FlipCard: '扑克游戏',
    SMG_RubyFloriditaFandango: '舞动佛罗里达',
    SMG_RubyFlosDiner: '佛洛晚餐',
    SMG_flowerpower: '力量之花',
    SMG_RubyFlyingAce: '王牌飞官',
    SMG_RubyFoamyFortunes: '好运泡沫',
    SMG_ForsakenKingdom: '失落的国度',
    SMG_RubyFortuna: '命运女神',
    SMG_FortuneCookie: '幸运饼乾',
    SMG_RubyFreeSpirit: '自由精神-财富之轮',
    SMG_RubyFreezingFuzzballs: '急冻冰块',
    SMG_FrenchRoulette: '法式轮盘',
    SMG_RubyFrootLoot: '水果大战',
    SMG_RubyFrostBite: '寒冰精灵',
    SMG_fruits: '水果老虎机',
    SMG_FruitFiesta: '水果嘉年华(3线)',
    SMG_FruitFiesta5Reel: '水果嘉年华(5线)',
    SMG_RubyFruitSalad: '水果沙拉',
    SMG_RubyFunHouse: '游乐园',
    SMF_BirdHunting: '捕鸟联盟',
    SMF_FishingJoy: '捕鱼联盟',
    SMG_9masksOfFire: '烈焰面具',
    SMG_longMuFortunes: '龙母财富',
    SMG_breakAwayLuckyWilds: '冰球突破幸运百搭',
    SMG_luckyTwinsJackpot: '幸运双星大奖',
    SMG_pingPongStar: '乒乓巨星',
    SMG_treasureDash: '逃宝街机游戏',
    SMG_auroraWilds: '极光百搭',
    SMG_bananaOdyssey: '香蕉奥德赛',
    SMG_bookOfOzLockNSpin: 'Oz之书锁定并旋转',
    SMG_breakDaBankAgainRespin: '再抢银行再旋转',
    SMG_fortuneGirl: ' 金库甜心',
    SMG_LegendOftheMoonLovers: '奔月传说',
    SMG_magicOfSahara: '魔力撒哈拉',
    SMG_maxDamageArcade: '终结侵略者',
    SMG_petsGoWild: '狂野宠物',
    SMG_playboyGoldJackpots: 'Playboy黃金大奖',
    SMG_queenOfTheCrystalRays: '水晶射线女王',
    SMG_sherlockOfLondonOnlineSlot: '伦敦的夏洛克',
    SMG_tikiVikings: '蒂基维京',
    SMG_treasuresOfLionCity: '海底宝城',
    SMG_villagePeople: '型男舞步',
    SMG_footballStarDeluxe: '足球明星豪华版',
    SMG_777superBigBuildUpDeluxe: '777超级BIGBuildUp™豪华版™',
    SMG_777MegaDeluxe: '777绝对豪华',
    "SMG_luckyTwinsPowerClusters": "幸运双星 PowerClusters",
    "SMG_massiveGold": "巨额黄金",
    "SMG_sharkPlatinum": "铂金鲨鱼",
    "SMG_drWattsUp": "瓦特博士",
    "SMG_luckyLittleDragons": "幸运小龙",
    "SMG_queenOfCairo": "开罗神后",
    "SMG_108HeroesWaterMargin": "新水浒传",
    "SFG_WDGoldenFuwaFishing": "万达金福娃捕鱼",
    "SMG_almightyZeusWilds": "全能宙斯百搭",
    "SMG_crazyRichTigers": "富豪老虎",
    "SMG_miningPotsOfGold": "金罐淘金热",
    "SMG_chroniclesOfOlympusIIZeus": "奥林匹斯 编年史 宙斯",
    "SMG_goldBlitzExtreme": "黄金闪电 终极版",
    "SMG_godsPyramidsPowerCombo": "众神和金字塔",
    "SMG_dragonsLoot": "龙之宝藏",
    "SMG_fireAndRosesJollyJoker": "火焰与玫瑰欢乐小丑",
    "SMG_9EnchantedBeans": "9个魔力豆子",
    "SFG_WDMerryIslandFishing": "万达快乐岛捕鱼",
    "SMG_fishinPotsOfGoldGoldBlitz": "金罐钓钓乐 黄金 闪电",
    "SMG_breakAwayMax": "冰球突破十万路",
    "SMG_pongPongMahjong": "碰碰麻将",
    "SMG_legendaryTreasures": "神秘宝藏",
    "SMG_queensOfRa": "太阳神 女王 POWER COMBO",
    "SMG_gemFireFrenzy": "疯狂宝石烈焰",
    "SMG_theEternalWidow": "永恒寡妇",
    "SMG_anvilAndOre": "铁砧&矿石",
    "SMG_unusualSuspects": "非常嫌疑犯",
    "SMG_asgardianFire": "阿斯加德之火",
    "SMG_tikiTikiBoom": "提基大爆炸",
    "SMG_9PotsOfGoldMegaways": "9罐黄金MEGAWAYS",
    "SFG_WDGoldenTyrantFishing": "万达金霸王捕鱼",
    "SMG_championsOfOlympus": "奥林匹斯斗士",
    "SMG_amazonLostGold": "亚马孙失落的黄金",
    "SMG_dogDays": "三伏天",
    "SMG_andvariTheMagicRing": "安德瓦里：魔法戒指",
    "SMG_fishEmUp": "快乐渔夫",
    "SMG_magicJokers": "魔法小丑",
    "SMG_stormToRiches": "暴富之路",
    "SMG_tippyTavern": "疯狂酒馆",
    "SMG_flyX": "飞翔X",
    "SMG_almightyZeusEmpire": "全能宙斯帝国",
    "SMG_grannyVsZombies": "僵尸大战",
    "SMG_spinSpinSugar": "转转 糖果",
    "SMG_amazingPharaoh": "神奇法老",
    "SMG_sugarCrazeBonanza": "甜蜜糖果热潮",
    "SMG_romeFightForGold": "罗马：黄金之战",
    "SMG_fortunePikeGold": "财富派克黄金",
    "SMG_bubbleBeez": "趣味蜜蜂",
    "SFG_WDGoldenFortuneFishing": "万达金财神捕鱼",
    "SMG_chilliPepeHotStacks": "辣椒堆叠乐",
    "SMG_monkeyBonanza": "幸运香蕉",
    "SMG_galloGoldMegaways": "布鲁诺 的加洛 黄金 Megaways",
    "SMG_leprechaunStrike": "妖精出击",
    "SMG_fireAndRosesJoker": "火焰与玫瑰小丑",
    "SMG_happyLuckyCats": "快乐招财猫",
    "SMG_chestsOfGold": "黄金宝箱: POWER COMBO",
    "SMG_wildfireWinsExtreme": "极度野火奖金",
    "SMG_candyRushWilds": "欢乐糖果百搭",
    "SMG_wolfBlazeMegaways": "烈焰战狼 Megaways",
    "SMG_goldBlitz": "黄金 闪电",
    "SMG_dragonsKeep": "龙之 堡垒",
    "SMG_dokiDokiFireworks": "心动烟花祭",
    "SMG_bisonMoon": "野牛圆月",
    "SMG_trojanKingdom": "特洛伊王国",
    "SMG_mastersOfOlympus": "奥林匹斯山之主",
    "SMG_playboyWilds": "Playboy 百搭",
    "SMG_tigersIce": "冰雪猛虎",
    "SFG_WPHilo": "王牌Hilo",
    "SMG_thunderstruckStormchaser": "雷霆万钧猎风战士",
    "SMG_fishinChristmasPotsOfGold": "金罐钓钓乐圣诞版",
    "SMG_fionasChristmasFortune": "菲奥娜的圣诞财富",
    "SMG_luckyTwinsLinkAndWin": "幸运双星 Link&Win",
    "SMG_fishinBiggerPots": "金罐钓钓乐升级版",
    "SMG_sonicLinks": "音速连接 ",
    "SFG_WPRummy": "王牌Rummy",
    "SFG_WPPaiKang": "王牌Pai Kang",
    "SMG_robinHoodsHeroes": "罗宾汉英雄",
    "SMG_aquanauts": "深海行者",
    "SMG_starliteFruits": "星光水果",
    "SMG_jadeShuriken": "翡翠勇士",
    "SMG_amazonKingdom": "亚马逊王国",
    "SMG_kitsuneAdventure": "小仙狐大冒险",
    "SFG_WPPokDeng": "王牌Pok Deng",
    "SMG_goldenDragons": "金龙呈祥",
    "SMG_arkOfRa": "埃及守护神",
    "SMG_777Surge": "777 电光火石",
    "SMG_boltXUP": "闪电 X UP",
    "SMG_luckyLeprechaunClusters": "幸运矮妖精集群奖金",
    "SMG_immortalRomanceVideoBingo": "不朽情缘视频宾果",
    "SMG_maskOfAmun": "阿蒙的面具",
    "SMG_divineRichesHelios": "神圣财富 - 赫利俄斯",
    "SFG_WPGaple": "王牌Gaple",
    "SMG_wildfireWins": "野火灼金",
    "SMG_circusJugglersJackpots": "⻢戏小丑累积奖金",
    "SMG_abraCatDabra": "\t猫咪咒语",
    "SMG_wildWildRomance": "百搭狂野浪漫",
    "SMG_lightningFortunes": "闪电财富",
    "SMG_25000Talons": "25000鹰爪",
    "SMG_cashNRichesMegaways": "现金与财富 Megaways",
    "SMG_15Tridents": "15个三叉戟",
    "SMG_dungeonsAndDiamonds": "地下城与钻石",
    "SMG_aztecFalls": "阿兹特克天降大奖",
    "SMG_mastersOfValhalla": "瓦尔哈拉的统治者",
    "SMG_dokiDokiParfait": "心动芭菲",
    "SFG_WPXocDia": "王牌 色碟",
    "SMG_oniHunterNightSakura": "鬼狩夜樱",
    "SMG_fishinPotsOfGold": "金罐钓钓乐",
    "SMG_kingsOfCrystals": "水晶之王",
    "SFG_WPFishPrawnCrab": "王牌鱼虾蟹",
    "SMG_jurassicParkGold": "Jurassic Park:黄金",
    "SMG_5StarKnockout": "5星淘汰",
    "SMG_agentJaneBlondeMaxVolume": "特工简·布隆德最大音量",
    "SMG_basketballStarWilds": "篮球巨星百搭",
    "SMG_kodiakKingdom": "科迪亚克王国",
    "SMG_bigBoomRiches": "财富大爆炸",
    "SMG_squealinRiches": "喝彩的旺财",
    "SMG_4DiamondBlues": "4钻石蓝调 Megaways",
    "SMG_9masksOfFireHyperSpins": "9 个烈焰面具 HyperSpins",
    "SMG_catClans": "猫咪部落",
    "SMG_bookOfMrsClaus": "圣诞老婆婆之书",
    "SMG_luckyClucks": "幸运鸡",
    "SMG_chroniclesOfOlympusXUP": "奥林匹斯編年史 X UP",
    "SMG_wweLegendsLinkWin": "WWE摔角传奇",
    "SMG_fortuneRush": "瑞龙献宝",
    "SMG_10000Wishes": "10000个愿望",
    "SMG_777RoyalWheel": "777皇家幸运轮",
    "SMG_aDarkMatter": "黑暗阴影",
    "SMG_aTaleOfElves": "精灵传说",
    "SMG_adventuresOfDoubloonIsland": "秘密行动-雪诺和塞布尔",
    "SMG_africaXUP": "非洲 X UP",
    "SMG_alchemyFortunes": "炼金财宝",
    "SMG_amazingLinkApollo": "神奇 连环 阿波罗",
    "SMG_amazingLinkZeus": "神奇连环宙斯",
    "SMG_ancientFortunesPoseidonMegaways": "古代财富 Poseidon Megaways",
    "SMG_astroLegendsLyraandErion": "太空传奇-莱拉和叶莉昂",
    "SMG_atlantisRising": "亚特兰蒂斯 崛起",
    "SMG_blazingMammoth": "炽热猛犸象",
    "SMG_boatofFortune": "金龙财宝",
    "SMG_bookOfKingArthur": "亚瑟王之书",
    "SMG_boomPirates": "炸弹海盗",
    "SMG_breakDaBankAgainMegaways": "再抢银行 MEGAWAYS",
    "SMG_carnavalJackpot": "嘉年华大奖",
    "SMG_cricketStarScratch": "板球明星 Scratch",
    "SMG_diamondKingJackpots": "钻石之王",
    "SMG_egyptianTombs": "埃及古墓",
    "SMG_emeraldGold": "金翠绿",
    "SMG_emperorOfTheSeaDeluxe": "青龙出海豪华版",
    "SMG_fireForge": "烈火锻金",
    "SFG_ForestParty": "森林舞会",
    "SMG_forgottenIsland": "遗忘之岛Megaways",
    "SMG_gemsAndDragons": "魔龙宝珠",
    "SMG_goldCollector": "淘金者",
    "SMG_goldenStallion": "金色骏马",
    "SMG_hyperGold": "超猎黄金",
    "SMG_incanAdventure": "挖到宝",
    "SMG_theIncredibleBalloonMachine": "不可思议的气球机",
    "SMG_legacyOfOz": "奥兹Oz传奇",
    "SMG_loade": "腰缠万贯",
    "SMG_luckyRichesHyperspins": "幸运富豪 Hyperspins",
    "SMG_luckyTwinsCatcher": "幸运双星接财神",
    "SMG_luckyTwinsWilds": "幸运双星百搭",
    "SMG_mauiMischief": "毛伊大乱斗",
    "SMG_monsterBlast": "怪物爆炸",
    "SMG_neptunesRichesOceanOfWilds": "海王星宝藏狂野之海",
    "SMG_odinsRiches": "奥丁的财宝",
    "SMG_oniHunter": "鬼狩",
    "SMG_oniHunterPlus": "鬼狩加強版",
    "SMG_queenofAlexandria": "亚历山大女王",
    "SMG_serengetiGold": "塞伦盖蒂黄金",
    "SMG_silverSeas": "银色之海",
    "SMG_soccerStriker": "足球前锋",
    "SMG_solarWilds": "太阳系百搭符号",
    "SMG_thunderstruckWildLightning": "雷霆万钧之百搭闪电",
    "SMG_tikiReward": "提基奖励",
    "SFG_WDFuWaFishing": "万达福娃捕鱼",
    "SFG_WDGoldBlastFishing": "万达爆金捕鱼",
  };

  // Sexy体育
  static SEXYSportsNames = {
    darts: '飞镖',
    egames: '电子竞技',
    fieldhockey: '曲棍球',
    floorball: '旱地冰球',
    football: '橄榄球',
    futsal: '五人制足球',
    golf: '高尔夫球',
    handball: '手球',
    icehockey: '冰上曲棍球',
    livebetting: '现场滚球',
    rugbyleague: '橄榄球联盟',
    rugbyunion: '橄榄球联合会',
    snooker: '桌球',
    soccer: '足球',
    tabletennis: '乒乓球',
    tennis: '网球',
    volleyball: '排球',
    waterpolo: '水球',
    rugby: '英式橄榄球联盟',
    aussierules: '澳式橄榄球',
    badminton: '羽毛球',
    bandy: '班迪球',
    baseball: '棒球',
    basketball: '篮球',
    beachvolleyball: '沙滩排球',
    cricket: '板球',
    chuanguan: '串关'
  };

  // trc赛马
  static TRCBetTypes = {
    WIN: '独赢',
    PLC: '位置'
  };

  // 沙巴体育
  static SBTGameNames = {
    1: '足球',
    2: '篮球',
    3: '美式足球',
    4: '冰上曲棍球',
    5: '网球',
    6: '排球',
    7: '斯诺克/台球',
    8: '棒球',
    9: '羽毛球',
    10: '高尔夫球',
    11: '赛车',
    12: '游泳',
    13: '政治',
    14: '水球',
    15: '跳水',
    16: '拳击',
    17: '射箭',
    18: '乒乓球',
    19: '举重',
    20: '皮划艇',
    21: '体操',
    22: '田径',
    23: '马术',
    24: '手球',
    25: '飞镖',
    26: '橄榄球',
    28: '曲棍球',
    29: '冬季运动',
    30: '壁球',
    31: '娱乐',
    32: '篮网球',
    33: '自行车',
    34: '击剑',
    35: '柔道',
    36: '现代五项',
    37: '划船',
    38: '帆船',
    39: '射击',
    40: '跆拳道',
    41: '铁人三项',
    42: '角力',
    43: '电子竞技',
    44: '泰拳',
    47: '卡巴迪',
    48: '藤球',
    49: '室内足球',
    50: '板球',
    51: '沙滩足球',
    // 百联赛
    161: '百练赛',
    164: '	快乐5',
    // 虚拟运动
    180: '虚拟足球',
    181: '虚拟赛马',
    182: '虚拟赛狗',
    183: '虚拟沙地摩托车',
    184: '虚拟赛车',
    185: '虚拟自行车',
    186: '虚拟网球',
    190: '虚拟足球联赛',
    191: '虚拟足球国家杯',
    192: '虚拟足球世界杯',
    193: '虚拟篮球',
    194: '虚拟足球亚洲杯',
    195: '虚拟赛狗',
    196: '虚拟网球',
    199: 'Virtual Sports Parlay',
    // 巨彩
    204: 'Colossus 足彩',
    // 电子游戏
    208: '电子游戏',
    209: '迷你电子游戏',
    210: '手机版电子游戏',
    251: '	娱乐城',
    // VB
    212: 'Macau Games',
    // 欧博
    211: '欧博',
    // Pragmatic Play
    248: 'Pragmatic Play',
    // 快乐彩
    202: '快乐彩',
    // 彩票
    220: '彩票',
    // 捕鱼天下
    219: '捕鱼天下'
  };
  static SBTGameTypes = {
    '1': '让球',
    '2': '单双盘',
    '3': '大小盘',
    '4': '波胆 (足球)',
    '5': '全场.标准盘',
    '6': '总进球 (足球)',
    '7': '上半场让球',
    '8': '上半场大小盘',
    '10': '优胜冠军',
    '11': '总角球数 (足球)',
    '12': '上半场.单双盘',
    '13': '零失球 (足球)',
    '14': '零失球 (足球)',
    '15': '上半场.标准盘',
    '16': '半场.全场 (足球)',
    '17': '下半场让球 (足球)',
    '18': '下半场大小盘 (足球)',
    '19': 'Substitutes',
    '20': '独赢',
    '21': '上半场独赢',
    '22': '得下一分 (足球)',
    '23': '下一角球',
    '24': '双重机会 (足球)',
    '25': '获胜球队 (足球)',
    '26': '双方/一方/两者皆不得分 (足球)',
    '27': '零失球的胜方 (足球)',
    '28': '三项让分投注 (足球)',
    '29': '串关',
    '30': '上半场波胆 (足球)',
    '121': '主队 (不获胜球队) (足球)',
    '122': '客队 (不获胜球队) (足球)',
    '123': '和局/不是和局 (足球)',
    '124': '全场 1X2 亚洲盘 (足球)',
    '125': '上半场 1X2 亚洲盘 (足球)',
    '126': '上半场总进球 (足球)',
    '127': '上半场最先进球 / 最后进球 (足球)',
    '128': '半场 / 全场 单/双 (足球)',
    '133/438': '主队胜出两个半场 (足球)',
    '134/439': '客队胜出两个半场 (足球)',
    '135': '点球决胜 (足球)',
    '140/442': '进球最多的半场 (足球)',
    '141/443': '主队进球最多的半场 (足球)',
    '142/444': '客队进球最多的半场 (足球)',
    '143': '上半场赛果/总进球数',
    '145': '两队皆进球 (足球)',
    '146/433': '下半场两队皆进球 (足球)',
    '147/436': '主队两个半场皆进球 (足球)',
    '148/437': '客队两个半场皆进球 (足球)',
    '149/440': '主队胜出其中一个半场 (足球)',
    '150/441': '客队胜出其中一个半场 (足球)',
    '151/410': '上半场双重机会 (足球)',
    '152/416': '上半场/全场波胆 (足球)',
    '153': '局数获胜者 (网球)',
    '154': '第 x 盘获胜者 (网球)',
    '155': '第 x 盘局数获胜者 (网球)',
    '156': '第 x 盘局数大小盘 (网球)',
    '157': '单双盘 (足球)',
    '159/406': '准确的总进球 (足球)',
    '160': '下一个进球 (足球)',
    '161/407': '准确的主队总进球 (足球)',
    '162/409': '准确的客队总进球 (足球)',
    '163/144': '赛果 / 总进球大小 (足球)',
    '164': '加时赛下一个进球 (足球)',
    '165': '加时赛上半场波胆 (足球)',
    '166': '加时赛波胆 (足球)',
    '167': '加时赛上半场1x2 (足球)',
    '168': '哪一队可晋级 (足球)',
    '169': '下一个进球时间 (足球)',
    '170': '哪队进球 (足球)',
    '171/408': '净胜球数 (足球)',
    '172/415': '赛果 /最先进球的球队 (足球)',
    '173': '加时赛 – 是/否 (足球)',
    '174': '加时赛/进球 (足球)',
    '175': '获胜的方式 (足球)',
    '176': '前10分钟1X2 (足球)',
    '177/430': '下半场1X2 (足球)',
    '178': '下半场大小盘 (足球)',
    '179': '准确的上半场总进球 (足球)',
    '180': '上半场下一个进球 (足球)',
    '181': '上半场准确的主队进球 (足球)',
    '182': '上半场准确的客队进球 (足球)',
    '184/428': '下半场单双盘 (足球)',
    '185/432': '下半场获胜球队 (足球)',
    '186/431': '下半场双重机会 (足球)',
    '187': '准确的下半场总进球 (足球)',
    '188/427': '上半场双方球队皆进球 (足球)',
    '189/434': '两个半场大1.5球 – 是/否 (足球)',
    '190/435': '两个半场小1.5球 – 是/否 (足球)',
    '191/411': '上半场获胜球队 (足球)',
    '192': '特定时间的第一个进球(10分钟) (足球)',
    '193': '特定时间的第一个进球(15分钟) (足球)',
    '194': '角球单双盘 (足球)',
    '195': '主队准确的角球 (足球)',
    '196': '客队准确的角球 (足球)',
    '197': '主队角球数大小盘 (足球)',
    '198': '客队角球数大小盘 (足球)',
    '199': '角球总数 (足球)',
    '200': '上半场主队准确的角球数 (足球)',
    '201': '上半场客队准确的角球数 (足球)',
    '202': '上半场角球总数 (足球)',
    '203': '上半场角球总数单双盘 (足球)',
    '204': '上半场主队角球数大小盘 (足球)',
    '205': '上半场客队角球数大小盘 (足球)',
    '206': '第一个角球 (足球)',
    '207': '上半场第一个角球 (足球)',
    '208': '最后一个角球 (足球)',
    '209': '上半场最后一个角球 (足球)',
    '210': '球员驱逐离场 (足球)',
    '211': '上半场球员驱逐离场 (足球)',
    '212': '主队球员驱逐离场 (足球)',
    '213': '上半场主队球员驱逐离场 (足球)',
    '214': '客队球员驱逐离场 (足球)',
    '215': '上半场客队球员驱逐离场 (足球)',
    '221': '下一分钟 (足球)',
    '222': '下五分钟 (足球)',
    '223': '下一分钟首先会发生什么 (足球)',
    '224': '下五分钟首先会发生什么 (足球)',
    '225': '下一分钟定点球 (足球)',
    '226': '下一分钟首先会发生哪个组合 (足球)',
    '227': '下五分钟首先会发生哪个组合 (足球)',
    '401': '主队大小盘 (篮球Dec)',
    '402': '客队大小盘 (篮球Dec)',
    '403': '上半场主队大小盘 (篮球Dec)',
    '404': '上半场客队大小盘 (篮球Dec)',
    '405': '下半场波胆 (足球)',
    '412': '准确上半场总进球 (足球)',
    '413': '波胆 (足球)',
    '414': '上半场波胆 (足球)',
    '417': '两队皆进球/赛果 (足球)',
    '418': '两队皆进球/总进球数 (足球)',
    '419': '预测进第一个球的半场 (足球)',
    '420': '主队于哪个半场先进球 (足球)',
    '421': '客队于哪个半场先进球 (足球)',
    '422': '最先进两个球的球队 (足球)',
    '423': '最先进三个球的球队 (足球)',
    '424': '第一个进球方式 (足球)',
    '425': '落后反超获胜的球队 (足球)',
    '426': '上半场净胜球数 (足球)',
    '429': '准确的下半场总进球 (足球)',
    '445': '两队皆进球 上半场/下半场 (足球)',
    '446': '主队均进球 上半场/下半场 (足球)',
    '447': '客队均进球 上半场/下半场 (足球)',
    '448': '最后进球的球队',
    '449': '双重机会/总进球数',
    '450': '单双盘/总进球数',
    '451': '单双盘/总进球数',
    '452': '进球最多的半场(双项)',
    '453': '上半场三项让分投注',
    '454': '双重机会/最先进球的球队',
    '455': '第一个进球时段',
    '456': '上半场两队皆进球/赛果',
    '457': '上半场两队皆进球/总进球数',
    '458': '亚洲 1x2',
    '459': '上半场亚洲1x2',
    '460': '哪个球队会赢5+球',
    '461': '主队大小盘(足球)',
    '462': '客队大小盘(足球)',
    '463': '上半场主队大小盘(足球)',
    '464': '上半场客队大小盘(足球)',
    '467': '半場/全場準確總進球 (足球)',
    '468': '比分讓球盤 (足球)',
    '469': '比分大小盤 (足球)',
    '470': '角球单双盘 (足球)',
    '471': '上半场角球单双盘 (足球)',
    '472': '下半场角球单双盘 (足球)',
    '473': '总角球数 (足球)',
    '474': '上半场总角球数 (足球)',
    '475': '角球附加盘 (足球)',
    '476': '上半场角球附加盘 (足球)',
    '477': '角球三项让分投注 (足球)',
    '478': '上半场角球三项让分投注 (足球)',
    '479': '第一个角球时段 (足球)',
    '481': '下半场第一个角球时段 (足球)',
    '482': '主队角球大小盘 (足球)',
    '483': '客队角球大小盘 (足球)',
    '484': '上半场主队角球大小盘 (足球)',
    '485': '上半场客队角球大小盘 (足球)',
    '486': '角球竞赛 (足球)',
    '487': '上半场角球竞赛 (足球)',
    '488': '第一个角球 (足球)',
    '489': '上半场第一个角球 (足球)',
    '490': '下半场第一个角球 (足球)',
    '491': '最后一个角球 (足球)',
    '492': '上半场最后一个角球 (足球)',
    '493': '上半场/全场 总角球数 (足球)',
    '494': '上半场准确的角球数 (足球)',
    '495': '下半场准确的角球数 (足球)',
    '496': '角球最多的半场 (足球)',
    '497': '角球最多的半场（双项）(足球)',
    '501': '比赛获胜者 (板球)',
    '601': '净胜分数14项 (篮球)',
    '602': '净胜分数12项 (篮球)',
    '603': '赛节得分最高的球队 (篮球)',
    '604': '最先得分球队 (篮球)',
    '605': '最后得分球队 (篮球)',
    '606': '上半场首先获得 X 分 (篮球)',
    '607': '让球下半场首先获得 X 分 (篮球)',
    '608': '上半场净胜分数13项 (篮球)',
    '609': '第 X 节让球盘 (篮球)',
    '610': '第 X 节大小盘 (篮球)',
    '611': '第 X 节单双盘 (篮球)',
    '612': '第 X 节独赢 (篮球)',
    '613': '第 X 节首先获得 Y 分 (篮球)',
    '614': '第 X 节的净胜分数7项 (篮球)',
    '615': '第 X 节主队大小盘 (篮球)',
    '616': '第 X 节客队大小盘 (篮球)',
    '617': '第 X 节最后得分球队 (篮球)',
    '1301': '(独赢) & (获胜者) (网球)',
    '1302': '盘数波胆 (网球)',
    '1303': '盘数获胜者 (网球)',
    '1305': '局数单双盘 (网球)',
    '1306': '局数大小盘 (网球)',
    '1308': '局数获胜者(3rd) (网球)',
    '1311': '第 x 盘获胜者(3rd) (网球)',
    '1312': '第 x 盘大小盘(3rd) (网球)',
    '1316': '第 x 盘局数获胜者 (网球)',
    '1317': '第 x 盘波胆 (网球)',
    '1318': '第 x 盘局数单双盘 (网球)',
    '1324': '第 x 盘 - 第 y 局获胜者 (网球)',
    '9001': '地图 X 胜负盘 (电竞)',
    '9002': '地图 X 击杀总数 - 让分盘 (电竞)',
    '9003': '地图 X 击杀总数 - 大小盘 (电竞)',
    '9004': '地图 X 击杀总数 - 胜负盘 (电竞)',
    '9005': '地图 X 击杀总数 - 单双盘 (电竞)',
    '9006': '地图 X 第一滴血 (电竞)',
    '9007': '地图 X 最先击杀 Y 数量 (电竞)',
    '9008': '地图 X 防御塔 - 让分盘 (电竞)',
    '9009': '地图 X 防御塔 - 大小盘 (电竞)',
    '9010': '地图 X 防御塔 - 胜负盘 (电竞)',
    '9011': '地图 X 第一防御塔 - 第Y层 (电竞)',
    '9012': '地图 X 肉山大魔王 - 让分盘 (电竞)',
    '9013': '地图 X 肉山大魔王 - 大小盘 (电竞)',
    '9014': '地图 X 肉山大魔王 - 胜负盘 (电竞)',
    '9015': '地图 X 第一肉山大魔王 (电竞)',
    '9016': '地图 X 第二肉山大魔王 (电竞)',
    '9017': '地图 X 第三肉山大魔王 (电竞)',
    '9018': '地图 X 兵营 - 让分盘 (电竞)',
    '9019': '地图 X 兵营 - 大小盘 (电竞)',
    '9020': '地图 X 兵营 - 胜负盘 (电竞)',
    '9021': '地图 X 第一组兵营 (电竞)',
    '9022': '地图 X 第二组兵营 (电竞)',
    '9023': '地图 X 第三组兵营 (电竞)',
    '9024': '地图 X 炮塔 - 让分盘 (电竞)',
    '9025': '地图 X 炮塔 - 大小盘 (电竞)',
    '9026': '地图 X 炮塔 - 胜负盘 (电竞)',
    '9027': '地图 X 第一炮塔 - 第Y层 (电竞)',
    '9028': '地图 X 巨龙 - 让分盘 (电竞) ',
    '9029': '地图 X 巨龙 - 大小盘 (电竞)',
    '9030': '地图 X 巨龙 - 胜负盘 (电竞)',
    '9031': '地图 X 第一只巨龙 (电竞)',
    '9032': '地图 X 第二只巨龙 (电竞)',
    '9033': '地图 X 第三只巨龙 (电竞)',
    '9034': '地图 X 男爵 - 让分盘 (电竞)',
    '9035': '地图 X 男爵 - 大小盘 (电竞)',
    '9036': '地图 X 男爵 - 胜负盘 (电竞)',
    '9037': '地图 X 第一个男爵 (电竞)',
    '9038': '地图 X 第二个男爵 (电竞)',
    '9039': '地图 X 第三个男爵 (电竞)',
    '9040': '地图 X 水晶 - 让分盘 (电竞)',
    '9041': '地图 X 水晶 - 大小盘 (电竞)',
    '9042': '地图 X 水晶 - 胜负盘 (电竞)',
    '9043': '地图 X 第一个水晶 (电竞)',
    '9044': '地图 X 第二个水晶 (电竞)',
    '9045': '地图 X 第三个水晶 (电竞)',
    '9046': '地图 X 暴君 - 让分盘 (电竞)',
    '9047': '地图 X 暴君 - 大小盘 (电竞)',
    '9048': '地图 X 暴君 - 胜负盘 (电竞)',
    '9049': '地图 X 第一个暴君 (电竞)',
    '9050': '地图 X 第二个暴君 (电竞)',
    '9051': '地图 X 第三个暴君 (电竞)',
    '9052': '地图 X 主宰 - 让分盘 (电竞)',
    '9053': '地图 X 主宰 - 大小盘 (电竞)',
    '9054': '地图 X 主宰 - 胜负盘 (电竞)',
    '9055': '地图 X 第一个主宰 (电竞) ',
    '9056': '地图 X 第二个主宰 (电竞)',
    '9057': '地图 X 第三个主宰 (电竞)',
    '9058': '地图 X 赛事时间(分) - 大小盘 (电竞)',
    '9059': '地图 X 回合获胜 - 让分盘 (电竞)',
    '9060': '地图 X 回合获胜 - 大小盘 (电竞)',
    '9061': '地图 X 回合获胜 - 单双盘 (电竞)',
    '9062': '地图 X 最先赢得 Y 个回合 (电竞)',
    '9063': '地图 X 上半场 (电竞)',
    '9064': '地图 X 下半场 (电竞)',
    '9065': '地图 X 最多第一击杀 (电竞)',
    '9066': '地图 X Clutches - 胜负盘 (电竞)',
    '9067': '地图 X 第 16 回合 (电竞)',
    '9068': '地图 X 回合 Y 胜负盘 (电竞)',
    '9069': '地图 X 回合 Y 击杀总数 - 胜负盘(电竞)',
    '9070': '地图 X 回合 Y 击杀总数 - 大小盘 (电竞)',
    '9071': '地图 X 回合 Y 击杀总数 - 单双盘 (电竞)',
    '9072': '地图 X 回合 Y 最先击杀 (电竞)',
    '9073': '地图 X 回合 Y 炸弹安装 (电竞)',
    '9074': '地图 X 回合 大小盘 （加时）(电竞)',
    '9075': '地图 X 最终回合炸弹安装 (电竞)',
    '9076': '地图 X Clutches - 让分盘 (电竞)',
    '9077': '地图 X 回合 Y 击杀总数 - 让分盘 (电竞)',
    '9078': '地图 X 防御塔 - 单双盘 (电竞)',
    '9079': '地图 X 肉山大魔王 - 单双盘 (电竞) ',
    '9080': '地图 X 兵营 - 单双盘 (电竞)',
    '9081': '地图 X 炮塔 - 单双盘 (电竞)',
    '9082': '地图 X 巨龙 - 单双盘 (电竞)',
    '9083': '地图 X 男爵 - 单双盘 (电竞)',
    '9084': '地图 X 水晶 - 单双盘 (电竞)',
    '9085': '地图 X 暴君 - 单双盘 (电竞)',
    '9086': '地图 X 主宰 - 单双盘 (电竞)',
    '81': '第一球大/小 (百练赛)',
    '82': '最后一球大/小 (百练赛)',
    '83': '第一球单/双 (百炼赛)',
    '84': '最后一球单/双 (百练赛)',
    '85': '大/小 (百练赛)',
    '86': '单/双 (百练赛)',
    '87': '下一个高/低 (百练赛)',
    '88': '斗士 (百练赛)',
    '89': '下一球组合 (百练赛)',
    '90': '数盘 (百练赛)',
    '8101': '大/小 (快乐5)',
    '8102': '单/双 (快乐5)',
    '8103': '四季 (快乐5)',
    '8104': '多单/多双 (快乐5)',
    '8105': '组合 (快乐5)',
    '1201': '让球 (虚拟足球)',
    '1203': '大小盘2.5 (虚拟足球)',
    '1204': '波胆 (虚拟足球)',
    '1205': '1X2 (虚拟足球) ',
    '1206': '总进球 (虚拟足球)',
    '1220': '独赢 (虚拟网球)',
    '1224': '双重机会 (虚拟足球)',
    '1231': '独赢 (虚拟赛事)',
    '1232': '位置 (虚拟赛事)',
    '1233': '独赢/位置 (虚拟赛事)',
    '1235': '波胆 (虚拟网球)',
    '1236': '总得分 (虚拟网球)',
    '1237': '连赢 (虚拟赛车)',
    '1238': '三重彩 (虚拟赛车)',
    '2701': '全场.标准盘 (虚拟足球联赛, 虚拟足球国家)',
    '2702': '上半场.标准盘(虚拟足球联赛, 虚拟足球国家)',
    '2703': '大小盘 (虚拟足球联赛, 虚拟足球国家)',
    '2704': '上半场大小盘(虚拟足球联赛, 虚拟足球国家)',
    '2705': '让球 (虚拟足球联赛, 虚拟足球国家)',
    '2706': '上半场让球 (虚拟足球联赛, 虚拟足球国家)',
    '2707': '波胆 (虚拟足球联赛, 虚拟足球国家)',
    '2799': '混合过关 (虚拟足球联赛, 虚拟足球国家)',
    '2801': '比赛获胜者 (虚拟篮球)',
    '2802': '1H赢家 (虚拟篮球)',
    '2803': '大小盘 (虚拟篮球)',
    '2804': '上半场大小盘 (虚拟篮球)',
    '2805': '让球 (虚拟篮球)',
    '2806': '上半场让球 (虚拟篮球)',
    '2807': '净胜分数6项 (虚拟篮球)',
    '2808': '上半场净胜分数7项 (虚拟篮球)',
    '2809': '全场首先获得 (虚拟篮球)',
    '2811': '主队大小盘 (虚拟篮球)',
    '2812': '客队大小盘 (虚拟篮球)',
    '911': '实时兑现',
    '1011': '捕鱼天下 (捕鱼天下)',
    '18000': 'Colossus Bets (Colossus足彩)',
    '18001': 'Pool Bet (Colossus足彩)',
    '18002': 'Cash-Out (Colossus足彩)',
    '18004': 'Consolation Prize (Colossus足彩)',
    '18005': 'Jackpot Prize (Colossus足彩)',
    '8000': 'Exchange',
    '71': '娱乐城游戏',
    '2101': '百家乐 ',
    '2102': '斗大',
    '2103': '21点',
    '2104': '扑克三重奏',
    '2105': '赌场德州扑克',
    '2106': '牌九扑克',
    '2107': '迷你轮盘',
    '2108': '超级轮盘',
    '2109': '骰宝',
    '2110': '对J高手',
    '2111': '超级轮盘(快)',
    '2112': '21点 (完美对子)',
    '2113': '免佣金百家乐',
    '2114': '鱼虾蟹',
    '2115': '龙虎',
    '2116': '三公',
    '2117': '幸运轮',
    '2118': '番摊',
    '2119': '纸牌转轮',
    '2120': '德州扑克',
    '2121': '开牌21点',
    '2122': '三张拉米 ',
    '2123': '红狗',
    '2124': '多轮轮盘',
    '2125': '灵猴高低',
    '2126': '麻将高低',
    '2127': '基诺快门 ',
    '2128': '基诺',
    '2129': '赛马王',
    '2130': '斗王',
    '2131': '玛雅之物',
    '2132': '恭喜发财',
    '2133': '爱神邱比特',
    '2134': '射龙门',
    '2135': '吃果果(刮刮乐)',
    '2136': '圣诞礼物',
    '2137': '敲敲蛋(刮刮乐)',
    '2138': '打地鼠',
    '2139': '趣味广场(刮刮乐)',
    '2140': '烈火奥运',
    '2141': '吧台寿司',
    '2142': '野球九宫格',
    '2143': '对10高手',
    '2144': '4 线对J高手',
    '2145': '25线对J高手',
    '2146': '50线对J高手',
    '2147': '泰山',
    '2148': '楚河汉界',
    '2149': '财神到',
    '2150': '钱来也',
    '2151': '五狐四海',
    '2152': '西游记',
    '2153': '冬之雪',
    '2154': '星座星空',
    '2155': '十二生肖',
    '2156': '水浒传',
    '2157': '我们是冠军',
    '2158': '疯狂医院',
    '2159': '麻将英雄传',
    '2160': '万圣派对',
    '2161': '快乐僵尸 ',
    '2162': '球迷俱乐部',
    '2163': '国色天香',
    '2164': '忍者英雄',
    '2165': '反转青楼',
    '2166': '福禄寿',
    '2167': '大魔术师',
    '2168': '财神到(双喜临门)',
    '2169': '铁拳',
    '2170': '泰山(双喜临门)',
    '2171': '谜龙谷',
    '2172': '西部牛仔',
    '2173': '恐龙世界',
    '2174': '美丽纹身',
    '2175': 'Mamak 檔',
    '2176': '海乐团',
    '2177': '套现',
    '2178': '百家乐(积宝奖金)',
    '2201': '(迷你)百家乐',
    '2202': '(迷你)赌场扑克',
    '2203': '(迷你)21点',
    '2204': '(迷你)对J高手',
    '2205': '(迷你)骰宝',
    '2206': '(迷你)Money Slot',
    '2301': '(Mobile)百家乐',
    '2302': '(Mobile)赌场德州扑克',
    '2303': '(Mobile)21点',
    '2304': '(Mobile)泰山',
    '2305': '(Mobile)财神到',
    '2306': '(Mobile)骰宝',
    '2307': '(Mobile)骰宝',
    '2308': '(Mobile)骰宝',
    '2309': '(Mobile)反转青楼',
    '2310': '(Mobile)迷你轮盘',
    '2311': 'Baccarat Super 6',
    '2312': 'Blackjack Perfect Pair',
    '2313': 'Fabulous 4 Baccarat',
    '2314': '开牌21点',
    '2315': '扑克三重奏',
    '2316': '龙虎',
    '2317': '德州扑克',
    '2318': '牌九扑克',
    '2319': '百家乐(积宝奖金)',
    '2320': 'Baccarat (Progressive JP) - Jackpot Game',
    '2321': '超级轮盘',
    '2901': '百家乐',
    '2902': '赌场德州扑克',
    '2903': '21点',
    '2904': '泰山',
    '2905': '财神到',
    '2906': '骰宝',
    '2907': '国色天香',
    '2908': '大魔术师',
    '2909': '反转青楼',
    '2910': '迷你轮盘',
    '2911': '超6百家乐',
    '2912': '21点(完美对子)',
    '2913': '开牌21点',
    '2914': '扑克三重奏',
    '2915': '龙虎',
    '2916': '德州扑克',
    '2917': '牌九扑克',
    '2919': '百家乐(积宝奖金)',
    '2920': 'Baccarat (Progressive JP) - Jackpot Game',
    '2921': '超级轮盘',
    '4002': 'BigHaul (Macau Games)',
    '4003': 'BushidoCode (Macau Games)',
    '4004': 'CasinoStudPoker (Macau Games)',
    '4005': 'CatchtheWaves (Macau Games)',
    '4008': 'DancingLions (Macau Games)',
    '4009': 'DragonKing (Macau Games)',
    '4010': 'DragonsandPearls (Macau Games)',
    '4011': 'Fish0Mania (Macau Games)',
    '4012': 'FortunePanda (Macau Games)',
    '4013': 'GodofWealth (Macau Games)',
    '4014': 'GoldofRa (Macau Games)',
    '4015': 'GuardianLion (Macau Games)',
    '4018': 'KingofTime (Macau Games)',
    '4019': 'LadyLuck (Macau Games)',
    '4020': 'MysticRiches (Macau Games)',
    '4021': 'PhoenixPrincess (Macau Games)',
    '4022': 'Roulette (Macau Games)',
    '4023': 'TempleTreasures (Macau Games)',
    '4024': 'ThaiDragon (Macau Games)',
    '4025': 'TreasureReef (Macau Games)',
    '4026': 'Venetia (Macau Games)',
    '4028': 'WildDolphin (Macau Games)',
    '4029': 'WolfQuest (Macau Games)',
    '4031': '财神的探索 (Macau Games)',
    '4032': '幸运神龙 (Macau Games)',
    '4033': '公路现金 (Macau Games)',
    '4035': '金胡子的宝藏 (Macau Games)',
    '4036': '双三重888 (Macau Games)',
    '4037': '幸运之神 (Macau Games)',
    '2401': '百家乐 (欧博)',
    '2402': 'VIP百家乐 (欧博)',
    '2403': '轮盘 (欧博)',
    '2404': '骰宝 (欧博)',
    '2405': '龙虎 (欧博)',
    '4101': '发发发龙',
    '4102': '三只老虎',
    '4103': '永恒钻石3线',
    '4104': '黄金列车',
    '4105': '富贵888',
    '4106': '7只小猪',
    '4107': '7只猴子',
    '4108': '猴子疯狂',
    '4109': '钻石罢工',
    '4110': '童话富豪',
    '4111': '龙发',
    '4112': '女神歌蒂娃',
    '4113': '西游记',
    '4114': '野狼黄金',
    '4115': '龙之国度',
    '4116': '野生动物园',
    '4117': '三国 - 赤壁之战',
    '4118': '火辣辣',
    '4119': '财神黄金',
    '4120': '精灵翅膀',
    '4121': '阿兹特克的宝石',
    '4122': '幸运新年',
    '4123': '勤劳蜜蜂',
    '4124': '水果爆破',
    '4125': '疯狂7s',
    '4126': '恶魔13',
    '4127': '幽灵财富',
    '4128': '熊猫财富',
    '4129': '财源滚滚',
    '4130': '维加斯之夜',
    '4131': '法力无边',
    '4132': '侏罗纪巨兽',
    '4133': '黑豹女王',
    '4134': '爱尔兰的魅力',
    '4135': '亚特兰提斯女王',
    '4136': '黄金女王',
    '4137': '宙斯之子海克力士',
    '4138': '3个精灵愿望',
    '4139': '猫爸第二集',
    '4140': '表沃夫',
    '4141': '幸运威龙',
    '4142': '矮人黄金',
    '4143': '罗密欧与朱丽叶',
    '4144': '狂野冰球赛',
    '4145': '月之女神',
    '4146': 'KTV',
    '4147': '极速糖果',
    '4148': '极速糖果夏日版',
    '4149': '极速糖果情人版',
    '4150': '极速糖果冬日版',
    '4151': '埃及传说',
    '4152': '曲棍球联盟 ',
    '4153': '荣耀罗马',
    '4154': '魔幻水晶',
    '4155': '矮人黄金25线',
    '4156': '大堡礁',
    '4157': '阿拉丁宝藏',
    '4158': '无敌金刚',
    '4159': '猫咪派对',
    '4160': '淘金热',
    '4161': '古代埃及',
    '4162': '圣诞老人',
    '4163': '小丑珠宝',
    '4164': '激情 88',
    '4165': '冠军杯',
    '4166': '5雄狮',
    '4167': '仙宫',
    '4168': '命运女士',
    '4169': '巨大犀牛',
    '4170': '玉蝴蝶',
    '4171': '小妖之歌',
    '4172': '达文西宝藏',
    '4173': '陈师傅的财富',
    '4174': '魔力维加斯',
    '4175': '小妖之歌圣诞版',
    '4176': '黄金野马',
    '4177': '龙龙龙',
    '4178': '狩猎之王',
    '4179': '马上有钱',
    '4180': '招财福猪',
    '4181': '狂野角斗士',
    '4182': '额外多汁',
    '4183': '埃及宿命',
    '4184': '汪汪之家',
    '4185': '财神运财',
    '4186': '甜入心扉',
    '4187': '7只猴子积宝',
    '4188': '野生动物园积宝',
    '4189': '猫爸第二集积宝',
    '4300': '活动奖金',
    '1515': 'Max Keno',
    '1516': 'Max Keno2',
    '1517': 'Mini Keno',
    '1519': '春',
    '1520': '夏',
    '1521': '秋',
    '1522': '冬',
    '1524': 'Mini Keno 2',
    '1031': 'Max5D 60 (彩票)',
    '1032': 'Max5D 90 (彩票)',
    '1033': 'Max3D 60 (彩票)',
    '1034': 'Max3D 90 (彩票)',
    '1035': 'Max11x5 60 (彩票)',
    '1036': 'Max11x5 90 (彩票)',
    '1037': 'MaxDice 60 (彩票)',
    '1038': 'MaxDice 90 (彩票)',
    '1039': 'Max 赛车',
    '1040': 'Max 赛车 2',
    '1041': '射龙门',
    '1042': '射龙门 2',
    '1043': '色碟',
    '1044': '色碟 2',
    '1045': '数字牛牛',
    '1046': '数字牛牛 2',
    '1047': 'Max 色碟 2',
    '1048': 'Max 色碟 2'
  };
  static gameTypes = {
    '1': {
      h: 'home_name',
      a: 'away_name'
    },
    '2': {
      h: '单',
      a: '双'
    },
    '3': {
      h: '大',
      a: '小'
    },
    '4': {
      '0:0': '0-0',
      '0:1': '0-1',
      '0:2': '0-2',
      '0:3': '0-3',
      '0:4': '0-4',
      '1:0': '1-0',
      '1:1': '1-1',
      '1:2': '1-2',
      '1:3': '1-3',
      '1:4': '1-4',
      '2:0': '2-1',
      '2:1': '2-1',
      '2:2': '2-2',
      '2:3': '2-3',
      '2:4': '2-4',
      '3:0': '3-0',
      '3:1': '3-1',
      '3:2': '3-2',
      '3:3': '3-3',
      '3:4': '3-4',
      '4:0': '4-0',
      '4:1': '4-1',
      '4:2': '4-2',
      '4:3': '4-3',
      '4:4': '4-4',
      '5:0': '5-0 以上',
      '0:5': '0-5 以上'
    },
    '5': {
      '1': '主队',
      x: '平手',
      '2': '客队'
    },
    '6': {
      '0-1': '0-1球',
      '2-3': '2-3球',
      '4-6': '4-6球',
      '7-over': '7球以上'
    },
    '7': {
      h: '主队',
      a: '客队'
    },
    '8': {
      h: '大',
      a: '小'
    },
    '12': {
      h: '单',
      a: '双'
    },
    '13': {
      HY: '主 是 (主队没失球)',
      HN: '主 否 (主队有失球)',
      AY: '客 是 (客队没失球)',
      AN: '客 否 (客队有失球)'
    },
    '14': {
      '1:1': '主队最先进球',
      '1:2': '主队最后进球',
      '2:1': '客队最先进球',
      '2:2': '客队最后进球',
      '0:0': '没有任何进球'
    },
    '15': {
      '1': '主队',
      x: '平手',
      '2': '客队'
    },
    '16': {
      '0:0': '半场平手/全场平手',
      '0:1': '半场平手/全场主队赢',
      '0:2': '半场平手/全场客队赢',
      '1:0': '半场主队赢/全场平手',
      '1:1': '半场主队赢/全场主队赢',
      '1:2': '半场主队赢/全场客队赢',
      '2:0': '半场客队赢/全场平手',
      '2:1': '半场客队赢/全场主队赢',
      '2:2': '半场客队赢/全场客队赢'
    },
    '17': {
      h: '主队',
      a: '客队'
    },
    '18': {
      o: '大',
      u: '小'
    },
    '20': {
      h: '主队',
      a: '客队'
    },
    '21': {
      h: '主队',
      a: '客队'
    },
    '22': {
      '1': '主队',
      x: '没有任何进球',
      '2': '客队'
    },
    '24': {
      '1x': '主队赢/平手',
      '12': '主队赢/客队赢',
      '2x': '客队赢/平手'
    },
    '25': {
      h: '主队',
      a: '客队'
    },
    '26': {
      o: '一方得分',
      n: '都没得分',
      b: '双方都得分'
    },
    '27': {
      h: '主队',
      a: '客队'
    },
    '28': {
      '1': '主队',
      x: '平手',
      '2': '客队'
    },
    '30': {
      '0:0': '0-0',
      '0:1': '0-1',
      '0:2': '0-2',
      '0:3': '0-3',
      '1:0': '1-0',
      '1:1': '1-1',
      '1:2': '1-2',
      '1:3': '1-3',
      '2:0': '2-1',
      '2:1': '2-1',
      '2:2': '2-2',
      '2:3': '2-3',
      '3:0': '3-0',
      '3:1': '3-1',
      '3:2': '3-2',
      '3:3': '3-3',
      '4:0': '4-0 以上',
      '0:4': '0-4 以上'
    },
    '121': {
      x: '平手',
      a: '客队'
    },
    '122': {
      h: '主队',
      x: '平手'
    },
    '123': {
      h: '平手',
      a: '不是平手'
    },
    '124': {
      '1': '主队',
      x: '平手',
      '2': '客队'
    },
    '125': {
      '1': '主队',
      x: '平手',
      '2': '客队'
    },
    '126': {
      '0-1': '0-1 球',
      '2-3': '2-3 球',
      '4-over': '4球以上'
    },
    '127': {
      '1:1': '主队最先进球',
      '1:2': '主队最后进球',
      '2:1': '客队最先进球',
      '2:2': '客队最后进球',
      '0:0': '没有任何进球'
    },
    '128': {
      oo: '半场单, 全场单',
      oe: '半场单, 全场双',
      eo: '半场双, 全场单',
      ee: '半场双, 全场双'
    },
    '133': {
      y: '是',
      n: '否'
    },
    '438': {
      y: '是',
      n: '否'
    },
    '134': {
      y: '是',
      n: '否'
    },
    '439': {
      y: '是',
      n: '否'
    },
    '135': {
      y: '是',
      n: '否'
    },
    '140': {
      '1h': '上半场',
      '2h': '下半场',
      tie: '一样多'
    },
    '442': {
      '1h': '上半场',
      '2h': '下半场',
      tie: '一样多'
    },
    '141': {
      '1h': '上半场',
      '2h': '下半场',
      tie: '一样多'
    },
    '443': {
      '1h': '上半场',
      '2h': '下半场',
      tie: '一样多'
    },
    '142': {
      '1h': '上半场',
      '2h': '下半场',
      tie: '一样多'
    },
    '444': {
      '1h': '上半场',
      '2h': '下半场',
      tie: '一样多'
    },
    '143': {
      ho: '主场 / 大',
      hu: '主场 / 小',
      do: '平手 / 大',
      du: '平手 / 小',
      ao: '客队 / 大',
      au: '客队 / 小'
    },
    '145': {
      y: '是',
      n: '否'
    },
    '146': {
      y: '是',
      n: '否'
    },
    '433': {
      y: '是',
      n: '否'
    },
    '147': {
      y: '是',
      n: '否'
    },
    '436': {
      y: '是',
      n: '否'
    },
    '150': {
      y: '是',
      n: '否'
    },
    '441': {
      y: '是',
      n: '否'
    },
    '149': {
      y: '是',
      n: '否'
    },
    '440': {
      y: '是',
      n: '否'
    },
    '148': {
      y: '是',
      n: '否'
    },
    '437': {
      y: '是',
      n: '否'
    },
    '151': {
      '1x': '主队或平手',
      '2x': '客队或平手',
      '12': '主队或客队'
    },
    '410': {
      '1x': '主队或平手',
      '2x': '客队或平手',
      '12': '主队或客队'
    },
    '1224': {
      '1x': '主队或平手',
      '2x': '客队或平手',
      '12': '主队或客队'
    },
    '153': {
      h: '主队',
      a: '客队',
      '': ''
    },
    '154': {
      h: '主队',
      a: '客队'
    },
    '155': {
      h: '主队',
      a: '客队'
    },
    '168': {
      h: '主队',
      a: '客队'
    },
    '185': {
      h: '主队',
      a: '客队'
    },
    '432': {
      h: '主队',
      a: '客队'
    },
    '191': {
      h: '主队',
      a: '客队'
    },
    '411': {
      h: '主队',
      a: '客队'
    },
    '425': {
      h: '主队',
      a: '客队'
    },
    '501': {
      h: '主队',
      a: '客队'
    },
    '1301': {
      h: '主队',
      a: '客队'
    },
    '1303': {
      h: '主队',
      a: '客队'
    },
    '1308': {
      h: '主队',
      a: '客队'
    },
    '1311': {
      h: '主队',
      a: '客队'
    },
    '1316': {
      h: '主队',
      a: '客队'
    },
    '1201': {
      h: '主队',
      a: '客队'
    },
    '1220': {
      h: '主队',
      a: '客队'
    },
    '2705': {
      h: '主队',
      a: '客队'
    },
    '2706': {
      h: '主队',
      a: '客队'
    },
    '2801': {
      h: '主队',
      a: '客队'
    },
    '2805': {
      h: '主队',
      a: '客队'
    },
    '2806': {
      h: '主队',
      a: '客队'
    },
    '2809': {
      h: '主队',
      a: '客队'
    },
    '156': {
      o: '大',
      u: '小'
    },
    '1306': {
      o: '大',
      u: '小'
    },
    '1312': {
      o: '大',
      u: '小'
    },
    '2703': {
      o: '大',
      u: '小'
    },
    '2704': {
      o: '大',
      u: '小'
    },
    '157': {
      h: '单',
      a: '双'
    },
    '1305': {
      h: '单',
      a: '双'
    },
    '1318': {
      h: '单',
      a: '双'
    },
    '83': {
      h: '单',
      a: '双'
    },
    '84': {
      h: '单',
      a: '双'
    },
    '86': {
      h: '单',
      a: '双'
    },
    '159': {
      G0: '0 球',
      G1: '1 球',
      G2: '2 球',
      G3: '3 球',
      G4: '4 球',
      G5: '5 球',
      G6: '6 球和以上'
    },
    '406': {
      G0: '0 球',
      G1: '1 球',
      G2: '2 球',
      G3: '3 球',
      G4: '4 球',
      G5: '5 球',
      G6: '6 球和以上'
    },
    '161': {
      G0: '0 球',
      G1: '1 球',
      G2: '2 球',
      G3: '3 球和以上'
    },
    '407': {
      G0: '0 球',
      G1: '1 球',
      G2: '2 球',
      G3: '3 球和以上'
    },
    '162': {
      G0: '0 球',
      G1: '1 球',
      G2: '2 球',
      G3: '3 球和以上'
    },
    '409': {
      G0: '0 球',
      G1: '1 球',
      G2: '2 球',
      G3: '3 球和以上'
    },
    '179': {
      G0: '0 球',
      G1: '1 球',
      G2: '2 球',
      G3: '3 球',
      G4: '4 球和以上'
    },
    '181': {
      G0: '0 球',
      G1: '1 球',
      G2: '2 球',
      G3: '3 球和以上'
    },
    '182': {
      G0: '0 球',
      G1: '1 球',
      G2: '2 球',
      G3: '3 球和以上'
    },
    '187': {
      G0: '0 球',
      G1: '1 球',
      G2: '2 球和以上'
    },
    '467': {
      'G0/G0': '上半場 0 / 全場 0',
      'G0/G1': '上半場 0 / 全場 1',
      'G0/G2': '上半場 0 / 全場 2',
      'G0/G3': '上半場 0 / 全場 3',
      'G0/G4': '上半場 0 / 全場 4',
      'G0/G5': '上半場 0 / 全場 5',
      'G0/G6': '上半場 0 / 全場 6',
      'G0/G7+': '上半場 0 / 全場 7+',
      'G1/G1': '上半場 1 / 全場 1',
      'G1/G2': '上半場 1 / 全場 2',
      'G1/G3': '上半場 1 / 全場 3',
      'G1/G4': '上半場 1 / 全場 4',
      'G1/G5': '上半場 1 / 全場 5',
      'G1/G6': '上半場 1 / 全場 6',
      'G1/G7+': '上半場 1 / 全場 7+',
      'G2/G2': '上半場 2 / 全場 2',
      'G2/G3': '上半場 2 / 全場 3',
      'G2/G4': '上半場 2 / 全場 4',
      'G2/G5': '上半場 2 / 全場 5',
      'G2/G6': '上半場 2 / 全場 6',
      'G2/G7+': '上半場 2 / 全場 7+',
      'G3/G3': '上半場 3 / 全場 3',
      'G3/G4': '上半場 3 / 全場 4',
      'G3/G5': '上半場 3 / 全場 5',
      'G3/G6': '上半場 3 / 全場 6',
      'G3/G7+': '上半場 3 / 全場 7+',
      'G4/G4': '上半場 4 / 全場 4',
      'G4/G5': '上半場 4 / 全場 5',
      'G4/G6': '上半場 4 / 全場 6',
      'G4/G7': '上半場 4 / 全場 7',
      'G4/G8+': '上半場 4 / 全場 8+',
      'G5/G5': '上半場 5 / 全場 5',
      'G5/G6': '上半場 5 / 全場 6',
      'G5/G7': '上半場 5 / 全場 7',
      ' G5/G8': '上半場 5 / 全場 8 ',
      'G5/G9+': '上半場 5 / 全場 9+',
      'G6/G6': '上半場 6 / 全場 6',
      'G6/G7': '上半場 6 / 全場 7',
      'G6/G8': '上半場 6 / 全場 8',
      'G6/G9': '上半場 6 / 全場 9',
      'G6/G10+': '上半場 6 / 全場 10+',
      'G7+/G7+': '上半場 7+ / 全場 7+'
    },
    '152': {
      '0:0/0:0': '0-0/0-0',
      '0:0/0:1': '0-0/0-1',
      '0:0/0:2': '0-0/0-2',
      '0:0/0:3': '0-0/0-3',
      '0:0/0:4': '0-0/0-4',
      '0:0/1:0': '0-0/1-0',
      '0:0/1:1': '0-0/0-1',
      '0:0/1:2': '0-0/0-2',
      '0:0/1:3': '0-0/0-3',
      '0:0/1:4': '0-0/0-4',
      '0:0/2:0': '0-0/2-0',
      '0:0/2:1': '0-0/2-1',
      '0:0/2:2': '0-0/2-2',
      '0:0/2:3': '0-0/2-3',
      '0:0/2:4': '0-0/2-4',
      '0:0/3:0': '0-0/3-0',
      '0:0/3:1': '0-0/3-1',
      '0:0/3:2': '0-0/3-2',
      '0:0/3:3': '0-0/3-3',
      '0:0/3:4': '0-0/3-4',
      '0:0/4:0': '0-0/4-0',
      '0:0/4:1': '0-0/4-1',
      '0:0/4:2': '0-0/4-2',
      '0:0/4:3': '0-0/4-3',
      '0:0/4:4': '0-0/4-4',
      '0:0/AOS': '0-0/其他',
      '0:1/0:1': '0-1/0-1',
      '0:1/0:2': '0-1/0-2',
      '0:1/0:3': '0-1/0-3',
      '0:1/0:4': '0-1/0-4',
      '0:1/1:1': '0-1/1-1',
      '0:1/1:2': '0-1/1-2',
      '0:1/1:3': '0-1/1-3',
      '0:1/1:4': '0-1/1-4',
      '0:1/2:1': '0-1/2-1',
      '0:1/2:2': '0-1/2-2',
      '0:1/2:3': '0-1/2-3',
      '0:1/2:4': '0-1/2-4',
      '0:1/3:1': '0-1/3-1',
      '0:1/3:2': '0-1/3-2',
      '0:1/3:3': '0-1/3-3',
      '0:1/3:4': '0-1/3-4',
      '0:1/4:1': '0-1/4-1',
      '0:1/4:2': '0-1/4-2',
      '0:1/4:3': '0-1/4-3',
      '0:1/4:4': '0-1/4-4',
      '0:1/AOS': '0-1/其他',
      '0:2/0:2': '0-2/0-2',
      '0:2/0:3': '0-2/0-3',
      '0:2/0:4': '0-2/0-4',
      '0:2/1:2': '0-2/1-2',
      '0:2/1:3': '0-2/1-3',
      '0:2/1:4': '0-2/1-4',
      '0:2/2:2': '0-2/2-2',
      '0:2/2:3': '0-2/2-3',
      '0:2/2:4': '0-2/2-4',
      '0:2/3:2': '0-2/3-2',
      '0:2/3:3': '0-2/3-3',
      '0:2/3:4': '0-2/3-4',
      '0:2/4:2': '0-2/4-2',
      '0:2/4:3': '0-2/4-3',
      '0:2/4:4': '0-2/4-4',
      '0:2/AOS': '0-2/其他',
      '0:3/0:3': '0-3/0-3',
      '0:3/0:4': '0-3/0-4',
      '0:3/1:3': '0-3/1-3',
      '0:3/1:4': '0-3/1-4',
      '0:3/2:3': '0-3/2-3',
      '0:3/2:4': '0-3/2-4',
      '0:3/3:3': '0-3/3-3',
      '0:3/3:4': '0-3/3-4',
      '0:3/4:3': '0-3/4-3',
      '0:3/4:4': '0-3/4-4',
      '0:3/AOS': '0-3/其他',
      '1:0/1:0': '1-0/1-0',
      '1:0/1:1': '1-0/1-1',
      '1:0/1:2': '1-0/1-2',
      '1:0/1:3': '1-0/1-3',
      '1:0/1:4': '1-0/1-4',
      '1:0/2:0': '1-0/2-0',
      '1:0/2:1': '1-0/2-1',
      '1:0/2:2': '1-0/2-2',
      '1:0/2:3': '1-0/2-3',
      '1:0/2:4': '1-0/2-4',
      '1:0/3:0': '1-0/3-0',
      '1:0/3:1': '1-0/3-1',
      '1:0/3:2': '1-0/3-2',
      '1:0/3:3': '1-0/3-3',
      '1:0/3:4': '1-0/3-4',
      '1:0/4:0': '1-0/4-0',
      '1:0/4:1': '1-0/4-1',
      '1:0/4:2': '1-0/4-2',
      '1:0/4:3': '1-0/4-3',
      '1:0/4:4': '1-0/4-4',
      '1:0/AOS': '1-0/其他',
      '1:1/1:1': '1-1/1-1',
      '1:1/1:2': '1-1/1-2',
      '1:1/1:3': '1-1/1-3',
      '1:1/1:4': '1-1/1-4',
      '1:1/2:1': '1-1/2-1',
      '1:1/2:2': '1-1/2-2',
      '1:1/2:3': '1-1/2-3',
      '1:1/2:4': '1-1/2-4',
      '1:1/3:1': '1-1/3-1',
      '1:1/3:2': '1-1/3-2',
      '1:1/3:3': '1-1/3-3',
      '1:1/3:4': '1-1/3-4',
      '1:1/4:1': '1-1/4-1',
      '1:1/4:2': '1-1/4-2',
      '1:1/4:3': '1-1/4-3',
      '1:1/4:4': '1-1/4-4',
      '1:1/AOS': '1-1/其他',
      '1:2/1:2': '1-2/1-2',
      '1:2/1:3': '1-2/1-3',
      '1:2/1:4': '1-2/1-4',
      '1:2/2:2': '1-2/2-2',
      '1:2/2:3': '1-2/2-3',
      '1:2/2:4': '1-2/2-4',
      '1:2/3:2': '1-2/3-2',
      '1:2/3:3': '1-2/3-3',
      '1:2/3:4': '1-2/3-4',
      '1:2/4:2': '1-2/4-2',
      '1:2/4:3': '1-2/4-3',
      '1:2/4:4': '1-2/4-4',
      '1:2/AOS': '1-2/其他',
      '1:3/1:3': '1-3/1-3',
      '1:3/1:4': '1-3/1-4',
      '1:3/2:3': '1-3/2-3',
      '1:3/2:4': '1-3/2-4',
      '1:3/3:3': '1-3/3-3',
      '1:3/3:4': '1-3/3-4',
      '1:3/4:3': '1-3/4-3',
      '1:3/4:4': '1-3/4-4',
      '1:3/AOS': '1-3/其他',
      '2:0/2:0': '2-0/2-0',
      '2:0/2:1': '2-0/2-1',
      '2:0/2:2': '2-0/2-2',
      '2:0/2:3': '2-0/2-3',
      '2:0/2:4': '2-0/2-4',
      '2:0/3:0': '2-0/3-0',
      '2:0/3:1': '2-0/3-1',
      '2:0/3:2': '2-0/3-2',
      '2:0/3:3': '2-0/3-3',
      '2:0/3:4': '2-0/3-4',
      '2:0/4:0': '2-0/4-0',
      '2:0/4:1': '2-0/4-1',
      '2:0/4:2': '2-0/4-2',
      '2:0/4:3': '2-0/4-3',
      '2:0/4:4': '2-0/4-4',
      '2:0/AOS': '2-0/其他',
      '2:1/2:1': '2-1/2-1',
      '2:1/2:2': '2-1/2-2',
      '2:1/2:3': '2-1/2-3',
      '2:1/2:4': '2-1/2-4',
      '2:1/3:1': '2-1/3-1',
      '2:1/3:2': '2-1/3-2',
      '2:1/3:3': '2-1/3-3',
      '2:1/3:4': '2-1/3-4',
      '2:1/4:1': '2-1/4-1',
      '2:1/4:2': '2-1/4-2',
      '2:1/4:3': '2-1/4-3',
      '2:1/4:4': '2-1/4-4',
      '2:1/AOS': '2-1/其他',
      '2:2/2:2': '2-2/2-2',
      '2:2/2:3': '2-2/2-3',
      '2:2/2:4': '2-2/2-4',
      '2:2/3:2': '2-2/3-2',
      '2:2/3:3': '2-2/3-3',
      '2:2/3:4': '2-2/3-4',
      '2:2/4:2': '2-2/4-2',
      '2:2/4:3': '2-2/4-3',
      '2:2/4:4': '2-2/4-4',
      '2:2/AOS': '2-2/其他',
      '2:3/2:3': '2-3/2-3',
      '2:3/2:4': '2-3/2-4',
      '2:3/3:3': '2-3/3-3',
      '2:3/3:4': '2-3/3-4',
      '2:3/4:3': '2-3/4-3',
      '2:3/4:4': '2-3/4-4',
      '2:3/AOS': '2-3/其他',
      '3:0/3:0': '3-0/3-0',
      '3:0/3:1': '3-0/3-1',
      '3:0/3:2': '3-0/3-2',
      '3:0/3:3': '3-0/3-3',
      '3:0/3:4': '3-0/3-4',
      '3:0/4:0': '3-0/4-0',
      '3:0/4:1': '3-0/4-1',
      '3:0/4:2': '3-0/4-2',
      '3:0/4:3': '3-0/4-3',
      '3:0/4:4': '3-0/4-4',
      '3:0/AOS': '3-0/其他',
      '3:1/3:1': '3-1/3-1',
      '3:1/3:2': '3-1/3-2',
      '3:1/3:3': '3-1/3-3',
      '3:1/3:4': '3-1/3-4',
      '3:1/4:1': '3-1/4-1',
      '3:1/4:2': '3-1/4-2',
      '3:1/4:3': '3-1/4-3',
      '3:1/4:4': '3-1/4-4',
      '3:1/AOS': '3-1/其他',
      '3:2/3:2': '3-2/3-2',
      '3:2/3:3': '3-2/3-3',
      '3:2/3:4': '3-2/3-4',
      '3:2/4:2': '3-2/4-2',
      '3:2/4:3': '3-2/4-3',
      '3:2/4:4': '3-2/4-4',
      '3:2/AOS': '3-2/其他',
      '3:3/3:3': '3-3/3-3',
      '3:3/3:4': '3-3/3-4',
      '3:3/4:3': '3-3/4-3',
      '3:3/4:4': '3-3/4-4',
      '3:3/AOS': '3-3/其他'
    },
    '416': {
      '0:0/0:0': '0-0/0-0',
      '0:0/0:1': '0-0/0-1',
      '0:0/0:2': '0-0/0-2',
      '0:0/0:3': '0-0/0-3',
      '0:0/0:4': '0-0/0-4',
      '0:0/1:0': '0-0/1-0',
      '0:0/1:1': '0-0/0-1',
      '0:0/1:2': '0-0/0-2',
      '0:0/1:3': '0-0/0-3',
      '0:0/1:4': '0-0/0-4',
      '0:0/2:0': '0-0/2-0',
      '0:0/2:1': '0-0/2-1',
      '0:0/2:2': '0-0/2-2',
      '0:0/2:3': '0-0/2-3',
      '0:0/2:4': '0-0/2-4',
      '0:0/3:0': '0-0/3-0',
      '0:0/3:1': '0-0/3-1',
      '0:0/3:2': '0-0/3-2',
      '0:0/3:3': '0-0/3-3',
      '0:0/3:4': '0-0/3-4',
      '0:0/4:0': '0-0/4-0',
      '0:0/4:1': '0-0/4-1',
      '0:0/4:2': '0-0/4-2',
      '0:0/4:3': '0-0/4-3',
      '0:0/4:4': '0-0/4-4',
      '0:0/AOS': '0-0/其他',
      '0:1/0:1': '0-1/0-1',
      '0:1/0:2': '0-1/0-2',
      '0:1/0:3': '0-1/0-3',
      '0:1/0:4': '0-1/0-4',
      '0:1/1:1': '0-1/1-1',
      '0:1/1:2': '0-1/1-2',
      '0:1/1:3': '0-1/1-3',
      '0:1/1:4': '0-1/1-4',
      '0:1/2:1': '0-1/2-1',
      '0:1/2:2': '0-1/2-2',
      '0:1/2:3': '0-1/2-3',
      '0:1/2:4': '0-1/2-4',
      '0:1/3:1': '0-1/3-1',
      '0:1/3:2': '0-1/3-2',
      '0:1/3:3': '0-1/3-3',
      '0:1/3:4': '0-1/3-4',
      '0:1/4:1': '0-1/4-1',
      '0:1/4:2': '0-1/4-2',
      '0:1/4:3': '0-1/4-3',
      '0:1/4:4': '0-1/4-4',
      '0:1/AOS': '0-1/其他',
      '0:2/0:2': '0-2/0-2',
      '0:2/0:3': '0-2/0-3',
      '0:2/0:4': '0-2/0-4',
      '0:2/1:2': '0-2/1-2',
      '0:2/1:3': '0-2/1-3',
      '0:2/1:4': '0-2/1-4',
      '0:2/2:2': '0-2/2-2',
      '0:2/2:3': '0-2/2-3',
      '0:2/2:4': '0-2/2-4',
      '0:2/3:2': '0-2/3-2',
      '0:2/3:3': '0-2/3-3',
      '0:2/3:4': '0-2/3-4',
      '0:2/4:2': '0-2/4-2',
      '0:2/4:3': '0-2/4-3',
      '0:2/4:4': '0-2/4-4',
      '0:2/AOS': '0-2/其他',
      '0:3/0:3': '0-3/0-3',
      '0:3/0:4': '0-3/0-4',
      '0:3/1:3': '0-3/1-3',
      '0:3/1:4': '0-3/1-4',
      '0:3/2:3': '0-3/2-3',
      '0:3/2:4': '0-3/2-4',
      '0:3/3:3': '0-3/3-3',
      '0:3/3:4': '0-3/3-4',
      '0:3/4:3': '0-3/4-3',
      '0:3/4:4': '0-3/4-4',
      '0:3/AOS': '0-3/其他',
      '1:0/1:0': '1-0/1-0',
      '1:0/1:1': '1-0/1-1',
      '1:0/1:2': '1-0/1-2',
      '1:0/1:3': '1-0/1-3',
      '1:0/1:4': '1-0/1-4',
      '1:0/2:0': '1-0/2-0',
      '1:0/2:1': '1-0/2-1',
      '1:0/2:2': '1-0/2-2',
      '1:0/2:3': '1-0/2-3',
      '1:0/2:4': '1-0/2-4',
      '1:0/3:0': '1-0/3-0',
      '1:0/3:1': '1-0/3-1',
      '1:0/3:2': '1-0/3-2',
      '1:0/3:3': '1-0/3-3',
      '1:0/3:4': '1-0/3-4',
      '1:0/4:0': '1-0/4-0',
      '1:0/4:1': '1-0/4-1',
      '1:0/4:2': '1-0/4-2',
      '1:0/4:3': '1-0/4-3',
      '1:0/4:4': '1-0/4-4',
      '1:0/AOS': '1-0/其他',
      '1:1/1:1': '1-1/1-1',
      '1:1/1:2': '1-1/1-2',
      '1:1/1:3': '1-1/1-3',
      '1:1/1:4': '1-1/1-4',
      '1:1/2:1': '1-1/2-1',
      '1:1/2:2': '1-1/2-2',
      '1:1/2:3': '1-1/2-3',
      '1:1/2:4': '1-1/2-4',
      '1:1/3:1': '1-1/3-1',
      '1:1/3:2': '1-1/3-2',
      '1:1/3:3': '1-1/3-3',
      '1:1/3:4': '1-1/3-4',
      '1:1/4:1': '1-1/4-1',
      '1:1/4:2': '1-1/4-2',
      '1:1/4:3': '1-1/4-3',
      '1:1/4:4': '1-1/4-4',
      '1:1/AOS': '1-1/其他',
      '1:2/1:2': '1-2/1-2',
      '1:2/1:3': '1-2/1-3',
      '1:2/1:4': '1-2/1-4',
      '1:2/2:2': '1-2/2-2',
      '1:2/2:3': '1-2/2-3',
      '1:2/2:4': '1-2/2-4',
      '1:2/3:2': '1-2/3-2',
      '1:2/3:3': '1-2/3-3',
      '1:2/3:4': '1-2/3-4',
      '1:2/4:2': '1-2/4-2',
      '1:2/4:3': '1-2/4-3',
      '1:2/4:4': '1-2/4-4',
      '1:2/AOS': '1-2/其他',
      '1:3/1:3': '1-3/1-3',
      '1:3/1:4': '1-3/1-4',
      '1:3/2:3': '1-3/2-3',
      '1:3/2:4': '1-3/2-4',
      '1:3/3:3': '1-3/3-3',
      '1:3/3:4': '1-3/3-4',
      '1:3/4:3': '1-3/4-3',
      '1:3/4:4': '1-3/4-4',
      '1:3/AOS': '1-3/其他',
      '2:0/2:0': '2-0/2-0',
      '2:0/2:1': '2-0/2-1',
      '2:0/2:2': '2-0/2-2',
      '2:0/2:3': '2-0/2-3',
      '2:0/2:4': '2-0/2-4',
      '2:0/3:0': '2-0/3-0',
      '2:0/3:1': '2-0/3-1',
      '2:0/3:2': '2-0/3-2',
      '2:0/3:3': '2-0/3-3',
      '2:0/3:4': '2-0/3-4',
      '2:0/4:0': '2-0/4-0',
      '2:0/4:1': '2-0/4-1',
      '2:0/4:2': '2-0/4-2',
      '2:0/4:3': '2-0/4-3',
      '2:0/4:4': '2-0/4-4',
      '2:0/AOS': '2-0/其他',
      '2:1/2:1': '2-1/2-1',
      '2:1/2:2': '2-1/2-2',
      '2:1/2:3': '2-1/2-3',
      '2:1/2:4': '2-1/2-4',
      '2:1/3:1': '2-1/3-1',
      '2:1/3:2': '2-1/3-2',
      '2:1/3:3': '2-1/3-3',
      '2:1/3:4': '2-1/3-4',
      '2:1/4:1': '2-1/4-1',
      '2:1/4:2': '2-1/4-2',
      '2:1/4:3': '2-1/4-3',
      '2:1/4:4': '2-1/4-4',
      '2:1/AOS': '2-1/其他',
      '2:2/2:2': '2-2/2-2',
      '2:2/2:3': '2-2/2-3',
      '2:2/2:4': '2-2/2-4',
      '2:2/3:2': '2-2/3-2',
      '2:2/3:3': '2-2/3-3',
      '2:2/3:4': '2-2/3-4',
      '2:2/4:2': '2-2/4-2',
      '2:2/4:3': '2-2/4-3',
      '2:2/4:4': '2-2/4-4',
      '2:2/AOS': '2-2/其他',
      '2:3/2:3': '2-3/2-3',
      '2:3/2:4': '2-3/2-4',
      '2:3/3:3': '2-3/3-3',
      '2:3/3:4': '2-3/3-4',
      '2:3/4:3': '2-3/4-3',
      '2:3/4:4': '2-3/4-4',
      '2:3/AOS': '2-3/其他',
      '3:0/3:0': '3-0/3-0',
      '3:0/3:1': '3-0/3-1',
      '3:0/3:2': '3-0/3-2',
      '3:0/3:3': '3-0/3-3',
      '3:0/3:4': '3-0/3-4',
      '3:0/4:0': '3-0/4-0',
      '3:0/4:1': '3-0/4-1',
      '3:0/4:2': '3-0/4-2',
      '3:0/4:3': '3-0/4-3',
      '3:0/4:4': '3-0/4-4',
      '3:0/AOS': '3-0/其他',
      '3:1/3:1': '3-1/3-1',
      '3:1/3:2': '3-1/3-2',
      '3:1/3:3': '3-1/3-3',
      '3:1/3:4': '3-1/3-4',
      '3:1/4:1': '3-1/4-1',
      '3:1/4:2': '3-1/4-2',
      '3:1/4:3': '3-1/4-3',
      '3:1/4:4': '3-1/4-4',
      '3:1/AOS': '3-1/其他',
      '3:2/3:2': '3-2/3-2',
      '3:2/3:3': '3-2/3-3',
      '3:2/3:4': '3-2/3-4',
      '3:2/4:2': '3-2/4-2',
      '3:2/4:3': '3-2/4-3',
      '3:2/4:4': '3-2/4-4',
      '3:2/AOS': '3-2/其他',
      '3:3/3:3': '3-3/3-3',
      '3:3/3:4': '3-3/3-4',
      '3:3/4:3': '3-3/4-3',
      '3:3/4:4': '3-3/4-4',
      '3:3/AOS': '3-3/其他'
    },
    '160': {
      '1': '主队',
      x: '无',
      '2': '客队'
    },
    '164': {
      '1': '主队',
      x: '无',
      '2': '客队'
    },
    '176': {
      '1': '主队',
      x: '无',
      '2': '客队'
    },
    '180': {
      '1': '主队',
      x: '无',
      '2': '客队'
    },
    '2701': {
      '1': '主队',
      x: '无',
      '2': '客队'
    },
    '2702': {
      '1': '主队',
      x: '无',
      '2': '客队'
    },
    '2802': {
      '1': '主队',
      x: '无',
      '2': '客队'
    },
    '163': {
      HU: '主场 / 小',
      HO: '主场 / 大',
      DU: '平手 / 小',
      DO: '平手 / 大',
      AU: '客队 / 小',
      AO: '客队 / 大'
    },
    '144': {
      HU: '主场 / 小',
      HO: '主场 / 大',
      DU: '平手 / 小',
      DO: '平手 / 大',
      AU: '客队 / 小',
      AO: '客队 / 大'
    },
    '165': {
      '0:0': '0-0',
      '1:0': '1-0',
      '2:0': '2-0',
      '0:1': '0-1',
      '1:1': '1-1',
      '0:2': '0-2'
    },
    '166': {
      '0:0': '0-0',
      '1:0': '1-0',
      '2:0': '2-0',
      '3:0': '3-0',
      '0:1': '0-1',
      '1:1': '1-1',
      '2:1': '2-1',
      '0:2': '0-2',
      '1:2': '1-2',
      '0:3': '0-3'
    },
    '167': {
      '1': '加时赛上半场主队',
      x: '加时赛上半场平手',
      '2': '加时赛上半场客队'
    },
    '169': {
      '1-15': '0 - 15分钟',
      '16-30': '16 - 30分钟',
      '31-45': '31 - 45分钟',
      '46-60': '46 - 60分钟',
      '61-75': '61 - 75分钟',
      '76-90': '76 – 90分钟',
      None: '无进球'
    },
    '170': {
      H: '主队',
      A: '客队',
      B: '都有',
      N: '都无'
    },
    '171': {
      H1: '主队净赢1球',
      H2: '主队净赢2球',
      H3: '主队净赢3球和以上',
      D: '平手',
      A1: '客队净赢1球',
      A2: '客队净赢2球',
      A3: '客队净赢3球和以上',
      NG: '无进球'
    },
    '408': {
      H1: '主队净赢1球',
      H2: '主队净赢2球',
      H3: '主队净赢3球和以上',
      D: '平手',
      A1: '客队净赢1球',
      A2: '客队净赢2球',
      A3: '客队净赢3球和以上',
      NG: '无进球'
    },
    '172': {
      HH: '主队 / 主队',
      HD: '主队 / 平手',
      HA: '主队 / 客队',
      AH: '客队 / 主队',
      AD: '客队 / 平手',
      AA: '客队 / 客队',
      NO: '无进球'
    },
    '415': {
      HH: '主队 / 主队',
      HD: '主队 / 平手',
      HA: '主队 / 客队',
      AH: '客队 / 主队',
      AD: '客队 / 平手',
      AA: '客队 / 客队',
      NO: '无进球'
    },
    '173': {
      Y: '是',
      N: '否'
    },
    '174': {
      Y: '是',
      N: '否'
    },
    '188': {
      Y: '是',
      N: '否'
    },
    '427': {
      Y: '是',
      N: '否'
    },
    '189': {
      Y: '是',
      N: '否'
    },
    '434': {
      Y: '是',
      N: '否'
    },
    '190': {
      Y: '是',
      N: '否'
    },
    '435': {
      Y: '是',
      N: '否'
    },
    '210': {
      Y: '是',
      N: '否'
    },
    '211': {
      Y: '是',
      N: '否'
    },
    '212': {
      Y: '是',
      N: '否'
    },
    '213': {
      Y: '是',
      N: '否'
    },
    '214': {
      Y: '是',
      N: '否'
    },
    '215': {
      Y: '是',
      N: '否'
    },
    '175': {
      HR: '主队 / 正规赛',
      HE: '主队 / 加时赛',
      HP: '主队 / 点球决胜',
      AR: '客队 / 正规赛',
      AE: '客队 / 加时赛',
      AP: '客队 / 点球决胜'
    },
    '177': {
      '1': '下半场主队',
      X: '下半场平手',
      '2': '下半场客队'
    },
    '430': {
      '1': '下半场主队',
      X: '下半场平手',
      '2': '下半场客队'
    },
    '178': {
      O: '大',
      U: '小'
    },
    '197': {
      O: '大',
      U: '小'
    },
    '198': {
      O: '大',
      U: '小'
    },
    '204': {
      O: '大',
      U: '小'
    },
    '205': {
      O: '大',
      U: '小'
    },
    '401': {
      O: '大',
      U: '小'
    },
    '402': {
      O: '大',
      U: '小'
    },
    '403': {
      O: '大',
      U: '小'
    },
    '404': {
      O: '大',
      U: '小'
    },
    '461': {
      O: '大',
      U: '小'
    },
    '462': {
      O: '大',
      U: '小'
    },
    '463': {
      O: '大',
      U: '小'
    },
    '464': {
      O: '大',
      U: '小'
    },
    '469': {
      O: '大',
      U: '小'
    },
    '610': {
      O: '大',
      U: '小'
    },
    '615': {
      O: '大',
      U: '小'
    },
    '616': {
      O: '大',
      U: '小'
    },
    '9003': {
      O: '大',
      U: '小'
    },
    '9009': {
      O: '大',
      U: '小'
    },
    '9013': {
      O: '大',
      U: '小'
    },
    '9019': {
      O: '大',
      U: '小'
    },
    '9025': {
      O: '大',
      U: '小'
    },
    '9029': {
      O: '大',
      U: '小'
    },
    '9035': {
      O: '大',
      U: '小'
    },
    '9041': {
      O: '大',
      U: '小'
    },
    '9047': {
      O: '大',
      U: '小'
    },
    '9053': {
      O: '大',
      U: '小'
    },
    '9058': {
      O: '大',
      U: '小'
    },
    '9060': {
      O: '大',
      U: '小'
    },
    '9070': {
      O: '大',
      U: '小'
    },
    '9074': {
      O: '大',
      U: '小'
    },
    '2803': {
      O: '大',
      U: '小'
    },
    '2804': {
      O: '大',
      U: '小'
    },
    '2811': {
      O: '大',
      U: '小'
    },
    '2812': {
      O: '大',
      U: '小'
    },
    '2807': {
      'H1-5': '主场赢1-5分',
      'H6-10': '主队赢6 - 10分',
      'H11+': '主队赢11+分',
      'A1-5': '客队赢1 - 5 points',
      'A6-10': '客队赢y 6 - 10 points',
      'A11+': '客队赢 11+ points'
    },
    '2808': {
      'H1-5': '主场赢1-5分',
      'H6-10': '主队赢6 - 10分',
      'H11+': '主队赢11+分',
      D: '平手',
      'A1-5': '客队赢1 - 5 points',
      'A6-10': '客队赢y 6 - 10 points',
      'A11+': '客队赢 11+ points'
    },
    '184': {
      O: '单',
      E: '双'
    },
    '428': {
      O: '单',
      E: '双'
    },
    '194': {
      O: '单',
      E: '双'
    },
    '203': {
      O: '单',
      E: '双'
    },
    '611': {
      O: '单',
      E: '双'
    },
    '9005': {
      O: '单',
      E: '双'
    },
    '9061': {
      O: '单',
      E: '双'
    },
    '9071': {
      O: '单',
      E: '双'
    },
    '9078': {
      O: '单',
      E: '双'
    },
    '9079': {
      O: '单',
      E: '双'
    },
    '9080': {
      O: '单',
      E: '双'
    },
    '9081': {
      O: '单',
      E: '双'
    },
    '9082': {
      O: '单',
      E: '双'
    },
    '9083': {
      O: '单',
      E: '双'
    },
    '9084': {
      O: '单',
      E: '双'
    },
    '9085': {
      O: '单',
      E: '双'
    },
    '9086': {
      O: '单',
      E: '双'
    },
    '186': {
      HD: '主队 / 平手',
      HA: '主队 / 客队',
      DA: '平手 / 客队'
    },
    '431': {
      HD: '主队 / 平手',
      HA: '主队 / 客队',
      DA: '平手 / 客队'
    },
    '192': {
      '1-10': '1-10 分钟',
      '11-20': '11-20分钟',
      '21-30': '21-30分钟',
      '31-40': '31-40分钟',
      '41-50': '41-50分钟',
      '51-60': '51-60分钟',
      '61-70': '61-70分钟',
      '71-80': '71-80分钟',
      '81-90': 'for 81-90分钟',
      None: '无'
    },
    '193': {
      '1-15': 'for 1-15分钟',
      '16-30': 'for 16-30分钟',
      '31-45': 'for 31-45分钟',
      '46-60': 'for 46-60分钟',
      '61-75': 'for 61-75分钟',
      '76-90': 'for 76-90分钟',
      None: '无'
    },
    '195': {
      '0-2': '0 - 2 角球',
      '3-4': '3 - 4 角球',
      '5-6': '5 - 6 角球',
      '7-over': '7球和以上'
    },
    '196': {
      '0-2': '0 - 2 角球',
      '3-4': '3 - 4 角球',
      '5-6': '5 - 6 角球',
      '7-over': '7球和以上'
    },
    '199': {
      '0-8': '0 - 8 角球',
      '9-11': '9 - 11角球',
      '12-over': '12角球和以上'
    },
    '200': {
      '0-1': '0 - 1 角球',
      '2': '2 角球',
      '3': '3 角球',
      '4-over': '4 角球和以上'
    },
    '201': {
      '0-1': '0 - 1 角球',
      '2': '2 角球',
      '3': '3 角球',
      '4-over': '4 角球和以上'
    },
    '202': {
      '0-4': '0-4 角球',
      '5-6': '5-6 角球',
      '7-over': '7 脚球和以上'
    },
    '206': {
      H: '主队',
      N: '无',
      A: '客队'
    },
    '207': {
      H: '主队',
      N: '无',
      A: '客队'
    },
    '208': {
      H: '主队',
      N: '无',
      A: '客队'
    },
    '209': {
      H: '主队',
      N: '无',
      A: '客队'
    },
    '603': {
      H: '主队',
      A: '客队'
    },
    '604': {
      H: '主队',
      A: '客队'
    },
    '605': {
      H: '主队',
      A: '客队'
    },
    '606': {
      H: '主队',
      A: '客队'
    },
    '607': {
      H: '主队',
      A: '客队'
    },
    '609': {
      H: '主队',
      A: '客队'
    },
    '612': {
      H: '主队',
      A: '客队'
    },
    '613': {
      H: '主队',
      A: '客队'
    },
    '617': {
      H: '主队',
      A: '客队'
    },
    '9001': {
      H: '主队',
      A: '客队'
    },
    '9002': {
      H: '主队',
      A: '客队'
    },
    '9004': {
      H: '主队',
      A: '客队'
    },
    '9006': {
      H: '主队',
      A: '客队'
    },
    '9007': {
      H: '主队',
      A: '客队'
    },
    '9008': {
      H: '主队',
      A: '客队'
    },
    '9010': {
      H: '主队',
      A: '客队'
    },
    '9011': {
      H: '主队',
      A: '客队'
    },
    '9012': {
      H: '主队',
      A: '客队'
    },
    '9014': {
      H: '主队',
      A: '客队'
    },
    '9015': {
      H: '主队',
      A: '客队'
    },
    '9016': {
      H: '主队',
      A: '客队'
    },
    '9017': {
      H: '主队',
      A: '客队'
    },
    '9018': {
      H: '主队',
      A: '客队'
    },
    '9020': {
      H: '主队',
      A: '客队'
    },
    '9021': {
      H: '主队',
      A: '客队'
    },
    '9022': {
      H: '主队',
      A: '客队'
    },
    '9023': {
      H: '主队',
      A: '客队'
    },
    '9024': {
      H: '主队',
      A: '客队'
    },
    '9026': {
      H: '主队',
      A: '客队'
    },
    '9027': {
      H: '主队',
      A: '客队'
    },
    '9028': {
      H: '主队',
      A: '客队'
    },
    '9030': {
      H: '主队',
      A: '客队'
    },
    '9031': {
      H: '主队',
      A: '客队'
    },
    '9032': {
      H: '主队',
      A: '客队'
    },
    '9033': {
      H: '主队',
      A: '客队'
    },
    '9034': {
      H: '主队',
      A: '客队'
    },
    '9036': {
      H: '主队',
      A: '客队'
    },
    '9037': {
      H: '主队',
      A: '客队'
    },
    '9038': {
      H: '主队',
      A: '客队'
    },
    '9039': {
      H: '主队',
      A: '客队'
    },
    '9040': {
      H: '主队',
      A: '客队'
    },
    '9042': {
      H: '主队',
      A: '客队'
    },
    '9043': {
      H: '主队',
      A: '客队'
    },
    '9044': {
      H: '主队',
      A: '客队'
    },
    '9045': {
      H: '主队',
      A: '客队'
    },
    '9046': {
      H: '主队',
      A: '客队'
    },
    '9048': {
      H: '主队',
      A: '客队'
    },
    '9049': {
      H: '主队',
      A: '客队'
    },
    '9050': {
      H: '主队',
      A: '客队'
    },
    '9051': {
      H: '主队',
      A: '客队'
    },
    '9052': {
      H: '主队',
      A: '客队'
    },
    '9054': {
      H: '主队',
      A: '客队'
    },
    '9055': {
      H: '主队',
      A: '客队'
    },
    '9056': {
      H: '主队',
      A: '客队'
    },
    '9057': {
      H: '主队',
      A: '客队'
    },
    '9059': {
      H: '主队',
      A: '客队'
    },
    '9062': {
      H: '主队',
      A: '客队'
    },
    '9063': {
      H: '主队',
      A: '客队'
    },
    '9064': {
      H: '主队',
      A: '客队'
    },
    '9065': {
      H: '主队',
      A: '客队'
    },
    '9066': {
      H: '主队',
      A: '客队'
    },
    '9067': {
      H: '主队',
      A: '客队'
    },
    '9068': {
      H: '主队',
      A: '客队'
    },
    '9069': {
      H: '主队',
      A: '客队'
    },
    '9072': {
      H: '主队',
      A: '客队'
    },
    '9073': {
      H: '主队',
      A: '客队'
    },
    '9075': {
      H: '主队',
      A: '客队'
    },
    '9076': {
      H: '主队',
      A: '客队'
    },
    '9077': {
      H: '主队',
      A: '客队'
    },
    '221': {
      '2': '进球-是',
      '-2': '进球-否',
      '4': '角球-是',
      '-4': '角球-否',
      '8': '任意球-是',
      '-8': '任意球-否',
      '16': '龙门球-是',
      '-16': '龙门球-否',
      '32': '界外球-是',
      '-32': '界外球-否'
    },
    '222': {
      '2': '进球-是',
      '-2': '进球-否',
      '4': '角球-是',
      '-4': '角球-否',
      '8': '任意球-是',
      '-8': '任意球-否',
      '16': '龙门球-是',
      '-16': '龙门球-否',
      '32': '界外球-是',
      '-32': '界外球-否',
      '128': '点球-是'
    },
    '223': {
      '1': '无',
      '2': '进球-是',
      '4': '角球-是',
      '8': '任意球',
      '16': '龙门球',
      '32': '界外球'
    },
    '224': {
      '1': '无',
      '2': '进球-是',
      '4': '角球-是',
      '64': '罚牌',
      '128': '点球'
    },
    '225': {
      '1': '否',
      '44': '是'
    },
    '226': {
      '26': '进球 / 任意球 / 龙门球',
      '36': '角球 / 界外球',
      '1': '无'
    },
    '227': {
      '194': '进球 / 罚牌 / 点球',
      '4': '角球',
      '1': '无'
    },
    '412': {
      '0': '没进球',
      '1': '没进球',
      '2': '进2球',
      '3-Over': '进3球以上'
    },
    '417': {
      yh: '对/主队',
      ya: '对/客队',
      yd: '对/和局',
      nh: '否/客队',
      na: '否/客队',
      nd: '否/平手'
    },
    '418': {
      yo: '对&大于',
      yu: '对&小于',
      no: '否&大于',
      nu: '否&小于'
    },
    '419': {
      '1h': '上半场',
      '2h': '下半场',
      n: '上下半场都没有进球'
    },
    '420': {
      '1h': '上半场',
      '2h': '下半场',
      n: '上下半场都没有进球'
    },
    '421': {
      '1h': '上半场',
      '2h': '下半场',
      n: '上下半场都没有进球'
    },
    '422': {
      h: '主队',
      a: '客队',
      n: '没有'
    },
    '423': {
      h: '主队',
      a: '客队',
      n: '没有'
    },
    '424': {
      s: '射门',
      h: '头射',
      p: '罚球',
      fk: '任意球',
      og: '乌龙球',
      ng: '没进球'
    },
    '426': {
      h1: '主队赢1球',
      'h2+': '主队赢2球以上',
      d: '两队进球平手',
      a1: '客队赢1球',
      'a2+': '客队赢2球以上',
      ng: '没进球'
    },
    '429': {
      '0': '没进球',
      '1': '进1球',
      '2': '进2球',
      '3-Over': '进3球以上'
    },
    '445': {
      yy: '两队都有进球/两队都有进球',
      yn: '两队都有进球/两队都没有进球',
      ny: '两队都没有进球/两队都有进球',
      nn: '两队都没有进球/两队都没有进球'
    },
    '446': {
      yy: '有/有',
      yn: '有/没有',
      ny: '没有/有',
      nn: '没有/没有'
    },
    '447': {
      yy: '有/有',
      yn: '有/没有',
      ny: '没有/有',
      nn: '没有/没有'
    },
    '448': {
      H: '主队',
      A: '客队',
      NG: '两者皆不'
    },
    '460': {
      H: '主队',
      A: '客队'
    },
    '449': {
      '1XO': '主/和局 & 大',
      '1XU': '主/和局 & 小',
      '12O': '主/客 & 大',
      '12U': '主/客 & 小',
      '2XO': '客/和局 & 大',
      '2XU': '客/和局 & 小'
    },
    '450': {
      OO: '单 & 大',
      OU: '单 & 小',
      EO: '双 & 大',
      EU: '双 &小'
    },
    '451': {
      Y1X: '是 & 主/和局',
      Y12: '是 & 主/客',
      Y2X: '是 & 客/和局',
      N1X: '否 & 主/和局',
      N12: '否 & 主/客',
      N2X: '否 & 客/和局'
    },
    '452': {
      '1H': '上半场',
      '2H': '下半场'
    },
    '453': {
      '1': '主队',
      X: '无',
      '2': '客队'
    },
    '454': {
      '1XH': '主/和局 & 主',
      '12H': '主/客 & 主',
      '2XH': '客/和局 & 主',
      '1XA': '主/和局 & 客',
      '12A': '主/客 & 客',
      '2XA': '客/和局 & 客',
      NG: '无进球'
    },
    '455': {
      '1-10': '00:01-10:00',
      '11-20': '10:01-20:00',
      '21-30': '20:01-30:00',
      '31-40': '30:01-40:00',
      '41-50': '40:01-50:00',
      '51-60': '50:01-60:00',
      '61-70': '60:01-70:00',
      '71-80': '70:01-80:00',
      '81-90': '80:01-90:00',
      NG: '无进球'
    },
    '456': {
      YH: '是/主',
      YA: '是/客',
      YD: '是/和局',
      NH: '否/主',
      NA: '否/客',
      ND: '否/和局'
    },
    '457': {
      YO: '是 & 大',
      YU: '是 & 小',
      NO: '否 & 大',
      NU: '否 & 小'
    },
    '458': {
      '1': 'FT Asian.1',
      '2': 'FT Asian.2',
      X: 'FT Asian.X'
    },
    '459': {
      '1': 'HT Asian.1',
      '2': 'HT Asian.2',
      X: 'HT Asian.X'
    },
    '468': {
      H: '主队',
      A: '客队'
    },
    '486': {
      H: '主队',
      A: '客队',
      N: '（兩者之中）無一個'
    },
    '487': {
      H: '主队',
      A: '客队',
      N: '（兩者之中）無一個'
    },
    '488': {
      H: '主队',
      A: '客队'
    },
    '489': {
      H: '主队',
      A: '客队'
    },
    '490': {
      H: '主队',
      A: '客队'
    },
    '491': {
      H: '主队',
      A: '客队'
    },
    '492': {
      H: '主队',
      A: '客队'
    },
    '470': {
      O: '单',
      E: '双'
    },
    '471': {
      O: '单',
      E: '双'
    },
    '472': {
      O: '单',
      E: '双'
    },
    '475': {
      O: '大',
      E: '精準',
      U: '小'
    },
    '476': {
      O: '大',
      E: '精準',
      U: '小'
    },
    '482': {
      O: '大',
      U: '小'
    },
    '483': {
      O: '大',
      U: '小'
    },
    '484': {
      O: '大',
      U: '小'
    },
    '485': {
      O: '大',
      U: '小'
    },
    '477': {
      '1': '主隊',
      X: '平手',
      '2': '客隊'
    },
    '478': {
      '1': '主隊',
      X: '平手',
      '2': '客隊'
    },
    '479': {
      Y: '是',
      N: '否'
    },
    '481': {
      Y: '是',
      N: '否'
    },
    '496': {
      '1H': '上半場',
      '2H': '下半場',
      Tie: '一樣多'
    },
    '497': {
      '1H': '上半場',
      '2H': '下半場'
    },
    '601': {
      'H1-2': '主队赢1到2分',
      'H3-6': '主队赢3到6分',
      'H7-9': '主队赢7到9分',
      'H10-13': '主队赢10到13分',
      'H14-16': '主队赢14到16分',
      'H17-20': '主队赢17到20分',
      'H21+': '主队赢21分以上',
      'A1-2': '客队赢1到2分',
      'A3-6': '客队赢3到6分',
      'A7-9': '客队赢7到9分',
      'A10-13': '客队赢10到13分',
      'A14-16': '客队赢14到16分',
      'A17-20': '客队赢17到20分',
      'A21+': '客队赢21分以上'
    },
    '602': {
      'H1-5': '主队赢1到5分',
      'H6-10': '主队赢6到10分',
      'H11-15': '主队赢11到15分',
      'H16-20': '主队赢16到20分',
      'H21-25': '主队赢20到25分',
      'H26+': '主队赢26分以上',
      'A1-5': '客队赢1到5分',
      'A6-10': '客队赢6到10分',
      'A11-15': '客队赢11到15分',
      'A16-20': '客队赢16到20分',
      'A21-25': '客队赢21到25分',
      'A26+': '客队赢26分以上'
    },
    '608': {
      'H1-5': '主队赢1到5分',
      'H6-10': '主队赢6到10分',
      'H11-15': '主队赢11到15分',
      'H16-20': '主队赢16到20分',
      'H21-25': '主队赢20到25分',
      'H26+': '主队赢26分以上',
      D: '平手',
      'A1-5': '客队赢1到5分',
      'A6-10': '客队赢6到10分',
      'A11-15': '客队赢11到15分',
      'A16-20': '客队赢16到20分',
      'A21-25': '客队赢21到25分',
      'A26+': '客队赢26分以上'
    },
    '614': {
      'H1-4': '主队赢1到4分',
      'H5-8': '主队赢5到8分',
      'H9+': '主队赢9分以上',
      D: '平手',
      'A1-4': '客队赢1到4分',
      'A5-8': '客队赢5到8分',
      'A9+': '客队赢9分以上'
    },
    '1302': {
      '20': '三盘制 主场 2-0 赢',
      '21': '三盘制 主场 2-1 赢',
      '02': '三盘制 客场 0-2 赢',
      '12': '三盘制 客场 1-2 赢',
      '30': '五盘制 主场 3-0 赢',
      '31': '五盘制 主场 3-1 赢',
      '32': '五盘制 主场 3-2 赢',
      '03': '五盘制 客场 0-3 赢',
      '13': '五盘制 客场 1-3 赢',
      '23': '五盘制 客场 2-3 赢'
    },
    '1317': {
      '60': '主场6-0',
      '61': '主场6-1',
      '62': '主场6-2',
      '63': '主场6-3',
      '64': '主场6-4',
      '75': '主场7-5',
      '76': '主场7-6',
      '06': '主场0-6',
      '16': '主场1-6',
      '26': '主场2-6',
      '36': '主场3-6',
      '46': '主场4-6',
      '57': '主场5-7',
      '67': '主场6-7'
    },
    '1324': {
      h: '主场',
      a: '客场'
    },
    // 百炼赛
    '81': {
      h: '大',
      a: '小'
    },
    '82': {
      h: '大',
      a: '小'
    },
    '85': {
      h: '大',
      a: '小'
    },
    '1203': {
      h: '大',
      a: '小'
    },
    '87': {
      h: '高',
      a: '低'
    },
    '88': {
      h: '第二球',
      a: '第三球'
    },
    '90': {
      '1-1': '下注号码 1~5',
      '1-2': '下注号码 6~10',
      '1-3': '下注号码 11~15',
      '1-4': '下注号码 16~20',
      '1-5': '下注号码 21~25',
      '1-6': '下注号码 26~30',
      '1-7': '下注号码 31~35',
      '1-8': '下注号码 36~40',
      '1-9': '下注号码 41~45',
      '1-10': '下注号码 46~50',
      '1-11': '下注号码 51~55',
      '1-12': '下注号码 56~60',
      '1-13': '下注号码 61~65',
      '1-14': '下注号码 66~70',
      '1-15': '下注号码 71~75',
      '2-1': '下注号码 1~15',
      '2-2': '下注号码 16~30',
      '2-3': '下注号码 31~45',
      '2-4': '下注号码 46~60',
      '2-5': '下注号码 61~75',
      '3-1': '下注号码 1~25',
      '3-2': '下注号码 26~50',
      '3-3': '下注号码 51~75',
      '4-1': '下注号码 1,6,11,16,21,26,31,36,41,46,51,56,61,66,71',
      '4-2': '下注号码 2,7,12,17,22,27,32,37,42,47,52,57,62,67,72',
      '4-3': '下注号码 3,8,13,18,23,28,33,38,43,48,53,58,63,68,73',
      '4-4': '下注号码 4,9,14,19,24,29,34,39,44,49,54,59,64,69,74',
      '4-5': '下注号码 5,10,15,20,25,30,35,40,45,50,55,60,65,70,75',
      '5-1': '下注号码 1',
      '5-2': '下注号码 2',
      '5-75': '下注号码 75'
    },
    '89': {
      '1:1': '大,单',
      '1:2': '大,双',
      '2:1': '小,单',
      '2:2': '小,双'
    },
    '8101': {
      b: '大',
      s: '小'
    },
    '8102': {
      o: '单',
      e: '双'
    },
    '8103': {
      sp: '春',
      su: '夏',
      au: '秋',
      wi: '冬'
    },
    '8104': {
      mo: '多单',
      me: '多双'
    },
    '8105': {
      bo: '大/单',
      be: '大/双',
      so: '小/单',
      se: '小/双'
    },
    '1205': {
      '1': '主队',
      x: '平手',
      '2': '客队'
    },
    '1206': {
      '0': '零进球',
      '1': '进1球',
      '2': '进2球',
      '3': '进3球',
      '4': '进4球'
    },
    '1235': {
      '1': '左边球员拿下局数: 右边球员得0分',
      '2': '左边球员拿下局数: 右边球员得15分',
      '3': '左边球员拿下局数: 右边球员得30分',
      '4': '左边球员拿下局数: 右边球员得40分',
      '5': '左边球员得 0分: 右边球员拿下局数',
      '6': '左边球员得 15分: 右边球员拿下局数',
      '7': '左边球员得 30分: 右边球员拿下局数',
      '8': '左边球员得 40分: 右边球员拿下局数'
    }
  };
  // BTI体育
  static BTITiYuGames = {
    1: '足球',
    2: '篮球',
    3: '美式足球',
    6: '网球',
    7: '棒球',
    8: '冰球',
    9: '特别赛事',
    10: '手球',
    11: '橄榄球联盟',
    12: '高尔夫球',
    13: '斯诺克台球',
    14: '赛车',
    15: '飞镖',
    16: '自行车赛',
    17: '扑克',
    18: '赛道',
    19: '排球',
    20: '搏击',
    21: '田径',
    22: '板球',
    23: '曲棍球',
    24: '地板球',
    25: '五人制足球',
    26: '乒乓球',
    27: '保龄球',
    28: '冬季运动',
    29: '板棍球',
    30: '帆船',
    31: '水球',
    32: '沙滩排球',
    33: '沙滩足球',
    34: '羽毛球',
    35: '橄榄球联盟',
    36: '竞走',
    37: '冰壶',
    38: '奥运会',
    39: 'Bandy',
    40: '沙滩手球',
    41: '澳洲规则',
    42: '冬季奥运会',
    43: '综合格斗',
    44: '射击',
    45: '游泳',
    46: '体操',
    47: '划船',
    48: '铁人三项',
    49: '皮艇',
    50: '柔道',
    51: '摔跤',
    52: '射箭',
    53: '跳水',
    54: '马术',
    55: '击剑',
    56: '现代五项',
    57: '跆拳道',
    58: '举重',
    59: '板球比赛',
    60: '冲浪',
    61: '赛马',
    62: '虚拟体育',
    63: '无挡板篮球',
    64: '电子竞技',
    65: '围棋',
    66: 'Greyhounds',
    67: '盖尔足球',
    68: '盖尔投掷'
  };
  // BBIN电子
  static BBINDZGames = {
    5005: '惑星战记',
    5010: '外星战记',
    5012: '外星争霸',
    5013: '传统',
    5014: '丛林',
    5015: 'FIFA2010',
    5019: '水果乐园',
    5027: '功夫龙',
    5030: '幸运财神',
    5039: '鱼虾蟹',
    5043: '钻石水果盘',
    5045: '森林舞会',
    5046: '斗魂',
    5054: '爆骰',
    5058: '疯狂水果盘',
    5061: '超级7',
    5062: '龙在囧途',
    5063: '水果拉霸',
    5064: '扑克拉霸',
    5065: '筒子拉霸',
    5066: '足球拉霸',
    5068: '酷搜马戏团',
    5069: '水果擂台',
    5076: '数字大转轮',
    5077: '水果大转轮',
    5079: '3D数字大转轮',
    5080: '乐透转轮',
    5083: '钻石列车',
    5084: '圣兽传说',
    5088: '斗大',
    5089: '红狗',
    5090: '金鸡报喜',
    5093: '金瓶梅',
    5094: '金瓶梅2',
    5095: '斗鸡',
    5096: '五行',
    5097: '海底世界',
    5098: '五福临门',
    5099: '金狗旺岁',
    5100: '七夕',
    5105: '欧式轮盘',
    5106: '三国',
    5107: '美式轮盘',
    5108: '彩金轮盘',
    5109: '法式轮盘',
    5110: '夜上海',
    5119: '神秘岛',
    5120: '女娲补天',
    5121: '奥林帕斯',
    5122: '球球大作战',
    5127: '绝地求生',
    5128: '多福多财',
    5138: '夹猪珠',
    5139: '熊猫乐园',
    5140: '啤酒嘉年华',
    5141: '斗牛赢家',
    5142: '魔光幻音',
    5143: '糖果派对3',
    5144: '情人夜',
    5145: '步步高升',
    5146: '糖果派',
    5150: '宝石传奇',
    5151: 'Jenga',
    5152: '埃及传奇',
    5153: '九尾狐',
    5154: '初音大进击',
    5155: '东海龙宫',
    5156: '祖玛帝国',
    5157: '三元四喜',
    5160: '雷神索尔',
    5204: '2014FIFA',
    5402: '夜市人生',
    5404: '沙滩排球',
    5407: '大红帽与小野狼',
    5601: '秘境冒险',
    5803: '阿兹特克宝藏',
    5805: '凯萨帝国',
    5810: '航海时代',
    5823: '发大财',
    5824: '恶龙传说',
    5828: '霸王龙',
    5835: '喜福牛年',
    5837: '喜福猴年',
    5839: '经典高球',
    5901: '连环夺宝',
    5902: '糖果派对',
    5903: '秦皇秘宝',
    5904: '蒸气炸弹',
    5907: '趣味台球',
    5908: '糖果派对2',
    5909: '开心消消乐',
    5910: '魔法元素',
    5912: '连环夺宝2'
  };
  // 皇冠体育
  static HGTiYuGames = {
    FT: '足球',
    BK: '篮球/美足',
    TN: '网球',
    BS: '棒球',
    OP: '其他'
  };
  // 博盛体育
  static MBTiYuGames = {
    '1': '让球',
    '2': '大小',
    '3': '单双',
    '4': '1x2',
    '5': '双重机会',
    '6': '波胆',
    '7': '总进球数',
    '8': '半场/全场'
  };
  // AGPY电子
  /*	static AGPYDZGames = {
    'YP801': '森林舞会',
    'YP802': '奔驰宝马',
    'YP803': '極速赛马',
    'YP804': '猜猜乐',
    'YP805': '水果拉霸',
    'YP806': '德州牛仔',
    'YP807': '飞禽走兽',
    'YP808': '水果派对',
    'YP810': '森林舞会多人版',
    'YP813': '水果拉霸多人版',
    'YP817': '百人牛牛',
    'YP818': '多宝水果拉霸',
    'YP820': '彩金水果拉霸',
    'YP814': '飞禽走兽多人版',
    'YP821': '牛牛对战',
    'YP811': '奔驰宝马多人版',
    'YP823': '动物狂欢',
    'YP824': '西游争霸',
    'YP822': '翻倍炸金花',
    'YP826': '刺激战场',
    'YP825': '斗三公',
    'YP827': '红黑梅方',
    'YP828': '彩金宝藏世界',
    'YP830': '疯狂德州',
    'YP831': '彩金奔驰宝马',
    'YP832': '海陆争霸',
    'YP834': '百人推筒子',
    'YP838': '功夫万条筒',
    'YP833': '双喜炸金花',
    'YP837': '凤凰传奇',
    'YP835': '全民21点',
    'YP836': '福星推筒子',
    'YP841': 'YP刮刮卡',
    'YP839': '财富转盘',
    'YP840': '射龙门',
    'YP842': '11选5',
    'YP843': '幸运7',
    'YP844': '宠物乐园',
    'YP845': '彩金飞禽走兽',
    'YP847': '皇牌ACE',
    'YP846': '彩金森林舞会'
}*/
  // AG电子
  static AGDZGames = {
    SB59: '功夫传奇',
    WC02: '疯狂弹球',
    WH52: '包租婆',
    SB60: '王牌狂徒',
    WH31: '巨龙宝藏',
    WH49: '摔跤英雄',
    WH25: '胡桃夹子',
    WH65: '神庙丽影',
    WH56: '赌城之夜',
    WH54: '极品飙车',
    WH26: '寿司大食客',
    WH40: '皇家戏台',
    WH44: '蒸汽战争',
    SB58: '魅惑魔女',
    WH36: '横行霸道',
    WH42: '古惑仔',
    WH55: '神奇宠物',
    SB57: '魔龙',
    WH28: '埃及宝藏',
    WH27: '和风剧院',
    WH30: '点石成金',
    WH23: '封神演义',
    WH07: '五狮进宝',
    WH02: '圣女贞德',
    WH12: '发财熊猫',
    WH35: '招财锦鲤',
    WH38: '十二生肖',
    WH20: '葫芦兄弟',
    WH32: '贪玩蓝月',
    WH34e: '内衣橄榄球',
    WH18: '白雪公主',
    SB55: '多宝鱼虾蟹',
    WC01: '跳跳乐',
    WH24: '僵尸来袭',
    WG21: '永恒之吻',
    WH29: '狂野女巫',
    WH22: '恐怖嘉年华',
    SC05: '百搭777',
    WA01: '钻石女王',
    WH17: '嫦娥奔月',
    WH10: '爱丽丝大冒险',
    WH11: '战火风云',
    WH06: '亚瑟王',
    WH04: '穆夏女神',
    EP02: '龙虎',
    WH03: '冠军足球',
    SB51: '王者传说',
    EP03: '骰宝',
    WH01: '阿里巴巴',
    SB47: '神奇宝石',
    SB50: 'XIN哥来了',
    SB49: '金龙珠',
    SB45: '猛龙传奇',
    SC03: '金拉霸',
    SX02: '街头烈战',
    XG13: '美女大格斗',
    XG11: '中秋佳节',
    XG12: '韩风劲舞',
    XG16: '黄金对垒',
    XG10: '龙舟竞渡',
    SB34: '冰河世界',
    SB33: '糖果碰碰乐',
    FRU2: '水果拉霸2',
    SB37: '上海百乐门',
    XG01: '龙珠',
    XG02: '幸运8',
    XG03: '闪亮女郎',
    XG04: '金鱼',
    XG05: '中国新年',
    XG06: '海盗王',
    XG07: '鲜果狂热',
    XG08: '小熊猫',
    XG09: '大豪客',
    SB30: '灵猴献瑞',
    SB28: '武财神',
    SB07: '象棋老虎机',
    SB10: '开心农场',
    SB08: '麻将老虎机',
    SB11: '夏日营地',
    SB01: '太空漫游',
    SB06: '日本武士',
    SB02: '复古花园',
    PKBJ: '杰克高手(大版视频扑克)',
    KFU: '水果拉霸',
    SB56: '赌城之夜',
    WH34: '内衣橄榄球',
    WH21: '永恒之吻'
  };
  // AG-YP电子
  static AGPYDZGames = {
    YP801: '森林舞会',
    YFD: '森林舞会',
    YP802: '奔驰宝马',
    YBEN: '奔驰宝马',
    YP803: '極速赛马',
    YHR: '極速赛马',
    YP804: '猜猜乐',
    YGS: '猜猜乐',
    YP805: '水果拉霸',
    YFR: '水果拉霸',
    YP806: '德州牛仔',
    YDZ: '德州牛仔',
    YP807: '飞禽走兽',
    YBIR: '飞禽走兽',
    YP808: '水果派对',
    YFP: '水果派对',
    YP810: '森林舞会多人版',
    YMFD: '森林舞会多人版',
    YP813: '水果拉霸多人版',
    YMFR: '水果拉霸多人版',
    YP817: '百人牛牛',
    YMBN: '百人牛牛',
    YP818: '多宝水果拉霸',
    YGFS: '多宝水果拉霸',
    YP820: '彩金水果拉霸',
    YJFS: '彩金水果拉霸',
    YP814: '飞禽走兽多人版',
    YMBI: '飞禽走兽多人版',
    YP821: '牛牛对战',
    YMBA: '牛牛对战',
    YP811: '奔驰宝马多人版',
    YMBZ: '奔驰宝马多人版',
    YP823: '动物狂欢',
    YMAC: '动物狂欢',
    YP824: '西游争霸',
    YMJW: '西游争霸',
    YP822: '翻倍炸金花',
    YMJH: '翻倍炸金花',
    YP826: '刺激战场',
    YMBF: '刺激战场',
    YP825: '斗三公',
    YMSG: '斗三公',
    YP827: '红黑梅方',
    YMJJ: '红黑梅方',
    YP828: '彩金宝藏世界',
    YJTW: '彩金宝藏世界',
    YP830: '疯狂德州',
    YMD2: '疯狂德州',
    YP831: '彩金奔驰宝马',
    YJBZ: '彩金奔驰宝马',
    YP832: '海陆争霸',
    YMSL: '海陆争霸',
    YP834: '百人推筒子',
    YMDD: '百人推筒子',
    YP838: '功夫万条筒',
    YMKM: '功夫万条筒',
    YP833: '双喜炸金花',
    YMDL: '双喜炸金花',
    YP837: '凤凰传奇',
    YMPL: '凤凰传奇',
    YP835: '全民21点',
    YMBJ: '全民21点',
    YP836: '福星推筒子',
    YMLD: '福星推筒子',
    YP841: 'YP刮刮卡',
    YMGG: 'YP刮刮卡',
    YP839: '财富转盘',
    YMFW: '财富转盘',
    YP840: '射龙门',
    YMBS: '射龙门',
    YP842: '11选5',
    YMEF: '11选5',
    YP843: '幸运7',
    YMLS: '幸运7',
    YP844: '宠物乐园',
    YMPP: '宠物乐园',
    YP845: '彩金飞禽走兽',
    YJBI: '彩金飞禽走兽',
    YP847: '皇牌ACE',
    YMRA: '皇牌ACE',
    YP846: '彩金森林舞会',
    YJFD: '彩金森林舞会'
  };
  // 美博棋牌
  static MBQPGames = {
    '1': '幸运777', // 完成
    '8': '百家乐', // 完成
    // '15': '比牌牛牛', // 暂不上线
    '19': '红黑大战', // 完成
    '41': '五星宏辉',
    '43': '骰宝', // 完成
    '45': '13水', // 完成
    '47': '抢庄牛牛', // 完成
    '52': '水果机', // 完成
    '54': '麻辣火锅', // 完成
    // '7': '王传奇', // 暂不上线
    '9': '百人牛牛', // 完成
    // '17': '21点', // 暂不上线
    '20': '炸金花', // 完成
    '42': '龙虎斗', // 完成
    // '44': '刮刮乐', // 开发中
    '46': '三公', // 完成
    '11': '皇家连环炮',
    // '50': '斗地主', // 暂不上线
    '53': '万人金花', // 完成
    '81': '红包扫雷', // 完成
    '40': '德州扑克',
    '44': '刮刮乐',
    '7': '猴王传奇',
    '49': '万人推筒子',
    '17': '21点',
    '50': '斗地主',
    '6': '捕鱼',
    '12': '埃及夺宝',
    '51': '渔场大亨',
    '10': '寻宝奇航',
    '2': '百草',
    '3': '双骰',
    '13': '2人麻将',
    '16': '印度三张牌',
    '58': '鱼虾蟹',
    '82': '色碟',
    '83': '猜AB',
    '84': '拉米纸牌',
    '21': '幸运骰子',
    '14': '奔驰宝马',
    '4': '梭哈',
    '85': '番摊',
    // 新增
    '24': '幸运轮盘',
    '15': '万圣夜',
    '27': '欢乐六星彩',
    '5': '骰子扑克',
    '26': 'BingoMoney',
    '25': '抢庄牌九',
    '29': '冰球突破',
    '28': '宝石物语',
    '59': 'Crash',
    '30': '糖果派对',
    '31': '金鸡报喜'
  };
  // QG红包
  static QGHBGames = {
    '1': '扫雷',
    '2': '牛牛',
    '3': '禁抢',
    '4': '超级牛牛',
    '5': '二八杠',
    '7': '接龙',
    '8': '二人牛牛'
  };

  // IG真人
  static IGSXGames = {
    '100': '百家乐',
    '104': '龙虎',
    '105': '骰宝',
    '108': '快3'
  };

  // 欧博真人
  static OBSXGames = {
    '101': '普通百家乐',
    '102': 'VIP百家乐',
    '103': '快速百家乐',
    '104': '咪牌百家乐',
    '201': '骰宝',
    '301': '龙虎',
    '401': '轮盘',
    '501': '博丁',
    '801': '牛牛',
    '901': '炸金花',
    '601': '石头剪刀布'
  };

  // 雷火电竞
  static LHDJGames = {
    1: 'CS:GO',
    2: 'Dota 2',
    3: 'League of Legends',
    4: 'Starcraft II',
    5: 'Overwatch',
    6: 'NBA 2K18',
    7: 'Street Fighter V',
    8: 'Hearthstone',
    9: 'Heroes of the Storm',
    10: 'Starcraft 1',
    11: 'Call of Duty',
    12: 'Rainbow Six',
    13: 'PUBG',
    14: 'King of Glory',
    15: 'Warcraft 3',
    16: 'Arena of Valor',
    17: 'Basketball',
    118: 'Rocket League',
    19: 'Fortnite',
    20: 'Game for Peace',
    21: 'Mobile Legend',
    22: 'FIFA',
    23: 'Quake',
    24: 'Valorant'
  };

  // IBO真人
  static IBOSXGames = {
    BA: '百家乐'
  };

  // EBET真人
  static EBETSXGames = {
    '1': '百家乐',
    '2': '龙虎',
    '3': '骰宝',
    '4': '轮盘',
    '5': '水果机',
    '6': '试玩水果机',
    '7': '原创百家乐',
    '8': '牛牛'
  };

  // XG真人
  static XGSXGames = {
    '1': '百家乐',
    '2': '骰宝',
    '3': '轮盘',
    '5': '龙虎',
    '6': '色碟',
    '7': '极速骰宝'
  };

  // EVO真人
  static EVOSXGames = {};

  // TG视讯
  static TGSXGames = {
    BAC: '经典百家乐',
    BAC_LS: '主播百家乐',
    SIC: '经典骰宝',
    SIC_LS: '主播骰宝',
    DTB: '经典龙虎',
    DTB_LS: '主播龙虎',
    NIU: '经典牛牛',
    NIU_LS: '主播牛牛',
    LOT_SO: '刮刮乐',
    ZBXF: '消费',
    HBFL: '返利'
  };

  // EBET 玩法类型
  static EBETPlayGame = {
    60: '闲',
    66: '闲对',
    68: '和',
    80: '庄',
    88: '庄对',
    86: '幸運六',
    81: '庄龙宝',
    61: '闲龙宝',
    70: '大',
    71: '小',
    82: '庄单',
    83: '庄双',
    62: '闲单',
    63: '闲双',
    10: '龙',
    11: '虎',
    12: '龙单',
    13: '龙双',
    14: '虎单',
    15: '虎双',
    16: '龙黑',
    17: '龙红',
    18: '虎黑',
    19: '虎红',
    100: '单',
    101: '双',
    102: '大',
    103: '小',
    104: '对子1',
    105: '对子2',
    106: '对子3',
    107: '对子4',
    108: '对子5',
    109: '对子6',
    110: '围骰1',
    111: '围骰2',
    112: '围骰3',
    113: '围骰4',
    114: '围骰5',
    115: '围骰6',
    116: '全围',
    117: '4点',
    118: '5点',
    119: '6点',
    120: '7点',
    121: '8点',
    125: '9点',
    126: '10点',
    127: '11点',
    128: '12点',
    129: '13点',
    130: '14点',
    131: '15点',
    132: '16点',
    133: '17点',
    134: '单点数',
    135: '单点数2',
    136: '单点数3',
    137: '单点数4',
    138: '单点数5',
    139: '单点数6',
    140: '组合1-2',
    141: '组合1-3',
    142: '组合1-4',
    143: '组合1-5',
    144: '组合1-6',
    145: '组合2-3',
    146: '组合2-4',
    147: '组合2-5',
    148: '组合2-6',
    149: '组合3-4',
    150: '组合3-5',
    151: '组合3-6',
    152: '组合4-5',
    153: '组合4-6',
    154: '组合5-6',
    155: '二同号',
    156: '三不号',
    200: '直接注',
    201: '分注',
    202: '街注',
    203: '角注',
    204: '三数',
    205: '四个号码',
    206: '线注',
    207: '列注',
    208: '打注',
    209: '红',
    210: '黑',
    211: '单',
    212: '双',
    213: '大',
    214: '小',
    301: '闲家1号平倍',
    302: '闲家1号翻倍',
    303: '闲家2号平倍',
    304: '闲家2号翻倍',
    305: '闲家3号平倍',
    306: '闲家3号翻倍'
  };

  // 沙巴彩票
  static SBCPGames = {
    1: '沙巴彩票',
    202: '-'
  };

  // 天成彩票
  static TCCPGames = {
    THAIGOV: '泰国彩',
    TCGSEA3D5: 'TCG300秒3D',
    TCGSEA3D3: 'TCG180秒3D',
    TCGSEA3D1: 'TCG60秒3D',
    TCGTURBO3D: 'TCG急速3D',
    TNVNC: '西宁越南彩',
    VLVNC: '永隆越南彩',
    HANOIVNC: '越南河内彩',
    AGVNC: '安江越南彩',
    BDHVNC: '平定越南彩',
    KGVNC: '坚江越南彩',
    TBHVNC: '太平越南彩',
    DNOVNC: '嘉义越南彩',
    HGVNC: '后江越南彩',
    KHVNC: '庆和越南彩',
    NTVNC: '宁顺越南彩',
    STVNC: '朔庄越南彩',
    TGVNC: '前江越南彩',
    DNGVNC: '岘港越南彩',
    HCMVNC: '胡志明市越南彩',
    NDVNC: '南定越南彩',
    GLVNC: '嘉来越南彩',
    HPVNC: '海防越南彩',
    QNIVNC: '广义越南彩',
    TVVNC: '茶荣越南彩',
    CTVNC: '芹苴越南彩',
    PYVNC: '富安越南彩',
    VTVNC: '头顿越南彩',
    QNHVNC: '广宁越南彩',
    BDVNC: '平阳越南彩',
    LDVNC: '林同越南彩',
    QNMVNC: '广南越南彩',
    BNVNC: '北宁越南彩',
    DNVNC: '同奈越南彩',
    KTVNC: '昆嵩越南彩',
    LAVNC: '龙安越南彩',
    QBVNC: '广平越南彩',
    QTVNC: '广治越南彩',
    DTVNC: '东塔越南彩',
    BPVNC: '平福越南彩',
    CMVNC: '金瓯越南彩',
    BTHVNC: '平顺越南彩',
    LAOCAI: '老挝彩',
    TCGSEA4D3: 'TCG180秒4D',
    TCGSEA4D1: 'TCG60秒4D',
    TCGSEA4D5: 'TCG300秒4D',
    'DMCMAS@': '大马彩',
    'MN4DMAS@': '万能4D',
    'TOTOMAS@': '多多万字',
    '4DSGC@': '新加坡4D',
    '4DTWC@': '台湾四星彩',
    SHSTOCK: '上海指数',
    KRSTOCK: '韩国指数',
    SGSTOCK: '新加坡指数',
    SZSTOCK: '深圳指数',
    HKSTOCK: '恒生指数',
    MYSTOCK: '马来西亚指数',
    TWSTOCK: '台湾指数',
    INSTOCK: '印度指数',
    JPSTOCK: '日经指数',
    THSTOCK: '泰国指数'
  };

  // GPI彩票
  static GPIcaipiao = {
    keno: '快乐彩',
    pk10: 'PK10',
    ladder: '画鬼脚',
    thailotter: '泰国彩票',
    rockpaperscissors: '石头剪刀布',
    fast3: '快3',
    thor: '雷神',
    sode: '梭达彩票',
    taixiu: '越南骰宝',
    luckyderby: '宝马大玩家',
    xocdia: '色碟',
    thaihilo: '泰式猜猜猜',
    fishprawncrab: '鱼虾蟹',
    fishprawncrabgame: '鱼虾蟹-刮刮卡',
    moneyblast: '冲吧！小宇宙',
    super98baccarat: '超级98百家乐'
  };

  // Kingmaker
  static KingmakerGame = {
    Thai_Hi_Lo_2: '泰式比高低',
    Fish_Prawn_Crab_2: '鱼虾蟹2',
    Belangkai_2: '鱼虾蚝2',
    Dragon_Tiger_2: '龙虎2',
    Fan_Tan_2: '番摊2',
    Viet_Fish_Prawn_Crab: '越式鱼虾蟹',
    Sicbo: '骰宝',
    Tai_Xiu: '越式大小',
    Max_Keno: '快乐彩',
    Poker_Roulette: '扑克轮盘',
    Thai_Fish_Prawn_Crab: '泰式鱼虾蟹',
    Xoc_Dia_Lightning: '色碟2',
    Fan_Tan_Classic: '番摊经典',
    Triple_Chance: '三重轮盘',
    Fruit_Roulette: '水果轮盘',
    Xoc_Dia: '色碟',
    Bai_Cao: '三公',
    'thai-hi-lo-2': '泰式比高低',
    'fish-prawn-crab-2': '鱼虾蟹2',
    'belangkai-2': '鱼虾蚝2',
    'dragon-tiger-2': '龙虎2',
    'fan-tan-2': '番摊2',
    'viet-fish-prawn-crab': '越式鱼虾蟹',
    sicbo: '骰宝',
    'tai-xiu': '越式大小',
    'max-keno': '快乐彩',
    'poker-roulette': '扑克轮盘',
    'thai-fish-prawn-crab': '泰式鱼虾蟹',
    'xoc-dia-2': '色碟2',
    'fan-tan-3': '番摊经典',
    'triple-chance': '三重轮盘',
    'fruit-roulette': '水果轮盘',
    'xoc-dia': '色碟',
    'bai-cao': '三公'
  };

  static EVOPlayGame = {
    296: '糖果梦',
    4: '埃及众神',
    82: '精灵公主',
    13: '金钱暴',
    19: '罗宾汉',
    79: '宝藏任务',
    5517: '幸运七',
    28: '美猴王',
    22: '贺新年',
    95: '皇帝陵墓',
    25: '鬼船海盗',
    10: '篮球联盟',
    719: '古老传说',
    101: '骷髅奇兵',
    728: '黄金时代',
    34: '赌城之夜',
    5695: '果冻啾啾',
    5452: '地城探险',
    5659: '爆炸水果',
    5547: '动物探索',
    1018: '驯兽宝贝',
    76: '赤壁',
    5641: '毛怪复仇者',
    16: '嬉游长城',
    976: '水果盘',
    736: '战车',
    979: '魅惑女王',
    742: '豚宝奇兵',
    85: '火线武力',
    7: '中發白',
    988: '幸运女孩',
    94: '疯狂木乃伊',
    1003: '终极杀阵',
    970: '死灵法师',
    37: '珠宝人生',
    88: '夏日派对',
    107: '足球女将',
    98: '亚特兰蒂斯',
    994: 'E.T.军团',
    31: '少林传奇',
    733: '大冲突',
    991: '辛迪加',
    104: '舞娘俱乐部',
    110: '辛巴达',
    725: '冒险飞船',
    982: '能源冲突',
    973: '神秘星球',
    722: '七十年代',
    293: '罗宾逊',
    739: '苏联杂货店',
    113: '图腾岛',
    730: '怪物实验室',
    5455: '撒',
    5398: '核世界',
    5479: '狂野圣诞',
    5483: '迷宫:渴望权力',
    5493: '奇怪旅游',
    5487: '饥饿之夜',
    5527: '四季美人',
    5485: '卡恩传奇',
    5551: '爱尔兰狂',
    5549: '乐透吧',
    5569: '能源冲突',
    5587: '经典老虎机',
    5593: '火箭之星',
    5653: '午夜表演',
    5673: '海上旋转',
    5677: '冲浪区',
    5721: '森林之梦',
    5717: '浣熊传说',
    5727: '被遗忘的寓言',
    5735: '欢乐宝',
    5737: '活生生的故事之夜',
    946: '欧洲轮盘',
    745: '二十一点幸运七人制',
    751: '经典绿洲扑克',
    5553: '德州扑克',
    1006: '印度三张',
    748: '绿洲扑克经典赛',
    5669: '法国轮盘',
    967: '百家乐777',
    5679: '德州扑克',
    1009: '美国轮盘3D',
    1010: '俄罗斯扑克',
    949: '骰吧',
    5491: '刮刮卡',
    964: '抛硬币',
    5525: '拆弹小组',
    961: '比大小',
    985: '剪刀石头布',
    5523: '幸运轮',
    952: '快演猜球',
    5736: '时间之轮',
    958: '四个王牌',
    5344: '高射手',
    5643: '点球大战',
    5489: '骑驴扫弹',
    955: '寻找女王',
    5585: '水手猜奖',
    5573: '踩地雷',
    5687: '足球专家',
    5545: '黑手党:辛迪加',
    1012: 'E.T.种族'
  };

  static PPPlayGame = {
    vs20goldfever: '富矿宝石',
    vs25walker: '行尸走肉',
    vs25samurai: '崛起的武士',
    vs1024lionsd: '五狮团拜',
    vswayshive: '星际赏金',
    vs20rhinoluxe: '巨大犀牛加强版',
    vs576treasures: '爱尔兰人宝藏',
    vs5ultra: '超级持控旋转',
    vswayswerewolf: '狼人的诅咒',
    vs20gorilla: '深林之猩',
    vs9aztecgemsdx: '古时代宝石加强版',
    vswaysdogs: '汪汪之家Megaways',
    vs25tigerwar: '武松打虎',
    vs40streetracer: '街头赛车',
    vs1fufufu: '福福福',
    vs1600drago: '群龙保珠-宝石致富',
    vs75bronco: '布洛克精神',
    vs25pyramid: '王者金字塔',
    vs5ultrab: '超级燃烧',
    vs1money: '钱生钱',
    vs117649starz: '行星宝石Megaways',
    vs20fruitparty: '水果派对',
    vs10bookoftut: '约翰·亨特之埃及图特书',
    vs10threestar: '三星报喜',
    vswaysrhino: '巨大犀牛Megaways',
    vs5hotburn: '热火燃烧',
    vs243dancingpar: '跳舞趴',
    vs40wildwest: '西部牛仔黄金地段',
    vs1ball: '金龙吐珠',
    vs40frrainbow: '彩虹水果',
    vs40madwheel: '疯狂机器',
    vs7776aztec: '阿兹特克获利之道',
    vs4096mystery: '神秘之城',
    vs75empress: '贵妃美人',
    vs1masterjoker: '杰克大师',
    vs5super7: '超级7s',
    vs20kraken: '解放大海怪',
    vs4096bufking: '王者野牛',
    vs8magicjourn: '西游争霸',
    vs25mmouse: '招财鼠',
    vs20aladdinsorc: '阿拉丁和巫师',
    vs243fortseren: '希腊众神',
    vs20sbxmas: '甜心盛宴圣诞',
    vs20hercpeg: '大力神和飞马',
    vs10firestrike: '红火暴击',
    vs20honey: '甜蜜蜜',
    vs5spjoker: '超炫小丑',
    vs25scarabqueen: '金龟子女王',
    vs1fortunetree: '发发树',
    vs20chicken: '小鸡大逃亡',
    vs10vampwolf: '吸血鬼vs狼',
    vs9hotroll: '超级辣',
    vs7776secrets: '阿兹特克秘宝',
    vs243mwarrior: '酷猴战士',
    bndt: '龙虎斗',
    vs5trjokers: '3倍小丑',
    vs243lionsgold: '5金狮',
    vs20wildpix: '野精灵',
    vs20fruitsw: '甜入心扉',
    vs243caishien: '财神运财',
    vs40pirate: '夺金海贼',
    vs20doghouse: '汪汪之家',
    vs20egypttrs: '埃及宿命',
    vs10fruity2: '额外多汁',
    bnadvanced: '龙宝百家乐',
    vs25gladiator: '狂野角斗士',
    vs25goldpig: '招财福猪',
    vs18mashang: '马上有钱',
    vs50safariking: '狩猎之王',
    vs20leprexmas: '小妖之歌圣诞版',
    vs25mustang: '黄金野马',
    vs5trdragons: '龙龙龙',
    bca: '百家乐',
    vs10egyptcls: '古代埃及经典版',
    vs20vegasmagic: '魔力维加斯',
    vs9chen: '陈师傅的财富',
    vs25davinci: '达芬奇宝藏',
    vs25peking: '好运北京',
    vs20leprechaun: '小妖之歌',
    vs1024butterfly: '玉蝴蝶',
    vs10madame: '命运女士',
    vs25asgard: '仙宫',
    vs243lions: '5雄狮',
    vs25champ: '冠军杯',
    vs20rhino: '巨大犀牛',
    vs5joker: '小丑珠宝',
    vs15fairytale: '童话财富',
    vs7fire88: '88火',
    vs25chilli: '火辣辣',
    bjmb: '美式二十一点',
    vs5aztecgems: '古时代宝石',
    vs10egypt: '古代埃及',
    vs25newyear: '幸运新年',
    vs1tigers: '三只老虎',
    vs9madmonkey: '猴子疯狂',
    vs25goldrush: '淘金热',
    vs20santa: '圣诞老人',
    vs25pandagold: '熊猫财富',
    cs5moneyroll: '财源滚滚',
    vs7pigs: '7只小猪',
    vs15diamond: '钻石罢工',
    vs25vegas: '维加斯之夜',
    vs25wildspells: '法力无边',
    vs243fortune: '财神黄金',
    vs50pixie: '精灵翅膀',
    vs3train: '黄金列车',
    vs1dragon8: '发发发龙',
    vs4096jurassic: '侏罗纪巨兽',
    vs20eightdragons: '8条龙',
    vs25kingdoms: '三国',
    vs25wolfgold: '野狼黄金',
    vs25pantherqueen: '黑豹女王',
    vs1024atlantis: '亚特兰蒂斯女王',
    cs3irishcharms: '爱尔兰的魅力',
    vs25queenofgold: '黄金女王',
    cs5triple8gold: '富贵888',
    bjma: '21点之富贵临门',
    cs3w: '永恒钻石3线',
    vs25dragonkingdom: '龙之国度',
    vs50hercules: '宙斯之子赫拉克勒斯',
    vs50aladdin: '3个精灵愿望',
    vs30catz: '猫爸第二部分',
    vs25journey: '西游记',
    vs40beowulf: '贝奥武夫',
    vs50chinesecharms: '飞龙在天',
    vs9hockey: '狂野冰球赛',
    vs25dwarves_new: '矮人黄金豪华版',
    vs25romeoandjuliet: '罗密欧与朱丽叶',
    vs25safari: '野生动物园',
    vs9catz: '猫咪派对',
    vs50kingkong: '无敌金刚',
    vs20godiva: '女神戈帝梵',
    vs15ktv: '歌厅',
    vs243crystalcave: '魔幻水晶',
    vs20hockey: '曲棍球联盟',
    vs50amt: '阿拉丁宝藏',
    vs13ladyofmoon: '月之女神',
    vs20egypt: '埃及传说',
    vs20cm: '极速糖果',
    vs20cw: '极速糖果冬日版',
    vs20cmv: '极速糖果情人版',
    vs20cms: '极速糖果夏日版',
    vs20gg: '幽灵财富',
    rla: '轮盘',
    kna: '基诺',
    vpa: '杰克扑克',
    vs25sea: '大堡礁',
    vs20rome: '荣耀罗马',
    vs25h: '水果爆破',
    vs25dwarves: '矮人黄金',
    vs13g: '恶魔13',
    vs20bl: '勤劳蜜蜂',
    vs7monkeys: '7只猴子',
    vs15b: '疯狂7s',
    vs25spotz: '骑士热点',
    vs15godsofwar: '宙斯vs哈迪斯-众神之战',
    vs20stickywild: '野牛冲锋',
    vs20excalibur: '圣剑出鞘',
    vswayseternity: '埃及钻石',
    vs10kingofdth: '亡者王国',
    vs20mvwild: '阿拉伯之梦',
    vs10jnmntzma: '珍亨特与蒙特苏马的面具',
    vswaysultrcoin: '牛仔金币',
    vs10gizagods: '吉萨之神',
    vswaysrsm: '狂野名人大巴',
    vs20hotzone: '非洲大象',
    vs1024moonsh: '登陆月球',
    vswaysredqueen: '红桃王后',
    vs10bbhas: '疯狂大鲈鱼持控旋转',
    vs20framazon: '亚马逊果实',
    vs20sknights: '骑士之王',
    vs20doghousemh: '汪汪之家多方位持控',
    vs20goldclust: '兔子乐园',
    vs20pistols: '西部牛仔对决',
    vswaysmorient: '东方的奥秘',
    vswayswwriches: '爱尔兰人宝藏Megaways',
    vs10powerlines: '极限能量',
    vs12tropicana: '热带俱乐部',
    vs25archer: '热火弓箭手',
    vs20gatotfury: '迦多铎卡之怒',
    vs20mochimon: '麻糬宝贝',
    vs20superlanche: '怪物超人',
    vs10fisheye: '埃及鱼之眼',
    vs25spgldways: '神秘黄金之城',
    vs20ltng: '海报女郎',
    vs20mammoth: '猛犸黄金Megaways',
    vswayswwhex: '狂野美味香蕉',
    vs20aztecgates: '阿茲特克之门',
    vswaysfuryodin: '奥丁之怒Megaways',
    vs20clspwrndg: '甜品派对Powernudge',
    vs20dugems: '辣椒狂热',
    vswayspizza: '比萨！比萨？比萨！',
    vs25rlbank: '摇滚银行',
    vswaysrabbits: '5金兔Megaways',
    vs20drgbless: '神龙大侠',
    vswaysluckyfish: '幸运捕鱼Megaways',
    vs10bbkir: '疯狂大鲈鱼持续旋转',
    vs20sparta: '斯巴达之盾',
    vs20gatotgates: '印尼传奇加多铎卡',
    vs20mtreasure: '海盗黄金时代',
    vs20lcount: '塞伦盖蒂之宝石',
    vswaysconcoll: '火鸟精神',
    vs20theights: '摩天大楼',
    vs20schristmas: '星光圣诞系列',
    vs20kraken2: '解放大海怪2',
    vs20porbs: '圣诞惊喜礼物',
    vs25kfruit: '阿茲特克火焰',
    vs20asgard: '阿斯加德之王',
    vs12bbbxmas: '超级大鲈鱼暴风雨-圣诞鱼获',
    vswaysfrywld: '旋转及射龙门Megaways',
    vs10tut: '约翰猎人与埃及之书重转',
    vswaysoldminer: '黄金老矿工Megaways',
    vs20swordofares: '希腊战神之剑',
    vs20mparty: '狂野蹦跳青蛙',
    vswaysbook: '埃及金沙之书',
    vswayslofhero: '英雄联盟Megaways',
    vs5strh: '火热闪烁5',
    vs20muertos: '墨西哥亡灵节Megaways',
    vs10crownfire: '王冠之火',
    vswaysstrwild: '糖果星星',
    vs20trswild2: '黑公牛',
    vs20wildman: '百搭超人星际战士',
    vswaysfltdrg: '鱼跃龙门Megaways',
    vs10mmm: '魔幻钱迷宫',
    vs20underground: '伦敦地铁',
    vs20wolfie: '野狼与小猪',
    vs100firehot: '热火100',
    vs40firehot: '热火40',
    vs20fh: '热火20',
    vs5firehot: '热火5',
    vs1024gmayhem: '暴怒猩猩',
    vs20octobeer: '十月啤酒嘉年华',
    vs40hotburnx: '极度热火燃烧',
    vs243ckemp: '调皮的皇帝',
    vswaysjkrdrop: '热带提基',
    vs10coffee: '拉摩卡百搭',
    vs100sh: '閃亮热火100',
    vs40sh: '閃亮热火40',
    vs20sh: '閃亮热火20',
    vs5sh: '閃亮热火5',
    vs10txbigbass: '大鲈鱼溅水',
    vs25bomb: '轰炸富矿',
    vs1024mahjpanda: '熊猫麻将',
    vs40cosmiccash: '宇宙现金',
    vs5littlegem: '优质宝石持控旋转',
    vs10egrich: '艳后之神',
    vs20cleocatra: '埃及神猫',
    vs243koipond: '招财锦鲤',
    vs40samurai3: '崛起的武士3',
    vs50northgard: '北境守护者',
    vs20stickysymbol: '城市大盗',
    vswayszombcarn: '丧尸嘉年华',
    vs20gobnudge: '地精时代',
    vs40cleoeye: '埃及艳后之眼',
    vs10firestrike2: '红火暴击2',
    vs10spiritadv: '寻宝冒险精神',
    vs20drtgold: '钻取金子',
    vs20mustanggld2: '黄金三叶草',
    vswayswildwest: '西部牛仔黄金地段Megaways',
    vs10chkchase: '农场追逐',
    vs20farmfest: '农场嘉年华',
    vs243queenie: '女王',
    vs243discolady: '迪斯科女士',
    vs10tictac: '井字游戏',
    vs20rainbowg: '黄金彩虹',
    vs10snakeladd: '蛇梯棋超级骰子',
    vs25bullfiesta: '斗牛嘉年华',
    vs50mightra: '埃及神祇',
    vs10bookazteck: '阿兹特克国王之书',
    vs20bchprty: '疯狂海滩派对',
    vs20ultim5: '终极之5',
    vs20colcashzone: '巨大的现金区域',
    vs25copsrobbers: '巡逻现警',
    vs20rockvegas: '石头族赌城',
    vswayselements: '五行宝石Megaways',
    vs10runes: '瓦尔哈拉之门',
    vs25goldparty: '黄金派对',
    vswaysxjuicy: '额外多汁Megaways',
    vs40wanderw: '深海动物',
    vs4096magician: '神秘的巫师',
    vswayscryscav: '水晶洞穴Megaways',
    vs20smugcove: '走私者海湾',
    vs243empcaishen: '财神驾到',
    vs25tigeryear: '虎虎生财',
    vs20amuleteg: '埃及财富',
    vs20superx: '超级X',
    vswaysbbb: '疯狂大鲈鱼Megaways',
    vs88hockattack: '冰球竞赛',
    vs25btygold: '赏金猎人',
    vs10bblpop: '泡泡糖',
    vs10bookfallen: '坠落之书',
    vs10bxmasbnza: '圣诞大鲈鱼',
    vs20santawonder: '圣诞仙境',
    vs40bigjuan: '墨西哥餐厅',
    vs20bermuda: '约翰猎人与百慕大财富',
    vs10starpirate: '星际海盗代码',
    vs20tweethouse: '禽鸟之屋',
    vswayswest: '神秘的酋长',
    vs20daydead: '亡灵节',
    vs20candvil: '糖果部落',
    vs9piggybank: '百万$小猪',
    vswaysbankbonz: '水果银行',
    vs20trsbox: '疯狂宝藏',
    vs12bbb: '超疯狂大鲈鱼',
    vs20starlight: '星光公主',
    vs243chargebull: '牛转钱坤',
    vs20pbonanza: '疯狂金字塔',
    vs10nudgeit: '吉萨金字塔PowerNudge',
    vswaysyumyum: '可口好吃Powerways',
    vswayschilheat: '超级辣椒Megaways',
    vs10luckcharm: '幸运，恩典和魅力',
    vswaysaztecking: '阿兹特克国王Megaways',
    vs20chickdrop: '金鸡下蛋',
    vs20phoenixf: '霹雳凤凰',
    vs20fparty2: '水果派对2',
    vs20emptybank: '盗空银行',
    vswayslight: '幸运雷神',
    vs25rio: '裏約之心',
    vs5drhs: '舞龙热控旋转',
    vswayslions: '5金狮Megaways',
    vs576hokkwolf: '北海道之狼',
    vs20terrorv: '现金升降机',
    vswayssamurai: '崛起的武士Megaways',
    vs20magicpot: '魔法大釜绝佳药剂',
    vs25aztecking: '阿茲特克国王',
    vs10amm: '奇幻无比金钱取款机',
    vs25pandatemple: '熊猫财富2',
    vs10floatdrg: '鱼跃龙门持控旋转',
    vswaysbufking: '王者野牛Megaways',
    vs25hotfiesta: '狂热嘉年华',
    vswayshammthor: '雷神之锤Megaways',
    vs20wildboost: '百搭加强器',
    vs50juicyfr: '多汁水果',
    vs1024temuj: '铁木真宝物',
    vs10goldfish: '大鱼奇缘',
    vs20olympgate: '奥林匹斯之门',
    vs20hburnhs: '热火燃烧持控旋转',
    vs25jokerking: '小丑王国',
    vs20midas: '迈达斯之手',
    vs10eyestorm: '风暴之眼',
    vs5drmystery: '神龙宝藏雄火之眼',
    vswaysmadame: '命运女巫Megaways',
    vs432congocash: '刚果财富',
    vs20ekingrr: '翡翠之王彩虹道路',
    vs10wildtut: '古埃及神话',
    vs25gldox: '鸿运你最牛',
    vs40voodoo: '恶毒魔法',
    vs10bbbonanza: '疯狂大鲈鱼',
    vs20bonzgold: '黄金富矿',
    vs10mayangods: '约翰猎人与玛雅神迹',
    vs40spartaking: '斯巴达之王',
    vs20xmascarol: '圣诞颂歌Megaways',
    vs10cowgold: '黄金叛变',
    vs40pirgold: '黄金海盗加强版',
    vs20eking: '翡翠之王',
    vs1024dtiger: '龙虎榜',
    vs25bkofkngdm: '圣书帝国',
    vs10returndead: '活死人归来'
  };

  // PG电子
  static PGGames = {
    1: '夜戏貂蝉',
    3: '横财来啦',
    24: '旺旺旺',
    6: '美杜莎II',
    26: '摇钱树',
    7: '美杜莎',
    12: '欧式二十一点',
    11: '美式二十一点',
    25: '抓抓乐',
    17: '巫师之书',
    2: '宝石侠',
    19: '蒸汽朋克:命运的旋转',
    4: '国王的召唤',
    18: '逆袭的小红帽',
    28: '麻辣火锅',
    29: '鱼跃龙门',
    35: '万胜狂欢夜',
    34: '后羿射日',
    36: '舞狮进宝',
    33: '嘻哈熊猫',
    37: '圣诞欢乐送',
    31: '至尊百家乐',
    38: '宝石侠-大宝剑',
    39: '金猪报财',
    41: '埃及寻宝',
    44: '皇上吉祥',
    42: '象财神',
    43: '三只猴子',
    40: '水果丛林',
    48: '双囍临门',
    53: '冰雪大冲关',
    50: '嘻游记',
    54: '赏金船长',
    60: '爱尔兰精灵',
    61: '唐伯虎点秋香',
    59: '忍者vs武侍',
    64: '拳霸',
    63: '龙虎争霸',
    65: '麻将胡了',
    57: '寻龙探宝',
    68: '鼠鼠福福',
    20: '亲爱的',
    62: '宝石侠-宝藏征途',
    67: '少林足球',
    71: '赢财神',
    70: '糖果连连爆',
    69: '比基尼天堂',
    74: '麻将胡了2',
    73: '埃及探秘宝典',
    75: '福运象财神',
    76: '高低扑克',
    82: '凤凰传奇',
    79: '澳门壕梦',
    78: '高低五福',
    77: '高低骰宝',
    83: '火树赢花',
    81: '添好运',
    85: '阿拉丁神灯',
    87: '寻宝黄金城',
    80: '欢乐嘉年华',
    92: '水上泰神奇',
    90: '艳后之谜',
    58: '德古拉女爵',
    84: '赏金女王',
    88: '金玉满堂',
    89: '招财喵',
    97: '冰锋奇侠',
    86: '星旅淘金',
    91: '冰火双娇',
    93: '新国粹',
    98: '十倍金牛',
    94: '巴厘之旅',
    103: '比特淘金',
    95: '宝石传奇',
    100: '糖心风暴',
    105: '霹雳神偷',
    101: '太阳神传说',
    109: '寿司之道',
    110: '恐龙帝国',
    102: '人鱼公主',
    111: '地鼠总动员',
    113: '丽影奇兵之探秘埃及',
    115: '超市大血拼',
    108: '美洲野牛',
    107: '美猴王传奇',
    119: '百鬼夜行',
    116: '星际农场',
    114: '钞级表情包',
    117: '夜醉佳人',
    118: '假面嘉年华',
    112: '江山美景图',
    126: '虎虎生财',
    122: '神鹰宝石',
    121: '日月星辰',
    125: '蝶恋花',
    123: '斗鸡',
    120: '韩宫御宴',
    124: '绝地大逃杀',
    129: '发财鱼虾蟹',
    130: '电玩金猪',
    127: '极速赢家',
    128: '珀尔修斯传奇',
    132: '疯赚过山车',
    135: '赏金大对决',
    1312883: '黄金摇钱树',
    1338274: '图腾奇迹',
    1368367: '炼金工坊',
    1340277: '维京纪元',
    1372643: '美食美刻',
    1402846: '点石成金',
    1543462: '金钱兔',
    1420892: '电音派对',
    1381200: '夏威夷探奇',
    1418544: '烘焙总动员',
    1448762: '泰嗨潑水節',
    1432733: '元素精靈'
  };


  // PG 电子新
  static PGSlotNew = {
    "65": "麻将胡了",
    "74": "麻将胡了2",
    "87": "寻宝黄金城",
    "60": "爱尔兰精灵",
    "89": "招财喵",
    "54": "赏金船长",
    "84": "赏金女王",
    "104": "亡灵大盗",
    "106": "麒麟送宝",
    "57": "寻龙探宝",
    "48": "双囍临门",
    "53": "冰雪大冲关",
    "71": "赢财神",
    "75": "福运象财神",
    "79": "澳门壕梦",
    "98": "十倍金牛",
    "135": "赏金大对决",
    "1312883": "黄金摇钱树",
    "1372643": "美食美刻",
    "73": "埃及探秘宝典",
    "82": "凤凰传奇",
    "83": "火树赢花",
    "92": "水上泰神奇",
    "94": "巴厘之旅",
    "103": "比特淘金",
    "110": "恐龙帝国",
    "117": "夜醉佳人",
    "126": "虎虎生财",
    "127": "极速赢家",
    "128": "珀尔修斯传奇",
    "1": "夜戏貂蝉",
    "3": "横财来啦",
    "24": "旺旺旺",
    "6": "美杜莎II",
    "26": "摇钱树",
    "7": "美杜莎",
    "25": "抓抓乐",
    "2": "宝石侠",
    "18": "逆袭的小红帽",
    "28": "麻辣火锅",
    "29": "鱼跃龙门",
    "35": "万胜狂欢夜",
    "34": "后羿射日",
    "36": "舞狮进宝",
    "33": "嘻哈熊猫",
    "37": "圣诞欢乐送",
    "31": "至尊百家乐",
    "38": "宝石侠-大宝剑",
    "39": "金猪报财",
    "41": "埃及寻宝",
    "44": "皇上吉祥",
    "42": "象财神",
    "40": "水果丛林",
    "50": "嘻游记",
    "61": "唐伯虎点秋香",
    "59": "忍者vs武侍",
    "64": "拳霸",
    "63": "龙虎争霸",
    "68": "鼠鼠福福",
    "20": "亲爱的",
    "62": "宝石侠-宝藏征途",
    "67": "少林足球",
    "70": "糖果连连爆",
    "69": "比基尼天堂",
    "85": "阿拉丁神灯",
    "80": "欢乐嘉年华",
    "90": "艳后之谜",
    "58": "德古拉女爵",
    "88": "金玉满堂",
    "97": "冰锋奇侠",
    "86": "星旅淘金",
    "91": "冰火双娇",
    "93": "新国粹",
    "95": "宝石传奇",
    "100": "糖心风暴",
    "105": "霹雳神偷",
    "101": "太阳神传说",
    "102": "人鱼公主",
    "113": "丽影奇兵之探秘埃及",
    "115": "超市大血拼",
    "108": "美洲野牛",
    "107": "美猴王传奇",
    "119": "百鬼夜行",
    "114": "钞级表情包",
    "118": "假面嘉年华",
    "112": "江山美景图",
    "122": "神鹰宝石",
    "121": "日月星辰",
    "125": "蝶恋花",
    "123": "斗鸡",
    "120": "韩宫御宴",
    "124": "绝地大逃杀",
    "129": "发财鱼虾蟹",
    "130": "电玩金猪",
    "132": "疯赚过山车",
    "1338274": "图腾奇迹",
    "1368367": "炼金工坊",
    "1340277": "维京纪元",
    "1402846": "点石成金",
    "1543462": "金钱兔",
    "1420892": "电音派对",
    "1381200": "夏威夷探奇",
    "1418544": "烘焙总动员",
    "1448762": "泰嗨泼水节",
    "1432733": "元素精灵",
    "1513328": "超级高尔夫",
    "1601012": "幸运草之恋",
    "1397455": "水果甜心",
    "1473388": "皇家游轮",
    "1594259": "非洲大冒险",
    "1572362": "角斗士荣耀"
  }


  // UPG电子
  static UPGGameCode = {
    UPG_6TokensofGold: '6枚黄金代币',
    UPG_11Champions: '11勇士',
    UPG_3TinyGods: '3小神',
    UPG_africanQuest: '非洲历险',
    UPG_aliyasWishes: '阿里娅的愿望',
    UPG_allWinFC: '全胜足球俱乐部',
    UPG_almightyAztec: '伟大阿兹特克',
    UPG_ancientWarriors: '远古勇士',
    UPG_anderthals: '尼人',
    UPG_aquaticTreasures: '深海宝藏',
    UPG_arenaofGold: '黄金竞技场',
    UPG_arthursGold: '亚瑟王黄金',
    UPG_attackonRetro: '进击复古城',
    UPG_attackOnRetro: '进击复古城',
    UPG_auroraBeastHunter: '欧若拉野兽猎人',
    UPG_bookOfAtem: '阿特曼之书',
    UPG_diamondForce: '钻石之力™',
    UPG_diamondInferno: '钻石烈焰',
    UPG_electricAvenue: '电光大道',
    UPG_fortressCharge: '堡垒冲锋',
    UPG_immortalGlory: '不朽荣耀',
    UPG_ivoryCitadel: '象牙塔',
    UPG_ladyEarth: '大地女神',
    UPG_lockaLuck: '锁定好运',
    UPG_majesticDragons: '威武巨龙',
    UPG_mayanEagle: '玛雅之鹰',
    UPG_miamiGlow: '迈阿密光芒',
    UPG_miningFever: '挖矿狂热',
    UPG_nobleRats: '高贵老鼠',
    UPG_northStorm: '北方风暴',
    UPG_retroGalaxy: '复古星系',
    UPG_risingRoyals: '皇室崛起',
    UPG_romanPower: '罗马力量',
    UPG_seven7s: '七7™',
    UPG_spectreEstate: '鬼屋',
    UPG_stellarPortals: '星际传送门',
    UPG_stumpyMcDoodles: '矮胖麦克杜',
    UPG_theBounty: '赏金',
    UPG_theVault: '金库神偷',
    UPG_tikiMania: '疯狂提基',
    UPG_treasureHeroes: '宝藏英雄',
    UPG_treasureSkyland: '宝藏天空',
    UPG_wolfHowl: '狼嚎',
    UPG_wonderWoods: '惊奇森林'
  };

  // HC电子
  static HCGameCode = {
    SlotsCaiShen: '财神到',
    '777res': '水果777',
    QHSlotsRes: '拳皇',
    LgMovieSlots: '乐高大电影',
    'fishing-golden-toad': '金蟾捕鱼',
    CardSlots: '纸牌老虎机',
    SuperFruitSlots: '水果小玛丽',
    slotZodiac: '十二生肖',
    FuLinMen: '福临门',
    slotPanda: '功夫熊猫',
    SlotsFootball: '点球大战',
    'arcade-angrybirds': '愤怒的小鸟',
    WaterMargin: '水浒传',
    GoldRushMaster: '淘金者',
    'slots-cockfighting': '斗鸡',
    WLZBslots: '五龙争霸',
    ChessSlots2: '楚河汉界',
    NightShowSlots2: '夜戏貂蝉',
    Halloween: '万圣节',
    SlotsFunOrchard: '缤纷果园',
    SlotsGhostBride: '鬼新娘',
    SlotsYearsEve: '皇城年夜',
    SlotsSnowQueen: '冰雪女王',
    SlotsLuffyLegend2: '路飞传奇2',
    SlotsCounselor: '军师',
    SlotsSTPD: '夏日海滩',
    SlotsWuSongZhuan: '武松传2',
    SlotsQueenOfFire: '烈焰女王',
    SlotsHallOfValor: '英灵殿',
    SlotsChristmas2: '欢乐圣诞',
    SlotsWealthJungle: '财富丛林',
    SlotsShyLock: '夏洛克',
    SlotsTheCatSwordsman: '猫剑客',
    'slots-animal-forest': '动物森林',
    'slots-date-night': '约会之夜'
  };

  // code2name fG电子
  static FGGameCode = {
    2001: '变形金刚',
    2002: '摸金校尉',
    2003: '金刚',
    2004: '淘金热',
    2005: '66路',
    2006: '阿兹特克',
    2007: '埃及',
    2008: '狂欢',
    2009: '中世纪特权',
    2010: '大秦帝国',
    2251: '火影忍者',
    2252: '欢乐水果',
    2253: '街头霸王',
    2254: '战舰少女',
    2255: '东方国度',
    2256: '西部牛仔',
    2257: '幻想大陆',
    2258: '拉斯维加斯',
    2259: '荒野大镖客',
    2260: '埃及艳后',
    2501: '仙剑',
    2502: '梦游仙境',
    2503: '80天旅行',
    2504: '海盗财富',
    2505: '西游',
    2506: '白蛇传',
    2507: '指环王',
    2508: '封神榜',
    2509: '荣耀王者',
    2510: '怪物命运',
    2011: '埃菲尔',
    2012: '新年符号',
    2013: '性感女神',
    2014: '世界末日',
    2015: '现代战争',
    2016: '甜水绿洲',
    2017: '加勒比海盗',
    2018: '功夫熊猫',
    2019: '侏罗纪',
    2020: '植物大战僵尸',
    2021: '守望英雄',
    2022: '阿凡达',
    2261: '希腊传说',
    2262: '吸血鬼PK狼人',
    2263: '梦幻西游',
    2264: '女校足球队',
    2265: '女校橄榄球',
    2266: '女校剑道部',
    2267: '武圣关云长',
    2268: '刺客信条',
    2269: '古墓丽影',
    2270: '绿野仙踪',
    2271: '剑侠情缘',
    2272: '格斗之王',
    2511: '黑珍珠号',
    2512: '富饶农场',
    2513: '玛雅',
    2514: '印第安',
    2515: '神秘东方',
    2516: '战争',
    2517: '哪吒闹海',
    2518: '七夕情缘',
    2519: '四大美女',
    2520: '金瓶梅',
    2521: '红楼梦',
    2522: '武松打虎',
    3101: '女校游泳队',
    3102: '女校啦啦队',
    3103: '女校体操队',
    3201: '天龙八部',
    3202: '鹿鼎记',
    3203: '笑傲江湖',
    3204: '神雕侠侣',
    3301: '幸运水果机',
    3302: '钻石之恋',
    3303: '众神之王',
    3304: '粉红女郎',
    3305: '鸟叔',
    2023: '十二星座',
    2024: '十二生肖',
    2025: '愤怒的小鸟',
    2026: '捕鱼达人',
    2273: '瑞狗迎春',
    2274: '哥谭魅影猫女',
    2275: '招财进宝',
    2276: '湛蓝深海',
    2523: '金狗旺财',
    2525: '恭贺新春',
    2526: '角斗士',
    2527: '埃及女王',
    3306: '灌篮高手',
    3307: '抢红包',
    3308: '闹元宵',
    3309: '森林舞会',
    2277: '金球争霸',
    2278: '黄金右脚',
    2279: '世界杯吉祥物',
    2528: '潘帕斯雄鹰',
    2529: '群星闪耀',
    3310: '金靴争霸',
    3311: '激情球迷',
    3312: '激情世界杯',
    2280: '人猿泰山',
    3316: '船长宝藏',
    3313: '疯狂七',
    2532: '鹊桥会',
    3315: '泰坦尼克号',
    2282: '蜘蛛侠',
    2281: '速度与激情',
    2530: '古怪猴子',
    3314: '海岛奇兵',
    2531: '水果派对',
    3317: '百变猴子',
    2283: '新年',
    2284: '多福多财',
    3318: '街机水浒传',
    2534: '发发发',
    3319: '水浒英雄',
    3320: '金瓶梅2',
    3321: '抢红包2',
    // 补充街机类型
    7000: '水果机',
    7001: '飞禽走兽',
    7002: '奔驰宝马',
    7003: '森林舞会',
    7004: '十二星座',
    7005: '十二生肖',
    7006: '燃烧吧，足球',
    7007: '巅峰篮球',
    7008: '黄金大转轮',
    7009: '水果转盘',
    7010: '赛马',
    7011: '连环夺宝',
    7012: '幸运5',
    7014: '好运射击',
    7015: '猴子爬树',
    7016: '浮岛历险记',
    7017: '水果传奇',
    7018: '萌宠夺宝',
    7019: '王者足球',
    7021: '糖果派对',
    7023: '龙珠探宝',
    7026: '奔驰宝马2'
  };

  static AMEBAGameCode = {
    1: '猴寶寶',
    2: '猴寶寶2',
    3: '777',
    4: '爆炸糖',
    5: '發發發',
    6: '運財童子',
    7: '魔鑽',
    8: '爆炸糖2',
    9: '超級巨星',
    10: '搖錢樹',
    11: '聯盟',
    12: '幸運骰',
    13: '招財8',
    14: '大熊貓',
    15: '龍8',
    16: '龍珠',
    17: '聯盟 2',
    18: '銀河瘋爆',
    19: '一路發',
    20: '外星人',
    22: '海洋世界',
    23: '環遊世界',
    24: '麻將',
    25: '一本萬利',
    26: '魔法球',
    27: '船長的寶藏',
    28: '招財進寶',
    29: '五行',
    31: '豐財聚寶',
    32: '吉祥如意',
    36: '金獅賀歲',
    37: '古怪猴子',
    38: '魔鑽 2',
    39: '年年有餘',
    40: '糖果瘋爆',
    41: '冰上曲棍球',
    42: '古怪猴子5000倍',
    43: '如意棒',
    45: '功夫猴子',
    47: '高速公路之王',
    49: '泰國之美',
    106: '龍王',
    117: '招財神獸',
    132: '中國錦鯉'
  };

  static SimplePlayGameCode = {
    'EG-SLOT-A050': '御火圣兽',
    'EG-SLOT-A048': '糖果女王',
    'EG-SLOT-A041': '龙后魅影',
    'EG-SLOT-A046': '超能战姬',
    'EG-SLOT-A049': '千岁凤',
    'EG-SLOT-A047': '泰象',
    'EG-SLOT-A042': '蜀国英杰',
    'EG-SLOT-A045': '三剑客',
    'EG-SLOT-A044': '炸弹神偷',
    'EG-SLOT-A043': '奇幻神灯',
    'EG-SLOT-S008': '打擂台',
    'EG-SLOT-A040': '屠龙英雄',
    'EG-SLOT-A037': '埃及夺金',
    'EG-SLOT-A038': '斯巴达之尊',
    'EG-SLOT-C003': '鱼虾蟹',
    'EG-SLOT-C004': '大三元',
    'EG-SLOT-A030': '三星报囍2',
    'EG-SLOT-A039': '六脉传奇',
    'EG-SLOT-A036': '宙斯',
    'EG-SLOT-A035': '马雅秘宝',
    'EG-SLOT-A034': '幸运四叶草',
    'EG-SLOT-A033': '猪八戒',
    'EG-SLOT-A029': '财神到',
    'EG-SLOT-A032': '假面王子',
    'EG-SLOT-A027': '上海教父',
    'EG-SLOT-A028': '双囍',
    'EG-SLOT-A031': '世界杯狂热',
    'EG-SLOT-C002': '龙8',
    'EG-SLOT-A026': '运财童子',
    'EG-SLOT-A025': '鸿运金狮',
    'EG-SLOT-A024': '幸运发',
    'EG-SLOT-C001': '富贵7',
    'EG-SLOT-A021': '招财猫',
    'EG-SLOT-A022': '将圣',
    'EG-SLOT-A023': '摇钱树',
    'EG-SLOT-A008': '红楼春梦',
    'EG-SLOT-S006': '美女沙排',
    'EG-SLOT-S007': '丧尸猎人',
    'EG-SLOT-A018': '张保仔',
    'EG-SLOT-S005': '魔鬼天使',
    'EG-SLOT-A020': '运财金鸡',
    'EG-SLOT-A006': '济公',
    'EG-SLOT-A003': '锦衣卫',
    'EG-SLOT-A015': '脆爆水果',
    'EG-SLOT-A014': '幸运金钻',
    'EG-SLOT-A016': '热带宝藏',
    'EG-SLOT-A017': '南北狮王',
    'EG-SLOT-A011': '赤龙',
    'EG-SLOT-A010': '欢乐农场',
    'EG-SLOT-A009': '同校生',
    'EG-SLOT-A004': '龙虎',
    'EG-SLOT-A005': '梦幻女神',
    'EG-SLOT-A013': '比基尼狂热',
    'EG-SLOT-A012': '趣怪丧尸',
    'EG-SLOT-A002': '三星报囍',
    'EG-SLOT-A001': '过大年'
  };

  // FG捕鱼
  static FGBYGameNames = {
    708001: '美人捕鱼',
    708002: '雷霆战警',
    708003: '捕鸟达人',
    708004: '欢乐捕鱼',
    708005: '天天捕鱼',
    708006: '捕鱼来了3D',
    708007: '魔法王者',
    708008: '猎鼠达人3D',
    708009: '美人鱼传说',
    //
    5001: '美人捕鱼',
    5002: '雷霆战警',
    5003: '捕鸟达人',
    5004: '欢乐捕鱼',
    5005: '天天捕鱼',
    5006: '捕鱼来了3D',
    5007: '魔法王者',
    5008: '猎鼠达人3D',
    5009: '美人鱼传说'
  };
  // FG棋牌
  static FGPlayGames = {
    // gameid
    6003: '百人牛牛',
    6004: '皇家德州',
    6005: '骰宝',
    6006: '百家乐',
    6002: '皇家宫殿',
    6008: '红黑大战',
    6009: '德州牛仔',
    6007: '二人麻将',
    6501: '抢庄牛牛',
    6502: '三公',
    6301: '斗地主',
    6302: '经典炸金花',
    6303: '梭哈',
    6504: '通比牛牛',
    6011: '十三水',
    6012: '二八杠',
    6013: '抢庄牌九',
    6014: '极速炸金花',
    6666: '欢乐德州',
    6506: '欢乐红包',
    6667: '欢乐麻将',
    6010: '21点',
    6507: '五星宏辉',
    6508: '扫雷红包',
    6701: '抢庄牛牛3D',
    6702: '欢乐麻将3D',
    6703: '乐斗牛魔王3D',
    6015: '关秦龙虎斗',
    6016: '百人骰宝',
    6017: '看牌抢庄牛牛'
  };

  // KG棋牌 王者棋牌
  static KGPlayGames = {
    2: '通比牛牛',
    3: '抢庄牛牛',
    4: '经典牛牛',
    5: '炸金花',
    6: '二八杠',
    7: '三公',
    8: '经典21点',
    9: '德州扑克',
    10: '百家乐',
    11: '牌九',
    12: '捕鱼',
    13: '梭哈',
    14: '十三水',
    16: '阿三猜数',
    17: '百人牛牛',
    18: '极速炸金花',
    19: '龙虎斗',
    20: '抢庄炸金花',
    21: '明牌炸金花',
    22: '百人骰宝',
    23: '抢红包',
    24: '红黑大战',
    25: '疯狂牛牛',
    26: '抢庄21点',
    27: '奔驰宝马',
    29: '森林舞会',
    30: '幸运五张',
    31: '疯狂马戏团',
    32: '飞禽走兽',
    33: '大话骰',
    34: '一元夺宝',
    35: '百人二八杠',
    36: '百人扎金花',
    37: '斗地主',
    38: '二人麻将',
    39: '红中麻将',
    40: '血战到底',
    41: '跑得快',
    43: '捕鱼大亨',
    1001: '寳石世界',
    1002: '戰棋',
    1003: '招財福袋',
    1004: '鬼王送財',
    1005: '金玉滿堂',
    1006: '豆腐屋奈奈致命诱惑',
    1007: '明日花潮吹升天',
    1008: '三上悠亚海滩甜心',
    1009: '神之乳Rion',
    1010: '旬果的淫乱教室',
    1011: '火热777',
    1012: '海盗传奇',
    1013: '我爱黑色会',
    1014: '天使萌萌哒',
    1015: '优的女仆贴身服务',
    1016: '千菜浴池旁的疯狂抽插',
    1017: '被凌辱的失学少女',
    1018: '贵妇的外遇初体验',
    1019: '发发发',
    1020: '招财福娃',
    1021: '关云长',
    1022: '不为人知の绝伦女帝',
    1023: '绝品人妻的完熟BODY',
    1024: '清纯公主的秘密',
    1025: '特训♡体欲少女',
    1026: '糖果传奇',
    1027: '蹦迪',
    1028: '武則天',
    1029: '麻將胡胡胡',
    1030: '愛爾蘭精靈',
    1031: '梅杜莎',
    1032: '天神宙斯',
    1033: '草原之王',
    1034: '阿拉丁',
    1035: '財神到'
  };

  // EA板球
  static EABQGameCode = {
    1: '足球',
    2: '网球',
    4: '蟋蟀'
  };

  // IM体育
  static IMTYGameCode = {};
  static IMDJGameCode = {};

  // 开元体育
  static KYTYGameCode = {
    Football: '足球',
    Basketball: '篮球',
    'American Football': '美式足球',
    'Ice Hockey': '冰棍球',
    Badminton: '羽毛球',
    'Pool/Snooker': '撞球/斯诺克',
    'Motor Sport': '赛车',
    Tennis: '网球球',
    Baseball: '棒球',
    Volleyball: '排球',
    Others: '其他',
    Golf: '高尔夫',
    Boxing: '拳击',
    Cricket: '板球',
    'Table Tennis': '乒乓球',
    Rugby: '橄榄球',
    Handball: '手球',
    Cycling: '自行车',
    Athletics: '竞技',
    'Beach Soccer': '沙滩足球',
    Futsal: '室内足球',
    Special: '特别',
    Financial: '金融',
    Darts: '飞镖',
    Olympic: '奥运会',
    Lacrosse: '长曲棍球',
    'Water Polo': '水球',
    'Winter Sports': '冬季运动',
    Squash: '壁球',
    'Field Hockey': '草地曲棍球',
    'Mixed Martial Arts': '综合格斗',
    'E Sports': '电子竞技',
    'Gaelic Football': '盖尔足球',
    Hurling: '板棍球',
    'Muay Thai': '泰拳',
    'Aussie Rules Football': '澳式足球',
    Bandy: '班迪',
    Olympics: '冬奥会'
  };

  // FB体育
  static FBTYGameCode = {
    1: '足球',
    2: '冰球',
    3: '篮球',
    4: '橄榄球',
    5: '网球',
    6: '美国足球',
    7: '棒球',
    8: '手球',
    10: '地板球',
    12: '高尔夫球',
    13: '排球',
    14: '玩板球',
    15: '乒乓球',
    16: '斯诺克台球',
    17: '五人制足球',
    18: '综合格斗',
    19: '拳击',
    20: '飞镖',
    21: '草地滚球',
    24: '水球',
    25: '自行车',
    47: '羽毛球',
    51: '沙滩排球',
    92: 'F1赛车',
    93: '特殊投注',
    94: '赛车',
    100: '奥林匹克',
    164: '刀塔2',
    165: '英雄联盟',
    177: '电子足球',
    178: '电子篮球',
    180: '王者荣耀',
    179: '反恐精英',
    1001: '虚拟足球',
    1020: '虚拟赛马',
    1021: '虚拟赛狗',
    1022: '虚拟沙地摩托车',
    1023: '虚拟摩托车'
  };

  // BNG电子
  static BNGDZGameCode = {
    285: '埃及女神',
    280: '疯狂淘金乐-集鸿运',
    276: '小辣娇-集鸿运',
    274: '惊天大盗',
    270: '莲花奇缘-超百搭集鸿运',
    269: '快乐鸟嘉年华',
    266: '致命毒苹果2-集鸿运',
    265: '满贯金财神-集鸿运',
    262: '太阳神殿3 - 集鸿运',
    261: '快乐鱼',
    256: '烈日女神-集鸿运',
    252: '金禧迎狮-集鸿运',
    250: '轰炸糖果',
    249: '黄金特快车-集鸿运',
    245: '皇朝盛世2-集鸿运',
    242: '丛林之王-集鸿运',
    240: '招财金象神-集鸿运',
    237: '月夜狼嚎-集鸿运',
    236: '圣甲虫大三元-集鸿运',
    233: '悟空传-集鸿运',
    231: '圣甲降临-集鸿运',
    228: '淘金乐-集鸿运',
    223: '魔幻宝珠-多胜彩',
    219: '致命毒苹果-集鸿运',
    217: '好运金财神-集鸿运',
    212: '大三元-集鸿运',
    204: '荷鲁斯之眼',
    209: '金钻霸虎-集鸿运',
    202: '太阳神殿2-集鸿运',
    201: '圣甲虫王朝-集鸿运',
    200: '诸神之战 - 集鸿运',
    197: '钱龙-集鸿运',
    190: '运财弥勒-集鸿运',
    189: '水果连环碰-集鸿运',
    187: '阿兹特克王朝-集鸿运',
    183: '月夜双娇-集鸿运',
    184: '法老宝典-主宰',
    181: '福胖达-集鸿运',
    178: '福虎生风-集鸿运',
    175: '超级7转运',
    173: '太阳神殿-集鸿运',
    168: '钱滚滚圣甲虫',
    166: '诸神荣耀',
    157: '法老宝典: 多胜彩',
    151: '15龙珠-集鸿运',
    148: '超级7',
    139: '法老宝典',
    86: '上帝的殿堂豪华版',
    14: '快乐鸟',
    283: '里约嘉年华',
    278: '猪宝大盗',
    272: '烈焰阿兹特克-集鸿运',
    267: '王者英雄',
    259: '采珠潜水员2: 藏宝箱',
    255: '巫师之书:水晶胜彩',
    254: '黑狼-集鸿运',
    244: '巫师之书-双重机会',
    232: '采珠潜水员',
    216: '狼佐贺-集鸿运',
    284: '抢银行: 集鸿运',
    282: '火龙秘宝: 幸运夺宝',
    281: '偷金鲨客',
    279: '皇家钱滚滚2: 集鸿运',
    277: '钻石宝库: 集鸿运',
    275: '闪耀红宝石: 集鸿运',
    273: '卢克索神庙: 集鸿运',
    271: '果汁梦工厂',
    268: '太阳圣典: 多倍彩',
    264: '狮王秘宝: 集鸿运',
    263: '火焰大赢家',
    257: '小丑金币: 集鸿运',
    251: '钻石舞台: 集鸿运',
    248: '蛮牛向钱冲节日版',
    246: '呛辣水果盘x2',
    241: '皇家钱滚滚: 集鸿运',
    238: '埃及之魂: 集鸿运',
    235: '天空霸主: 集鸿运',
    234: '皇家宝库',
    239: '极致呛辣水果盘',
    230: '9是法老',
    227: '疯富钻石: 集鸿运',
    225: '黄金卷轴: 双福彩',
    220: '黄金麦达斯',
    222: '双星报喜: 双福彩',
    221: '钱滚滚水果盘: 集鸿运',
    224: '水果幸运星: 双福彩',
    218: '狼魂: 集鸿运',
    215: '龙王宝珠: 集鸿运',
    214: '超级水果大亨: 6轴',
    208: '超级水果大亨',
    206: '超级夏日果情:集鸿运',
    205: '天王',
    199: '黄金卷轴: 多胜彩',
    198: '钻石俱乐部: 集鸿运',
    192: '黄金古埃及豪华版',
    191: '蛮牛向钱冲: 集鸿运',
    188: '好运金皇冠',
    186: '珍珠美人: 集鸿运',
    185: '经典水果大亨: 6轴 ',
    182: '玛雅盛典',
    180: '夏日果情: 集鸿运',
    176: '王者凯萨',
    172: '呛辣水果盘: 火力全开',
    171: '黄金卷轴: 主宰',
    169: '绿装小矮妖',
    167: '国王水果盘: 100条赔付线',
    164: '天后',
    162: '国王水果盘: 40条赔付线',
    160: '嗨爆777 - 火力全开: 5条赔付线',
    159: '黄金卷轴: 起源',
    158: '推啊77: 10条赔付线',
    156: '狂野小丑: 100条赔付线',
    153: '嗨爆777: 27种方式',
    152: '维京宝藏: 集鸿运',
    150: '国王水果盘: 5条赔付线',
    149: '经典水果大亨: 20条赔付线',
    147: '幸运四叶草: 20条赔付线',
    146: '狂野王国: 4096种方式',
    144: '小丑',
    138: '强力女战士',
    136: '狂野小丑: 40条赔付线',
    133: '黄金卷轴: 双胜彩',
    132: '王牌水果盘: 40条赔付线',
    129: '水果嘉年华: 经典5线',
    130: '狂野小丑',
    100: '黄金古埃及',
    111: '王牌水果盘: 5条赔付线',
    102: '呛辣水果盘',
    96: '40小丑',
    117: '经典水果大亨',
    69: '女王的传奇',
    8: '水晶之国'
  };

  // YGG电子
  static YGGDZGameCode = {
    10085: '猛虎聚',
    10216: '亡灵的手指',
    7310: '地狱小丑',
    7315: '萌萌外星人',
    10148: '狂野北欧海盗',
    7317: '福尔摩斯和被盗石头',
    10168: '齐柏林赌场',
    7320: '激情燃烧',
    10103: '冬季浆果',
    10169: '金鱼缸',
    7301: '游乐园小丑',
    7323: '丰收季',
    7321: '邪恶马戏团',
    7325: '传奇美猴王',
    7326: '足球决赛',
    7328: '大布洛思',
    7329: '双龙',
    7319: '涅盘',
    10147: '暴怒北欧海盗',
    7332: '炼金师',
    7333: '美女与野兽',
    10131: '阳光海滩',
    10055: '水果朗姆酒',
    10073: '乐手莱恩',
    10094: '山谷之神',
    7337: '丛林冒险',
    10134: '轰炸南瓜',
    8301: '东方快车',
    7345: '奥兹温超级大奖池',
    10133: '宝石矿山',
    10072: '复活岛',
    7348: '摔角高手',
    7304: '速冻水果',
    10101: '去死吧维京人',
    7349: '图坦卡蒙破坏者',
    7350: '企鹅城市',
    10135: '汉佐的道场',
    7352: '狼猎人',
    7353: '黑暗漩涡',
    7354: '安息日男爵',
    8302: '巨魔之桥',
    10142: '宇宙奇幻赌场',
    10158: '罗马冠军',
    10132: '矮人矿工',
    7360: '嘉年华大师',
    7361: '夺宝奇兵',
    7366: '机器人冒险工厂',
    10143: '独臂强盗',
    7370: '相扑争霸',
    10157: '撒哈拉之夜',
    22001: '黄金轮盘',
    7365: '阿萨神族',
    1003: '雪怪人',
    7372: '阿尔多游记',
    7374: '黄金寺庙',
    7356: '时空老虎旅人',
    7375: '巴西狂欢节',
    8303: '皇室大家庭',
    8304: '海盗-精灵岛',
    10122: '飞向奇迹',
    7362: '威廉英雄传说',
    7381: '霓虹冲锋',
    10161: '噬血之月',
    10160: '阿瑟的宝藏',
    7388: '电光小丑女',
    8308: '阿凡达特攻队',
    10124: '招财幸运猫',
    7387: '财宝金库',
    7386: '足球传奇',
    7373: '奖池快车',
    10114: '山谷之神2',
    1017: '摇滚现金',
    10144: '超级爆发',
    10125: '黑帝斯',
    1018: '茉莉莫拉',
    10159: '暗阎石',
    10130: '小精灵之歌',
    8309: '海盗2:叛乱',
    7398: '冰风暴女王奖池',
    1022: '吉萨无限卷轴',
    1020: '亚特兰蒂斯之谜',
    1025: '荒野强盗团',
    10138: '愿望卷轴',
    1026: '黄金国无限卷轴',
    1028: '重锤游戏场',
    1036: '西部恶犬',
    7396: '复活岛2',
    1032: '约翰传奇',
    1035: '雷神无限卷轴',
    1027: '远古日蚀',
    7397: '亚特兰蒂斯古城',
    1038: '罗宾大盗',
    1043: '龙王无限卷轴',
    1033: '奥丁无限卷轴',
    1040: '釜之谜',
    10011: '捷豹印记',
    7401: '掏金鳄',
    10022: '炙热军团1',
    7406: '追日者',
    10016: '石像鬼无限卷轴',
    10025: '12个特洛伊之谜',
    10053: '疯狂攀登者',
    10035: '救世主罗宾',
    10010: '蒂基无限卷轴',
    10021: '黄金蛇发女妖',
    10046: '火星矿工无限卷轴',
    10064: '水晶瀑布',
    1042: '疯狂小丑',
    10082: '奖金猎人',
    7408: '猛禽兽啸',
    1054: '火速补给',
    10080: '急速倒转',
    10173: '暴怒北欧海盗-重装版',
    10090: '灵神锤',
    10123: '掏金矿坑无限卷轴',
    10086: '美杜莎：死亡之穴 ',
    10172: '猎月传说',
    10182: '魔幻宝石无限卷轴',
    7409: '金鱼缸2',
    10180: '热带天堂大冒险',
    10171: '骇星齐爆',
    10137: '荒原狮鸣',
    10054: '雪怪人族',
    10174: '幻惑奇缘',
    10140: '五宗族',
    10071: '巫妖魔灵',
    10170: '奢迷掏金鳄',
    10194: '轰鸣雷神',
    10181: '妖之气息',
    10208: '猛兽王朝无限卷轴',
    10199: '甜心撞击',
    10211: '重金流浪乐队无限卷轴',
    10188: '齐柏林赌场-重装版',
    10052: '冥界通道',
    10074: '英灵神殿',
    10097: '天丛云神剑',
    10069: '超时空装置',
    10214: '超级现金掉落',
    10030: '好胜猫',
    10225: '蒂基狂奔2',
    10223: '分秒浩劫',
    10231: '狂野决斗',
    10096: '香蕉齐爆',
    7400: '北欧海盗英雄之战',
    10233: '阿基里斯的愤怒',
    10228: '女武圣的甦醒',
    10232: '地狱怪客猪',
    10243: '巨型珍珠',
    10239: '猛爆水花',
    10255: '女巫的大锅',
    10227: '狂野小丑',
    10234: '钞票大富翁',
    10235: '4快乐钓鱼',
    10250: '波斯之眼 2',
    10247: '海盗齐爆',
    10229: '铸铁狂热',
    10267: '狂野水牛城',
    10268: '荒野城镇',
    10288: '现金成堆',
    10252: '克兰克博士的重组机',
    10260: '古文明极限探险',
    10262: '都柏林幸运仙境',
    10256: '怪兽大对决',
    10285: '火焰圣书',
    10215: '带财萌宠',
    10280: '屠兽圣战',
    10302: '钱进游乐园',
    10284: '农家掏金乐',
    10309: '巫毒诅咒',
    10292: '亡灵宝藏',
    10298: '狂野神钓手',
    10308: '冥界之王',
    10303: '寒冰巫师',
    10310: '魔法小丑',
    10290: '末日食人花',
    10327: '热血足球',
    10111: '圣女武神',
    10305: '幸运七金',
    10304: '西部恶犬2',
    10326: '地牢魔兽塔',
    10283: '水果宝石大亨',
    10365: '死亡炮击',
    10366: '狂欢圣诞夜',
    10324: '众神的战争',
    10375: '小丑的彩虹金盆',
    10377: '武藏传说'
  };

  // JILI棋牌
  static JILIQPGameCode = {
    111: '數字之王',
    112: '西遊爭霸M',
    113: '撲克之王',
    123: '龍虎鬥',
    122: 'iRich Bingo',
    124: '7上7下',
    72: 'TeenPatti',
    75: 'AK47',
    79: 'Andar Bahar',
    94: 'Rummy',
    127: 'Callbreak',
    159: 'TeenPatti Joker'
  };

  // 大唐棋牌
  static DTQPGameCode = {
    720: '百人二八杠',
    830: '抢庄牛牛',
    210: '红黑大战',
    220: '炸金花',
    860: '三公',
    900: '百人龙虎',
    600: '21点',
    930: '百人牛牛',
    730: '抢庄牌九',
    630: '十三水',
    870: '通比牛牛',
    400: '至尊五张',
    890: '咪牌抢庄牛牛',
    240: '抢庄炸金花',
    820: '抢庄选三张(三公)',
    300: '抢庄选三张(炸金花)',
    880: '百变抢庄牛牛',
    200: '红黑斗牛',
    610: '斗地主',
    270: '抢红包',
    910: '百家乐'
  };

  // 大唐捕鱼
  static DTBYGameCode = {
    500: '金蝉捕鱼',
    510: '李逵劈鱼',
    520: '人鱼传说',
    530: '大闹天宫'
  };

  // JILI电子
  static JILIDZGameCode = {
    10: '寶石派對',
    5: '火熱辣椒',
    2: '秦皇傳說',
    9: '舞龍爭霸',
    6: '發財樹',
    17: '上海甜心',
    21: '極速發發發',
    4: '關雲長',
    27: '極速777',
    35: '瘋狂777',
    30: '人魚甜心',
    36: '包青天',
    40: '瘋狂一路發',
    43: '喜洋洋',
    33: '極速豬來了',
    23: '糖果寶貝',
    38: '封神',
    45: '瘋狂搶金樂',
    48: '霸金磚',
    46: '神龍奪寶',
    47: '衝鋒野牛',
    49: '超級王牌',
    58: '黃金艷后',
    51: '瘋狂錢來也',
    16: '叢林之王',
    77: '拳王',
    78: '秘境奪寶',
    85: '法老祕寶',
    91: '象財神',
    100: '真有錢',
    102: '羅馬X',
    103: '黃金帝國',
    109: '迦羅寶石',
    92: '瘋狂砲手',
    76: '嗨翻夜',
    108: '阿拉丁',
    115: '王牌特務',
    106: 'TWINWI',
    110: '阿里巴巴',
    134: '無限王牌',
    89: '幸運女神',
    101: '梅杜莎',
    87: '黃金之書',
    61: '龍虎競猜',
    62: '骰子競猜',
    63: '七上下競猜',
    66: '數字競猜'
  };

  // PS电子
  static PSDZGameCode = {
    'PSS-ON-00001': '武媚娘',
    'PSS-ON-00002': '罗马帝国',
    'PSS-ON-00003': '五虎',
    'PSS-ON-00004': '海盗宝藏',
    'PSS-ON-00005': '印度之宝',
    'PSS-ON-00006': '古埃及',
    'PSS-ON-00007': '海豚',
    'PSS-ON-00009': '凯洛琳夫人',
    'PSS-ON-00012': '疯猴子岛',
    'PSS-ON-00013': '宝盒',
    'PSS-ON-00014': '幸运熊猫',
    'PSS-ON-00015': '牛逼特工',
    'PSS-ON-00016': '恭喜发财',
    'PSS-ON-00017': '猴子',
    'PSS-ON-00019': '天子',
    'PSS-ON-00021': '机械莎拉',
    'PSS-ON-00022': '水果趴',
    'PSS-ON-00024': '发发猴',
    'PSS-ON-00025': '777',
    'PSS-ON-00026': '双囍',
    'PSS-ON-00028': '雅典娜',
    'PSS-ON-00029': '荣耀王国',
    'PSS-ON-00031': '亚特兰提斯',
    'PSS-ON-00032': '777火焰',
    'PSS-ON-00033': '大富贵',
    'PSS-ON-00034': '夏日派对',
    'PSS-ON-00035': '狼人',
    'PSS-ON-00036': '亚历山大灯塔',
    'PSS-ON-00037': '招财进宝',
    'PSS-ON-00038': '变脸',
    'PSS-ON-00039': '可爱狗狗',
    'PSS-ON-00040': '万兽之王',
    'PSS-ON-00042': '巴西嘉年华',
    'PSS-ON-00044': '金鸡报喜',
    'PSS-ON-00047': '象王',
    'PSS-ON-00050': '威尼斯',
    'PSS-ON-00051': '万圣节',
    'PSS-ON-00054': '花开富贵',
    'PSS-ON-00058': '火焰皇后',
    'PSS-ON-00059': '吸血鬼',
    'PSS-ON-00065': '超级有钱',
    'PSS-ON-00067': '端午节',
    'PSS-ON-00068': '飞龙在天',
    'PSS-ON-00069': '殭尸末日',
    'PSS-ON-00070': '炼丹术',
    'PSS-ON-00073': '花好月圆',
    'PSS-ON-00074': '七大奇观',
    'PSS-ON-00075': '游乐园',
    'PSS-ON-00077': '月阳之神',
    'PSS-ON-00078': '祝融共工',
    'PSS-ON-00079': '招财喵喵',
    'PSS-ON-00083': '皇上吉祥',
    'PSS-ON-00084': '狗来富',
    'PSS-ON-00085': '极地狼嚎',
    'PSS-ON-00086': '圣诞节',
    'PSS-ON-00087': 'PS双星・福多多',
    'PSS-ON-00088': '音乐家',
    'PSS-ON-00089': 'PS双星・财多多',
    'PSS-ON-00090': '宝石之城',
    'PSS-ON-00091': '超级有势',
    'PSS-ON-00092': '古墓丽影',
    'PSS-ON-00093': '甜蜜蜜',
    'PSS-ON-00094': 'PS不夜城',
    'PSS-ON-00095': '麻将大侠',
    'PSS-ON-00096': '牛转乾坤',
    'PSS-ON-00097': '黄金右脚',
    'PSS-ON-00099': '大挑战・玛雅历',
    'PSS-ON-00100': '祖玛传奇',
    'PSS-ON-00101': '大挑战・福禄寿禧',
    'PSS-ON-00102': '黄金周',
    'PSS-ON-00103': '幸运女郎',
    'PSS-ON-00104': '功夫',
    'PSS-ON-00105': '大挑战・喜从猪来',
    'PSS-ON-00106': '水墨武侠',
    'PSS-ON-00107': '超級炸',
    'PSS-ON-00108': '金鼠迎财神',
    'PSS-ON-00110': '二哈当家',
    'PSS-ON-00111': '888大富贵',
    'PSS-ON-00112': '超级赢',
    'PSS-ON-00114': '鱼虾蟹',
    'PSS-ON-00115': '麻将发发发',
    'PSS-ON-00116': '舞娘俱乐部2',
    'PSS-ON-00117': '孔雀王2',
    'PSS-ON-00118': '烈火英雄2',
    'PSS-ON-00119': '幸运扑克2',
    'PSS-ON-00121': '墨西哥风采2',
    'PSS-ON-00123': '百发百粽',
    'PSS-ON-00124': '貂蝉发大财',
    'PSS-ON-00125': '吕布发大财',
    'PSS-ON-00127': '超级金彩',
    'PSS-ON-00128': '巨龙宝藏',
    'PSS-ON-00129': '金玉满堂',
    'PSS-ON-00130': '速度与激情',
    'PSS-ON-00131': '魔幻东南亚',
    'PSS-ON-00132': '疯狂777',
    'PSS-ON-00133': '金币达人',
    'PSS-ON-00135': '买特色・喜从猪来',
    'PSS-ON-00136': '买特色・圣诞特快车',
    'PSS-ON-00137': '买特色・超级有势'
  };

  // RT电子
  static RTDZGameCode = {
    '10001nights00000': '10001夜',
    '1942skywarrior00': '1942：天空战士',
    '24hourgrandprix0': '24小时大奖',
    '4squad0000000000': '4人小队',
    '5families0000000': '5人家族',
    '777strike0000000': '777连胜',
    ageofakkadia0000: '阿卡德时代',
    agentroyale00000: '皇家特工',
    ancientscript000: '寻找法老',
    ancientsblessing: '上古神兽的祝福',
    arcadebomb000000: '水果大爆炸',
    atlantis00000000: '亚特兰蒂斯',
    aurumcodex000000: '黄金圣典',
    aztecspins000000: '阿兹特克旋转',
    beriched00000000: '魔法财富',
    bettyborisandboo: '贝蒂、鲍里斯和啵啵',
    blazingclusters0: '炽热群集',
    bluediamond00000: '蓝钻风暴',
    bombuster0000000: '缤纷炸弹',
    bountyraid000000: '赏金突击',
    cashornothing000: '现金或全无',
    cashultimate0000: '现金大放送',
    cashvolt00000000: '现金伏特',
    chinesetreasures: '华夏祥瑞',
    cinderella000000: '灰姑娘',
    cirquedelafortun: '财富马戏团',
    clashofthebeasts: '野兽冲突',
    classicblackjack: '二十一点',
    crazygenie000000: '淘气的神灯',
    crystalmirror000: '水晶镜子',
    davincismystery0: '达芬奇谜团',
    devilsnumber0000: '恶魔密码',
    diamondblitz0000: '钻石突袭',
    dicedicedice0000: '魔幻骰子',
    divineways000000: '圣兽传奇',
    doggyrichesmegaw: '贵族狗狗巨额赢利线™',
    draculaawakening: '德古拉觉醒',
    dragonkinglegend: '海底龙王传说',
    dragonsfire00000: '神龙火焰',
    dragonsfireinfin: '神龙火焰无限卷轴',
    dragonsfiremegaw: '神龙火焰超级赢利线™',
    dragonsluck00000: '龙之谕超级赢利线™',
    dragonslckdeluxe: '豪华龙运',
    dragonsluckmegaw: '龙之谕超级赢利线™',
    dragonslckstacks: '龙之谕Ⅱ',
    dynamiteriches00: '财富大爆炸',
    dynamiterichesme: '财富大爆炸超级赢利线',
    eagleriches00000: '老鹰财富',
    elvenmagic000000: '魔箭精灵',
    emeralddiamond00: '绿钻风暴',
    epicjourney00000: '西游寻宝',
    esqueletomariach: '狂欢亡灵节',
    europeanroulette: '欧式轮盘赌',
    fafababies000000: '吉祥娃娃',
    fivestar00000000: '幸运五星',
    fivestarpowerree: '幸运五星II',
    flamingfox000000: '火狐传说',
    forever7s0000000: '7星高照',
    fortunecharm0000: '幸运宝箱',
    fortunefest00000: '恭贺新禧',
    fortunehouse0000: '财富满屋',
    fortunehousepowe: '财富满屋力量卷轴',
    fruitblox0000000: '水果宝藏',
    fruitsnap0000000: '水果皇族',
    gemsgonewild0000: '宝石暴走',
    gemsgonewildpowe: '宝石百搭力量转轴',
    gemtastic0000000: '宝石记',
    genienights00000: '精灵之夜',
    getthegoldinfini: '淘金无限卷轴',
    godofwealth00000: '财神',
    goldstar00000000: '摇滚星',
    goldencryptex000: '金色密码',
    goldenleprechaun: '金色小精灵超级赢利线™',
    goldenlotus00000: '金莲呈祥',
    goldenoffer00000: '麒麟送宝',
    templeofgold0000: '金殿丽影',
    goldentsar000000: '金色沙皇',
    gonzosqumegaways: '贡萨的任务™超级赢利线™',
    grandwheel000000: '幸运大转盘',
    hammergods000000: '诸神之锤',
    harlecoin0000000: '滑稽金币',
    hoardofposeidon0: '海神宝藏',
    hothotchillipot0: '麻辣火锅',
    hustling00000000: '喧嚣对战',
    imperialpalace00: '甄妃传奇',
    jackinapot000000: '杰克的聚宝盆',
    jackpotquest0000: '黄金猎人',
    jadecharms000000: '珠光宝器',
    jesterspins00000: '欢乐小丑',
    jewelscarabs0000: '埃及圣甲虫',
    jinglebells00000: '铃儿响叮当',
    jinglebellspower: '铃儿响叮当无敌卷轴',
    jinglewaysmegawa: 'JingleWaysMegaway',
    joeexotic0000000: '虎王：怪人乔',
    laserfruit000000: '镭射水果',
    lastchancesaloon: '最后一击',
    legendofathena00: '雅典娜传说',
    legendaryexcalib: '王者之剑',
    liondance0000000: '雄狮纳财',
    lionshoard000000: '巨狮宝藏',
    luckyeaster00000: '兔子的彩蛋',
    luckyfortunecat0: '招财猫',
    luckyfridays0000: '幸运星期五',
    luckyhalloween00: '幸运万圣节',
    luckydevil000000: '幸运恶魔',
    luckyoktoberfest: '狂欢啤酒节',
    luckyvalentine00: '甜蜜情人节',
    luckywizard00000: '幸运魔法师',
    magicgate0000000: '五福临门',
    masquerade000000: '假面舞会',
    mayangods0000000: '玛雅时代',
    megadragon000000: '巨龙传说',
    megajade00000000: '翡翠王',
    megapyramid00000: '金字塔风云',
    megarise00000000: '超級大贏家',
    midasgold0000000: '点石成金',
    multiplierriches: '翻倍器财富',
    mysteryfruit0000: '神秘水果',
    mysteryreelsmega: '神秘卷轴超级赢利线™',
    mysteryreelspowe: '神秘水果II',
    mysticstaxx00000: '神秘堆叠',
    mysticwheel00000: '轮盘秘语',
    narcosmexico0000: 'NarcosMexico',
    neonlinks0000000: '霓虹连接',
    nftmegaways00000: 'NFTMegaways',
    nightroller00000: '深夜骰子',
    ninjaways0000000: '忍者传奇',
    oceanfortune0000: '深海宝藏',
    pathofdestiny000: '命运之路',
    persianfortune00: '波斯宝藏',
    redphoenixrising: '凤凰涅槃',
    phoenixfwerreels: '凤凰涅槃Ⅱ',
    piggypirates0000: '猪猪海盗',
    piggyrichesmegaw: '富豪猪猪™超级赢利线™',
    piratesplenty000: '幽灵海盗',
    piratespeforgold: '海盗之战',
    piratesplentymeg: '幽灵海盗超级赢利线',
    primateking00000: '百兽之王',
    puntobanco000000: '百家乐',
    pussnboots000000: '靴猫剑客',
    raslegend0000000: '拉神的恩赐',
    rainbowjackpots0: '彩虹的祝福',
    rainbowjackpotsp: '彩虹的祝福II',
    reactor000000000: '财富引擎',
    reddiamond000000: '红钻风暴',
    reelheist0000000: '追击劫匪',
    reelkeeper000000: '转轴守护者',
    reelkingmega0000: '超级卷轴之王',
    regalbeasts00000: '皇家野兽',
    regalstreak00000: '皇家连胜',
    reptizillionspow: '爬行动物大集合力量卷轴',
    riddleofthesphin: '狮身人面像之谜',
    riostars00000000: '里约之星',
    robinhoodswildfo: '罗宾汉的野外森林',
    rocketmen0000000: '火箭对决',
    royalgems0000000: '皇家珍宝',
    shahmat000000000: '击杀',
    snowwildandthe7f: '白雪派对',
    spintown00000000: '疯狂小镇',
    stage88800000000: '梦想好声音',
    sumospins0000000: '相扑大赏',
    sylvanspirits000: '森林之神',
    tenelements00000: '妖神传说',
    theequalizer0000: '劲爆迪斯科',
    thegreatesttrain: '列车神偷',
    thewildhatter000: '疯帽子',
    thewisecrackerli: '小丑闪电',
    thorslightning00: '雷电之子',
    thorsvengeance00: '索尔的复仇',
    threekingdoms000: '三国争霸',
    threemusketeers0: '三剑客',
    tikifruits000000: '水果消消乐',
    tikifruitstotemf: '水果消消乐之疯狂图腾',
    totemlightning00: '图腾的秘密',
    totemlightningpo: '图腾的秘密II',
    treasuremine0000: '矿洞奇遇',
    treasureminepowe: '宝藏大发现力量卷轴',
    trillionaire0000: '万亿富豪',
    vaultcracker0000: '金库怪客',
    vaultofanubis000: '阿努比斯金库',
    vickyventura0000: '维琪冒险记',
    viralspiral00000: '水果螺旋病毒',
    warofgods0000000: '诸神之战',
    wellofwildsmegaw: '百搭之井超级赢利线',
    wellofwishes0000: '愿望之井',
    whatthefoxmegawa: '什么是狐狸超级赢利线',
    wildcatsmultilin: '王者集结',
    wildcircus000000: '疯狂马戏团',
    wildelements0000: '自然之神',
    wildexpedition00: '狂野探险',
    wildfight0000000: '功夫神话',
    wildhotchilliree: '百搭红辣椒卷轴',
    wildnords0000000: '诺德英雄传',
    wildoclock000000: '百搭时钟',
    wildspartans0000: '斯巴达勇士',
    wildtundra000000: '狂野冻原',
    wildwildchest000: '狂野西部诱惑',
    winescalator0000: '奖金速递',
    wingsofra0000000: '太阳神之翼',
    winterwonders000: '精灵童话',
    yucatansmystery0: '尤卡坦之謎',
    zeuslightningpow: '宙斯闪电力量转轴',
    zillardking00000: '齐拉德之王'
  };

  // VT电子
  static VTDZGameCode = {
    TripleMonkey: '三倍猴子',
    DuoFuDuoCai88Fortune: '大福大財-財富發發',
    DuoFuDuoCai5Treasures: '大福大財-黃金選擇',
    DuoFuDuoCaiDiamondEternity: '大福大財-鑽石永恆',
    DuoFuDuoCaiDancingDrum: '大福大財-擊鼓迎福',
    FortuneKing: '財神到',
    JuFuGoldenTale: '聚福納財-黃金傳奇',
    JurassicWorld: '侏羅紀公園',
    JuFuFountainOfWealth: '聚福納財-財源滾滾',
    BuffaloBlitz: '美洲野牛',
    Circus: '驚奇馬戲團',
    DuoFuDuoCaiFlowerOfRiches: '大福大財-盛世牡丹',
    WuLuCaiShen: '五路財神',
    JuFuRhythmOfFortune: '聚福納財-鑼鼓生財',
    TrueBreakAway: '擺脫8',
    IceWind: '維京冰風暴',
    WaterMargin: '水滸',
    WuFuTongZi: '飛龍在天',
    FunkyMonkey: '古怪猴子',
    ZhaoCaiTongZi: '招財童子',
    Triple7: '777',
    PurePlatinum: '白金',
    FiftyLions: '50 雄獅',
    LuckyTwins: '幸運雙子',
    Halloween: '萬聖節',
    GreatBlue: '湛藍深海',
    Horizon: '期待黎明',
    FaFaFa: '發發發',
    FiveDragons: '五龍',
    LionDance2: '舞獅爭霸',
    JuFuEternalDiamond: '聚福納財-永恒寶鑽',
    TaiWangSiShen: '太王四神記',
    JuFuBloomingRiches: '聚福納財-花開富貴',
    LongLongLong: '龍龍龍',
    FiveFlowers: '五花爭艷',
    TrueZhaoCaiJinBao: '真.招財進寶',
    Aladin: '阿拉丁',
    MapleStory: '冒險島',
    Tarzan: '泰山',
    Slot2: '大吉大利',
    MysticNine: '九門點天燈',
    ZhaoCaiJinBao: '招財進寶',
    BreakAway: '極速賽車',
    MagicCandy: '魔法糖果',
    EgyptCity: '埃及迷城',
    Arcadia: '電音大蜘蛛',
    FiveChildTeasingMaitreya: '五子戲彌勒',
    DiamondSlot: '鑽石角子',
    SevenLuckyGod: '七福神',
    DJRemix: '慢搖',
    LostCityOfGold: '勇闖黃金城',
    Geisha: '艺妓',
    FoxSpirit: '狐仙',
    Odin: '奧丁之怒',
    Chaoji8: '超级8',
    Ninja: '侍忍',
    Mojin2: '摸金校尉',
    SunQuest: '探索太阳',
    VT: '波利之城',
    MightMagic: '魔法门',
    Pirates: '加勒比海盗',
    LineBrownDaydream: '爱做梦的熊大',
    MarvelTsum: '复仇者 Tsum Tsum',
    SpecialChef: '中华小当家',
    WarHammer40K: '战锤40K',
    FishParty: '海洋派对',
    MarvelClassic: '无限战争',
    Tropics: '热带海洋',
    AliceAdv: '爱丽丝',
    Rich: '大富翁',
    HungryHungryShark: '饥饿的鲨鱼',
    SlotJumanji: '野蛮丛林',
    KingsOfCash: '变装皇后的珠宝',
    FallingSakura: '落樱散华抄',
    ArtificialGirl: '人工少女',
    TaikoDrumMaster: '太鼓达人',
    PixelWar: '光明与深渊',
    AzurLane: '战舰少女',
    MoneyFarm: '金币农场',
    SixLions: '六狮王朝',
    PokerCity: '死党的游戏',
    BarBarSheep: '秋秋羊',
    Military: '军事风云',
    AsTheGodsWill: '要听神明的话',
    TravelFrog: '旅行青蛙',
    Onmologist: '阴阳师',
    ZombieHunter: '僵尸猎人',
    CrossyRoad: '天天过马路',
    BeanStalk: '杰克与魔豆',
    FishingExpert: '捕鱼达人'
  };

  // DB电子
  static DBDZGameCode = {
    2033: "热血羽毛球",
    5496: "野牛圆月",
    2082: "奇妙马戏团",
    2072: "瓦尔哈拉的统治者",
    2068: "奥兹Oz传奇",
    5497: "冰雪猛虎",
    2083: "雷霆万钧之百搭闪电",
    2085: "财宝宫殿",
    2053: "地下城与钻石",
    2038: "闪电XUP",
    2034: "篮球巨星火力全开",
    5565: "趣味蜜蜂™",
    5557: "全能宙斯百搭",
    2087: "西域黄金",
    2015: "25000鹰爪",
    2027: "古代财富 Poseidon Megaways™",
    2045: "猫咪部落",
    2025: "神奇连环宙斯",
    2048: "板球明星",
    2086: "歹徒通缉令",
    2059: "魔龙宝珠",
    2046: "奥林匹斯編年史XUP",
    2018: "777电光火石",
    5503: "辣椒堆叠乐",
    5501: "罗马：黄金之战",
    2026: "神奇连环阿波罗",
    2050: "神圣财富-赫利俄斯",
    2031: "阿瓦隆",
    5498: "欢乐糖果百搭",
    2057: "金罐钓钓乐",
    2023: "非洲XUP",
    2051: "神龙碎片",
    5502: "财富派克黄金",
    5564: "亚马孙失落的黄金",
    2067: "科迪亚克王国",
    2071: "阿蒙的面具",
    2056: "深海派对",
    2036: "财富大爆炸",
    2016: "4钻石蓝调 MEGAWAYS",
    5561: "甜蜜糖果热潮",
    2064: "Jurassic Park:黄金",
    5563: "奥林匹斯山之主",
    2055: "烈火锻金",
    2077: "三叶草福尔摩斯 Megaways™",
    2022: "奇遇宫殿",
    2017: "5星淘汰",
    2075: "宝石转轴豪华版",
    2058: "财富之都",
    2029: "刺客之月",
    2066: "水晶之王",
    2074: "亚历山大女王",
    2076: "经典老虎机",
    2021: "9个烈焰面具 HyperSpins™",
    5558: "全能宙斯帝国",
    2090: "WWE摔角传奇",
    2089: "野火灼金",
    2079: "喝彩的旺财",
    2037: "炽热猛犸象",
    2042: "炸弹海盗",
    2088: "荒野垂钓",
    2080: "星光之吻",
    2044: "现金与财富 Megaways™",
    2014: "15个三叉戟",
    5504: "布鲁诺的加洛黄金 Megaways",
    2024: "炼金财宝",
    2081: "追寻太阳",
    5559: "黄金闪电",
    5566: "极度野火奖金",
    2035: "美丽骷髅",
    5562: "777 超级 BIG BuildUp™ 豪华版™",
    5567: "深海行者",
    5556: "冰球突破十万路",
    2054: "雄鹰之翼",
    2040: "Oz之书锁定并旋转",
    2070: "幸运富豪 Hyperspins",
    2060: "超猎黄金",
    2069: "招财鞭炮",
    5560: "烈焰战狼Megaways™",
    2078: "银背乘数山",
    2020: "9个烈焰面具",
    2039: "Oz之书",
    5499: "火焰与玫瑰小丑",
    1897: "海洋珍宝",
    1965: "金色沙皇",
    1812: "一万零一夜",
    5575: "芬恩与糖果旋转",
    1976: "幽灵海盗",
    1950: "皇家特工",
    1852: "仙宫宝石",
    1799: "亡命对决2",
    1910: "蒸汽之塔™",
    1858: "炫目宝石™",
    1921: "神秘水果豪华版",
    1837: "赏金冲刺2",
    1989: "愿望之井",
    1898: "帕台农神庙：探索永生™",
    1949: "777连胜",
    1883: "热线2",
    1855: "现金争锋™",
    1808: "老虎机之王™",
    1907: "银背大猩猩黄金",
    1953: "阿兹特克旋转",
    1938: "水果螺旋病毒",
    1945: "吉祥娃娃",
    1924: "摇滚连锁",
    1860: "亡命对决2™ 特色游戏购买",
    1838: "疯狂旋转",
    1893: "摩托头",
    5568: "麒麟送宝",
    1935: "制造混乱",
    1824: "索尔的复仇",
    5573: "五福临门",
    1797: "天赐好运™巨额路线™",
    1952: "黄金圣典",
    1868: "童话传说：魔镜™",
    1818: "金莲呈祥",
    1913: "好运Clusterbuster™",
    1906: "塞伦盖蒂之王",
    1881: "枪炮玫瑰视频老虎机™",
    1861: "德古拉™",
    1991: "百搭红辣椒卷轴",
    1944: "贵族狗狗",
    1969: "雄狮纳财",
    1817: "三国争霸",
    1925: "钓鱼大亨",
    1834: "放克大师",
    5571: "吸血男爵Megaways™",
    1943: "财富满屋 Power Reels™",
    1798: "双子卷轴™",
    5572: "宝石暴走",
    1878: "贡佐的探险™",
    1823: "喧嚣对战",
    1822: "维琪冒险记",
    1832: "科尼利厄斯",
    1826: "炽热群集",
    1880: "猩猩王国™",
    1904: "斯库达摩尔的超级赌注",
    1922: "钱多多",
    1973: "深夜骰子",
    1845: "甜蜜果儿™",
    1813: "百兽之王",
    1923: "贡琪塔的任务",
    1942: "魔法财富",
    1981: "爬行动物大集合力量卷轴",
    1905: "古岩的奥秘™",
    1891: "锦鲤公主™",
    1870: "菲恩的金酒馆",
    1939: "精灵之夜",
    1926: "特洛伊之神",
    1829: "圣诞奇迹",
    1875: "水果商店™ 巨额路线™",
    1828: "纸杯蛋糕",
    5574: "龙袍",
    1963: "水果皇族",
    1836: "金色魔法书",
    1929: "阿帕切之路",
    1919: "失窃珍宝",
    1966: "贡萨的任务MEGAWAYS™",
    1948: "狂野冻原",
    1990: "自然之神",
    1959: "神龙火焰MEGAWAYS™",
    1796: "失落遗迹™",
    1934: "拯救猫科巨额赢利线™",
    1869: "童话传说：小红帽",
    1882: "万圣节杰克™",
    1995: "宙斯闪电力量转轴",
    1842: "卷轴神偷™",
    1901: "米德加德的财富：土地和扩张™",
    1892: "灯光™",
    1804: "罗马：黄金时代™",
    1802: "寻蝶记",
    1862: "竞速：多重狂欢™",
    1962: "老鹰财富",
    1866: "神剑™",
    1890: "丛林精灵：百搭互换",
    1872: "足球冠军杯™",
    5570: "梦幻奶昔超极限",
    1816: "巨龙超级群集™",
    1941: "齐拉德之王",
    1850: "阿罗哈！圣诞™",
    1848: "荒野之地™",
    1970: "狂欢啤酒节",
    1873: "水果商店™",
    1975: "富豪猪猪MEGAWAYS™",
    1894: "神话少女™",
    1877: "黄金之神：无限卷轴",
    1930: "拉塞达财富",
    1967: "滑稽金币",
    1811: "缤纷炸弹",
    1931: "性感歌舞厅",
    1988: "百搭之井MEGAWAYS™",
    1902: "玛雅崛起™",
    1844: "超级前锋™",
    1854: "寻蝶记2",
    1946: "淘金",
    1960: "黄金炸弹客",
    1900: "怒海狂涛™",
    1983: "里约之星",
    1915: "蛋糕与冰激凌",
    1884: "爱尔兰幸运罐子™",
    1887: "吉米·亨德里克斯在线老虎机™",
    1888: "小丑专业版™",
    1978: "幽灵海盗MEGAWAYS™",
    1800: "精灵菲恩与漩涡转盘",
    1863: "德鲁伊之梦™",
    1825: "霓虹连接",
    1979: "转轴守护者",
    5576: "龙之谕Ⅱ",
    1867: "童话传说：韩赛尔和格雷特™",
    1918: "影子密会",
    1815: "野兽冲突",
    1977: "海盗之战",
    1886: "杰克·汉墨2™",
    1917: "雅典 MegaWays™",
    1847: "巨魔奖池5000™",
    1846: "狼祸™",
    1879: "戈登·拉姆齐地狱厨房",
    1896: "霓虹星光™",
    1803: "财富法典™",
    1830: "超级巨星",
    1831: "推动神庙",
    1955: "现金大放送",
    1961: "黄金炸弹客Megaways",
    1876: "水果转盘™",
    1933: "皇家钻石",
    1807: "双重堆叠™",
    1810: "太空大战2力量指数",
    1805: "圣诞铃铛转盘™",
    1820: "劲爆迪斯科",
    1903: "邋遢鸭™",
    1964: "金色密码",
    5569: "龙之谕",
    1864: "马蒂克神蛋™",
    1936: "斗牛狂奔",
    1851: "阿罗哈！符号串赢奖™",
    1909: "星光四射™",
    1928: "圣殿秘宝Power Reels™",
    1994: "狂野西部诱惑",
    1985: "森林之神",
    1849: "财富之翼™",
    1993: "斯巴达勇士",
    1856: "马蒂克现金™",
    1833: "富贵蜂巢",
    1874: "水果商店圣诞版™",
    1839: "黑暗之王：禁忌财富",
    1951: "亚特兰蒂斯",
    1827: "非同质化代币",
    1974: "猪猪海盗",
    1968: "埃及圣甲虫",
    1927: "10001夜Megaways™",
    1809: "赏金冲刺™",
    1865: "元素：觉醒™",
    1957: "神龙火焰",
    1916: "奇幻兔子洞",
    5577: "拉神的恩赐",
    1937: "现金或全无",
    1920: "捣蛋万圣节",
    1982: "狮身人面像之谜",
    1853: "吸血男爵II™",
    1899: "财富小猪™",
    1801: "幼狼™",
    1819: "疯狂小镇",
    1908: "皮纳塔盛会™",
    1972: "神秘水果大中奖",
    1885: "杰克和魔豆™",
    1806: "狂野集市™",
    1871: "花朵™",
    1290: "死神殿",
    1317: "魅惑女王",
    1327: "阿努比斯之月",
    1295: "赌城之夜",
    1308: "三重辣椒",
    1388: "辣椒的道",
    1328: "狂野圣诞",
    1283: "野性霸主",
    1405: "四个王牌",
    1407: "财富之轮",
    1362: "圣诞节之伸手奖金购买",
    1325: "足球女将",
    1291: "水果超级新星 60",
    1281: "布袋卷轴",
    1344: "怪奇旅游",
    1414: "踩地雷",
    1309: "骷颅奇兵",
    1301: "梦想之谷",
    1383: "最大鱼获",
    5586: "足球单人前锋",
    1368: "死神殿奖金购买",
    1382: "野性霸主奖金购买",
    1365: "四季美人",
    1348: "水果超级新星 30",
    1420: "骑驴扫弹",
    1367: "火箭之星",
    1347: "冲浪区",
    1438: "龙之酒馆奖金购买",
    1279: "皇帝陵墓",
    1426: "东方神秘",
    1364: "森林梦境",
    1390: "钱币铸造机",
    1358: "运气循环奖金购买",
    1377: "血腥珠宝",
    5581: "欧洲转运奖金购买",
    1360: "辛迪加",
    1313: "命运符文",
    1376: "圣诞节之伸手",
    1361: "迷宫：渴望权力",
    1406: "好运之卡",
    1280: "藏宝任务",
    1385: "多汁宝石",
    1306: "海妖之金奖金购买",
    1336: "饥饿之夜",
    1284: "水果新星",
    1299: "鬼船海盗",
    1428: "点球系列",
    1402: "刮刮卡",
    1330: "史诗冲突",
    1342: "冒险飞船",
    1374: "水果迪斯科",
    1339: "无限愿望",
    1322: "爱尔兰画卷",
    1404: "幸运轮",
    1423: "弹球游戏",
    1343: "海上旋转",
    1340: "艾伦的财富",
    5585: "足球刮刮卡",
    1346: "毛怪复仇者",
    1272: "幸运七",
    1337: "愉快宝藏",
    1307: "努克世界",
    1334: "甜甜食糖",
    5582: "无限宝藏奖金购买",
    1286: "埃及众神",
    1409: "比大小",
    1323: "幸运七特辑",
    1431: "基诺圣诞猫",
    1355: "经典老虎机",
    1298: "篮球联盟",
    1311: "狂野子弹",
    1297: "果冻啾啾",
    5578: "圣贤遗产奖金购买",
    1312: "地城探险",
    1285: "美猴王",
    1273: "糖果梦",
    1341: "水果超级新星 40",
    1292: "冰狂",
    1413: "基诺之书",
    1387: "糖果梦:甜蜜星球",
    1410: "帕特里克之魔法场",
    1363: "遗忘的寓言",
    1294: "运气循环",
    5587: "点球轮盘",
    1359: "光之树",
    1378: "卢克斯之影",
    1403: "抛硬币",
    1381: "龙之酒馆",
    1282: "精灵公主",
    1332: "罗宾逊",
    1329: "午夜派对",
    1411: "时间之轮",
    1318: "古老传说",
    1277: "多汁宝石奖金购买",
    1421: "水手猜奖",
    1352: "亚特兰蒂斯",
    1436: "自豪之战",
    1324: "豚宝奇兵",
    1373: "法老诅咒奖金购买",
    1326: "乐透吧",
    1315: "荷鲁斯的崛起",
    1333: "复活之夜",
    5580: "冠军腰带奖金购买",
    1354: "E.T.军团",
    1320: "疯狂宝藏",
    1416: "寻找女王",
    1310: "水果超级新星 100",
    5583: "红玫瑰殿堂奖金购买",
    5579: "冬之星奖金购买",
    1384: "最大鱼获金购买",
    1276: "金钱暴",
    1274: "雷霆之殿",
    1370: "美食盛宴",
    1408: "骰吧",
    1289: "布袋卷轴奖金购买",
    1319: "驯兽宝贝",
    1430: "扭转胜利",
    1275: "水果超级新星",
    1288: "罗宾汉",
    1349: "爆炸水果",
    1356: "史诗传说",
    1304: "幸运女孩",
    1401: "点球大战",
    1296: "夏日派对",
    1335: "水果超级新星 80",
    1321: "疯狂木乃伊",
    1345: "海妖之金",
    1303: "战车",
    1761: "金玉满堂",
    1757: "朱雀宝藏",
    1786: "龙珠",
    1769: "大话西游",
    2013: "盗墓笔记",
    1784: "经典拉霸",
    1787: "魔童降世",
    1792: "五号宝藏",
    5506: "龙之财富",
    1999: "决战世界杯",
    2091: "经典拉霸2",
    1752: "摇钱树",
    1754: "驯龙高手",
    1747: "神灯传说",
    2001: "金色道虎",
    1996: "九线拉王",
    1771: "万圣狂欢夜",
    5554: "水浒Q传",
    1997: "钻石永恒",
    1783: "街头霸王",
    1751: "深夜食堂",
    1794: "真金拉霸",
    1793: "财富连连",
    1791: "金吉报喜",
    1744: "赤壁之战",
    1779: "寻妖录",
    2003: "百万金轮",
    2012: "幸运魔力球",
    1773: "遗迹探险",
    2007: "大富大贵",
    1762: "愤怒的小鸟",
    1790: "金鼓迎福",
    2004: "金色道龙",
    1789: "多福多财",
    1781: "王国纪元",
    1774: "狩猎日记",
    1768: "盗贼之海",
    1777: "魔法少女",
    1795: "财神的宝藏",
    1770: "龙凤呈祥",
    1749: "特洛伊木马",
    2008: "多福多财街机版",
    2009: "花开富贵",
    1746: "财神到",
    1780: "哈利波特",
    2002: "无尽宝藏",
    1745: "大闹天宫",
    1750: "功夫熊猫",
    1778: "糖果世界",
    1753: "超级马里奥",
    2005: "水果超市",
    5484: "麻将来了3",
    1766: "牛气冲天",
    1755: "森林舞会",
    1772: "长坂坡英雄",
    1758: "疯狂动物城",
    5588: "爱尔兰精灵",
    1775: "植物大战僵尸",
    1782: "丧尸之战",
    1776: "黄金矿工",
    1748: "花木兰",
    2006: "水果发发发",
    2000: "八宝黄龙",
    2011: "疯狂小玛莉",
    1788: "皇室战争",
    1760: "闪电狗",
    1763: "冰川时代",
    1998: "魔力宝箱",
    1756: "渔童钓宝",
    1759: "冰雪奇缘",
    5589: "疯狂刮刮乐",
    5549: "哈希大小",
    5551: "HILO翻牌",
    5545: "幸运哈希",
    5548: "哈希单双",
    5552: "T台美女秀",
    5555: "Bingo",
    5550: "极限跳伞",
    5547: "幸运庄闲",
    5546: "哈希牛牛",
  }

  // 365棋牌
  static QiPai365Games = {
    555: '抢庄牛牛',
    556: '明牌牛牛',
    666: '欢乐牛牛',
    705: '通比牛牛',
    567: '爆点牛牛',
    563: '三公',
    566: '通比三公',
    569: '极速三公',
    703: '爆点三公',
    706: '三公大吃小',
    449: '二人麻将',
    445: '红中麻将',
    452: '广东麻将',
    564: '血流麻将',
    557: '决战21点',
    561: '炸金花',
    559: '跑得快',
    562: '十三水',
    565: '德州扑克',
    549: '爆点百家乐',
    570: '二人斗地主',
    701: '365扑克',
    702: '8张',
    707: '印度三张',
    710: '印度拉米',
    912: '越式鱼虾蟹',
    913: '泰式鱼虾蟹',
    916: '越式大小',
    917: '泰式比高低',
    918: '印度骰宝',
    921: '安打巴哈',
    789: '八九点'
  };

  static sv388 = {
    WALA: '闲',
    BDD: '双亡和',
    MERON: '庄'
  };

  static SBOoddsStyle = type => {
    const obj = {
      M: '马来盘',
      H: '香港盘',
      E: '欧洲盘',
      I: '印尼盘'
    };
    return obj[type] || '';
  };

  static getEBETZRName = {
    1: '百家乐',
    2: '龙虎',
    3: '骰宝',
    4: '轮盘',
    5: '水果机',
    6: '试玩水果机',
    7: '原创百家乐',
    8: '牛牛'
  };

  static getSBOTYName = {
    Soccer: '足球',
    BasketBall: '篮球',
    Basketball: '篮球',
    Football: '美式足球',
    'Ice Hocket': '冰棍球',
    Badminton: '羽毛球',
    Pool: '撞球/斯洛克',
    'Pool/Snooker': '撞球/斯洛克',
    Motor_Sport: '赛车',
    'Motor Sport': '赛车',
    Tennis: '网球',
    Baseball: '棒球',
    Volleyball: '排球',
    Others: '其他',
    Golf: '高尔夫球',
    Boxing: '拳击',
    Cricket: '板球',
    Table_Tennis: '乒乓球',
    'Table Tennis': '乒乓球',
    Rugby: '橄榄球',
    Handball: '手球',
    Cycling: '自行车',
    Athletics: '竞技',
    Beach_Soccer: '沙滩足球',
    'Beach Soccer': '沙滩足球',
    Futsal: '室内足球',
    Entertainment: '独特',
    Financial: '金融',
    Darts: '飞镖',
    Olympic: '奥林匹克',
    Lacrosse: '袋棍球',
    Water_Polo: '水球',
    'Water Polo': '水球',
    'Ice Hockey': '冰棍球',
    Winter_Sports: '冬季运动',
    'Winter Sports': '冬季运动',
    Squash: '壁球',
    Field_Hockey: '草地曲棍球',
    'Field Hockey': '草地曲棍球',
    Mixed_Martial_Arts: '综合格斗',
    'Mixed Martial Arts': '综合格斗',
    E_Sports: '电子竞技',
    'E Sports': '电子竞技',
    Gaelic_Football: '盖尔式足球',
    'Gaelic Football': '盖尔式足球',
    Hurling: '板棍球',
    Muay_Thai: '泰拳',
    'Muay Thai': '泰拳',
    Aussie_Rules_Football: '澳洲足球',
    'Aussie Rules Football': '澳洲足球',
    Bandy: '班迪球',
    Winter_Olympics: '冬季奥林匹克运动会',
    'Winter Olympics': '冬季奥林匹克运动会',
    VirtualSports: '虚拟体育',
    VirtualFootballMobile: '虚拟足球手机版',
    VirtualFootballDesktop: '虚拟足球电脑版',
    VirtualRacing: '虚拟赛跑',
    VirtualBasketballMobile: '虚拟篮球手机版',
    VirtualWorldCupMobile: '虚拟世界杯手机版',
    VirtualWorldCupDesktop: '虚拟世界杯电脑版',
    MixParlayDesktop: '混搭电脑版',
    MixParlayMobile: '混搭手机版',
    'Mix Parlay': '混搭',
    VirtualBasketballDesktop: '虚拟篮球电脑版',
    VirtualTennisDesktop: '虚拟网球电脑版',
    VirtualTennisMobile: '虚拟网球手机版',
    VirtualEuroCupDesktop: '虚拟欧洲杯电脑版',
    VirtualEuroCupMobile: '虚拟欧洲杯手机版',
    VirtualAsianCupDesktop: '虚拟亚洲杯电脑版',
    VirtualAsianCupMobile: '虚拟亚洲杯手机版',
    VirtualChampionsCupDesktop: '虚拟冠军杯电脑版',
    VirtualChampionsCupMobile: '虚拟冠军杯手机版'
  };

  static KPPlayGames = {
    baicao: '百草',
    pokdeng: '博灯',
    tk: '杀手十三',
    horoscope: '星运',
    lieng: '丽恩',
    button: '色碟',
    hilo: 'Hi-Lo',
    fish: '鱼虾蟹',
    fantan: '番摊',
    blackjack: '黑杰克'
  };

  static getS128GameName() {
    return 'S128斗鸡';
  }

  static getSV388GameName() {
    return 'SV388斗鸡';
  }

  static getSEXYTYGameName() {
    return 'Sexy体育';
  }

  static getIMTYGameName() {
    return 'IM体育';
  }

  static getIMDJGameName() {
    return 'IM电竞';
  }

  static getDBDJGameName() {
    return 'DB电竞';
  }

  static getKYTYGameName() {
    return '开元体育';
  }

  static getFBTYGameName() {
    return 'FB体育';
  }

  static getTempDataGameName(data, value) {
    return value;
  }

  static getLyGameRoom(KindId, ServerId) {
    let name = '';
    let key = KindId + '_' + ServerId;
    if (this.LYQiPaiGameRooms[key]) {
      name = this.LYQiPaiGameRooms[key];
    }
    return name;
  }

  static getGameName(key, id) {
    let name = '';
    if (this[key] && this[key][id]) {
      name = this[key][id];
    }
    return name;
  }

  static getGameRoom(key, arr) {
    let room = '';
    let id = '';
    if (arr.length > 1) {
      id = arr.join('_');
    } else {
      id = arr[0];
    }
    if (this[key][id]) {
      room = this[key][id];
    }
    return room;
  }

  static getBLGameRoom(data, key) {
    let name = '';
    if (this.BLQiPaiGameRooms[data]) {
      name = this.BLQiPaiGameRooms[data][key];
    }
    return name;
  }

  // BBIN视讯
  static getBBINGameResult(nameData, parameter, tempData) {
    let { gameresult, gameType, card } = tempData;
    let _this = this;
    let gameResults = gameresult || '--';
    if (gameresult) {
      switch (+gameType) {
        case 3007:
        case 3008:
        case 3011:
        case 3015:
        case 3016:
        case 3019:
        case 3021:
          gameResults = gameresult;
          break;
        case 3002:
        case 3005:
          gameResults =
            _this.BBINGameResult[gameType][0] +
            ':' +
            gameresult.split(',')[0] +
            ',' +
            _this.BBINGameResult[gameType][1] +
            ':' +
            gameresult.split(',')[1] +
            ',' +
            _this.BBINGameResult[gameType][2] +
            ':' +
            gameresult.split(',')[2] +
            ',' +
            _this.BBINGameResult[gameType][3] +
            ':' +
            gameresult.split(',')[3];
          break;
        case 3012:
          // eslint-disable-next-line no-case-declarations
          let resultArr = [];
          for (let i = 0; i < gameresult.split(',').length; i++) {
            const playResults = {
              'No Bull': '无牛',
              Bull1: '牛一',
              Bull2: '牛二',
              Bull3: '牛三',
              Bull4: '牛四',
              Bull5: '牛五',
              Bull6: '牛六',
              Bull7: '牛七',
              Bull8: '牛八',
              Bull9: '牛九',
              'Bull Bull': '牛牛'
            }[gameresult.split(',')[i]];
            resultArr.push(playResults);
          }
          gameResults =
            _this.BBINGameResult[gameType][0] +
            ':' +
            resultArr[0] +
            ',' +
            _this.BBINGameResult[gameType][1] +
            ':' +
            resultArr[1] +
            ',' +
            _this.BBINGameResult[gameType][2] +
            ':' +
            resultArr[2] +
            ',' +
            _this.BBINGameResult[gameType][3] +
            ':' +
            resultArr[3];
          break;
        case 3001:
          gameResults = _this.BBINGameResult[gameType][0] + '(' + gameresult.split(',')[0] + '),' + _this.BBINGameResult[gameType][1] + '(' + gameresult.split(',')[1] + ')';
          break;
        case 3014:
        case 3017:
        case 3020:
        case 3003:
          gameResults = _this.BBINGameResult[gameType][0] + gameresult.split(',')[0] + _this.BBINGameResult[gameType][1] + gameresult.split(',')[1];
          break;
        case 3006:
          // eslint-disable-next-line no-case-declarations
          let resultList = [];
          for (let i = 0; i < gameresult.split(',').length; i++) {
            const item = gameresult.split(',')[i].slice(-1);
            const playResult = { L: '输', W: '赢' }[item];
            resultList.push(playResult);
          }
          gameResults = _this.BBINGameResult[gameType][0] + resultList[0] + ',' + _this.BBINGameResult[gameType][1] + resultList[1] + ',' + _this.BBINGameResult[gameType][2] + resultList[2];
          break;
        case 3018: // 炸金花
          // eslint-disable-next-line no-case-declarations
          let List = [];
          if (gameresult) {
            for (let i = 0; i < gameresult.split(',').length; i++) {
              const item = gameresult.split(',')[i];
              const itemCardTypesd = item.split('-')[0];
              const itemCards = item.split('-')[1];
              const cardResults =
                {
                  HighCard: '散牌',
                  AnyTripleKiller: '豹子杀手',
                  Pair: '对子',
                  Straight: '顺子',
                  Flush: '同花',
                  StraightFlush: '同花順',
                  AnyTriple: '豹子'
                }[itemCardTypesd] + itemCards;
              List.push(cardResults);
            }
            gameResults = _this.BBINGameResult[gameType][0] + ':' + List[0] + ',' + _this.BBINGameResult[gameType][1] + ':' + List[1];
          } else {
            gameResults = _this.BBINGameResult[gameType][0] + '()' + _this.BBINGameResult[gameType][1] + '()';
          }
          break;
        case 3010:
          // eslint-disable-next-line no-case-declarations
          let cardList = [[], [], []];
          for (let j = 0; j < card.split('*').length; j++) {
            const temp = card.split('*')[j].split(',');
            for (let k = 0; k < temp.length; k++) {
              const index = temp[k].split('.');
              const cardTypes = { H: '红桃', C: '梅花', S: '黑桃', D: '方块' }[index[0]];
              const cardItems = {
                '1': 'A',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
                '8': '8',
                '9': '9',
                '10': '10',
                '11': 'J',
                '12': 'Q',
                '13': 'K'
              }[index[1]];
              const cardResults = cardTypes + cardItems;
              cardList[j].push(cardResults);
            }
          }
          gameResults =
            _this.BBINGameResult[gameType][0] +
            ':' +
            cardList[0].toString() +
            ';' +
            _this.BBINGameResult[gameType][1] +
            ':' +
            cardList[1].toString() +
            ';' +
            _this.BBINGameResult[gameType][2] +
            ':' +
            cardList[2].toString();
      }
    }
    return gameResults;
  }

  static getBBINBetDetail(nameData, parameter, tempData) {
    let { gameType, wagerDetail } = tempData;
    let _this = this;
    let betDetails = '';
    if (wagerDetail) {
      if (wagerDetail.includes('*')) {
        let detailArr = wagerDetail.split('*');
        for (let i = 0; i < detailArr.length; i++) {
          let detailItem = detailArr[i].split(',')[0];
          if (_this.BBINbetDetails[gameType] && detailItem) {
            betDetails += _this.BBINbetDetails[gameType][detailItem] + ',';
          }
        }
        betDetails = betDetails.substr(0, betDetails.length - 1);
      } else {
        let gameIndex = Number(wagerDetail.split(',')[0]);
        betDetails = _this.BBINbetDetails[gameType] ? _this.BBINbetDetails[gameType][gameIndex] : '';
      }
    }
    return betDetails;
  }

  // 沙巴体育
  static getSBTGameType(gameTypes) {
    let gameType = '';
    if (this.SBTGameTypes[gameTypes]) {
      gameType = this.SBTGameTypes[gameTypes];
    }
    return gameType;
  }

  // Sexy体育
  static getSEXYSportsName(sportsNames) {
    let sportsName = '';
    if (this.SEXYSportsNames[sportsNames]) {
      sportsName = this.SEXYSportsNames[sportsNames];
    }
    return sportsName;
  }

  static getSBTGameStatus(nameData, parameter, tempData) {
    let statusStr = '';
    let status = tempData.ticket_status ? tempData.ticket_status.toLocaleLowerCase() : '';
    switch (status) {
      case 'waiting':
        statusStr = '未受理';
        break;
      case 'running':
        statusStr = '未结算';
        break;
      case 'void':
        statusStr = '已作废';
        break;
      case 'refund':
        statusStr = '已退款';
        break;
      case 'reject':
        statusStr = '已取消';
        break;
      case 'lose':
        statusStr = '输';
        break;
      case 'won':
        statusStr = '赢';
        break;
      case 'draw':
        statusStr = '未输赢';
        break;
      case 'half won':
        statusStr = '赢一半';
        break;
      case 'half lose':
        statusStr = '输一半';
        break;
      default:
    }
    return statusStr;
  }

  static getSBTGameName(nameData, parameter, tempData) {
    let sportName = '';
    if (this.SBTGameNames[tempData.sport_type]) {
      sportName = this.SBTGameNames[tempData.sport_type];
    } else if (tempData.bet_type === 29) {
      sportName = '篮球';
    }
    return sportName;
  }

  static getSBTOddsType(type) {
    return { 1: '马来盘', 2: '香港盘', 3: '欧洲盘', 4: '印尼盘', 5: '美国盘' }[type];
  }

  // 天游棋牌
  static getTHQiPaiGameRoom(nameData, parameter, tempData) {
    let room = '';
    if (this.THQiPaiGameRooms[tempData.room_level]) {
      room = this.THQiPaiGameRooms[tempData.room_level];
    }
    return room;
  }

  static getIBOSXWFType(type) {
    return { MH: '庄', MC: '闲', MN: '和', HP: '庄对', CP: '闲对', PH: '小费' }[type];
  }
}
