//  11选5
export default {
  plays: {
    0: {rule_id: 22000, area_id: 22000, play_name: 'Q3ZXFS', play_cname: '前三直选复式', unit_price: 1, note_tag: 'qianN'},
    1: {
      rule_id: 22001,
      area_id: 22001,
      play_name: 'Q3ZUXFS',
      play_cname: '前三组选复式',
      unit_price: 1,
      note_tag: 'sscComb3'
    },
    2: {rule_id: 22002, area_id: 22002, play_name: 'Q3ZXDT', play_cname: '前三组选胆拖', unit_price: 1, note_tag: 'dtComb3'},
    3: {rule_id: 22003, area_id: 22003, play_name: 'Z3ZXFS', play_cname: '中三直选复式', unit_price: 1, note_tag: 'qianN'},
    4: {
      rule_id: 22004,
      area_id: 22004,
      play_name: 'Z3ZUXFS',
      play_cname: '中三组选复式',
      unit_price: 1,
      note_tag: 'sscComb3'
    },
    5: {rule_id: 22005, area_id: 22005, play_name: 'Z3ZXDT', play_cname: '中三组选胆拖', unit_price: 1, note_tag: 'dtComb3'},
    6: {rule_id: 22006, area_id: 22006, play_name: 'H3ZXFS', play_cname: '后三直选复式', unit_price: 1, note_tag: 'qianN'},
    7: {
      rule_id: 22007,
      area_id: 22007,
      play_name: 'Z3ZUXFS',
      play_cname: '后三组选复式',
      unit_price: 1,
      note_tag: 'sscComb3'
    },
    8: {rule_id: 22008, area_id: 22008, play_name: 'H3ZXDT', play_cname: '后三组选胆拖', unit_price: 1, note_tag: 'dtComb3'},
    9: {rule_id: 22009, area_id: 22009, play_name: 'Q2ZXFS', play_cname: '前二直选复式', unit_price: 1, note_tag: 'qianN'},
    10: {
      rule_id: 22010,
      area_id: 22010,
      play_name: 'Q2ZUXFS',
      play_cname: '前二组选复式',
      unit_price: 1,
      note_tag: 'sscComb2'
    },
    11: {rule_id: 22011, area_id: 22011, play_name: 'Q2ZXDT', play_cname: '前二组选胆拖', unit_price: 1, note_tag: 'dtComb2'},
    12: {rule_id: 22012, area_id: 22012, play_name: 'H2ZXFS', play_cname: '后二直选复式', unit_price: 1, note_tag: 'qianN'},
    13: {
      rule_id: 22013,
      area_id: 22013,
      play_name: 'H2ZUXFS',
      play_cname: '后二组选复式',
      unit_price: 1,
      note_tag: 'sscComb2'
    },
    14: {rule_id: 22014, area_id: 22014, play_name: 'H2ZXDT', play_cname: '后二组选胆拖', unit_price: 1, note_tag: 'dtComb2'},
    15: {rule_id: 22015, area_id: 22015, play_name: 'BDWQ3', play_cname: '不定位前三', unit_price: 1, note_tag: 'count'},
    16: {rule_id: 22016, area_id: 22016, play_name: 'BDWZ3', play_cname: '不定位中三', unit_price: 1, note_tag: 'count'},
    17: {rule_id: 22017, area_id: 22017, play_name: 'BDWH3', play_cname: '不定位后三', unit_price: 1, note_tag: 'count'},
    18: {rule_id: 22018, area_id: 22018, play_name: 'DWD', play_cname: '定位胆', unit_price: 1, note_tag: 'count'},
    19: {rule_id: 22019, area_id: 22019, play_name: 'RXFS1Z1', play_cname: '任选复式一中一', unit_price: 1, note_tag: 'count'},
    20: {
      rule_id: 22020,
      area_id: 22020,
      play_name: 'RXFS2Z2',
      play_cname: '任选复式二中二',
      unit_price: 1,
      note_tag: 'sscComb2'
    },
    21: {
      rule_id: 22021,
      area_id: 22021,
      play_name: 'RXFS3Z3',
      play_cname: '任选复式三中三',
      unit_price: 1,
      note_tag: 'sscComb3'
    },
    22: {
      rule_id: 22022,
      area_id: 22022,
      play_name: 'RXFS4Z4',
      play_cname: '任选复式四中四',
      unit_price: 1,
      note_tag: 'sscComb4'
    },
    23: {
      rule_id: 22023,
      area_id: 22023,
      play_name: 'RXFS5Z5',
      play_cname: '任选复式五中五',
      unit_price: 1,
      note_tag: 'sscComb5'
    },
    24: {
      rule_id: 22024,
      area_id: 22024,
      play_name: 'RXFS6Z5',
      play_cname: '任选复式六中五',
      unit_price: 1,
      note_tag: 'sscComb6'
    },
    25: {
      rule_id: 22025,
      area_id: 22025,
      play_name: 'RXFS7Z5',
      play_cname: '任选复式七中五',
      unit_price: 1,
      note_tag: 'sscComb7'
    },
    26: {
      rule_id: 22026,
      area_id: 22026,
      play_name: 'RXFS8Z5',
      play_cname: '任选复式八中五',
      unit_price: 1,
      note_tag: 'sscComb8'
    },
    27: {
      rule_id: 22027,
      area_id: 22027,
      play_name: 'RXDT2Z2',
      play_cname: '任选胆拖二中二',
      unit_price: 1,
      note_tag: 'dtComb2'
    },
    28: {
      rule_id: 22028,
      area_id: 22028,
      play_name: 'RXDT3Z3',
      play_cname: '任选胆拖三中三',
      unit_price: 1,
      note_tag: 'dtComb3'
    },
    29: {
      rule_id: 22029,
      area_id: 22029,
      play_name: 'RXDT4Z4',
      play_cname: '任选胆拖四中四',
      unit_price: 1,
      note_tag: 'dtComb4'
    },
    30: {
      rule_id: 22030,
      area_id: 22030,
      play_name: 'RXDT5Z5',
      play_cname: '任选胆拖五中五',
      unit_price: 1,
      note_tag: 'dtComb5'
    },
    31: {
      rule_id: 22031,
      area_id: 22031,
      play_name: 'RXDT6Z5',
      play_cname: '任选胆拖六中五',
      unit_price: 1,
      note_tag: 'dtComb6'
    },
    32: {
      rule_id: 22032,
      area_id: 22032,
      play_name: 'RXDT7Z5',
      play_cname: '任选胆拖七中五',
      unit_price: 1,
      note_tag: 'dtComb7'
    },
    33: {
      rule_id: 22033,
      area_id: 22033,
      play_name: 'RXDT8Z5',
      play_cname: '任选胆拖八中五',
      unit_price: 1,
      note_tag: 'dtComb8'
    }
  }
}
