// @ts-nocheck
import Base64 from 'crypto-js/enc-base64';
import Latin1 from 'crypto-js/enc-latin1';
import {isAndroid, isIOS} from './index';
import config from '../config';
import Open3GameConfig from './Open3GameConfig';

export default class RNEvent {
  context = null;
  events = [];
  observers = {};

  eKeyboardWillShow = 'keyboardWillShow'; // 键盘将显示
  eKeyboardDidShow = 'keyboardDidShow'; // 键盘显示完成
  eKeyboardWillHide = 'keyboardWillHide'; // 键盘将隐藏
  eKeyboardDidHide = 'keyboardDidHide'; // 键盘隐藏完成
  eKeyboardWillChangeFrame = 'keyboardWillChangeFrame'; // 键盘窗口将变化
  eKeyboardDidChangeFrame = 'keyboardDidChangeFrame'; // 键盘窗口已变化

  eConnectionChange = 'ConnectionChange'; // 网络连接发生变化
  eAppStateChanged = 'AppStateChanged'; // App状态发生变化(App前后能切换) android:active/background ios:inactive/background/active
  sendPicture = 'sendPicture'; // 发送图片
  goHomeBack = 'goHomeBack'; // 返回首页
  curUrlRoute = 'curUrlRoute'; // 当前线路
  rnConfigData = 'configData';
  h5Delay = 'h5Delay';
  backToFront = 'backToFront'; // app从后台进入了前台
  LBPay = 'LBPay'; // 联邦支付

  init() {
    // 接收来自ReactNative的信息
    let that = this;
    window.receivedReactNativeEvent = function (msg) {
      console.error('====>>>接收来自ReactNative的信息内容:', msg, ' <==>类型: ', typeof msg);
      let data = null;
      try {
        // 如果msg 是对象 直接输出
        if ((typeof msg).toLowerCase() === 'object') {
          data = msg;
        } else {
          data = JSON.parse(msg);
        }
      } catch (e) {
        console.error('====>>>ReactNative的消息释出错：' + e.message);
      }
      if (data == null) data = msg;
      that.events.push(data);
    };
    window.closeGame = function (msg) {
      try {
        that.dispatchEvent({
          name: 'thirdGameClose'
        });
      } catch (e) {
      }
    };
    setInterval(() => {
      // 处理接收到的设备消息
      if (that.events.length !== 0) {
        let event = that.events.shift();
        that.dispatchEvent(event);
      }
    }, 100);
  }

  addObserver(key, eventName, func) {
    this.observers[key + '-' + eventName] = {
      eventName: eventName,
      func: func
    };
  }

  removeObserver(key, eventName) {
    delete this.observers[key + '-' + eventName];
  }

  dispatchEvent(event) {
    for (let k in this.observers) {
      let observer = this.observers[k];
      let evName = 'message';
      let evData = event;
      if (event.name) {
        evName = event.name;
      }
      if (evName === 'executor') {
        /* eslint-disable */
        window.rnBridgerTmp = this;
        eval('var executor=' + event.data.js + ';executor();');
        window.rnBridgerTmp = null;
        /* eslient-enable */
      }
      if (event.data) {
        evData = event.data;
      }
      if (observer.eventName === evName) {
        if (observer.func) {
          observer.func(evData, event);
        }
      }
    }
  }

  sendMessage(msg) {
    this.sendNativeMsg(msg);
  }

  // sendBetMessage(msg) {
  //   this.sendBetNativeMsg(msg);
  // }

  base64encode(str) {
    return Base64.stringify(Latin1.parse(str));
  }

  base64deencode(str) {
    return Latin1.stringify(Base64.parse(str));
  }

  // 开始录音
  recordAudioStart(callback) {
    // this.__callNativeApi('recordAudioStart', {}, callback)
    //
    let data = {
      name: 'recordAudioStart',
      args: {},
      id: 'id-record-audio-start' // 使用固定的ID，
    };
    if (callback) {
      this.addObserver(data.id, data.name, (result, event) => {
        if (event.id === data.id) {
          // 因为在录音过程中会多次返回相关状态，所以不执行removeObserver
          callback(result);
        }
      });
    }
    this.sendNativeMsg('__callNativeApi:' + JSON.stringify(data));
  }

  // 停止录音
  recordAudioStop(callback) {
    this.__callNativeApi('recordAudioStop', {}, callback);
  }

  // 播放录音
  recordAudioPlay(callback) {
    this.__callNativeApi('recordAudioPlay', {}, callback);
  }

  // 获取设备信息
  getDevice(callback) {
    this.__callNativeApi('getDevice', {}, callback);
  }

  // 调用起支付宝支付
  alipay(orderInfo, callback) {
    this.__callNativeApi('alipay', orderInfo || '', callback);
  }

  // 封装的RN里的fetch方法
  fetch(info, callback) {
    // {url: '', params: {}}
    this.__callNativeApi('fetch', info, callback);
  }

  // 从原生获取租户等信息
  getRNConfig(callback) {
    this.__callNativeApi('getRNConfig', {}, callback);
  }

  // 向原生提交要重定向的资源列表
  /**
   * [{
   * srcHost: 需要重定向的资host
   * srcPath: 需要重定向的资路径,
   * distPath: 响应的资源路径,
   * distHost: 响应的资源的host
   }]
   * */
  postRedirectResList(list, callback) {
    let args = {};
    args.list = list;
    args.host = window.location.host;
    this.__callNativeApi('postRedirectResList', args, callback);
  }

  // 上传表单
  // @param formData FormData数据结构
  postFormData(url, headers, formData, callback) {
    let args = {
      url: url,
      headers: headers,
      formData: {}
    };
    if (formData) {
      // eslint-disable-next-line
      formData.forEach((value, key) => (args.formData[key] = value));
    }
    this.__callNativeApi('postFormData', args, callback);
  }

  openQQ(params = {}, callback = null) {
    // 参考
    // https://ask.dcloud.net.cn/question/7425
    let url = isAndroid() ? 'mqqwpa://im/chat' : 'mqq://im/chat';
    if (params == null) {
      params = {};
    }
    params['chat_type'] = 'wpa';
    params['version'] = '1';
    params['src_type'] = 'web';
    this.openScheme(url, params, callback);
  }

  // 打开scheme
  openScheme(baseURL, params = {}, callback = null) {
    if (params == null) {
      params = {};
    }

    let arr = [];
    for (let k in params) {
      arr.push(`${k}=${params[k]}`);
    }
    let paramsStr = arr.join('&');
    let args = {
      scheme: baseURL.split('://')[0] + '://',
      baseURL: baseURL,
      params: params,
      url: baseURL + (paramsStr ? '?' + paramsStr : '')
    };
    if (params) {
      args.params = params;
    }
    this.__callNativeApi('openScheme', args, callback);
  }

  // 打开第三方游戏
  open3Game(url, gameInfo, callback) {
    if (window.android && window.android.openUrlByBrower) {
      window.android.openUrlByBrower(url);
      return;
    }
    // RN壳webview容器打开有问题的游戏，ios使用外置浏览器打开
    const isOpenInBroswer = Open3GameConfig.openInBroswerGames.some(item => item === Number(gameInfo?.play_id) || item === Number(gameInfo?.gameId));
    // RN壳webview容器打开有问题的游戏，安卓和ios都使用外置浏览器打开
    const isOpenInBroswerAll = Open3GameConfig.openInBroswerGamesAll.some(item => item === Number(gameInfo?.play_id) || item === Number(gameInfo?.gameId));
    // 是否竖屏
    const isLandscape = !Open3GameConfig.portraitGames.some(item => item === Number(gameInfo.play_id) || item === Number(gameInfo.gameId));
    // 是否不锁方向
    const isUnlockOrientation = Open3GameConfig.lockGames.some(item => item === Number(gameInfo.play_id) || item === Number(gameInfo.gameId));
    if ((isOpenInBroswer && isIOS()) || isOpenInBroswerAll) {
      this.sendNativeMsg('url:' + url);
      return;
    }
    let args = {
      url: url,
      gameInfo: gameInfo,
      isLandscape: isLandscape,
      isUnlockOrientation: isUnlockOrientation
    };
    this.__callNativeApi('open3Game', args, callback);
  }

  // 获取安装信息（免填邀请码）
  getInstall(callback) {
    this.__callNativeApi('getInstall', {}, callback);
  }

  // 已进入首页
  didAppLoaded(callback) {
    this.__callNativeApi('didAppLoaded', {}, callback);
  }

  // 设置设备的方向
  // @param orientation Landscape:横屏 Portrait:坚屏
  setOrientation(orientation, callback) {
    let sendMsg = 'setOrientation:';
    let data = {
      name: orientation,
      args: {},
      id: new Date().getTime() + ''
    };
    let that = this;
    if (callback) {
      this.addObserver(data.id, data.name, (result, event) => {
        if (event.id === data.id) {
          that.removeObserver(event.id, event.name);
          callback(result);
        }
      });
    }
    sendMsg += JSON.stringify(data);
    this.sendNativeMsg(sendMsg);
  }

  __callNativeApi(name, _args, callback) {
    let sendMsg = '__callNativeApi:';
    let data = {
      name: name,
      args: {},
      id: new Date().getTime() + ''
    };
    if (_args) {
      data.args = _args;
    }
    let that = this;
    if (callback) {
      this.addObserver(data.id, data.name, (result, event) => {
        if (event.id === data.id) {
          that.removeObserver(event.id, event.name);
          callback(result);
        }
      });
    }
    sendMsg += JSON.stringify(data);
    this.sendNativeMsg(sendMsg);
  }

  // 从react-native中获取数据
  // Deprecated 即将弃用
  getInfo(name, _args, callback) {
    let sendMsg = 'getinfo:';
    let data = {
      name: name,
      args: {},
      id: new Date().getTime() + ''
    };
    if (_args) {
      data.args = _args;
    }
    let that = this;
    if (callback) {
      this.addObserver(data.id, data.name, (result, event) => {
        if (event.id === data.id) {
          that.removeObserver(event.id, event.name);
          callback(result);
        }
      });
      sendMsg += JSON.stringify(data);
      this.sendNativeMsg(sendMsg);
    }
  }

  // 读存档
  readRecord(itemName, callback) {
    let sendMsg = 'readRecord:';
    let data = {
      name: itemName,
      id: new Date().getTime() + ''
    };
    let that = this;
    if (callback) {
      this.addObserver(data.id, data.name, (result, event) => {
        if (event.id === data.id) {
          that.removeObserver(event.id, event.name);
          callback(result);
        }
      });
      sendMsg += JSON.stringify(data);
      this.sendNativeMsg(sendMsg);
    }
  }

  // 写存档
  writeRecord(itemName, object, callback?) {
    let sendMsg = 'writeRecord:';
    let data = {
      name: itemName,
      id: new Date().getTime() + '',
      value: object
    };
    let that = this;
    this.addObserver(data.id, data.name, (result, event) => {
      if (event.id === data.id) {
        that.removeObserver(event.id, event.name);
        callback(result);
      }
    });
    sendMsg += JSON.stringify(data);
    this.sendNativeMsg(sendMsg);
  }

  // 添加监听Android返回事件
  addAndroidBackHandler(key, callback) {
    this.sendNativeMsg('addBackHandler:' + key);
    if (callback) {
      this.addObserver(key, 'hardwareBackPress', (result, event) => {
        if (event.id === key) {
          callback(result);
        }
      });
    }
  }

  // 删除监听Android返回事件
  removeAndroidBackHandler(key) {
    this.sendNativeMsg('removeBackHandler:' + key);
    this.removeObserver(key, 'hardwareBackPress');
  }

  sendNativeMsg(msg, type) {
    try {
      if (isAndroid()) {
        if (type == 'weixin') {
          // eslint-disable-next-line no-undef
          NativeInterface.reciveJsMessage('weixin:open');
        } else {
          // eslint-disable-next-line
          NativeInterface.reciveJsMessage(msg);
        }
      } else if (isIOS()) {
        if (config.appWKwebview) {
          window.webkit.messageHandlers.reactNative.postMessage({data: msg});
        } else {
          window.postMessage(msg);
        }
      }
      window.jsBridge && window.jsBridge.reciveJsMessage && window.jsBridge.reciveJsMessage(msg);
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  }

  sendBetNativeMsg(method, msg, type) {
    try {
      if (isAndroid()) {
        if (type == 'weixin') {
          // eslint-disable-next-line no-undef
          BettingFollow.FollowBetSend('weixin:open');
        } else {
          // eslint-disable-next-line
          BettingFollow.FollowBetSend(method, msg);
        }
      } else if (isIOS()) {
        if (config.appWKwebview) {
          window.webkit.messageHandlers.BettingFollow.postMessage({
            method,
            data: msg
          });
        } else {
          window.postMessage(msg);
        }
      } else {
        window.BettingFollow && window.BettingFollow.FollowBetSend && window.BettingFollow.FollowBetSend(msg);
        // eslint-disable-next-line no-empty
      }
    } catch (e) {
    }
  }

  static inst = null;

  static getInstance() {
    if (this.inst == null) {
      this.inst = new RNEvent();
      this.inst.init();
    }
    return this.inst;
  }
}
