// 走势图相关配置
export const chartTabs = {
  // ===>>时时彩
  TAB_SSC_01: {
    name: '开奖结果',
    sub_title: 'SSC_SUB_APP_RESULT',
    xt_keys: ['DXDS_0', 'DXDS_1', 'DXDS_2', 'DXDS_3', 'DXDS_4', 'XT_0_2', 'XT_2_4'],
    disable_line: true,
    disable_yilou: true
  },
  TAB_SSC_02: {name: '万位', sub_title: 'SSC_SUB_NUMS', data_keys: ['MISS_0'], stats: {start_col: 1}},
  TAB_SSC_03: {name: '千位', sub_title: 'SSC_SUB_NUMS', data_keys: ['MISS_1'], stats: {start_col: 1}},
  TAB_SSC_04: {name: '百位', sub_title: 'SSC_SUB_NUMS', data_keys: ['MISS_2'], stats: {start_col: 1}},
  TAB_SSC_05: {name: '十位', sub_title: 'SSC_SUB_NUMS', data_keys: ['MISS_3'], stats: {start_col: 1}},
  TAB_SSC_06: {name: '个位', sub_title: 'SSC_SUB_NUMS', data_keys: ['MISS_4'], stats: {start_col: 1}},
  // 前三
  TAB_SSC_07: {
    name: '走势',
    sub_title: 'SSC_SUB_NUMS',
    data_keys: ['MISS_0_2'],
    stats: {start_col: 1},
    disable_line: true
  },
  TAB_SSC_08: {name: '跨度', sub_title: 'SSC_SUB_NUMS', data_keys: ['MISS_KD_0_2'], stats: {start_col: 1}},
  // 后三
  TAB_SSC_09: {
    name: '走势',
    sub_title: 'SSC_SUB_NUMS',
    data_keys: ['MISS_2_4'],
    stats: {start_col: 1},
    disable_line: true
  },
  TAB_SSC_10: {name: '跨度', sub_title: 'SSC_SUB_NUMS', data_keys: ['MISS_KD_2_4'], stats: {start_col: 1}},

  // 大小单双
  TAB_SSC_11: {
    name: '前二形态',
    sub_title: 'SSC_SUB_DXDS',
    data_keys: ['MISS_DXDS_0', 'MISS_DXDS_1'],
    stats: {start_col: 2},
    disable_line: true,
    win_range: [0, 1]
  },
  TAB_SSC_12: {
    name: '前三形态',
    sub_title: 'SSC_SUB_QIAN3',
    xt_keys: ['DXDS_0', 'DXDS_1', 'DXDS_2'],
    win_range: [0, 2],
    disable_line: true,
    disable_yilou: true
  },
  TAB_SSC_13: {
    name: '后二形态',
    sub_title: 'SSC_SUB_DXDS',
    data_keys: ['MISS_DXDS_3', 'MISS_DXDS_4'],
    stats: {start_col: 2},
    disable_line: true,
    win_range: [3, 4]
  },
  TAB_SSC_14: {
    name: '后三形态',
    sub_title: 'SSC_SUB_HOU3',
    xt_keys: ['DXDS_2', 'DXDS_3', 'DXDS_4'],
    win_range: [2, 4],
    disable_line: true,
    disable_yilou: true
  },

  // 任选
  TAB_SSC_15: {
    name: '走势',
    sub_title: 'SSC_SUB_NUMS',
    data_keys: ['MISS_RX'],
    stats: {start_col: 1},
    disable_line: true
  },
  TAB_SSC_16: {name: '合值组合', sub_title: 'SSC_SUB_HEZI', xt_keys: ['HZZH_2'], disable_line: true, disable_yilou: true},
  TAB_SSC_17: {name: '合值组合', sub_title: 'SSC_SUB_HEZI', xt_keys: ['HZZH_3'], disable_line: true, disable_yilou: true},
  TAB_SSC_18: {name: '合值组合', sub_title: 'SSC_SUB_HEZI', xt_keys: ['HZZH_4'], disable_line: true, disable_yilou: true},

  TAB_SSC_99: {name: '冷热', sub_title: 'SSC_SUB_COLD_HOT', cold_hot: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], disable_line: true},

  // ===>>11选5
  TAB_115_01: {
    name: '开奖结果',
    sub_title: 'A115_SUB_APP_RESULT',
    xt_keys: ['KD', 'CHGS'],
    disable_line: true,
    disable_yilou: true
  },
  TAB_115_02: {name: '一位走势', sub_title: 'A115_SUB_NUMS', data_keys: ['MISS_0'], stats: {start_col: 1}},
  TAB_115_03: {name: '二位走势', sub_title: 'A115_SUB_NUMS', data_keys: ['MISS_1'], stats: {start_col: 1}},
  TAB_115_04: {name: '三位走势', sub_title: 'A115_SUB_NUMS', data_keys: ['MISS_2'], stats: {start_col: 1}},
  TAB_115_05: {name: '四位走势', sub_title: 'A115_SUB_NUMS', data_keys: ['MISS_3'], stats: {start_col: 1}},
  TAB_115_06: {name: '五位走势', sub_title: 'A115_SUB_NUMS', data_keys: ['MISS_4'], stats: {start_col: 1}},
  // 前三
  TAB_115_07: {
    name: '号码走势',
    sub_title: 'A115_SUB_NUMS',
    data_keys: ['MISS_Q3'],
    stats: {start_col: 1},
    disable_line: true
  },
  TAB_115_08: {
    name: '号码形态',
    sub_title: 'A115_SUB_APP_XINTAI',
    xt_keys: ['XT_DX_Q3', 'XT_JO_Q3', 'XT_ZH_Q3'],
    disable_line: true,
    disable_yilou: true
  },
  // 前三
  TAB_115_09: {
    name: '号码走势',
    sub_title: 'A115_SUB_NUMS',
    data_keys: ['MISS_Z3'],
    stats: {start_col: 1},
    disable_line: true
  },
  TAB_115_10: {
    name: '号码形态',
    sub_title: 'A115_SUB_APP_XINTAI',
    data_keys: [''],
    xt_keys: ['XT_DX_Z3', 'XT_JO_Z3', 'XT_ZH_Z3']
  },
  // 后三
  TAB_115_11: {
    name: '号码走势',
    sub_title: 'A115_SUB_NUMS',
    data_keys: ['MISS_H3'],
    stats: {start_col: 1},
    disable_line: true
  },
  TAB_115_12: {
    name: '号码形态',
    sub_title: 'A115_SUB_APP_XINTAI',
    xt_keys: ['XT_DX_H3', 'XT_JO_H3', 'XT_ZH_H3'],
    disable_line: true,
    disable_yilou: true
  },

  // 前二
  TAB_115_13: {
    name: '号码走势',
    sub_title: 'A115_SUB_NUMS',
    data_keys: ['MISS_Q2'],
    stats: {start_col: 1},
    disable_line: true
  },
  TAB_115_14: {
    name: '号码形态',
    sub_title: 'A115_SUB_APP_XINTAI',
    xt_keys: ['XT_DX_Q2', 'XT_JO_Q2', 'XT_ZH_Q2'],
    disable_line: true,
    disable_yilou: true
  },

  // 后二
  TAB_115_15: {
    name: '号码走势',
    sub_title: 'A115_SUB_NUMS',
    data_keys: ['MISS_H2'],
    stats: {start_col: 1},
    disable_line: true
  },
  TAB_115_16: {
    name: '号码形态',
    sub_title: 'A115_SUB_APP_XINTAI',
    xt_keys: ['XT_DX_H2', 'XT_JO_H2', 'XT_ZH_H2'],
    disable_line: true,
    disable_yilou: true
  },

  TAB_115_17: {name: '一位形态', sub_title: 'SSC_SUB_EMXT', data_keys: ['XT_JO_0', 'XT_ZH_0'], disable_line: true},
  TAB_115_18: {name: '二位形态', sub_title: 'SSC_SUB_EMXT', data_keys: ['XT_JO_1', 'XT_ZH_1'], disable_line: true},
  TAB_115_19: {name: '四位形态', sub_title: 'SSC_SUB_EMXT', data_keys: ['XT_JO_3', 'XT_ZH_3'], disable_line: true},
  TAB_115_20: {name: '五位形态', sub_title: 'SSC_SUB_EMXT', data_keys: ['XT_JO_4', 'XT_ZH_4'], disable_line: true},

  // 任选
  TAB_115_21: {
    name: '号码走势',
    sub_title: 'A115_SUB_NUMS',
    data_keys: ['MISS_RX'],
    stats: {start_col: 1},
    disable_line: true
  },
  TAB_115_22: {
    name: '号码形态',
    sub_title: 'A115_SUB_APP_XINTAI',
    xt_keys: ['XT_DX_RX', 'XT_JO_RX', 'XT_ZH_RX'],
    disable_line: true,
    disable_yilou: true
  },

  TAB_115_99: {
    name: '冷热',
    sub_title: 'A115_SUB_APP_COLD_HOT',
    cold_hot: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    disable_line: true
  },

  // ===>>快3
  TAB_K3_01: {
    name: '开奖结果',
    sub_title: 'K3_SUB_APP_RESULT',
    xt_keys: ['SUM', 'DX', 'DS', 'XT'],
    disable_line: true,
    disable_yilou: true
  },
  TAB_K3_02: {name: '一位走势', sub_title: 'K3_SUB_NUMS', data_keys: ['MISS_0'], stats: {start_col: 1}},
  TAB_K3_03: {name: '二位走势', sub_title: 'K3_SUB_NUMS', data_keys: ['MISS_1'], stats: {start_col: 1}},
  TAB_K3_04: {name: '三位走势', sub_title: 'K3_SUB_NUMS', data_keys: ['MISS_2'], stats: {start_col: 1}},

  // 和值
  TAB_K3_05: {
    name: '基本走势',
    sub_title: 'K3_SUB_HEZI',
    data_keys: ['MISS_0_2'],
    stats: {start_col: 4},
    xt_keys: ['SUM', 'KD'],
    disable_line: true
  },
  TAB_K3_06: {
    name: '冷热',
    sub_title: 'A115_SUB_APP_COLD_HOT',
    cold_hot: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
    disable_line: true
  },

  // 三通选
  TAB_K3_07: {
    name: '形态走势',
    sub_title: 'K3_SUB_3TXT',
    data_keys: ['MISS_XT'],
    stats: {start_col: 2},
    disable_line: true
  },
  // 二同号
  TAB_K3_08: {
    name: '号码分布',
    sub_title: 'K3_SUB_HMFB',
    data_keys: ['MISS_2TH'],
    stats: {start_col: 2},
    disable_line: true
  },

  TAB_K3_99: {name: '冷热', sub_title: 'A115_SUB_APP_COLD_HOT', cold_hot: [1, 2, 3, 4, 5, 6], disable_line: true},

  // ===>>PC00
  TAB_PC00_01: {
    name: '开奖结果',
    sub_title: 'PC00_SUB_MISS',
    xt_keys: ['DXDS', 'SB'],
    disable_line: true,
    disable_yilou: true
  },
  TAB_PC00_02: {
    name: '和值走势',
    sub_title: 'PC00_SUB_HEZI',
    data_keys: ['MISS_HZ'],
    stats: {start_col: 1},
    disable_yilou: true
  },

  // ===>>六合彩
  TAB_MARKSIX_01: {
    name: '开奖结果',
    sub_title: 'MARKSIX_SUB_MISS',
    xt_keys: ['TM', 'SX_6', 'DS_6', 'SB_6', 'TT_6', 'WS_6', 'HDS_6'],
    win_range: [0, 5],
    disable_line: true,
    disable_yilou: true
  },

  // ===>>PK10
  TAB_PK10_01: {name: '开奖结果', sub_title: 'PK10_SUB_APP_RESULT', disable_line: true, disable_yilou: true},
  TAB_PK10_02: {name: '冠军走势', sub_title: 'PK10_SUB_APP_NUMS', data_keys: ['MISS_0'], stats: {start_col: 1}},
  TAB_PK10_03: {name: '亚军走势', sub_title: 'PK10_SUB_APP_NUMS', data_keys: ['MISS_1'], stats: {start_col: 1}},
  TAB_PK10_04: {name: '季军走势', sub_title: 'PK10_SUB_APP_NUMS', data_keys: ['MISS_2'], stats: {start_col: 1}},
  TAB_PK10_05: {name: '第四走势', sub_title: 'PK10_SUB_APP_NUMS', data_keys: ['MISS_3'], stats: {start_col: 1}},
  TAB_PK10_06: {name: '第五走势', sub_title: 'PK10_SUB_APP_NUMS', data_keys: ['MISS_4'], stats: {start_col: 1}},
  TAB_PK10_07: {name: '第六走势', sub_title: 'PK10_SUB_APP_NUMS', data_keys: ['MISS_5'], stats: {start_col: 1}},
  TAB_PK10_08: {name: '第七走势', sub_title: 'PK10_SUB_APP_NUMS', data_keys: ['MISS_6'], stats: {start_col: 1}},
  TAB_PK10_09: {name: '第八走势', sub_title: 'PK10_SUB_APP_NUMS', data_keys: ['MISS_7'], stats: {start_col: 1}},
  TAB_PK10_10: {name: '第九走势', sub_title: 'PK10_SUB_APP_NUMS', data_keys: ['MISS_8'], stats: {start_col: 1}},
  TAB_PK10_11: {name: '第十走势', sub_title: 'PK10_SUB_APP_NUMS', data_keys: ['MISS_9'], stats: {start_col: 1}},
  TAB_PK10_12: {
    name: '冠军冷热',
    sub_title: 'A115_SUB_APP_COLD_HOT',
    cold_hot: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    hot_keys: ['HOT_30:MISS_0', 'HOT_50:MISS_0', 'HOT_100:MISS_0', 'MISS:MISS_0'],
    disable_line: true
  },
  TAB_PK10_13: {
    name: '亚军冷热',
    sub_title: 'A115_SUB_APP_COLD_HOT',
    cold_hot: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    hot_keys: ['HOT_30:MISS_1', 'HOT_50:MISS_1', 'HOT_100:MISS_1', 'MISS:MISS_1'],
    disable_line: true
  },
  TAB_PK10_14: {
    name: '季军冷热',
    sub_title: 'A115_SUB_APP_COLD_HOT',
    cold_hot: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    hot_keys: ['HOT_30:MISS_2', 'HOT_50:MISS_2', 'HOT_100:MISS_2', 'MISS:MISS_2'],
    disable_line: true
  }
}

export const tableTitles = {
  // 号码
  SSC_SUB_NUMS: [
    {key: 'term_code', name: '期号', app_width: 60, pc_hide: true},
    {key: '1', name: '0'},
    {key: '2', name: '1'},
    {key: '3', name: '2'},
    {key: '4', name: '3'},
    {key: '5', name: '4'},
    {key: '6', name: '5'},
    {key: '7', name: '6'},
    {key: '8', name: '7'},
    {key: '9', name: '8'},
    {key: '10', name: '9'}
  ],
  // 开奖结果
  SSC_SUB_APP_RESULT: [
    {key: 'term_code', name: '期号', app_width: 40, pc_width: 120},
    {key: 'term_win', name: '开奖号码', app_width: 60, pc_width: 120},
    {key: '0', name: '万位', pc_width: 100},
    {key: '1', name: '千位', pc_width: 100},
    {key: '2', name: '百位', pc_width: 100},
    {key: '3', name: '十位', pc_width: 100},
    {key: '4', name: '个位', pc_width: 100},
    {key: '5', name: '前三', pc_width: 100},
    {key: '6', name: '后三', pc_width: 100}
  ],
  // 冷热
  SSC_SUB_COLD_HOT: [
    {key: 'num', name: '号码', pc_width: 60},
    {key: '30', name: '30期', pc_width: 100},
    {key: '50', name: '50期', pc_width: 100},
    {key: '100', name: '100期', pc_width: 100},
    {key: 'yilou', name: '遗漏', pc_width: 100}
  ],
  // 大小单双
  SSC_SUB_DXDS: [
    {key: 'term_code', name: '期号', app_width: 50, pc_hide: true},
    {key: 'term_win', name: '开奖号码', app_width: 50, pc_hide: true},
    {key: '2', name: '大'},
    {key: '3', name: '小'},
    {key: '4', name: '单'},
    {key: '5', name: '双'},
    {key: '6', name: '大'},
    {key: '7', name: '小'},
    {key: '8', name: '单'},
    {key: '9', name: '双'}
  ],
  // 前三
  SSC_SUB_QIAN3: [
    {key: 'term_code', name: '期号', app_width: 60, pc_hide: true},
    {key: 'term_win', name: '开奖号码', app_width: 100, pc_hide: true},
    {key: '0', name: '万位', pc_width: 60},
    {key: '1', name: '千位', pc_width: 60},
    {key: '2', name: '百位', pc_width: 60}
  ],
  // 前三
  SSC_SUB_HOU3: [
    {key: 'term_code', name: '期号', app_width: 60, pc_hide: true},
    {key: 'term_win', name: '开奖号码', app_width: 100, pc_hide: true},
    {key: '2', name: '百位', pc_width: 60},
    {key: '3', name: '十位', pc_width: 60},
    {key: '4', name: '个位', pc_width: 60}
  ],
  // 和值
  SSC_SUB_HEZI: [
    {key: 'term_code', name: '期号', app_width: 60, pc_hide: true},
    {key: 'term_win', name: '开奖号码', app_width: 100, pc_hide: true},
    {key: '0', name: '和值组合', pc_width: 180}
  ],
  // 二码形态
  SSC_SUB_EMXT: [
    {key: 'term_code', name: '期号', app_width: 60, pc_hide: true},
    {key: 'term_win', name: '开奖号码', app_width: 100, pc_hide: true},
    {key: '2', name: '奇'},
    {key: '3', name: '偶'},
    {key: '4', name: '质'},
    {key: '5', name: '合'}
  ],
  // 号码
  A115_SUB_NUMS: [
    {key: 'term_code', name: '期号', app_width: 60, pc_hide: true},
    {key: '1', name: '1'},
    {key: '2', name: '2'},
    {key: '3', name: '3'},
    {key: '4', name: '4'},
    {key: '5', name: '5'},
    {key: '6', name: '6'},
    {key: '7', name: '7'},
    {key: '8', name: '8'},
    {key: '9', name: '9'},
    {key: '10', name: '10'},
    {key: '11', name: '11'}
  ],
  A115_SUB_APP_RESULT: [
    {key: 'term_code', name: '期号', app_width: 50, pc_width: 150},
    {key: 'term_win', name: '开奖号码', app_width: 120, pc_width: 200},
    {key: '0', name: '跨度', pc_width: 150},
    {key: '1', name: '重号个数', pc_width: 150}
  ],
  A115_SUB_APP_COLD_HOT: [
    {key: 'num', name: '号码', pc_width: 100},
    {key: '30', name: '30期', pc_width: 100},
    {key: '50', name: '50期', pc_width: 100},
    {key: '100', name: '100期', pc_width: 100},
    {key: 'yilou', name: '遗漏', pc_width: 100}
  ],
  A115_SUB_APP_XINTAI: [
    {key: 'term_code', name: '期号', app_width: 50, pc_hide: true},
    {key: 'term_win', name: '开奖号码', app_width: 120, pc_hide: true},
    {key: '0', name: '大小比', pc_width: 100},
    {key: '1', name: '奇偶比', pc_width: 100},
    {key: '2', name: '质合比', pc_width: 100}
  ],
  K3_SUB_APP_RESULT: [
    {key: 'term_code', name: '期号', app_width: 60, pc_width: 120},
    {key: 'term_win', name: '开奖号码', app_width: 100, pc_width: 180},
    {key: '2', name: '和值', app_width: 50, pc_width: 100},
    {key: '3', name: '大小', app_width: 50, pc_width: 100},
    {key: '4', name: '单双', app_width: 50, pc_width: 100},
    {key: '5', name: '形态', app_width: 80, pc_width: 200}
  ],
  // 号码
  K3_SUB_NUMS: [
    {key: 'term_code', name: '期号', app_width: 60, pc_hide: true},
    {key: '1', name: '1'},
    {key: '2', name: '2'},
    {key: '3', name: '3'},
    {key: '4', name: '4'},
    {key: '5', name: '5'},
    {key: '6', name: '6'}
  ],
  // 和值
  K3_SUB_HEZI: [
    {key: 'term_code', name: '期号', app_width: 60, pc_hide: true},
    {key: 'term_win', name: '开奖号码', app_width: 80, pc_hide: true},
    {key: 'hz', name: '和值', app_width: 40, pc_width: 60},
    {key: 'kd', name: '跨度', app_width: 40, pc_width: 60},
    {key: '4', name: '1', pc_width: 60},
    {key: '5', name: '2', pc_width: 60},
    {key: '6', name: '3', pc_width: 60},
    {key: '7', name: '4', pc_width: 60},
    {key: '8', name: '5', pc_width: 60},
    {key: '9', name: '6', pc_width: 60}
  ],
  // 三同号形态
  K3_SUB_3TXT: [
    {key: 'term_code', name: '期号', app_width: 60, pc_hide: true},
    {key: 'term_win', name: '开奖号码', app_width: 80, pc_hide: true},
    {key: '2', name: '三同号', pc_width: 80},
    {key: '3', name: '三不同', pc_width: 80},
    {key: '4', name: '二同号', pc_width: 80},
    {key: '5', name: '二不同', pc_width: 80}
  ],
  // 二同号号码分布
  K3_SUB_HMFB: [
    {key: 'term_code', name: '期号', app_width: 60, pc_hide: true},
    {key: 'term_win', name: '开奖号码', app_width: 80, pc_hide: true},
    {key: '2', name: '11'},
    {key: '3', name: '22'},
    {key: '4', name: '33'},
    {key: '5', name: '44'},
    {key: '6', name: '55'},
    {key: '7', name: '66'}
  ],
  PC00_SUB_MISS: [
    {key: 'term_code', name: '期号', app_width: 60, pc_width: 100},
    {key: 'term_win', name: '开奖号码', app_width: 120, pc_width: 180},
    {key: '2', name: '大小单双', pc_width: 200},
    {key: '3', name: '色波', pc_width: 80}
  ],
  PC00_SUB_HEZI: [
    {key: 'term_code', name: '期号', app_width: 60, pc_hide: true},
    {key: 'term_win', name: '开奖号码', app_width: 120, pc_hide: true},
    {key: '2', name: '0'},
    {key: '3', name: '1'},
    {key: '4', name: '2'},
    {key: '5', name: '3'},
    {key: '6', name: '4'},
    {key: '7', name: '5'},
    {key: '8', name: '6'},
    {key: '9', name: '7'},
    {key: '10', name: '8'},
    {key: '11', name: '9'},
    {key: '12', name: '10'},
    {key: '13', name: '11'},
    {key: '14', name: '12'},
    {key: '15', name: '13'},
    {key: '16', name: '14'},
    {key: '17', name: '15'},
    {key: '18', name: '16'},
    {key: '19', name: '17'},
    {key: '20', name: '18'},
    {key: '21', name: '19'},
    {key: '22', name: '20'},
    {key: '23', name: '21'},
    {key: '24', name: '22'},
    {key: '25', name: '23'},
    {key: '26', name: '24'},
    {key: '27', name: '25'},
    {key: '28', name: '26'},
    {key: '29', name: '27'}
  ],
  MARKSIX_SUB_MISS: [
    {key: 'term_code', name: '期号', app_width: 60, pc_width: 100},
    {key: 'term_win', name: '正码', app_width: 140, pc_width: 280},
    {key: 'term_win_t', name: '特码', app_width: 30, pc_width: 120},
    {key: '3', name: '生肖', app_width: 30, pc_width: 120},
    {key: '4', name: '单双', app_width: 30, pc_width: 120},
    {key: '5', name: '色波', app_width: 30, pc_width: 120},
    {key: '6', name: '特头', app_width: 30, pc_width: 120},
    {key: '7', name: '尾数', app_width: 30, pc_width: 120},
    {key: '8', name: '合单双', app_width: 40, pc_width: 120}
  ],

  PK10_SUB_APP_RESULT: [
    {key: 'term_code', name: '期号', app_width: 50, pc_width: 100},
    {key: 'term_win', name: '开奖号码', pc_width: 240}
  ],

  PK10_SUB_APP_NUMS: [
    {key: 'term_code', name: '期号', app_width: 50, pc_hide: true},
    {key: '1', name: '1'},
    {key: '2', name: '2'},
    {key: '3', name: '3'},
    {key: '4', name: '4'},
    {key: '5', name: '5'},
    {key: '6', name: '6'},
    {key: '7', name: '7'},
    {key: '8', name: '8'},
    {key: '9', name: '9'},
    {key: '10', name: '10'}
  ]
}

export const chartConfigs = {
  'PK10': {
    winlen: 2,
    trends: [
      {
        trend: 0,
        name: '基本走势',
        tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_03', 'TAB_PK10_04', 'TAB_PK10_05', 'TAB_PK10_06', 'TAB_PK10_07', 'TAB_PK10_08', 'TAB_PK10_09', 'TAB_PK10_10', 'TAB_PK10_11'],
        term_code_len: 3
      },
      {trend: 1, name: '前一', tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_12'], term_code_len: 3},
      {
        trend: 2,
        name: '前二',
        tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_12', 'TAB_PK10_03', 'TAB_PK10_13'],
        term_code_len: 3
      },
      {
        trend: 3,
        name: '前三',
        tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_12', 'TAB_PK10_03', 'TAB_PK10_13', 'TAB_PK10_04', 'TAB_PK10_14'],
        term_code_len: 3
      }
      // { trend: 1, name: '和值走势', tab_keys: [ 'TAB_PC00_02'], term_code_len: 3 },
    ]
  },
  'PK10JDC': {
    winlen: 2,
    trends: [
      {
        trend: 0,
        name: '基本走势',
        tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_03', 'TAB_PK10_04', 'TAB_PK10_05', 'TAB_PK10_06', 'TAB_PK10_07', 'TAB_PK10_08', 'TAB_PK10_09', 'TAB_PK10_10', 'TAB_PK10_11'],
        term_code_len: 3
      },
      {trend: 1, name: '前一', tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_12'], term_code_len: 3},
      {
        trend: 2,
        name: '前二',
        tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_12', 'TAB_PK10_03', 'TAB_PK10_13'],
        term_code_len: 3
      },
      {
        trend: 3,
        name: '前三',
        tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_12', 'TAB_PK10_03', 'TAB_PK10_13', 'TAB_PK10_04', 'TAB_PK10_14'],
        term_code_len: 3
      }
      // { trend: 1, name: '和值走势', tab_keys: [ 'TAB_PC00_02'], term_code_len: 3 },
    ]
  },
  'XYFTJDC': {
    winlen: 2,
    trends: [
      {
        trend: 0,
        name: '基本走势',
        tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_03', 'TAB_PK10_04', 'TAB_PK10_05', 'TAB_PK10_06', 'TAB_PK10_07', 'TAB_PK10_08', 'TAB_PK10_09', 'TAB_PK10_10', 'TAB_PK10_11'],
        term_code_len: 3
      },
      {trend: 1, name: '前一', tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_12'], term_code_len: 3},
      {
        trend: 2,
        name: '前二',
        tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_12', 'TAB_PK10_03', 'TAB_PK10_13'],
        term_code_len: 3
      },
      {
        trend: 3,
        name: '前三',
        tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_12', 'TAB_PK10_03', 'TAB_PK10_13', 'TAB_PK10_04', 'TAB_PK10_14'],
        term_code_len: 3
      }
      // { trend: 1, name: '和值走势', tab_keys: [ 'TAB_PC00_02'], term_code_len: 3 },
    ]
  },
  'XYFT': {
    winlen: 2,
    trends: [
      {
        trend: 0,
        name: '基本走势',
        tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_03', 'TAB_PK10_04', 'TAB_PK10_05', 'TAB_PK10_06', 'TAB_PK10_07', 'TAB_PK10_08', 'TAB_PK10_09', 'TAB_PK10_10', 'TAB_PK10_11'],
        term_code_len: 3
      },
      {trend: 1, name: '前一', tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_12'], term_code_len: 3},
      {
        trend: 2,
        name: '前二',
        tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_12', 'TAB_PK10_03', 'TAB_PK10_13'],
        term_code_len: 3
      },
      {
        trend: 3,
        name: '前三',
        tab_keys: ['TAB_PK10_01', 'TAB_PK10_02', 'TAB_PK10_12', 'TAB_PK10_03', 'TAB_PK10_13', 'TAB_PK10_04', 'TAB_PK10_14'],
        term_code_len: 3
      }
      // { trend: 1, name: '和值走势', tab_keys: [ 'TAB_PC00_02'], term_code_len: 3 },
    ]
  },
  'PC00': {
    winlen: 2,
    trends: [
      {trend: 0, name: '基本走势', tab_keys: ['TAB_PC00_01'], term_code_len: 3, pc_hide: true},
      {trend: 1, name: '基本走势', tab_keys: ['TAB_PC00_01', 'TAB_PC00_02'], term_code_len: 3, app_hide: true}
    ]
  },
  'K3': {
    winlen: 1,
    trends: [
      {trend: 0, name: '基本走势', tab_keys: ['TAB_K3_01', 'TAB_K3_02', 'TAB_K3_03', 'TAB_K3_04'], term_code_len: 3},
      {trend: 1, name: '和值', tab_keys: ['TAB_K3_01', 'TAB_K3_05', 'TAB_K3_06'], term_code_len: 3},
      {trend: 2, name: '三同号', tab_keys: ['TAB_K3_01', 'TAB_K3_05', 'TAB_K3_07'], term_code_len: 3},
      {trend: 2, name: '三连号', tab_keys: ['TAB_K3_01', 'TAB_K3_05'], term_code_len: 3},
      {trend: 3, name: '三不同号', tab_keys: ['TAB_K3_01', 'TAB_K3_05', 'TAB_K3_07', 'TAB_K3_99'], term_code_len: 3},
      {trend: 4, name: '二同号', tab_keys: ['TAB_K3_01', 'TAB_K3_05', 'TAB_K3_08'], term_code_len: 3},
      {trend: 5, name: '二不同号', tab_keys: ['TAB_K3_01', 'TAB_K3_05', 'TAB_K3_99'], term_code_len: 3}
    ]
  },
  '115': {
    winlen: 2,
    trends: [
      {
        trend: 0,
        name: '基本走势',
        tab_keys: ['TAB_115_01', 'TAB_115_02', 'TAB_115_03', 'TAB_115_04', 'TAB_115_05', 'TAB_115_06'],
        term_code_len: 3
      },
      {trend: 1, name: '前三走势', tab_keys: ['TAB_115_01', 'TAB_115_07', 'TAB_115_08', 'TAB_115_99'], term_code_len: 3},
      {trend: 2, name: '中三走势', tab_keys: ['TAB_115_01', 'TAB_115_09', 'TAB_115_10', 'TAB_115_99'], term_code_len: 3},
      {trend: 3, name: '后三走势', tab_keys: ['TAB_115_01', 'TAB_115_11', 'TAB_115_12', 'TAB_115_99'], term_code_len: 3},
      {
        trend: 4,
        name: '前二走势',
        tab_keys: ['TAB_115_01', 'TAB_115_13', 'TAB_115_14', 'TAB_115_02', 'TAB_115_17', 'TAB_115_03', 'TAB_115_18', 'TAB_115_05', 'TAB_115_19', 'TAB_115_06', 'TAB_115_20', 'TAB_115_99'],
        term_code_len: 3
      },
      {
        trend: 5,
        name: '后二走势',
        tab_keys: ['TAB_115_01', 'TAB_115_15', 'TAB_115_16', 'TAB_115_02', 'TAB_115_17', 'TAB_115_03', 'TAB_115_18', 'TAB_115_05', 'TAB_115_19', 'TAB_115_06', 'TAB_115_20', 'TAB_115_99'],
        term_code_len: 3
      },
      {trend: 6, name: '任选走势', tab_keys: ['TAB_115_01', 'TAB_115_21', 'TAB_115_22', 'TAB_115_99'], term_code_len: 3}
    ]
  },
  'SSC': {
    winlen: 1,
    trends: [
      {
        trend: 0,
        name: '基本走势',
        tab_keys: ['TAB_SSC_01', 'TAB_SSC_02', 'TAB_SSC_03', 'TAB_SSC_04', 'TAB_SSC_05', 'TAB_SSC_06'],
        term_code_len: 3
      },
      {
        trend: 0,
        name: '定位胆',
        tab_keys: ['TAB_SSC_01', 'TAB_SSC_02', 'TAB_SSC_03', 'TAB_SSC_04', 'TAB_SSC_05', 'TAB_SSC_06'],
        term_code_len: 3
      },
      {
        trend: 0,
        name: '五星',
        tab_keys: ['TAB_SSC_01', 'TAB_SSC_02', 'TAB_SSC_03', 'TAB_SSC_04', 'TAB_SSC_05', 'TAB_SSC_06'],
        term_code_len: 3
      },
      {
        trend: 1,
        name: '四星',
        tab_keys: ['TAB_SSC_01', 'TAB_SSC_03', 'TAB_SSC_04', 'TAB_SSC_05', 'TAB_SSC_06'],
        term_code_len: 3
      },
      {trend: 2, name: '后三', tab_keys: ['TAB_SSC_01', 'TAB_SSC_09', 'TAB_SSC_10', 'TAB_SSC_99'], term_code_len: 3},
      {trend: 3, name: '前三', tab_keys: ['TAB_SSC_01', 'TAB_SSC_07', 'TAB_SSC_08', 'TAB_SSC_99'], term_code_len: 3},
      {trend: 4, name: '前二', tab_keys: ['TAB_SSC_01', 'TAB_SSC_02', 'TAB_SSC_03', 'TAB_SSC_99'], term_code_len: 3},

      {
        trend: 0,
        name: '不定位',
        tab_keys: ['TAB_SSC_01', 'TAB_SSC_02', 'TAB_SSC_03', 'TAB_SSC_04', 'TAB_SSC_05', 'TAB_SSC_06'],
        term_code_len: 3
      },
      {
        trend: 5,
        name: '大小单双',
        tab_keys: ['TAB_SSC_01', 'TAB_SSC_11', 'TAB_SSC_12', 'TAB_SSC_13', 'TAB_SSC_14'],
        term_code_len: 3
      },
      {trend: 6, name: '任选二', tab_keys: ['TAB_SSC_01', 'TAB_SSC_15', 'TAB_SSC_16', 'TAB_SSC_99'], term_code_len: 3},
      {trend: 7, name: '任选三', tab_keys: ['TAB_SSC_01', 'TAB_SSC_15', 'TAB_SSC_17', 'TAB_SSC_99'], term_code_len: 3},
      {trend: 8, name: '任选四', tab_keys: ['TAB_SSC_01', 'TAB_SSC_15', 'TAB_SSC_18', 'TAB_SSC_99'], term_code_len: 3},
      {
        trend: 0,
        name: '龙虎',
        tab_keys: ['TAB_SSC_01', 'TAB_SSC_02', 'TAB_SSC_03', 'TAB_SSC_04', 'TAB_SSC_05', 'TAB_SSC_06'],
        term_code_len: 3
      }
    ]
  },
  'SSCJDC': {
    winlen: 1,
    trends: [
      {
        trend: 0,
        name: '基本走势',
        tab_keys: ['TAB_SSC_01', 'TAB_SSC_02', 'TAB_SSC_03', 'TAB_SSC_04', 'TAB_SSC_05', 'TAB_SSC_06'],
        term_code_len: 3
      },
      {
        trend: 0,
        name: '定位胆',
        tab_keys: ['TAB_SSC_01', 'TAB_SSC_02', 'TAB_SSC_03', 'TAB_SSC_04', 'TAB_SSC_05', 'TAB_SSC_06'],
        term_code_len: 3
      },
      {
        trend: 0,
        name: '五星',
        tab_keys: ['TAB_SSC_01', 'TAB_SSC_02', 'TAB_SSC_03', 'TAB_SSC_04', 'TAB_SSC_05', 'TAB_SSC_06'],
        term_code_len: 3
      },
      {
        trend: 1,
        name: '四星',
        tab_keys: ['TAB_SSC_01', 'TAB_SSC_03', 'TAB_SSC_04', 'TAB_SSC_05', 'TAB_SSC_06'],
        term_code_len: 3
      },
      {trend: 2, name: '后三', tab_keys: ['TAB_SSC_01', 'TAB_SSC_09', 'TAB_SSC_10', 'TAB_SSC_99'], term_code_len: 3},
      {trend: 3, name: '前三', tab_keys: ['TAB_SSC_01', 'TAB_SSC_07', 'TAB_SSC_08', 'TAB_SSC_99'], term_code_len: 3},
      {trend: 4, name: '前二', tab_keys: ['TAB_SSC_01', 'TAB_SSC_02', 'TAB_SSC_03', 'TAB_SSC_99'], term_code_len: 3},

      {
        trend: 0,
        name: '不定位',
        tab_keys: ['TAB_SSC_01', 'TAB_SSC_02', 'TAB_SSC_03', 'TAB_SSC_04', 'TAB_SSC_05', 'TAB_SSC_06'],
        term_code_len: 3
      },
      {
        trend: 5,
        name: '大小单双',
        tab_keys: ['TAB_SSC_01', 'TAB_SSC_11', 'TAB_SSC_12', 'TAB_SSC_13', 'TAB_SSC_14'],
        term_code_len: 3
      },
      {trend: 6, name: '任选二', tab_keys: ['TAB_SSC_01', 'TAB_SSC_15', 'TAB_SSC_16', 'TAB_SSC_99'], term_code_len: 3},
      {trend: 7, name: '任选三', tab_keys: ['TAB_SSC_01', 'TAB_SSC_15', 'TAB_SSC_17', 'TAB_SSC_99'], term_code_len: 3},
      {trend: 8, name: '任选四', tab_keys: ['TAB_SSC_01', 'TAB_SSC_15', 'TAB_SSC_18', 'TAB_SSC_99'], term_code_len: 3},
      {
        trend: 0,
        name: '龙虎',
        tab_keys: ['TAB_SSC_01', 'TAB_SSC_02', 'TAB_SSC_03', 'TAB_SSC_04', 'TAB_SSC_05', 'TAB_SSC_06'],
        term_code_len: 3
      }
    ]
  },
  'MARKSIX': {
    winlen: 2,
    trends: [
      {trend: 0, name: '基本走势', tab_keys: ['TAB_MARKSIX_01'], term_code_len: 3}
    ]
  },
  'LHCJDC': {
    winlen: 2,
    trends: [
      {trend: 0, name: '基本走势', tab_keys: ['TAB_MARKSIX_01'], term_code_len: 3}
    ]
  }
}
