/* 北京赛车 */
export default {
  plays: {
    0: {
      rule_id: 34000,
      area_id: 34000,
      play_name: 'CGJ',
      play_cname: '猜冠军',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    100: {
      rule_id: 34100,
      area_id: 34100,
      play_name: 'CGYJ',
      play_cname: '猜冠亚军',
      special: false,
      unit_price: 1,
      note_tag: 'qianN'
    },
    200: {
      rule_id: 34200,
      area_id: 34200,
      play_name: 'CQ3M',
      play_cname: '猜前三名',
      special: false,
      unit_price: 1,
      note_tag: 'qianN'
    },
    300: {
      rule_id: 34300,
      area_id: 34300,
      play_name: 'LM',
      play_cname: '两面',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    400: {
      rule_id: 34400,
      area_id: 34400,
      play_name: 'LH',
      play_cname: '龙虎',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    500: {
      rule_id: 34500,
      area_id: 34500,
      play_name: '',
      play_cname: '冠亚季选一',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    600: {
      rule_id: 34600,
      area_id: 34600,
      play_name: '',
      play_cname: '冠亚组合',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    601: {
      rule_id: 34601,
      area_id: 34601,
      play_name: '',
      play_cname: '冠亚总和',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    700: {
      rule_id: 34700,
      area_id: 34700,
      play_name: 'DWD',
      play_cname: '定位胆',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    800: {
      rule_id: 34800,
      area_id: 34800,
      play_name: 'GYHZDS',
      play_cname: '冠亚和单双',
      special: false,
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },
    801: {
      rule_id: 34801,
      area_id: 34801,
      play_name: 'GYHZDX',
      play_cname: '冠亚和大小',
      special: false,
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },
    802: {
      rule_id: 34802,
      area_id: 34802,
      play_name: 'GYHZZDZ',
      play_cname: '冠亚和指定',
      special: false,
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    }
  }
}
