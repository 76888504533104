/* 印度彩 */
export default {
  plays: {
    110: {rule_id: 52000, area_id: 52000, play_name: 'GWLQ', play_cname: 'Green', note_tag: 'count', unit_price: 1},
    120: {rule_id: 52001, area_id: 52001, play_name: 'GWHQ', play_cname: 'Red', note_tag: 'count', unit_price: 1},
    130: {rule_id: 52002, area_id: 52002, play_name: 'GWZQ', play_cname: 'Violet', note_tag: 'count', unit_price: 1},
    100: {
      rule_id: 52003,
      area_id: 52003,
      play_name: 'GWDX',
      play_cname: 'Number',
      note_tag: 'count',
      unit_price: 1,
      cmd_signal_ball: true
    }
  }
}