/**
 * 消息 公告 banner 的操作
 */
import {defineStore} from 'pinia';
import msgFetch from './msgFetch';
import {useHot} from '/@/kernel-mn/stores/useHot';

export const useMsgStore = defineStore({
  id: 'message',
  state: () => {
    return {
      banners: [], // banner图
      newLists: [], // 公告
      gameNewsLists: [], // 游戏公告
      activeformquery: [],
      activeTaskType: null,
      reqNewListFinish: false,
      webNewsUnread: 0, // 未读站内信
      enquiryActivityApplication: '', // 优惠申请查询
      dtycj: {
        isCanOpenDtycj: false, // 用于控制是否能进入答题赢彩金的
        datiNum: 0, // 答题次数
      },
    };
  },
  getters: {},
  actions: {
    ...msgFetch,
    setBanners(data) {
      this.banners = data;
    },
    setNewLists(data) {
      this.newLists = data;
    },
    setGameNewsLists(data) {
      this.gameNewsLists = data;
    },
    setReqNewListFinish(data) {
      this.reqNewListFinish = data;
    },
    setWebNewsUnread(data) {
      this.webNewsUnread = data
    },
    setActiveTaskType(type) {
      this.activeTaskType = type;
    },
    setIsCanOpenDtycj(data) {
      this.dtycj.isCanOpenDtycj = data
    },
  }
});
