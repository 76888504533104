import helpers from './helpersOthersGames'
import {utilsTotal} from '../../utils'
import {mb_t} from '/@/plugins/global'

export default class historyUtil {
  static getTitle(tempData, showTitle) {
    let obj = {
      gameName: '',
      money: 0
    }
    for (let i = 0; i < showTitle.length; i++) {
      let data = showTitle[i]
      if (!data.fun) {
        obj.gameName = data.Field
      } else if (data.fun.nameData) {
        if (this[data.fun.fun]) {
          obj.gameName = this[data.fun.fun](data.fun.nameData, tempData[data.fun.parameter])
        } else {
          obj.gameName = helpers[data.fun.fun](data.fun.nameData, tempData[data.fun.parameter], tempData)
        }
      } else {
        if (data.fun.parameter.length > 1) {
          obj.money = this[data.fun.fun](tempData, data.fun.parameter)
        } else {
          obj.money = utilsTotal[data.fun.fun](tempData[data.Field], 2)
        }
      }
    }
    return obj
  }

  static getDataList(tempData, dataObj) {
    let arr = []
    for (let j in dataObj) {
      let obj = dataObj[j]
      let newObj: any = {}
      newObj.text = obj.text
      if (obj.fun) {
        let funObj = obj.fun
        let funKey = funObj.fun
        if (funObj.nameData) {
          if (this[funKey]) {
            newObj.value = this[funKey](tempData, funObj.nameData)
          } else {
            let parameters = this.getParameter(funObj.parameter, tempData)
            let key = funObj.nameData
            newObj.value = helpers[funKey](key, parameters, tempData)
          }
        } else {
          if (this[funKey]) {
            if (funObj.parameter.length === 2) {
              newObj.value = this[funKey](tempData, funObj.parameter)
            } else {
              newObj.value = this[funKey](tempData, obj.Field)
            }
          } else {
            const type = funKey === 'dateFormat' ? undefined : 2
            newObj.value = utilsTotal[funKey](tempData[obj.Field], type)
            if (funKey === 'moneyToFloat') newObj.value = newObj.value + mb_t('元')
          }
        }
      } else {
        newObj.value = tempData[obj.Field]
      }
      // 可复制参数选项
      obj.copy && (newObj.copy = true)
      arr.push(newObj)
    }
    return arr
  }

  static getParameter(arr, tempData) {
    let arr1 = []
    arr1 = arr.map(item => tempData[item])
    return arr1
  }

  static addition(data, arr) {
    return utilsTotal.moneyToFloat(Number(data[arr[0]]) + Number(data[arr[1]]))
  }

  static winOrLose(data, key) {
    return (Number(data[key]) === 0) ? '输' : (Number(data[key]) === 1) ? '赢' : '和'
  }

  // static getBLGameRoom (data, key) {
  // 	return helpers.getBLGameRoom(data.game_code, data.game_code === 'slot' ? data.scene : data.scene_id)
  // }

  static subtraction(data, arr) {
    return utilsTotal.moneyToFloat(Number(data[arr[0]]) - Number(data[arr[1]]))
  }

  static getXYBYGameName(data, key) {
    return Number(key) === 105 ? 'BG捕鱼大师' : '西游捕鱼'
  }

  static getBTITYGameName(key, data) {
    let id = data[0].branchId
    return helpers.getGameName(key, id)
  }

  static SBTYRelated(data, key) {
    return data.selectionList[0][key]
  }

  static getBTITYStatus(data, key) {
    let status = data.selectionList[0][key]
    let statusStr = ''
    status = status.toLocaleLowerCase()
    switch (status) {
      case 'waiting':
        statusStr = '未受理'
        break
      case 'running':
        statusStr = '未结算'
        break
      case 'void':
        statusStr = '已作废'
        break
      case 'refund':
        statusStr = '已退款'
        break
      case 'reject':
        statusStr = '已取消'
        break
      case 'lost':
        statusStr = '输'
        break
      case 'won':
        statusStr = '赢'
        break
      case 'draw':
        statusStr = '未输赢'
        break
      case 'half won':
        statusStr = '赢一半'
        break
      case 'half lost':
        statusStr = '输一半'
        break
      default:
    }
    return statusStr
  }

  static BBINDZResult(data, key) {
    return data.result === '-1' ? '注销' : data.result === '0' ? '未结算' : '已结算'
  }
}
