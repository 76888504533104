/* eslint-disable */
import type { App } from 'vue';
import { showFailToast, showSuccessToast, showToast, showLoadingToast, closeToast, showConfirmDialog } from 'vant';
import useClipboard from 'vue-clipboard3';
const { toClipboard } = useClipboard();
import RNEvent from '/@/kernel-mn/utils/RNEvent';
import { useTenantStore } from '/@/kernel-mn/stores/tenant';
import { useHot } from '/@/kernel-mn/stores/useHot';
import router from '/@/router';
import { getSession, setSession } from '/@/kernel-mn/utils/storage';
import config from '/@/kernel-mn/config';
import { playIsXY } from '/@/kernel-mn/helpers/helperPlay';
import ElotType from '/@/kernel-mn/config/ElotType';
import { useGameStore } from '/@/kernel-mn/stores/game';
import i18n, { $t2en } from '/@/languages';
import Loop from '/@/kernel-mn/utils/Loop';
import { isApp } from "/@/kernel-mn/utils";
import { G_goLogin } from "/@/hooks/common";

// 本项目金币前面的金币字符
export const BL = () => {
  return '¥';
};
export const mb_t = (srt: any, arg2?, arg3?) => {
  if (typeof srt !== 'string') {
    return srt;
  }
  return i18n.global.t(srt, arg2, arg3);
};
// 0没有图标的弹窗 1成功弹窗 2错误弹窗
export const mb_toast = (str: string, type = 0) => {
  if (type === 0) {
    showToast(str);
  } else if (type === 1) {
    showSuccessToast(str);
  } else {
    showFailToast(str);
  }
};
// 确认弹窗 confirm-error
export const mb_confirm = (message = '', opt = {}, className = '') => {
  return showConfirmDialog({
    className: 'confirm-box ' + className,
    showCancelButton: true,
    confirmButtonText: mb_t('确认'),
    cancelButtonText: mb_t('取消'),
    message,
    ...opt,
  });
}
// 加载动画
export const mb_loading: any = (opt = {}) => {
  let opts: any = {
    message: '加载中...',
    icon: '/images/loading.gif',
    iconSize: '100px',
    forbidClick: false,
    loadingType: 'circular',
    duration: 0,
    ...opt
  };
  mb_loading.clear = closeToast;
  return showLoadingToast(opts);
}

// 统一弹窗翻译处理 0 没有图标的弹窗 1成功弹窗 2错误弹窗
export const mb_err = (str: any, type = 0) => {
  if (str === undefined) return;
  if (typeof str !== 'string' && typeof str === 'object') {
    str = String(str);
  }
  if (str.includes('可用资产小于')) {
    let newstr = '可用资产小于';
    let endstr = str.replace(newstr, '');
    return mb_toast(i18n.global.t(newstr) + endstr, type);
  }
  if (str.includes('同一个银行卡绑定人数过多')) {
    return mb_toast(i18n.global.t('同一个银行卡绑定人数过多'), type);
  }
  if (str.includes('同一个姓名绑定人数过多')) {
    return mb_toast(i18n.global.t('同一个姓名绑定人数过多'), type);
  }
  if (/当前(.+)账号已被绑定/.test(str)) {
    let s = /当前(.+)账号已被绑定(!|！)?/;
    let _s = str.replace(s, '$1');
    return mb_toast(i18n.global.t('当前{k}账号已被绑定', { k: _s }), type);
  }
  if (/温馨提示：充值金额不能大于\d+金币/.test(str)) {
    return mb_toast(i18n.global.t('温馨提示：充值金额不能大于{n}金币', { n: str.replace(/[^0-9]/gi, '') }), type);
  }
  if (i18n.global.te(str) || i18n.global.locale === 'cn') {
    return mb_toast(i18n.global.t(str), type);
  } else {
    // 这里调用后端接口
    const tenantStore = useTenantStore();
    // 元替换成金币
    str = str.replace('元', '金币');
    tenantStore.translationText({
      text: [str],
      language: i18n.global.locale === 'zh' ? 'zh-TW' : i18n.global.locale
    }).then(res => {
      let strs = res[0] || str;
      mb_toast(strs, type);
    }).catch(err => {
      mb_toast(err.info || str, 2);
    });
  }
};

export const mb_handleError = err => {
  const router = window['isRouteType'];
  const route = router.currentRoute.value;
  if (err && err.logout && route.path !== '/login') {
    let frompath = route.fullPath.slice(1);
    if (frompath.length > 0) {
      G_goLogin({ path: '/login' });
    } else {
      G_goLogin({ path: '/login' });
    }
  }

  if (route.path !== '/chatroom') {
    mb_toast(mb_t('请登录'));
  }
};

// 绑定提现 - 返回页面
export const withdrawBackPage = () => {
  router.go(-1)
  // const { from, cut } = router.currentRoute.value.query;
  // const path: any = from || 'walletManagement';
  // let query = {};
  // if (cut) query = { cut };
  // router.push({
  //   path,
  //   query
  // });
};

//复制
export const mb_copy = async text => {
  try {
    await toClipboard(text);
    mb_toast(mb_t('复制成功'));
  } catch (e) {
    //复制失败
    mb_toast(mb_t('复制失败'));
  }
};
export const mb_RNEvent = RNEvent.getInstance();
// 后台返回的主题名
export const tResLoc = () => useHot().tResLoc;
// 设置主题
export const settResLoc = (theme = '') => {
  const tenantStore = useTenantStore();
  tenantStore.setTheme(theme);
};
// 获取租户关系 字符串 如 7-5
export const cidAndTid = () => {
  let { hotData: { tenantInfo } } = useHot();
  let locid = getSession('CIDTID'); //  获取临时关系 测试环境用的
  return locid || tenantInfo.value.clusters_id + '-' + tenantInfo.value.tid;
}

// app跳转链接 type app跳转类型 isajaxurl是否为Ajax返回的url 如果是 则显示跳转弹窗
export const mb_APPJump = (url, type = '', isajaxurl = false, title = '') => {
  if (isajaxurl) {
    if (isApp()) {
      mb_RNEvent.sendNativeMsg('url:' + url, type)
    } else {
      showConfirmDialog({
        title: title || mb_t('进入第三方游戏'),
        message: `
          <a href="${url}" class="van-button" target="_blank">${mb_t('确定')}</a>
        `,
        className: 'go-thirst-dialog',
        showConfirmButton: false,
        showCancelButton: false,
        allowHtml: true,
        closeOnPopstate: true,
        closeOnClickOverlay: true,
      })
    }
  } else {
    mb_RNEvent.sendNativeMsg('url:' + url, type)
  }
}

// 外部跳转链接 统一到jumpurl页面使用iframe 打开 '/jumpurl?url=' + url 或者 url存session中 前者权重最高
export const jumpUrl = (url, saveUrl = true) => {
  // if (saveUrl) {
  setSession('jumpurl', url);
  router.push('/jumpurl');
  // } else {
  //   router.push('/jumpurl?url=' + url);
  // }
};
export const myloop = Loop.getInstance();

/**
 * 增加类型，防止报错
 */
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    mb_t: typeof mb_t;
    mb_handleError: typeof mb_handleError;
    mb_RNEvent: typeof mb_RNEvent;
    mb_cidAndTid: typeof cidAndTid;
    // tResLoc().value
    THEME: string;
    // cidAndTid
    CIDTID: string;
    // i18n.global.locale
    locale: string;
    // 币种符号
    BL: typeof BL;
  }
}

//todo  待换成彩票相关的查询
export const PLAY_GAME_MAP = {
  HASH_LUCKY: {
    showname: '幸运哈希',
    id: 1
  },
  HASH_NORMALNIUNIU: {
    showname: '平倍牛牛',
    id: 18
  },
  HASH_NIUNIU5: {
    showname: '五倍牛牛',
    id: 19
  },
  HASH_LUCKNUMBER: {
    showname: '幸运数字',
    id: 20
  },
  HASH_PK10: {
    showname: '哈希PK10',
    id: 21
  },
  HASH_ZHUANGXIAN: {
    showname: '哈希庄闲',
    id: 22
  }
};

// 指定功能是否开启
export const tr = rightId => {
  const { hotData } = useHot();
  const { tenantInfo } = hotData;
  const rightIdDict = tenantInfo.value.rightIdDict || {};
  return !rightIdDict[rightId];
};

export const lotteryImg = item => {
  const gameStore = useGameStore();
  if (item.isOriginal) {
    return '';
  }
  let id = item.play_id || item.playid || item.playId || item.id;
  let sId = item.subplayid || item.spaly_id || item.sub_play_id;
  let play = config.getPlayInfo(id);
  const isXY = play ? playIsXY(play, sId) : false;
  let obj = gameStore.allLotteryData[id + ((isXY || item.play_cat === 4) && ElotType['SAMEID'].plays.indexOf(Number(id)) !== -1 ? '_' : '')];
  return obj ? obj.img_url : '';
};
// 彩票图片是否需要先本地图片 彩种列表
const LOCAL_GAME_IMG = (item = { base: '', play_id: 0 }) => {
  const LOCAL_PATH = `/images/${tResLoc().value}/lotterypc28/`
  const Local_base = []
  const Local_payid = [41, 302, 387, 389, 1400, 1401];
  // 含有base的彩种元素
  if (Local_base.includes(item.base)) {
    return LOCAL_PATH + item.base + '.png';
  }
  if (Local_payid.includes(item.play_id)) {
    return LOCAL_PATH + item.play_id + '.png';
  }
}
// 处理彩票图片
export const exlotteryimg = (element) => {
  const { imgDomain } = useTenantStore();
  let imgurl = LOCAL_GAME_IMG(element);
  // 如果本地目录有对应的彩种图片 就直接返回
  if (imgurl) {
    return imgurl;
  }
  let lang = $t2en(i18n.global.locale)
  if (element.base) {
    if (element.play_cat === 4) {
      imgurl = imgDomain + 'staticQ/caipiao/default/' + lang + '/icon_play_' + (element.base ? element.base + '_XY' : element.base) + '.png'
    } else {
      imgurl = imgDomain + 'staticQ/caipiao/default/' + lang + '/icon_play_' + element.base + '.png'
    }
  } else {
    if (String(element.play_id).startsWith('1385')) {
      imgurl = imgDomain + 'staticQ/caipiao/1385/' + lang + '/game_' + element.play_id + '.png'
    } else {
      imgurl = imgDomain + 'staticQ/caipiao/default/' + lang + '/icon_play_' + element.play_id + '.png'
    }
  }
  return imgurl
}

export default {
  install: (app: App) => {
    app.config.globalProperties['mb_handleError'] = mb_handleError;
    app.config.globalProperties['mb_t'] = mb_t;
    app.config.globalProperties['mb_RNEvent'] = mb_RNEvent;
    app.config.globalProperties['lotteryImg'] = lotteryImg;
    app.config.globalProperties['exlotteryimg'] = exlotteryimg;
    app.config.globalProperties['mb_APPJump'] = mb_APPJump;
    app.config.globalProperties['mb_copy'] = mb_copy;
    app.config.globalProperties['mb_cidAndTid'] = cidAndTid; // 获取租户关系 如7-5
    app.config.globalProperties['BL'] = BL; // 币种符号
    app.config.globalProperties['locale'] = i18n.global.locale;
    app.config.globalProperties['transferImg'] = val => {
      const map = {
        cn: val,
        en: val + '_en',
        vi: val + '_vi',
        th: val + '_th'
      };
      return map[i18n.global.locale] || map.en;
    };
    /**
     * 全局指令
     */
    // 防止重复点击
    app.directive('preventReClick', {
      // 当被绑定的元素挂载到 DOM 中时……
      mounted(el, binding) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true;
            setTimeout(() => {
              el.disabled = false;
            }, binding.value || 3000);
          }
        });
      }
    });
    app.directive('drag', {
      mounted(el) {
        const dragBox = el; //获取当前元素
        const action = (start, move, end) => {
          dragBox[start] = event => {
            let moveCounter = 0; //鼠标移动事件触发次数
            event.preventDefault(); //禁止页面滚动
            document[move] = e => {
              if (moveCounter > 4) {
                el.setAttribute('data-click', 0);
                //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                let _el = null;
                if (start === 'onmousedown') {
                  _el = e;
                } else {
                  _el = e.changedTouches[0];
                }
                let left = _el.clientX - dragBox.offsetWidth / 2;
                let top = _el.clientY - dragBox.offsetHeight / 2;
                dragBox.setAttribute('maxleft', 2); // 在中部
                dragBox.setAttribute('maxtop', 2); // 在中部
                if (left < 0) {
                  left = 0;
                  dragBox.setAttribute('maxleft', 0); // 最左边
                }
                if (top < 0) {
                  top = 0;
                  dragBox.setAttribute('maxtop', 0); // 最顶部
                }
                if (left > document.documentElement.clientWidth - dragBox.offsetWidth) {
                  left = document.documentElement.clientWidth - dragBox.offsetWidth;
                  dragBox.setAttribute('maxleft', 1); // 最右边
                }
                if (top > document.documentElement.clientHeight - dragBox.offsetHeight) {
                  top = document.documentElement.clientHeight - dragBox.offsetHeight;
                  dragBox.setAttribute('maxtop', 1); // 最底部
                }
                dragBox.style.left = left + 'px';
                dragBox.style.top = top + 'px';
              } else {
                moveCounter++;
              }
            };
            document[end] = e => {
              //鼠标弹起来的时候不再移动
              document[move] = null;
              //预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动）
              document[end] = null;
              if (moveCounter <= 4) {
                el.setAttribute('data-click', 1);
                e.target.click();
              }
            };
          };
        };
        action('ontouchstart', 'ontouchmove', 'ontouchend');
        action('onmousedown', 'onmousemove', 'onmouseup');
      },
      updated(el) {
        const target = el.children[0];
        target.style.left = '';
        target.style.top = '';
      }
    });
  }
};
