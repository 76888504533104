export default class Loop {
  // 队列
  queue = {}

  static inst = null

  static getInstance() {
    if (this.inst == null) {
      this.inst = new Loop()
      this.inst.run()
    }
    return this.inst
  }

  addLoop(key, loopItem) {
    this.queue[key] = loopItem
  }

  // 删除循环
  removeLoop(key) {
    delete this.queue[key]
  }

  // 主循环
  run() {
    setInterval(() => {
      for (let key in this.queue) {
        const item = this.queue[key]
        if (item.runStart && item.execute) { // 第一次执行
          item.runStart = false
          item.running = true
          item.execute()
        } else { // 是否满足执行条件
          if (item.condition) {
            item.running = item.condition()
          }
          const currentTime = new Date().getTime()
          if ((currentTime - item.lastTime >= item.duration) && item.running) {
            item.lastTime = currentTime
            if (item.execute) {
              item.execute()
            }
          }
        }
      }
    }, 10)
  }
}
