// H5的团队游戏记录配置
export default {
  // 开元
  297: {
    cmdId: 313603,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'KYQiPaiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '桌子号',
        Field: 'deskNo'
      }, {
        text: '有效下注金额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '盈亏',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '开始时间',
        Field: 'startTime',
        fun: { fun: 'dateFormat' }
      }, {
        text: '结束时间',
        Field: 'endTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 乐游
  299: {
    cmdId: 313702,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'LYQiPaiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '桌子号',
        Field: 'deskNo'
      }, {
        text: '总下注',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效下注金额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注时间',
        Field: 'startTime',
        fun: { fun: 'dateFormat' }
      }, {
        text: '结束时间',
        Field: 'endTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // DB棋牌
  1383: {
    cmdId: 313603,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'DBQiPaiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '桌子号',
        Field: 'deskNo'
      }, {
        text: '有效下注金额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '盈亏',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '开始时间',
        Field: 'startTime',
        fun: { fun: 'dateFormat' }
      }, {
        text: '结束时间',
        Field: 'endTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // DB彩票
  1385: {
    cmdId: 317103,
    showTitle: [
      {
        Field: 'DB彩票'
      },
      {
        Field: 'winlost_amount',
        fun: { fun: 'moneyToFloat', parameter: ['winlost_amount'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'betMoney',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'betPrize',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'betTime	',
        fun: { fun: 'dateFormat' }
      }
    ]
  },

  // 大富翁
  380: {
    cmdId: 314403,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'DFWQiPaiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '游戏房间',
      // 	Field: 'roomid',
      // 	fun: { nameData: 'DFWQiPaiGameRooms', fun: 'getGameRoom', parameter: ['roomid'] }
      // },
      // {
      // 	text: '局号',
      // 	Field: 'serialId',
      // 	copy: true
      // },
      {
        text: '桌子号',
        Field: 'deskNo'
      }, {
        text: '总下注',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效下注金额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注时间',
        Field: 'startTime',
        fun: { fun: 'dateFormat' }
      }, {
        text: '结束时间',
        Field: 'endTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 财神
  382: {
    cmdId: 314603,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'CSQiPaiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'addition', parameter: ['money', 'servicemoney'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'id',
      // 	copy: true
      // },
      // {
      // 	text: '局号',
      // 	Field: 'serialId'
      // },
      {
        text: '房间编号',
        Field: 'roomid'
      }, {
        text: '抽水',
        Field: 'servicemoney',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        // },{
        // 	text: '中奖结果',
        // 	Field: 'jack_pot',
        // 	fun: { fun: 'winOrLose' }
        // },{
        text: '开始时间',
        Field: 'startTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 欢乐
  388: {
    cmdId: 313928,
    showTitle: [
      {
        Field: 'gametype',
        fun: { nameData: 'HLQiPaiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '玩家对局号',
        Field: 'gameNumber',
        copy: true
      }, {
        text: '房间名称',
        Field: 'arenaName'
      }, {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '结束时间',
        Field: 'endTime',
        fun: { fun: 'dateFormat' }
      }, {
        text: '有效投注金额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '盈利',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '开始时间',
        Field: 'startTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // SBO利记体育
  570: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'HGTiYuGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // Sexy体育
  750: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'SEXYSportsNames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '游戏',
        Field: 'sportName',
        fun: { nameData: 'SEXYSportsNames', fun: 'getGameName', parameter: ['sportName'] }
      },
      {
        text: '投注金额',
        Field: 'stake',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'payoutAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'betTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // TRC赛马
  925: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'betType',
        fun: { nameData: 'TRCBetTypes', fun: 'getGameName', parameter: 'betType' }
      },
      {
        Field: 'realWinLost',
        fun: { fun: 'moneyToFloat', parameter: ['realWinLost'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注类型',
        Field: 'betType',
        fun: { nameData: 'TRCBetTypes', fun: 'getGameName', parameter: ['betType'] }
      },
      {
        text: '投注金额',
        Field: 'betAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'realWinLost',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'betTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // EA板球
  930: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'event_type_id',
        fun: { nameData: 'EABQGameCode', fun: 'getGameName', parameter: 'event_type_id' }
      },
      {
        Field: 'profit',
        fun: { fun: 'moneyToFloat', parameter: ['profit'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'trade_amount',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '有效投注金额',
        Field: 'valid_trade'
      },
      {
        text: '输赢',
        Field: 'profit',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // IM体育
  931: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'event_type_id',
        fun: { nameData: 'IMTYGameCode', fun: 'getIMTYGameName', parameter: 'event_type_id' }
      },
      {
        Field: 'profit',
        fun: { fun: 'moneyToFloat', parameter: ['profit'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'wagerno'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'bet_amount',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'profit',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'transaction_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // IM电竞
  981: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'event_type_id',
        fun: { nameData: 'IMDJGameCode', fun: 'getIMDJGameName', parameter: 'event_type_id' }
      },
      {
        Field: 'winloss',
        fun: { fun: 'moneyToFloat', parameter: ['winloss'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'betid'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'stakeamount',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'winloss',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'wagercreationdatetime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // DB电竞
  1387: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'event_type_id',
        fun: { nameData: 'DBDJGameCode', fun: 'getDBDJGameName', parameter: 'event_type_id' }
      },
      {
        Field: 'winloss',
        fun: { fun: 'moneyToFloat', parameter: ['winloss'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'betid'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'stakeamount',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'winloss',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'wagercreationdatetime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 开元体育
  1031: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'sportType',
        fun: { nameData: 'KYTYGameCode', fun: 'getGameName', parameter: 'sportType' }
      },
      {
        Field: 'winlost',
        fun: { fun: 'moneyToFloat', parameter: ['winlost'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'turnover',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'winlost',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'create_date',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // FB体育
  1308: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'sportId',
        fun: { nameData: 'FBTYGameCode', fun: 'getGameName', parameter: 'sportId' }
      },
      {
        Field: 'winlost',
        fun: { fun: 'moneyToFloat', parameter: ['winlost'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'orderId'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'stakeAmount',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'winlost',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'modiFyTime'
      }
    ]
  },
  // JOKER电子
  1305: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameCode',
        fun: { nameData: 'JOKERGameCode', fun: 'getGameName', parameter: 'gameCode' }
      },
      {
        Field: 'result',
        fun: { fun: 'moneyToFloat', parameter: ['result'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'amount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'result',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gameCode',
        fun: { nameData: 'JOKERGameCode', fun: 'getGameName', parameter: ['gameCode'] }
      }, {
        text: '投注时间',
        Field: 'time'
      }
    ]
  },
  // 完美真人
  1309: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'gid',
        fun: { nameData: 'WMZRGameCode', fun: 'getGameName', parameter: 'gid' }
      },
      {
        Field: 'winLoss',
        fun: { fun: 'moneyToFloat', parameter: ['winLoss'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'validbet',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'winLoss',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'betTime'
      }
    ]
  },
  // AESEXY真人
  1312: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'gameCode',
        fun: { nameData: 'AESEXYGameCode', fun: 'getGameName', parameter: 'gameCode' }
      },
      {
        Field: 'realWinLost',
        fun: { fun: 'moneyToFloat', parameter: ['realWinLost'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'realBetAmount',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'realWinLost',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'betTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // PRETTY真人
  1313: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'type',
        fun: { nameData: 'PRETTYGameCode', fun: 'getGameName', parameter: 'type' }
      },
      {
        Field: 'winLose',
        fun: { fun: 'moneyToFloat', parameter: ['winLose'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'validAmt',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'winLose',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'createDate',
      }
    ]
  },
  // AFB真人
  1314: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'gametype',
        fun: { nameData: 'AFBSXGameCode', fun: 'getGameName', parameter: 'gametype' }
      },
      {
        Field: 'winloss',
        fun: { fun: 'moneyToFloat', parameter: ['winloss'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'validstake',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'winloss',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // SAGaming视讯
  1317: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'gametype',
        fun: { nameData: 'SAGSXGameCode', fun: 'getGameName', parameter: 'gametype' }
      },
      {
        Field: 'resultamount',
        fun: { fun: 'moneyToFloat', parameter: ['resultamount'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'rolling',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'resultamount',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'bettime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // AFB电子
  1315: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameCode',
        fun: { nameData: 'AFBDZGameCode', fun: 'getGameName', parameter: 'gameCode' }
      },
      {
        Field: 'win',
        fun: { fun: 'moneyToFloat', parameter: ['win'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'bet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'win',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gameCode',
        fun: { nameData: 'AFBDZGameCode', fun: 'getGameName', parameter: ['gameCode'] }
      }, {
        text: '投注时间',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // AFB体育
  1316: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'sportstype',
        fun: { nameData: 'AFBTYGameCode', fun: 'getGameName', parameter: 'sportstype' }
      },
      {
        Field: 'w',
        fun: { fun: 'moneyToFloat', parameter: ['w'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'validamt',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'w',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // DB体育
  1389: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'sportstype',
        fun: { nameData: 'AFBTYGameCode', fun: 'getGameName', parameter: 'sportstype' }
      },
      {
        Field: 'w',
        fun: { fun: 'moneyToFloat', parameter: ['w'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'validamt',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'w',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // PGSOFT电子
  1318: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameCode',
        fun: { nameData: 'PGSOFTDZGameCode', fun: 'getGameName', parameter: 'gameCode' }
      },
      {
        Field: 'payout',
        fun: { fun: 'moneyToFloat', parameter: ['payout'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'stake',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'payout',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gameCode',
        fun: { nameData: 'PGSOFTDZGameCode', fun: 'getGameName', parameter: ['gameCode'] }
      }, {
        text: '投注时间',
        Field: 'createTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // EVOPLAY电子
  1319: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameId',
        fun: { nameData: 'EVOPLAYDZGameCode', fun: 'getGameName', parameter: 'gameId' }
      },
      {
        Field: 'winLoss',
        fun: { fun: 'moneyToFloat', parameter: ['winLoss'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'bet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'winLoss',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gameId',
        fun: { nameData: 'EVOPLAYDZGameCode', fun: 'getGameName', parameter: ['gameId'] }
      }, {
        text: '投注时间',
        Field: 'betTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // RSG电子
  1306: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'RSGDZGameCode', fun: 'getGameName', parameter: 'gameId' }
      },
      {
        Field: 'netWinSum',
        fun: { fun: 'moneyToFloat', parameter: ['netWinSum'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'betSum',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'netWinSum',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gameType',
        fun: { nameData: 'RSGDZGameCode', fun: 'getGameName', parameter: ['gameId'] }
      }, {
        text: '投注时间',
        Field: 'createDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // TP电子
  1320: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameid',
        fun: { nameData: 'TPDZGameCode', fun: 'getGameName', parameter: 'gameid' }
      },
      {
        Field: 'betresult',
        fun: { fun: 'moneyToFloat', parameter: ['betresult'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'betresult',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gameid',
        fun: { nameData: 'TPDZGameCode', fun: 'getGameName', parameter: ['gameid'] }
      }, {
        text: '投注时间',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // PP真人
  1322: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'gameID',
        fun: { nameData: 'PPSXGameCode', fun: 'getGameName', parameter: 'gameID' }
      },
      {
        Field: 'profit',
        fun: { fun: 'moneyToFloat', parameter: ['profit'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'bet',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'profit',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'startDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // KA电子
  1321: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gamename',
        fun: { nameData: 'KADZGameCode', fun: 'getGameName', parameter: 'gamename' }
      },
      {
        Field: 'cashwon',
        fun: { fun: 'moneyToFloat', parameter: ['cashwon'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'cashplayed',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'cashwon',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gamename',
        fun: { nameData: 'KADZGameCode', fun: 'getGameName', parameter: ['gamename'] }
      }, {
        text: '投注时间',
        Field: 'spindate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // NETENT电子
  1323: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameCode',
        fun: { nameData: 'NEDZGameCode', fun: 'getGameName', parameter: 'gameCode' }
      },
      {
        Field: 'payout',
        fun: { fun: 'moneyToFloat', parameter: ['payout'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'stake',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'payout',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gameCode',
        fun: { nameData: 'NETDZGameCode', fun: 'getGameName', parameter: ['gameCode'] }
      }, {
        text: '投注时间',
        Field: 'createTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // FC电子
  1324: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameID',
        fun: { nameData: 'FCDZGameCode', fun: 'getGameName', parameter: 'gameID' }
      },
      {
        Field: 'winlose',
        fun: { fun: 'moneyToFloat', parameter: ['winlose'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'bet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'winlose',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gameID',
        fun: { nameData: 'FCDZGameCode', fun: 'getGameName', parameter: ['gameID'] }
      }, {
        text: '投注时间',
        Field: 'bdate'
      }
    ]
  },
  // SG电子
  1325: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameCode',
        fun: { nameData: 'SGDZGameCode', fun: 'getGameName', parameter: 'gameCode' }
      },
      {
        Field: 'winLoss',
        fun: { fun: 'moneyToFloat', parameter: ['winLoss'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'betAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'winLoss',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gameCode',
        fun: { nameData: 'SGDZGameCode', fun: 'getGameName', parameter: ['gameCode'] }
      }, {
        text: '投注时间',
        Field: 'ticketTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },

  // 沙巴彩票
  1326: {
    cmdId: 317103,
    showTitle: [
      {
        Field: '沙巴彩票'
      },
      {
        Field: 'winlost_amount',
        fun: { fun: 'moneyToFloat', parameter: ['winlost_amount'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'stake',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'winlost_amount',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'transaction_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // MT电子
  983: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameCode',
        fun: { nameData: 'MTDZGameCode', fun: 'getGameName', parameter: 'gameCode' }
      },
      {
        Field: 'winAmount',
        fun: { fun: 'moneyToFloat', parameter: ['result'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'betAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'winAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gameCode',
        fun: { nameData: 'MTDZGameCode', fun: 'getGameName', parameter: ['gameCode'] }
      }, {
        text: '投注时间',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // NEXTSPIN电子
  1311: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameCode',
        fun: { nameData: 'NEXTSPINGameCode', fun: 'getGameName', parameter: 'gameCode' }
      },
      {
        Field: 'winLoss',
        fun: { fun: 'moneyToFloat', parameter: ['winLoss'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'betAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'winLoss',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gameCode',
        fun: { nameData: 'NEXTSPINGameCode', fun: 'getGameName', parameter: ['gameCode'] }
      }, {
        text: '投注时间',
        Field: 'ticketTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 天游棋牌
  393: {
    cmdId: 314703,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'THQiPaiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '局号',
      // 	Field: 'serialId',
      // 	copy: true
      // },
      {
        text: '游戏',
        Field: 'gameType',
        fun: { nameData: 'THQiPaiGames', fun: 'getGameName', parameter: ['gameType'] }
      },
      // {
      // 	text: '房间类型',
      // 	Field: 'room_level',
      // 	fun: { nameData: 'THQiPaiGames', fun: 'getTHQiPaiGameRoom', parameter: ['room_level'] }
      // },
      {
        text: '总下注',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效下注金额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '盈亏',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '初始金额',
        Field: 'beforebalance',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '结束金额',
        Field: 'end_money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏时间',
        Field: 'endTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 天美棋牌
  391: {
    cmdId: 314922,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'TMQiPaiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '游戏房间',
      // 	Field: 'roomid',
      // 	fun: { nameData: 'TMQiPaiGameRooms', fun: 'getGameRoom', parameter: ['roomid'] }
      // },
      // {
      // 	text: '局号',
      // 	Field: 'serialId',
      // 	copy: true
      // },
      {
        text: '有效投注额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '盈亏',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: 'jackpot',
        Field: 'servicemoney',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏时间',
        Field: 'endTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 博乐棋牌
  394: {
    cmdId: 314707,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'BLQiPaiGames', fun: 'getGameName', parameter: 'gameName' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '游戏房间',
      // 	Field: 'serverID',
      // 	fun: { nameData: 'BLQiPaiGameRooms', fun: 'getBLGameRoom', parameter: ['gameName', 'roomid'] }
      // },
      // {
      // 	text: '战绩ID',
      // 	Field: 'report_id',
      // 	copy: true
      // },
      {
        text: '总下注',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效下注金额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '盈亏',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏时间',
        Field: 'endTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 天境棋牌
  398: {
    cmdId: 317033,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'TJQiPaiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '游戏房间',
      // 	Field: 'roomType',
      // 	fun: { nameData: 'TJQiPaiGameRooms', fun: 'getGameRoom', parameter: ['roomid'] }
      // },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      // {
      // 	text: '局号',
      // 	Field: 'serialId'
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏时间',
        Field: 'startTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 王者棋牌
  745: {
    cmdId: 318010,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'WZQiPaiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'betAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'winAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'createTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 大唐棋牌
  746: {
    cmdId: 318010,
    showTitle: [
      {
        Field: 'gameId',
        fun: { nameData: 'DTQPGameCode', fun: 'getGameName', parameter: 'gameId' }
      },
      {
        Field: 'profit',
        fun: { fun: 'moneyToFloat', parameter: ['profit'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'validBet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'profit',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'gameStartTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 365棋牌
  927: {
    cmdId: 318010,
    showTitle: [
      {
        Field: 'gameId',
        fun: { nameData: 'QiPai365Games', fun: 'getGameName', parameter: 'gameId' }
      },
      {
        Field: 'profit',
        fun: { fun: 'moneyToFloat', parameter: ['profit'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'validbet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'profit',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // JILI棋牌
  928: {
    cmdId: 318010,
    showTitle: [
      {
        Field: 'gameId',
        fun: { nameData: 'JILIQPGameCode', fun: 'getGameName', parameter: 'gameId' }
      },
      {
        Field: 'profit',
        fun: { fun: 'moneyToFloat', parameter: ['profit'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'betAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'profit',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'wagersTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 百胜棋牌
  404: {
    cmdId: 318010,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'BSQiPaiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏时间',
        Field: 'startTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 新世界
  401: {
    cmdId: 318103,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'XSJQiPaiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '局号',
      // 	Field: 'serialId',
      // 	copy: true
      // },
      // {
      // 	text: '房间类型',
      // 	Field: 'roomid',
      // 	fun: { nameData: 'XSJQiPaiGameRooms', fun: 'getGameRoom', parameter: ['roomid'] }
      // },
      {
        text: '总投注',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'startTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // DT电子
  405: {
    cmdId: 314113,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'DTDianZiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '中奖金额',
        Field: 'gold',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'createTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // CQ9电子
  403: {
    cmdId: 318007,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'CQ9DianZiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'subtraction', parameter: ['money', 'betamount'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'subtraction', parameter: ['money', 'betamount'] }
      }, {
        text: '投注时间',
        Field: 'endTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // PG电子
  571: {
    cmdId: 314203,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'PGGames', fun: 'getGameName', parameter: 'gameId' }
      },
      {
        Field: 'winLost',
        fun: { fun: 'moneyToFloat', parameter: [] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'betId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'winLost',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注时间',
        Field: 'betEndTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // PG新电子
  1380: {
    cmdId: 314203,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'PGGames', fun: 'getGameName', parameter: 'gameId' }
      },
      {
        Field: 'winLost',
        fun: { fun: 'moneyToFloat', parameter: [] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'betId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'winLost',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注时间',
        Field: 'betTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // UPG电子
  593: {
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'UPGGameCode', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: [] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '下注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注金额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注时间',
        Field: 'endTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // MG电子
  343: {
    cmdId: 314203,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'MGGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '下注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '派彩金额',
        Field: 'gold',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注时间',
        Field: 'startTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // MG捕鱼
  396: {
    cmdId: 314205,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'MGGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '下注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '派彩金额',
        Field: 'gold',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注时间',
        Field: 'startTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // MG视讯
  412: {
    cmdId: 314803,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'MGGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '下注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '派彩金额',
        Field: 'gold',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 天游捕鱼
  399: {
    cmdId: 314721,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'THQiPaiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '游戏房间',
      // 	Field: 'id',
      // 	fun: { nameData: 'THQiPaiGameRooms', fun: 'getGameRoom', parameter: ['room_level'] }
      // },
      // {
      // 	text: '局号',
      // 	Field: 'serialId',
      // 	copy: true
      // },
      {
        text: '总下注',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效下注金额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '盈亏',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏时间',
        Field: 'endTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 百胜捕鱼
  407: {
    cmdId: 318011,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'BSQiPaiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '局号',
      // 	Field: 'serialId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏时间',
        Field: 'startTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 大唐捕鱼
  747: {
    cmdId: 318011,
    showTitle: [
      {
        Field: 'gameId',
        fun: { nameData: 'DTBYGameCode', fun: 'getGameName', parameter: 'gameId' }
      },
      {
        Field: 'profit',
        fun: { fun: 'moneyToFloat', parameter: ['profit'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '局号',
      // 	Field: 'serialId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'allBe',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注额',
        Field: 'validBet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'profit',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏时间',
        Field: 'gameStartTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // CQ9捕鱼
  408: {
    cmdId: 318007,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'CQ9DianZiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'win',
        fun: { fun: 'subtraction', parameter: ['money', 'betamount'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'subtraction', parameter: ['money', 'betamount'] }
      }, {
        text: '投注时间',
        Field: 'endTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 皇冠体育
  409: {
    cmdId: 319103,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'HGTiYuGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // JDB电子
  340: {
    cmdId: 313903,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'JDBDianZiGameNames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '游戏类型',
      // 	Field: 'gameType',
      // 	fun: { nameData: 'JDBDianZiGameNames', fun: 'getGameName', parameter: ['gameType'] }
      // },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '押注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏日期',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // JDB捕鱼
  397: {
    cmdId: 313905,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'JDBDianZiGameNames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '游戏类型',
      // 	Field: 'gameType',
      // 	fun: { nameData: 'JDBDianZiGameNames', fun: 'getGameName', parameter: ['gameType'] }
      // },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '押注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏日期',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // AG视讯
  60: {
    cmdId: 313304,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'AGSXGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '单号',
        Field: 'orderId',
        copy: true
      },
      // {
      // 	text: '玩法类型',
      // 	Field: 'play_type',
      // 	fun: { nameData: 'AGShiXunGames', fun: 'getGameName', parameter: ['play_type'] }
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注金额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注盈亏',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'betTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // BBIN视讯
  345: {
    cmdId: 313908,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'BBINShiXunGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      // {
      // 	text: '局号',
      // 	Field: 'serialId'
      // },
      {
        text: '桌号',
        Field: 'deskNo'
      }, {
        text: '场次',
        Field: 'roundNo'
      },
      // {
      // 	text: '下注类型',
      // 	Field: 'orderId',
      // 	fun: { nameData: 'BBINShiXunGames', fun: 'getBBINBetDetail', parameter: ['gameType', 'wagerDetail'] }
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注金额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '派彩金额',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      },
      // {
      // 	text: '结果',
      // 	Field: 'orderId',
      // 	fun: { nameData: 'BBINShiXunGames', fun: 'getBBINGameResult', parameter: ['gameType', 'gameresult', 'card'] }
      // },
      {
        text: '投注时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // DG视讯
  400: {
    cmdId: 318003,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'DGShiXunGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'winOrLoss',
        fun: { fun: 'subtraction', parameter: ['money', 'betamount'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'total',
        fun: { fun: 'subtraction', parameter: ['money', 'betamount'] }
      }, {
        text: '游戏日期',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // BG视讯
  402: {
    cmdId: 314023,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'BGShiXunGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏日期',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 沙巴体育
  342: {
    cmdId: 314103,
    showTitle: [
      {
        Field: 'selectionList',
        fun: { nameData: 'SBTGameNames', fun: 'getSBTGameName', parameter: '' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '游戏玩法',
        Field: 'betSettledDate',
        fun: { nameData: 'SBTGameNames', fun: 'getSBTGameName', parameter: [''] }
      }, {
        text: '押注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注状态',
        Field: 'totalStake',
        fun: { nameData: 'SBTGameNames', fun: 'getSBTGameStatus', parameter: [''] }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注时间',
        Field: 'betTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // BTI体育
  395: {
    cmdId: 314711,
    showTitle: [
      {
        Field: 'selectionList',
        fun: { nameData: 'BTITiYuGames', fun: 'getBTITYGameName', parameter: 'selectionList' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }, {
        text: '赛事名称',
        Field: 'leagueName',
        fun: { fun: 'SBTYRelated', parameter: ['leagueName'] }
      }, {
        text: '投注',
        Field: 'yourBet',
        fun: { fun: 'SBTYRelated', parameter: ['yourBet'] }
      }, {
        text: '投注额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '状态',
        Field: 'status',
        fun: { fun: 'getBTITYStatus', parameter: ['status'] }
      }
    ]
  },
  // AG捕鱼
  381: {
    cmdId: 313305,
    showTitle: [
      {
        Field: 'AG捕鱼'
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '场景',
      // 	Field: 'scene_id',
      // 	copy: true
      // },
      {
        text: '房间号',
        Field: 'roomid'
      }, {
        text: '鱼价值',
        Field: 'earn',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '子弹价值',
        Field: 'cost',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '盈亏',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }
    ]
  },
  // bg捕鱼
  383: {
    cmdId: 313923,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'BGShiXunGames', fun: 'getXYBYGameName', parameter: ['gameType'] }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      // {
      // 	text: '局号',
      // 	Field: 'serialId'
      // },
      {
        text: '发炮数量',
        Field: 'bullet_num'
      }, {
        text: '押注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: 'JP中奖',
        Field: 'net_amount_bonus',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: 'JP预抽',
        Field: 'extend',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注后结余',
        Field: 'gameBalance',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '开始时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // FG捕鱼
  708: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'game_id',
        fun: { nameData: 'FGBYGameNames', fun: 'getGameName', parameter: ['game_id'] }
      },
      {
        Field: 'result',
        fun: { fun: 'moneyToFloat', parameter: ['result'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, /*, {
				text: '局号',
				Field: 'serialId'
			} */ {
        text: '发炮数量',
        Field: 'bullet_count'
      }, {
        text: '押注金额',
        Field: 'all_bets',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '中奖',
        Field: 'all_wins',
        fun: { fun: 'moneyToFloat' }
      }, /* {
				text: 'JP预抽',
				Field: 'extend',
				fun: { fun: 'moneyToFloat' }
			} */ {
        text: '开始筹码',
        Field: 'start_chips',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注后结余',
        Field: 'end_chips',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '开始时间',
        Field: 'start_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // DB捕鱼
  1388: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'game_id',
        fun: { nameData: 'FGBYGameNames', fun: 'getGameName', parameter: ['game_id'] }
      },
      {
        Field: 'result',
        fun: { fun: 'moneyToFloat', parameter: ['result'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, /*, {
				text: '局号',
				Field: 'serialId'
			} */ {
        text: '发炮数量',
        Field: 'bullet_count'
      }, {
        text: '押注金额',
        Field: 'all_bets',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '中奖',
        Field: 'all_wins',
        fun: { fun: 'moneyToFloat' }
      }, /* {
				text: 'JP预抽',
				Field: 'extend',
				fun: { fun: 'moneyToFloat' }
			} */ {
        text: '开始筹码',
        Field: 'start_chips',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注后结余',
        Field: 'end_chips',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '开始时间',
        Field: 'start_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // bg西游捕鱼
  390: {
    cmdId: 313940,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'BGShiXunGames', fun: 'getXYBYGameName', parameter: ['gameType'] }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      // {
      // 	text: '局号',
      // 	Field: 'serialId'
      // },
      {
        text: '发炮数量',
        Field: 'bullet_num'
      }, {
        text: '押注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: 'JP中奖',
        Field: 'jack_pot',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: 'JP预抽',
        Field: 'extend',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注后结余',
        Field: 'gameBalance',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '开始时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // PT电子
  414: {
    cmdId: 319203,
    showTitle: [
      {
        Field: 'gameName',
        fun: { nameData: 'PTDianZiGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '奖池贡献',
        Field: 'progressiveBet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '奖池中奖金额',
        Field: 'progressiveWin',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
      // },{
      // 	text: '游戏类型',
      // 	Field: 'gameName',
      // 	fun: { nameData: 'PTDianZiGames', fun: 'getGameName', parameter: ['gameName'] }
      // }
    ]
  },
  // acct_id: 73472
  // betAmount: 25000
  // commissionable: 24990
  // createDate: 1590232247000
  // currency: "RMB"
  // cust_id: 56468
  // cust_name: "xuzhu100"
  // exchangeRate: 1000
  // gameType: "5005"
  // id: 15
  // part_day: 144
  // payoff: -25000
  // result: "200"
  // tid: 3
  // upperName: "ddza4b3"
  // userName: "xuzhu100"
  // wagersDate: 1590232176000
  // wagersId: "507061124424"
  // BBIN电子
  413: {
    cmdId: 314307,
    showTitle: [
      {
        Field: 'gameName',
        fun: { nameData: 'BBINDZGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '下注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      },
      // , {
      // 	text: '结果',
      // 	Field: 'gameresult',
      // 	fun: { fun: 'BBINDZResult', parameter: ['gameresult'] }
      // },
      {
        text: '有效投注额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 胜博体育
  418: {
    cmdId: 319206,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'MBTiYuGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // AG电子
  415: {
    cmdId: 313308,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'AGDZGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注金额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // SimplePlay电子
  926: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'detail',
        fun: { nameData: 'SimplePlayGameCode', fun: 'getGameName', parameter: 'detail' }
      },
      {
        Field: 'resultamount',
        fun: { fun: 'moneyToFloat', parameter: ['resultamount'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'resultamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'detail',
        fun: { nameData: 'SimplePlayGameCode', fun: 'getGameName', parameter: ['detail'] }
      }, {
        text: '投注时间',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // Ameba电子
  938: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'game_id',
        fun: { nameData: 'AMEBAGameCode', fun: 'getGameName', parameter: 'game_id' }
      },
      {
        Field: 'payout_amt',
        fun: { fun: 'moneyToFloat', parameter: ['payout_amt'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'bet_amt',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'payout_amt',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'game_id',
        fun: { nameData: 'AMEBAGameCode', fun: 'getGameName', parameter: ['game_id'] }
      }, {
        text: '投注时间',
        Field: 'bet_at',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // PS电子
  939: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gid',
        fun: { nameData: 'PSDZGameCode', fun: 'getGameName', parameter: 'gid' }
      },
      {
        Field: 'win',
        fun: { fun: 'moneyToFloat', parameter: ['win'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'bet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'win',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gid',
        fun: { nameData: 'PSDZGameCode', fun: 'getGameName', parameter: ['gid'] }
      }, {
        text: '投注时间',
        Field: 's_tm',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // RT电子
  960: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameId',
        fun: { nameData: 'RTDZGameCode', fun: 'getGameName', parameter: 'gid' }
      },
      {
        Field: 'resultamount',
        fun: { fun: 'moneyToFloat', parameter: ['resultamount'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'betbmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'resultamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gid',
        fun: { nameData: 'RTDZGameCode', fun: 'getGameName', parameter: ['gid'] }
      }, {
        text: '投注时间',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // VT电子
  961: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'VTDZGameCode', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'profit',
        fun: { fun: 'moneyToFloat', parameter: ['profit'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'betMoney',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'profit',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gameType',
        fun: { nameData: 'VTDZGameCode', fun: 'getGameName', parameter: ['gameType'] }
      }, {
        text: '投注时间',
        Field: 'logTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // JILI电子
  929: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gid',
        fun: { nameData: 'JILIDZGameCode', fun: 'getGameName', parameter: 'gameId' }
      },
      {
        Field: 'profit',
        fun: { fun: 'moneyToFloat', parameter: ['profit'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'betAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'profit',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏名称',
        Field: 'gid',
        fun: { nameData: 'JILIDZGameCode', fun: 'getGameName', parameter: ['gameId'] }
      }, {
        text: '投注时间',
        Field: 'wagersTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // FG电子
  707: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'game_id',
        fun: { nameData: 'FGGameCode', fun: 'getGameName', parameter: 'game_id' }
      },
      {
        Field: 'result',
        fun: { fun: 'moneyToFloat', parameter: ['result'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'all_bets',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'all_wins',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注后结余',
        Field: 'end_chips',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // HC电子
  711: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameCode',
        fun: { nameData: 'HCGameCode', fun: 'getGameName', parameter: 'gameCode' }
      },
      {
        Field: 'netWin',
        fun: { fun: 'moneyToFloat', parameter: ['netWin'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '投注金额',
        Field: 'bet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'netWin',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '下注后结余',
        Field: 'AftAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // AG-YP
  416: {
    cmdId: 313309,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'AGPYDZGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注金额',
        Field: 'validbetamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 美博棋牌
  417: {
    cmdId: 314903,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'MBQPGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'validBet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // FG棋牌
  709: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'game_id',
        fun: { nameData: 'FGPlayGames', fun: 'getGameName', parameter: 'game_id' }
      },
      {
        Field: 'result',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '会员账号',
        Field: 'cust_name'
      }, {
        text: '平台注单号',
        Field: 'platform_order_id',
        copy: true
      }, {
        text: '投注金额',
        Field: 'all_bets',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'result',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // QG红包
  419: {
    cmdId: 419103,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'QGHBGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '局号',
      // 	Field: 'serialId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'amountFlow',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // IG视讯
  420: {
    cmdId: 315003,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'IGSXGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'money',
        fun: { fun: 'moneyToFloat', parameter: ['money'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'money',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'wagersDate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 欧博视讯
  421: {
    cmdId: 315103,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'OBSXGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'winOrLoss',
        fun: { fun: 'moneyToFloat', parameter: ['winOrLoss'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'client'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'betNum',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注金额',
        Field: 'validAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'winOrLoss',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'betTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 雷火电竞
  422: {
    cmdId: 314317,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'LHDJGames', fun: 'getGameName', parameter: 'game_type_id' }
      },
      {
        Field: 'earnings',
        fun: { fun: 'moneyToFloat', parameter: ['earnings'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'id',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'amount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'earnings',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'date_created',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // ibo视讯
  423: {
    cmdId: 316103,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'IBOSXGames', fun: 'getGameName', parameter: 'gtype' }
      },
      {
        Field: 'win_gold',
        fun: { fun: 'moneyToFloat', parameter: ['win_gold'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'id',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'gold',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注金额',
        Field: 'vgold',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'win_gold',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'adddate',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // TG视讯
  710: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gametype',
        fun: { nameData: 'TGSXGames', fun: 'getGameName', parameter: 'gametype' }
      },
      {
        Field: 'betincome',
        fun: { fun: 'moneyToFloat', parameter: ['betincome'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注额',
        Field: 'betscore',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'betincome',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '游戏日期',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // ebet真人
  572: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'IBOSXGames', fun: 'getGameName', parameter: 'gtype' }
      },
      {
        Field: 'win_gold',
        fun: { fun: 'moneyToFloat', parameter: ['win_gold'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'betHistoryId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'bet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注金额',
        Field: 'bet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'profit',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'createTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // XG真人
  924: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'XGSXGames', fun: 'getGameName', parameter: 'gameType' }
      },
      {
        Field: 'payoffAmount',
        fun: { fun: 'moneyToFloat', parameter: ['payoffAmount'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '游戏类型',
        Field: 'gameType',
        fun: { nameData: 'XGSXGames', fun: 'getGameName', parameter: ['gameType'] }
      },
      {
        text: '投注金额',
        Field: 'validBetAmount',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'payoffAmount',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // evo真人
  980: {
    cmdId: 392001,
    showTitle: [
      {
        Field: 'gameType',
        fun: { nameData: 'EVOSXGames', fun: 'getGameName', parameter: 'game_name' }
      },
      {
        Field: 'resultamount',
        fun: { fun: 'moneyToFloat', parameter: ['resultamount'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      {
        text: '游戏类型',
        Field: 'game_name',
        fun: { nameData: 'EVOSXGames', fun: 'getGameName', parameter: ['game_name'] }
      },
      {
        text: '投注金额',
        Field: 'betamount',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '输赢',
        Field: 'resultamount',
        fun: { fun: 'moneyToFloat' }
      },
      {
        text: '投注时间',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // 天成彩票
  468: {
    cmdId: 317103,
    showTitle: [
      {
        Field: 'gameCode',
        fun: { nameData: 'TCCPGames', fun: 'getGameName', parameter: 'gameCode' }
      },
      {
        Field: 'netPNL',
        fun: { fun: 'moneyToFloat', parameter: ['netPNL'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'orderNum',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '中奖金额',
        Field: 'winAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'netPNL',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'betTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // GPI彩票
  504: {
    cmdId: 320103,
    showTitle: [
      {
        Field: 'game_id',
        fun: { nameData: 'GPIcaipiao', fun: 'getGameName', parameter: 'game_id' }
      },
      {
        Field: 'winlose',
        fun: { fun: 'moneyToFloat', parameter: ['winlose'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'bet_id',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'bet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '开奖结果',
        Field: 'game_result'
      }, {
        text: '输赢',
        Field: 'winlose',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'trans_date',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // s128斗鸡
  490: {
    cmdId: 321103,
    showTitle: [
      {
        Field: 'meron_cock_cn',
        fun: { nameData: 'S128Games', fun: 'getS128GameName', parameter: 'meron_cock_cn' }
      },
      {
        Field: 'winloss',
        fun: { fun: 'moneyToFloat', parameter: ['winloss'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'ticket_id',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'stake',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '派彩',
        Field: 'payout',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'winloss',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '赛事时间',
        Field: 'match_date',
        fun: { fun: 'dateFormat' }
      }, {
        text: '投注时间',
        Field: 'created_datetime',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  // sv388斗鸡
  706: {
    showTitle: [
      {
        Field: 'meron_cock_cn',
        fun: { nameData: 'S128Games', fun: 'getSV388GameName', parameter: 'meron_cock_cn' }
      },
      {
        Field: 'realWinLost',
        fun: { fun: 'moneyToFloat', parameter: ['realWinLost'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'platformTxId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'betAmount',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '派彩',
        Field: 'payout',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'realWinLost',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'betTime',
        fun: { fun: 'dateFormat' }
      }
      /*, {
        text: '游戏编码',
        Field: 'gameCode',
        // fun: { fun: 'dateFormat' }
      } */
    ]
  },
  // kingmaker
  506: {
    cmdId: 314820,
    showTitle: [
      {
        Field: 'gameid',
        fun: { nameData: 'KingmakerGame', fun: 'getGameName', parameter: 'gameid' }
      },
      {
        Field: 'winloss',
        fun: { fun: 'moneyToFloat', parameter: ['winloss'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'ugsbetid',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'riskamt',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '有效投注额',
        Field: 'validbet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'winloss',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'beton',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  507: {
    cmdId: 319003,
    showTitle: [
      {
        Field: 'game_id',
        fun: { nameData: 'EVOPlayGame', fun: 'getGameName', parameter: 'game_id' }
      },
      {
        Field: 'winloss',
        fun: { fun: 'moneyToFloat', parameter: ['winloss'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'round_id',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'bet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'winloss',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'bet_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  508: {
    cmdId: 322103,
    showTitle: [
      {
        Field: 'gameID',
        fun: { nameData: 'PPPlayGame', fun: 'getGameName', parameter: 'gameID' }
      },
      {
        Field: 'profit',
        fun: { fun: 'moneyToFloat', parameter: ['profit'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'cust_name'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'playSessionID',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'bet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'profit',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'create_time',
        fun: { fun: 'dateFormat' }
      }
    ]
  },
  509: {
    cmdId: 320003,
    showTitle: [
      {
        Field: 'type',
        fun: { nameData: 'KPPlayGames', fun: 'getGameName', parameter: 'type' }
      },
      {
        Field: 'surplus',
        fun: { fun: 'moneyToFloat', parameter: ['surplus'] }
      }
    ],
    pageShow: [
      {
        text: '平台注单号',
        Field: 'platform_order_id'
      },
      {
        text: '会员账号',
        Field: 'custName'
      },
      // {
      // 	text: '注单号',
      // 	Field: 'betId',
      // 	copy: true
      // },
      {
        text: '投注金额',
        Field: 'bet',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '输赢',
        Field: 'surplus',
        fun: { fun: 'moneyToFloat' }
      }, {
        text: '投注时间',
        Field: 'startBetTime',
        fun: { fun: 'dateFormat' }
      }
    ]
  }
}
