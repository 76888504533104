export const PlayInfo = {
  17: { chartid: 17, play_name: 'CQSSC', cat: 'SSC', pan: 'A', strategy: 'SSC', play_cname: '重庆时时彩', time: ['07:10:00', '03:30:00'], page: 'pk10', tip: '07:10:00~次日03:30:00', default: 17000, type: 2, color: true },
  22: { chartid: 22, play_name: 'GD115', cat: '115', pan: 'A', strategy: '115', play_cname: '广东11选5', time: ['9:10:00', '23:10:00'], page: 'pk10', tip: '9:10:00-23:10:00', default: 22018, type: 2, color: true },
  27: { chartid: 27, play_name: 'SH115', cat: '115', pan: 'A', strategy: '115', play_cname: '上海11选5', time: ['9:00:00', '24:00:00'], page: 'pk10', tip: '9:00:00-24:00:00', default: 27018, type: 2, color: true },
  33: { chartid: 33, play_name: 'MARKSIX', cat: 'MARKSIX', pan: 'B', strategy: 'MARKSIX', play_cname: '香港六合彩', page: 'hklottery', tip: '花样新翻 六合共赢', default: '33000', type: 2 },
  464: { chartid: 464, play_name: 'TWDLT', cat: 'MARKSIX', pan: 'B', strategy: 'MARKSIX', play_cname: '台湾大乐透', page: 'hklottery', tip: '花样新翻 六合共赢', default: '464000', type: 2 },
  465: { chartid: 465, play_name: 'XJPDDLHC', cat: 'MARKSIX', pan: 'B', strategy: 'MARKSIX', play_cname: '澳门六合彩', page: 'hklottery', tip: '花样新翻 六合共赢', default: '465000', type: 2 },
  466: { chartid: 466, play_name: 'BAMLHC', cat: 'MARKSIX', pan: 'B', strategy: 'MARKSIX', play_cname: '新澳门六合彩', page: 'hklottery', tip: '花样新翻 六合共赢', default: '466000', type: 2 },
  34: { chartid: 34, play_name: 'PK10', cat: 'PK10', pan: 'A', strategy: 'PK10', play_cname: '极速赛车', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '冠亚之争 由您来定', default: 34700, type: 2 },
  37: { chartid: 37, play_name: 'JSK3', cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '江苏快3', time: ['8:30:00', '22:10:00'], page: 'k3', tip: '8:30:00-22:10:00', default: 37000, type: 2, color: true },
  39: { chartid: 39, play_name: 'XJSSC', cat: 'SSC', pan: 'A', strategy: 'SSC', play_cname: '新疆时时彩', time: ['10:00:00', '2:00:00'], page: 'pk10', tip: '10:00:00-2:00:00', default: 39000, type: 2, color: true },
  40: { chartid: 40, play_name: 'PC00', cat: 'PC00', pan: 'A', strategy: 'PC00', play_cname: '北京28', time: ['9:00:00', '24:00:00'], tip: '日场幸运狂欢', type: 1 },
  387: { chartid: 387, play_name: 'TWBINGO', cat: 'PC00', pan: 'A', strategy: 'PC00', play_cname: '台湾宾果', time: ['07:05:00', '23:55:00'], tip: '日场幸运狂欢', type: 1 },
  389: { chartid: 389, play_name: 'AZXY20', cat: 'PC00', pan: 'A', strategy: 'PC00', play_cname: '澳洲幸运20', time: ['00:00:00', '24:00:00'], tip: '日场幸运狂欢', type: 1 },
  1400: { chartid: 1400, play_name: 'JNDX00', cat: 'PC00', pan: 'A', strategy: 'PC00', play_cname: '加拿大西28', time: ['00:00:00', '24:00:00'], tip: '日场幸运狂欢', type: 1 },
  1401: { chartid: 1401, play_name: 'BTB128', cat: 'PC00', pan: 'A', strategy: 'PC00', play_cname: '比特币1分28', time: ['00:00:00', '24:00:00'], tip: '日场幸运狂欢', type: 1 },
  41: { chartid: 41, play_name: 'JND00', cat: 'PC00', pan: 'A', strategy: 'PC00', play_cname: '加拿大28', time: ['21:05:00', '19:00:00'], tip: '午夜激情不停', type: 1 },
  42: { chartid: 42, play_name: 'CQLHC', cat: 'LHC', pan: 'B', strategy: 'LHC', play_cname: '重庆六合彩', note_tag: 'lhcMoney', time: ['07:10:00', '03:30:00'], page: 'cqlhc', tip: '花样新翻 六合共赢', norunAfter: true, default: 42000, type: 2 },
  43: { chartid: 43, play_name: 'TJSSC', cat: 'SSC', pan: 'A', strategy: 'SSC', play_cname: '天津时时彩', time: ['9:20:00', '23:20:00'], page: 'pk10', tip: '9:20:00-23:20:00', default: 43000, type: 2, color: true },
  46: { chartid: 46, play_name: 'HUBK3', cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '湖北快3', time: ['9:00:00', '22:00:00'], page: 'k3', tip: '9:00:00-22:00:00', default: 46000, type: 2, color: true },
  47: { chartid: 47, play_name: 'BJK3', cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '北京快3', time: ['9:00:00', '23:40:00'], page: 'k3', tip: '9:00:00-23:40:00', default: 47000, type: 2, color: true },
  48: { chartid: 48, play_name: 'HEBK3', cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '河北快3', time: ['8:30:00', '22:10:00'], page: 'k3', tip: '8:30:00-22:10:00', default: 48000, type: 2, color: true },
  49: { chartid: 49, play_name: 'GSK3', cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '甘肃快3', time: ['10:00:00', '22:00:00'], page: 'k3', tip: '10:00:00-22:00:00', default: 49000, type: 2, color: true },
  50: { chartid: 50, play_name: 'SHK3', cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '上海快3', time: ['8:40:00', '22:20:00'], page: 'k3', tip: '8:40:00-22:20:00', default: 50000, type: 2, color: true },
  51: { chartid: 51, play_name: 'GZK3', cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '贵州快3', time: ['9:10:00', '23:10:00'], page: 'K3', tip: '9:10:00-23:10:00', default: 51000, type: 2, color: true },
  52: { chartid: 52, play_name: 'JX115', cat: '115', pan: 'A', strategy: '115', play_cname: '江西11选5', time: ['9:10:00', '23:10:00'], page: 'pk10', tip: '9:10:00-23:10:00', default: 52018, type: 2, color: true },
  53: { chartid: 53, play_name: 'SD115', cat: '115', pan: 'A', strategy: '115', play_cname: '山东11选5', time: ['8:40:00', '23:00:00'], page: 'pk10', tip: '8:40:00-23:00:00', default: 53018, type: 2, color: true },
  467: { chartid: 467, play_name: 'LN115', cat: '115', pan: 'A', strategy: '115', play_cname: '辽宁11选5', time: ['8:49:00', '22:29:00'], page: 'pk10', tip: '8:49:00-22:29:00', default: 467018, type: 2, color: true },

  58: { chartid: 58, play_name: 'XYFT', cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '老幸运飞艇', time: ['13:04:00', '4:04:00'], page: 'pk10', tip: '13:04:00~4:04:00', default: 58700, type: 2, termlong: 9 },
  99: { chartid: 58, play_name: 'XYFT', cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '老幸运飞艇', time: ['13:04:00', '4:04:00'], page: 'pk10', tip: '13:04:00~4:04:00', default: 58700, type: 2, termlong: 9 },
  200: { chartid: 58, play_name: 'XYFT', cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '老幸运飞艇', time: ['13:04:00', '4:04:00'], page: 'pk10', tip: '13:04:00~4:04:00', default: 58700, type: 2, termlong: 9 },
  203: { chartid: 58, play_name: 'XYFT', cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '老幸运飞艇', time: ['13:04:00', '4:04:00'], page: 'pk10', tip: '13:04:00~4:04:00', default: 58700, type: 2, termlong: 9 },
  206: { chartid: 58, play_name: 'XYFT', cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '老幸运飞艇', time: ['13:04:00', '4:04:00'], page: 'pk10', tip: '13:04:00~4:04:00', default: 58700, type: 2, termlong: 9 },
  209: { chartid: 58, play_name: 'XYFT', cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '老幸运飞艇', time: ['13:04:00', '4:04:00'], page: 'pk10', tip: '13:04:00~4:04:00', default: 58700, type: 2, termlong: 9 },
  212: { chartid: 58, play_name: 'XYFT', cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '老幸运飞艇', time: ['13:04:00', '4:04:00'], page: 'pk10', tip: '13:04:00~4:04:00', default: 58700, type: 2, termlong: 9 },
  215: { chartid: 58, play_name: 'XYFT', cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '老幸运飞艇', time: ['13:04:00', '4:04:00'], page: 'pk10', tip: '13:04:00~4:04:00', default: 58700, type: 2, termlong: 9 },
  218: { chartid: 58, play_name: 'XYFT', cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '老幸运飞艇', time: ['13:04:00', '4:04:00'], page: 'pk10', tip: '13:04:00~4:04:00', default: 58700, type: 2, termlong: 9 },
  221: { chartid: 58, play_name: 'XYFT', cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '老幸运飞艇', time: ['13:04:00', '4:04:00'], page: 'pk10', tip: '13:04:00~4:04:00', default: 58700, type: 2, termlong: 9 },
  224: { chartid: 58, play_name: 'XYFT', cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '老幸运飞艇', time: ['13:04:00', '4:04:00'], page: 'pk10', tip: '13:04:00~4:04:00', default: 58700, type: 2, termlong: 9 },
  743: { chartid: 743, play_name: 'JSFT', cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '极速飞艇', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '猜你喜欢 极速飞艇', default: 743700, type: 2, termlong: 9 },

  60: { play_name: 'AG', play_cname: 'AG旗舰厅', cat: 'AG', time: null, tip: '真人娱乐平台', type: 8 },
  381: { play_name: 'AGBY', play_cname: 'AG捕鱼', cat: 'BY', time: null, tip: 'AG捕鱼娱乐平台', type: 13 },
  297: { play_name: 'KY', play_cname: '开元棋牌', cat: 'QP', time: null, tip: '玩桌游就选开元棋牌', type: 9 },
  299: { play_name: 'LY', play_cname: '乐游棋牌', cat: 'QP', time: null, tip: '乐游棋牌', type: 10 },
  // 392: { play_name: 'MB', play_cname: '美博棋牌', cat: 'QP', time: null, tip: '美博棋牌' },
  // 339: { play_name: 'LMG', play_cname: '立马高视讯', cat: 'AG', time: null, tip: '立马高视讯', type: 11 },
  340: { play_name: 'JDB', play_cname: 'JDB夺宝电子', cat: 'DZ', time: null, tip: 'JDB夺宝电子', type: 12 },
  593: { play_name: 'UPG', play_cname: 'UPG电子', cat: 'DZ', time: null, tip: 'UPG电子', type: 12 },
  342: { play_name: 'SBT', play_cname: '沙巴体育', cat: 'TY', time: null, tip: '沙巴体育', type: 14 },
  750: { play_name: 'SEXY', play_cname: 'Sexy体育', cat: 'TY', time: null, tip: 'Sexy体育', type: 14 },
  930: { play_name: 'EABQ', play_cname: 'EA板球', cat: 'TY', time: null, tip: 'EA板球', type: 14 },
  931: { play_name: 'IMTY', play_cname: 'IM体育', cat: 'TY', time: null, tip: 'IM体育', type: 14 },
  981: { play_name: 'IMDJ', play_cname: 'IM电竞', cat: 'TY', time: null, tip: 'IM电竞', type: 14 },
  1387: { play_name: 'DBDJ', play_cname: 'DB电竞', cat: 'TY', time: null, tip: 'DB电竞', type: 14 },
  1031: { play_name: 'KYTY', play_cname: '开元体育', cat: 'TY', time: null, tip: '开元体育', type: 14 },
  1308: { play_name: 'FBTY', play_cname: 'FB体育', cat: 'TY', time: null, tip: 'FB体育', type: 14 },
  343: { play_name: 'MGDZ', play_cname: 'MG电子', cat: 'DZ', time: null, tip: 'MG电子', type: 15 },
  403: { play_name: 'CQ9', play_cname: 'CQ9电子', cat: 'DZ', time: null, tip: 'CQ9电子', type: 15 },
  405: { play_name: 'DTDZ', play_cname: 'DT电子', cat: 'DZ', time: null, tip: 'DT电子', type: 15 },
  571: { play_name: 'PG', play_cname: 'PG电子', cat: 'DZ', time: null, tip: 'PG电子' },
  938: { play_name: 'AMEBA', play_cname: 'Ameba电子', cat: 'DZ', time: null, tip: 'Ameba电子', hide: true },
  926: { play_name: 'SimplePlay', play_cname: 'SimplePlay电子', cat: 'DZ', time: null, tip: 'SimplePlay电子', hide: true },
  939: { play_name: 'PSDZ', play_cname: 'PS电子', cat: 'DZ', time: null, tip: 'PS电子', hide: true },
  961: { play_name: 'VTDZ', play_cname: 'VT电子', cat: 'DZ', time: null, tip: 'VT电子', hide: true },
  960: { play_name: 'RTDZ', play_cname: 'RT电子', cat: 'DZ', time: null, tip: 'RT电子', hide: true },
  929: { play_name: 'JILIDZ', play_cname: 'JILI电子', cat: 'DZ', time: null, tip: 'JILI电子', hide: true },
  707: { play_name: 'FG', play_cname: 'FG电子', cat: 'DZ', time: null, tip: 'FG电子', hide: true },
  708: { play_name: 'FGBY', play_cname: 'FG捕鱼', cat: 'BY', time: null, tip: 'FG捕鱼', type: 33, hide: true },
  1388: { play_name: 'DBBY', play_cname: 'DB捕鱼', cat: 'BY', time: null, tip: 'DB捕鱼', type: 34, hide: true },
  709: { play_name: 'FGQP', play_cname: 'FG棋牌', cat: 'QP', time: null, tip: 'FG棋牌', type: 36, hide: true },
  1383: { play_name: "DBQP", play_cname: 'DB棋牌', cat: 'QP', time: null, tip: '玩桌游就选DB棋牌', type: 9 },
  // IG不能开放
  // 344: { play_name: 'IG', play_cname: 'IG电子', cat: 'DZ', time: null, tip: 'IG电子', type: 16 },
  345: { play_name: 'BBIN', play_cname: 'BBIN视讯', cat: 'AG', time: null, tip: 'BBIN视讯', type: 17 },
  380: { play_name: 'DFW', play_cname: '大富翁棋牌', cat: 'QP', time: null, tip: '大富翁棋牌', type: 18 },
  382: { play_name: 'CS', play_cname: '财神棋牌', cat: 'QP', time: null, tip: '财神棋牌', type: 19 },
  383: { play_name: 'BG', play_cname: 'BG捕鱼大师', cat: 'BY', time: null, tip: 'BG捕鱼', type: 20 },
  388: { play_name: 'HL', play_cname: '欢乐棋牌', cat: 'QP', time: null, tip: '欢乐棋牌', type: 22 },
  390: { play_name: 'XY', play_cname: 'BG西游捕鱼', cat: 'BY', time: null, tip: '西游捕鱼', type: 21 },
  391: { play_name: 'TM', play_cname: '天美棋牌', cat: 'QP', time: null, tip: '天美棋牌', type: 23 },
  393: { play_name: 'TH', play_cname: '天游棋牌', cat: 'QP', time: null, tip: '天游棋牌', type: 24 },
  394: { play_name: 'BL', play_cname: '博乐棋牌', cat: 'QP', time: null, tip: '博乐棋牌', type: 26 },
  395: { play_name: 'BTI', play_cname: 'BTI体育', cat: 'TY', time: null, tip: 'BTI体育', type: 25 },
  396: { play_name: 'MGBY', play_cname: 'MG捕鱼', cat: 'BY', time: null, tip: 'MG捕鱼', hide: true, type: 27 },
  397: { play_name: 'JDBBY', play_cname: 'JDB捕鱼', cat: 'BY', time: null, tip: 'JDB捕鱼', hide: true, type: 28 },
  398: { play_name: 'TJ', play_cname: '天境棋牌', cat: 'QP', time: null, tip: '天境棋牌', hide: true, type: 30 },
  399: { play_name: 'THBY', play_cname: '天游捕鱼', cat: 'BY', time: null, tip: '天游捕鱼', hide: true, type: 29 },
  400: { play_name: 'DG', play_cname: 'DG视讯', cat: 'AG', time: null, tip: 'DG视讯', type: 17 },
  402: { play_name: 'BGSX', play_cname: 'BG视讯', cat: 'AG', time: null, tip: 'BG视讯', type: 17 },
  408: { play_name: 'CQ9BY', play_cname: 'CQ9捕鱼', cat: 'BY', time: null, tip: 'CQ9捕鱼', hide: true, type: 31 },
  407: { play_name: 'BSBY', play_cname: '百胜捕鱼', cat: 'BY', time: null, tip: '百胜捕鱼', hide: true, type: 32 },
  747: { play_name: 'DTBY', play_cname: '大唐捕鱼', cat: 'BY', time: null, tip: '大唐捕鱼', hide: true, type: 34 },
  404: { play_name: 'BS', play_cname: '百胜棋牌', cat: 'QP', time: null, tip: '百胜棋牌', hide: true, type: 30 },
  745: { play_name: 'KGQP', play_cname: '王者棋牌', cat: 'QP', time: null, tip: '王者棋牌', hide: true, type: 30 },
  927: { play_name: '365QP', play_cname: '365棋牌', cat: 'QP', time: null, tip: '365棋牌', hide: true, type: 30 },
  746: { play_name: 'DTQP', play_cname: '大唐棋牌', cat: 'QP', time: null, tip: '大唐棋牌', hide: true, type: 30 },
  928: { play_name: 'JILIQP', play_cname: 'JILI棋牌', cat: 'QP', time: null, tip: 'JILI棋牌', hide: true, type: 30 },
  401: { play_name: 'XSJ', play_cname: '新世界棋牌', cat: 'QP', time: null, tip: '新世界棋牌', hide: true, type: 30 },
  412: { play_name: 'MGSX', play_cname: 'MG视讯', cat: 'AG', time: null, tip: 'MG视讯' },
  409: { play_name: 'HGTY', play_cname: '皇冠体育', cat: 'TY', time: null, tip: '皇冠体育' },
  414: { play_name: 'PT', play_cname: 'PT电子', cat: 'DZ', time: null, tip: 'PT电子' },
  413: { play_name: 'BBINDZ', play_cname: 'BBIN电子', cat: 'DZ', time: null, tip: 'BBIN电子' },
  415: { play_name: 'AGDZ', play_cname: 'AG电子', cat: 'DZ', time: null, tip: 'AG电子' },
  416: { play_name: 'AGYP', play_cname: 'AG-YP电子', cat: 'DZ', time: null, tip: 'AG-YP电子' },
  // 417: { play_name: 'MBQP', play_cname: '美博棋牌', cat: 'QP', time: null, tip: '美博棋牌' },
  417: { play_name: 'HJ', play_cname: '美博棋牌', cat: 'QP', time: null, tip: '美博棋牌' },
  418: { play_name: 'SBTY', play_cname: '胜博体育', cat: 'TY', time: null, tip: '胜博体育' },
  570: { play_name: 'SBObet', play_cname: 'SBO利记体育', cat: 'TY', time: null, tip: 'SBO利记体育' },
  419: { play_name: 'HBYX', play_cname: '红包游戏', cat: 'HB', time: null, tip: '红包游戏' },
  420: { play_name: 'IGSX', play_cname: 'IG视讯', cat: 'AG', time: null, tip: 'IG视讯' },
  572: { play_name: 'EBETSX', play_cname: 'ebet视讯', cat: 'AG', time: null, tip: 'ebet视讯' },
  421: { play_name: 'OBSX', play_cname: '欧博视讯', cat: 'AG', time: null, tip: '欧博视讯' },
  422: { play_name: 'LHDJ', play_cname: '雷火电竞', cat: 'TY', time: null, tip: '雷火电竞' },
  423: { play_name: 'IBOSX', play_cname: 'IBO视讯', cat: 'AG', time: null, tip: 'IBO视讯' },
  490: { play_name: 'S128DJ', play_cname: 'S128斗鸡', cat: 'TY', time: null, tip: 'S128斗鸡' },
  925: { play_name: 'TRCSM', play_cname: 'TRC赛马', cat: 'TY', time: null, tip: 'TRC赛马' },
  506: { play_name: 'KMQP', play_cname: 'kingmaker', cat: 'QP', time: null, tip: 'kingmaker' },
  // 507: { play_name: 'EVOPlay', play_cname: 'EVOPlay电子', cat: 'DZ', time: null, tip: 'EVOPlay电子' },
  508: { play_name: 'PP', play_cname: 'PP电子', cat: 'DZ', time: null, tip: 'PP电子' },
  509: { play_name: 'KPQP', play_cname: 'kingpoker', cat: 'QP', time: null, tip: 'kingpoker' },
  706: { play_name: 'SV388', play_cname: 'SV388斗鸡', cat: 'TY', time: null, tip: 'SV388斗鸡' },
  710: { play_name: 'GLGSX', play_cname: 'GLG视讯', cat: 'AG', time: null, tip: 'GLG视讯' },
  924: { play_name: 'XGSX', play_cname: 'XG视讯', cat: 'AG', time: null, tip: 'XG视讯' },
  980: { play_name: 'EVOSX', play_cname: 'EVO视讯', cat: 'AG', time: null, tip: 'EVO视讯' },
  /* 注意注意：  711暂时不上，具体请问kerry */
  711: { play_name: 'HC', play_cname: 'HC电子', cat: 'DZ', time: null, tip: 'HC电子', hide: true },
  1305: { play_name: 'JOKER', play_cname: 'JOKER电子', cat: 'DZ', time: null, tip: 'JOKER电子' },
  1306: { play_name: 'RSG', play_cname: 'RSG电子', cat: 'DZ', time: null, tip: 'RSG电子' },
  1307: { play_name: 'BNGDZ', play_cname: 'BNG电子', cat: 'DZ', time: null, tip: 'BNG电子' },
  1310: { play_name: 'YGGDZ', play_cname: 'YGG电子', cat: 'DZ', time: null, tip: 'YGG电子' },
  1309: { play_name: 'WMSX', play_cname: '完美真人', cat: 'AG', time: null, tip: '完美真人' },
  983: { play_name: 'MTDZ', play_cname: 'MT电子', cat: 'DZ', time: null, tip: 'MT电子' },
  1311: { play_name: 'NEXTSPINDZ', play_cname: 'NEXTSPIN电子', cat: 'DZ', time: null, tip: 'NEXTSPIN电子' },
  1312: { play_name: 'AESEXY', play_cname: 'AESEXY真人', cat: 'AG', time: null, tip: 'AESEXY真人' },
  1313: { play_name: 'PRETTY', play_cname: 'PRETTY真人', cat: 'AG', time: null, tip: "PRETTY真人" },
  1314: { play_name: 'AFBSX', play_cname: 'AFB真人', cat: 'AG', time: null, tip: "AFB真人" },
  1384: { play_name: 'DBZR', play_cname: 'DB真人', cat: 'AG', time: null, tip: "DB真人" },
  1315: { play_name: 'AFBDZ', play_cname: 'AFB电子', cat: 'DZ', time: null, tip: 'AFB电子' },
  1316: { play_name: 'AFBTY', play_cname: 'AFB体育', cat: 'TY', time: null, tip: 'AFB体育' },
  1389: { play_name: 'DBTY', play_cname: 'DB体育', cat: 'TY', time: null, tip: 'DB体育' },
  1317: { play_name: 'SAGSX', play_cname: 'SAGaming视讯', cat: 'AG', time: null, tip: 'SAGaming视讯' },
  1318: { play_name: 'PGSOFT', play_cname: 'PGSOFT电子', cat: 'DZ', time: null, tip: 'PGSOFT电子' },
  1319: { play_name: 'EVOPLAY', play_cname: 'EVOPLAY电子', cat: 'DZ', time: null, tip: 'EVOPLAY电子' },
  1320: { play_name: 'TPDZ', play_cname: 'TP电子', cat: 'DZ', time: null, tip: 'TP电子' },
  1321: { play_name: 'KADZ', play_cname: 'KA电子', cat: 'DZ', time: null, tip: 'KA电子' },
  1322: { play_name: 'PPSX', play_cname: 'PP真人', cat: 'AG', time: null, tip: 'PP真人' },
  1323: { play_name: 'NEDZ', play_cname: 'NETENT电子', cat: 'DZ', time: null, tip: 'NETENT电子' },
  1324: { play_name: 'FC', play_cname: 'FC电子', cat: 'DZ', time: null, tip: 'FC电子' },
  1325: { play_name: 'SGDZ', play_cname: 'SG电子', cat: 'DZ', time: null, tip: 'SG电子' },
  1380: { play_name: 'PGSINGEL', play_cname: 'PG电子(新)', cat: 'DZ', time: null, tip: 'PG电子(新)' },
  // 1326: { play_name: 'SBCP', play_cname: '沙巴彩票', cat: '', time: null, tip: '沙巴彩票' },
  1386: { play_name: 'DBDZ', play_cname: 'DB电子', cat: 'DZ', time: null, tip: 'DB电子' },
  // 捕鱼子玩法配置
  396001: { main_id: 396, play_name: 'SMF_BirdHunting', play_cname: '捕鸟联盟', cat: 'BY', time: null, tip: '捕鸟联盟', type: 27 },
  396002: { main_id: 396, play_name: 'SMF_FishingJoy', play_cname: '捕鱼联盟', cat: 'BY', time: null, tip: '捕鱼联盟', type: 27 },
  397001: { main_id: 397, play_name: '7001', play_cname: '龙王捕鱼', cat: 'BY', time: null, tip: '龙王捕鱼', type: 28 },
  397002: { main_id: 397, play_name: '7002', play_cname: '龙王捕鱼2', cat: 'BY', time: null, tip: '龙王捕鱼2', type: 28 },
  397003: { main_id: 397, play_name: '7003', play_cname: '财神捕鱼', cat: 'BY', time: null, tip: '财神捕鱼', type: 28 },
  397004: { main_id: 397, play_name: '7004', play_cname: '五龙捕鱼', cat: 'BY', time: null, tip: '五龙捕鱼', type: 28 },
  399001: { main_id: 399, play_name: 'jcby', play_cname: '金蟾捕鱼', cat: 'BY', time: null, tip: '金蟾捕鱼', type: 29 },
  399002: { main_id: 399, play_name: 'dntg1', play_cname: '大闹天宫1', cat: 'BY', time: null, tip: '大闹天宫1', type: 29 },
  399003: { main_id: 399, play_name: 'dntg2', play_cname: '大闹天宫2', cat: 'BY', time: null, tip: '大闹天宫2', type: 29 },
  399004: { main_id: 399, play_name: 'lkby', play_cname: '李逵劈鱼', cat: 'BY', time: null, tip: '李逵劈鱼', type: 29 },
  399005: { main_id: 399, play_name: 'dsby', play_cname: '大圣捕鱼', cat: 'BY', time: null, tip: '大圣捕鱼', type: 29 },
  399006: { main_id: 399, play_name: 'xldb', play_cname: '寻龙夺宝', cat: 'BY', time: null, tip: '寻龙夺宝', type: 29 },
  399007: { main_id: 399, play_name: 'jcbydlc', play_cname: '金蟾海王2', cat: 'BY', time: null, tip: '金蟾海王2', type: 29 },
  399008: { main_id: 399, play_name: 'dhlw', play_cname: '3D捕鱼', cat: 'BY', time: null, tip: '3D捕鱼', type: 29 },
  399009: { main_id: 399, play_name: 'bndr', play_cname: '捕鸟达人', cat: 'BY', time: null, tip: '捕鸟达人', type: 29 },
  407001: { main_id: 407, play_name: '1001', play_cname: '千炮捕鱼王', cat: 'BY', time: null, tip: '千炮捕鱼王', type: 32 },
  407002: { main_id: 407, play_name: '1046', play_cname: '3D海盗来了', cat: 'BY', time: null, tip: '3D海盗来了', type: 32 },
  407003: { main_id: 407, play_name: '1047', play_cname: '3D美人鱼', cat: 'BY', time: null, tip: '3D美人鱼', type: 32 },
  407004: { main_id: 407, play_name: '1018', play_cname: '捕鱼王中王', cat: 'BY', time: null, tip: '捕鱼王中王', type: 32 },
  408001: { main_id: 408, play_name: 'AB3', play_cname: '皇金渔场', cat: 'BY', time: null, tip: '皇金渔场', type: 31 },
  408002: { main_id: 408, play_name: 'AT01', play_cname: '一炮捕鱼', cat: 'BY', time: null, tip: '一炮捕鱼', type: 31 },
  408003: { main_id: 408, play_name: 'AT04', play_cname: '雷电战机', cat: 'BY', time: null, tip: '雷电战机', type: 31 },
  408004: { main_id: 408, play_name: 'AB1', play_cname: '皇金渔场2', cat: 'BY', time: null, tip: '皇金渔场2', type: 31 },
  407005: { main_id: 407, play_name: '1048', play_cname: '李逵劈鱼', cat: 'BY', time: null, tip: '李逵劈鱼', type: 32 },
  407006: { main_id: 407, play_name: '1049', play_cname: '大闹天宫', cat: 'BY', time: null, tip: '大闹天宫', type: 32 },
  407007: { main_id: 407, play_name: '1050', play_cname: '捕鸟达人', cat: 'BY', time: null, tip: '捕鸟达人', type: 32 },
  // FG捕鱼
  708001: { main_id: 708, play_name: '708001', play_cname: '美人捕鱼', cat: 'BY', time: null, tip: '美人捕鱼', type: 33 },
  708002: { main_id: 708, play_name: '708002', play_cname: '雷霆战警', cat: 'BY', time: null, tip: '雷霆战警', type: 33 },
  708003: { main_id: 708, play_name: '708003', play_cname: '捕鸟达人', cat: 'BY', time: null, tip: '捕鸟达人', type: 33 },
  708004: { main_id: 708, play_name: '708004', play_cname: '欢乐捕鱼', cat: 'BY', time: null, tip: '欢乐捕鱼', type: 33 },
  708005: { main_id: 708, play_name: '708005', play_cname: '天天捕鱼', cat: 'BY', time: null, tip: '天天捕鱼', type: 33 },
  708006: { main_id: 708, play_name: '708006', play_cname: '捕鱼来了3D', cat: 'BY', time: null, tip: '捕鱼来了3D', type: 33 },
  708007: { main_id: 708, play_name: '708007', play_cname: '魔法王者', cat: 'BY', time: null, tip: '魔法王者', type: 33 },
  708008: { main_id: 708, play_name: '708008', play_cname: '猎鼠达人3D', cat: 'BY', time: null, tip: '猎鼠达人3D', type: 33 },
  708009: { main_id: 708, play_name: '708009', play_cname: '美人鱼传说', cat: 'BY', time: null, tip: '美人鱼传说', type: 33 },
  // 大唐捕鱼
  747001: { main_id: 747, play_name: '747001', play_cname: '金蝉捕鱼', cat: 'BY', time: null, tip: '金蝉捕鱼', type: 34 },
  747002: { main_id: 747, play_name: '747002', play_cname: '李逵劈鱼', cat: 'BY', time: null, tip: '李逵劈鱼', type: 34 },
  747003: { main_id: 747, play_name: '747003', play_cname: '人鱼传说', cat: 'BY', time: null, tip: '人鱼传说', type: 34 },
  747004: { main_id: 747, play_name: '747004', play_cname: '大闹天宫', cat: 'BY', time: null, tip: '大闹天宫', type: 34 },
  78: { chartid: 78, play_name: 'TX1FC', cat: 'SSC', pan: 'A', strategy: 'SSC_TXFFC', play_cname: '腾讯分分彩', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', default: 78000, type: 2, color: true },

  // 自有C盘-公用
  111: { chartid: 17, play_name: 'CQSSCXY', cat: 'SSCJDC', pan: 'C', strategy: 'SSCJDC', play_cname: '重庆时时彩信用', time: ['07:10:00', '03:30:00'], page: 'pk10', tip: '07:10:00-03:30:00', default: 111000 },
  112: { chartid: 39, play_name: 'XJSSCXY', cat: 'SSCJDC', pan: 'C', strategy: 'SSCJDC', play_cname: '新疆时时彩信用', time: ['10:00:00', '2:00:00'], page: 'pk10', tip: '10:00:00-2:00:00', default: 112000 },
  109: { chartid: 34, play_name: 'PK10XY', cat: 'PK10JDC', pan: 'C', strategy: 'PK10JDC', play_cname: '极速赛车信用', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '冠亚之争 由您来定', default: 109000, termlong: 11 },
  110: { chartid: 58, play_name: 'XYFTXY', cat: 'XYFTJDC', pan: 'C', strategy: 'XYFTJDC', play_cname: '老幸运飞艇信用', time: ['13:04:00', '4:04:00'], page: 'pk10', tip: '冠亚之争 由您来定', default: 110000, termlong: 9 },

  753: { chartid: 753, play_name: 'FC3D', cat: 'FC3D', pan: 'A', strategy: 'FC3D', play_cname: '福彩3D', time: ['00:00:00', '24:00:00'], page: 'fc3d', tip: '24小时在线购彩', default: 753000 },

  // 境外彩
  385: { chartid: 385, play_name: 'AZXY10', cat: 'PK10JDC', pan: 'C', strategy: 'PK10JDC', play_cname: '澳洲幸运10', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', default: 385000 },
  384: { chartid: 384, play_name: 'AZXY5', cat: 'SSCJDC', pan: 'C', strategy: 'SSCJDC', play_cname: '澳洲幸运5', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', default: 384000 },
  23: { chartid: 23, disable: true, play_name: 'AHK3', cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '安徽快3', time: ['8:40:00', '22:00:00'], page: 'k3', tip: '08:40:00~22:00:00', default: 23000, type: 2, color: true }
}
