// @ts-nocheck

import HmacMD5 from 'crypto-js/hmac-md5'
import Base64 from 'crypto-js/enc-base64'
import Latin1 from 'crypto-js/enc-latin1'
import md5 from 'md5'

import * as des from './des'
import NetworkError from './error'
import {useTenantStore} from '/@/kernel-mn/stores/tenant'
import {useUserStore} from '/@/kernel-mn/stores/user'
import config from '/@/kernel-mn/config'

// 格式化要发送的数据
export const formatSendData = (data, type = 0) => {
  // url code
  if (type === 0) {
    let arr = []
    for (let k in data) {
      let v = data[k]
      if (v instanceof Array) {
        for (let subK in v) {
          arr.push(`${k}[]=${v[subK]}`)
        }
      } else {
        arr.push(`${k}=${data[k]}`)
      }
    }
    return arr.join('&')
  } else if (type === 2) {
    return data.join('/')
  }
  // json
  return JSON.stringify(data)
}

export const getP = p => {
  return HmacMD5(p, '7NEkojNzfkk=').toString()
}

export const enD = (rk, str) => {
  let enc = des.des(rk, str, 1, 0, null, 1)
  return Base64.stringify(Latin1.parse(enc))
}

export const dnD = (rk, str) => {
  let s = Latin1.stringify(Base64.parse(str))
  let d = des.des(rk, s, 0, 0, null, 1)
  return d
}

export const enP = (rk, vk, t) => {
  let enc = des.des(vk, rk + t, 1, 0, null, 1)
  return Base64.stringify(Latin1.parse(enc))
}

export const dnP = (vk, str) => {
  let s = Latin1.stringify(Base64.parse(str))
  let p = des.des(vk, s, 0, 0, null, 1)
  return p
}

export const enC = (rk, vk, m) => {
  let enc = HmacMD5(m + rk, vk)
  return Base64.stringify(enc)
}

export const finalHeaders = (inHeaders, context, signature) => {
  let headers = {}
  let tid = 0
  const tenantState = useTenantStore()
  if (tenantState.tenantInfo && tenantState.tenantInfo.tid) {
    tid = tenantState.tenantInfo.tid
  }
  let Custid = 0
  const userStore = useUserStore()
  if (userStore.userInfo && userStore.userInfo.cust_id) {
    Custid = userStore.userInfo.cust_id
  }
  headers.Tid = tid
  if (Custid) {
    headers.Custid = Custid
  }
  headers['Content-Type'] = 'application/x-www-form-urlencoded'
  headers['Accept'] = 'application/json, application/xml, text/play, text/html, *.*'
  headers['Signature'] = md5(signature)
  return Object.assign(headers, inHeaders)
}

// 普通接口
export const getNormalHeaders = (cmdId, context) => {
  let headers = {}
  let grantType = 'password'
  let scope = 'read write'

  // 消息头
  headers['Cmdid'] = cmdId
  headers['Custid'] = ''
  headers['Aseqid'] = config.aseqId
  let signature = 'react_clientgrant_type=' + grantType + 'scope=' + scope + 'cmd_id=' + cmdId + 'react'
  return finalHeaders(headers, context, signature)
}

// 刷新AccessToken接口 2, 370432
export const getRefreshTokenHeaders = (cmdId, context) => {
  let headers = {}
  let grantType = 'refresh_token'
  let scope = ''

  // 消息头
  headers['Cmdid'] = cmdId
  headers['Custid'] = ''
  headers['Aseqid'] = config.aseqId
  let signature = 'react_clientgrant_type=' + grantType + 'scope=' + scope + 'cmd_id=' + cmdId + 'react'
  return finalHeaders(headers, context, signature)
}

// 鉴权接口
export const getAuthHeaders = (cmdId, context) => {
  let headers = {}
  let signature = ''
  let accessToken = ''
  const userStore = useUserStore()
  if (userStore.userInfo && userStore.userInfo.access_token) {
    accessToken = userStore.userInfo.access_token
  }
  let authorization = 'Bearer ' + accessToken

  // 消息头
  headers['Cmdid'] = cmdId
  headers['Authorization'] = authorization
  signature = 'react_clientauthorization=' + authorization + 'cmd_id=' + cmdId + 'react'
  return finalHeaders(headers, context, signature)
}

// 加工请求数据
export const reqMake = (inHeaders, data, paramType, callback) => {
  let t = new Date().getTime()
  let rk = md5(Math.random() + t).substring(0, 8)
  let vk = config.vk
  let pwd = enP(rk, vk, t)
  let sendStr = enD(rk, formatSendData(data, paramType))
  let sendDateStr = enD(rk, t.toString())
  let checkOr = enC(rk, vk, sendDateStr)
  let h2 = Object.assign({}, inHeaders)
  h2['Checkor'] = checkOr
  h2['Pwds'] = pwd
  h2['Datetime'] = t
  h2['Aseqid'] = config.aseqId
  h2['Nc'] = config.nc
  h2['Tbc'] = md5(t + 'fT6phq0wkOPRlAoyToidAnkogUV7tBBD')

  if (callback) {
    callback(h2, sendStr)
  }
}

// 解释响应数据
export const parseResponse = (key, response, callback) => {
  let err = null
  let resData = null
  const reqHeaders = response.config?.headers || {}
  const responseHeaders = response.headers
  const context = response.data
  if (context && context.length !== 0) {
    try {
      const rpwds = responseHeaders.Pwds || responseHeaders.pwds
      const rcheckor = responseHeaders.Checkor || responseHeaders.checkor
      const rdatetime = responseHeaders.Datetime || responseHeaders.datetime
      const recData = context
      const dpwd = dnP(config.vk, rpwds)
      const drk = dpwd.substring(0, 8)
      const dm = dnD(drk, recData)
      const recDateStr = enD(drk, rdatetime.toString())
      const dc = enC(drk, config.vk, recDateStr)
      if (dm == null || dm.length === 0) {
        err = new NetworkError({
          key: key,
          tag: 'Network error 1',
          info: '数据为空',
          origin: '',
          cmd: reqHeaders.Cmdid
        })
      } else if (dc !== rcheckor) {
        err = new NetworkError({
          key: key,
          tag: 'Network error 2',
          info: '返回数据异常',
          origin: '',
          cmd: reqHeaders.Cmdid
        })
      } else {
        resData = JSON.parse(dm)
      }
      if (resData || (dm + '').length) {
        if (resData.exLevel) {
          // 接口请求的错误处理
          err = new NetworkError({
            key: key,
            tag: 'Network error 3',
            info: resData.exMessage,
            origin: resData,
            cmd: reqHeaders.Cmdid,
            code: resData.exCode
          })
          // 版本号不对，强制更新
          if (err.code === 1108) {
            err.update = true
          } else if ([1200, 1201, 1109, 1112, 1202, 1007].includes(+err.code)) {
            // 1200 refresh_token错误
            // 1201 access_token错误
            // 1112 IP限制
            err.logout = true // 退出登录
            err.tag = 'Network error 4'
            err.logoutMessage = '返回异常'
          }
        } else if (resData.error) {
          err = new NetworkError({
            key: key,
            tag: 'Network error 5',
            info: resData.error_description,
            origin: resData,
            cmd: reqHeaders.Cmdid,
            code: resData.exCode
          })
        } else if (resData.response_code === '0') {
          // 回收第三方金额 没有的的时候 也是返回'0'
          if ([391138].includes(+reqHeaders.Cmdid)) {
            resData.response_code = '1'
          } else {
            err = new NetworkError({
              key: key,
              tag: 'Network error 8',
              info: '操作失败',
              origin: resData,
              cmd: reqHeaders.Cmdid,
              code: resData.exCode
            })
          }
        }
      } else {
        err = new NetworkError({
          key: key,
          tag: 'Network error 1',
          info: '数据为空',
          origin: '',
          cmd: reqHeaders.Cmdid
        })
      }
    } catch (e) {
      // 404等错误处理
      let errStr = context
      if (typeof context === 'object') {
        errStr = JSON.stringify(context)
      } else {
        const arr = context.match(/title[\s\S]*?title/g)
        if (arr && arr.length !== 0) {
          errStr = arr[0].replace(/title>/g, '').replace(/<\/title/g, '')
        }
      }
      let logout = false
      if (errStr.indexOf('invalid_token') !== -1) {
        logout = true
      }
      // 类似这种错误的时候 跳到登录页 "error":"access_denied" "error":"unauthorized"
      if (errStr.indexOf('access_denied') !== -1 || errStr.indexOf('unauthorized') !== -1) {
        logout = true
      }
      err = new NetworkError({
        key: key,
        tag: 'Network error 6',
        info: errStr,
        origin: context,
        cmd: reqHeaders.Cmdid,
        code: -1,
        logout: logout
      })
    }
  } else {
    let errStr = context || (response.data === undefined ? response?.message || response.toString() : '');
    err = new NetworkError({
      key: key,
      tag: 'Network error 7',
      info: errStr || '请求没有返回任何数据',
      origin: context || response.toString(),
      cmd: reqHeaders.Cmdid,
      code: -1
    })
  }
  if (err) {
    callback && callback(null, err)
  } else {
    callback && callback(resData, err)
  }
}
