export const get = (key: string) => localStorage.getItem(key);
export const set = (key: string, data: string) => localStorage.setItem(key, data);
export const remove = (key: string) => localStorage.removeItem(key);

export const getSession = (key: string) => sessionStorage.getItem(key);
export const setSession = (key: string, data: string) => sessionStorage.setItem(key, data);
export const removeSession = (key: string) => sessionStorage.removeItem(key);

// session 为真 存sessionStorage 默认假
export const setJSON = (key: string, data: any, session: boolean = false) => {
  session ? setSession(key, JSON.stringify(data)) : set(key, JSON.stringify(data))
}
// session 为真 存sessionStorage 默认假
export const getJSON = <T = any>(key: string, session: boolean = false): T | null => {
  const data = session ? getSession(key) : get(key);
  return typeof data === 'string' && data ? JSON.parse(data) : null;
}

// 本地保存最近完的游戏 data当前点击的游戏数据 uid用户的cust_id   {uid: [{...data}]}
export const saveGameRecord = (data: any = {}, uid) => {
  let old_record = getJSON('gameRecord') || {}; // 当前电脑上面保存的所有用户游戏
  let old_uid_record = old_record[uid] || []; // 当前用户的游戏
  if (old_uid_record.length) { // 看看是否有相同的
    let oidx = old_uid_record.findIndex(item => item.play_id === data.play_id);
    if (oidx > -1) { // 有就放到最前面
      old_uid_record.splice(oidx, 1);
      old_uid_record.unshift(data);
    } else {
      old_uid_record = [data, ...old_uid_record];
    }
    old_record[uid] = old_uid_record.slice(0, 30); // 最多存30个 足够用了
  } else {
    old_record[uid] = [data];
  }
  setJSON('gameRecord', old_record); // 保存所有数据
}
// 读取本地保存游戏列表 uid用户的cust_id [{...data}] len读多少个
export const reedGameRecord = (uid, len = 6) => {
  let old_record = getJSON('gameRecord') || {}; // 当前电脑上面保存的所有用户游戏
  let old_uid_record = old_record[uid] || []; // 当前用户的游戏
  return old_uid_record.splice(0, len);
}
// 清空本地保存的游戏数据 uid为空 就清空全部
export const cleanGameRecord = (uid = '') => {
  if (uid) {
    let old_record = getJSON('gameRecord') || {}; // 当前电脑上面保存的所有用户游戏
    if (old_record[uid]) {
      old_record[uid] = [];
      delete old_record[uid];
      setJSON('gameRecord', old_record); // 保存所有数据
    }
  } else {
    remove('gameRecord')
  }
}

export default {
  get,
  set,
  remove,
  setJSON,
  getJSON,
  getSession,
  setSession,
  removeSession,
}
