/**
 * 哈希
 */
import {defineStore} from 'pinia'
import haxiFetch from './haxiFetch'

export const useHaxiStore = defineStore({
  id: 'haxi',
  state: () => {
    return {
      gameList: [],
      promoteRates: {
        up_one_rate: "0%",
        up_three_rate: "0%",
        up_two_rate: "0%",
      }
    }
  },
  getters: {},
  actions: {
    ...haxiFetch,
    setHaxiGames(data) {
      this.gameList = data
    },
  }
})
