/**
 * 体育
 */
import {defineStore} from 'pinia'
import sportsFetch from './sportsFetch'

export const useSportsStore = defineStore({
  id: 'sports',
  state: () => {
    return {
      // 用户自定义数据配置
      userConfig: {
        AvailableOddsType: [1, 2, 3, 4], // 1 = 马来盘; 2 = 香港盘; 3 = 欧洲盘; 4 = 印尼盘
        BettingStyle: 1,
        ComboAutoAccept: 1,
        SelectedTimeZone: 29, // 东八区
        FastBetCombo: [1000, 500, 100],
        FastBetSingle: [1000, 500, 100], // 快捷投注值
        // LanguageCode: null,
        SelectedOddsType: 3, // 当前赔率类型
        // SelectedSport: 1,
      },
      othersGameHistory: {},
      blance: 0, // 会员余额
      freezeBalance: 0, // 冻结的钱
      marking_quantity_sum: 0, // 打码量
      pcLogin: false,
      pcRegister: false,
    }
  },
  getters: {},
  actions: {
    ...sportsFetch
  }
})
