import axios from 'axios'
import network from '../../network'
import {useTenantStore} from '../tenant'
// import NetworkError from '/@/kernel-mn/network/error';
import {useHot} from "/@/kernel-mn/stores/useHot";
import OSS from 'ali-oss';
import './aws-sdk-2.1159.0.min.js';

let domain_addr = location.origin
// @ts-ignore
if (import.meta.env.DEV) {
  // @ts-ignore
  domain_addr = import.meta.env.VITE_API_DOMAIN_ADDR
}

export default {
  async fetchXianlu(payload: any = {}) {
    // this.$reset() // 重置state
    const tenantState = useTenantStore()
    try {
      const res = await network.request({
        cmdId: 373133, // 支持查询多个配置线路
        data: {
          tid: tenantState.tenantInfo.tid,
          domain_type: payload.domain_type || 0,
        },
        headerType: 1,
      });
      if (res.type === 'success') {
        let lists: any = res.data || [];
        for (let i = 0; i < lists.length; i++) {
          let item = lists[i];
          let url: string = item.domain_addr
          let domain_type: number = item.domain_type
          // XMPP聊天线路 不处理
          if (domain_type === 11) {
            window.xmppConfig.posturl = url;
            continue;
          }
          if (!/^http/.test(url)) {
            let protocol = domain_addr.toString().split('//')[0]
            url = `${protocol}//${url}`
          }
          const timebegin = new Date().getTime()
          if (!url.includes('wss://') && !url.includes('ws://')) {
            try {
              let respone = await axios.get(url);
              if (respone.status === 200) {
                const timestamp = new Date().getTime() - timebegin
                if (domain_type === 1) {
                  item.speeds = (timestamp * 0.001).toFixed(3) + '秒'
                  this.chat.push(item)
                } else if (domain_type === 2) {
                  this.pay.push(item)
                }
              }
            } catch (e) {
              console.log('当前线路 error===>', (e || '').toString(), url);
              // return Promise.reject(e)
            }
          }
        }
      }
    } catch (e) {
      console.log('线路信息 error===>', e)
    }
  },
  // 获取上传线路
  async reqImgToken(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      domain_type: '4,9,10',
      ...payload,
    };
    try {
      const res = await network.request({
        cmdId: 360830,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        let data: any = res.data || {};
        // 处理线路是否可用的判断
        const tmp = async (index) => {
          const domainItem = data.url[index]
          if (!domainItem) {
            return Promise.resolve(res.data);
          }
          const urlAll = domainItem.callback_addr
          let callbackAddr = domainItem.domain_addr
          if (!(callbackAddr.includes('http') || callbackAddr.includes('https'))) {
            callbackAddr = 'https://' + callbackAddr
          }
          if (domainItem.domain_type === 9) {
            try {
              const systemInfoRes: any = await network.request({
                cmdId: 370730,
                data: {tid: 0, option_code: 'ALIYUN_OSS_STORAGE_CONFIGURE'}
              })
              const systemInfo = systemInfoRes.data
              if (!systemInfo) {
                return tmp(++index)
              }
              const systemConfig = JSON.parse(systemInfo.value)
              const resConfigRes: any = await network.request({
                cmdId: 370722,
                data: {
                  regionId: systemConfig.regionId,
                  accessKeyId: systemConfig.accessKeyId,
                  accessSecret: systemConfig.secretAccessKey,
                  roleArn: systemConfig.roleArn,
                  roleSessionName: systemConfig.roleSessionName
                },
                isApiV2: true,
              })
              const resConfig = resConfigRes.data;
              if (resConfig && !resConfig.tokenSecret) {
                return tmp(++index)
              }
              const client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: systemConfig.region,
                accessKeyId: resConfig.accessKeyId,
                accessKeySecret: resConfig.accessKeySecret,
                stsToken: resConfig.tokenSecret,
                // 填写Bucket名称，例如examplebucket。
                bucket: systemConfig.bucketName,
                // 刷新临时访问凭证。
                refreshSTSToken: async () => {
                  const refreshTokenRes: any = await network.request({
                    cmdId: 370722,
                    data: {
                      regionId: systemConfig.regionId,
                      accessKeyId: systemConfig.accessKeyId,
                      accessSecret: systemConfig.secretAccessKey,
                      roleArn: systemConfig.roleArn,
                      roleSessionName: systemConfig.roleSessionName
                    },
                    isApiV2: true,
                  })
                  const refreshToken = refreshTokenRes.data
                  return {
                    accessKeyId: refreshToken.AccessKeyId,
                    accessKeySecret: refreshToken.AccessKeySecret,
                    stsToken: refreshToken.SecurityToken
                  }
                }
              })
              // payload.callback({ client: client, callback_addr: callbackAddr, type: 9 }, err)
              // 兼容原来的上传图片接口则做如下更改
              return Promise.resolve({
                token: {client: client, type: 9},
                url: callbackAddr,
                callback_addr: callbackAddr,
                type: 9
              })
            } catch (error) {
              return Promise.reject(error.data);
            }
          } else if (domainItem.domain_type === 10) {
            try {
              const systemInfoRes: any = await network.request({
                cmdId: 370730,
                data: {tid: 0, option_code: 'AWS_STORAGE_CONFIGURE'}
              });
              const systemInfo = systemInfoRes.data;
              if (!systemInfo) {
                tmp(++index);
                return;
              }
              const systemConfig = JSON.parse(systemInfo.value);
              return Promise.resolve({
                token: {type: 10, systemConfig},
                url: callbackAddr,
                callback_addr: callbackAddr,
                type: 10
              });
            } catch (error) {
              return Promise.reject(error.data);
            }
          } else {
            const url = `${urlAll.split('//')[0]}//${urlAll.split('/')[2]}`
            try {
              let respone = await axios.get(url);
              if (respone.status === 200) {
                return Promise.resolve({token: data.token, url: urlAll, callback_addr: callbackAddr, type: 4})
              } else {
                return tmp(++index)
              }
            } catch (e) {
              // return Promise.reject(
              //   new NetworkError({
              //     info: e
              //   })
              // )
            }
          }
        }
        if (data && data.url && data.url.length > 0) {
          return tmp(0);
        } else {
          return Promise.reject('线路为空');
        }
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 上传图片
   * 新增了阿里云地址 则 type为9 为阿里云
   * payload.callback({ token: {client: client, type: 9}, url: callbackAddr, err)
   */
  async uploadImg({file, token, url}) {
    if (token.type === 9) { // 阿里云处理方式
      const path = `${window.location.hostname}/${+new Date()}_${file.name}`;
      try {
        let result = await token.client.put(path, file);
        if (result && result.res.status === 200) {
          return Promise.resolve({url: url + '/' + path})
        } else {
          return Promise.reject('服务器异常')
        }
      } catch (e) {
        return Promise.reject(e)
      }
    } else if (token.type === 10) { // AWS处理方式
      // @ts-ignore
      let AWS = window.AWS;
      return new Promise<any>((resolve, reject) => {
        let albumName = window.location.hostname;
        try {
          AWS.config.update({
            accessKeyId: token.systemConfig.ak,
            secretAccessKey: token.systemConfig.sk,
          })
          AWS.config.region = token.systemConfig.aws_area
          let bucket = new AWS.S3({params: {Bucket: token.systemConfig.bucketName}})
          let params: any = {
            Key: albumName + '/' + file.name,
            ContentType: file.type,
            Body: file,
            'Access-Control-Allow-Credentials': '*',
            ACL: 'public-read'
          }
          bucket.upload(params, (err, data) => {
            if (err) {
              return reject(err);
            } else {
              return resolve({url: data.Location})
            }
          });
        } catch (err) {
          return reject(err);
        }
      });
    } else { // 原来的常规处理方式
      const param = new FormData()
      param.append('file', file, file.name)
      param.append('token', token)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      try {
        let res: any = axios.post(url, param, config);
        return Promise.resolve(res.data)
      } catch (e) {
        return Promise.reject(e)
      }
    }
  },
}
