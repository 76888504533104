export default class helperAgent {
  static context = null

  // 获取
  static getMemberState(state) {
    let strState = '默认'
    switch (state) {
      case 0:
        strState = '默认'
        break
      case 1:
        strState = '在用'
        break
      case 2:
        strState = '已注销'
        break
      case 3:
        strState = '锁定'
        break
      case 9:
        strState = '预注册'
        break
      default:
    }
    return strState
  }

  static getBalanceType(balance) {
    let strType = ''
    if (balance) {
      switch (balance.balance_type_id) {
        case 1:
          strType = '本金'
          break
        case 2:
          strType = '本金（不可提取）'
          break
        case 11:
          strType = '赠金'
          break
        case 12:
          strType = '赠金1个月'
          break
        case 13:
          strType = '赠金2个月'
          break
        case 14:
          strType = '赠金6个月'
          break
        case 15:
          strType = '赠金12个月'
          break
        default:
      }
    }
    return strType
  }
}