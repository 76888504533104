/* eslint-disable */
export default class helperLMG {
  static LMGGameNames = {
    'BACCARAT': '百家乐',
    'DRAGON_TIGER': '龙虎',
    'ROULETTE': '轮盘',
    'BACCARAT_INSURANCE': '保险百家乐',
    'SICBO': '骰宝',
    'XOC_DIA': '色碟',
    'BULL_BULL': '牛牛'
  }

  static LMGPlayNames = {
    'BC_BANKER': '庄',
    'BC_BANKER_NC': '庄免佣',
    'BC_PLAYER': '闲',
    'BC_TIE': '和',
    'BC_BANKER_PAIR': '庄对',
    'BC_PLAYER_PAIR': '闲对',
    'BC_ARBITRARILY_PAIR': '任意对子',
    'BC_PERFECT_PAIR': '完美对子',
    'BC_BANKER_SUPER_SIX': '超级六点',
    'BC_BIG': '大',
    'BC_SMALL': '小',
    'BC_BANKER_INSURANCE': '庄保险',
    'BC_PLAYER_INSURANCE': '闲保险',
    'BC_B_TOTALPOINT_0': '庄总点数0',
    'BC_B_TOTALPOINT_1': '庄总点数1',
    'BC_B_TOTALPOINT_2': '庄总点数2',
    'BC_B_TOTALPOINT_3': '庄总点数3',
    'BC_B_TOTALPOINT_4': '庄总点数4',
    'BC_B_TOTALPOINT_5': '庄总点数5',
    'BC_B_TOTALPOINT_6': '庄总点数6',
    'BC_B_TOTALPOINT_7': '庄总点数7',
    'BC_B_TOTALPOINT_8': '庄总点数8',
    'BC_B_TOTALPOINT_9': '庄总点数9',
    'BC_P_TOTALPOINT_0': '闲总点数0',
    'BC_P_TOTALPOINT_1': '闲总点数1',
    'BC_P_TOTALPOINT_2': '闲总点数2',
    'BC_P_TOTALPOINT_3': '闲总点数3',
    'BC_P_TOTALPOINT_4': '闲总点数4',
    'BC_P_TOTALPOINT_5': '闲总点数5',
    'BC_P_TOTALPOINT_6': '闲总点数6',
    'BC_P_TOTALPOINT_7': '闲总点数7',
    'BC_P_TOTALPOINT_8': '闲总点数8',
    'BC_P_TOTALPOINT_9': '闲总点数9',
    'BC_B_PAIRCOMBINA': '庄对子组合',
    'BC_P_PAIRCOMBINA': '闲对子组合',
    'BC_B_ROUTINECARD': '庄例牌',
    'BC_P_ROUTINECARD': '闲例牌',
    'BC_B_DRAGONBONUS': '庄龙宝',
    'BC_P_DRAGONBONUS': '闲龙宝',
    'BC_PANDA': '熊猫',
    'BC_DRAGON': '龙',
    'RL_0': '直注',
    'RL_1': '直注',
    'RL_2': '直注',
    'RL_3': '直注',
    'RL_4': '直注',
    'RL_5': '直注',
    'RL_6': '直注',
    'RL_7': '直注',
    'RL_8': '直注',
    'RL_9': '直注',
    'RL_10': '直注',
    'RL_11': '直注',
    'RL_13': '直注',
    'RL_14': '直注',
    'RL_15': '直注',
    'RL_16': '直注',
    'RL_17': '直注',
    'RL_18': '直注',
    'RL_19': '直注',
    'RL_20': '直注',
    'RL_21': '直注',
    'RL_22': '直注',
    'RL_23': '直注',
    'RL_24': '直注',
    'RL_25': '直注',
    'RL_26': '直注',
    'RL_27': '直注',
    'RL_28': '直注',
    'RL_29': '直注',
    'RL_30': '直注',
    'RL_31': '直注',
    'RL_32': '直注',
    'RL_33': '直注',
    'RL_34': '直注',
    'RL_35': '直注',
    'RL_36': '直注',
    'RL_0_1': '分注',
    'RL_0_2': '分注',
    'RL_0_3': '分注',
    'RL_1_2': '分注',
    'RL_1_4': '分注',
    'RL_2_3': '分注',
    'RL_2_5': '分注',
    'RL_3_6': '分注',
    'RL_4_5': '分注',
    'RL_4_7': '分注',
    'RL_5_6': '分注',
    'RL_5_8': '分注',
    'RL_6_9': '分注',
    'RL_7_8': '分注',
    'RL_7_10': '分注',
    'RL_8_9': '分注',
    'RL_8_11': '分注',
    'RL_9_12': '分注',
    'RL_10_11': '分注',
    'RL_10_13': '分注',
    'RL_11_12': '分注',
    'RL_11_14': '分注',
    'RL_12_15': '分注',
    'RL_13_14': '分注',
    'RL_13_16': '分注',
    'RL_14_15': '分注',
    'RL_14_17': '分注',
    'RL_15_18': '分注',
    'RL_16_17': '分注',
    'RL_16_19': '分注',
    'RL_17_18': '分注',
    'RL_17_20': '分注',
    'RL_18_21': '分注',
    'RL_19_20': '分注',
    'RL_19_22': '分注',
    'RL_20_21': '分注',
    'RL_20_23': '分注',
    'RL_21_24': '分注',
    'RL_22_23': '分注',
    'RL_22_25': '分注',
    'RL_23_24': '分注',
    'RL_23_26': '分注',
    'RL_24_27': '分注',
    'RL_25_26': '分注',
    'RL_25_28': '分注',
    'RL_26_27': '分注',
    'RL_26_29': '分注',
    'RL_27_30': '分注',
    'RL_28_29': '分注',
    'RL_28_31': '分注',
    'RL_29_30': '分注',
    'RL_29_32': '分注',
    'RL_30_33': '分注',
    'RL_31_32': '分注',
    'RL_31_34': '分注',
    'RL_32_33': '分注',
    'RL_32_35': '分注',
    'RL_33_36': '分注',
    'RL_34_35': '分注',
    'RL_35_36': '分注',
    'RL_0_1_2': '衔注',
    'RL_0_2_3': '衔注',
    'RL_1_2_3': '衔注',
    'RL_4_5_6': '衔注',
    'RL_7_8_9': '衔注',
    'RL_10_11_12': '衔注',
    'RL_13_14_15': '衔注',
    'RL_16_17_18': '衔注',
    'RL_19_20_21': '衔注',
    'RL_22_23_24': '衔注',
    'RL_25_26_27': '衔注',
    'RL_28_29_30': '衔注',
    'RL_31_32_33': '衔注',
    'RL_34_35_36': '衔注',
    'RL_0_1_2_3': '角注',
    'RL_1_2_4_5': '角注',
    'RL_2_3_5_6': '角注',
    'RL_4_5_7_8': '角注',
    'RL_5_6_8_9': '角注',
    'RL_7_8_10_11': '角注',
    'RL_8_9_11_12': '角注',
    'RL_10_11_13_14': '角注',
    'RL_11_12_14_15': '角注',
    'RL_13_14_16_17': '角注',
    'RL_14_15_17_18': '角注',
    'RL_16_17_19_20': '角注',
    'RL_17_18_20_21': '角注',
    'RL_20_21_23_24': '角注',
    'RL_25_26_28_29': '角注',
    'RL_26_27_29_30': '角注',
    'RL_28_29_31_32': '角注',
    'RL_29_30_32_33': '角注',
    'RL_31_32_34_35': '角注',
    'RL_32_33_35_36': '角注',
    'RL_1_2_3_4_5_6': '线注',
    'RL_4_5_6_7_8_9': '线注',
    'RL_7_8_9_10_11_12': '线注',
    'RL_10_11_12_13_14_15': '线注',
    'RL_13_14_15_16_17_18': '线注',
    'RL_16_17_18_19_20_21': '线注',
    'RL_19_20_21_22_23_24': '线注',
    'RL_22_23_24_25_26_27': '线注',
    'RL_25_26_27_28_29_30': '线注',
    'RL_28_29_30_31_32_33': '线注',
    'RL_31_32_33_34_35_36': '线注',
    'RL_ROW_1': '列注',
    'RL_ROW_2': '列注',
    'RL_ROW_3': '列注',
    'RL_COL_1': '打注',
    'RL_COL_2': '打注',
    'RL_COL_3': '打注',
    'RL_EVEN': '双',
    'RL_RED': '红',
    'RL_BLACK': '黑',
    'RL_ODD': '单',
    'RL_BIG': '大',
    'RL_SMALL': '小',
    'DT_DRAGON': '龙',
    'DT_TIGER': '虎',
    'DT_TIE': '和',
    'DT_DRAGON_RED': '龙红',
    'DT_DRAGON_BLACK': '龙黑',
    'DT_DRAGON_ONE': '龙单',
    'DT_DRAGON_DOUBLE': '龙双',
    'DT_TIGER_RED': '虎红',
    'DT_TIGER_BLACK': '虎黑',
    'DT_TIGER_ONE': '虎单',
    'DT_TIGER_DOUBLE': '虎双',
    'SB_SMALL': '小',
    'SB_BIG': '大',
    'SB_ODD': '单',
    'SB_EVEN': '双',
    'SB_PAIR_1': '对子 11-66',
    'SB_PAIR_2': '对子 11-66',
    'SB_PAIR_3': '对子 11-66',
    'SB_PAIR_4': '对子 11-66',
    'SB_PAIR_5': '对子 11-66',
    'SB_PAIR_6': '对子 11-66',
    'SB_TRIPLE_1': '围骰 111-666',
    'SB_TRIPLE_2': '围骰 111-666',
    'SB_TRIPLE_3': '围骰 111-666',
    'SB_TRIPLE_4': '围骰 111-666',
    'SB_TRIPLE_5': '围骰 111-666',
    'SB_TRIPLE_6': '围骰 111-666',
    'SB_ANYTRIPLE': '全围',
    'SB_TWO_1_2': '组合',
    'SB_TWO_1_3': '组合',
    'SB_TWO_1_4': '组合',
    'SB_TWO_1_5': '组合',
    'SB_TWO_1_6': '组合',
    'SB_TWO_2_3': '组合',
    'SB_TWO_2_4': '组合',
    'SB_TWO_2_5': '组合',
    'SB_TWO_2_6': '组合',
    'SB_TWO_3_4': '组合',
    'SB_TWO_3_5': '组合',
    'SB_TWO_3_6': '组合',
    'SB_TWO_4_5': '组合',
    'SB_TWO_4_6': '组合',
    'SB_TWO_5_6': '组合',
    'SB_ANYONE_1': '三军，1-6',
    'SB_ANYONE_2': '三军，1-6',
    'SB_ANYONE_3': '三军，1-6',
    'SB_ANYONE_4': '三军，1-6',
    'SB_ANYONE_5': '三军，1-6',
    'SB_ANYONE_6': '三军，1-6',
    'SB_SUM_4': '点数',
    'SB_SUM_5': '点数',
    'SB_SUM_6': '点数',
    'SB_SUM_7': '点数',
    'SB_SUM_8': '点数',
    'SB_SUM_9': '点数',
    'SB_SUM_10': '点数',
    'SB_SUM_11': '点数',
    'SB_SUM_13': '点数',
    'SB_SUM_14': '点数',
    'SB_SUM_15': '点数',
    'SB_SUM_16': '点数',
    'SB_SUM_17': '点数',
    'XOC_ZERO': '全白',
    'XOC_ONE': '３白１红',
    'XOC_TWO': '２白２红',
    'XOC_THREE': '１白３红',
    'XOC_FOUR': '全红',
    'XOC_ODD': '单',
    'XOC_EVEN': '双',
    'BB_PALYER1_EQUAL': '闲1平倍',
    'BB_PALYER1_DOUBLE': '闲1翻倍',
    'BB_PALYER1_MANY': '闲1多倍',
    'BB_PALYER2_EQUAL': '闲2平倍',
    'BB_PALYER2_DOUBLE': '闲2翻倍',
    'BB_PALYER2_MANY': '闲2多倍',
    'BB_PALYER3_EQUAL': '闲3平倍',
    'BB_PALYER3_DOUBLE': '闲3翻倍',
    'BB_PALYER3_MANY': '闲3多倍'
  }

  static getLMGGameName(gameType) {
    let name = ''
    if (this.LMGGameNames[gameType]) {
      name = this.LMGGameNames[gameType]
    }
    return name
  }

  static getLMGGameTypes() {
    return this.LMGGameNames
  }

  static getLMGPlayName(list) {
    if (!list) {
      return
    }
    let playName = ''
    for (let i = 0; i < list.length; i++) {
      let itemBetType = list[i].betType
      if (this.LMGPlayNames[itemBetType]) {
        playName += (this.LMGPlayNames[itemBetType] + ',')
      }
    }
    playName = playName.substring(playName.length - 1, 0)
    return playName
  }

  static getLMGGameResult(gameType, gameResult) {
    if (!gameType || !gameResult) {
      return false
    }
    let gameStr = ''
    let name1 = ''
    let name2 = ''
    let name3 = ''
    let name4 = ''
    let name5 = ''
    if (gameResult === '-1') {
      gameStr = '已取消'
    } else {
      if (gameType === 'BACCARAT' || gameType === 'BACCARAT_INSURANCE' || gameType === 'DRAGON_TIGER' || gameType === 'BULL_BULL') {
        const gameResultList = JSON.parse(gameResult.split('~')[0])
        for (let i = 0; i < gameResultList.length; i++) {
          if (gameType === 'BACCARAT' || gameType === 'BACCARAT_INSURANCE') {
            // 百家乐 或者 保险百家乐
            if (i === 0 && gameResultList[0]) {
              name1 = {1: '庄', 2: '闲', 3: '和'}[gameResultList[i]]
            }
            if (i === 1 && gameResultList[1]) {
              name3 = {0: '', 1: '庄对', 2: '闲对', 3: '庄对闲对'}[gameResultList[i]]
            }
            if (i === 2 && gameResultList[2]) {
              name2 = {1: '小', 2: '大'}[gameResultList[i]]
            }
            gameStr = name3 ? name1 + ',' + name3 + ',' + name2 : name1 + ',' + name2
          } else if (gameType === 'DRAGON_TIGER') {
            // 龙虎
            if (i === 0 && gameResultList[0]) {
              name1 = {1: '虎赢', 2: '龙赢', 3: '和赢'}[gameResultList[i]]
            }
            if (i === 2 && gameResultList[2]) {
              name2 = {1: '龙单', 2: '龙双'}[gameResultList[i]]
            }
            if (i === 3 && gameResultList[3]) {
              name3 = {1: '虎单', 2: '虎双'}[gameResultList[i]]
            }
            if (i === 4 && gameResultList[4]) {
              name4 = {1: '龙红', 2: '龙黑'}[gameResultList[i]]
            }
            if (i === 5 && gameResultList[5]) {
              name5 = {1: '虎红', 2: '虎黑'}[gameResultList[i]]
            }
            gameStr = name1 + ',' + name2 + ',' + name3 + ',' + name4 + ',' + name5
          } else if (gameType === 'BULL_BULL') {
            // 牛牛
            if (i === 0) {
              if (!gameResultList[0]) {
                name1 = '无牛'
              } else if (gameResultList[0] > 0 && gameResultList[0] <= 9) {
                name1 = '牛' + gameResultList[0]
              } else if (gameResultList[0] === 10) {
                name1 = '牛牛'
              } else if (gameResultList[0] === 11) {
                name1 = '五公'
              }
            }
            if (i === 2) {
              if (!gameResultList[2]) {
                name2 = '无牛'
              } else if (gameResultList[2] > 0 && gameResultList[2] <= 9) {
                name2 = '牛' + gameResultList[2]
              } else if (gameResultList[2] === 10) {
                name2 = '牛牛'
              } else if (gameResultList[2] === 11) {
                name2 = '五公'
              }
            }
            if (i === 4) {
              if (!gameResultList[4]) {
                name3 = '无牛'
              } else if (gameResultList[4] > 0 && gameResultList[4] <= 9) {
                name3 = '牛' + gameResultList[4]
              } else if (gameResultList[4] === 10) {
                name3 = '牛牛'
              } else if (gameResultList[4] === 11) {
                name3 = '五公'
              }
            }
            if (i === 8) {
              if (!gameResultList[8]) {
                name4 = '无牛'
              } else if (gameResultList[8] > 0 && gameResultList[8] <= 9) {
                name4 = '牛' + gameResultList[8]
              } else if (gameResultList[8] === 10) {
                name4 = '牛牛'
              } else if (gameResultList[8] === 11) {
                name4 = '五公'
              }
            }
            gameStr = '庄:' + name1 + ';' + '闲1:' + name2 + ';' + '闲2:' + name3 + ';' + '闲3:' + name4
          }
        }
      } else if (gameType === 'XOC_DIA') {
        // 色碟
        let playStr = Number(JSON.parse(gameResult).toString())
        gameStr = {0: '全白', 1: '３白１红', 2: '２白２红', 3: '1白3红', 4: '4，全红'}[playStr]
      } else if (gameType === 'SICBO' || gameType === 'ROULETTE') {
        gameStr = JSON.parse(gameResult).toString()
      }
    }
    return gameStr
  }
}
