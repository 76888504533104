import { createI18n } from 'vue-i18n';
import { nextTick } from 'vue';
import qs from 'qs';

import { get, set } from '/@/kernel-mn/utils/storage';
import { LAN_TO_NUM } from '/@/hooks/common';
import { mb_t } from '/@/plugins/global';

let locale = 'en';
const localeLanguageCode = navigator.language.toLowerCase();
if (/^zh-/.test(localeLanguageCode) || localeLanguageCode === 'zh') {
  if (/cn|CN/.test(localeLanguageCode)) {
    // 中文(中国大陆简体)
    locale = 'cn';
  } else {
    // 中文（中国台湾，繁体）
    //locale = 'zh';
    // 暂时没繁体翻译
    locale = 'cn';
  }
} else if (/^en-/.test(localeLanguageCode) || localeLanguageCode === 'en' || localeLanguageCode === 'tl-ph' || localeLanguageCode === 'tl') {
  // 美国，英语(英国)，菲律宾
  locale = 'en';
/*} else if (/^es-/.test(localeLanguageCode) || localeLanguageCode === 'es') {
  // 西班牙语
  locale = 'es';
} else if (/^pl-/.test(localeLanguageCode) || localeLanguageCode === 'pl') {
  // 波兰
  locale = 'pl';
} else if (/^tr-/.test(localeLanguageCode) || localeLanguageCode === 'tr') {
  // 土耳其
  locale = 'tr';
} else if (/^ro-/.test(localeLanguageCode) || localeLanguageCode === 'ro') {
  // 罗马尼亚语
  locale = 'ro';
} else if (/^vi-/.test(localeLanguageCode) || localeLanguageCode === 'vi') {
  // 越南语
  locale = 'vi';
} else if (/^th-/.test(localeLanguageCode) || localeLanguageCode === 'th') {
  // 泰语
  locale = 'th';
} else if (/^pt-/.test(localeLanguageCode) || localeLanguageCode === 'pt') {
  // 葡萄牙语
  locale = 'pt';
} else if (/^ar-/.test(localeLanguageCode) || localeLanguageCode === 'ar') {
  // 阿拉伯语
  locale = 'ar';
} else if (/^hi-/.test(localeLanguageCode) || localeLanguageCode === 'hi') {
  // 印地语
  locale = 'hi';
} else if (/^id-/.test(localeLanguageCode) || localeLanguageCode === 'id') {
  // 印度尼西亚
  locale = 'id';
} else if (/^ms-/.test(localeLanguageCode) || localeLanguageCode === 'ms') {
  // 马来语
  locale = 'ms';
} else if (/^bn-/.test(localeLanguageCode) || localeLanguageCode === 'bn') {
  // 孟加拉语
  locale = 'bn';*/
}
// @ts-ignore
const local_lang = import.meta.env.VITE_LANG_NAME;
const qsObj: any = qs.parse(location.href.split('?')[1]) || {};
locale = qsObj.language || get('language') || local_lang || locale;

// 多语言转数字
try {
  LAN_TO_NUM(locale);
} catch (error) {
  console.error(error);
}

const i18n = createI18n({
  locale: locale,
  silentTranslationWarn: true
});

export async function setI18nLanguage(lang) {
  try {
    const messages = await import(`./lib/${locale}.js`);
    i18n.global.locale = lang;
    set('language', lang);
    // @ts-ignore
    i18n.global.setLocaleMessage(locale, messages.default);
    document.querySelector('html').setAttribute('lang', lang);
  } catch (e) {
    console.log(e);
  }
}

export async function loadLocaleMessages() {
  try {
    const messages = await import(`./lib/${locale}.js`);
    // @ts-ignore
    i18n.global.setLocaleMessage(locale, messages.default);
  } catch (e) {
    console.log(e);
  }
  return nextTick();
}

// 处理信用彩票cname
export const $tcn = cname => {
  if (cname.length > 2 && i18n.global.locale !== 'cn') {
    let tail2 = cname.substring(cname.length - 2, cname.length);
    if (tail2 === '信用') {
      return mb_t(cname.substring(0, cname.length - 2)) + ' C';
    }
    if (cname.length > 3) {
      let tail3 = cname.substring(cname.length - 3, cname.length);
      if (tail3 === '(信)') {
        return mb_t(cname.substring(0, cname.length - 3)) + ' C';
      }
    }
  }
  return mb_t(cname);
};

export const $t2en = val => {
  return { cn: 'cn', en: 'en', zh: 'cn' }[val] || 'en';
};

export const LANG_OPTIONS = {
  cn: '中文(简体)',
  en: 'English',
  // th: 'ภาษาไทย',
  // hi: 'हिन्दी',
  // vi: 'Tiếng Việt',
  // id: 'Bahasa Indonesia',
  // ms: 'Malay',
  // pt: 'Português',
  // es: 'Español',
  // tr: 'Turkey',
  // ar: 'عرب',
  // bn: 'বাংলা'
};

export const LANG_OPTIONS_ICON = {
  cn: 'i_China',
  en: 'i_English',
  // th: 'i_Thailand',
  // hi: 'i_India',
  // vi: 'i_Vietnam',
  // id: 'i_BahasaIndonesia',
  // ms: 'i_Malay',
  // pt: 'i_Portugal',
  // es: 'i_Spain',
  // tr: 'i_Turkey',
  // ar: 'i_Ar',
  // bn: 'i_Bn'
};

export default i18n;
