// 快3
export default {
  plays: {
    0: {
      rule_id: 37000,
      area_id: 37000,
      play_name: 'HZ',
      play_cname: '和值',
      unit_price: 1,
      dices: [1, 2, 3],
      Method: 0,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },
    100: {
      rule_id: 37100,
      area_id: 37100,
      play_name: '2THDX',
      play_cname: '二同号单选',
      unit_price: 1,
      dices: [1, 1, 3],
      Method: 6,
      note_tag: 'qianDX'
    },
    200: {
      rule_id: 37200,
      area_id: 37200,
      play_name: '2THFX',
      play_cname: '二同号复选',
      unit_price: 1,
      dices: [1, 1, 3],
      Method: 5,
      note_tag: 'count'
    },
    300: {
      rule_id: 37300,
      area_id: 37300,
      play_name: '2BTH',
      play_cname: '二不同号',
      unit_price: 1,
      dices: [1, 4, 4],
      Method: 7,
      note_tag: 'sscComb2'
    },
    400: {
      rule_id: 37400,
      area_id: 37400,
      play_name: '3BTH',
      play_cname: '三不同号',
      unit_price: 1,
      dices: [2, 3, 5],
      Method: 3,
      note_tag: 'sscComb3'
    },
    500: {
      rule_id: 37500,
      area_id: 37500,
      play_name: '3THDX',
      play_cname: '三同号单选',
      unit_price: 1,
      dices: [1, 1, 1],
      Method: 2,
      note_tag: 'count'
    },
    600: {
      rule_id: 37600,
      area_id: 37600,
      play_name: '3THTX',
      play_cname: '三同号通选',
      unit_price: 1,
      dices: [1, 1, 1],
      Method: 1,
      note_tag: 'count'
    },
    700: {
      rule_id: 37700,
      area_id: 37700,
      play_name: '3LHTX',
      play_cname: '三连号通选',
      unit_price: 1,
      dices: [1, 2, 3],
      Method: 4,
      note_tag: 'count'
    },

    701: {
      rule_id: 37000,
      area_id: 37000,
      play_name: 'HZ',
      play_cname: '和值',
      unit_price: 1,
      dices: [1, 2, 3],
      Method: 0,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },
    702: {
      rule_id: 37700,
      area_id: 37700,
      play_name: '3LHTX',
      play_cname: '三连号通选',
      unit_price: 1,
      dices: [1, 2, 3],
      Method: 4,
      note_tag: 'count'
    },
    703: {
      rule_id: 37500,
      area_id: 37500,
      play_name: '3THDX',
      play_cname: '三同号单选',
      unit_price: 1,
      dices: [1, 1, 1],
      Method: 2,
      note_tag: 'count'
    },
    704: {
      rule_id: 37600,
      area_id: 37600,
      play_name: '3THTX',
      play_cname: '三同号通选',
      unit_price: 1,
      dices: [1, 1, 1],
      Method: 1,
      note_tag: 'count'
    },
    705: {
      rule_id: 37200,
      area_id: 37201,
      play_name: '2THFX',
      play_cname: '二同号',
      unit_price: 1,
      dices: [1, 1, 3],
      Method: 5,
      note_tag: 'count'
    }
  }
}
