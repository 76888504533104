/**
 * 租户信息
 */
import {defineStore} from 'pinia';
import tenantFetch from './tenantFetch';
import {getJSON, getSession, setJSON} from "/@/kernel-mn/utils/storage";
import {accMul, isApp, isMobile} from '/@/kernel-mn/utils';
import config from "/@/kernel-mn/config";
import RNEvent from "/@/kernel-mn/utils/RNEvent";

// @ts-ignore
const PROJECT = import.meta.env.VITE_API_PROJECT; // 哪个项目

interface TenantInfo {
  proxy: number;
  domain_addr: string;
  webtitle: string; // 网站名字
  tid: number;
  rightIdDict: Object;
  tenant: {
    tid: number;
    [propName: string]: any;
  };
  sysOptions: {
    [propName: string]: any;
  };

  [propName: string]: any;
}

interface Countrys {
  [propName: string]: any;
}

export const useTenantStore = defineStore({
  id: 'tenant',
  state: () => {
    let tenantInfo: TenantInfo = {
      proxy: 0,
      domain_addr: '',
      webtitle: '',
      tid: 0,
      rightIdDict: {},
      tenant: {
        tid: 0
      },
      sysOptions: {},
    }
    const countrys: Countrys = {}
    const custContact = {
      Z1_confirm_password: 0,
      Z1_real_name: 0,
      Z1_nickname: 0,
      Z1_mobile_phone: 0,
      Z1_qq: 0,
      Z1_email: 0,
      Z1_zalo: 0,
      Z1_line: 0,
      Z1_facebook: 0,
      Z1_whatsApp: 0,
      Z1_code: 0,
      Z1_withdrawal_password: 0,
      Z1_telegram: 0,
      Z2_confirm_password: 0,
      Z2_real_name: 0,
      Z2_nickname: 0,
      Z2_mobile_phone: 0,
      Z2_qq: 0,
      Z2_email: 0,
      Z2_zalo: 0,
      Z2_line: 0,
      Z2_facebook: 0,
      Z2_whatsApp: 0,
      Z2_code: 0,
      Z2_withdrawal_password: 0,
      Z2_telegram: 0,
    }
    return {
      pageLoading: false,
      commissionList: [], // 佣金表
      tenantInfo,
      backupWebsites: [], // 备用地址
      registerSysOptions: {}, // 注册配置
      tResLoc: '', // 后台回来的主题名
      imgDomain: '',
      areaLists: [], // 汇率列表
      // 找出默认的后台计价方式 [默认第一个]
      defaultArea: {area: 'CNY', huilv: 1},
      // account 账号登录 email_captcha 邮件登录 phone_captcha 短信登录 email_pwd_captcha 邮箱密码登录
      loginMode: '',
      // account 账号注册 email_captcha 邮件注册 phone_captcha 短信注册 email_pwd_captcha 邮箱密码注册
      registerMode: '',
      // // 验证码是否显示
      verifyCodeFlag: false,
      // 发送短信的国际编号
      countrys,
      aseq: <any>{
        magic_key: '',
      },
      // 租户后台设置
      activeConfiguration: <any>{},
      // 控制是否显示的
      custContact: custContact,
      // 登录选项的各种配置
      sysLoginOptions: {
        CUST_LOGIN_CAPTCHA_MODE: {
          SMART_SWITCH: '',
          Z2_M_SMART_CAPTCHA_MODE: '',
          Z2_PC_SMART_CAPTCHA_MODE: '',
          PICTURE_SWITCH: '',
        },
        CUST_LOGIN_MODE: {},
        CUST_LOGIN_SPECIAL_CAPTCHA: '',
        SMS_OPEN_COUNTRY: {},
        BET_AMOUNT_UNIT: 1,
      },
      // 注册选项的各种配置
      sysRegisterOptions: {
        CUST_CONTACT_LIST: '',
        CUST_REGISTER_CAPTCHA_MODE: {
          SMART_SWITCH: '',
          Z2_M_SMART_CAPTCHA_MODE: '',
          Z2_PC_SMART_CAPTCHA_MODE: '',
          PICTURE_SWITCH: '',
        },
        CUST_REGISTER_MODE: {},
        CUST_REGISTER_PASSWORD_MODE: {
          PASSWORD_MODE: '',
        },
        SMS_OPEN_COUNTRY: {},
      },
      // 注册登录配置已经获取到了 不需要每次获取
      sysRegAndLoginOptLoaded: null, // 登录注册配置原始数据
      virtualCurrency: 0,
      bankList: [], // 银行卡列表
      showBargainingChip: 1,
      amountPK: 0,
      sideMenu: {
        left: [],
        right: [],
        leftDown: [],
        rightDown: []
      },
      IPLimit: false, // ip限制
      systemRepair: false, // 网站维护
    }
  },
  getters: {},
  actions: {
    ...tenantFetch,
    // 选择默认计价方式 update是否更新计价
    selectArea(data, update = false) {
      const KEY = 'localArea'; // 本地计价方式key
      const RNEventInstance = RNEvent.getInstance();
      const list = this.areaLists;
      if (isApp()) {
        try {
          RNEventInstance.readRecord(KEY, result => {
            const tmpStr = result ? result.result : '';
            if (tmpStr && tmpStr !== "[object Object]") { // 有数据
              const tmpObj = JSON.parse(tmpStr);
              let loc = list.find(item => item.area === tmpObj.area) || null; // 查找是否在列表中
              RNEventInstance.writeRecord(KEY, JSON.stringify(update ? data : (loc || data))); // 如果在就用原来的如果不在就用新数据
              this.defaultArea = update ? data : (loc || data);
            } else { // 没有数据
              this.defaultArea = data;
              RNEventInstance.writeRecord(KEY, JSON.stringify(data));
            }
          });
        } catch (error) {
          this.defaultArea = data;
          RNEventInstance.writeRecord(KEY, JSON.stringify(data));
        }
      } else {
        let tmpObj = getJSON(KEY) || {area: ''};
        let loc = list.find(item => item.area === tmpObj.area) || null; // 查找是否在列表中
        setJSON(KEY, update ? data : (loc || data));
        this.defaultArea = update ? data : (loc || data);
      }
    },
    setPageLoading(data) {
      this.pageLoading = data
    },
    setInitPage(data) {
      let newData = {}
      newData[data] = true
      if (this.tenantInfo.initPage) {
        this.tenantInfo.initPage = Object.assign(this.tenantInfo.initPage, newData)
      } else {
        this.tenantInfo.initPage = Object.assign({}, newData)
      }
    },
    // setVerifyCodeFlag() {
    //   const CUST_LOGIN_CAPTCHA_MODE = this.tenantInfo.sysOptions.CUST_LOGIN_CAPTCHA_MODE
    //   this.verifyCodeFlag = CUST_LOGIN_CAPTCHA_MODE.PICTURE_SWITCH === '1'
    // },
    // 规范数据结构
    standardTenant() {
      if (this.tenantInfo.sysOptions) {
        // 存入 sysLoginOptions 登录配置数据
        this.setContactConfig({sysLoginOptions: config.deepCopy(this.tenantInfo.sysOptions)})
        const CUST_LOGIN_CAPTCHA_MODE = this.tenantInfo.sysOptions.CUST_LOGIN_CAPTCHA_MODE
        if (CUST_LOGIN_CAPTCHA_MODE) {
          this.tenantInfo.sysOptions.CUST_LOGIN_CAPTCHA_MODE = JSON.parse(CUST_LOGIN_CAPTCHA_MODE)
          // this.setVerifyCodeFlag()
        }
        const CUST_LOGIN_MODE = this.tenantInfo.sysOptions.CUST_LOGIN_MODE
        if (CUST_LOGIN_MODE) {
          this.tenantInfo.sysOptions.CUST_LOGIN_MODE = JSON.parse(CUST_LOGIN_MODE)
        }
        const CUST_REGISTER_PASSWORD_MODE = this.tenantInfo.sysOptions.CUST_REGISTER_PASSWORD_MODE
        if (CUST_REGISTER_PASSWORD_MODE) {
          this.tenantInfo.sysOptions.CUST_REGISTER_PASSWORD_MODE = JSON.parse(CUST_REGISTER_PASSWORD_MODE)
          this.tenantInfo.sysOptions.PASSWORD_MODE = JSON.parse(CUST_REGISTER_PASSWORD_MODE).PASSWORD_MODE
        }
        const LANGUAGE_IS_DISPLAY = this.tenantInfo.sysOptions.LANGUAGE_IS_DISPLAY
        if (LANGUAGE_IS_DISPLAY) {
          this.tenantInfo.sysOptions.LANGUAGE_IS_DISPLAY = JSON.parse(LANGUAGE_IS_DISPLAY)
        }
        const PRICING_METHOD = this.tenantInfo.sysOptions.PRICING_METHOD
        if (PRICING_METHOD) {
          let PRICING = JSON.parse(PRICING_METHOD)
          this.tenantInfo.sysOptions.PRICING_METHOD = PRICING
          // 设置默认计价方式
          this.areaLists = PRICING;
          this.selectArea(PRICING[0]);
        }
        const SMS_OPEN_COUNTRY = this.tenantInfo.sysOptions.SMS_OPEN_COUNTRY
        if (SMS_OPEN_COUNTRY) {
          this.tenantInfo.sysOptions.SMS_OPEN_COUNTRY = JSON.parse(SMS_OPEN_COUNTRY)
          this.countrys = this.tenantInfo.sysOptions.SMS_OPEN_COUNTRY
        }
        const YIDUN_CAPTCHA_ID = this.tenantInfo.sysOptions.YIDUN_CAPTCHA_ID
        if (YIDUN_CAPTCHA_ID) {
          this.tenantInfo.sysOptions.YIDUN_CAPTCHA_ID = JSON.parse(YIDUN_CAPTCHA_ID)
        } else {
          this.tenantInfo.sysOptions.YIDUN_CAPTCHA_ID = {}
        }
        const BET_AMOUNT_UNIT = this.tenantInfo.sysOptions.BET_AMOUNT_UNIT
        if (BET_AMOUNT_UNIT) {
          this.showBargainingChip = Number(BET_AMOUNT_UNIT)
        }
        const PLAYPLAN_RESTRICT_REWARD_PER_TERMCODE = this.tenantInfo.sysOptions.PLAYPLAN_RESTRICT_REWARD_PER_TERMCODE
        if (PLAYPLAN_RESTRICT_REWARD_PER_TERMCODE) {
          this.amountPK = Number(accMul(PLAYPLAN_RESTRICT_REWARD_PER_TERMCODE, 0.0001))
        }
        const WAIHUI_TYPE_DATA = this.tenantInfo.sysOptions.WAIHUI_TYPE_DATA
        if (WAIHUI_TYPE_DATA) {
          this.tenantInfo.sysOptions.WAIHUI_TYPE_DATA = JSON.parse(WAIHUI_TYPE_DATA)
        }
      }
      if (this.tenantInfo.tenant) {
        // online_addr 主盘在线客服地址
        // online_addr1 代理盘1在线客服地址
        // online_addr2 代理盘2在线客服地址
        // online_addr3 代理盘3在线客服地址
        // type 主盘在线客服地址是否三方 0自己，1三方
        // type1 代理盘1在线客服地址是否三方 0自己，1三方
        // type2 代理盘2在线客服地址是否三方 0自己，1三方
        // type3 代理盘3在线客服地址是否三方 0自己，1三方
        // activity_address 主盘账号交易在线客服地址
        // activity_address1 代理盘1账号交易在线客服地址
        // activity_address2 代理盘2账号交易在线客服地址
        // activity_address3 代理盘3账号交易在线客服地址
        // zhjy_type 主盘账号交易在线客服地址是否三方 0自己，1三方
        // zhjy_type1 代理盘1账号交易在线客服地址是否三方 0自己，1三方
        // zhjy_type2 代理盘2账号交易在线客服地址是否三方 0自己，1三方
        // zhjy_type3 代理盘3账号交易在线客服地址是否三方 0自己，1三方
        let proxyType = (+this.tenantInfo.proxy === 0 ? '' : String(this.tenantInfo.proxy))
        this.tenantInfo.tenant.type = this.tenantInfo.tenant[`type${proxyType}`] !== undefined ? this.tenantInfo.tenant[`type${proxyType}`] : this.tenantInfo.tenant.type
        this.tenantInfo.tenant.zhjy_type = this.tenantInfo.tenant[`zhjy_type${proxyType}`] !== undefined ? this.tenantInfo.tenant[`zhjy_type${proxyType}`] : this.tenantInfo.tenant.zhjy_type
        this.tenantInfo.tenant.online_addr = this.tenantInfo.tenant[`online_addr${proxyType}`] !== undefined ? this.tenantInfo.tenant[`online_addr${proxyType}`] : this.tenantInfo.tenant.online_addr
        this.tenantInfo.tenant.activity_address = this.tenantInfo.tenant[`activity_address${proxyType}`] !== undefined ? this.tenantInfo.tenant[`activity_address${proxyType}`] : this.tenantInfo.tenant.activity_address
        // official_addr 安卓下载地址主盘
        // official_addr1 安卓下载地址代理盘1
        // official_addr2 安卓下载地址代理盘2
        // official_addr3 安卓下载地址代理盘3
        // app_reg_addr 苹果下载地址主盘
        // app_reg_addr1 苹果下载地址代理盘1
        // app_reg_addr2 苹果下载地址代理盘2
        // app_reg_addr3 苹果下载地址代理盘3
        this.tenantInfo.tenant.official_addr = this.tenantInfo.tenant[`official_addr${proxyType}`] !== undefined ? this.tenantInfo.tenant[`official_addr${proxyType}`] : this.tenantInfo.tenant.official_addr
        this.tenantInfo.tenant.app_reg_addr = this.tenantInfo.tenant[`app_reg_addr${proxyType}`] !== undefined ? this.tenantInfo.tenant[`app_reg_addr${proxyType}`] : this.tenantInfo.tenant.app_reg_addr
      }
    },
    // 设置项目主题 根据后端返回主题名 后期判断可以放这里面
    setTheme(theme = '') {
      // if (this.tenantInfo.tenant) {
      //   this.tResLoc = this.tenantInfo.tenant.res_loc
      // }
      let retheme = theme;
      if (PROJECT === 'haxi-app') {
        retheme = getSession('themeName') || 'red';
      } else if (['tiyu-app', 'tiyu-pc'].includes(PROJECT)) { // 体育主题修正
        let stheme = this.tenantInfo.tenant.res_loc.replace(/^ty_/, '');
        // retheme = getSession('themeName') || this.tenantInfo.tenant.res_loc; // 设置主题
        retheme = stheme || 'sport789'; // 设置主题
        this.tenantInfo.tenant.res_loc = retheme;
      } else {
        retheme = getSession('themeName') || this.tenantInfo.tenant.res_loc; // 设置主题
      }
      this.tResLoc = theme || retheme;
    },
    setContactConfig(data) {
      // 注册相关的配置
      if (data.sysOptions) {
        const sysRegisterOptions = data.sysOptions
        const CUST_CONTACT_LIST = sysRegisterOptions.CUST_CONTACT_LIST
        if (CUST_CONTACT_LIST) {
          const requiredArr = CUST_CONTACT_LIST.split(';')
          const tmpObj = {}
          requiredArr.forEach(item => {
            const current = item.split(',')
            current.forEach(cItem => {
              const cItemTmp = cItem.split(':')
              if (cItemTmp[0]) {
                tmpObj[cItemTmp[0]] = Number(cItemTmp[1])
              }
            })
          })
          this.custContact = tmpObj
        }
        const CUST_REGISTER_CAPTCHA_MODE = sysRegisterOptions.CUST_REGISTER_CAPTCHA_MODE
        if (CUST_REGISTER_CAPTCHA_MODE) {
          sysRegisterOptions.CUST_REGISTER_CAPTCHA_MODE = JSON.parse(CUST_REGISTER_CAPTCHA_MODE)
        }
        const CUST_REGISTER_MODE = sysRegisterOptions.CUST_REGISTER_MODE
        if (CUST_REGISTER_MODE) {
          sysRegisterOptions.CUST_REGISTER_MODE = JSON.parse(CUST_REGISTER_MODE)
          if (sysRegisterOptions.CUST_REGISTER_MODE?.account === '1') {
            this.registerMode = 'account'
          } else if (sysRegisterOptions.CUST_REGISTER_MODE?.phone_captcha === '1') {
            this.registerMode = 'phone_captcha'
          } else if (sysRegisterOptions.CUST_REGISTER_MODE?.email_captcha === '1') {
            this.registerMode = 'email_captcha'
          }
        }
        const CUST_REGISTER_PASSWORD_MODE = sysRegisterOptions.CUST_REGISTER_PASSWORD_MODE
        if (CUST_REGISTER_PASSWORD_MODE) {
          sysRegisterOptions.CUST_REGISTER_PASSWORD_MODE = JSON.parse(CUST_REGISTER_PASSWORD_MODE)
        }
        const SMS_OPEN_COUNTRY = sysRegisterOptions.SMS_OPEN_COUNTRY
        if (SMS_OPEN_COUNTRY) {
          sysRegisterOptions.SMS_OPEN_COUNTRY = JSON.parse(SMS_OPEN_COUNTRY)
        }
        const YIDUN_CAPTCHA_ID = sysRegisterOptions.YIDUN_CAPTCHA_ID
        if (YIDUN_CAPTCHA_ID) {
          sysRegisterOptions.YIDUN_CAPTCHA_ID = JSON.parse(YIDUN_CAPTCHA_ID)
        }
        this.sysRegisterOptions = sysRegisterOptions
      }
      // 登录相关的配置
      if (data.sysLoginOptions) {
        const sysLoginOptions = data.sysLoginOptions
        const CUST_LOGIN_CAPTCHA_MODE = sysLoginOptions.CUST_LOGIN_CAPTCHA_MODE
        if (CUST_LOGIN_CAPTCHA_MODE) {
          sysLoginOptions.CUST_LOGIN_CAPTCHA_MODE = JSON.parse(CUST_LOGIN_CAPTCHA_MODE)
        }
        const CUST_LOGIN_MODE = sysLoginOptions.CUST_LOGIN_MODE
        if (CUST_LOGIN_MODE) {
          sysLoginOptions.CUST_LOGIN_MODE = JSON.parse(CUST_LOGIN_MODE)
          if (sysLoginOptions.CUST_LOGIN_MODE?.account === '1') {
            this.loginMode = 'account'
          } else if (sysLoginOptions.CUST_LOGIN_MODE?.phone_captcha === '1') {
            this.loginMode = 'phone_captcha'
          } else if (sysLoginOptions.CUST_LOGIN_MODE?.email_captcha === '1') {
            this.loginMode = 'email_captcha'
          } else if (sysLoginOptions.CUST_LOGIN_MODE?.email_pwd_captcha === '1') {
            this.loginMode = 'email_pwd_captcha'
          }
        }
        const SMS_OPEN_COUNTRY = sysLoginOptions.SMS_OPEN_COUNTRY
        if (SMS_OPEN_COUNTRY) {
          sysLoginOptions.SMS_OPEN_COUNTRY = JSON.parse(SMS_OPEN_COUNTRY)
        }
        const YIDUN_CAPTCHA_ID = sysLoginOptions.YIDUN_CAPTCHA_ID
        if (YIDUN_CAPTCHA_ID) {
          sysLoginOptions.YIDUN_CAPTCHA_ID = JSON.parse(YIDUN_CAPTCHA_ID)
        }
        const BET_AMOUNT_UNIT = sysLoginOptions.BET_AMOUNT_UNIT
        if (BET_AMOUNT_UNIT) {
          this.showBargainingChip = Number(BET_AMOUNT_UNIT)
        }
        const PLAYPLAN_RESTRICT_REWARD_PER_TERMCODE = sysLoginOptions.PLAYPLAN_RESTRICT_REWARD_PER_TERMCODE
        if (PLAYPLAN_RESTRICT_REWARD_PER_TERMCODE) {
          this.amountPK = Number(accMul(PLAYPLAN_RESTRICT_REWARD_PER_TERMCODE, 0.0001))
        }
        this.sysLoginOptions = sysLoginOptions
      }
    },
    setRegisterMode(data) {
      this.registerMode = data
    },
    setLoginMode(data) {
      this.loginMode = data
    },
    setActiveConfiguration(data) {
      let obj = {}
      data.map((item) => {
        if (isMobile()) {
          obj[item.name] = Number(item.show_mobile)
        } else {
          obj[item.name] = Number(item.show_pc)
        }
      })
      setJSON('activeConfiguration', obj)
      this.activeConfiguration = obj
    },
    setIPLimitStatus(data) {
      this.IPLimit = data
    },
    setRepairStatus(data) {
      this.systemRepair = data
    },
  }
})
