import {useTenantStore} from '/@/kernel-mn/stores/tenant';
// @ts-nocheck
import {mb_t} from '/@/plugins/global';
// 错误提示
export default class NetworkError {
  private tag: string;
  private key: string;
  private info: string;
  private orginfo: string;
  private level: string;
  private origin: string;
  private cmd: string;
  private code: number;
  private logout: boolean;
  private update: boolean;
  private time: string;
  constructor(options) {
    this.tag = ''
    this.key = ''
    this.info = ''
    this.orginfo = ''
    this.level = ''
    this.origin = ''
    this.cmd = ''
    // this.code = 0
    this.code = -1
    this.logout = false
    this.update = false
    this.time = new Date().toLocaleString().split('GMT')[0]
    if (options) {
      if (options.key) {
        this.key = options.key
      }
      if (options.tag) {
        this.tag = options.tag
      }
      if (options.info) {
        this.info = options.info
      }
      if (options.level) {
        this.level = options.level
      }
      if (options.origin) {
        this.origin = options.origin
      }
      if (options.cmd) {
        this.cmd = options.cmd
      }
      if (options.code) {
        this.code = options.code
      }
      if (options.logout) {
        this.logout = options.logout
      }
      if (options.update) {
        this.update = options.update
      }
    }
    const tenantStore = useTenantStore();
    if (this.info.indexOf('invalid_token') !== -1) {
      this.info = '亲，麻烦重新登录哦'
    } else if (this.info.indexOf('Request failed with status code 500') !== -1) {
      this.info = '网络不给力，请检查网络' + ' (500)'
    } else if (this.info.indexOf('Request failed with status code 502') !== -1) {
      this.info = '网络不给力，请检查网络' + ' (502)';
      if ([371130, 381119].includes(+this.cmd)) {
        tenantStore.setRepairStatus(true)
      }
    } else if (this.info.indexOf('Error 503 Service Unavailable') !== -1) {
      this.info = '网络不给力，请检查网络' + ' (503)'
    } else if (this.info.indexOf('Request failed with status code 504') !== -1) {
      this.info = '网络不给力，请检查网络' + ' (504)'
    } else if (this.info.indexOf('timeout of 20000ms') !== -1) {
      this.info = '请求超时，请检查网络'
    } else if (this.info.indexOf('Error 400 Bad Request') !== -1) {
      this.info = '网络不给力，请重新尝试'
    } else if (this.info.indexOf('Network Error') !== -1) {
      this.info = '网络错误，请检查网络'
    } else {
      console.error('err.info:', this.info, this.cmd)
      // this.info = this.info
      if (this.info.includes('访问限制')) {
        tenantStore.setIPLimitStatus(true)
      }
      // [371130, 370433]返回了请求没有任何数据 才跳维护 其他接口忽略
      if (this.info.includes('系统维护中') || ([371130, 370433].includes(+this.cmd) && (this.info.includes('数据为空') || this.info.includes('请求没有返回任何数据')))) {
        tenantStore.setRepairStatus(true)
      }
    }
    this.orginfo = this.info;
    this.info = mb_t(this.info);
  }

  toString() {
    return mb_t(this.info)
  }

  print() {
    console.error('{code:' + this.code + ', tag:' + this.tag + ', message:' + this.info + ', cmd_id:' + this.cmd + ', time:"' + this.time + '"}')
  }
}
