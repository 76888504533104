// 虚拟币
export default {
  plays: {
    100: {
      rule_id: 37000,
      area_id: 38100,
      play_name: 'FT',
      play_cname: '反投',
      unit_price: 1,
      xnb: true,
      Method: 0,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },
    200: {
      rule_id: 37000,
      area_id: 38200,
      play_name: 'HZ',
      play_cname: '和值',
      unit_price: 1,
      xnb: true,
      Method: 0,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },
    300: {
      rule_id: 37000,
      area_id: 38300,
      play_name: 'ZT',
      play_cname: '正投',
      unit_price: 1,
      xnb: true,
      Method: 0,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },
    400: {
      rule_id: 37000,
      area_id: 38400,
      play_name: 'HZFT',
      play_cname: '和值反投',
      unit_price: 1,
      xnb: true,
      Method: 0,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },
    500: {
      rule_id: 37000,
      area_id: 38500,
      play_name: 'HEHZ',
      play_cname: '后二和值',
      unit_price: 1,
      xnb: true,
      Method: 0,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },
  }
}
