/**
 * 用于配置打开第三方游戏的配置，目前有竖屏id、是否锁屏游戏，以后可扩展其他的.
 */

export default {
  // 竖屏
  portraitGames: [
    60, 342, 345, 395, 400, 409, 412, 418, 416, 468, 506, 571, 340001, 340005, 340021, 343028, 343100, 343113, 343123, 394007, 399007, 403007, 403013, 403014, 403030, 403034, 403043, 403045, 403048,
    403056, 403064, 403072, 403077, 403115, 403124, 408002, 408003, 416001, 416002, 416003, 416004, 416005, 416006, 416007, 416008, 416009, 4160010, 4160011, 4160012, 4160013, 4160014, 4160015,
    4160016, 4160017, 4160018, 4160019, 4160020, 4160021, 4160022, 4160023, 4160024, 4160025, 4160026, 4160027, 4160028, 4160029, 4160030, 4160031, 4160032, 4160033, 4160034, 4160035, 4160036,
    4160037, 4160038, 4160039, 4160040, 4160041, 4150083, 4150084, 4150063, 419001, 419002, 419003, 419004, 419005, 419006, 419007, 422, 423, 413090, 394018, 394019, 394021, 394017, 394003, 394004,
    394007, 394015, 504, 403028, 509, 413086, 572, 593, 707, 708, 709, 710, 711, 925, 928, 930, 1031, 1308, 1312, 1313, 1314, 1315, 1317, 1318, 1322, 1324, 1326, 1385, 570, 750, 1380, 1382,
  ],
  // 不锁屏
  lockGames: [416003],
  // RN壳webview容器打开有问题的游戏，ios使用外置浏览器打开
  openInBroswerGames: [343, 412, 413, 414, 415, 419, 423, 504],
  // RN壳webview容器打开有问题的游戏，安卓和ios都使用外置浏览器打开
  openInBroswerGamesAll: [412, 711, 960, 980, 1322]
};
