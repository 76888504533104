/* eslint-disable */
export default class helperIG {
  static IgGameNames = {
    "ctiv": "让球猫王战赌城",
    "donq": "唐吉诃德",
    "eas": "让球复活节惊喜",
    "fdt": "德托里传奇",
    "fdtjg": "德托里传奇积寶游戏",
    "fff": "酷炫水果农场",
    "glg": "黄金体育竞技场",
    "glrj": "角斗士积宝",
    "hlf": "万圣节宝藏",
    "jpgt": "奖金巨人",
    "paw": "三只小猪与大灰狼",
    "phot": "紫色狂热",
    "pnp": "粉红豹",
    "shmst": "神秘夏洛克",
    "tht": "泰国佛寺",
    "tmqd": "三个火枪手",
    "zcjb": "招财进宝",
    "wlg": "舞龙",
    "aogs": "众神时代",
    "athn": "众神时代：智慧女神",
    "bfb": "犎牛闪电突击",
    "bs": "白狮",
    "ct": "船长的宝藏",
    "dcv": "海上寻宝",
    "fbr": "终极足球",
    "fkmj": "疯狂麻将",
    "fow": "惊异之林",
    "ftsis": "众神时代：命运姐妹",
    "furf": "众神时代：狂暴4",
    "gemq": "宝石女王",
    "ges": "艺伎故事",
    "ght_a": "烈焰钻石",
    "gos": "黄金旅途",
    "gro": "最强奥德赛",
    "gts50": "炙热宝石",
    "gts52": "维京狂热",
    "gtscnb": "警察和土匪",
    "gtsfc": "足球嘉年华",
    "gtsflzt": "飞龙在天",
    "gtsmrln": "玛丽莲梦露",
    "gtssmbr": "桑巴之舞",
    "gtswng": "纯金之翼",
    "hlf2": "万圣节宝藏 2",
    "hrcls": "众神时代：奥林匹斯王子",
    "irl": "幸运爱尔兰",
    "jqw": "金钱蛙",
    "mfrt": "幸运女士",
    "mgstk": "魔力老虎机",
    "mobdt": "沙漠财宝",
    "pgv": "企鹅假期",
    "pst": "法老王的秘密",
    "qnw": "权杖女王",
    "rng2": "罗马荣光",
    "sib": "银弹",
    "sis": "忍者风云",
    "srcg": "欧莱里之黄金大田",
    "sx": "四象",
    "topg": "捍卫战士",
    "tpd2": "泰国梦天堂",
    "zctz": "招财童子",
    "arc": "弓箭手",
    "bib": "海底探宝",
    "bob": "熊之舞",
    "catqc": "猫后",
    "dnr": "海豚之梦",
    "esm": "埃斯梅拉达",
    "fcgz": "翡翠公主",
    "fxf": "狐媚宝藏",
    "gtspor": "充裕财富",
    "hb": "狂欢夜",
    "hk": "高速公路之王",
    "kkg": "无敌金刚",
    "lm": "疯狂乐透",
    "nian": "年年有余",
    "pmn": "月亮下的黑豹",
    "rky": "洛奇",
    "ssp": "圣诞老人奇袭",
    "whk": "白狮王",
    "bld": "刀锋战士",
    "bld50": "刀锋战士 50线",
    "drd": "超胆侠",
    "elr": "艾丽卡",
    "ghr": "幽灵骑士",
    "glr": "角斗士",
    "iceh": "冰球大战",
    "sfh": "野生狩獵",
    "spm": "火腿骑士",
    "tfs": "顶级足球明星",
    "tst": "网球明星",
    "ttc": "王牌明星",
    "ttl": "传奇足球明星",
    "ttwfs": "顶级足球明星 2014",
    "wis": "狂野精灵",
    "4seasons": "四季",
    "afternightfalls": "夜幕降临",
    "alkemorstower": "魔幻塔",
    "atthecopa": "美洲舞会",
    "atthemovies": "看电影",
    "achristmascarol": "圣诞颂歌",
    "anightinparis": "巴黎之夜",
    "birds": "消消鸟",
    "blackgold": "黑金",
    "boomanji": "烟花",
    "drjekyllandmrhyde": "化身博士",
    "eventhorizon": "黑洞",
    "frankenslotsmonster": "科学怪人",
    "fruitzen": "水果忍者",
    "geniesfortune": "三个愿望",
    "gladiator": "角斗士",
    "great88": "幸运88",
    "gypsyrose": "吉普赛玫瑰",
    "heist": "抢劫者",
    "houseoffun": "欢乐屋",
    "lost": "迷失",
    "megaglamlife": "魅力生活",
    "megagems": "巨型宝石",
    "mrvegas": "维加斯先生",
    "puppylove": "宠物狗",
    "safarisam": "山姆历险记",
    "slotsangels": "天使老虎机",
    "sushibar": "寿司吧",
    "thecuriousmachine": "神奇机器",
    "thetipsytourist": "休闲之旅",
    "thetruesheriff": "正义警长",
    "treasureroom": "宝藏室",
    "trueillusions": "真实幻想",
    "tycoons": "企业大亨",
    "underthebed": "床底惊魂",
    "underthesea": "海底世界",
    "vikingage": "维京时代",
    "weekendinvegas": "维加斯周末",
    "5ReelDrive": "五轮驱动",
    "adventurePalace": "冒险丛林",
    "agentJaneBlonde": "城市猎人",
    "ageOfDiscovery": "大航海时代",
    "alaskanFishing": "阿拉斯加垂钓",
    "ariana": "阿丽亚娜",
    "barsNStripes": "美式酒吧",
    "basketballStar": "篮球巨星",
    "beachBabes": "海滩宝贝",
    "bigKahuna": "森林之王",
    "bigTop": "马戏团",
    "boogieMonsters": "布吉怪兽",
    "breakDaBankAgain": "银行抢匪",
    "burningDesire": "燃烧的欲望",
    "bushTelegraph": "丛林快讯",
    "breakAway": "冰球突破",
    "candyDreams": "梦果子乐园",
    "carnaval": "狂欢节",
    "cashapillar": "昆虫派对",
    "cashVille": "现金威乐",
    "centreCourt": "网球冠军",
    "classic243": "经典243",
    "coolBuck5Reel": "运财酷儿",
    "gameOfThrones": "权力的游戏",
    "gameOfThrones_Ways": "权力的游戏243ways",
    "coolWolf": "酷派浪人",
    "cricketStar": "板球明星",
    "deckTheHalls": "圣诞大餐",
    "emperorOfTheSea": "青龙出海",
    "eaglesWings": "疾风老鹰",
    "fishParty": "海底派对",
    "footballStar": "足球之巅",
    "forbiddenThrone": "禁忌的皇权",
    "fruitVSCandy": "水果VS糖果",
    "goldenEra": "黄金时代",
    "goldenPrincess": "黄金公主",
    "halloweenies": "万圣节派对",
    "happyHolidays": "开心假期",
    "harveys": "哈维斯的晚餐",
    "highSociety": "上流社会",
    "hitman": "终极杀手",
    "huangdiTheYellowEmperor": "轩辕帝传",
    "isis": "艾西斯",
    "jungleJimElDorado": "丛林吉姆黄金国",
    "karaokeParty": "K歌乐韵",
    "kathmandu": "加德满都",
    "kingsOfCash": "现金之王",
    "ladiesnite": "淑女之夜",
    "lifeOfRiches": "富裕人生",
    "lionsPride": "狮子的骄傲",
    "liquidGold": "液体黄金",
    "loaded": "幸运嘻哈",
    "luckyKoi": "幸运锦鲤",
    "luckyTwins": "幸运双星",
    "madHatters": "疯狂的帽子",
    "mayanPrincess": "玛雅公主",
    "mermaidsMillions": "百万美人鱼",
    "mysticDreams": "神秘的梦",
    "partyIsland": "派对岛屿",
    "pollenParty": "花粉之国",
    "prettyKitty": "可爱的猫咪",
    "purePlatinum": "白金俱乐部",
    "reelSpinner": "旋转大战",
    "reelThunder": "雷霆风暴",
    "rhymingReelsGeorgiePorgie": "乔治与柏志",
    "rhymingReelsHeartsAndTarts": "烘培西点餐厅秀",
    "rivieraRiches": "瑞维拉财宝",
    "rugbyStar": "橄榄球明星",
    "santaPaws": "冰雪圣诞村",
    "secretAdmirer": "暗恋者",
    "secretRomance": "秘密爱慕者",
    "shanghaiBeauty": "上海美女",
    "silverFang": "银狼",
    "soManyMonsters": "多多怪物",
    "soMuchCandy": "多多糖果",
    "soMuchSushi": "多多寿司",
    "springBreak": "海滨嘉年华",
    "starlightKiss": "星光之吻",
    "stashOfTheTitans": "泰坦帝国",
    "summerHoliday": "夏日度假",
    "summertime": "暑假时光",
    "sunTide": "太阳征程​",
    "supeItUp": "增强马力",
    "sureWin": "必胜",
    "tallyHo": "狐狸爵士",
    "theHeatIsOn": "火力全开",
    "theTwistedCircus": "反转马戏团",
    "thunderstruck": "雷神",
    "thunderstruckII": "雷神2",
    "tigersEye": "老虎之眼",
    "tombRaider": "古墓奇兵",
    "treasurePalace": "宝藏宫殿",
    "voila": "你瞧",
    "whatAHoot": "猫头鹰乐园",
    "winSumDimSum": "开心点心",
    "bigfootlhr": "寻找大脚怪",
    "bingobillions": "十亿宾果",
    "callofthecolosseumlhr": "罗马竞技场",
    "1can2can": "你行我行",
    "cherryblossoms": "樱花恋",
    "doctorlove": "医生之爱",
    "dragondrop": "龙降",
    "dragonpalace": "龙宫",
    "drloveonvacation": "医生之旅",
    "easterndragonlhr": "东方的龙",
    "easyslider": "易滑",
    "emperorsgarden": "皇宫花园",
    "foxinwins": "狐狸赢了",
    "geniewild": "精灵仙子",
    "greatwildelk": "超级野生麋鹿",
    "irisheyes": "爱尔兰之眼",
    "irisheyes2": "爱尔兰之眼2",
    "madmadmonkey": "疯狂的猴子",
    "medusa": "美杜莎",
    "merlinsmillionssuperbet": "梅林的魔法",
    "oilmania": "疯狂的石油",
    "owleyes": "猫头鹰的眼睛",
    "pizzaprize": "披萨大奖",
    "potioncommotion": "疯狂的药水",
    "300shields": "300盾",
    "spanisheyes": "西班牙的之眼",
    "spinsorceress": "旋转的女巫",
    "starmania": "星幻",
    "supersafari": "快乐旅行",
    "teddybearspicnic": "泰迪熊的野餐",
    "thecodfather": "教父",
    "titanstorm": "巨人风暴",
    "venetianrose": "威尼斯玫瑰",
    "volcanoeruption": "火山喷发",
    "wildcatcanyon": "原始森林",
    "wildwest": "荒凉西部",
    "luckyangler": "幸运的钓鱼者多雪",
    "wildwater": "急湍",
    "mythicmaiden": "神话少女",
    "stickers": "贴纸",
    "fruitshopchristmas": "鲜果店-圣诞版",
    "deadoralive": "生或死",
    "dazzle": "耀眼的光",
    "flowerschristmas": "花儿-圣诞版",
    "fireflies": "萤火虫",
    "flowers": "花儿",
    "jokerpro": "小丑专场",
    "piggyriches": "有钱的小猪",
    "secretsofchristmas": "圣诞的秘密",
    "neonstaxx": "霓虹灯",
    "jackandbeanstalk": "杰克和豆茎",
    "multipliermayhem": "驱动倍增器混乱",
    "fruitshop": "鲜果店",
    "copycats": "山寨猫",
    "sparks": "火花",
    "bollywoodstory": "宝莱坞故事",
    "wolfcub": "小狼崽",
    "highlights": "亚特兰提斯的秘密",
    "excalibur": "神剑",
    "magicportals": "魔法之门",
    "jackhammer": "杰克锤击",
    "kingofslots": "游戏之王",
    "starburst": "星爆",
    "wizards": "魔法世界",
    "hiddencoinsofegypt": "Coins of Egypt",
    "kawaiikitty": "可爱猫咪",
    "theangler": "垂钓者",
    "moregolddiggin": "掘金者",
    "rooksrevenge": "鲁克的复仇",
    "fafatwins": "发发姐妹",
    "pacoandthepoppingpeppers": "劲爆小辣椒",
    "fireandsteel": "点燃战火",
    "greedygoblins": "贪婪地精",
    "itcamefromvenus": "金星来者",
    "magicshoppe": "魔法商店",
    "arrival": "到达者",
    "enchanted": "魔法森林",
    "stampede": "兽奔",
    "goodgirlbadgirl": "天使与魔鬼"
  }

  static getIgGameName(sportType) {
    let sportName = ''
    if (this.IgGameNames[sportType]) {
      sportName = this.IgGameNames[sportType]
    }
    return sportName
  }

  static getIGGameTypeNames() {
    return this.IgGameNames
  }
}
