import { createRouter, createWebHashHistory, RouteRecordRaw, RouterOptions } from 'vue-router';

// 通过Vite的import.meta.glob()方法实现自动化导入路由
// @ts-ignore
const mainRouterModules = import.meta.glob('../layout/*.vue');
// @ts-ignore
const viewRouterModules = import.meta.glob('../views/**/*.vue');

// 子路由
const childRoutes = Object.keys(viewRouterModules).map(path => {
  const childName = path.match(/\.\.\/views\/(.*)\.vue$/)[1].split('/')[1];
  const tmpObj = {
    path: `/${childName.toLowerCase()}`,
    name: childName,
    component: viewRouterModules[path],
    meta: {
      hasFooter: false,
      keepAlive: false
    }
  };
  if (childName === 'Home' || childName === 'Mine') {
    tmpObj.meta.hasFooter = true;
  }
  return tmpObj;
});

// 根路由
const rootRoutes: any = Object.keys(mainRouterModules).map(path => {
  const name = path.match(/\.\.\/layout\/(.*)\.vue$/)[1].toLowerCase();
  const routePath = `/${name}`;
  if (routePath === '/index') {
    return {
      path: '/',
      name,
      redirect: '/home',
      component: mainRouterModules[path],
      children: childRoutes
    };
  }
});

const routes: Array<RouteRecordRaw> = rootRoutes;

const router = createRouter(<RouterOptions>{
  history: createWebHashHistory(),
  routes
});

const { currentRoute, replace, push } = router;
window['isRouteType'] = { currentRoute, replace, push };

router.beforeEach(async (to, from, next) => {
  next();
});
router.afterEach(async () => {
  // 切换路由后 将滚动条置顶
  let h = document.querySelector('.layout-content');
  h && h.scrollTo(0, 0);
});

export default router;
