/* 重庆时时彩-经典彩 */
export default {
  plays: {
    0: {
      rule_id: 54000,
      area_id: 54000,
      play_name: 'ZHP',
      play_cname: '整合盘',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    1: {
      rule_id: 54001,
      area_id: 54001,
      play_name: 'SZP',
      play_cname: '数字盘',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    2: {
      rule_id: 54002,
      area_id: 54002,
      play_name: 'LMP',
      play_cname: '两面盘',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    3: {
      rule_id: 54003,
      area_id: 54003,
      play_name: 'DIQ',
      play_cname: '第一球',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    4: {
      rule_id: 54004,
      area_id: 54004,
      play_name: 'D2Q',
      play_cname: '第二球',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    5: {
      rule_id: 54005,
      area_id: 54005,
      play_name: 'D3Q',
      play_cname: '第三球',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    6: {
      rule_id: 54006,
      area_id: 54006,
      play_name: 'D4Q',
      play_cname: '第四球',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    7: {
      rule_id: 54007,
      area_id: 54007,
      play_name: 'D5Q',
      play_cname: '第五球',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    }
  }
}