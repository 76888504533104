/**
 * 用户信息
 */
import {defineStore} from 'pinia'
import {getJSON, remove, setJSON} from '/@/kernel-mn/utils/storage'
import userFetch from './userFetch'
import accountFetch from './accountFetch'
import {accAdd, isApp, dateFormat} from "/@/kernel-mn/utils"
import RNEvent from '/@/kernel-mn/utils/RNEvent'
import {useBalanceListStore} from '/@/kernel-mn/stores/user/balanceLists'
import dailiFetch from "./dailiFetch.ts";

const rebatePercenStr = (rebate) => {
  if (isNaN(rebate)) {
    return '0.00 %'
  }
  let rebateStr = (rebate / 100).toString()
  const pos = rebateStr.indexOf('.')
  if (pos < 0) {
    rebateStr += '.'
  }
  for (let i = 0; i < 1 - pos; i++) {
    rebateStr += '0'
  }
  return rebateStr + ' %'
}

export const useUserStore = defineStore({
  id: 'user',
  state: () => {
    return {
      userInfo: {
        cust_id: 0,
        cust_name: '',
        hash_wallet_address: '',
        partner: {},
        partner_flag: 0,
      } as any,
      contact: {}, // 用户的联系信息
      verifyCode: {
        image: '',
        verifycode_key: '',
      },
      isCanShowYEB: false,
      checkPayIdIndex: 0, // 自助提现选中的是银行卡支付方式还是支付宝方式的下标
      currentBankInfo: {},
      allowCreateMuc: false, // 是否可以创建聊天群m
      deviceId: '', // 设备ID
      isCreateFict: false, // 是否有创建虚拟会员权限
      bindBanklist: [], // 用户绑定银行卡列表
      offineList: [], // 线下支付大类列表
      onLineList: [], // 线上支付大类列表
      quickList: [], // 快速充值
      certification: {}, // 会员认证信息
      deviceUDID: '', // 用户的设备的uuid
      defaultCard: [], // 用户的相关银行卡信息
      checkedDefaultCardInx: 0, // 选中的 defaultCard 相关银行卡信息下标
      cardTips: { // 银行卡提示文案
        xunihuobi: '',
      },
      qipaiHistoryData: {},
      userReportShow: true,
      userReportData: {
        currentPlay: {}
      },
      commissionList: [],
      myShareList: {},
      myShareListCode: {}, // 我的分享只有code 和 url
      myShareRebate: {}, // 我的分享 - 佣金
      allSummaryList: { // 个人游戏记录列表
        realtimePlayList: []
      },
      discountInfo: {is_showDiscount: 1}, // 是否展示优惠
      lotteryDialogShow: false, // 个人报表，团队游戏 彩票详情的弹窗显示
      pcothersGameHistory: {},
      thirdGamesResData: {},
      statisticsList: [], // 第三方游戏统计
      OTCbitLists: [], // otc全部币种
      OTCFBLists: [], // otc全部法币
      myCommissionlist: {} as any, // 我的佣金
      signInList: {} as any,
      vipSignInList: {},
      rescueFundListData: [],
      sharePlayers: 0, // 分享领工资有效玩家
      shareRecharge: 0, // 分享领工资充值
      shareCoding: 0, // 分享领工资打码
      activityListLeft: [],
      userTaskList: [], // 会员任务列表
      borrowTypeList: [],
      borrowTypeDate: '',
      bankConfig: {},
      // 充值成功后ws推送的数据 {amount, number, charge_type, cust_id}
      rechargeSuccess: null, // 充值类型 charge_type 0:线上 1线下 2手工
      // 提现成功后ws推送的数据 {amount, withdrw_type, cust_id}
      withdrawSuccess: null, // 提现类型 withdrw_type 1:成功 2拒绝
      localCountry: [], // 本地国别列表
      customerGetUrlQuery: '', // 在线客服请求的token数据
    }
  },
  getters: {
    isLogined: (state) => !!state.userInfo.cust_id, // 是否已经登录
    // 各彩种的基础返点
    uBaseRebates: (state) => {
      let rebates = {
        'rebate': 0, // 时时彩
        'rebate_ft': 0, // 福体
        'rebate_sc': 0, // 北京赛车
        'rebate_k3': 0, // 快3
        'rebate_115': 0, // 11选5
        'rebate_zr': 0, // 真人
        'rebate_dz': 0, // 电子游艺
        'rebate_ty': 0, // 体育
        'rebate_qp': 0, // 棋牌
        'rebate_ms': 0 // 六合彩
      }
      if (state.userInfo) {
        // 代理
        if (state.userInfo.partner_flag === 1 && state.userInfo.partner) {
          for (let k in rebates) {
            rebates[k] = state.userInfo.partner[k]
          }
        } else { // 普通用户
          for (let k in rebates) {
            rebates[k] = state.userInfo[k]
          }
        }
      }
      return rebates
    },
    isFreeUser: (state) => {
      if (state.userInfo.cust_name) {
        return state.userInfo.cust_name.substr(0, 5) === 'tUser'
      }
      return false
    },
    getRebatePercentStr: (state) => {
      const userInfo: any = state.userInfo || {}
      return rebatePercenStr(userInfo.rebate)
    },
    getSCRebatePercentStr: (state) => {
      const userInfo: any = state.userInfo || {}
      return rebatePercenStr(userInfo.rebate_sc)
    },
    get115RebatePercentStr: (state) => {
      const userInfo: any = state.userInfo || {}
      return rebatePercenStr(userInfo.rebate_115)
    },
    getK3RebatePercentStr: (state) => {
      const userInfo: any = state.userInfo || {}
      return rebatePercenStr(userInfo.rebate_k3)
    },
    getMSRebatePercentStr: (state) => {
      const userInfo: any = state.userInfo || {}
      return rebatePercenStr(userInfo.rebate_ms)
    },
    getFTRebatePercentStr: (state) => {
      const userInfo: any = state.userInfo || {}
      return rebatePercenStr(userInfo.rebate_ft)
    },
    getTCLRebatePercentStr: (state) => {
      const userInfo: any = state.userInfo || {}
      return rebatePercenStr(userInfo.rebate_tcl)
    },
    getZRRebatePercentStr: (state) => {
      const userInfo: any = state.userInfo || {}
      return rebatePercenStr(userInfo.rebate_zr)
    },
    getDZRebatePercentStr: (state) => {
      const userInfo: any = state.userInfo || {}
      return rebatePercenStr(userInfo.rebate_dz)
    },
    getTYRebatePercentStr: (state) => {
      const userInfo: any = state.userInfo || {}
      return rebatePercenStr(userInfo.rebate_ty)
    },
    getQPRebatePercentStr: (state) => {
      const userInfo: any = state.userInfo || {}
      return rebatePercenStr(userInfo.rebate_qp)
    },
    getBYRebatePercentStr: (state) => {
      const userInfo: any = state.userInfo || {}
      return rebatePercenStr(userInfo.rebate_by)
    },
    // 用户绑定的虚拟币卡列表 pay_org_id: 187
    XNBBankList: state => state.bindBanklist.filter(item => item.pay_org_id === 187),
    // 虚拟币支付大类
    XNBOffine: state => state.offineList, //.find(item => item.pay_org_id === 188) || {},
    // 虚拟币线上支付大类
    XNBOnLine: state => state.onLineList, //.find(item => item.pay_org_id === 187) || {},
  },
  actions: {
    setRechargeSuccessData(data = null) {
      this.rechargeSuccess = data || null;
    },
    setWithdrawSuccessData(data = null) {
      this.withdrawSuccess = data || null;
    },
    setIsCanShowYEB(data) {
      this.isCanShowYEB = data
    },
    // 用户签到列表
    setSignInList(data) {
      if (data.isClear) {
        this.signInList = {}
      } else {
        if (data) {
          let newData: any = {}
          newData.sindInList = [data.monday / 1000, data.tuesday / 1000, data.wednesday / 1000, data.thursday / 1000, data.friday / 1000, data.saturday / 1000, data.sunday / 1000]
          newData.isSignIn = Number(data.day_type) === 1
          newData.signDay = data.sign_day
          newData.isLevelReward = data.isLevelReward
          newData.webState = Number(data.web_state)
          newData.sign_type = Number(data.sign_type)
          this.signInList = newData
        } else {
          this.signInList = {}
        }
      }
    },
    vipSignInList(data) {
      let newData = []
      if (data.length) {
        data.forEach((item) => {
          newData.push(item.cumulative_attendance / 1000)
        })
      }
      this.vipSignInList = newData
    },
    setActivePlayers(data) {
      this.sharePlayers = data.standara.record || 0
      this.shareRecharge = data.cumulative_recharge || 0
      this.shareCoding = data.cumulative_coding || 0
    },
    setRescueFundListNew(data, isShow, payload) {
      let res = data
      let newArr = []
      // const ori = JSON.parse(JSON.stringify(data))
      if (res.length) {
        let rechargePack = []
        let rescueFund = []
        let giveAway = []
        let shareIt = []
        let virtualCurrency = []
        for (let i = 0; i < res.length; i++) {
          if (+res[i].type === 101) { // 充值大礼包
            if (isShow['2']) {
              let standara = res[i].standara
              let amount = standara.chargeAmount / 1000
              let arr = []
              let offset = [] // 充值金额与达标金额的差值
              for (let j = 1; j < 11; j++) {
                let list: any = {}
                let stan = 'standara' + j
                let standara_name = 'standara_name' + j
                let icon_url = 'icon_url' + j
                let explains = 'explains' + j
                if (standara[stan]) {
                  let listArr = standara[stan].split(':')
                  list.fraction = Number(listArr[0]) // 达标
                  list.reward = Number(listArr[1]) // 奖金
                  list.standara_name = standara[standara_name]
                  list.icon_url = standara[icon_url]
                  list.explains = standara[explains]
                  list.activity_standard = standara.activity_standard
                  list.recharge_type = standara.recharge_type
                  if (list.fraction !== 0) {
                    arr.push(list.fraction)
                    let off = amount - list.fraction
                    off >= 0 && offset.push(off) // 充值金额 - 达标金额
                    list.isReceive = 0
                    list.percentage = 0
                    list.startTime = res[i].eff_time
                    list.endTime = res[i].exp_time
                    list.chargeAmount = standara.chargeAmount
                    list.index = j - 1
                    rechargePack.push(list)
                  }
                }
              }
              for (let k = 0; k < arr.length; k++) {
                let item = arr[k]
                if (Date.now() < res[i].eff_time) {
                  rechargePack[k].isReceive = 3 // 即将开始
                } else {
                  if (item <= amount) {
                    rechargePack[k].percentage = 100
                    rechargePack[k].isReceive = 2 // 可领取
                  } else {
                    if (k === 0) {
                      if (amount === 0) {
                        rechargePack[k].percentage = 0
                      } else {
                        rechargePack[k].percentage = parseInt(String(amount / item * 100))
                      }
                    } else if (item > amount && amount > arr[k - 1]) {
                      rechargePack[k].percentage = parseInt(String((amount - arr[k - 1]) / (item - arr[k - 1]) * 100))
                    }
                  }
                }
                rechargePack[k].completed = amount < 0 ? 0 : amount
                if (standara.receive_amount) {
                  standara.receive_amount.forEach((item) => {
                    if (Number(item.receive_level) - 1 === k) {
                      rechargePack[k].isReceive = 1 // 已领取
                    }
                  })
                }
              }
              // 如果后台设置不能多重领取 则满足领取条件的选项走如下流程
              // activity_standard 1非多重 0多重 recharge_type 0全部 1线下 2线上 3首充
              if (res[i].activity_standard === 1) {
                rechargePack.forEach((item, idx) => {
                  if (item.isReceive === 2) {
                    if (idx < offset.length - 1) { // 手动领取时 满足领取的其他 不包括最后那一个
                      item.noRepeat = 1 // 不让领取
                    }
                  }
                  // 如果是首充类型 最大领取后面的进度条设置0
                  if (item.recharge_type === 3 && idx >= offset.length) {
                    item.percentage = 0
                  }
                })
              }
              res[i].standara = rechargePack
              newArr.push(res[i])
            }
          } else if (+res[i].type === 102) { // 有玩就有礼
            if (isShow['3']) {
              let standara = res[i].standara
              let amount = standara.PlayAmount / 1000
              let arr = []
              for (let c = 1; c < 11; c++) {
                let list: any = {}
                let stan1 = 'standara' + c
                let standara_name = 'standara_name' + c
                let icon_url = 'icon_url' + c
                let explains = 'explains' + c
                if (standara[stan1]) {
                  let listArr1 = standara[stan1].split(':')
                  list.fraction = Number(listArr1[0]) // 达标
                  list.reward = Number(listArr1[1]) // 奖金
                  list.standara_name = standara[standara_name]
                  list.icon_url = standara[icon_url]
                  list.explains = standara[explains]
                  if (list.fraction !== 0) {
                    arr.push(list.fraction)
                    list.isReceive = 0
                    list.percentage = 0
                    list.startTime = res[i].eff_time
                    list.endTime = res[i].exp_time
                    list.PlayAmount = standara.PlayAmount
                    rescueFund.push(list)
                    list.index = c - 1
                  }
                }
              }
              for (let l = 0; l < arr.length; l++) {
                let item = arr[l]
                if (Date.now() < res[i].eff_time) {
                  rescueFund[l].isReceive = 3 // 即将开始
                } else {
                  if (item <= amount) {
                    rescueFund[l].percentage = 100
                    rescueFund[l].isReceive = 2 // 可领取
                  } else {
                    if (l === 0) {
                      if (amount === 0) {
                        rescueFund[l].percentage = 0
                      } else {
                        rescueFund[l].percentage = parseInt(String(amount / item * 100))
                      }
                    } else if (item > amount && amount > arr[l - 1]) {
                      rescueFund[l].percentage = parseInt(String((amount - arr[l - 1]) / (item - arr[l - 1]) * 100))
                    }
                  }
                  rescueFund[l].completed = amount < 0 ? 0 : amount
                  if (standara.receive_amount) {
                    standara.receive_amount.forEach((item) => {
                      if (Number(item.receive_level) - 1 === l) {
                        rescueFund[l].isReceive = 1 // 已领取
                      }
                    })
                  }
                }
              }
              res[i].standara = rescueFund
              newArr.push(res[i])
            }
          } else if (+res[i].type === 103) {
            if (isShow['5']) {
              let standara = res[i].standara
              let isMultiple = res[i].is_multiple
              let amount = standara.Profit / 1000
              let arr = []
              for (let v = 1; v < 11; v++) {
                let list: any = {}
                let stan2 = 'standara' + v
                let standara_name = 'standara_name' + v
                let icon_url = 'icon_url' + v
                let explains = 'explains' + v
                if (standara[stan2]) {
                  let listArr2 = standara[stan2].split(':')
                  list.fraction = Number(listArr2[0]) // 达标
                  list.reward = Number(listArr2[1]) // 奖金
                  list.standara_name = standara[standara_name]
                  list.icon_url = standara[icon_url]
                  list.explains = standara[explains]
                  if (list.fraction !== 0) {
                    arr.push(list.fraction)
                    list.isReceive = 0
                    list.percentage = 0
                    list.startTime = res[i].eff_time
                    list.endTime = res[i].exp_time
                    list.Profit = standara.Profit
                    list.index = v - 1
                    giveAway.push(list)
                  }
                }
              }
              for (let o = 0; o < arr.length; o++) {
                let item = arr[o]
                if (Date.now() < res[i].eff_time) {
                  giveAway[o].isReceive = 3 // 即将开始
                } else if (amount !== 0) {
                  if (item <= amount) {
                    giveAway[o].percentage = 100
                    if (isMultiple === 2) {
                      giveAway[o].isReceive = 2
                      if (standara.logs && standara.logs.length > 0) {
                        for (let m = 0; m < standara.logs.length; m++) {
                          let num = o + 1
                          if (standara.logs[m].receive_level === num) {
                            giveAway[o].isReceive = 1
                          }
                        }
                      }
                    } else {
                      if (amount === item) giveAway[o].isReceive = 2
                    }
                  } else {
                    if (o === 0) {
                      giveAway[o].percentage = parseInt(String(amount / item * 100))
                    } else if (item >= amount && amount >= arr[o - 1]) {
                      giveAway[o].percentage = parseInt(String((amount - arr[o - 1]) / (item - arr[o - 1]) * 100))
                      if (isMultiple === 1) {
                        giveAway[o - 1].isReceive = 2
                        if (+standara.count === 1) giveAway[o - 1].isReceive = 1
                      }
                    }
                  }
                }
                giveAway[o].completed = amount < 0 ? 0 : amount
              }
              if (giveAway[arr.length - 1] && giveAway[arr.length - 1].fraction && giveAway[arr.length - 1].fraction <= amount) {
                giveAway[arr.length - 1].isReceive = 2
                if (+standara.count >= 1) giveAway[arr.length - 1].isReceive = 1
              }
              res[i].standara = giveAway
              newArr.push(res[i])
            }
          } else if (+res[i].type === 203) { // 新增的救援金活动
            let giveAway = []
            if (isShow['83']) {
              let standara = res[i].standara
              let isMultiple = res[i].is_multiple
              let amount = standara.Profit / 1000
              let arr = []
              for (let v = 1; v < 11; v++) {
                let list: any = {}
                let stan2 = 'standara' + v
                let standara_name = 'standara_name' + v
                let icon_url = 'icon_url' + v
                let explains = 'explains' + v
                if (standara[stan2]) {
                  let listArr2 = standara[stan2].split(':')
                  list.fraction = Number(listArr2[0]) // 达标
                  list.reward = Number(listArr2[1]) // 奖金
                  list.standara_name = standara[standara_name]
                  list.icon_url = standara[icon_url]
                  list.explains = standara[explains]
                  if (list.fraction !== 0) {
                    arr.push(list.fraction)
                    list.isReceive = 0
                    list.percentage = 0
                    list.startTime = res[i].eff_time
                    list.endTime = res[i].exp_time
                    list.Profit = standara.Profit
                    list.index = v - 1
                    giveAway.push(list)
                  }
                }
              }
              for (let o = 0; o < arr.length; o++) {
                let item = arr[o]
                if (Date.now() < res[i].eff_time) {
                  giveAway[o].isReceive = 3 // 即将开始
                } else if (amount !== 0) {
                  if (item <= amount) {
                    giveAway[o].percentage = 100
                    if (isMultiple === 2) {
                      giveAway[o].isReceive = 2
                      if (standara.logs && standara.logs.length > 0) {
                        for (let m = 0; m < standara.logs.length; m++) {
                          let num = o + 1
                          if (standara.logs[m].receive_level === num) {
                            giveAway[o].isReceive = 1
                          }
                        }
                      }
                    } else {
                      if (amount === item) giveAway[o].isReceive = 2
                    }
                  } else {
                    if (o === 0) {
                      // @ts-ignore
                      giveAway[o].percentage = parseInt(amount / item * 100)
                    } else if (item >= amount && amount >= arr[o - 1]) {
                      // @ts-ignore
                      giveAway[o].percentage = parseInt((amount - arr[o - 1]) / (item - arr[o - 1]) * 100)
                      if (isMultiple === 1) {
                        giveAway[o - 1].isReceive = 2
                        if (+standara.count === 1) giveAway[o - 1].isReceive = 1
                      }
                    }
                  }
                }
                giveAway[o].completed = amount < 0 ? 0 : amount
              }
              if (giveAway[arr.length - 1] && giveAway[arr.length - 1].fraction && giveAway[arr.length - 1].fraction <= amount) {
                isMultiple === 1 && (giveAway[arr.length - 1].isReceive = 2) // isMultiple =1 才有领取最后一个
                if (+standara.count >= 1) giveAway[arr.length - 1].isReceive = 1
              }
              res[i].standara = giveAway
              newArr.push(res[i])
            }
          } else if (+res[i].type === 106) { // 分享领工资
            if (isShow['4']) {
              this.setActivePlayers(res[i])
              let standara = res[i].standara
              let amount = standara.record
              let arr = []
              for (let z = 1; z < 11; z++) {
                let list: any = {}
                let stan2 = 'standara' + z
                let standara_name = 'standara_name' + z
                let icon_url = 'icon_url' + z
                let explains = 'explains' + z
                if (standara[stan2]) {
                  let listArr2 = standara[stan2].split(':')
                  list.fraction = Number(listArr2[0]) // 达标
                  list.reward = Number(listArr2[1]) // 奖金
                  list.standara_name = standara[standara_name]
                  list.icon_url = standara[icon_url]
                  list.explains = standara[explains]
                  if (list.fraction !== 0) {
                    arr.push(list.fraction)
                    list.isReceive = 0
                    list.percentage = 0
                    list.startTime = res[i].eff_time
                    list.endTime = res[i].exp_time
                    list.index = z - 1
                    shareIt.push(list)
                  }
                }
              }
              for (let x = 0; x < arr.length; x++) {
                let item = arr[x]
                if (item <= amount) {
                  shareIt[x].isReceive = 2
                  shareIt[x].percentage = 100
                }
                if (x === 0) {
                  if (amount === 0) {
                    shareIt[x].percentage = 0
                  } else {
                    if (item === amount) shareIt[x].isReceive = 2
                    shareIt[x].percentage = parseInt(String(amount / item * 100))
                    if (shareIt[x].percentage > 100) shareIt[x].percentage = 100
                  }
                } else if (item > amount && amount > arr[x - 1]) {
                  // shareIt[x - 1].isReceive = 2
                  shareIt[x].percentage = parseInt(String((amount - arr[x - 1]) / (item - arr[x - 1]) * 100))
                }
                shareIt[x].completed = amount < 0 ? 0 : amount
                if (standara.receive_amount) {
                  standara.receive_amount.forEach((item) => {
                    if (Number(item.receive_level) - 1 === x) {
                      shareIt[x].isReceive = 1 // 已领取
                    }
                  })
                }
              }
              res[i].standara = shareIt
              newArr.push(res[i])
            }
          } else if (+res[i].type === 111) { // 虚拟币充值
            if (isShow['32']) {
              let standara = res[i].standara
              let amount = standara.virtualAmount ? (standara.virtualAmount / 1000) : 0
              let arr = []
              for (let n = 1; n < 11; n++) {
                let list: any = {}
                let stan = 'standara' + n
                let standara_name = 'standara_name' + n
                let icon_url = 'icon_url' + n
                let explains = 'explains' + n
                if (standara[stan]) {
                  let listArr = standara[stan].split(':')
                  list.fraction = Number(listArr[0]) // 达标
                  list.reward = Number(listArr[1]) // 奖金
                  list.standara_name = standara[standara_name]
                  list.icon_url = standara[icon_url]
                  list.explains = standara[explains]
                  if (list.fraction !== 0) {
                    arr.push(list.fraction)
                    list.isReceive = 0
                    list.percentage = 0
                    list.startTime = res[i].eff_time
                    list.endTime = res[i].exp_time
                    list.index = n - 1
                    virtualCurrency.push(list)
                  }
                }
              }
              for (let m = 0; m < arr.length; m++) {
                let item = arr[m]
                if (Date.now() < res[i].eff_time) {
                  virtualCurrency[m].isReceive = 3 // 即将开始
                } else {
                  if (item <= amount) {
                    virtualCurrency[m].percentage = 100
                    virtualCurrency[m].isReceive = 2 // 可领取
                  } else {
                    if (m === 0) {
                      if (amount === 0) {
                        virtualCurrency[m].percentage = 0
                      } else {
                        virtualCurrency[m].percentage = parseInt(String(amount / item * 100))
                      }
                    } else if (item > amount && amount > arr[m - 1]) {
                      virtualCurrency[m].percentage = parseInt(String((amount - arr[m - 1]) / (item - arr[m - 1]) * 100))
                    }
                  }
                }
                virtualCurrency[m].completed = amount < 0 ? 0 : amount
                if (standara.receive_amount) {
                  standara.receive_amount.forEach((item) => {
                    if (Number(item.receive_level) - 1 === m) {
                      virtualCurrency[m].isReceive = 1 // 已领取
                    }
                  })
                }
              }
              res[i].standara = virtualCurrency
              newArr.push(res[i])
            }
          } else if (+res[i].type === 110) {
            if (isShow['22']) {
              res[i].standara = [true]
              newArr.push(res[i])
            }
          } else if (+res[i].type === 112) {
            if (isShow['35']) {
              res[i].standara = []
              newArr.push(res[i])
            }
          } else if (+res[i].type === 113) {
            if (isShow['50']) {
              res[i].standara = []
              newArr.push(res[i])
            }
          } else if (payload.showSubtasks) {
            if (+res[i].type === 114) { // 游戏任务彩票
              res[i].standara = [true]
              newArr.push(res[i])
            }
            if (+res[i].type === 115) { // 游戏任务棋牌
              res[i].standara = [true]
              newArr.push(res[i])
            }
            if (+res[i].type === 116) { // 游戏任务电子
              res[i].standara = [true]
              newArr.push(res[i])
            }
            if (+res[i].type === 117) { // 游戏任务真人
              res[i].standara = [true]
              newArr.push(res[i])
            }
            if (+res[i].type === 118) { // 游戏任务捕鱼
              res[i].standara = [true]
              newArr.push(res[i])
            }
            if (+res[i].type === 119) { // 游戏任务体育
              res[i].standara = [true]
              newArr.push(res[i])
            }
          }
        }
        if (newArr && newArr.length > 0) {
          newArr.sort((a, b) => a.sort_v - b.sort_v)
        }
      }
      this.rescueFundListData = newArr
    },
    /**
     * 清除用户数据
     */
    clearUserinfo() {
      const RNEventInstance = RNEvent.getInstance();
      const balanceListStore = useBalanceListStore();
      this.userInfo = {};
      this.bindBanklist = [];
      this.offineList = [];
      this.certification = {};
      balanceListStore.$reset(); // 清空用户币的数据
      if (isApp()) {
        RNEventInstance.writeRecord('userInfo', {});
      } else {
        remove('userInfo')
      }
    },
    // 更新用户数据
    saveUser(newData) {
      const RNEventInstance = RNEvent.getInstance();
      let userInfo = getJSON('userInfo') || {};
      // 初始化用户数据
      this.userInfo = {...userInfo, ...newData}
      setJSON('userInfo', this.userInfo)

      if (isApp()) {
        RNEventInstance.readRecord('userInfo', result => {
          const tmpStr = result.result
          // 初始化用户数据
          if (tmpStr) {
            const tmpObj = JSON.parse(tmpStr);
            if (tmpObj.cust_id) {
              this.userInfo = {...tmpObj, ...newData};
              RNEventInstance.writeRecord('userInfo', this.userInfo);
            }
          } else {
            this.userInfo = {...newData};
            RNEventInstance.writeRecord('userInfo', this.userInfo);
          }
        });
      }
    },
    setUserInfo(data) {
      this.userInfo = data
    },
    setpcOthersGameHistory(data) {
      this.pcothersGameHistory = data
    },
    setLotteryDialogShow(data) {
      this.lotteryDialogShow = data
    },
    setQiPaiHistoryData(data) {
      if (data.t == null) {
        data.t = {}
      }
      this.qipaiHistoryData = Object.assign({}, data)
    },
    setQiPaiHistoryDataAll(data) {
      let newQipaiHistoryData = Object.assign({}, this.qipaiHistoryData)
      let list = newQipaiHistoryData.data
      newQipaiHistoryData.data = list.concat(data.data)
      this.qipaiHistoryData = Object.assign({}, newQipaiHistoryData)
    },
    setUserReportShow(show) {
      this.userReportShow = show
    },
    setUserReportData(data) {
      this.userReportData = data
    },
    setDeviceUDID(deviceUDID) {
      this.deviceUDID = deviceUDID || ''
    },
    // 392001请求游戏记录，返回的数据解析
    setThirdGamesResData(data) {
      this.thirdGamesResData = data
    },
    setStatisticsList(data) {
      let obj: any = {
        plan_count: 0,
        plan_amount: 0,
        agent_rebat_amount: 0,
        self_rebat_amount: 0,
        plan_profit: 0,
        self_rebat_sum: 0
      }
      if (data.big_type === 2) {
        obj.play_name = '棋牌投注汇总'
      } else if (data.big_type === 3) {
        obj.play_name = '电子投注汇总'
      } else if (data.big_type === 4) {
        obj.play_name = '捕鱼投注汇总'
      } else if (data.big_type === 5) {
        obj.play_name = '真人投注汇总'
      } else if (data.big_type === 6) {
        obj.play_name = '体育投注汇总'
      } else if (data.big_type === 8) {
        obj.play_name = '红包投注汇总'
      } else if (data.big_type === 7) {
        obj.play_name = '国际彩票汇总'
      }
      obj.stateStr = '-'
      if (data.data && data.data.length) {
        data.data.map((item, index) => {
          data.data[index].stateStr = '详情'
          obj.plan_count = accAdd(obj.plan_count + '', item.plan_count + '')
          obj.plan_amount = accAdd(obj.plan_amount + '', item.plan_amount + '')
          obj.agent_rebat_amount = accAdd(obj.agent_rebat_amount + '', item.agent_rebat_amount + '')
          obj.self_rebat_amount = accAdd(obj.self_rebat_amount + '', item.self_rebat_amount + '')
          obj.plan_profit = accAdd(obj.plan_profit + '', item.plan_profit + '')
          obj.self_rebat_sum = accAdd(obj.self_rebat_sum + '', item.self_rebat_sum + '')
        })
        data.data.push(obj)
      }
      this.statisticsList = data.data
    },
    setUserTaskList(data) {
      let newData = [...data]
      newData.map(item => {
        item.needNumber = item.needNumber / 1000
        item.reward = item.reward / 1000
        item.alreadyNumber_old = item.alreadyNumber
        let title = ''
        let aNum = item.alreadyNumber
        let oNum = item.days
        switch (item.statProject) {
          case 1:
            title = '累计充值' + item.needNumber
            aNum = item.alreadyNumber / 1000
            oNum = item.needNumber
            break
          case 2:
            title = '累计充值' + item.days + '天'
            break
          case 3:
            title = '连续充值' + item.days + '天'
            break
          case 4:
            title = '首充' + item.needNumber
            aNum = item.alreadyNumber / 1000
            oNum = item.needNumber
            break
          case 5:
            title = '单日首充' + item.needNumber
            aNum = item.alreadyNumber / 1000
            oNum = item.needNumber
            break
          case 101:
            title = '累计打码' + item.needNumber
            aNum = item.alreadyNumber / 1000
            oNum = item.needNumber
            break
          case 102:
            title = '累计彩票打码' + item.needNumber
            aNum = item.alreadyNumber / 1000
            oNum = item.needNumber
            break
          case 103:
            title = '累计电子打码' + item.needNumber
            aNum = item.alreadyNumber / 1000
            oNum = item.needNumber
            break
          case 104:
            title = '累计真人打码' + item.needNumber
            aNum = item.alreadyNumber / 1000
            oNum = item.needNumber
            break
          case 105:
            title = '累计体育打码' + item.needNumber
            aNum = item.alreadyNumber / 1000
            oNum = item.needNumber
            break
          case 106:
            title = '累计捕鱼打码' + item.needNumber
            aNum = item.alreadyNumber / 1000
            oNum = item.needNumber
            break
          case 107:
            title = '累计棋牌打码' + item.needNumber
            aNum = item.alreadyNumber / 1000
            oNum = item.needNumber
            break
          case 108: // hashgame
            title = '累计hashgame打码' + item.needNumber
            aNum = item.alreadyNumber / 1000
            oNum = item.needNumber
            break
          case 111:
            title = '累计打码' + item.days + '天'
            break
          case 112:
            title = '累计彩票打码' + item.days + '天'
            break
          case 113:
            title = '累计电子打码' + item.days + '天'
            break
          case 114:
            title = '累计真人打码' + item.days + '天'
            break
          case 115:
            title = '累计体育打码' + item.days + '天'
            break
          case 116:
            title = '累计捕鱼打码' + item.days + '天'
            break
          case 117:
            title = '累计棋牌打码' + item.days + '天'
            break
          case 118:
            title = '累计hashgame打码' + item.days + '天'
            break
          case 121:
            title = '连续打码' + item.days + '天'
            break
          case 122:
            title = '连续彩票打码' + item.days + '天'
            break
          case 123:
            title = '连续电子打码' + item.days + '天'
            break
          case 124:
            title = '连续真人打码' + item.days + '天'
            break
          case 125:
            title = '连续体育打码' + item.days + '天'
            break
          case 126:
            title = '连续捕鱼打码' + item.days + '天'
            break
          case 127:
            title = '连续棋牌打码' + item.days + '天'
            break
          case 128:
            title = '连续hashgame打码' + item.days + '天'
            break
        }
        let taskDate = ''
        let weekObj = {
          1: '一',
          2: '二',
          3: '三',
          4: '四',
          5: '五',
          6: '六',
          7: '日',
        }
        let dateArr = item.statDateTypeDetail.split(':')
        switch (item.statDateType) {
          case 1:
            taskDate = '每日'
            break
          case 2:
            taskDate = dateFormat(Number(dateArr[0]), 2) + '-' + dateFormat(Number(dateArr[1]), 2)
            break
          case 3:
            taskDate = dateFormat(Number(dateArr[0]), 2) + '-' + dateFormat(Number(dateArr[1]), 2)
            break
          case 4:
            taskDate = '每周' + weekObj[item.statDateTypeDetail]
            break
          case 5:
            taskDate = '每月' + item.statDateTypeDetail + '日'
            break
        }
        item.proTitle = title
        item.taskDate = taskDate
        let percentage = aNum / oNum * 100
        item.percentage = percentage <= 100 ? percentage : 100
        item.alreadyNumber = item.aNum = aNum
        item.oNum = oNum
      })
      this.userTaskList = newData
    },
    setBorrowInfo(data) {
      this.borrowTypeList = data.type
      this.borrowTypeDate = data.date
    },
    ...userFetch,
    ...accountFetch,
    ...dailiFetch,
  }
})
