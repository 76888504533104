// @ts-nocheck

import axios from 'axios';

import config from '../config';
import * as helper from './helper';
import {uuid, isMobile, isIOS, getBetPlatform} from '../utils';
import {useUserStore} from '../stores/user/index';
import {mb_handleError} from '/@/plugins/global'
import {LAN_TO_NUM} from '/@/hooks/common'
import i18n from '/@/languages'

let source = axios.CancelToken.source();

// 记录该请求是否为正在发送中
const pingCmdIds = {}; // 记录所有正在请求的接口id
const restrict = {}; // 只记录了限制重复请求的接口
// 接口是否限制重复请求
const restrictCmdIds = {
  '320604': true, // 投注接口
  '341400': true, // 线下充值
  '341200': true, // 提现
  '394012': true, // 任务领取
  '374002': true, // 签到
  '340334': true, // 转入保险箱
  '340335': true, // 转入余额
  '321543': true, // 第三方游戏状态
  '314501': true, // 请求第三方游戏url
  '374001': true, // 晋级奖励和俸禄领取
  '310136': true, // 绑定银行卡验证密码
  '310400': true, // 绑定银行卡
  '400088': true, // 追号【一键开启】功能
  '340234': true, // 查询余额
  '371130': true, // 域名租户关系
  '320567': true // 收藏
};

const networkType = {
  ERROR: 'error', // 错误
  SUCCESS: 'success' // 成功
};

// 请求到的数据返回
export interface NetworkResponse {
  type: string;
  data: Object;
}

// 网络请求
const methods = {
  /**
   * @name 接口请求
   * @param @requires payload.cmdId 接口编号
   * @param @requires payload.data 传递的参数，JSON格式
   * @param payload.paramType 参数的拼接方法 0:字符串 1:json 2:restful
   * @param payload.testLogin 请求前是否检查登录状态
   * @param payload.headerType 消息头类型(签名) 1:普通接口 2:鉴权接口 3:刷新AccessToken接口
   * @param payload.inHeaders 要传递的消息头的值{key:value}
   * @param payload.callback 回调 function (res, err) {}
   */
  request(inPayLoad = {}): Promise<NetworkResponse> {
    const payLoad = {
      paramType: 0,
      testLogin: false,
      headerType: 2,
      data: {},
      isApiV2: false,
      Nc: inPayLoad.cmdId === 371130 ? '2' : '1',
      ...inPayLoad,
      // 复杂对象放这下面进行处理
      inHeaders: {
        isMobileOpen: isMobile() ? '1' : '0',
        languagenum: LAN_TO_NUM(i18n.global.locale || window.localStorage.getItem('language')),
        Platform: getBetPlatform(), // 哪端
        Project: import.meta.env.VITE_API_PROJECT, // 哪个项目
        ...inPayLoad.inHeaders
      }
    };
    // @ts-ignore
    let baseURL = import.meta.env.VITE_BASE_URL || '';
    if (Number(payLoad.cmdId) === 371138) {
      baseURL += '/api/v3';
    } else if (payLoad.isApiV2) {
      baseURL += '/api/v2';
    } else {
      baseURL += '/api/v1';
    }
    if (restrict[payLoad.cmdId.toString()]) {
      console.error('重复提交===>', payLoad.cmdId);
      return;
    }
    let headers = {};
    if (payLoad.headerType === 1) {
      headers = helper.getNormalHeaders(payLoad.cmdId);
    } else if (payLoad.headerType === 2) {
      headers = helper.getAuthHeaders(payLoad.cmdId);
    } else if (payLoad.headerType === 3) {
      headers = helper.getRefreshTokenHeaders(payLoad.cmdId);
    }
    headers.Reqid = uuid(32, 16);
    // 将接口记录起来
    pingCmdIds[payLoad.cmdId.toString()] = true;
    if (restrictCmdIds[payLoad.cmdId.toString()]) {
      restrict[payLoad.cmdId.toString()] = true;
    }
    return new Promise((resolve, reject) => {
      localStorage.getItem('查看参数') && console.warn(inPayLoad.cmdId + '：requestData', payLoad.data)
      helper.reqMake(headers, payLoad.data, payLoad.paramType, (newHeaders, newData) => {
        newHeaders = {...newHeaders, ...payLoad.inHeaders};
        // 苹果要加这句话，POST才能生效
        if (isIOS() && config.appVersion > 0) {
          newHeaders['ios_data'] = newData;
        }

        const userStore = useUserStore();
        axios({
          method: 'post',
          url: '/',
          baseURL: baseURL,
          timeout: inPayLoad.timeout || 1000 * 120,
          headers: newHeaders,
          data: newData,
          emulateJSON: true,
          cancelToken: source.token
        })
          .then(response => {
            if (restrict[payLoad.cmdId.toString()]) delete restrict[payLoad.cmdId.toString()];
            if (pingCmdIds[payLoad.cmdId + '']) delete pingCmdIds[payLoad.cmdId + ''];
            helper.parseResponse('', response, (res, err) => {
              localStorage.getItem('查看参数') && console.warn(inPayLoad.cmdId + '：responseData：', res)
              if (err) {
                resolve({type: networkType.ERROR, data: err});
                // 退出登录
                if (err.logout) {
                  mb_handleError(err)
                  userStore.clearUserinfo(); // 清空用户数据
                }
              } else {
                resolve({type: networkType.SUCCESS, data: res});
              }
            });
          })
          // ===如果不启用catch，则无网络状态下不会返回错误
          .catch(error => {
            if (restrict[payLoad.cmdId.toString()]) delete restrict[payLoad.cmdId.toString()];
            if (pingCmdIds[payLoad.cmdId + '']) delete pingCmdIds[payLoad.cmdId + ''];
            helper.parseResponse('', error, (res, err) => {
              if (err) {
                reject({type: networkType.ERROR, data: err});
              } else {
                reject({type: networkType.ERROR, data: res});
              }
            });
            // if ((typeof error).toLowerCase() === 'object') {
            //   reject({ type: networkType.ERROR, data: error.toString() });
            // } else {
            //   reject({ type: networkType.ERROR, data: error });
            // }
          });
      });
    });
  },
  /*
   * 取消所有POST请求 noreset如果这些请求在执行就不取消
   * key为false的时候，只取消当前正在执行的请求
   * key为true时，取消所有请求，且不能再发出任何请求，可以通过 cancelPost(false) 来反向激活
   **/
  cancelPost(key, noreset = []) {
    let cmd = {...pingCmdIds};
    for (let i = 0; i < noreset.length; i++) {
      if (cmd[noreset[i] + '']) return false;
    }
    source.cancel('请求取消');
    if (!key) {
      source = axios.CancelToken.source();
    }
  },
  /**
   * @name 获取指定URL的内容(get方式)
   * @param @requires payload.url 要请求的网页的地址
   */
  getContent(payload) {
    return axios({
      method: 'get',
      url: payload.url,
      timeout: 120000,
      headers: payload.headers ? payload.headers : {}
    })
  }
};

export default {...methods};
