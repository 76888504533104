/* eslint-disable */
export default class helperAg {
  static context = null
  static gameTypes = { // AG视讯游戏种类
    'BAC': '百家乐',
    'CBAC': '包桌百家乐',
    'LINK': '多台',
    'DT': '龙虎',
    'SHB': '骰宝',
    'ROU': '轮盘',
    'FT': '番摊',
    'LBAC': '竞咪百家乐',
    'ULPK': '终极德州扑克',
    'SBAC': '保险百家乐',
    'NN': '牛牛',
    'BJ': '21 点',
    'ZJH': '炸金花',
    'SL1': '巴西世界杯',
    'SL2': '疯狂水果店',
    'SL3': '3D水族馆',
    'PK_J': '视频扑克(杰克高手)',
    'SL4': '极速赛车',
    'PKBJ': '新视频扑克(杰克高手)',
    'FRU': '水果拉霸',
    'HUNTER': '捕鱼王',
    'SLM1': '美女排球(沙滩排球)',
    'SLM2': '运财羊(新年运财羊)',
    'SLM3': '武圣传',
    'SC01': '幸运老虎机',
    'TGLW': '极速幸运轮',
    'SLM4': '武则天',
    'TGCW': '赌场战争',
    'SB01': '太空漫游',
    'SB02': '复古花园',
    'SB03': '关东煮',
    'SB04': '牧场咖啡',
    'SB05': '甜一甜屋',
    'SB06': '日本武士',
    'SB07': '象棋老虎机',
    'SB08': '麻将老虎机',
    'SB09': '西洋棋老虎机',
    'SB10': '开心农场',
    'SB11': '夏日营地',
    'SB12': '海底漫游',
    'SB13': '鬼马小丑',
    'SB14': '机动乐园',
    'SB15': '惊吓鬼屋',
    'SB16': '疯狂马戏团',
    'SB17': '海洋剧场',
    'SB18': '水上乐园',
    'SB25': '土地神',
    'SB26': '布袋和尚',
    'SB27': '正财神',
    'SB28': '武财神',
    'SB29': '偏财神',
    'SB19': '空中战争',
    'SB20': '摇滚狂迷',
    'SB21': '越野机车',
    'SB22': '埃及奥秘',
    'SB23': '欢乐时光',
    'SB24': '侏罗纪',
    'AV01': '性感女仆',
    'XG01': '龙珠',
    'XG02': '幸运8',
    'XG03': '闪亮女郎',
    'XG04': '金鱼',
    'XG05': '中国新年',
    'XG06': '海盗王',
    'XG07': '鲜果狂热',
    'XG08': '小熊猫',
    'XG09': '大豪客',
    'SB30': '灵猴献瑞',
    'SB31': '天空守护者',
    'PKBD': '百搭二王',
    'PKBB': '红利百搭',
    'SB32': '齐天大圣',
    'SB33': '糖果碰碰乐',
    'SB34': '冰河世界',
    'FRU2': '水果拉霸2',
    'TG01': '21点(电子游戏)',
    'TG02': '百家乐(电子游戏)',
    'TG03': '轮盘(电子游戏)',
    'SB35': '欧洲列强争霸',
    'SB36': '捕鱼王者',
    'SB37': '上海百乐门',
    'SB38': '竞技狂热',
    'SB39': '太空水果',
    'SB40': '秦始皇',
    'TA01': '多手二十一点 低额投注',
    'TA02': '多手二十一点',
    'TA05': '1手二十一点',
    'TA07': 'Hilo 低额投注',
    'TA0C': '3手 Hilo 高额投注',
    'TA0Z': '5手杰克高手',
    'TA12': '1手杰克高手',
    'TA17': '1手百搭小丑',
    'TA18': '10手百搭小丑',
    'TA1C': '1手百搭二王',
    'TA1F': '50手百搭二王',
    'TA0U': '经典轿车',
    'TA0V': '星际大战',
    'TA0W': '海盗夺宝',
    'TA0X': '巴黎茶座',
    'TA0Y': '金龙献宝',
    'XG10': '龙舟竞渡',
    'XG11': '中秋佳节',
    'XG12': '韩风劲舞',
    'XG13': '美女大格斗',
    'XG14': '龙凤呈祥',
    'XG16': '黄金对垒',
    'TA0P': '怪兽食坊',
    'TA0S': '足球竞赛',
    'TA0L': '无法无天',
    'TA0M': '法老秘密',
    'TA0N': '烈火战车',
    'TA0O': '捕猎季节',
    'TA0Q': '日与夜',
    'TA0R': '七大奇迹',
    'TA0T': '珠光宝气',
    'TA1O': '欧洲轮盘(移动版)',
    'TA1L': '欧洲轮盘(桌面版)',
    'SV41': '富贵金鸡',
    'DTA0': '赛亚烈战',
    'SX02': '街头烈战',
    'SC03': '金拉霸',
    'SB45': '猛龙传奇',
    'DTAR': '英雄荣耀',
    'DTB1': '快乐农庄',
    'DTAM': '封神榜',
    'DTAZ': '摇滚之夜',
    'SB49': '金龙珠',
    'YFP': '水果派对',
    'YDZ': '德州牛仔',
    'YBIR': '飞禽走兽',
    'YMFD': '森林舞会多人版',
    'YFD': '森林舞会',
    'YBEN': '奔驰宝马',
    'YHR': '极速赛马',
    'YMFR': '水果拉霸多人版',
    'YGS': '猜猜乐',
    'YFR': '水果拉霸',
    'YMGS': '猜猜乐多人版',
    'YMBN': '百人牛牛',
    'YGFS': '多宝水果拉霸',
    'YJFS': '彩金水果拉霸',
    'YMBI': '飞禽走兽多人版',
    'YMBA': '牛牛对战',
    'YMBZ': '奔驰宝马多人版'
  };
  static kyGametypss = {
    '620': '德州扑克',
    '720': '二八杠',
    '830': '抢庄牛牛',
    '220': '炸金花',
    '860': '三公',
    '900': '押庄龙虎',
    '600': '21 点',
    '870': '通比牛牛',
    // '880': '欢乐红包', // 已下线
    '230': '极速炸金花',
    '730': '抢庄牌九',
    '630': '十三水',
    // '380': '幸运五张', // 已下线
    '610': '斗地主',
    // '390': '射龙门', // 已下线
    '910': '百家乐',
    '920': '森林舞会',
    '930': '百人牛牛',
    '1950': '万人炸金花',
    '650': '血流成河',
    '890': '看牌抢庄牛牛',
    '740': '二人麻将',
    '1350': '幸运转盘',
    '1940': '金鲨银鲨',
    '1960': '奔驰宝马',
    '1980': '百人骰宝',
    '1810': '单挑牛牛',
    '1990': '炸金牛',
    '1850': '押宝抢庄牛牛',
    '510': '红包捕鱼',
    '1660': '血战到底',
    '1355': '搏一搏',
    '1610': '幸运夺宝',
    '1970': '五星宏辉',
    '1860': '赌场扑克',
    '1370': '港式梭哈',
    '1690': '血战骰宝',
    '1930': '鱼虾蟹',
    '8130': '跑得快',
    '1890': '水果机',
    '520': '李逵劈鱼',
    '840': '疯狂抢庄牛牛',
    '950': '红黑大战'
  }
  static playTypes = {
    // 'BAC' : { '百家乐',
    1: '庄',
    2: '闲',
    3: '和',
    4: '庄对',
    5: '闲对',
    6: '大',
    7: '小',
    8: '庄保险',
    9: '闲保险',
    11: '庄免佣',
    12: '庄龙宝',
    13: '闲龙宝',
    14: '超级六',
    15: '任意对子',
    16: '完美对子',
    // },

    // 'CBAC' : '包桌百家乐',
    // 'LINK' : '多台',
    // 'DT' : { //  '龙虎',
    21: '龙',
    22: '虎',
    23: '和(龙虎)',
    // },
    // 'SHB' : {//  '骰宝',
    41: '大',
    42: '小',
    43: '单',
    44: '双',
    45: '全围',
    46: '围 1',
    47: '围 2',
    48: '围 3',
    49: '围 4',
    50: '围 5',
    51: '围 6',
    52: '单点 1',
    53: '单点 2',
    54: '单点 3',
    55: '单点 4',
    56: '单点 5',
    57: '单点 6',
    58: '对子 1',
    59: '对子 2',
    60: '对子 3',
    61: '对子 4',
    62: '对子 5',
    63: '对子 6',
    64: '组合 12',
    65: '组合 13',
    66: '组合 14',
    67: '组合 15',
    68: '组合 16',
    69: '组合 23',
    70: '组合 24',
    71: '组合 25',
    72: '组合 26',
    73: '组合 34',
    74: '组合 35',
    75: '组合 36',
    76: '组合 45',
    77: '组合 46',
    78: '组合 56',
    79: '和值 4',
    80: '和值 5',
    81: '和值 6',
    82: '和值 7',
    83: '和值 8',
    84: '和值 9',
    85: '和值 10',
    86: '和值 11',
    87: '和值 12',
    88: '和值 13',
    89: '和值 14',
    90: '和值 15',
    91: '和值 16',
    92: '和值 17',
    // },
    // 'ROU' : {//  '轮盘',
    101: '直接注',
    102: '分注',
    103: '街注',
    104: '三数',
    105: '4个号码',
    106: '角注',
    107: '列注(列 1)',
    108: '列注(列 2)',
    109: '列注(列 3)',
    110: '线注',
    111: '打一',
    112: '打二',
    113: '打三',
    114: '红',
    115: '黑',
    116: '大',
    117: '小',
    118: '单',
    119: '双',
    // },
    // 'FT' : {// '番摊',
    130: '1番',
    131: '2番',
    132: '3番',
    133: '4番',
    134: '1念2',
    135: '1念3',
    136: '1念4',
    137: '2念1',
    138: '2念3',
    139: '2念4',
    140: '3念1',
    141: '3念2',
    142: '3念4',
    143: '4念1',
    144: '4念2',
    145: '4念3',
    146: '角(1,2)',
    147: '单',
    148: '角(1,4)',
    149: '角(2,3)',
    150: '双',
    151: '角(3,4)',
    152: '1,2 四 通',
    153: '1,2 三 通',
    154: '1,3 四 通',
    155: '1,3 二 通',
    156: '1,4 三 通',
    157: '1,4 二 通',
    158: '2,3 四 通',
    159: '2,3 一 通',
    160: '2,4 三 通',
    161: '2,4 一 通',
    162: '3,4 二 通',
    163: '3,4 一 通',
    164: '三门(3,2,1)',
    165: '三门(2,1,4)',
    166: '三门(1,4,3)',
    167: '三门(4,3,2)',
    // },
    // 'LBAC' : '竞咪百家乐',
    // 'ULPK' : {//  '终极德州扑克',
    180: '底注+盲注',
    181: '一倍加注',
    182: '二倍加注',
    183: '三倍加注',
    184: '四倍加注',
    // },
    // 'SBAC' : '保险百家乐',
    // 'NN' : {//  '牛牛',
    211: '闲1平倍',
    212: '闲1翻倍',
    213: '闲2平倍',
    214: '闲2翻倍',
    215: '闲3平倍',
    216: '闲3翻倍',
    207: '庄1平倍',
    208: '庄1翻倍',
    209: '庄2平倍',
    210: '庄2翻倍',
    217: '庄3平倍',
    218: '庄3翻倍',
    // },
    // 'BJ' : {//  '21 点',
    220: '底注',
    221: '分牌',
    222: '保险',
    223: '分牌保险',
    224: '加注',
    225: '分牌加注',
    226: '完美对子',
    227: '21+3',
    228: '旁注',
    229: '旁注分牌',
    230: '旁注保险',
    231: '旁注分牌保险',
    232: '旁注加注',
    233: '旁注分牌加注',
    // },
    // 'ZJH' : {// '炸金花',
    260: '龙',
    261: '凤',
    262: '对8以上',
    263: '同花',
    264: '顺子',
    265: '豹子',
    266: '同花顺',
    1930: '鱼虾蟹',
    8130: '跑得快',
    1890: '水果机'
    // },
  };

  static getGameTypes() {
    return this.gameTypes
  }

  static getGameTypeName(KindId) {
    let gameType = ''
    if (this.gameTypes[KindId]) {
      return this.gameTypes[KindId]
    }
    return gameType
  }

  static getPlayTypeName(playType) {
    if (!playType || playType === 'null') return ''
    if (this.playTypes[playType]) {
      return this.playTypes[playType]
    }
    return playType
  }

  //  结算状态
  static getPlanFlagName(flag) {
    let flagText = ''
    switch (flag) {
      case 1:
        flagText = '已结算'
        break
      case 0:
        flagText = '未结算'
        break
      case -8:
        flagText = '取消指定局注单'
        break
      case -9:
        flagText = '取消指定注单'
        break
    }
    return flagText
  }

  static kyGameNames = {
    3600: '德州扑克新手房',
    3601: '德州扑克初级房',
    3602: '德州扑克中级房',
    3603: '德州扑克高级房',
    3700: '德州扑克财大气粗房',
    3701: '德州扑克腰缠万贯房',
    3702: '德州扑克挥金如土房',
    3703: '德州扑克富贵逼人房',
    7201: '二八杠体验房',
    7202: '二八杠初级房',
    7203: '二八杠中级房',
    7204: '二八杠高级房',
    7205: '二八杠至尊房',
    7206: '二八杠王者房',
    8301: '抢庄牛牛体验房',
    8302: '抢庄牛牛初级房',
    8303: '抢庄牛牛中级房',
    8304: '抢庄牛牛高级房',
    8305: '抢庄牛牛至尊房',
    8306: '抢庄牛牛王者房',
    2201: '炸金花体验房',
    2202: '炸金花初级房',
    2203: '炸金花中级房',
    2204: '炸金花高级房',
    8601: '三公体验房',
    8602: '三公初级房',
    8603: '三公中级房',
    8604: '三公高级房',
    8605: '三公至尊房',
    8606: '三公王者房',
    9001: '龙虎体验房',
    9002: '龙虎初级房',
    9003: '龙虎中级房',
    9004: '龙虎高级房',
    6001: '21点体验房',
    6002: '21点初级房',
    6003: '21点中级房',
    6004: '21点高级房',
    8701: '通比牛牛体验房',
    8702: '通比牛牛初级房',
    8703: '通比牛牛中级房',
    8704: '通比牛牛高级房',
    8705: '通比牛牛至尊房',
    8706: '通比牛牛王者房',
    8801: '欢乐红包体验房',
    8802: '欢乐红包初级房',
    8803: '欢乐红包中级房',
    8804: '欢乐红包高级房',
    2301: '极速炸金花新手房',
    2302: '极速炸金花初级房',
    2303: '极速炸金花中级房',
    2304: '极速炸金花高级房',
    7301: '抢庄牌九新手房',
    7302: '抢庄牌九初级房',
    7303: '抢庄牌九中级房',
    7304: '抢庄牌九高级房',
    7305: '抢庄牌九至尊房',
    7306: '抢庄牌九王者房',
    6101: '斗地主体验房',
    6102: '斗地主初级房',
    6103: '斗地主中级房',
    6104: '斗地主高级房',
    6301: '十三水常规场新手房',
    6302: '十三水常规场初级房',
    6303: '十三水常规场中级房',
    6304: '十三水常规场高级房',
    6305: '十三水极速场新手房',
    6306: '十三水极速场初级房',
    6307: '十三水极速场中级房',
    6308: '十三水极速场高级房',
    3801: '幸运五张体验房',
    3802: '幸运五张初级房',
    3803: '幸运五张中级房',
    3804: '幸运五张高级房',
    3901: '射龙门经典房',
    3902: '射龙门暴击房',
    9101: '百家乐体验房',
    9102: '百家乐初级房',
    9103: '百家乐中级房',
    9104: '百家乐高级房',
    9201: '森林舞会体验房',
    9202: '森林舞会初级房',
    9203: '森林舞会中级房',
    9204: '森林舞会高级房',
    9301: '百人牛牛体验房',
    9302: '百人牛牛初级房',
    9303: '百人牛牛中级房',
    9304: '百人牛牛高级房',
    19501: '万人炸金花体验房',
    19502: '万人炸金花初级房',
    19503: '万人炸金花中级房',
    19504: '万人炸金花高级房',
    6501: '血流成河体验房',
    6502: '血流成河初级房',
    6503: '血流成河中级房',
    6504: '血流成河高级房',
    8901: '看牌抢庄牛牛体验房',
    8902: '看牌抢庄牛牛初级房',
    8903: '看牌抢庄牛牛中级房',
    8904: '看牌抢庄牛牛高级房',
    8905: '看牌抢庄牛牛至尊房',
    8906: '看牌抢庄牛牛王者房',
    7401: '二人麻将体验房',
    7402: '二人麻将初级房',
    7403: '二人麻将中级房',
    7404: '二人麻将高级房',
    13501: '幸运转盘',
    13502: '幸运转盘',
    13503: '幸运转盘',
    19401: '金鲨银鲨体验房',
    19402: '金鲨银鲨初级房',
    19403: '金鲨银鲨中级房',
    19404: '金鲨银鲨高级房',
    19601: '奔驰宝马体验房',
    19602: '奔驰宝马初级房',
    19603: '奔驰宝马中级房',
    19604: '奔驰宝马高级房',
    19801: '百人骰宝体验房',
    19802: '百人骰宝初级房',
    19803: '百人骰宝中级房',
    19804: '百人骰宝高级房',
    18101: '单挑牛牛体验房',
    18102: '单挑牛牛初级房',
    18103: '单挑牛牛中级房',
    18104: '单挑牛牛高级房',
    18105: '单挑牛牛至尊房',
    18106: '单挑牛牛王者房',
    19901: '炸金牛体验房',
    19902: '炸金牛初级房',
    19903: '炸金牛中级房',
    19904: '炸金牛高级房',
    19905: '炸金牛至尊房',
    19906: '炸金牛王者房',
    18501: '押宝抢庄牛牛体验房',
    18502: '押宝抢庄牛牛初级房',
    18503: '押宝抢庄牛牛中级房',
    18504: '押宝抢庄牛牛高级房',
    18505: '押宝抢庄牛牛至尊房',
    18506: '押宝抢庄牛牛王者房',
    5101: '红包捕鱼初级房',
    5102: '红包捕鱼中级房',
    5103: '红包捕鱼高级房',
    16601: '血战到底体验房',
    16602: '血战到底初级房',
    16603: '血战到底中级房',
    16604: '血战到底高级房',
    19701: '五星宏辉体验房',
    19702: '五星宏辉初级房',
    19703: '五星宏辉中级房',
    19704: '五星宏辉高级房',
    18601: '赌场扑克体验房',
    18602: '赌场扑克初级房',
    18603: '赌场扑克中级房',
    18604: '赌场扑克高级房',
    13701: '港式梭哈新手房',
    13702: '港式梭哈初级房',
    13703: '港式梭哈中级房',
    13704: '港式梭哈高级房',
    16901: '血战骰宝体验房',
    16902: '血战骰宝初级房',
    16903: '血战骰宝中级房',
    16904: '血战骰宝高级房',
    18901: '水果机体验房',
    18902: '水果机初级房',
    18903: '水果机中级房',
    18904: '水果机高级房',
    16101: '白银宝箱房间',
    16102: '黄金宝箱房间',
    16103: '铂金宝箱房间',
    16104: '钻石宝箱房间',
    19301: '鱼虾蟹体验房',
    19302: '鱼虾蟹初级房',
    19303: '鱼虾蟹中级房',
    19304: '鱼虾蟹高级房',
    81301: '跑得快体验房',
    81302: '跑得快初级房',
    81303: '跑得快中级房',
    81304: '跑得快高级房',
    5201: '李逵劈鱼初级房',
    5202: '李逵劈鱼中级房',
    5203: '李逵劈鱼高级房',
    8401: '疯狂抢庄牛牛新手房',
    8402: '疯狂抢庄牛牛初级房',
    8403: '疯狂抢庄牛牛中级房',
    8404: '疯狂抢庄牛牛高级房',
    8405: '疯狂抢庄牛牛至尊房',
    8406: '疯狂抢庄牛牛王者房',
    9501: '红黑大战体验房',
    9502: '红黑大战初级房',
    9503: '红黑大战中级房',
    9504: '红黑大战高级房'
  }

  static getKyGameName(KindId, ServerId) {
    let name = ''
    // let key = 'K' + KindId + '_' + ServerId
    if (this.kyGameNames[ServerId]) {
      name = this.kyGameNames[ServerId]
    }
    return name
  }

  static getKyGameTypeName(KindId) {
    let gameName = ''
    if (this.kyGametypss[KindId]) {
      gameName = this.kyGametypss[KindId]
    }
    return gameName
  }

  static getKyGameTypes() {
    return this.kyGameNames
  }

  static getDBGameTypeName (KindId) {
		let gameName = ''
		if (this.kyGametypss[KindId]) {
			gameName = this.kyGametypss[KindId]
		}
		return gameName
	}

  static getGameDetails(list) {
    if (!list) {
      return false
    }
    let gameResults = ''
    // if (list.game_type === 'BAC') {
    // 	gameResults = '庄' + list.banker_point + '闲' + list.player_point
    // } else if (list.game_type === 'DT') {
    // 	gameResults = '龙' + list.banker_point + '虎' + list.player_point
    // }
    gameResults = {
      'BAC': '庄' + list.banker_point + '闲' + list.player_point,
      'DT': '龙' + list.dragon_point + '虎' + list.tiger_point
    }[list.game_type]
    return gameResults
  }
}
