import {storeToRefs} from 'pinia';
import {useTenantStore} from '/@/kernel-mn/stores/tenant';
import {useUserStore} from '/@/kernel-mn/stores/user';
import {getSession} from '/@/kernel-mn/utils/storage';

export function useHot() {
  // @ts-ignore
  const local_theme = import.meta.env.VITE_THEME_NAME;
  const tenantStore = useTenantStore();
  const {tenantInfo, defaultArea, activeConfiguration, tResLoc, imgDomain, countrys} = storeToRefs(tenantStore);
  const userStore = useUserStore();
  const {isLogined, userInfo, isFreeUser, signInList, rechargeSuccess, withdrawSuccess} = storeToRefs(userStore);
  // 如果本地session存有主题名 则优先使用[方便测试环境切换主题] 前端本地主题名次之 再后端配置主题名
  let theme = getSession('themeName') || local_theme || tResLoc.value || '';
  if (theme) {
    tResLoc.value = theme;
  }
  return {
    hotData: {
      tenantInfo,
      isLogined,
      userInfo,
      isFreeUser
    },
    countrys, // 国别编号
    rechargeSuccess, // ws返回的充值结果数据
    withdrawSuccess, // ws返回的提现结果数据
    setRechargeSuccessData: userStore.setRechargeSuccessData, // 设置ws充值数据方法 主要用于清空数据
    setWithdrawSuccessData: userStore.setWithdrawSuccessData, // 设置ws提现数据方法 主要用于清空数据
    signInList,
    defaultArea,
    activeConfiguration, // 代理盘控制
    tResLoc,
    imgDomain, // 图片域名地址
  };
}
