// 原创
export default {
  plays: {
    100: {
      rule_id: 37000,
      area_id: 39100,
      play_name: 'QK',
      play_cname: '区块',
      unit_price: 1,
      xnb: true,
      Method: 0,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },
  }
}
