import network from '../../network';
import config from '/@/kernel-mn/config';
import { useTenantStore } from '../tenant';
import { useHot } from '/@/kernel-mn/stores/useHot';
import { displayCardcode, fixFloat, getp, utilsTotal } from '../../utils';
import { useUserStore } from '.';
import helperAg from '../../helpers/helperAg';
import helpersOthersGames from '../otherGames/helpersOthersGames';
import helperBBIN from '../../helpers/helperBBIN';
import { getJSON } from '../../utils/storage';

export default {
  // 活动领取记录
  async getReceviveList(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      custName: userInfo.value.cust_name,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 395005,
        data: data,
        paramType: 0
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 修改头像
  async reNickHead(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      head: payload.head,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 310114,
        data: data
      });
      if (res && res.type === 'success') {
        this.saveUser({
          ...this.userInfo,
          head: payload.head
        });
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 转入保险箱
  async safeTransferInfo(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      acct_id: userInfo.value.acct_id,
      balance: payload.balance,
      mark: 1,
      oper_code: userInfo.value.cust_code
    };
    try {
      const res = await network.request({
        cmdId: 340334,
        data: data,
        paramType: 1
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 转出保险箱
  async safeTransferOut(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      acct_id: userInfo.value.acct_id,
      balance: payload.balance,
      mark: 1,
      oper_code: userInfo.value.cust_code
    };
    try {
      const res = await network.request({
        cmdId: 340335,
        data: data,
        paramType: 1
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 打开保险箱
  async openSafe(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_code: userInfo.value.cust_code,
      acct_pwd: getp(payload.pwd)
    };
    try {
      const res = await network.request({
        cmdId: 310136,
        data: data,
        headerType: 2,
        paramType: 0
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 余额宝利息相关
  async reqInterestData() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 340337,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 验证余额宝
  async checkInterest(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      type: '105' // 105余额宝, 108借呗
    };
    if (payload?.type) {
      data.type = payload.type;
    }
    try {
      const res = await network.request({
        cmdId: 360731,
        data: data
      });
      if (res) {
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 余额宝按钮是否显示
  async reqInterest() {
    const { hotData } = useHot();
    // eslint-disable-next-line no-unused-vars
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      type: '105'
    };
    try {
      const res = await network.request({
        cmdId: 370601,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 余额宝列表接口
  async reqSafeList(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_code: userInfo.value.cust_name,
      page_start: payload.page,
      num_per_page: 10
    };
    try {
      const res = await network.request({
        cmdId: 340620,
        data: data,
        paramType: 0
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 保险箱相关数据
  async getSafeRelated() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 340336,
        data: data,
        headerType: 2,
        paramType: 0
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 获取用户是否在禁用名单
   */
  async reqDiscountInfo() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 3101453,
        data: data
      });
      if (res && res.type === 'success') {
        this.discountInfo = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 缓存新银行卡数据
  setCurrentBankInfo(data) {
    if (data.isClear) {
      this.currentBankInfo = {};
    } else {
      this.currentBankInfo = Object.assign(this.currentBankInfo, data);
    }
  },
  // 清除新银行卡数据
  clearCurrentBankInfo() {
    this.currentBankInfo = {};
  },
  setCheckPayIdIndex(data) {
    this.checkPayIdIndex = data;
  },
  /**
   * @name 设定默认银行卡
   * @param @requires payload.pay_info_id
   * @param payload.key 本次请求的key，默认为default
   * @param payload.options 更多参数 JSON
   */
  async reqReDefaultBankCard(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = [tenantInfo.value.tid, payload.pay_info_id, userInfo.value.cust_id];

    try {
      const res = await network.request({
        cmdId: 310412,
        data: data,
        paramType: 2
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取签到数据
  async reqSignIn() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 374003,
        data
      });
      if (res && res.type === 'success') {
        this.setSignInList(res.data);
        return Promise.resolve(this.signInList);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 签到
  async singIn() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 374002,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  /**
   * 获取vip晋级模式列表信息,并携带领取状态
   */
  async reqVipListWithStatus() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_name: userInfo.value.cust_name,
      level: userInfo.value.cust_level,
      state: 1
    };
    try {
      const res = await network.request({
        cmdId: 374031,
        data
      });
      if (res && res.type === 'success') {
        // this.setSignInList(res.data);
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取vip晋级模式列表信息
   */
  async reqVipList() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      state: 1
    };
    try {
      const res = await network.request({
        cmdId: 374030,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取游戏任务列表
  async reqGameMission() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo, isLogined } = hotData;
    let data: any = {
      tid: tenantInfo.value.tid,
      proxy: tenantInfo.value.proxy
    };
    if (isLogined.value) {
      data.cust_id = userInfo.value.cust_id;
    }
    try {
      const res = await network.request({
        cmdId: isLogined.value ? 374038 : 377211,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 任务列表领取接口
  async reqClickPickup(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      missons: payload.arr
    };
    try {
      const res = await network.request({
        cmdId: 374037,
        data,
        paramType: 1
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取自助返水比例
  async reqWashCodeProportion(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      game_type: payload.game_type,
      sgame_type: payload.sgame_type
    };
    try {
      const res = await network.request({
        cmdId: 390000,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  // 获取自助返水页面数据
  async reqWashCodeList() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      cust_id: userInfo.value.cust_id,
      tid: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 390003,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取历史返水记录记录
  async reqWashCodeRecording() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      cust_id: userInfo.value.cust_id,
      tid: tenantInfo.value.tid,
      data_type: 1,
      page: 30
    };
    try {
      const res = await network.request({
        cmdId: 390005,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  // 自助返水详情
  async reqDetailsList(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      log_id: payload.logId
    };
    try {
      const res = await network.request({
        cmdId: 390006,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  // 点击一键反水
  async reqWachCode(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      cust_id: userInfo.value.cust_id,
      tid: tenantInfo.value.tid,
      end_time: payload.end_time
    };
    try {
      const res = await network.request({
        cmdId: 390004,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 会员返水-MN项目 type // 0 今日，1 昨日，2 近7日，3 近15日，4 近30日
  async reqReturnWashLists(payload: any = {}) {
    const { hotData: { tenantInfo, userInfo } } = useHot();
    let data: any = {
      ...payload,
      type: payload.type || 0,
      cust_id: userInfo.value.cust_id,
      tid: tenantInfo.value.tid,
    };
    try {
      const res: any = await network.request({
        cmdId: 600117,
        data
      });
      if (res && res.type === 'success') {
        res.data.old_type = payload.type; // 防止数据错乱
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  // 获取红包活动规则
  async reqHBRules() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      option_code: 'PUSH_SCHEDULE_RED_REMARK',
      page_start: 1,
      num_per_page: 1000
    };
    try {
      const res = await network.request({
        cmdId: 370732,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取红包数据
  async reqHB() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      cust_id: userInfo.value.cust_id,
      tid: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 382233,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取红包中奖列表
  async reqHBWinnerList() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      cust_id: userInfo.value.cust_id,
      tid: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 382235,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取红包列表
  async reqHBList() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      cust_id: userInfo.value.cust_id,
      tid: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 382236,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 查询红包活动状态
  async checkActivity(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      cust_id: userInfo.value.cust_id,
      tid: tenantInfo.value.tid,
      activity_name: payload.activity_name
    };
    payload.red_packet_id && (data.red_packet_id = payload.red_packet_id);
    try {
      const res = await network.request({
        cmdId: 360728,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 抢红包后获取红包数据
  async reqOpenHB(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      tid: tenantInfo.value.tid,
      acct_id: userInfo.value.acct_id,
      id: payload.id
    };
    try {
      const res = await network.request({
        cmdId: 374140,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 幸运大转盘 - 积分查询
  async reqLuckyBigWchatIntegral() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      tid: tenantInfo.value.tid,
      custId: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 322961,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 幸运大转盘 - 轮盘查询
  async reqLuckyBigWchat() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    let data: any = {
      tId: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 322957,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 幸运大转盘 - 抽奖
  async reqDrawLuckWheel(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      ...payload,
      tid: tenantInfo.value.tid,
      custId: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 322962,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  async reqRescueFundListNew(payload = {}) {
    const { hotData, activeConfiguration } = useHot();
    const { tenantInfo, userInfo, isLogined } = hotData;
    const _activeConfigurationJson = getJSON('activeConfiguration', true);
    let isShow = activeConfiguration.value || _activeConfigurationJson; // 配置
    let data: any = {
      tid: tenantInfo.value.tid
    };
    if (isLogined.value) {
      data.cust_id = userInfo.value.cust_id;
    }
    try {
      const res = await network.request({
        cmdId: isLogined.value ? 370603 : 377212,
        data
      });
      if (res && res.type === 'success') {
        this.setRescueFundListNew(res.data, isShow, payload);
        return Promise.resolve(this.rescueFundListData);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 检测钱包地址是否被注册过
   */
  async checkHashWalletAddress(data) {
    const tenantState = useTenantStore();
    data = {
      hash_wallet_address: data.hash_wallet_address,
      tid: tenantState.tenantInfo.tid
    };
    try {
      const res = await network.request({
        cmdId: 470003,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 增加提款
   */
  async reqAddWithdraw(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    const data = {
      ...payload,
      acct_id: userInfo.value.acct_id,
      acct_pwd: payload.acct_pwd ? getp(payload.acct_pwd) : '',
      amount: payload.amount,
      chan_code: payload.chan_code || config.chanCode,
      cust_code: userInfo.value.cust_code,
      cust_id: userInfo.value.cust_id,
      oper_code: userInfo.value.cust_code,
      pay_info_id: payload.pay_info_id || '',
      pay_name: payload.pay_name || '',
      pay_no: payload.pay_no || '',
      pay_org_id: payload.pay_org_id || '',
      pay_org_name: payload.pay_org_name || '',
      open_addr: payload.open_addr || '',
      remark: '审核中',
      state: 1,
      tid: tenantInfo.value.tid,
      withdraw_type: 1
    };
    try {
      const res = await network.request({
        cmdId: 341200,
        data: data,
        paramType: 1,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 用户的默认银行卡信息
   */
  async reqDefaultBankCard2() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    try {
      const res = await network.request({
        cmdId: 310435,
        data: [tenantInfo.value.tid, userInfo.value.cust_id],
        paramType: 2
      });
      if (res && res.type === 'success') {
        this.defaultCard = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 请求用户的银行卡提示文案
   */
  async reqBankCardTips(payload = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const data = {
      ...payload,
      tid: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 310436,
        data,
        paramType: 0
      });
      if (res && res.type === 'success') {
        this.cardTips = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 接口参数：tid=3&cust_id=1&start_time=1596211214000&end_time=1599494414000
  // 个人报表统计
  async reqAllSummary(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      start_time: payload.startTime,
      end_time: payload.endTime
    };
    try {
      const res: any = await network.request({
        cmdId: 360714,
        data
      });
      if (res && res.type === 'success') {
        // "big_type": "2",  -- 2棋牌，3电子，4捕鱼，5真人，6体育，7彩票，8红包,9电竞
        if (res.data && res.data.realtimePlayList && res.data.realtimePlayList.length) {
          res.data.realtimePlayList = res.data.realtimePlayList.map(item => {
            if (Number(item.big_type) === 2) {
              item.big_title = '棋牌投注汇总';
            } else if (Number(item.big_type) === 3) {
              item.big_title = '电子投注汇总';
            } else if (Number(item.big_type) === 4) {
              item.big_title = '捕鱼投注汇总';
            } else if (Number(item.big_type) === 5) {
              item.big_title = '真人投注汇总';
            } else if (Number(item.big_type) === 6) {
              item.big_title = '体育投注汇总';
            } else if (Number(item.big_type) === 7) {
              item.big_title = '全部彩票汇总';
            } else if (Number(item.big_type) === 8) {
              item.big_title = '红包投注汇总';
            } else if (Number(item.big_type) === 0) {
              item.big_title = '全部类型汇总';
            }
            return item;
          });
        }
        this.allSummaryList = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  // 个人报表数据
  async reqUserReportList(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;

    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      start_time: payload.startTime,
      end_time: payload.endTime,
    }

    try {
      const res = await network.request({
        cmdId: 360720,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  // tid=3&cust_id=2421&start_time=1593532814000&end_time=1599494414000&big_type=7
  // 个人记录大类统计
  async reqGeneralStatistics(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      start_time: payload.startTime,
      end_time: payload.endTime,
      big_type: payload.big_type
    };
    try {
      const res = await network.request({
        cmdId: 360715,
        data
      });
      if (res && res.type === 'success') {
        const userStore = useUserStore();
        userStore.setStatisticsList({ big_type: payload.big_type, data: res.data });
        return Promise.resolve(this.statisticsList);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 请求游戏记录
   * @param @requires payload.start_page
   * @param @requires payload.num_per_page
   * @param payload.start_time 起始时间
   * @param payload.end_time 结束时间
   * @param payload.is_clear 是否清空本地数据(不清空则追加)
   * @param payload.key 本次请求的key，默认为default
   * @param payload.options 更多参数 JSON
   * @param payload.callback 回调 function (res, err) {}
   */
  async reqpcOthersGameHistory(payload) {
    const { hotData, activeConfiguration } = useHot();
    const userStore = useUserStore();
    const { tenantInfo, userInfo } = hotData;
    let isShow = activeConfiguration.value['30']; // 是否脱敏
    let data: any = {};
    data.play_id = payload.play_id;
    data.tid = tenantInfo.value.tid;
    data.start_time = payload.start_time;
    data.end_time = payload.end_time;
    data.page_start = payload.page_start;
    data.num_per_page = payload.num_per_page;
    payload.cust_name && (data.cust_name = payload.cust_name);
    data.direct_type = payload.direct_type || 0;
    data.cust_id = userInfo.value.cust_id;
    if (payload.type === 'team') {
      delete data.cust_id;
      data.partner_name = userInfo.value.cust_name;
    } else {
      data.cust_name = userInfo.value.cust_name;
    }
    if (payload.info1) {
      data.platform_order_id = payload.info1;
    }
    if (payload.info2) {
      data.info2 = payload.info2;
    }
    if (payload.info3) {
      data.info3 = payload.info3;
    }
    if (payload.is_clear) {
      userStore.setQiPaiHistoryData({ data: [], t: {}, count: 0 });
    }
    let sendPayLoad: any = {};
    sendPayLoad.cmdId = 392001;
    sendPayLoad.data = data;
    sendPayLoad.paramType = 0;
    sendPayLoad.isApiV2 = true;
    try {
      const respones = await network.request(sendPayLoad);
      if (respones && respones.type === 'success') {
        let pagebetamountsum = 0;
        let pagevalidbetamountsum = 0;
        let pagenetamountsum = 0;
        let res: any = respones.data || {};
        if (res && res.data && res.data.length !== 0) {
          for (let i = 0; i < res.data.length; i++) {
            let gameName = '';
            let roomName = '';
            const item = res.data[i];
            switch (Number(payload.play_id)) {
              case 297: // 开元棋牌
                if (!item.kind_id) {
                  gameName = '';
                } else {
                  gameName = helperAg.getKyGameTypeName(item.kind_id);
                }
                roomName = helperAg.getKyGameName(item.kind_id, item.server_id);
                if (isShow && item.cust_name) res.data[i].cust_name = displayCardcode(item.cust_name, 1, 4);
                pagebetamountsum = pagebetamountsum + res.data[i].all_bet;
                pagevalidbetamountsum = pagevalidbetamountsum + res.data[i].cell_score;
                pagenetamountsum = pagenetamountsum + res.data[i].profit;
                break;
              case 299: // 乐游棋牌
                gameName = helpersOthersGames.getGameName('LYQiPaiGames', res.data[i].kind_id);
                roomName = helpersOthersGames.getLyGameRoom(item.kind_id, item.server_id);
                if (isShow && payload.type === 'team' && item.cust_name) res.data[i].cust_name = displayCardcode(item.cust_name, 1, 4);
                pagebetamountsum = pagebetamountsum + res.data[i].all_bet;
                pagevalidbetamountsum = pagevalidbetamountsum + res.data[i].cell_score;
                pagenetamountsum = pagenetamountsum + res.data[i].profit;
                break;
              case 380: // 大富翁
                if (isShow && payload.type === 'team' && res.data[i].cust_name) res.data[i].cust_name = displayCardcode(res.data[i].cust_name, 1, 4);
                roomName = helpersOthersGames.getGameName('DFWQiPaiGameRooms', res.data[i].classNo);
                gameName = helpersOthersGames.getGameName('DFWQiPaiGames', res.data[i].typeId);
                res.data[i].playerFullName = res.data[i].playerFullName.split('_')[1];
                pagebetamountsum = pagebetamountsum + res.data[i].totalScore;
                pagenetamountsum = pagenetamountsum + res.data[i].profitScore;
                break;
              case 382: // 财神棋牌
                if (payload.type === 'team' && res.data[i].cust_name) res.data[i].cust_name = displayCardcode(res.data[i].cust_name, 1, 4);
                res.data[i].play_result = Number(res.data[i].gameresult) === 0 ? '输' : Number(res.data[i].gameresult) === 1 ? '赢' : '和';
                gameName = helpersOthersGames.getGameName('CSQiPaiGames', res.data[i].gametype);
                res.data[i].actulWinging = Number(res.data[i].money) + Number(res.data[i].servicemoney);
                pagebetamountsum = pagebetamountsum + res.data[i].betamount;
                pagevalidbetamountsum = pagevalidbetamountsum + res.data[i].validbetamount;
                pagenetamountsum = pagenetamountsum + res.data[i].money + res.data[i].servicemoney;
                break;
              case 388: // 欢乐棋牌
                if (payload.type === 'team' && res.data[i].cust_name) res.data[i].cust_name = displayCardcode(res.data[i].cust_name, 1, 4);
                pagebetamountsum = pagebetamountsum + res.data[i].betamount;
                pagevalidbetamountsum = pagevalidbetamountsum + res.data[i].goldEffective;
                pagenetamountsum = pagenetamountsum + res.data[i].gold;
                break;
              case 393: // 天游棋牌
                if (payload.type === 'team' && res.data[i].cust_name) res.data[i].cust_name = displayCardcode(res.data[i].cust_name, 1, 4);
                gameName = helpersOthersGames.getGameName('THQiPaiGames', res.data[i].game_type);
                roomName = helpersOthersGames.getGameName('THQiPaiGameRooms', res.data[i].room_level);
                pagebetamountsum = pagebetamountsum + res.data[i].deal_money;
                pagevalidbetamountsum = pagevalidbetamountsum + res.data[i].effect_money;
                pagenetamountsum = pagenetamountsum + res.data[i].win_money;
                break;
              case 391: // 天美棋牌
                if (payload.type === 'team' && res.data[i].cust_name) res.data[i].cust_name = displayCardcode(res.data[i].cust_name, 1, 4);
                gameName = helpersOthersGames.getGameName('TMQiPaiGames', res.data[i].kindID);
                roomName = helpersOthersGames.getGameName('TMQiPaiGameRooms', res.data[i].serverID);
                pagebetamountsum = pagebetamountsum + res.data[i].betScore;
                pagevalidbetamountsum = pagevalidbetamountsum + res.data[i].useBetScore;
                pagenetamountsum = pagenetamountsum + res.data[i].score;
                break;
              case 394: // 博乐棋牌
                if (payload.type === 'team' && res.data[i].cust_name) res.data[i].cust_name = displayCardcode(res.data[i].cust_name, 1, 4);
                gameName = helpersOthersGames.getGameName('BLQiPaiGames', res.data[i].game_code);
                roomName = helpersOthersGames.getBLGameRoom(res.data[i].game_code, res.data[i].game_code === 'slot' ? res.data[i].scene : res.data[i].scene_id);
                pagebetamountsum = pagebetamountsum + res.data[i].bet_num;
                pagevalidbetamountsum = pagevalidbetamountsum + res.data[i].bet_num_valid;
                pagenetamountsum = pagenetamountsum + res.data[i].gain_gold;
                break;
              case 398: // 天境棋牌
                if (isShow && payload.type === 'team' && res.data[i].cust_name) res.data[i].cust_name = displayCardcode(res.data[i].cust_name, 1, 4);
                gameName = helpersOthersGames.getGameName('TJQiPaiGames', res.data[i].gameId);
                roomName = helpersOthersGames.getGameName('TJQiPaiGameRooms', res.data[i].roomType);
                pagebetamountsum = pagebetamountsum + res.data[i].betCredit;
                pagevalidbetamountsum = pagevalidbetamountsum + res.data[i].effectCredit;
                pagenetamountsum = pagenetamountsum + res.data[i].winLoseCredit;
                break;
              case 343: // MG电子
                if (isShow && payload.type === 'team' && res.data[i].cust_name) res.data[i].cust_name = displayCardcode(res.data[i].cust_name, 1, 4);
                gameName = helpersOthersGames.getGameName('MGGames', res.data[i].gameCode);
                pagebetamountsum = pagebetamountsum + res.data[i].betAmount;
                pagenetamountsum = pagenetamountsum + res.data[i].total;
                break;
              case 340: // JDB电子
                if (isShow && payload.type === 'team' && res.data[i].cust_name) res.data[i].cust_name = displayCardcode(res.data[i].cust_name, 1, 4);
                gameName = helpersOthersGames.getGameName('JDBDianZiGameNames', item.mtype);
                pagebetamountsum = pagebetamountsum + res.data[i].bet;
                pagenetamountsum = pagenetamountsum + res.data[i].total;
                break;
              case 60: // AG视讯
                if (isShow && payload.type === 'team' && res.data[i].cust_name) res.data[i].cust_name = displayCardcode(res.data[i].cust_name, 1, 4);
                gameName = helpersOthersGames.getGameName('AGSXGames', item.game_type);
                res.data[i].game_result = helperAg.getGameDetails(item.agPlayResult);
                if (!item.play_type) {
                  res.data[i].play_type = '';
                  gameName = '';
                } else {
                  res.data[i].play_type = helperAg.getPlayTypeName(item.play_type);
                  gameName = helperAg.getPlayTypeName(item.play_type);
                }
                res.data[i].bet_time = utilsTotal.dateFormat(item.bet_time);
                pagebetamountsum = pagebetamountsum + res.data[i].bet_amount;
                res.data[i].betamount = utilsTotal.moneyToFloat(item.bet_amount);
                pagevalidbetamountsum = pagevalidbetamountsum + res.data[i].valid_bet_amount;
                res.data[i].valid_bet_amount = utilsTotal.moneyToFloat(item.valid_bet_amount);
                res.data[i].flag = helperAg.getPlanFlagName(item.flag);
                pagenetamountsum = pagenetamountsum + res.data[i].net_amount;
                res.data[i].net_amount = utilsTotal.moneyToFloat(item.net_amount);
                break;
              case 345: // BBIN视讯
                if (isShow && payload.type === 'team' && res.data[i].cust_name) res.data[i].cust_name = displayCardcode(res.data[i].cust_name, 1, 4);
                gameName = helpersOthersGames.getGameName('BBINShiXunGames', item.gameType);
                res.data[i].result_type = item.resultType === '-1' ? '注销' : item.resultType === '0' ? '未结算' : '已结算';
                res.data[i].payoff = item.payoff;
                res.data[i].wagersId = item.wagersId;
                res.data[i].game_result = helperBBIN.getBBINGameResult(item.gameType, item.result, item.card) ? helperBBIN.getBBINGameResult(item.gameType, item.result, item.card) : '';
                res.data[i].betDetail = helperBBIN.getBBINBetDetail(item.gameType, item.wagerDetail) ? helperBBIN.getBBINBetDetail(item.gameType, item.wagerDetail) : '';
                pagebetamountsum = pagebetamountsum + res.data[i].commissionable;
                pagevalidbetamountsum = pagevalidbetamountsum + res.data[i].commissionable;
                res.data[i].valid_bet_amount = utilsTotal.moneyToFloat(item.commissionable);
                pagenetamountsum = pagenetamountsum + res.data[i].payoff;
                break;
              case 390: // bg西游捕鱼
                if (isShow && payload.type === 'team' && res.data[i].cust_name) res.data[i].cust_name = displayCardcode(res.data[i].cust_name, 1, 4);
                gameName = Number(res.data[i].gameType) === 105 ? 'BG捕鱼大师' : '西游捕鱼';
                pagebetamountsum = pagebetamountsum + res.data[i].payout;
                pagevalidbetamountsum = pagevalidbetamountsum + res.data[i].validAmount;
                break;
              case 397: // JDB捕鱼
                gameName = helpersOthersGames.getGameName('JDBDianZiGameNames', res.data[i].mtype);
                pagebetamountsum = pagebetamountsum + res.data[i].bet;
                pagenetamountsum = pagenetamountsum + res.data[i].total;
                break;
              case 400: // DG视讯
                gameName = helpersOthersGames.getGameName('DGShiXunGames', res.data[i].gameId);
                pagebetamountsum = pagebetamountsum + res.data[i].betPoints;
                pagenetamountsum = pagenetamountsum + parseInt(String(res.data[i].winOrLoss - res.data[i].betPoints));
                break;
              case 402: // BG视讯
                gameName = helpersOthersGames.getGameName('BGShiXunGames', res.data[i].gameId);
                pagebetamountsum = pagebetamountsum + res.data[i].bAmount;
                pagenetamountsum = pagenetamountsum + res.data[i].payment;
                break;
              case 412: // MG视讯
                gameName = helpersOthersGames.getGameName('MGGames', res.data[i].gameCode);
                pagebetamountsum = pagebetamountsum + res.data[i].betAmount;
                pagenetamountsum = pagenetamountsum + res.data[i].total;
                break;
              case 399: // 天游捕鱼
                gameName = helpersOthersGames.getGameName('THQiPaiGames', res.data[i].game_type);
                roomName = helpersOthersGames.getGameName('THQiPaiGameRooms', res.data[i].room_level);
                pagebetamountsum = pagebetamountsum + res.data[i].deal_money;
                pagenetamountsum = pagenetamountsum + res.data[i].win_money;
                break;
              case 407: // 百胜捕鱼
                gameName = helpersOthersGames.getGameName('BSBYGameNames', res.data[i].game_id);
                pagebetamountsum = pagebetamountsum + res.data[i].all_bet;
                pagenetamountsum = pagenetamountsum + res.data[i].payoff;
                break;
              case 408: // CQ9捕鱼
                gameName = helpersOthersGames.getGameName('CQ9BYGameNames', res.data[i].gamecode);
                pagebetamountsum = pagebetamountsum + res.data[i].bet;
                pagenetamountsum = pagenetamountsum + parseInt(String(res.data[i].win - res.data[i].bet));
                break;
              case 403: // CQ9电子
                gameName = helpersOthersGames.getGameName('CQ9DianZiGames', res.data[i].gamecode);
                pagebetamountsum = pagebetamountsum + res.data[i].bet;
                pagenetamountsum = pagenetamountsum + parseInt(String(res.data[i].win - res.data[i].bet));
                break;
              case 413: // BBIN电子
                gameName = helpersOthersGames.getGameName('BBINDZGames', res.data[i].gameType);
                pagebetamountsum = pagebetamountsum + res.data[i].betAmount;
                pagenetamountsum = pagenetamountsum + res.data[i].payoff;
                break;
              case 414: // PT电子
                gameName = helpersOthersGames.getGameName('PTDianZiGames', res.data[i].gameName);
                pagebetamountsum = pagebetamountsum + res.data[i].bet;
                pagenetamountsum = pagenetamountsum + res.data[i].profit;
                break;
              case 415: // AG电子
                gameName = helpersOthersGames.getGameName('AGDZGames', res.data[i].gameType);
                pagebetamountsum = pagebetamountsum + res.data[i].account;
                pagenetamountsum = pagenetamountsum + res.data[i].cus_account;
                break;
              case 416: // AGPY电子
                gameName = helpersOthersGames.getGameName('AGPYDZGames', res.data[i].gameType);
                pagebetamountsum = pagebetamountsum + res.data[i].account;
                pagenetamountsum = pagenetamountsum + res.data[i].cus_account;
                break;
              case 401: // 新世界棋牌
                gameName = helpersOthersGames.getGameName('XSJQiPaiGames', res.data[i].kind_id);
                roomName = helpersOthersGames.getGameName('XSJQiPaiGameRooms', res.data[i].server_id);
                pagebetamountsum = pagebetamountsum + res.data[i].all_bet;
                pagenetamountsum = pagenetamountsum + res.data[i].profit;
                break;
              case 745: // 王者棋牌
                gameName = helpersOthersGames.getGameName('KGPlayGames', res.data[i].gameId);
                pagebetamountsum = pagebetamountsum + res.data[i].betAmount;
                pagenetamountsum = pagenetamountsum + res.data[i].winAmount;
                break;
              case 404: // 百胜棋牌
                gameName = helpersOthersGames.getGameName('BSQiPaiGames', res.data[i].game_id);
                pagebetamountsum = pagebetamountsum + res.data[i].all_bet;
                pagenetamountsum = pagenetamountsum + res.data[i].payoff;
                break;
              case 417: // 美博棋牌
                gameName = helpersOthersGames.getGameName('MBQPGames', res.data[i].nid);
                pagebetamountsum = pagebetamountsum + res.data[i].validBet;
                pagenetamountsum = pagenetamountsum + res.data[i].profit;
                break;
              case 405: // DT电子
                gameName = helpersOthersGames.getGameName('DTDianZiGames', res.data[i].gamecode);
                pagebetamountsum = pagebetamountsum + res.data[i].betprice;
                pagenetamountsum = pagenetamountsum + res.data[i].prizewins;
                break;
              case 396: // MG捕鱼
                gameName = helpersOthersGames.getGameName('MGGames', res.data[i].gameCode);
                pagebetamountsum = pagebetamountsum + res.data[i].betAmount;
                pagenetamountsum = pagenetamountsum + res.data[i].total;
                break;
              case 418: // 胜博体育
                pagebetamountsum = pagebetamountsum + res.data[i].betAmount;
                pagenetamountsum = pagenetamountsum + res.data[i].winAmount;
                break;
              case 395: // BTI体育
                gameName = helpersOthersGames.getGameName('BTITiYuGames', res.data[i].selectionList[0].branchId);
                pagebetamountsum = pagebetamountsum + res.data[i].totalStake;
                pagenetamountsum = pagenetamountsum + res.data[i].pl;
                break;
              case 409: // 皇冠体育
                pagebetamountsum = pagebetamountsum + res.data[i].gold_d;
                pagenetamountsum = pagenetamountsum + res.data[i].winGold_d;
                break;
              case 750: // sexy体育
                pagebetamountsum = pagebetamountsum + res.data[i].stake;
                pagenetamountsum = pagenetamountsum + res.data[i].payoutAmount;
                break;
              case 419: // QG红包
                gameName = helpersOthersGames.getGameName('QGHBGames', res.data[i].gameType);
                pagebetamountsum = pagebetamountsum + res.data[i].amountFlow;
                pagenetamountsum = pagenetamountsum + res.data[i].totalProfitLoss;
                break;
              case 420: // IG视讯
                gameName = helpersOthersGames.getGameName('IGSXGames', res.data[i].gameProcess);
                pagebetamountsum = pagebetamountsum + res.data[i].betScore;
                pagenetamountsum = pagenetamountsum + res.data[i].winScore;
                break;
              case 421: // 欧博视讯
                gameName = helpersOthersGames.getGameName('OBSXGames', res.data[i].gameType);
                pagebetamountsum = pagebetamountsum + res.data[i].validAmount;
                pagenetamountsum = pagenetamountsum + res.data[i].winOrLoss;
                break;
              case 422: // 雷火电竞
                gameName = helpersOthersGames.getGameName('LHDJGames', res.data[i].game_type_id);
                pagebetamountsum = pagebetamountsum + res.data[i].amount;
                pagenetamountsum = pagenetamountsum + res.data[i].earnings;
                break;
              case 423: // ibo视讯
                gameName = helpersOthersGames.getGameName('IBOSXGames', res.data[i].gtype);
                roomName = helpersOthersGames.getIBOSXWFType(res.data[i].rtype);
                pagebetamountsum = pagebetamountsum + res.data[i].vgold;
                pagenetamountsum = pagenetamountsum + res.data[i].win_gold;
                break;
              case 468: // 天成彩票
                gameName = helpersOthersGames.getGameName('TCCPGames', res.data[i].gameCode);
                pagebetamountsum = pagebetamountsum + res.data[i].betAmount;
                pagenetamountsum = pagenetamountsum + res.data[i].netPNL;
                break;
              case 490: // s128
                pagebetamountsum = pagebetamountsum + res.data[i].stake;
                pagenetamountsum = pagenetamountsum + res.data[i].winloss;
                break;
              case 504: // s128
                gameName = helpersOthersGames.getGameName('GPIcaipiao', res.data[i].game_id);
                pagebetamountsum = pagebetamountsum + res.data[i].bet;
                pagenetamountsum = pagenetamountsum + res.data[i].winlose;
                break;
              case 506: // km棋牌
                gameName = helpersOthersGames.getGameName('KingmakerGame', res.data[i].gameid);
                pagebetamountsum = pagebetamountsum + res.data[i].riskamt;
                pagenetamountsum = pagenetamountsum + res.data[i].winloss;
                break;
              case 507: // EVOPlay电子
                gameName = helpersOthersGames.getGameName('EVOPlayGame', res.data[i].game_id);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].bet);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winloss);
                break;
              case 508: // EVOPlay电子
                gameName = helpersOthersGames.getGameName('PPPlayGame', res.data[i].gameID);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].bet);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].profit);
                break;
              case 509: // EVOPlay电子
                gameName = helpersOthersGames.getGameName('KPPlayGames', res.data[i].type);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].bet);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].surplus);
                break;
              case 570: // SBO利记体育
                gameName = helpersOthersGames.getGameName('getSBOTYName', res.data[i].sportsType);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].actualStake);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winLost);
                break;
              case 571: // PG电子
                gameName = helpersOthersGames.getGameName('PGGames', res.data[i].gameId);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].betAmount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winLost);
                break;
              case 572: // ebet真人
                gameName = helpersOthersGames.getGameName('getEBETZRName', res.data[i].gameType);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].bet);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].profit);
                break;
              case 593: // UPG电子
                gameName = helpersOthersGames.getGameName('UPGGameCode', res.data[i].gameCode);
                pagebetamountsum += Number(res.data[i].betAmount);
                pagenetamountsum += Number(res.data[i].total);
                break;
              case 706: // SV388斗鸡
                pagebetamountsum += Number(res.data[i].realBetAmount);
                pagenetamountsum += Number(res.data[i].realWinLost);
                break;
              case 707: // FG电子
                gameName = helpersOthersGames.getGameName('FGGameCode', res.data[i].game_id);
                pagebetamountsum += Number(res.data[i].all_bets);
                pagenetamountsum += Number(res.data[i].result);
                break;
              case 708: // FG捕鱼
                gameName = helpersOthersGames.FGBYGameNames[res.data[i].game_id];
                pagebetamountsum += Number(res.data[i].all_bets);
                pagenetamountsum += Number(res.data[i].result);
                break;
              case 1388: // DB捕鱼
                gameName = helpersOthersGames.FGBYGameNames[res.data[i].game_id];
                pagebetamountsum += Number(res.data[i].all_bets);
                pagenetamountsum += Number(res.data[i].result);
                break;
              case 709: // FG棋牌
                gameName = helpersOthersGames.getGameName('FGPlayGames', res.data[i].game_id);
                pagebetamountsum += Number(res.data[i].all_bets);
                pagenetamountsum += Number(res.data[i].result);
                break;
              case 710: // tg视讯真人
                gameName = helpersOthersGames.getGameName('TGSXGames', res.data[i].gametype);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].betamount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].betincome);
                break;
              case 711: // hc电子
                gameName = helpersOthersGames.getGameName('HCGameCode', res.data[i].gameCode);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].bet);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].netWin);
                break;
              case 924: // XG真人
                gameName = helpersOthersGames.getGameName('XGSXGames', res.data[i].gameType);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].validBetAmount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].payoffAmount);
                break;
              case 925: // TRC赛马
                gameName = helpersOthersGames.getGameName('TRCBetTypes', res.data[i].betType);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].betAmount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].realWinLost);
                break;
              case 926: // SimplePlay电子
                gameName = helpersOthersGames.getGameName('SimplePlayGameCode', res.data[i].detail);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].betamount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].resultamount);
                break;
              case 938: // Ameba电子
                gameName = helpersOthersGames.getGameName('AMEBAGameCode', res.data[i].game_id);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].bet_amt);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].payout_amt);
                break;
              case 939: // PS电子
                gameName = helpersOthersGames.getGameName('PSDZGameCode', res.data[i].game_type_id);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].bet);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].win);
                break;
              case 927: // 365棋牌
                gameName = helpersOthersGames.getGameName('QiPai365Games', Number(res.data[i].gameTypeId));
                pagebetamountsum = pagebetamountsum + res.data[i].validbet;
                pagenetamountsum = pagenetamountsum + res.data[i].profit;
                break;
              case 928: // JILI棋牌
                gameName = helpersOthersGames.getGameName('JILIQPGameCode', Number(res.data[i].game_type_id));
                pagebetamountsum = pagebetamountsum + res.data[i].betAmount;
                pagenetamountsum = pagenetamountsum + res.data[i].profit;
                break;
              case 929: // JILI电子
                gameName = helpersOthersGames.getGameName('JILIDZGameCode', Number(res.data[i].game_type_id));
                pagebetamountsum = pagebetamountsum + res.data[i].betAmount;
                pagenetamountsum = pagenetamountsum + res.data[i].profit;
                break;
              case 930: // EA板球
                gameName = helpersOthersGames.getGameName('EABQGameCode', Number(res.data[i].game_type_id));
                pagebetamountsum = pagebetamountsum + res.data[i].trade_amount;
                pagenetamountsum = pagenetamountsum + res.data[i].profit;
                break;
              case 746: // 大唐棋牌
                gameName = helpersOthersGames.getGameName('DTQPGameCode', Number(res.data[i].game_type_id));
                pagebetamountsum = pagebetamountsum + res.data[i].validBet;
                pagenetamountsum = pagenetamountsum + res.data[i].profit;
                break;
              case 747: // 大唐捕鱼
                gameName = helpersOthersGames.getGameName('DTQPGameCode', Number(res.data[i].game_type_id));
                pagebetamountsum = pagebetamountsum + res.data[i].validBet;
                pagenetamountsum = pagenetamountsum + res.data[i].profit;
                break;
              case 931: // IM体育
                gameName = helpersOthersGames.getGameName('IMTYGameCode', Number(res.data[i].game_type_id));
                pagebetamountsum = pagebetamountsum + res.data[i].bet_amount;
                pagenetamountsum = pagenetamountsum + res.data[i].profit;
                break;
              case 980: // evo真人
                gameName = helpersOthersGames.getGameName('EVOSXGames', res.data[i].game_name);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].betamount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].resultamount);
                break;
              case 960: // RT电子
                gameName = helpersOthersGames.getGameName('RTDZGameCode', res.data[i].game_name);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].betamount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].resultamount);
                break;
              case 961: // VT电子
                gameName = helpersOthersGames.getGameName('VTDZGameCode', res.data[i].game_type_id);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].betMoney);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].profit);
                break;
              case 981: // IM电竞
                gameName = helpersOthersGames.getGameName('IMDJGameCode', res.data[i].game_type_id);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].stakeamount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winloss);
                break;
              case 1387: // DB电竞
                gameName = helpersOthersGames.getGameName('DBDJGameCode', res.data[i].game_type_id);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].stakeamount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winloss);
                break;
              case 1031: // 开元体育
                gameName = helpersOthersGames.getGameName('KYTYGameCode', res.data[i].game_type_id);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].turnover);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winlost);
                break;
              case 1305: // JOKER电子
                gameName = helpersOthersGames.getGameName('JOKERGameCode', res.data[i].gameCode);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].amount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].result);
                break;
              case 1308: // FB体育
                gameName = helpersOthersGames.getGameName('FBTYGameCode', Number(res.data[i].sportId));
                pagebetamountsum = pagebetamountsum + Number(res.data[i].stakeAmount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winlost);
                break;
              case 1309: // 完美真人
                gameName = helpersOthersGames.getGameName('WMZRGameCode', Number(res.data[i].sportId));
                pagebetamountsum = pagebetamountsum + Number(res.data[i].validbet);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winLoss);
                break;
              case 983: // MT电子
                gameName = helpersOthersGames.getGameName('MTDZGameCode', res.data[i].gameCode);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].betAmount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winAmount);
                break;
              case 1311: // NEXTSPIN电子
                gameName = helpersOthersGames.getGameName('NEXTSPINGameCode', res.data[i].gameCode);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].betAmount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winLoss);
                break;
              case 1312: // AESEXY真人
                gameName = helpersOthersGames.getGameName('AESEXYGameCode', res.data[i].gameCode);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].realBetAmount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].realWinLost);
                break;
              case 1313: // PRETTY真人
                gameName = helpersOthersGames.getGameName('PRETTYGameCode', res.data[i].gameCode);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].validAmt);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winLose);
                break;
              case 1314: // AFB真人
                gameName = helpersOthersGames.getGameName('AFBSXGameCode', res.data[i].gametype);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].validstake);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winloss);
                break;
              case 1315: // AFB电子
                gameName = helpersOthersGames.getGameName('AFBDZGameCode', res.data[i].gameCode);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].bet);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].win);
                break;
              case 1316: // AFB体育
                gameName = helpersOthersGames.getGameName('AFBTYGameCode', res.data[i].sportstype);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].validamt);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].w);
                break;
              case 1389: // DB体育
                gameName = helpersOthersGames.getGameName('DBTYGameCode', res.data[i].sportstype);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].validamt);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].w);
                break;
              case 1317: // SAGaming视讯
                gameName = helpersOthersGames.getGameName('SAGSXGameCode', res.data[i].gametype);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].rolling);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].resultamount);
                break;
              case 1318: // PGSOFT电子
                gameName = helpersOthersGames.getGameName('PGSOFTDZGameCode', res.data[i].gameCode);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].stake);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].payout);
                break;
              case 1319: // EVOPLAY电子
                gameName = helpersOthersGames.getGameName('EVOPLAYDZGameCode', res.data[i].gameId);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].bet);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winLoss);
                break;
              case 1306: // RSG电子
                gameName = helpersOthersGames.getGameName('RSGDZGameCode', res.data[i].game_type_id);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].betSum);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].netWinSum);
                break;
              case 1320: // TP电子
                gameName = helpersOthersGames.getGameName('TPDZGameCode', res.data[i].gameid);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].betamount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].betresult);
                break;
              case 1321: // KA电子
                gameName = helpersOthersGames.getGameName('KADZGameCode', res.data[i].gamename);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].cashplayed);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].cashwon);
                break;
              case 1322: // pp真人
                gameName = helpersOthersGames.getGameName('PPSXGameCode', res.data[i].gameID);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].bet);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].profit);
                break;
              case 1323: // NETENT电子
                gameName = helpersOthersGames.getGameName('NEDZGameCode', res.data[i].gameid);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].betamount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].betresult);
                break;
              case 1324: // FC电子
                gameName = helpersOthersGames.getGameName('FCDZGameCode', res.data[i].gameID);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].bet);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winlose);
                break;
              case 1325: // SG电子
                gameName = helpersOthersGames.getGameName('SGDZGameCode', res.data[i].gameCode);
                pagebetamountsum = pagebetamountsum + Number(res.data[i].betAmount);
                pagenetamountsum = pagenetamountsum + Number(res.data[i].winLoss);
                break;
              case 1326: // 沙巴彩票
                gameName = '';
                pagebetamountsum = pagebetamountsum + res.data[i].stake;
                pagenetamountsum = pagenetamountsum + res.data[i].winlost_amount;
                break;
              case 1383: // DB棋牌
                gameName = helpersOthersGames.getGameName('DBQiPaiGames', res.data[i].kind_id);
                // roomName = helpersOthersGames.getLyGameRoom(item.kind_id, item.server_id);
                if (isShow && payload.type === 'team' && item.cust_name) res.data[i].cust_name = displayCardcode(item.cust_name, 1, 4);
                pagebetamountsum = pagebetamountsum + res.data[i].all_bet;
                pagevalidbetamountsum = pagevalidbetamountsum + res.data[i].cell_score;
                pagenetamountsum = pagenetamountsum + res.data[i].profit;
                break;
              case 1385: // DB彩票
                gameName = '';
                pagebetamountsum = pagebetamountsum + res.data[i].betMoney;
                pagenetamountsum = pagenetamountsum + res.data[i].winAmount;
                break;

            }
            if (isShow && payload.type === 'team' && item.cust_name) item.cust_name = displayCardcode(item.cust_name, 1, 4);
            if (isShow && payload.type === 'team' && item.cust_code) item.cust_code = displayCardcode(item.cust_code, 1, 4);
            item.game_name = gameName;
            item.room_name = roomName;
          }
        }
        if (res.t) {
          // 保留两位小数，与运维一致
          res.t.page_bet_amount_sum = Number(fixFloat(utilsTotal.moneyToFloat(Math.abs(pagebetamountsum))).toFixed(2));
          res.t.page_net_amount_sum = Number(fixFloat(utilsTotal.moneyToFloat(pagenetamountsum)).toFixed(2));
          res.t.page_valid_bet_amount_sum = Number(fixFloat(utilsTotal.moneyToFloat(pagevalidbetamountsum)).toFixed(2));
          if (res.t.profit_sum) {
            res.t.profit_sum = Number(fixFloat(utilsTotal.moneyToFloat(res.t.profit_sum)).toFixed(2));
          }
          if (res.t.cell_score_sum) {
            res.t.cell_score_sum = Number(fixFloat(utilsTotal.moneyToFloat(res.t.cell_score_sum)).toFixed(2));
          }
        }
        if (res) {
          userStore.setpcOthersGameHistory(res);
        }
        return Promise.resolve(res);
      } else {
        return Promise.reject(respones.data);
      }
    } catch (error) {
      // ElMessage.error()
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 账户相关的一些配置信息
   * @param payload.key 本次请求的key，默认为default
   */
  async reqBankInfoStatusConfig(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      option_code: payload.option_code
    };
    const res: any = await network.request({
      cmdId: 370730,
      data
    });
    if (res && res.type === 'success') {
      this.bankConfig = {
        ...this.bankConfig,
        [payload.option_code]: {
          isVisible: JSON.parse(res.data.value),
          isRequire: JSON.parse(res.data.value2)
        }
      };
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },

  // 账号价值
  async reqAccountValue() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;

    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
    }
    const res = await network.request({
      cmdId: 382008,
      data
    });
    if (res && res.type === 'success') {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },

  // 系统回收/我要赎回
  async reqSubmitTransaction(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
    }
    const res = await network.request({
      cmdId: 382009,
      data
    });
    if (res && res.type === 'success') {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },

  // 账号交易记录
  async reqAccountTransactionHistory(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;

    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      page_start: payload.page,
      num_per_page: payload.num_per_page ? payload.num_per_page : '10'
    }
    const res = await network.request({
      cmdId: 382010,
      data
    });

    if (res && res.type === 'success') {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },

  // 评估价格
  async reqAssessThePrice() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
    }
    const res = await network.request({
      cmdId: 382001,
      data
    });
    if (res && res.type === 'success') {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },

  // 是否显示账号交易的按钮
  // reqIsShowAccountTransactions(context, payload) {
  //   let data = {}
  //   data.tid = context.rootGetters['tenant/tid']
  //   let sendPayLoad = {
  //     cmdId: 382007,
  //     data: data,
  //     callback: (res, err) => {
  //       callback.doCallback(payload, res, err)
  //     }
  //   }
  //   context.dispatch('network/request', sendPayLoad, {root: true})
  // },
};
