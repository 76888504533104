// 玩法规则信息
export const PlayRuleInfo = {
  // 北京赛车
  34000: {
    tip: '从第一名中至少选择1个号码组成一注',
    help: '选择1个号码组成一注。所选号码与当期冠军一致，即为中奖。',
    example: '投注方案：2',
    result: '开奖号码：2 *,即中猜冠军',
    award: '19.60'
  },
  34100: {
    tip: '从第一名、第二名中各选择1个号码组成一注',
    help: '选择2个数字号码竞猜全部开奖号码，投注号码与开奖号码前2相同中奖。',
    example: '投注方案：1 2',
    result: '开奖号码：1 2 *,即中猜冠亚军',
    award: '中2:55.00,中1:2.00',
    isPK: true
  },
  34200: {
    tip: '从第一名、第二名、第三名中各选择1个号码组成一注',
    help: '选择3个数字号码竞猜全部开奖号码，投注号码与开奖号码前3相同中奖',
    example: '投注方案：1 2 3',
    result: '开奖号码：1 2 3 *，即中猜冠亚季军',
    award: '中3:160.00,中2:10.00,中1:2.00.',
    isPK: true
  },
  34300: {
    tip: '从冠、亚、季至第十名中的“大、小、单、双中至少选择一个组成一注”',
    help: '从冠、亚、季至第十名中的“大、小、单、双中至少选择一个组成一注”',
    example: '投注方案：冠：小双',
    result: '开奖号码：冠：小双，即中小双',
    award: ''
  },
  34400: {
    tip: '从\"龙、虎\"中至少选择一个组成一注',
    help: '开奖结果中第一位车号对比第十位。大即是龙中奖，小的话是虎中奖。',
    example: '投注方案：龙',
    result: '开奖号码：8********1，即中猜龙虎斗',
    award: '3.8'
  },
  34500: {tip: ''},
  34600: {tip: ''},
  34601: {tip: ''},
  34700: {
    tip: '从第一名至第十名任意位置上选择1个或1个以上号码',
    help: '从第一名至第十名任意位置上选择1个或1个以上号码,所选号码与相同位置上的开奖号码一致即为中奖。',
    example: '第一名01',
    result: '开奖号码：第一名01，即中定位胆第一名。',
    award: ''
  },
  34800: {
    tip: '从\"单、双\"中至少选择一个组成一注',
    help: '从“单、双”中至少选择一个组成一注,开奖号码冠亚军的和值单双与所选号码一致即为中奖。',
    example: '选“单“',
    result: '开奖号码：第一名01，第二名02，和值01+02=03为单，即中奖。',
    award: ''
  },
  34801: {
    tip: '从\"大、小\"中至少选择一个组成一注',
    help: '从“大、小”中至少选择一个组成一注,和值大于11为大，小于或等于11为小，开奖号码冠亚军的和值大小与所选号码一致即为中奖。',
    example: '选“大“',
    result: '开奖号码：第一名05,第二名07，和值05+07=12为大，即中奖。',
    award: ''
  },
  34802: {
    tip: '从03~19的号码中至少选择一个号码进行投注',
    help: '“冠亚和值”可能出现的结果为3～19， 投中对应“冠亚和值”数字的视为中奖，其余视为不中奖。',
    example: '选择10',
    result: '开奖号码：第一名01，第二名为09，和值为01+09=10即中奖',
    award: ''
  },
  // 重庆时时彩(时时彩)
  // 定位胆
  17000: {
    tip: '从万、千、百、十、个位号选一个号码组成一注',
    help: '从万位、千位、百位、十位、个位任意位置上至少选择1个以上号码，所选号码与相同位置上的开奖号码一致，即为中奖',
    example: '投注方案：万位1',
    result: '开奖号码：万位1，即中定位胆万位',
    award: ''
  },
  // 前二直选复式
  17001: {
    tip: '从万、千位号各选一个号码组成一注',
    help: '从万位、千位中选择一个2位数号码组成一注，所选号码与开奖号码的前2位相同，且顺序一致，即为中奖',
    example: '投注方案：58',
    result: '开奖号码：前二位58，即中前二星直选',
    award: '',
    isPK: true
  },
  // 前二直选和值
  17002: {
    tip: '从0-18中任意选择1个或1个以上号码',
    help: '所选数值等于开奖号码万位、千位二个数字相加之和，即为中奖',
    example: '投注方案：和值1',
    result: '开奖号码：前二位01，10，即中前二星直选',
    award: '',
    isPK: true
  },
  // 前二直选跨度
  17003: {
    tip: '从0-9中选择1个号码',
    help: '所选数值等于开奖号码的前2位最大与最小数字相减之差，即为中奖',
    example: '投注方案：跨度9',
    result: '开奖号码：前二位90或09，即中前二直选',
    award: ''
  },
  // 后二直选复式
  17004: {
    tip: '从十、个位号各选一个号码组成一注',
    help: '从十位、个位中选择一个2位数号码组成一注，所选号码与开奖号码的后2位相同，且顺序一致，即为中奖。',
    example: '投注方案：58',
    result: '开奖号码：后二位58，即中后二直选',
    award: '',
    isPK: true
  },
  // 后二直选和值
  17005: {
    tip: '从0-18中任意选择1个或1个以上号码',
    help: '所选数值等于开奖号码十位、个位二个数字相加之和，即为中奖',
    example: '投注方案：和值1',
    result: '开奖号码：后二位01，10，即中后二直选',
    award: '',
    isPK: true
  },
  // 后二直选跨度
  17006: {
    tip: '从0-9中选择1个号码',
    help: '所选数值等于开奖号码的后2位最大与最小数字相减之差，即为中奖',
    example: '投注方案：跨度9',
    result: '开奖号码：后二位90或09，即中后二直选',
    award: ''
  },
  // 前二组选复式
  17007: {
    tip: '从万、千位号选一个号码组成一注',
    help: '从0-9中选2个号码组成一注，所选号码与开奖号码的万位、千位相同（不含对子号），顺序不限，即中奖',
    example: '投注方案：58',
    result: '开奖号码：前二位58或是85（顺序不限，不含对子）即中前二组选',
    award: '',
    isPK: true
  },
  // 前二组选和值
  17008: {
    tip: '从1-17中任意选择1个或1个以上号码',
    help: '所选数值等于开奖号码的万位、千位的数字之和，即为中奖',
    example: '投注方案：和值1',
    result: '开奖号码：前二位10或是01（顺序不限，不含对子）即中前二组选',
    award: '',
    isPK: true
  },
  // 前二组选包胆
  17009: {
    tip: '从0-9中选择1个号码',
    help: '从0-9中任意选1个包胆号码，开奖号码的万位，千位中任意1位包含所选的包胆号码相同（不含对子号）即为中奖',
    example: '投注方案：包胆8',
    result: '开奖号码：前二位8x，且x不等于8，即中前二组选',
    award: '',
    isPK: true
  },
  // 后二组选复式
  17010: {
    tip: '从十、个位号选一个号码组成一注',
    help: '从0-9中选2个号码组成一注，所选号码与开奖号码的十位、个位相同（不含对子号），顺序不限，即中奖',
    example: '投注方案：58',
    result: '开奖号码：后二位58或是85（顺序不限，不含对子）即中后二组选',
    award: '',
    isPK: true
  },
  // 后二组选和值
  17011: {
    tip: '从1-17中任意选择1个或1个以上号码',
    help: '所选数值等于开奖号码的十位、个位的数字之和，即为中奖',
    example: '投注方案：和值1',
    result: '开奖号码：后二位10或是01（顺序不限，不含对子）即中后二组选',
    award: '',
    isPK: true
  },
  // 后二组选包胆
  17012: {
    tip: '从0-9中选择1个号码',
    help: '从0-9中任意选1个包胆号码，开奖号码的十位，个位中任意1位包含所选的包胆号码相同（不含对子号）即为中奖',
    example: '投注方案：包胆8',
    result: '开奖号码：后二位8x，且x不等于8，即中后二组选',
    award: '',
    isPK: true
  },
  // 前三直选复式
  17013: {
    tip: '从万位、千位、百位各选一个号码组成一注',
    help: '从万位、千位、百位中选择一个3位数号码组成一注，所选号码与开奖号码前3位相同，且顺序一致，即为中奖',
    example: '投注方案：345',
    result: '开奖号码：前三位 345，即中前三直选',
    award: '',
    isPK: true
  },
  // 前三直选和值
  17014: {
    tip: '从0-27中任意选择1个或1个以上号码',
    help: '所选数值等于开奖号码的万位、千位、百位三个数字相加之和，即为中奖',
    example: '投注方案：和值1',
    result: '开奖号码：前三位 001、010、100，即中前三直选和值',
    award: '',
    isPK: true
  },
  // 前三直选跨度
  17015: {
    tip: '从0-9中选择1个号码',
    help: '所选数值等于开奖号码的前三位最大与最小相减之差，即为中奖',
    example: '投注方案：跨度8',
    result: '开奖号码：前三位 0,8,X，其中X不等于9；或者前三位1,9,X，其中X不等于0，即中前三直选跨度',
    award: ''
  },
  // 前三直选组合
  17016: {
    tip: '从万位、千位、百位号选一个号码组成三注',
    help: '从万位、千位、百位中至少各选一个号码组成1-3星的组合共3注，所选号码的百位与开奖号码全部相同，则中1个3等奖；所选号码的千位、百位与开奖号码全部相同，则中1个3等奖以及1个2等奖，依此类推，最高可中3个奖',
    example: '投注方案：购买6+7+8，有以下3注:678(三星)、78(二星)、8(一星)构成',
    result: '开奖号码：678，即可中三星、二星、一星各1注',
    award: ''
  },
  // 前三组选组三
  17017: {
    tip: '从0-9中任意选择2个或2个以上号码',
    help: '从0-9选择2个数字组成二注，所选号码与开奖号码的万位、千位、百位相同，且顺序不限，即为中奖',
    example: '投注方案：588',
    result: '开奖号码：前三位 588（顺序不限），即中前三组选三',
    award: '',
    isPK: true
  },
  // 前三组选组六
  17018: {
    tip: '从0-9中任意选择3个或3个以上号码',
    help: '从0-9选择3个数字组成一注，所选号码与开奖号码的万位、千位、百位相同，且顺序不限，即为中奖',
    example: '投注方案：258',
    result: '开奖号码：前三位 852（顺序不限），即中前三组选六',
    award: '',
    isPK: true
  },
  // 前三组选和值
  17019: {
    tip: '从1-26中任意选择1个以上号码',
    help: '所选数值等于开奖号码万位、千位、百位三个数字相加之和（非豹子号），即为中奖',
    example: '投注方案：和值3',
    result: '开奖号码： 前三位003（顺序不限）即中前三组选三，或者前三位012（顺序不限）即中前三组选和值',
    award: ''
  },
  // 前三组选包胆
  17020: {
    tip: '从0-9中任选1个号码',
    help: '从0-9中任意选择1个包胆号码，开奖号码的万位、千位、百位中任意1位与所选包胆号码相同（不含豹子号），即为中奖',
    example: '投注方案：包胆3',
    result: '开奖号码：前三位33x，即中前三组选三，或者前三位3xx，即中前三组选包胆',
    award: '',
    isPK: true
  },
  // 前三其他和值尾数
  17021: {
    tip: '从0-9中任选1个号码',
    help: '所选数值等于开奖号码的万位、千位、百位三个数字相加之和的尾数，即为中奖',
    example: '投注方案：和值尾数8',
    result: '开奖号码：前三位936，和值尾数为8，即中和值尾数',
    award: ''
  },
  // 前三其他特殊号码
  17022: {
    tip: '选择一个号码形态',
    help: '所选的号码特殊属性和开奖号码前3位属性一致，即为中奖。其中；1.顺子号的万、千、百位不分顺序；2.对子号指的是开奖号码的前三位当中，任意2位数字相同的三位数号码',
    example: '投注方案：豹子对子顺子',
    result: '开奖号码：前三位888，即中豹子；前三位678，即中顺子；前三位558即中对子',
    award: ''
  },
  // 中三直选复式
  17023: {
    tip: '从千位、百位、十位各选一个号码组成一注',
    help: '从千位、百位、十位中选择一个3位数号码组成一注，所选号码与开奖号码中3位相同，且顺序一致，即为中奖',
    example: '投注方案：345',
    result: '开奖号码：中三位 345，即中中三直选',
    award: '',
    isPK: true
  },
  // 中三直选和值
  17024: {
    tip: '从0-27中任意选择1个或1个以上号码',
    help: '所选数值等于开奖号码的千位、百位、十位三个数字相加之和，即为中奖',
    example: '投注方案：和值1',
    result: '开奖号码：中三位 001、010、100，即中中三直选和值',
    award: '',
    isPK: true
  },
  // 中三直选跨度
  17025: {
    tip: '从0-9中选择1个号码',
    help: '所选数值等于开奖号码的中三位最大与最小相减之差，即为中奖',
    example: '投注方案：跨度8',
    result: '开奖号码：中三位 0,8,X，其中X不等于9；或者中三位1,9,X，其中X不等于0，即中中三直选跨度',
    award: ''
  },
  // 中三直选组合
  17026: {
    tip: '从千位、百位、十位号选一个号码组成三注',
    help: '从千位、百位、十位中至少各选一个号码组成1-3星的组合共3注，所选号码的十位与开奖号码全部相同，则中1个3等奖；所选号码的百位、十位与开奖号码全部相同，则中1个3等奖以及1个2等奖，依此类推，最高可中3个奖',
    example: '投注方案：购买6+7+8，有以下3注:678(三星)、78(二星)、8(一星)构成',
    result: '开奖号码：678，即可中三星、二星、一星各1注',
    award: ''
  },
  // 中三组选组三
  17027: {
    tip: '从0-9中任意选择2个或2个以上号码',
    help: '从0-9选择2个数字组成二注，所选号码与开奖号码的千位、百位、十位相同，且顺序不限，即为中奖',
    example: '投注方案：588',
    result: '开奖号码：中三位 588（顺序不限），即中中三组选三',
    award: '',
    isPK: true
  },
  // 中三组选组六
  17028: {
    tip: '从0-9中任意选择3个或3个以上号码',
    help: '从0-9选择3个数字组成一注，所选号码与开奖号码的千位、百位、十位相同，且顺序不限，即为中奖',
    example: '投注方案：258',
    result: '开奖号码：中三位 852（顺序不限），即中中三组选六',
    award: '',
    isPK: true
  },
  // 中三组选和值
  17029: {
    tip: '从1-26中任意选择1个以上号码',
    help: '所选数值等于开奖号码千位、百位、十位三个数字相加之和（非豹子号），即为中奖',
    example: '投注方案：和值3',
    result: '开奖号码： 中三位003（顺序不限）即中中三组选三，或者中三位012（顺序不限）即中中三组选和值',
    award: ''
  },
  // 中三组选包胆
  17030: {
    tip: '从0-9中任选1个号码',
    help: '从0-9中任意选择1个包胆号码，开奖号码的千位、百位、十位中任意1位与所选包胆号码相同（不含豹子号），即为中奖',
    example: '投注方案：包胆3',
    result: '开奖号码：中三位33x，即中中三组选三，或者中三位3xx，即中中三组选包胆',
    award: '',
    isPK: true
  },
  // 中三其他和值尾数
  17031: {
    tip: '从0-9中任选1个号码',
    help: '所选数值等于开奖号码的千位、百位、十位三个数字相加之和的尾数，即为中奖',
    example: '投注方案：和值尾数8',
    result: '开奖号码：中三位936，和值尾数为8，即中和值尾数',
    award: ''
  },
  // 中三其他特殊号码
  17032: {
    tip: '选择一个号码形态',
    help: '所选的号码特殊属性和开奖号码中3位属性一致，即为中奖。其中；1.顺子号的千、百、十位不分顺序；2.对子号指的是开奖号码的中三位当中，任意2位数字相同的三位数号码',
    example: '投注方案：豹子对子顺子',
    result: '开奖号码：中三位888，即中豹子；中三位678，即中顺子；中三位558即中对子',
    award: ''
  },
  // 后三直选复式
  17033: {
    tip: '从百位、十位、个位各选一个号码组成一注',
    help: '从百位、十位、个位中选择一个3位数号码组成一注，所选号码与开奖号码后3位相同，且顺序一致，即为中奖',
    example: '投注方案：345',
    result: '开奖号码：后三位 345，即中后三直选',
    award: '',
    isPK: true
  },
  // 后三直选和值
  17034: {
    tip: '从0-27中任意选择1个或1个以上号码',
    help: '所选数值等于开奖号码的百位、十位、个位三个数字相加之和，即为中奖',
    example: '投注方案：和值1',
    result: '开奖号码：后三位 001、010、100，即中后三直选和值',
    award: '',
    isPK: true
  },
  // 后三直选跨度
  17035: {
    tip: '从0-9中选择1个号码',
    help: '所选数值等于开奖号码的后三位最大与最小相减之差，即为中奖',
    example: '投注方案：跨度8',
    result: '开奖号码：后三位 0,8,X，其中X不等于9；或者后三位1,9,X，其中X不等于0，即中后三直选跨度',
    award: ''
  },
  // 后三直选组合
  17036: {
    tip: '从百位、十位、个位号选一个号码组成三注',
    help: '从百位、十位、个位中至少各选一个号码组成1-3星的组合共3注，所选号码的个位与开奖号码全部相同，则中1个3等奖；所选号码的个位、十位与开奖号码全部相同，则中1个3等奖以及1个2等奖，依此类推，最高可中3个奖',
    example: '投注方案：购买6+7+8，有以下3注:678(三星)、78(二星)、8(一星)构成',
    result: '开奖号码：678，即可中三星、二星、一星各1注',
    award: ''
  },
  // 后三组选组三
  17037: {
    tip: '从0-9中任意选择2个或2个以上号码',
    help: '从0-9选择2个数字组成二注，所选号码与开奖号码的百位、十位、个位相同，且顺序不限，即为中奖',
    example: '投注方案：588',
    result: '开奖号码：后三位 588（顺序不限），即中后三组选三',
    award: '',
    isPK: true
  },
  // 后三组选组六
  17038: {
    tip: '从0-9中任意选择3个或3个以上号码',
    help: '从0-9选择3个数字组成一注，所选号码与开奖号码的百位、十位、个位相同，且顺序不限，即为中奖',
    example: '投注方案：258',
    result: '开奖号码：后三位 852（顺序不限），即中后三组选六',
    award: '',
    isPK: true
  },
  // 后三组选和值
  17039: {
    tip: '从1-26中任意选择1个以上号码',
    help: '所选数值等于开奖号码百位、十位、个位三个数字相加之和（非豹子号），即为中奖',
    example: '投注方案：和值3',
    result: '开奖号码： 后三位003（顺序不限）即中后三组选三，或者后三位012（顺序不限）即中后三组选六',
    award: ''
  },
  // 后三组选包胆
  17040: {
    tip: '从0-9中任选1个号码',
    help: '从0-9中任意选择1个包胆号码，开奖号码的百位、十位、个位中任意1位与所选包胆号码相同（不含豹子号），即为中奖',
    example: '投注方案：包胆3',
    result: '开奖号码：后三位33x，即中后三组选三，或者后三位3xx，即中后三组选六',
    award: '',
    isPK: true
  },
  // 后三其他和值尾数
  17041: {
    tip: '从0-9中任选1个号码',
    help: '所选数值等于开奖号码的百位、十位、个位三个数字相加之和的尾数，即为中奖',
    example: '投注方案：和值尾数8',
    result: '开奖号码：后三位936，和值尾数为8，即中和值尾数',
    award: ''
  },
  // 后三其他特殊号码
  17042: {
    tip: '选择一个号码形态',
    help: '所选的号码特殊属性和开奖号码后3位属性一致，即为中奖。其中；1.顺子号的百、十、个位不分顺序；2.对子号指的是开奖号码的后三位当中，任意2位数字相同的三位数号码',
    example: '投注方案：豹子对子顺子',
    result: '开奖号码：后三位888，即中豹子；后三位678，即中顺子；后三位558即中对子',
    award: ''
  },
  // 后四直选
  17043: {
    tip: '从千、百、十、个位号选一个号码组成一注',
    help: '从千位、百位、十位、个位选择一个4位数号码组成一注，所选号码与开奖号码全部相同，且顺序一致，即为中奖',
    example: '投注方案：3456',
    result: '开奖号码：3456，即中四星直选',
    award: '',
    isPK: true
  },
  // 五星直选
  17044: {
    tip: '从万、千、百、十、个位各选一个号码组成一注',
    help: '从万位、千位、百位、十位、个位选择一个5位数号码组成一注，所选号码与开奖号码全部相同，且顺序一致，即为中奖',
    example: '投注方案：13456',
    result: '开奖号码：13456，即中五星直选',
    award: '',
    isPK: true
  },
  // 大小单双后二
  17045: {
    tip: '从十位、个位中的“大、小、单、双”中至少各选一个组成一注',
    help: '对十位、个位的“大（56789）小（01234）、单（13579）双（02468）”型态进行购买，所选号码的位置、型态与开奖号码的位置、型态相同，即为中奖',
    example: '投注方案：大单',
    result: '开奖号码：十位与个位“大单”，即中后二大小单双',
    award: ''
  },
  // 大小单双后三
  17046: {
    tip: '从百位、十位、个位中的“大、小、单、双”中至少各选一个组成一注',
    help: '对百位、十位和个位的“大（56789）小（01234）、单（13579）双（02468）”型态进行购买，所选号码的位置、型态与开奖号码的位置、型态相同，即为中奖',
    example: '投注方案：大单大',
    result: '开奖号码：百位、十位、个位“大单大”，即中后三大小单双',
    award: ''
  },
  // 大小单双前二
  17047: {
    tip: '从万位、千位中的“大、小、单、双”中至少各选一个组成一注',
    help: '对万位、千位的“大（56789）小（01234）、单（13579）双（02468）”型态进行购买，所选号码的位置、型态与开奖号码的位置、型态相同，即为中奖',
    example: '投注方案：大单',
    result: '开奖号码：万位与千位“大单”，即中前二大小单双',
    award: ''
  },
  // 大小单双前三
  17048: {
    tip: '从万位、千位、百位中的“大、小、单、双”中至少各选一个组成一注',
    help: '对万位、千位和百位的“大（56789）小（01234）、单（13579）双（02468）”型态进行购买，所选号码的位置、型态与开奖号码的位置、型态相同，即为中奖',
    example: '投注方案：大单大',
    result: '开奖号码：万位、千位、百位“大单大”，即中前三大小单双',
    award: ''
  },

  // 前三一码不定位
  17049: {
    tip: '从0-9中任意选择1个以上号码',
    help: '从0-9中选择1个号码，每注由1号码组成，只要开奖号码的万位、千位、百位中包含所选号码，即为中奖',
    example: '投注方案：1',
    result: '开奖号码：前三位至少出现1个1，即中前三一码不定位',
    award: ''
  },
  // 前三二码不定位
  17050: {
    tip: '从0-9中任意选择2个以上号码',
    help: '从0-9中选择2个号码，每注由2个不同号码组成，开奖号码的万位、千位、百位中包所选的2个号码，即为中奖',
    example: '投注方案：12',
    result: '开奖号码：前三位至少出现1和2各一个，即中前三二码不定位',
    award: ''
  },
  // 后三一码不定位
  17051: {
    tip: '从0-9中任意选择1个以上号码',
    help: '从0-9中选择1个号码，每注由1号码组成，只要开奖号码的百位、十位、个位中包含所选号码，即为中奖',
    example: '投注方案：1',
    result: '开奖号码：后三位至少出现1个1，即中后三一码不定位',
    award: ''
  },
  // 后三二码不定位
  17052: {
    tip: '从0-9中任意选择2个以上号码',
    help: '从0-9中选择2个号码，每注由2个不同号码组成，开奖号码的百位、十位、个位中包所选的2个号码，即为中奖',
    example: '投注方案：12',
    result: '开奖号码：后三位至少出现1和2各一个，即中后三二码不定位',
    award: ''
  },
  // 前四一码不定位
  17053: {
    tip: '从0-9中任意选择1个以上号码',
    help: '从0-9中选择1个号码，每注由1个号码组成，只要开奖号码的万位、千位、百位、十位中包含所选号码，即为中奖',
    example: '投注方案：1',
    result: '开奖号码：前四位，至少出现1个1，即中前四一码不定位',
    award: ''
  },
  // 前四二码不定位
  17054: {
    tip: '从0-9中任意选择2个以上号码',
    help: '从0-9中选2个号码，每注由2个不同的号码组成，开奖号码的万位、千位、百位、十位中同时包含所选的2个号码，即为中奖',
    example: '投注方案：12',
    result: '开奖号码：前四位，至少出现1和2各一个，即中前四二码不定位',
    award: ''
  },
  // 后四一码不定位
  17055: {
    tip: '从0-9中任意选择1个以上号码',
    help: '从0-9中选择1个号码，每注由1个号码组成，只要开奖号码的千位、百位、十位、个位中包含所选号码，即为中奖',
    example: '投注方案：1',
    result: '开奖号码：后四位，至少出现1个1，即中后四一码不定位',
    award: ''
  },
  // 后四二码不定位
  17056: {
    tip: '从0-9中任意选择2个以上号码',
    help: '从0-9中选2个号码，每注由2个不同的号码组成，开奖号码的千位、百位、十位、个位中同时包含所选的2个号码，即为中奖',
    example: '投注方案：12',
    result: '开奖号码：后四位，至少出现1和2各一个，即中后四二码不定位',
    award: ''
  },
  // 五星一码不定位
  17057: {
    tip: '从0-9中任意选择1个以上号码',
    help: '从0-9中选择1个号码，每注由1个号码组成，只要开奖号码的万位、千位、百位、十位、个位中包含所选号码，即为中奖',
    example: '投注方案：1',
    result: '开奖号码：至少出现1个1，即中五星一码不定位',
    award: ''
  },
  // 五星二码不定位
  17058: {
    tip: '从0-9中任意选择2个以上号码',
    help: '从0-9中选2个号码，每注由2个不同的号码组成，开奖号码的万位、千位、百位、十位、个位中同时包含所选的2个号码，即为中奖',
    example: '投注方案：12',
    result: '开奖号码：至少出现1和2各一个，即中五星二码不定位',
    award: ''
  },
  // 五星三码不定位
  17059: {
    tip: '从0-9中任意选择3个以上号码',
    help: '从0-9中选3个号码，每注由3个不同的号码组成，开奖号码的万位、千位、百位、十位、个位中同时包含所选的3个号码，即为中奖',
    example: '投注方案：123',
    result: '开奖号码：至少出现1、2、3各一个，即中五星三码不定位',
    award: ''
  },
  // 四星组选4
  17060: {
    tip: '从“三重号”中选择一个号码，“单号”中选择一个号码组成一注',
    help: '选择1个三重号码和1个单号号码组成一注，所选号码与开奖号码的千位、百位、十位、个位相同，且所选三重号码在开奖号码中出现了3次，即为中奖。',
    example: '投注方案：三重号：8，单号：2',
    result: '只要开奖的四个数字从小到大排列为2、8、8、8，即可中四星组选4。',
    award: '',
    isPK: true
  },
  // 四星组选6
  17061: {
    tip: '从“二重号”中选择两个号码组成一注',
    help: '选择2个二重号码组成一注，所选的2个二重号码在开奖号码的千位、百位、十位、个位中分别出现了2次，即为中奖。',
    example: '投注方案：二重号：2、8',
    result: '只要开奖的四个数字从小到大排列为2、2、8、8，即可中四星组选6。',
    award: '',
    isPK: true
  },
  // 四星组选12
  17062: {
    tip: '从“二重号”中选择一个号码，“单号”中选择两个号码组成一注',
    help: '选择1个二重号码和2个单号号码组成一注，所选号码与开奖号码的千位、百位、十位、个位相同，且所选二重号码在开奖号码中出现了2次，即为中奖。',
    example: '投注方案：二重号：8，单号：0、6',
    result: '只要开奖的四个数字从小到大排列为 0、6、8、8，即可中四星组选12。',
    award: '',
    isPK: true
  },
  // 四星组选24
  17063: {
    tip: '从0-9中选择4个号码组成一注',
    help: '从0-9中任意选择4个号码组成一注，所选号码与开奖号码的千位、百位、十位、个位相同，且顺序不限，即为中奖。',
    example: '投注方案：0 5 6 8',
    result: '开奖号码的四个数字只要包含0、5、6、8，即可中四星组选24。',
    award: '',
    isPK: true
  },
  // 五星组选5
  17064: {
    tip: '从“四重号”选择一个号码，“单号”中选择一个号码组成一注',
    help: '选择1个四重号码和1个单号号码组成一注，所选的单号号码与开奖号码相同，且所选四重号码在开奖号码中出现了4次，即为中奖。',
    example: '投注方案：四重号：8；单号：1',
    result: '开奖号码：18888（顺序不限），即可中五星组选5。',
    award: '',
    isPK: true
  },
  // 五星组选10
  17065: {
    tip: '从“三重号”选择一个号码，“二重号”中选择一个号码组成一注',
    help: '选择1个三重号码和1个二重号码，所选三重号码在开奖号码中出现3次，并且所选二重号码在开奖号码中出现了2次，即为中奖。',
    example: '投注方案：三重号：8；二重号：1',
    result: '开奖号码：11888（顺序不限），即可中五星组选10。',
    award: '',
    isPK: true
  },
  // 五星组选20
  17066: {
    tip: '从“三重号”选择一个号码，“单号”中选择两个号码组成一注',
    help: '选择1个三重号码和2个单号号码组成一注，所选的单号号码与开奖号码相同，且所选三重号码在开奖号码中出现了3次，即为中奖。',
    example: '投注方案：三重号：8；单号：01',
    result: '开奖号码：01888（顺序不限），即可中五星组选20。',
    award: '',
    isPK: true
  },
  // 五星组选30
  17067: {
    tip: '从“二重号”选择两个号码，“单号”中选择一个号码组成一注',
    help: '选择2个二重号和1个单号号码组成一注，所选的单号号码与开奖号码相同，且所选的2个二重号码分别在开奖号码中出现了2次，即为中奖。',
    example: '投注方案：二重号：68；单号：0',
    result: '开奖号码：06688（顺序不限），即可中五星组选30。',
    award: '',
    isPK: true
  },
  // 五星组选60
  17068: {
    tip: '从“二重号”选择一个号码，“单号”中选择三个号码组成一注',
    help: '选择1个二重号码和3个单号号码组成一注，所选的单号号码与开奖号码相同，且所选二重号码在开奖号码中出现了2次，即为中奖。',
    example: '投注方案：二重号：8；单号：016',
    result: '开奖号码：01688（顺序不限），即可中五星组选60。',
    award: '',
    isPK: true
  },
  // 五星组选120
  17069: {
    tip: '从0-9中选择5个号码组成一注',
    help: '从0-9中任意选择5个号码组成一注，所选号码与开奖号码的万位、千位、百位、十位、个位相同，顺序不限，即为中奖。',
    example: '投注方案：10568',
    result: '开奖号码：10568（顺序不限），即可中五星组选120。',
    award: '',
    isPK: true
  },
  // 一帆风顺
  17070: {
    tip: '从0-9中任意选择1个以上号码',
    help: '从0-9中任意选择1个号码组成一注，只要开奖号码的万位、千位、百位、十位、个位中包含所选号码，即为中奖。',
    example: '投注方案：8',
    result: '开奖号码：至少出现1个8，即中一帆风顺。',
    award: ''
  },
  // 好事成双
  17071: {
    tip: '从0-9中任意选择1个以上的二重号码',
    help: '从0-9中任意选择1个号码组成一注，只要所选号码在开奖号码的万位、千位、百位、十位、个位中出现2次，即为中奖。',
    example: '投注方案：8',
    result: '开奖号码：至少出现2个8，即中好事成双。',
    award: ''
  },
  // 三星报喜
  17072: {
    tip: '从0-9中任意选择1个以上的三重号码',
    help: '从0-9中任意选择1个号码组成一注，只要所选号码在开奖号码的万位、千位、百位、十位、个位中出现3次，即为中奖。',
    example: '投注方案：8',
    result: '开奖号码：至少出现3个8，即中三星报喜。',
    award: '',
    isPK: true
  },
  // 四季发财
  17073: {
    tip: '从0-9中任意选择1个以上的四重号码',
    help: '从0-9中任意选择1个号码组成一注，只要所选号码在开奖号码的万位、千位、百位、十位、个位中出现4次，即为中奖。',
    example: '投注方案：8',
    result: '开奖号码：至少出现4个8，即中四季发财。',
    award: '',
    isPK: true
  },
  // 前二直选单式
  17074: {
    tip: '手动输入号码，至少输入1个二位数号码组成一注',
    help: '手动输入号码，至少输入1个2位数号码组成一注，万位、千位与开奖号码全部相同，且顺序一致，即为中奖。',
    example: '投注方案：58',
    result: '开奖号码：前二位58，即中前二星直选。',
    award: '',
    isPK: true
  },
  // 后二直选单式
  17075: {
    tip: '手动输入号码，至少输入1个二位数号码组成一注',
    help: '手动输入号码，至少输入1个2位数号码组成一注，十位、个位与开奖号码全部相同，且顺序一致，即为中奖。',
    example: '投注方案：58',
    result: '开奖号码：后二位58，即中后二直选。',
    award: '',
    isPK: true
  },
  // 前二组选单式
  17076: {
    tip: '手动输入号码，至少输入1个二位数号码组成一注',
    help: '手动输入一个2位数号码组成一注，输入号码万位、千位与开奖号码相同（不含对子号），顺序不限，即中奖。',
    example: '投注方案：58',
    result: '开奖号码：前二位58或是85（顺序不限，不含对子）即中前二组选。',
    award: '',
    isPK: true
  },
  // 后二组选单式
  17077: {
    tip: '手动输入号码，至少输入1个二位数号码组成一注',
    help: '手动输入一个2位数号码组成一注，输入号码十位、个位与开奖号码相同（不含对子号），顺序不限，即中奖。',
    example: '投注方案：58',
    result: '开奖号码：后二位58或是85（顺序不限，不含对子）即中后二组选。',
    award: '',
    isPK: true
  },
  // 前三直选单式
  17078: {
    tip: '手动输入号码，至少输入1个三位数号码组成一注',
    help: '手动输入一个3位数号码组成一注，所选号码与开奖号码万位、千位、百位相同，且顺序一致，即为中奖。',
    example: '投注方案:345',
    result: '开奖号码:前三位 345，即中前三直选。',
    award: '',
    isPK: true
  },
  // 中三直选单式
  17079: {
    tip: '手动输入号码，至少输入1个三位数号码组成一注',
    help: '手动输入一个3位数号码组成一注，所选号码与开奖号码千位、百位、十位相同，且顺序一致，即为中奖。',
    example: '投注方案:345',
    result: '开奖号码:中三位 345，即中中三直选。',
    award: '',
    isPK: true
  },
  // 后三直选单式
  17080: {
    tip: '手动输入号码，至少输入1个三位数号码组成一注',
    help: '手动输入一个3位数号码组成一注，所选号码与开奖号码百位、十位、个位相同，且顺序一致，即为中奖。',
    example: '投注方案:345',
    result: '开奖号码:后三位 345，即中后三直选。',
    award: '',
    isPK: true
  },
  // 后四直选单式
  17081: {
    tip: '手动输入号码，至少输入1个四位数号码组成一注',
    help: '从千位、百位、十位、个位选择一个4位数号码组成一注，所选号码与开奖号码全部相同，且顺序一致，即为中奖。',
    example: '投注方案:3456',
    result: '开奖号码:3456，即中四星直选。',
    award: '',
    isPK: true
  },
  // 五星直选单式
  17082: {
    tip: '手动输入号码，至少输入1个五位数号码组成一注',
    help: '从万位、千位、百位、十位、个位选择一个5位数号码组成一注，所选号码与开奖号码全部相同，且顺序一致，即为中奖。',
    example: '投注方桉:13456',
    result: '开奖号码:13456，即中五星直选。',
    award: '',
    isPK: true
  },

  // 任选二
  // 直选复式
  17083: {
    tip: '从万位、千位、百位、十位、个位中至少两位上各选1个号码组成一注',
    help: '从万位、千位、百位、十位、个位中至少两位上各选1个号码组成一注，所有号码与开奖号码的指定位置上的号码相同，且顺序一致，即为中奖。',
    example: '投注方案：万位5、百位8',
    result: '开奖号码：51812，即中任选二直选。',
    award: '',
    isPK: true
  },
  // 直选单式
  17084: {
    tip: '从万位、千位、百位、十位、个位中至少选择两个位置，至少手动输入一个两位数的号码构成一注',
    help: ' 从万位、千位、百位、十位、个位中至少选择两个位置,至少手动输入一个两位数的号码构成一注，所选号码与开奖号码的指定位置上的号码相同，且顺序一致，即为中奖。',
    example: '投注方案：位置选择万位、百位，输入号码58',
    result: '开奖号码：51812，即中任二直选单式',
    award: '',
    isPK: true
  },
  // 直选和值
  17085: {
    tip: '从万位、千位、百位、十位、个位中至少选择两个位置，至少选择一个和值号码构成一注',
    help: '从万位、千位、百位、十位、个位中至少选择两个位置，至少选择一个和值号码构成一注，所选号码与开奖号码的和值相同，即为中奖。',
    example: '投注方案：位置选择万位、百位，选择和值号码13',
    result: '开奖号码：51812，即中任二直选和值',
    award: '',
    isPK: true
  },
  // 组选复式
  17086: {
    tip: '从万位、千位、百位、十位、个位中至少选择两个位置，号码区至少选择两个号码构成一注（不含对子）',
    help: '从万位、千位、百位、十位、个位中至少选择两个位置,号码区至少选择两个号码构成一注（不含对子），所选号码与开奖号码的指定位置上的号码相同，且顺序不限，即为中奖。',
    example: '投注方案：位置选择万位、百位，选择号码85',
    result: '开奖号码：51812或81512，即中任二组选',
    award: '',
    isPK: true
  },
  // 组选单式
  17087: {
    tip: '从万位、千位、百位、十位、个位中至少选择两个位置，至少手动输入一个两位数的号码构成一注（不含对子）',
    help: '从万位、千位、百位、十位、个位中至少选择两个位置,至少手动输入一个两位数的号码构成一注（不含对子），所选号码与开奖号码的指定位置上的号码相同，且顺序不限，即为中奖。',
    example: '投注方案：位置选择万位、百位，输入入号码85',
    result: '开奖号码：51812或81512，即中任二组选(单式)',
    award: '',
    isPK: true
  },
  // 组选和值
  17088: {
    tip: '从万位、千位、百位、十位、个位中至少选择两个位置，至少选择一个和值号码构成一注（不含对子）',
    help: '从万位、千位、百位、十位、个位中至少选择两个位置，至少选择一个和值号码构成一注（不含对子），所选号码与开奖号码的和值相同，即为中奖。',
    example: '位置选择万位、百位，选择和值号码13',
    result: '开奖号码：51812，即中任二组选和值',
    award: '',
    isPK: true
  },

  // 任选三
  // 直选复式
  17089: {
    tip: '从万位、千位、百位、十位、个位中至少三位上各选1个号码组成一注',
    help: '从万位、千位、百位、十位、个位中至少三位上各选1个号码组成一注，所选号码与开奖号码的指定位置上的号码相同，且顺序一致，即为中奖。',
    example: '投注方案：万位5、百位8、个位2',
    result: '开奖号码：51812，即为中任三直选',
    award: '',
    isPK: true
  },
  // 直选单式
  17090: {
    tip: '从万位、千位、百位、十位、个位中至少选择三个位置,至少手动输入一个三位数的号码构成一注',
    help: '从万位、千位、百位、十位、个位中至少选择三个位置,至少手动输入一个三位数的号码构成一注，所选号码与开奖号码的指定位置上的号码相同，且顺序一致，即为中奖。',
    example: '投注方案：位置选择万位、百位、个位，输入号码582',
    result: '开奖号码：51812，即中任三直选（单式）',
    award: '',
    isPK: true
  },
  // 直选和值
  17091: {
    tip: '从万位、千位、百位、十位、个位中至少选择三个位置,至少选择一个和值号码构成一注',
    help: '从万位、千位、百位、十位、个位中至少选择三个位置,至少选择一个和值号码构成一注，所选号码与开奖号码的和值相同，即为中奖。',
    example: '投注号码：位置选择万位、百位、个位，选择和值号码15',
    result: '开奖号码：51812，即中任选三直选和值',
    award: '',
    isPK: true
  },
  // 组三复式
  17092: {
    tip: '从万位、千位、百位、十位、个位中至少选择三个位置,号码区至少选择两个号码构成一注',
    help: '从万位、千位、百位、十位、个位中至少选择三个位置,号码区至少选择两个号码构成一注，所选号码与开奖号码的指定位置上的号码相同，且顺序不限，即为中奖。',
    example: '投注方案：选择位置万位、十位、个位，选择号码12',
    result: '开奖号码：11812，即中任三组三',
    award: '',
    isPK: true
  },
  // 组三单式
  17093: {
    tip: '从万位、千位、百位、十位、个位中至少选择三个位置,手动至少输入三个号码构成一注',
    help: '从万位、千位、百位、十位、个位中至少选择三个位置,手动至少输入三个号码构成一注，所选号码与开奖号码的指定位置上的号码相同，且顺序不限，即为中奖。',
    example: '投注方案：选择位置万位、十位、个位，输入号码122',
    result: '开奖号码：11122，即中任三组三（单式）',
    award: '',
    isPK: true
  },
  // 组六复式
  17094: {
    tip: '从万位、千位、百位、十位、个位中至少选择三个位置,号码区至少选择三个号码构成一注',
    help: '从万位、千位、百位、十位、个位中至少选择三个位置,号码区至少选择三个号码构成一注，所选号码与开奖号码的指定位置上的号码相同，且顺序不限，即为中奖。',
    example: '投注方案：选择位置万位、十位、个位，选择号码512',
    result: '开奖号码：51812，即中任三组六',
    award: '',
    isPK: true
  },
  // 组六单式
  17095: {
    tip: '从万位、千位、百位、十位、个位中至少选择三个位置,手动至少输入三个号码构成一注',
    help: '从万位、千位、百位、十位、个位中至少选择三个位置,手动至少输入三个号码构成一注，所选号码与开奖号码的指定位置上的号码相同，且顺序不限，即为中奖。',
    example: '投注方案：选择位置万位、十位、个位，输入号码512',
    result: '开奖号码：51812，即中任三组六',
    award: '',
    isPK: true
  },
  // 组选混合
  17096: {
    tip: '从万位、千位、百位、十位、个位中至少选择三个位置,手动至少输入三个号码构成一注（不包含豹子号）',
    help: '从万位、千位、百位、十位、个位中至少选择三个位置,手动至少输入三个号码构成一注（不包含豹子号），所选号码与开奖号码的指定位置上的号码相同，且顺序不限，即为中奖。',
    example: '投注方案：选择位置万位、十位、个位，输入号码512',
    result: '开奖号码：51812，即中任三混合组选',
    award: '',
    isPK: true
  },

  // 任选四
  // 直选复式
  17097: {
    tip: '从万位、千位、百位、十位、个位中至少四位上各选1个号码组成一注',
    help: '从万位、千位、百位、十位、个位中至少四位上各选1个号码组成一注，所选号码与开奖号码的指定位置上的号码相同，且顺序一致，即为中奖',
    example: '投注方案：万位5、千位1、百位8、十位1',
    result: '开奖号码：51812，即中任四直选',
    award: '',
    isPK: true
  },
  // 直选单式
  17098: {
    tip: '从万位、千位、百位、十位、个位中至少选择四个位置,至少手动输入一个四位数的号码构成一注',
    help: '从万位、千位、百位、十位、个位中至少选择四个位置,至少手动输入一个四位数的号码构成一注，所选号码与开奖号码的指定位置上的号码相同，且顺序致，即为中奖。',
    example: '投注方案：选择万位、千位、百位、十位，输入号码5181',
    result: '开奖号码：51812，即中任四直选（单式）',
    award: '',
    isPK: true
  },
  // 组选24
  17099: {
    tip: '从万位、千位、百位、十位、个位中至少选择四个位置,号码区至少选择四个号码构成一注',
    help: '从万位、千位、百位、十位、个位中至少选择四个位置,号码区至少选择四个号码构成一注，所选号码与开奖号码的指定位置上的号码相同，且顺序不限，即为中奖。',
    example: '投注方案：位置选择千位、百位、十位、个位，号码选择0568',
    result: '开奖号码：10568（指定位置号码顺序不限），即中任四组选24',
    award: '',
    isPK: true
  },
  // 组选12
  17100: {
    tip: '从万、千、百、十、个位中至少选择四个位置,从“二重号”选择一个号码，“单号”中选择两个号码组成一注',
    help: '从万、千、百、十、个位中至少选择四个位置,从“二重号”选择一个号码，“单号”中选择两个号码组成一注，所选号码与开奖号码的指定位置上的号码相同，且顺序不限，即为中奖。',
    example: '投注方案：位置选择千位、百位、十位、个位，二重号：8；单号06',
    result: '开奖号码：10688（指定位置号码顺序不限），即中任四组选12',
    award: '',
    isPK: true
  },
  // 组选6
  17101: {
    tip: '从万、千、百、十、个位中至少选择四个位置,从“二重号”中选择两个号码组成一注',
    help: '从万、千、百、十、个位中至少选择四个位置,从“二重号”中选择两个号码组成一注，所选号码与开奖号码的指定位置上的号码相同，且顺序不限，即为中奖。',
    example: '投注方案：位置选择千位、百位、十位、个位，二重号：28',
    result: '开奖号码：12288（指定位置号码顺序不限）即中任四组选6',
    award: '',
    isPK: true
  },
  // 组选4
  17102: {
    tip: '从万、千、百、十、个位中至少选择四个位置,从“三重号”中选择一个号码，“单号”中选择一个号码组成一注',
    help: '从万、千、百、十、个位中至少选择四个位置,从“三重号”中选择一个号码，“单号”中选择一个号码组成一注，所选号码与开奖号码的指定位置上的号码相同，且顺序不限，即为中奖。',
    example: '投注方案：位置选择千位、百位、十位、个位，三重号：8；单号：2',
    result: '开奖号码：18828（指定位置号码顺序不限），即中任四组选4',
    award: '',
    isPK: true
  },

  // 广东11选5(11选5)
  22000: {
    tip: '从万、千、百位中至少各选择一个号码组成一注',
    help: '从万位、千位、百位至少选择1个号码组成一注，所选号码与相同位置上的开奖号码一致，即为中奖',
    example: '投注方案：03，04，05',
    result: '开奖号码：前三位03，04，05，即中前三直选',
    award: '',
    isPK: true
  },
  22001: {
    tip: '从01-11中任意选择3个或3个以上号码组成一注',
    help: '从01-11中任意选择3个或3个以上号码组成一注，所选号码与开奖号码的万位、千位、百位相同，顺序不限，即为中奖。',
    example: '投注方案:02 05 08',
    result: '开奖号码:前三位08 05 02（顺序不限），即中前三组选。',
    award: '',
    isPK: true
  },
  22002: {
    tip: '从01-11中，选取3个及以上的号码进行投注，每注需至少包括1个胆码及2个拖码',
    help: '从01-11中，选取3个及以上的号码进行投注，每注需至少包括1个胆码及2个拖码。所选单注号码与开奖号码的万位、千位、百位相同，顺序不限，即为中奖。',
    example: '投注方案:胆码02，拖码01 06',
    result: '开奖号码:前三位02 01 06（顺序不限）即中前三组选胆拖。',
    award: '',
    isPK: true
  },
  22003: {
    tip: '从千位、百位、十位中至少各选择1个号码组成一注',
    help: '从千位、百位、十位至少个选1个号码组成一注，所选号码与开奖号码中3位相同，且顺序一致，即为中奖',
    example: '投注方案:03 04 05',
    result: '开奖号码:中三位03 04 05，即中中三直选。',
    award: '',
    isPK: true
  },
  22004: {
    tip: '从01-11中任意选择3个或3个以上号码组成一注',
    help: '从01-11中任意选择3个或3个以上号码组成一注，所选号码与开奖号码的千位、百位、十位相同，顺序不限，即为中奖',
    example: '投注方案:02 05 08',
    result: '开奖号码:中三位08 05 02（顺序不限），即中中三组选',
    award: '',
    isPK: true
  },
  22005: {
    tip: '从01-11中，选取3个及以上的号码进行投注，每注需至少包括1个胆码及2个拖码',
    help: '从01-11中，选取3个及以上的号码进行投注，每注需至少包括1个胆码及2个拖码。所选单注号码与开奖号码的千位、百位、十位相同，顺序不限，即为中奖。',
    example: '投注方案:胆码02，拖码01 06',
    result: '开奖号码:中三位02 01 06（顺序不限）即中中三组选胆拖',
    award: '',
    isPK: true
  },
  22006: {
    tip: '从百位、十位、个位中至少各选择1个号码组成一注',
    help: '从百位、十位、个位至少个选1个号码组成一注，所选号码与开奖号码后3位相同，且顺序一致，即为中奖。',
    example: '投注方案:03 04 05',
    result: '开奖号码:后三位03 04 05，即中后三直选',
    award: '',
    isPK: true
  },
  22007: {
    tip: '从01-11中任意选择3个或3个以上号码组成一注',
    help: '从01-11中任意选择3个或3个以上号码组成一注，所选号码与开奖号码的百位、十位、个位相同，顺序不限，即为中奖。',
    example: '投注方案:02 05 08',
    result: '开奖号码:后三位08 05 02（顺序不限），即中后三组选',
    award: '',
    isPK: true
  },
  22008: {
    tip: '从01-11中，选取3个及以上的号码进行投注，每注需至少包括1个胆码及2个拖码',
    help: '从01-11中，选取3个及以上的号码进行投注，每注需至少包括1个胆码及2个拖码。所选单注号码与开奖号码的百位、十位、个位相同，顺序不限，即为中奖。',
    example: '投注方案:胆码02，拖码01 06',
    result: '开奖号码:后三位02 01 06（顺序不限）即中后三组选胆拖。',
    award: '',
    isPK: true
  },
  22009: {
    tip: '从万位、千位中至少各选择1个号码组成一注',
    help: '从万位、千位至少个选1个号码组成一注，所选号码与开奖号码前2位相同，且顺序一致，即为中奖。',
    example: '投注方案:05 08',
    result: '开奖号码:前二位05 08，即中前二直选',
    award: '',
    isPK: true
  },
  22010: {
    tip: '从01-11中任意选择2个或2个以上号码组成一注',
    help: '从01-11中任意选择2个或2个以上号码组成一注，所选号码与开奖号码的万位、千位相同，顺序不限，即为中奖。',
    example: '投注方案:05 08',
    result: '开奖号码:前二位08 05 （顺序不限），即中前二组选',
    award: '',
    isPK: true
  },
  22011: {
    tip: '从01-11中，选取2个及以上的号码进行投注，每注需至少包括1个胆码及1个拖码',
    help: '从01-11中，选取2个及以上的号码进行投注，每注需至少包括1个胆码及1个拖码。所选单注号码与开奖号码的万位、千位相同，顺序不限，即为中奖。',
    example: '投注方案:胆码02，拖码06',
    result: '开奖号码:前二位02 06（顺序不限）即中前二组选胆拖',
    award: '',
    isPK: true
  },
  22012: {
    tip: '从十位、个位中至少各选择1个号码组成一注',
    help: '从十位、个位至少个选1个号码组成一注，所选号码与开奖号码前2位相同，且顺序一致，即为中奖',
    example: '投注方案:05 08',
    result: '开奖号码:后二位05 08，即中后二直选',
    award: '',
    isPK: true
  },
  22013: {
    tip: '从01-11中任意选择2个或2个以上号码组成一注',
    help: '从01-11中任意选择2个或2个以上号码组成一注，所选号码与开奖号码的十位、个位相同，顺序不限，即为中奖。',
    example: '投注方案:05 08',
    result: '开奖号码:后二位08 05 （顺序不限），即中后二组选',
    award: '',
    isPK: true
  },
  22014: {
    tip: '从01-11中，选取2个及以上的号码进行投注，每注需至少包括1个胆码及1个拖码',
    help: '从01-11中，选取2个及以上的号码进行投注，每注需至少包括1个胆码及1个拖码。所选单注号码与开奖号码的十位、个位相同，顺序不限，即为中奖。',
    example: '投注方案:胆码02，拖码06',
    result: '开奖号码:后二位02 06（顺序不限）即中后二组选胆拖',
    award: '',
    isPK: true
  },
  22015: {
    tip: '从01-11中任意选择1个以上号码',
    help: '从01-11中选择1个号码，每注由1个号码组成，只要开奖号码的万位、千位、百位中包含所选号码，即为中奖。',
    example: '投注方案:1',
    result: '开奖号码:前三位至少出现1个1，即中前三一码不定位',
    award: ''
  },
  22016: {
    tip: '从01-11中任意选择1个以上号码',
    help: '从01-11中选择1个号码，每注由1个号码组成，只要开奖号码的千位、百位、十位中包含所选号码，即为中奖。',
    example: '投注方案:1',
    result: '开奖号码:中三位至少出现1个1，即中中三一码不定位',
    award: ''
  },
  22017: {
    tip: '从01-11中任意选择1个以上号码',
    help: '从01-11中选择1个号码，每注由1个号码组成，只要开奖号码的百位、十位、个位中包含所选号码，即为中奖。',
    example: '投注方案:1',
    result: '开奖号码:后三位至少出现1个1，即中后三一码不定位',
    award: ''
  },
  22018: {
    tip: '从万位、千位、百位、十位、个任意位置上任意选择1个或1个以上号码',
    help: '从万位、千位、百位、十位、个位任意位置上至少选择1个以上号码，所选号码与相同位置上的开奖号码一致，即为中奖',
    example: '投注方案:万位1',
    result: '开奖号码:万位1，即中定位胆万位。',
    award: ''
  },
  22019: {
    tip: '从01-11中任意选择1个或1个以上号码',
    help: '从01-11共11号码中选择1个号码进行购买，只要当期顺序摇出的5个开奖号码中包含所选号码，即为中奖。',
    example: '投注方案：05',
    result: '开奖号码:08 04 11 05 03，即中一中一。',
    award: ''
  },
  22020: {
    tip: '从01-11中任意选择2个或2个以上号码',
    help: '从01-11共11号码中选择2个号码进行购买，只要当期顺序摇出的5个开奖号码中包含所选号码，即为中奖。',
    example: '投注方案:05 04',
    result: '开奖号码:08 04 11 05 03，即中二中二',
    award: ''
  },
  22021: {
    tip: '从01-11中任意选择3个或3个以上号码',
    help: '从01-11共11号码中选择3个号码进行购买，只要当期顺序摇出的5个开奖号码中包含所选号码，即为中奖。',
    example: '投注方案:05 04 11',
    result: '开奖号码:08 04 11 05 03，即中三中三',
    award: ''
  },
  22022: {
    tip: '从01-11中任意选择4个或4个以上号码',
    help: '从01-11共11号码中选择4个号码进行购买，只要当期顺序摇出的5个开奖号码中包含所选号码，即为中奖。',
    example: '投注方案:05 04 08 03',
    result: '开奖号码:08 04 11 05 03，即中四中四',
    award: '',
    isPK: true
  },
  22023: {
    tip: '从01-11中任意选择5个或5个以上号码',
    help: '从01-11共11号码中选择5个号码进行购买，只要当期顺序摇出的5个开奖号码中包含所选号码，即为中奖。',
    example: '投注方案:05 04 11 03 08',
    result: '开奖号码:08 04 11 05 03，即中五中五',
    award: '',
    isPK: true
  },
  22024: {
    tip: '从01-11中任意选择6个或6个以上号码',
    help: '从01-11共11号码中选择6个号码进行购买，只要当期顺序摇出的5个开奖号码中包含所选号码，即为中奖。',
    example: '投注方案:05 10 04 11 03 08',
    result: '开奖号码:08 04 11 05 03，即中六中五',
    award: '',
    isPK: true
  },
  22025: {
    tip: '从01-11中任意选择7个或7个以上号码',
    help: '从01-11共11号码中选择7个号码进行购买，只要当期顺序摇出的5个开奖号码中包含所选号码，即为中奖。',
    example: '投注方案:05 10 04 11 03 08 09',
    result: '开奖号码:08 04 11 05 03，即中七中五',
    award: ''
  },
  22026: {
    tip: '从01-11中任意选择8个或8个以上号码',
    help: '从01-11共11号码中选择8个号码进行购买，只要当期顺序摇出的5个开奖号码中包含所选号码，即为中奖。',
    example: '投注方案:05 10 04 11 03 08 09 01',
    result: '开奖号码:08 04 11 05 03，即中八中五',
    award: ''
  },
  22027: {
    tip: '从01-11中，选取2个及以上的号码进行投注，每注需至少包括1个胆码及1个拖码',
    help: '分别从胆码和拖码的01-11中，至少选择1个胆码和1个拖码组成一注，只要当期顺序摇出的5个开奖号码中同时包含所选的1个胆码和拖码，所选胆码必须全中，即为中奖。',
    example: '投注方案:05 04',
    result: '开奖号码:08 04 11 05 03，即任选中二中二',
    award: ''
  },
  22028: {
    tip: '从01-11中，选取3个及以上的号码进行投注，每注需至少包括1个胆码及2个拖码',
    help: '分别从胆码和拖码的01-11中，至少选择1个胆码和2个拖码组成一注，只要当期顺序摇出的5个开奖号码中同时包含所选的1个胆码和2个拖码，所选胆码必须全中，即为中奖。',
    example: '投注方案:胆码08，拖码06 11',
    result: '开奖号码:06 08 11 09 02，即任选中三中三',
    award: ''
  },
  22029: {
    tip: '从01-11中，选取4个及以上的号码进行投注，每注需至少包括1个胆码及3个拖码',
    help: '分别从胆码和拖码的01-11中，至少选择1个胆码和3个拖码组成一注，只要当期顺序摇出的5个开奖号码中同时包含所选的1个胆码和3个拖码，所选胆码必须全中，即为中奖。',
    example: '投注方案:胆码08，拖码06 09 11',
    result: '开奖号码:06 08 11 09 02，即任选中四中四',
    award: '',
    isPK: true
  },
  22030: {
    tip: '从01-11中，选取5个及以上的号码进行投注，每注需至少包括1个胆码及4个拖码',
    help: '分别从胆码和拖码的01-11中，至少选择1个胆码和4个拖码组成一注，只要当期顺序摇出的5个开奖号码中同时包含所选的1个胆码和4个拖码，所选胆码必须全中，即为中奖。',
    example: '投注方案:胆码08，拖码02 06 09 11',
    result: '开奖号码:06 08 11 09 02，即任选中五中五',
    award: '',
    isPK: true
  },
  22031: {
    tip: '从01-11中，选取6个及以上的号码进行投注，每注需至少包括1个胆码及5个拖码',
    help: '分别从胆码和拖码的01-11中，至少选择1个胆码和5个拖码组成一注，只要当期顺序摇出的5个开奖号码同时存在于胆码和拖码的任意组合中，即为中奖。',
    example: '投注方案:胆码08，拖码02 05 06 09 11',
    result: '开奖号码:06 08 11 09 02，即任选中六中五',
    award: '',
    isPK: true
  },
  22032: {
    tip: '从01-11中，选取7个及以上的号码进行投注，每注需至少包括1个胆码及6个拖码',
    help: '分别从胆码和拖码的01-11中，至少选择1个胆码和6个拖码组成一注，只要当期顺序摇出的5个开奖号码同时存在于胆码和拖码的任意组合中，即为中奖。',
    example: '投注方案:胆码08，拖码01 02 05 06 09 11',
    result: '开奖号码:06 08 11 09 02，即任选中七中五',
    award: ''
  },
  22033: {
    tip: '从01-11中，选取8个及以上的号码进行投注，每注需至少包括1个胆码及7个拖码',
    help: '分别从胆码和拖码的01-11中，至少选择1个胆码和7个拖码组成一注，只要当期顺序摇出的5个开奖号码同时存在于胆码和拖码的任意组合中，即为中奖。',
    example: '投注方案:胆码08，拖码01 02 03 05 06 09 11',
    result: '开奖号码:06 08 11 09 02，即任选中八中五。',
    award: ''
  },

  /* 江苏快3 */
  37000: {
    tip: '猜3个开奖号相加的和，4-10为小，11-17为大，5 、7 、9、11、13、15、17为单，4，6，8，10，12，14，16为双。若开出为豹子111和666，通杀大，小，单，双，对子，总和',
    help: '至少选择1个和值（3个号码之和）进行投注，所选和值与开奖的3个号码的和值相同，即为中奖。',
    example: '投注方案：8',
    result: '开奖号码:1 3 4，即中和值。',
    award: '',
    isPK: true
  },
  37100: {
    tip: '选择1对相同号码和1个不同号码投注，选号与奖号相同，即为中奖',
    help: '选择1对相同号码和1个不同号码投注，选号与奖号相同（顺序不限），即为中奖。',
    example: '投注方案：选号：同号 1 1 不同号 3',
    result: '开奖号码:1 1 3，即中二同号单选。',
    award: '',
    isPK: true
  },
  37200: {
    tip: '从11-66中任选1个或多个号码，选号与奖号(包含11-66，不限顺序)相同，即为中奖（不含豹子）',
    help: '从11～66中任选1个或多个号码，选号与奖号（包含11～66，不限顺序）相同，即为中奖。',
    example: '投注方案：选号：1 1',
    result: '开奖号码:1 1 2，即中二同号复选。',
    award: ''
  },
  37300: {
    tip: '从1-6中任选2个或多个号码，所选号码与开奖号码任意2个号码相同，即为中奖',
    help: '从1～6中任选2个或多个号码，所选号码与开奖号码任意2个号码相同，即为中奖。',
    example: '投注方案：选号：1 2',
    result: '开奖号码:1 2 3，即中二不同号。',
    award: ''
  },
  37400: {
    tip: '从1-6中任选3个或多个号码,所选号码与开奖号码的3个号码相同,即为中奖',
    help: '从1～6中任选3个或多个号码，所选号码与开奖号码的3个号码相同，即为中奖。',
    example: '投注方案：选号：2 4 6',
    result: '开奖号码:2 4 6，即中三不同号。',
    award: ''
  },
  37500: {
    tip: '对相同的三个号码(111、222、333、444、555、666)中的任意一个或多个进行投注，所选号码开出，即为中奖',
    help: '对相同的三个号码（111、222、333、444、555、666）中的任意一个进行投注，所选号码开出，即为中奖。',
    example: '投注方案：选号：1 1 1',
    result: '开奖号码:1 1 1，即中三同号单选。',
    award: '',
    isPK: true
  },
  37600: {
    tip: '对所有相同的三个号码(111、222、333、444、555、666)进行投注，任意号码开出，即为中奖',
    help: '对所有相同的三个号码（111、222、333、444、555、666）进行投注，任意号码开出，即为中奖。',
    example: '投注方案：选号：三同号通选',
    result: '开奖号码:1 1 1，即中三同号通选。',
    award: ''
  },
  37700: {
    tip: '对所有的3个相连号码(123、234、345、456)进行投注，任意号码开出，即为中奖',
    help: '对所有3个相连的号码（123、234、345、456）进行投注，任意号码开出，即为中奖。',
    example: '投注方案：选号：三连号通选',
    result: '开奖号码:1 2 3，即中三连号通选。',
    award: ''
  },

  // 北京赛车
  58000: {
    tip: '从第一名中至少选择1个号码组成一注',
    help: '选号与开奖号码按位猜对1位即中奖',
    example: '投注方案：01',
    result: '开奖号码：01 02 03 04 05 06 07 08 09 10 即中前一直选。',
    award: ''
  },
  58100: {
    tip: '从第一名、第二名中至少各选1个号码组成一注',
    help: '选号与开奖号码按位猜对2位即中奖',
    example: '投注方案：01 02',
    result: '开奖号码：01 02 03 04 05 06 07 08 09 10 即中前二直选。',
    award: '',
    isPK: true
  },
  58200: {
    tip: '从第一名、第二名、第三名中至少各选1个号码组成一注',
    help: '选号与开奖号码按位猜对3位即中奖',
    example: '投注方案：01 02 03',
    result: '开奖号码：01 02 03 04 05 06 07 08 09 10 即中前三直选。',
    award: '',
    isPK: true
  },
  58400: {
    tip: '在龙、虎任意选择1个或1个以上玩法',
    help: '开奖结果中第一位车号对比第十位。大即是龙中奖，小的话是虎中奖。',
    example: '投注方案：龙',
    result: '开奖号码：8********1，即中猜龙虎斗',
    award: ''
  },
  58500: {
    tip: '从第一名、第二名、第三名、第四名中至少各选1个号码组成一注',
    help: '选号与开奖号码按位猜对4位即中奖',
    example: '投注方案：01 02 03 04',
    result: '开奖号码：01 02 03 04 05 06 07 08 09 10 即中前四直选。',
    award: '',
    isPK: true
  },
  58600: {
    tip: '从第一名、第二名、第三名、第四名、第五名中至少各选1个号码组成一注',
    help: '选号与开奖号码按位猜对5位即中奖',
    example: '投注方案：01 02 03 04 05',
    result: '开奖号码：01 02 03 04 05 06 07 08 09 10 即中前五直选。',
    award: '',
    isPK: true
  },
  753000: {
    tip: '0~9任选1号进行投注',
    help: '当开奖结果佰拾个任一数与所选的号码相同时，即为中奖；若开奖结果出现重覆数字时，视为中奖一次。',
    example: '投注方案：01 02 03',
    result: '佰/拾/个 大小：0~4为小，5~9为大。佰/拾/个 单双：1、3、5、7、9为单，0、2、4、6、8为双。佰/拾/个 质合：1、2、3、5、7为质数，0、4、6、8、9为合数。',
    award: ''
  },
  753001: {
    tip: '0~9任选1号进行投注',
    help: '当开奖结果佰拾个任一数与所选的号码相同时，即为中奖；若开奖结果出现重覆数字时，视为中奖一次。',
    example: '投注方案：01 02 03',
    result: '佰/拾/个 大小：0~4为小，5~9为大。佰/拾/个 单双：1、3、5、7、9为单，0、2、4、6、8为双。佰/拾/个 质合：1、2、3、5、7为质数，0、4、6、8、9为合数。',
    award: ''
  },
  753002: {
    tip: '0~9任选2号进行投注',
    help: '当开奖结果佰拾个任二数与所选的号码相同时，即为中奖。',
    example: '投注方案：01 02',
    result: `佰拾和数：开奖结果佰拾二位数的总和值若与投注的佰拾数字的总和值相同时，即为中奖。佰个和数：开奖结果佰个二位数的总和值若与投注的佰个数字的总和值相同时，即为中奖。拾个和数：开奖结果拾个二位数的总和值若与投注的拾个数字的总和值相同时，即为中奖。佰拾/佰个/拾个 和数尾数：以开奖号码的总和尾数，作为中奖依据。会员可以选择0~9的任何一个号码。如：开奖结果为：3、4、5。佰拾和数尾数：佰拾总和为7，尾数为7。佰个和数尾数：佰个总和为8，尾数为8。拾个和数尾数：拾个总和为9，尾数为9。佰拾/佰个/拾个 和数单双：1、3、5、7、9、11、13、15、17为单，0、2、4、6、8、10、12、14、16、18为双。佰拾/佰个/拾个 和数尾数大小：0~4为小，5~9为大。佰拾/佰个/拾个 和数尾数质合：1、2、3、5、7为质数，0、4、6、8、9为合数。`,
    award: ''
  },
  753003: {
    tip: '0~9任选3号进行投注',
    help: '当开奖结果佰拾个任三数与所选的号码相同时，即为中奖。',
    example: '投注方案：01 02 03',
    result: `佰拾个和数：开奖结果佰拾个三位数的总和值若与投注的佰拾个数字的总和值相同时，即为中奖。佰拾个 和数尾数：以开奖号码的总和尾数，作为中奖依据。会员可以选择0~9的任何一个号码。如：开奖结果为：3、4、5。佰拾个的总和为12，尾数为2。佰拾个 和数大小：0~13为小，14~27为大。佰拾个 和数单双：1、3、5、7、9、11、13、15、17、19、21、23、25、27为单，0、2、4、6、8、10、12、14、16、18、20、22、24、26、28为双。佰拾个 和数尾数大小：0~4为小，5~9为大。佰拾个 和数尾数质合：1、2、3、5、7为质数，0、4、6、8、9为合数。`,
    award: ''
  },
  753004: {
    tip: '于佰拾个任选一位，自0~9任选1号进行投注',
    help: '当开奖结果与所选的定位与号码相同时，即为中奖。',
    example: '投注方案：01 02 03',
    result: '开奖号码：01 02 03 即中一字定位。',
    award: ''
  },
  753005: {
    tip: '于佰拾个任选二位，自0~9任选2号进行投注',
    help: '当开奖结果与所选号码相同且顺序一致时，即为中奖。',
    example: '投注方案：01 02',
    result: '开奖号码：01 02 即中二字定位。',
    award: ''
  },
  753006: {
    tip: '于佰拾个位自0~9任选3号进行投注',
    help: '当开奖结果与所选号码相同且顺序一致时，即为中奖。',
    example: '投注方案：01 02 03',
    result: '开奖号码：01 02 03 即中三字定位。',
    award: ''
  },
  753007: {
    tip: '0~9任选2号进行投注',
    help: '当开奖结果佰拾个任二数与所选的号码相同时，即为中奖。',
    example: '投注方案：01 02',
    result: `佰拾和数：开奖结果佰拾二位数的总和值若与投注的佰拾数字的总和值相同时，即为中奖。佰个和数：开奖结果佰个二位数的总和值若与投注的佰个数字的总和值相同时，即为中奖。拾个和数：开奖结果拾个二位数的总和值若与投注的拾个数字的总和值相同时，即为中奖。佰拾/佰个/拾个 和数尾数：以开奖号码的总和尾数，作为中奖依据。会员可以选择0~9的任何一个号码。如：开奖结果为：3、4、5。佰拾和数尾数：佰拾总和为7，尾数为7。佰个和数尾数：佰个总和为8，尾数为8。拾个和数尾数：拾个总和为9，尾数为9。佰拾/佰个/拾个 和数单双：1、3、5、7、9、11、13、15、17为单，0、2、4、6、8、10、12、14、16、18为双。佰拾/佰个/拾个 和数尾数大小：0~4为小，5~9为大。佰拾/佰个/拾个 和数尾数质合：1、2、3、5、7为质数，0、4、6、8、9为合数。`,
    award: ''
  },
  753008: {
    tip: '0~9任选3号进行投注',
    help: '当开奖结果佰拾个任三数与所选的号码相同时，即为中奖。',
    example: '投注方案：01 02 03',
    result: `佰拾个和数：开奖结果佰拾个三位数的总和值若与投注的佰拾个数字的总和值相同时，即为中奖。佰拾个 和数尾数：以开奖号码的总和尾数，作为中奖依据。会员可以选择0~9的任何一个号码。如：开奖结果为：3、4、5。佰拾个的总和为12，尾数为2。佰拾个 和数大小：0~13为小，14~27为大。佰拾个 和数单双：1、3、5、7、9、11、13、15、17、19、21、23、25、27为单，0、2、4、6、8、10、12、14、16、18、20、22、24、26、28为双。佰拾个 和数尾数大小：0~4为小，5~9为大。佰拾个 和数尾数质合：1、2、3、5、7为质数，0、4、6、8、9为合数。`,
    award: ''
  },
  753009: {
    tip: '会员可以挑选5~10个号码',
    help: '当开奖结果中有且只有两个号码重复，则视为中奖。挑选不同个数号码有其相对应的赔率。如果是选择 1、2、3、4、5中的任何两个号码，且其中有一个号码重复则中奖。',
    example: '投注方案：112、344',
    result: '开奖号码：112、344 即中奖。若是开出豹子则不算中奖。【附注】："豹子"为三字同号，例如：111、222。',
    award: ''
  },
  753010: {
    tip: '会员可以挑选择4~8个号码',
    help: '当开奖结果都出现在所下注的号码中且没有任何号码重复，则视为中奖。挑选不同的号码有其相对应的赔率，中奖赔率以所选号码中的最小赔率计算彩派。',
    example: '投注方案：1、2、3、4',
    result: '开奖结果为123、124、134、234都中奖，其他都是不中奖。例如：112、133、145、444等都是不中奖。',
    award: ''
  },
  753011: {
    tip: '以开奖三个号码的最大差距(跨度)，作为中奖的依据。会员可以选择0~9的任一跨度。',
    help: '',
    example: '',
    result: '开奖结果为3，4，8。中奖的跨度为5。(最大号码 8减最小号码 3 = 5)。若开奖结果三号码都相同，称作豹子，投注注豹子者中奖，其馀为不中奖。',
    award: ''
  }
}
