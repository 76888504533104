import qs from 'qs';
import storage, {getJSON} from '/@/kernel-mn/utils/storage';
import {isApp,isIOS,isAndroid} from '/@/kernel-mn/utils';
import RNEvent from '/@/kernel-mn/utils/RNEvent';
import {useUserStore} from '/@/kernel-mn/stores/user';
import * as Info from '/@/kernel-mn/config/PlayInfo';
import PlayInfoStrategy from './PlayInfoStrategy';
import PlayInfoCommon from './PlayInfoCommon';
import * as BallPan from './BallPan';
import * as BallArea from './BallArea';
import * as SubPlay from './SubPlay';
import * as Rule from './PlayRuleInfo';
import * as PlayExt from './PlayExt';
import {chartTabs, chartConfigs, tableTitles} from './ChartInfo';

export default class config {
  static appVersion = -1.0;
  static appVersion1 = -1.0;
  static chanCode = 'MWEB';
  static aseqId = '7';
  static vk = 'fT6phq0wkOPRlAoyToidAnkogUV7ttGo';
  static nc = 1;
  static versionCode = ''; // IOS/Android的版本号
  static versionName = ''; // IOS/Android的版本名称
  static appWKwebview = 0;
  static routeidx = -1; // 原生线路序号
  static promote = ''; // 推广链接
  static gameOpenIn = false; // 第三方游戏是否内置打开
  static h5DelayKV = null; // 用于h5Delay延迟打开的数据存储
  static isWD = 0; // h5是否在微端中,默认不在
  static tid = 0; // RN中url中带有 tid 的
  static clusters_id = 0; // RN中url中带有 clusters_id 的
  // h5的url中带有推荐信息的
  static code = ''; // 推荐码
  static channelCode = ''; // 推广渠道
  static appid = ''; // 推广的包名
  static gameConfig = Object.assign({}, Info.PlayInfo); // 游戏配置数据
  static dynamicPlayInfo = {};
  static ballcmdinfos = {};
  static dynamicSubPlay = {};
  static ballinfos = {};

  /**
   * 初始化
   * 1、处理了 url 上的各种参数
   * 2、初始化用户的数据
   * 3、注意先后顺序，比如 isApp 中的 appVersion 参数是在url参数初始化过后执行
   */
  static init() {
    const RNEventInstance = RNEvent.getInstance();
    /**
     * 获取设备的相关信息
     */
    RNEventInstance.sendNativeMsg('getDeviceUDID');
    RNEventInstance.addObserver('deviceUDIDKey', 'deviceUDID', deviceUDID => {
      const userStore = useUserStore();
      userStore.setDeviceUDID(deviceUDID);
      // @ts-ignore
      this.rnBridger.removeObserver('deviceUDIDKey', 'deviceUDID');
    });

    // 处理 URL 上的参数
    let urlStr = location.href.split('?')[1];
    if (urlStr) {
      urlStr = urlStr.split('#/')[0];
    }
    // URL 上没有参数
    if (!urlStr) {
      const urlParamsStr = storage.getSession('urlParams');
      if (urlParamsStr) {
        // // 用一次就行了，用于赋值到 config的各个静态变量上
        // storage.removeSession('urlParams')
        const tmpObj = JSON.parse(urlParamsStr);
        // 赋值给 config 的静态变量
        Object.keys(tmpObj).forEach(key => {
          this[key] = tmpObj[key];
        });
      }
      // 初始化用户信息
      this.initUserinfo();
      return;
    }
    const urlObj = qs.parse(urlStr);
    // url上面的参数
    const urlParams: any = {};
    if (urlObj.appVersion) {
      sessionStorage.setItem('_appVersion', urlObj.appVersion);
      // @ts-ignore
      urlParams.appVersion = urlObj.appVersion;
    }
    urlParams.appVersion = urlParams.appVersion || Number(sessionStorage.getItem('_appVersion')) || -1.0;
    if (urlObj.v) {
      // @ts-ignore
      urlParams.appVersion1 = urlObj.v;
    }
    if (urlObj.vc) {
      // @ts-ignore
      urlParams.versionCode = urlObj.vc;
    }
    if (urlObj.vn) {
      // @ts-ignore
      urlParams.versionName = urlObj.vn;
    }
    if (urlObj.appWKwebview) {
      // @ts-ignore
      urlParams.appWKwebview = urlObj.appWKwebview;
    }
    if (urlObj.vk) {
      // @ts-ignore
      urlParams.vk = urlObj.vk;
    }
    if (urlObj.aseq_id) {
      // @ts-ignore
      urlParams.aseqId = urlObj.aseq_id;
    }
    // 原生现在传值过来的是chan_code
    if (urlObj.chan_code) {
      // @ts-ignore
      urlParams.chanCode = urlObj.chan_code;
    }
    if (urlObj.route_idx) {
      // @ts-ignore
      urlParams.routeidx = Number(urlObj.route_idx);
    }
    if (urlObj.p) {
      // @ts-ignore
      urlParams.promote = urlObj.p;
    }
    if (urlObj.gameOpenIn) {
      // @ts-ignore
      urlParams.gameOpenIn = urlObj.gameOpenIn;
    }
    if (urlObj.isWD) {
      // @ts-ignore
      urlParams.isWD = urlObj.isWD;
    }
    if (urlObj.h5timeIng || urlObj.h5time) {
      // @ts-ignore
      urlParams.h5DelayKV = {timeIng: urlObj.h5timeIng, time: parseInt(urlObj.h5time)};
    }
    if (urlObj.tid) {
      // @ts-ignore
      urlParams.tid = Number(urlObj.tid);
    }
    if (urlObj.clusters_id) {
      // @ts-ignore
      urlParams.clusters_id = Number(urlObj.clusters_id);
    }
    if (urlObj.code) {
      // @ts-ignore
      urlParams.code = urlObj.code;
    }
    if (urlObj.channelCode) {
      // @ts-ignore
      urlParams.channelCode = urlObj.channelCode;
    }
    if (urlObj.appid) {
      // @ts-ignore
      urlParams.appid = urlObj.appid;
    }
    // 初始化用户信息
    this.initUserinfo();
    // 处理有参数的情况
    if (Object.keys(urlParams).length > 0) {
      // 赋值给 config 的静态变量
      Object.keys(urlParams).forEach(key => {
        this[key] = urlParams[key];
      });
      storage.setSession('urlParams', JSON.stringify(urlParams));
      // 处理完 URL 上的参数过后重置URL
      window.location.href = location.origin + location.hash;
    }
  }

  static getChanCode = () => {
    let chan_con = '';
    if (isApp()) {
      if (isIOS()) {
        chan_con = 'IOS';
      } else if (isAndroid()) {
        chan_con = 'ANDROID';
      }
    } else {
      chan_con = 'MWEB';
    }
    return chan_con;
  };

  static initUserinfo() {
    const RNEventInstance = RNEvent.getInstance();
    const userStore = useUserStore();
    if (isApp()) {
      RNEventInstance.readRecord('userInfo', result => {
        const tmpStr = result.result;
        // 初始化用户数据
        if (tmpStr) {
          const tmpObj = JSON.parse(tmpStr);
          if (tmpObj.cust_id) {
            userStore.userInfo = tmpObj;
          }
        }
      });
    } else {
      let userInfo = getJSON('userInfo');
      // 初始化用户数据
      if (userInfo && userInfo.cust_id) {
        userStore.userInfo = userInfo;
      }
    }
  }

  static addYLCconfig() {
    let obj = {
      468: {play_name: 'TCCP', play_cname: '天成彩票', cat: 'CP', time: null, tip: '天成彩票'},
      504: {play_name: 'GPICP', play_cname: 'GPI彩票', cat: 'CP', time: null, tip: 'GPI彩票'},
      1326: {play_name: 'SBCP', play_cname: '沙巴彩票', cat: 'CP', time: null, tip: '沙巴彩票'},
      1385: { play_name: 'DBCP', play_cname: 'DB彩票', cat: 'CP', time: null, tip: 'DB彩票' }
    };
    this.gameConfig = {...this.gameConfig, ...obj};
  }

  static getBigClass(cat) {
    let arr = [];
    // 娱乐城是 addYLCconfig 执行在app.js
    if (!this.gameConfig[468]) {
      this.gameConfig[468] = {play_name: 'TCCP', play_cname: '天成彩票', cat: 'CP', time: null, tip: '天成彩票'};
    }
    if (!this.gameConfig[504]) {
      this.gameConfig[504] = {play_name: 'GPICP', play_cname: 'GPI彩票', cat: 'CP', time: null, tip: 'GPI彩票'};
    }
    if (!this.gameConfig[1326]) {
      this.gameConfig[1326] = {play_name: 'SBCP', play_cname: '沙巴彩票', cat: 'CP', time: null, tip: '沙巴彩票'};
    }
    if (!this.gameConfig[1385]) {
      this.gameConfig[1385] = { play_name: 'DBCP', play_cname: 'DB彩票', cat: 'CP', time: null, tip: 'DB彩票' };
    }

    for (let i in this.gameConfig) {
      if (this.gameConfig[i].cat && this.gameConfig[i].cat === cat) {
        arr.push(Number(i));
      }
    }
    return arr;
  }

  // 游戏分类
  static getGameCategory(payId) {
    let fishingList = [...this.getBigClass('BY'), 749, 1032];
    let chessList = [...this.getBigClass('QP'), 744, 1034];
    let electronicList = [...this.getBigClass('DZ'), 748,1380];
    let physicalEducation = this.getBigClass('TY');
    let videoList = [...this.getBigClass('AG'), 1033];
    let CredEnvelope = this.getBigClass('HB');
    let caipiao = this.getBigClass('CP');
    if (fishingList.some(id => id === payId)) {
      return 3;
    } else if (chessList.some(id => id === payId)) {
      return 1;
    } else if (electronicList.some(id => id === payId)) {
      return 2;
    } else if (physicalEducation.some(id => id === payId)) {
      return 5;
    } else if (videoList.some(id => id === payId)) {
      return 6;
    } else if (CredEnvelope.some(id => id === payId)) {
      return 7;
    } else if (caipiao.some(id => id === payId)) {
      return 8;
    } else {
      return 4;
    }
  }

  static deepCopy(obj) {
    if (obj === null) return null;
    if (obj instanceof RegExp) return new RegExp(obj);
    if (obj instanceof Date) return new Date(obj);
    if (typeof obj !== 'object') {
      return obj;
    }
    let t = new obj.constructor();
    for (let key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        t[key] = this.deepCopy(obj[key]);
      }
    }
    return t;
  }

  // 获得玩法信息
  static getPlayInfo(playId) {
    if (!Number(playId)) {
      return null;
    }
    this.addYLCconfig && this.addYLCconfig()
    let play = this.gameConfig[playId];
    if (play == null) {
      play = this.dynamicPlayInfo[playId];
    } else {
      if (play.state == null) {
        play.state = {};
      }
      play.id = Number(playId); // 主玩法
      if (play.base && !play.baseinit) {
        let base = PlayInfoCommon[play.base];
        if (base) {
          base = this.deepCopy(base);
          play = Object.assign(base, play);
        }
        if (play.icon == null) {
          play.icon = play.base;
        }
        play.baseinit = true;
        this.gameConfig[playId] = play;
      }
      if (play.icon == null) {
        play.icon = play.id;
      }
      
      if (play.default == null) {
        if (play.cat === 'PK10' || play.cat === 'XYFT' || play.cat === 'FC3D') {
          play.default = Number(play.id + '700');
        } else if (play.time === null) {
          play.default = Number(play.id);
          play.enable = true;
        } else {
          play.default = Number(play.id + '000');
        }
        if (play._play_cname) {
          if (play.cat === 'SSC') {
            play._default = Number(play.id + '103');
          } else if (play.cat === 'XYFT') {
            play._default = Number(play.id + '803');
          } else if (play.cat === 'K3') {
            play._default = Number(play.id + '701');
          }
        }
      }
      if (play.chartid == null) {
        play.chartid = play.id;
      }
    }
    // 动态生成玩法信息
    if (play == null && playId > 1000) {
      let strPlayId = playId + '';
      let mainPlayId = Number(strPlayId.substring(0, strPlayId.length - 3));
      let mainPlay = this.gameConfig[mainPlayId];
      let playBase = mainPlayId * 1000;
      if (mainPlay && mainPlay.strategy) {
        let strategy = PlayInfoStrategy[mainPlay.strategy];
        for (let id in strategy.plays) {
          let subPlayId = playBase + Number(id);
          let playInfo: any = this.deepCopy(strategy.plays[Number(id)]) || {};
          playInfo.id = subPlayId;
          this.dynamicPlayInfo[subPlayId] = playInfo;
        }
        play = this.dynamicPlayInfo[playId];
      }
    }
    if (play == null || Number(play.id) !== Number(playId)) {
      if (Number(playId) !== -1) {
        // console.error('没有找到相应玩法:' + playId)
      }
    }
    return play;
  }

  // 获得玩法的选号球信息
  static getBallCmdInfo(playId) {
    let play = this.getPlayInfo(playId);
    let ballinfo = this.ballcmdinfos[playId];
    if (ballinfo == null) {
      ballinfo = {areas: []};
      let ballArea = null;
      if (play && play.area_id) {
        ballArea = BallArea.BallArea[play.area_id];
      }
      if (ballArea) {
        for (let i in ballArea.areas) {
          let area = ballArea.areas[i];
          let balls = {};
          balls['show'] = area.show;
          let ballplan = BallPan.BallPan[area.ballpanid];
          if (ballplan) {
            for (let j in ballplan.balls) {
              let ball = Object.assign({}, ballplan.balls[j]);
              ball.id += area.baseballid;
              if (area.basecmd) {
                ball.cmd = Number(ball.cmd) + area.basecmd + '';
              }
              balls[ball.cmd] = ball;
              if (Number(j) === 0) {
                balls['digit'] = ball.cmd.length;
              }
            }
          }
          ballinfo.areas.push(balls);
        }
      }
      this.ballcmdinfos[playId] = ballinfo;
    }
    return ballinfo;
  }

  static marginTenantPlayInfo(config) {
    if (config) {
      this.gameConfig = Object.assign(this.gameConfig, config);
    }
  }

  static updateMainPlayStates(states) {
    for (let k in this.gameConfig) {
      let play = this.getPlayInfo(k);
      if (states) {
        let gameState = states[play.id];
        if (gameState && gameState.play) {
          play.state = gameState.play.play_state;
          if (Number(gameState.play.play_state) !== 3) {
            play.enable = true;
          }
          play.strState = '';
          switch (Number(gameState.play.play_state)) {
            case 2:
              play.strState = '暂停销售';
              break;
            case 3:
              play.strState = '停止销售';
              break;
          }
        }
      }
    }
  }

  static getPlayConfig() {
    let dict = {};
    for (let k in this.gameConfig) {
      let play = this.getPlayInfo(k);
      if (play && play.enable) {
        dict[play.id] = play;
      }
    }
    return dict;
  }

  // 获得子玩法信息
  static getSubPlayInfo(playId, isXY = false) {
    let subInfo = this.dynamicSubPlay[playId];
    if (playId < 3000) {
      let play = this.getPlayInfo(playId);
      if (play && play.strategy) {
        let comSubInfo = SubPlay.SubPlay[play.strategy + (isXY ? 'XY' : '')];
        if (comSubInfo) {
          subInfo = this.deepCopy(comSubInfo);
          subInfo.subs = [];
          this.dynamicSubPlay[playId] = subInfo;
          let playIdBase = Number(playId + '000');
          for (let i in comSubInfo.subs) {
            let group = comSubInfo.subs[i];
            let groupNew = this.deepCopy(group) as any;
            groupNew.playids = [];
            for (let j in group.playids) {
              groupNew.playids.push(playIdBase + Number(group.playids[j]));
            }
            subInfo.subs.push(groupNew);
          }
        }
      }
    }
    return subInfo;
  }

  // 获得玩法的选号球信息
  static getBallInfo(playId) {
    let play = this.getPlayInfo(playId);
    let ballinfo = this.ballinfos[playId];
    if (ballinfo == null || (ballinfo.areas && !ballinfo.areas.length)) {
      ballinfo = {balls: {}, areas: [], playId: playId};
      let ballArea = null;
      if (play && play.area_id) {
        ballArea = BallArea.BallArea[play.area_id];
        ballinfo.area_id = play.area_id;
      }
      if (ballArea) {
        for (let i in ballArea.areas) {
          let area = ballArea.areas[i];
          let ids = [];
          let ballplan = BallPan.BallPan[area.ballpanid];
          if (ballplan) {
            for (let j in ballplan.balls) {
              let ball = Object.assign({}, ballplan.balls[j]);
              // 虚拟币跳过
              if (!play || !play.xnb) {
                ball.id += area.baseballid;
              }
              ball.areaidx = i;
              ball.isoption = area.isoption;
              if (area.basecmd) {
                ball.cmd = Number(ball.cmd) + area.basecmd + '';
              }
              ballinfo.balls[ball.id] = ball;
              ids.push(ball.id);
            }
          }
          let newArea = Object.assign({ids: ids}, area);
          newArea.area_id = play.area_id;
          ballinfo.areas.push(newArea);
        }
      }
      this.ballinfos[playId] = ballinfo;
    }
    return ballinfo;
  }

  // 获得玩法规则信息
  static getPlayRuleInfo(playId) {
    let rule = null;
    let play = this.getPlayInfo(playId);
    if (play) {
      rule = Rule.PlayRuleInfo[play.rule_id];
    }
    return rule;
  }

  static getPlayExtItem(extid) {
    return PlayExt.PlayExt[extid];
  }

  static getChartConfig(cat, isApp = true) {
    let cfg = Object.assign({}, chartConfigs[cat]);
    let trends = cfg.trends;
    cfg.trends = [];
    if (isApp) {
      for (let i in trends) {
        if (!trends[i].app_hide) {
          cfg.trends.push(trends[i]);
        }
      }
    } else {
      for (let i in trends) {
        if (!trends[i].pc_hide) {
          cfg.trends.push(trends[i]);
        }
      }
    }
    return cfg;
  }

  static getChartTrend3(cat, trendIdx, isApp = false) {
    let dict = null;
    let cfg = this.getChartConfig(cat, isApp);
    if (cfg && cfg.trends.length > trendIdx) {
      dict = Object.assign({}, cfg.trends[trendIdx]);
      let chart = cfg.trends[trendIdx];
      dict.winlen = cfg.winlen;
      dict.name = chart.name;
      dict.term_code_len = chart.term_code_len;
      dict.tabs = [];
      dict.tabDict = {};
      dict.cat = cat;
      if (isApp) {
        for (let i = 0; i < chart.tab_keys.length; i++) {
          let tabCfg = chartTabs[chart.tab_keys[i]];
          let tab = Object.assign({key: chart.tab_keys[i]}, tabCfg);
          if (!tab.app_hide) {
            let columns = Object.assign(tableTitles[tabCfg.sub_title]);
            tab.columns = [];
            let idx = 0;
            for (let j = 0; j < columns.length; j++) {
              if (!columns[j].app_hide) {
                columns[j].col_idx = idx;
                tab.columns.push(columns[j]);
                idx++;
              }
            }
            dict.tabs.push(tab);
            dict.tabDict[chart.tab_keys[i]] = tab;
            tab.columns_dict = {};
            for (let j = 0; j < tab.columns.length; j++) {
              tab.columns_dict[tab.columns[j].key] = tab.columns[j];
            }
            delete tab.sub_title;
          }
        }
      } else {
        for (let i = 0; i < chart.tab_keys.length; i++) {
          let tabCfg = chartTabs[chart.tab_keys[i]];
          let tab = Object.assign({key: chart.tab_keys[i]}, tabCfg);
          if (!tab.pc_hide) {
            let columns = Object.assign(tableTitles[tabCfg.sub_title]);
            tab.columns = [];
            let idx = 0;
            for (let j = 0; j < columns.length; j++) {
              if (!columns[j].pc_hide) {
                columns[j].col_idx = idx;
                tab.columns.push(columns[j]);
                idx++;
              }
            }
            dict.tabs.push(tab);
            dict.tabDict[chart.tab_keys[i]] = tab;
            tab.columns_dict = {};
            for (let j = 0; j < tab.columns.length; j++) {
              tab.columns_dict[tab.columns[j].key] = tab.columns[j];
            }
            delete tab.sub_title;
          }
        }
      }
    }
    return dict;
  }
}
