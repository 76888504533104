// 一些前端写死的公共数据
import { accMul, isApp, isIOS } from "/@/kernel-mn/utils";
import router from "/@/router";
import { mb_t } from "/@/plugins/global";

// 密码等级说明文字
export const pwdLevelText = (mode = 1) => {
  return {
    1: '密码规则为6-16位数字',
    2: '密码规则为6-16个字母和数字组成',
    3: '密码规则为6-16位数字和大小写字母组成',
    4: '密码规则为6-16位数字和大小写字母和特殊字符(~＠^#-%/.+:;=*)',
    5: '密码规则为6-16位字符', // 任意输入,不限制
  }[mode + ''];
};
// 列表渲染公共参数
export const ListPublic = () => {
  return {
    count: 0,
    list: [],
    num_per_page: 10, // 每页条数
    page_start: 1, // 页码
    finished: false,
    loading: false,
    loadingTtext: mb_t('加载中') + '...', // 加载提示文字
    error: false, // 错误的时候 | 手动控制加载下一页
    errorText: mb_t('加载下一页'),
    autoLoad: false, // 是否自动加载下一页
    resetlist: (_this) => {
      _this.count = 0;
      _this.list = [];
      _this.page_start = 1;
      _this.finished = false;
      _this.error = false;
    },
  };
};
// 虚拟币地址类型
export const VIRTUAL_TYPE = () => {
  let typeList = ['TRC20', 'ERC20', 'BEP2', 'BEP20(BSC)'];
  return typeList;
};

// 多语言列表
// banner 公告等接口后端需要传对应的语言数字
// 0中文 1英语 2越南语 3泰语 4马来语 5印尼语 6葡萄牙语 7西班牙 9波兰语 10保加利亚语 11土耳其语 12日语 13韩语 14法语 15俄罗斯 16阿拉伯
// 17意大利 18南非祖鲁 19罗马尼亚语 20德语 21匈牙利 22中文繁体 23印地语 24捷克语 25希腊语 26波斯语
export const LAN_TO_NUM = locale => {
  let languageNum = {
    cn: 0, en: 1, vi: 2, th: 3, ms: 4, id: 5, pt: 6, es: 7, pl: 9, bg: 10, tr: 11, ja: 12, ko: 13, fr: 14,
    ru: 15, ar: 16, it: 17, zu: 18, ro: 19, de: 20, hu: 21, zh: 22, hi: 23, cs: 24, el: 25, fa: 26, bn: 28,
  };
  let num = languageNum[locale] !== undefined ? languageNum[locale] : 1;
  window.localStorage.setItem('languageNum', num);
  window.localStorage.setItem('language', locale);
  return num;
};
// 充值getway
export const GETWAY = id => {
  return {
    23: 1, // 网上银行（网关pc)
    24: 1, // 网上银行（网关app)
    28: 1, // 支付宝支付网关
    29: 1, // 微信支付网关
    30: 1, // QQ钱包网关
    34: 1, // 京东钱包
    10: 1,
    11: 1,
    25: 1,
    187: 1, // 虚拟货币
    33: 1,
    190: 1, // zalo pay
    189: 1, // Momo pay
    197: 1, // PAYTM
    195: 1, // UPI
    199: 1, // picpay pay
    203: 1,
    300: 1, // 数字人民币
    302: 1, // 博币
    310: 1, // 电子钱包
  }[id + ''];
};
/**
 * 本项目用到的私有工具函数
 * */
export const G_goLogin = (params = null, isBack = false) => {
  // @ts-ignore
  if (window.location.href.includes('chatroom') && !isBack) {
    return
  }
  router.push(params ? params : '/login');
};

export const G_goRegister = (params = null) => {
  // @ts-ignore
  router.push(params ? params : '/register');
};
export const numberComma = (source: number | string, length = 3): string => {
  let sourceArr: string[] = String(source).split('.');
  sourceArr[0] = sourceArr[0].replace(new RegExp('(\\d)(?=(\\d{' + length + '})+$)', 'ig'), '$1,');
  return sourceArr.join('.');
};
// eslint-disable-next-line no-unused-vars
export const banlance2str = (balance, digital = 2) => {
  if (isNaN(balance)) {
    return '0';
  }
  return (Math.round(+balance || 0) / 1000).toFixed(digital).toString();
};
// 千分位
export const numThirdFormat = str => {
  return str ? (str + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
};
// 独立序列化金币
export const fmtnum2str = (num: number) => numberComma(banlance2str(num));
// 隐藏手机号中间位数
export const displayPhoneNum = (payNo, prenum = 3, nextnum = 4, num = 4) => {
  let str1 = '*';
  payNo = payNo + '';
  const reg = new RegExp('(.{' + prenum + '})(.*)(.{' + nextnum + '})');
  payNo = payNo.replace(reg, str => {
    return str.slice(0, prenum) + str1.repeat(num) + str.slice(str.length - nextnum, str.length);
  });
  return payNo;
};
// 姓名脱敏
export const displayName = name => (name ? `${name.slice(0, 1)}*${name.slice(-1)}` : ``);

// 字符串数字保留2位小数
export const number2float = (numStr, len = 2) => {
  let exp = new RegExp('^\\d+(?:\\.\\d{0,' + len + '})?');
  return String((numStr + '').match(exp) || 0);
};

// 根据时间获取星期几
export const getMyDay = date => {
  let week = '';
  if (date.getDay() === 0) week = '星期日';
  if (date.getDay() === 1) week = '星期一';
  if (date.getDay() === 2) week = '星期二';
  if (date.getDay() === 3) week = '星期三';
  if (date.getDay() === 4) week = '星期四';
  if (date.getDay() === 5) week = '星期五';
  if (date.getDay() === 6) week = '星期六';
  return week;
};
// 获取最近七天的日期
export const getNearly7Day = type => {
  let days = [];
  let date = new Date();
  for (let i = 0; i <= 24 * 6; i += 24) {
    // 今天加上前6天
    let dateItem = new Date(date.getTime() - i * 60 * 60 * 1000); // 使用当天时间戳减去以前的时间毫秒（小时*分*秒*毫秒）
    let y = dateItem.getFullYear(); // 获取年份
    let m = dateItem.getMonth() + 1; // 获取月份js月份从0开始，需要+1
    let d = dateItem.getDate(); // 获取日期
    m = addDate0(m); // 给为单数的月份补零
    d = addDate0(d); // 给为单数的日期补零
    let valueItem = y + '-' + m + '-' + d; // 组合
    if (type) {
      valueItem = m + '月' + d + '日';
    }
    days.push(valueItem); // 添加至数组
  }
  // 给日期加0
  function addDate0(time) {
    if (time.toString().length == 1) {
      time = '0' + time.toString();
    }
    return time;
  }
  return days;
};

/**
 * 默認返回yyyy-MM-dd HH:mm:ss
 * @param timestamp
 * @param type
 */
export const dateFormat = (timestamp, type) => {
  if (!timestamp) {
    return;
  }
  const date = new Date(timestamp);
  const year = date.getFullYear();
  let month: any = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day: any = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  let hour: any = date.getHours();
  if (hour < 10) {
    hour = `0${hour}`;
  }
  let minute: any = date.getMinutes();
  if (minute < 10) {
    minute = `0${minute}`;
  }
  let second: any = date.getSeconds();
  if (second < 10) {
    second = `0${second}`;
  }
  if (type === 'HH:mm') {
    return `${hour}:${minute}`;
  } else if (type === 'HH:mm:ss') {
    return `${hour}:${minute}:${second}`;
  } else if (type === 'MM-dd HH:mm') {
    return `${month}-${day} ${hour}:${minute}`;
  } else {
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }
};

export const transferThirdGameUrl = (url, payload) => {
  const mainDom = document.getElementsByClassName('layout-content');
  let scrollDist = 0;
  if (mainDom.length) {
    const _obj = mainDom[0];
    scrollDist = _obj.scrollTop;
  }
  window.sessionStorage.setItem('PAGE_SCROLL_TOP', scrollDist + '');
  window.sessionStorage.setItem('THIRD_GAME_PLAY_ID', payload.play_id);
  window.sessionStorage.setItem('THIRD_GAME_PAYLOAD', JSON.stringify(payload));
  // https域名下内嵌http打不开,转一下
  const _protocol = document.location.protocol;
  if (_protocol === 'https:' && url.indexOf('http://') === 0) {
    url = url.replace('http://', 'https://');
  }
  if (isApp()) {
    return {
      url,
      target: '_blank'
    };
  } else {
    const live = [];
    const dz = [
      // 571 // pg
    ];
    if (isIOS() && payload.play_id === 415) {
      // ag电子
      dz.push(payload.play_id);
    }
    if ([...live, ...dz].includes(Number(payload.play_id))) {
      return {
        url,
        target: '_blank'
      };
    } else {
      window.sessionStorage.setItem('THIRD_GAME_URL', url);
      window.sessionStorage.setItem('BACK_URL_HASH', window.location.hash);
      return {
        url: `/#/iframe`,
        target: '_self'
      };
    }
  }
};

const currencyMap = {
  // "BRL": "pt-BR",
  // "CNY": "pt-BR",
}

export const currencyMoney = (money, digital = 2) => {
  const num = (+money || 0).toFixed(digital);
  const localArea = localStorage.getItem('PLATFORM_AREA') || "";
  let currency = currencyMap[localArea];
  if (currency) {
    // @ts-ignore
    return num.toLocaleString(currency, { maximumFractionDigits: digital })
  } else {
    return numberComma(num)
  }
}

export const hideStrMiddle = (str) => {
  return str.substring(0, 3) + '***' + str.substr(-3)
}

export const handlePlayBackground = (vol = 1) => {
  if (!Number(window.localStorage.getItem('BACKGROUND_MUSIC'))) {
    return
  }
  let bgMusicAudio = null
  bgMusicAudio = document.getElementById('backgroundMusic');
  if (bgMusicAudio) {
    bgMusicAudio.currentTime = 0;
    bgMusicAudio.volume = vol;
    bgMusicAudio.play();
  }
}

export const handleStopBackground = () => {
  if (Number(window.localStorage.getItem('BACKGROUND_MUSIC'))) {
    return
  }
  let bgMusicAudio = null
  bgMusicAudio = document.getElementById('backgroundMusic');
  if (bgMusicAudio) {
    bgMusicAudio.pause();
  }
}

export const handlePlay = (sound = 1, vol = 1) => {
  if (!Number(window.localStorage.getItem('CLICK_MUSIC'))) {
    return
  }

  let bet_audio = null
  if (sound === 1) {
    //@ts-ignore
    bet_audio = window.bet_audio = window.bet_audio || document.getElementById('bet_audio');
  } else if (sound === 2) {
    //@ts-ignore
    bet_audio = document.getElementById('bet_audio2');
  }

  if (bet_audio) {
    bet_audio.currentTime = 0;
    bet_audio.volume = vol;
    bet_audio.play();
  }
};
// PC蛋蛋的和值波色 return {1: 'red', 2: 'blue', 3: 'green'}
export const PC00_COLOR = num => {
  // {1: 'red', 2: 'blue', 3: 'green'}
  const PC00Color = [
    [3, 6, 9, 12, 13, 15, 18, 21, 24],
    [2, 5, 8, 11, 14, 17, 20, 23, 26],
    [0, 1, 4, 7, 10, 16, 19, 22, 25, 27],
  ]

  for (let i = 0, l = PC00Color.length; i < l; i++) {
    const item = PC00Color[i].find(item => Number(num) === item)
    if (item || item === 0) {
      return i + 1;
    }
  }
};

// 第一球 第二球 第三球 数字颜色 return {1: 'red', 2: 'green', 3: '蓝'}
export const PC00_COLOR2 = (num) => {
  const PC00Color = [
    [0, 3, 6, 9],
    [1, 4, 7],
    [2, 5, 8],
  ]
  for (let i = 0, l = PC00Color.length; i < l; i++) {
    const item = PC00Color[i].find(item => num === `${item}`)
    if (item || item === 0) {
      return i + 1;
    }
  }
}
// PC蛋蛋房间名称
export const PC00_ROOM_NAME = () => {
  return { 1: '普通房', 2: '贵宾房', 3: '高赔率' }
}
// 处理VIP等级返回的数据
export const actionVIPData = (result = []) => {
  let list = [], len = result.length;
  // 处理数据
  for (let i = 0; i < len; i++) {
    let item = result[i]; // 当前
    let next = result[i + 1] || result[len - 1]; // 下一个
    // value_lower:投注 balance_lower:充值 relegation_bet_lower:保级流水
    // 差多少升级 differ_amount:投注 balance_differ_amount:存款
    let { value_lower, balance_lower, relegation_bet_lower, differ_amount, balance_differ_amount, withdraw_upper_limit } = item;
    // 升级需要多少投注 sur_lower = 下一级value_lower - 当前value_lower
    let sur_value_lower = next.value_lower - value_lower;
    // 完成的投注 success_lower = 需要的 - 差值
    let success_value_lower = sur_value_lower - differ_amount;
    // 完成投注的百分比 % 如果需要的等于0或者差值大于需要的则表示100%
    let success_value_lower_pl = sur_value_lower === 0 || differ_amount >= sur_value_lower ? 100 : success_value_lower / sur_value_lower * 100;

    // 升级需要多少充值 sur_balance_lower = 下一级balance_lower - 当前balance_lower
    let sur_balance_lower = next.balance_lower - balance_lower;
    // 完成的投注 success_balance_lower = 需要的 - 差值
    let success_balance_lower = sur_balance_lower - balance_differ_amount;
    // 完成充值的百分比 % 如果需要的等于0或者差值大于需要的则表示100%
    let success_balance_lower_pl = sur_balance_lower === 0 || balance_differ_amount >= sur_balance_lower ? 100 : success_balance_lower / sur_balance_lower * 100;
    // 开始赋值
    list.push({
      channel: item.channel, // 存款加速通道[0:没有 1:有]
      manager: item.manager, // 专属客服经理[0:没有 1:有]
      withdraw_upper_limit: accMul(withdraw_upper_limit, 0.001, 0), // 每日提现金额上限  除1000
      withdraw_free_cnt: item.withdraw_free_cnt, // 每日免手续费次数
      daily_withdrawal_limit: item.daily_withdrawal_limit, // 每日提现次数上限
      level: item.level,
      level_id: item.level_id,
      level_name: item.level_name,
      vip_log_url: item.vip_log_url,
      value_lower: accMul(value_lower, 0.001, 0),
      balance_lower: accMul(balance_lower, 0.001, 0),
      relegation_bet_lower: accMul(relegation_bet_lower, 0.001, 0),
      differ_amount: accMul(differ_amount, 0.001, 0),
      balance_differ_amount: accMul(balance_differ_amount, 0.001, 0),
      sur_value_lower: accMul(sur_value_lower, 0.001, 0),
      success_value_lower: accMul(success_value_lower, 0.001, 0),
      success_value_lower_pl,
      sur_balance_lower: accMul(sur_balance_lower, 0.001, 0),
      success_balance_lower: accMul(success_balance_lower, 0.001, 0),
      success_balance_lower_pl,
      // 下一级的
      next_value_lower: accMul(next.value_lower, 0.001, 0),
      next_balance_lower: accMul(next.balance_lower, 0.001, 0),
      // 晋级奖励
      level_reward: +accMul(item.level_reward || 0, 0.001, 0),
      isLevelReward: item.isLevelReward, // 0不能领取 1领取 2已领取
      // 周俸禄
      isWeekReward: item.isWeekReward, // 0不能领取 1领取 2已领取
      week_reward: +accMul(item.week_reward || 0, 0.001, 0) || 0,
      // 月俸禄
      isMonth_Reward: item.isMonth_Reward, // 0不能领取 1领取 2已领取
      month_reward: +accMul(item.month_reward || 0, 0.001, 0) || 0,
      // 自助返水
      scale: +accMul(item.scale || 0, 0.001, 0) || 0
    });
  };
  return list;
};
// 线下充值id与名字
export const OFFLINE_NAME_BY_ID = (id = 0) => {
  let list = [
    { name: '银行卡充值', en: 'Bank', payid: 9 },
    { name: '支付宝充值', en: 'AliPay', payid: 7 },
    { name: '微信充值', en: 'WeChat', payid: 8 },
    { name: 'QQ充值', en: 'QQ', payid: 183 },
    { name: '云闪付充值', en: 'Cloud QuickPass', payid: 184 },
    { name: '虚拟币充值', en: 'VirtualPay', payid: 188 },
    { name: 'KDPay', payid: 317 },
    { name: 'CBPay', payid: 319 }, // C币支付
  ];
  return list.find((item) => item.payid === id) || null;
};
// 所有的绑卡类型
export const BIND_CARD_TYPES = () => {
  // 银行卡id 支付宝185 虚拟币187 upi195 picpay199 pixpay203 gopay220 goubaopay280 agpay250 Jaazcash260 Easypaisa262 BobiPay302 NumPay300 nopay270 okpay230 topay240 paynow65 wbpay312
  // K豆pay316[线上316 线下317] Cpay318[线上318 线下319] MPay314 988pay[线上320 线下321] Gcash[线上322 线下323] ipay[线上324 线下325] 波币钱包[线上326 线下327]
  // cut: 0银行卡，1支付宝，2虚拟支付, 3UPI, 4PicPay, 5PixPay, 6GoPay, 7OkPay, 8ToPay, 9Jaazcash, 10Easypaisa, 11AgPay, 12博币 13数字人民币, 14NoPay, 15GouBaoPay，16paynow 17wbpay
  // 18Mpay 19K豆pay 20CPay
  // is_bind: 0已绑定，1未绑定，2没有权限绑定
  let list = [
    { id: 0, name: '银行卡', en: 'Bank', payid: 0 },
    { id: 1, name: '支付宝', en: 'AliPay', payid: 185 },
    { id: 2, name: '虚拟币', en: 'VirtualPay', payid: 187 },
    { id: 3, name: 'UPI', payid: 195 },
    { id: 4, name: 'PicPay', payid: 199 },
    { id: 5, name: 'PixPay', payid: 203 },
    { id: 6, name: 'GoPay', payid: 220 },
    { id: 7, name: 'OkPay', payid: 230 },
    { id: 8, name: 'ToPay', payid: 240 },
    { id: 9, name: 'Jaazcash', payid: 260 },
    { id: 10, name: 'Easypaisa', payid: 262 },
    { id: 11, name: 'AgPay', payid: 250 },
    { id: 12, name: '博币', en: 'BoBi', payid: 302 }, // [线上302 线下303]
    { id: 13, name: '数字人民币', en: 'NumPay', payid: 300 },
    { id: 14, name: 'NoPay', payid: 270 },
    { id: 15, name: 'GouBaoPay', payid: 280 },
    { id: 16, name: 'PayNow', payid: 65 },
    { id: 17, name: '万币', en: 'WBPay', payid: 312 },// [线上312 线下313]
    { id: 18, name: 'MPay', payid: 314 }, // [线上314 线下315]
    { id: 19, name: 'KDPay', payid: 316 }, // [线上316 线下317]
    { id: 20, name: 'CBPay', payid: 318 }, // C币支付[线上318 线下319]
    { id: 21, name: '988pay', payid: 320 }, // [线上320 线下321]
    { id: 22, name: 'GCash', payid: 322 }, // [线上322 线下323]
    { id: 23, name: 'IPay', payid: 324 }, // [线上324 线下325]
    { id: 24, name: '波币', en: 'BoCoin', payid: 326 }, // [线上326 线下327]
    { id: 25, name: 'AIPAY', payid: 328 }, // [线上328 线下329]
    { id: 26, name: '808钱包', en: '808Pay', payid: 330 }, // [线上330 线下331]
    { id: 27, name: 'JDPay', payid: 332 }, // [线上332 线下333]
    { id: 28, name: 'FPay', payid: 334 }, // [线上334 线下335]
  ];
  return list;
};
