/* eslint-disable */
export default class helperBBIN {
  static BBINGameNames = {
    '3001': '百家乐',
    '3002': '二八杠',
    '3003': '龙虎斗',
    '3005': '三公',
    '3006': '温州牌九',
    '3007': '轮盘',
    '3008': '骰宝',
    '3010': '德州扑克',
    '3011': '色碟',
    '3012': '牛牛',
    '3014': '无限21点',
    '3015': '番摊',
    '3016': '鱼虾蟹',
    '3017': '保险百家乐',
    '3018': '炸金花',
    '3019': '幸运转轮',
    '3020': '走地百家乐',
    '3021': 'HiLo',
    '3025': '原创百家乐',
    '3026': '原创龙虎斗',
    '3027': '原创保险百家乐',
    '3028': '原创轮盘',
    '3029': '原创炸金花',
    '3030': '原创三公',
    '3031': '原创牛牛',
    '3032': '原创色碟',
    '3033': '原创骰宝',
    '3034': '原创鱼虾蟹',
    '3036': '原创HiLo',
    '3038': '原创温州牌九',
    '3043': '原创21点+',
    '3047': '21点百家乐'
  }
  static BBINGameResult = {
    '3001': {0: '庄', 1: '闲'},
    '3047': {0: '庄', 1: '闲'},
    '3002': {0: '庄门牌', 1: '上门牌', 2: '中门牌', 3: '下门牌'},
    '3003': {0: '龙', 1: '虎'},
    '3005': {0: '庄', 1: '闲一', 2: '闲二', 3: '闲三'},
    '3006': {0: '闲一', 1: '闲二', 2: '闲三'},
    '3007': {0: ''},
    '3008': {0: ''},
    '3010': {0: '庄家牌型', 1: '闲家牌型', 2: '公牌'},
    '3011': {0: ''},
    '3012': {0: '庄', 1: '闲一', 2: '闲二', 3: '闲三'},
    '3014': {0: '庄', 1: '闲'},
    '3015': {0: ''},
    '3016': {0: ''},
    '3017': {0: '庄', 1: '闲'},
    '3018': {0: '龙牌型', 1: '凤牌型'},
    '3019': {0: ''},
    '3020': {0: '庄', 1: '闲'},
    '3021': {0: ''}
  }
  static BBINbetDetails = {
    '3001': {
      1: '庄',
      2: '闲',
      3: '和',
      4: '庄对',
      5: '闲对',
      6: '大',
      7: '小',
      8: '庄单',
      9: '庄双',
      10: '闲单',
      11: '闲双',
      12: '任意对子',
      13: '完美对子',
      14: '庄(免佣)',
      15: '超級六(免佣)'
    },
    '3003': {
      1: '虎',
      2: '龙',
      3: '和',
      4: '虎单',
      5: '虎双',
      6: '龙单',
      7: '龙双',
      8: '虎黑',
      9: '虎红',
      10: '龙黑',
      11: '龙红'
    },
    '3005': {
      1: '闲 1 贏',
      2: '闲 1 輸',
      3: '闲 1 和',
      4: '闲 1 三公',
      5: '闲 1 对牌以上',
      6: '闲 2 贏',
      7: '闲 2 輸',
      8: '闲 2 和',
      9: '闲 2 三公',
      10: '闲 2 对牌以上',
      11: '闲 3 贏',
      12: '闲 3 輸',
      13: '闲 3 和',
      14: '闲 3 三公',
      15: '闲 3 对牌以上',
      16: '庄对牌以上'
    },
    '3006': {
      1: '順门贏',
      2: '闲家一輸',
      3: '出门贏',
      4: '闲家二輸',
      5: '到门贏',
      6: '闲家三輸'
    },
    '3007': {
      0: '直注(0)',
      1: '直注(1)',
      2: '直注(2)',
      3: '直注(3)',
      4: '直注(4)',
      5: '直注(5)',
      6: '直注(6)',
      7: '直注(7)',
      8: '直注(8)',
      9: '直注(9)',
      10: '直注(10)',
      11: '直注(11)',
      12: '直注(12)',
      13: '直注(13)',
      14: '直注(14)',
      15: '直注(15)',
      16: '直注(16)',
      17: '直注(17)',
      18: '直注(18)',
      19: '直注(19)',
      20: '直注(20)',
      21: '直注(21)',
      22: '直注(22)',
      23: '直注(23)',
      24: '直注(24)',
      25: '直注(25)',
      26: '直注(26)',
      27: '直注(27)',
      28: '直注(28)',
      29: '直注(29)',
      30: '直注(30)',
      31: '直注(31)',
      32: '直注(32)',
      33: '直注(33)',
      34: '直注(34)',
      35: '直注(35)',
      36: '直注(36)',
      37: '分注( 0,1 )',
      39: '分注( 0,3 )',
      40: '分注( 1,2 )',
      41: '分注( 1,4 )',
      42: '分注( 2,3 )',
      43: '分注( 2,5 )',
      44: '分注( 3,6 )',
      45: '分注( 4,5 )',
      46: '分注( 4,7 )',
      47: '分注( 5,6 )',
      48: '分注( 5,8 )',
      49: '分注( 6,9 )',
      50: '分注( 7,8 )',
      51: '分注( 7,10 )',
      52: '分注( 8,9 )',
      53: '分注( 8,11 )',
      54: '分注( 9,12 )',
      55: '分注( 10,11 )',
      56: '分注( 10,13 )',
      57: '分注( 11,12 )',
      58: '分注( 11,14 )',
      59: '分注( 12,15 )',
      60: '分注( 13,14 )',
      61: '分注( 13,16 )',
      62: '分注( 14,15 )',
      63: '分注( 14,17 )',
      64: '分注( 15,18 )',
      65: '分注( 16,17 )',
      66: '分注( 16,19 )',
      67: '分注( 17,18 )',
      68: '分注( 17,20 )',
      69: '分注( 18,21 )',
      70: '分注( 19,20 )',
      71: '分注( 19,22 )',
      72: '分注( 20,21 )',
      73: '分注( 20,23 )',
      74: '分注( 21,24 )',
      75: '分注( 22,23 )',
      76: '分注( 22,25 )',
      77: '分注( 23,24 )',
      78: '分注( 23,26 )',
      79: '分注( 24,27 )',
      80: '分注( 25,26 )',
      81: '分注( 25,28 )',
      82: '分注( 26,27 )',
      83: '分注( 26,29 )',
      84: '分注( 27,30 )',
      85: '分注( 28,29 )',
      86: '分注( 28,31 )',
      87: '分注( 29,30 )',
      88: '分注( 29,32 )',
      89: '分注( 30,33 )',
      90: '分注( 31,32 )',
      91: '分注( 31,34 )',
      92: '分注( 32,33 )',
      93: '分注( 32,35 )',
      94: '分注( 33,36 )',
      95: '分注( 34,35 )',
      96: '分注( 35,36 )',
      97: '街注(1,2,3)',
      98: '街注(4,5,6)',
      99: '街注(7,8,9)',
      100: '街注(10,11,12)',
      101: ' 街注(13,14,15)',
      102: '街注(16,17,18)',
      103: '街注(19,20,21)',
      104: '街注(22,23,24)',
      105: '街注(25,26,27)',
      106: '街注(28,29,30)',
      107: '街注(31,32,33)',
      108: '街注(34,35,36)',
      109: '三数(0,1,2)',
      110: '三数(0,2,3 )',
      111: '角注(1,2,4,5)',
      112: '角注(2,3,5,6)',
      113: '角注(4,5,7,8)',
      114: '角注(5,6,8,9)',
      115: '角注(7,8,10,11)',
      116: '角注(8,9,11,12)',
      117: '角注(10,11,13,14)',
      118: '角注(11,12,14,15)',
      119: '角注(13,14,16,17)',
      120: '角注(14,15,17,18)',
      121: '角注(16,17,19,20)',
      122: '角注(17,18,20,21)',
      123: '角注(19,20,22,23)',
      124: '角注(20,21,23,24)',
      125: '角注(22,23,25,26)',
      126: '角注(23,24,26,27)',
      127: '角注(25,26,28,29)',
      128: '角注(26,27,29,30)',
      129: '角注(28,29,31,32)',
      130: '角注(29,30,32,33)',
      131: '角注(31,32,34,35)',
      132: '角注(32,33,35,36)',
      133: '四个号码(0,1,2,3)',
      134: '线注(1,2,3,4,5,6)',
      135: '线注(4,5,6,7,8,9)',
      136: '线注(7,8,9,10,11,12)',
      137: '线注(10,11,12,13,14,15)',
      138: '线注(13,14,15,16,17,18)',
      139: '线注(16,17,18,19,20,21)',
      140: '线注(19,20,21,22,23,24)',
      141: '线注(22,23,24,25,26,27)',
      142: '线注(25,26,27,28,29,30)',
      143: '线注(28,29,30,31,32,33)',
      144: '线注(31,32,33,34,35,36)',
      145: '列注(1st)',
      146: '列注(2nd)',
      147: '列注(3th)',
      148: '打(1st)',
      149: '打(2nd)',
      150: '打(3th)',
      151: '红/黑(红)',
      152: '红/黑(黑)',
      153: '单/双(单)',
      154: '单/双(双)',
      155: '大/小 (1-18)',
      156: '大/小 (19-36)'
    },
    '3008': {
      1: '大/小(小)',
      2: '大/小(大)',
      4: '点数/4 点',
      5: '点数/5 点',
      6: '点数/6 点',
      7: '点数/7 点',
      8: '点数/8 点',
      9: '点数/9 点',
      10: '点数/10 点',
      11: '点数/11 点',
      12: '点数/12 点',
      13: '点数/13 点',
      14: '点数/14 点',
      15: '点数/15 点',
      16: '点数/16 点',
      17: '点数/17 点',
      18: '短牌(1,2)',
      19: '短牌(1,3)',
      20: '短牌(1,4)',
      21: '短牌(1,5)',
      22: '短牌(1,6)',
      23: '短牌(2,3)',
      24: '短牌(2,4)',
      25: '短牌(2,5)',
      26: '短牌(2,6)',
      27: '短牌(3,4)',
      28: '短牌(3,5)',
      29: '短牌(3,6)',
      30: '短牌(4,5)',
      31: '短牌(4,6)',
      32: '短牌(5,6)',
      33: '长牌(1,1)',
      34: '长牌(2,2)',
      35: '长牌(3,3)',
      36: '长牌(4,4)',
      37: '长牌(5,5)',
      38: '长牌(6,6)',
      39: '圆骰(1,1,1)',
      40: '圆骰(2,2,2)',
      41: '圆骰(3,3,3)',
      42: '圆骰(4,4,4)',
      43: '圆骰(5,5,5)',
      44: '圆骰(6,6,6)',
      45: '全圆',
      46: '三军(1)',
      47: '三军(2)',
      48: '三军(3)',
      49: '三军(4)',
      50: '三军(5)',
      51: '三军(6)',
      52: '单双（单）',
      53: '单双（双）'
    },
    '3010': {
      1: '底注',
      2: '翻牌',
      3: '转牌',
      4: '河牌',
      5: 'Bonus'
    },
    '3011': {
      1: '4 白',
      2: '4 红',
      3: '3 白 1 红',
      4: '3 红 1 白',
      5: '单',
      6: '双'
    },
    '3012': {
      1: '闲 1 平倍',
      2: '闲 1 翻倍',
      3: '闲 1 预扣额度',
      4: '闲 2 平倍',
      5: '闲 2 翻倍',
      6: '闲 2 预扣额度',
      7: '闲 3 平倍',
      8: '闲 3 翻倍',
      9: '闲 3 预扣额度'
    },
    '3014': {
      1: '闲',
      2: '分牌',
      3: '保险',
      4: '加倍',
    },
    '3015': {
      1: '1番',
      2: '2番',
      3: '3番',
      4: '4番',
      5: '1念2',
      6: '1念3',
      7: '1念4',
      8: '2念1',
      9: '2念3',
      10: '2念4',
      11: '3念1',
      12: '3念2',
      13: '3念4',
      14: '4念1',
      15: '4念2',
      16: '4念3',
      17: '1，2角',
      18: '2，3角',
      19: '3，4角',
      20: '4，1角',
      21: '2，3一通',
      22: '2，4一通',
      23: '3，4一通',
      24: '1，3二通',
      25: '1，4二通',
      26: '3，4二通',
      27: '1，2三通',
      28: '1，4三通',
      29: '2，4三通',
      30: '1，2四通',
      31: '1，3四通',
      32: '2，3四通',
      33: '三门（4，3，2）',
      34: '三门（1，4，3）',
      35: '三门（2，1，4）',
      36: '三门（3，2，1）',
      37: '单',
      38: '双',
    },
    '3016': {
      1: '大/小(小)',
      2: '大/小(大)',
      4: '点数/4 点',
      5: '点数/5 点',
      6: '点数/6 点',
      7: '点数/7 点',
      8: '点数/8 点',
      9: '点数/9 点',
      10: '点数/10 点',
      11: '点数/11 点',
      12: '点数/12 点',
      13: '点数/13 点',
      14: '点数/14 点',
      15: '点数/15 点',
      16: '点数/16 点',
      17: '点数/17 点',
      18: '指定单色(绿)',
      19: '指定单色(蓝)',
      20: '指定单色(红)',
      21: '指定双色(绿)',
      22: '指定双色(蓝)',
      23: '指定双色(红)',
      24: '指定三色(绿)',
      25: '指定三色(蓝)',
      26: '指定三色(红)',
      27: '任意三色',
      28: '圆骰(1,1,1)',
      29: '圆骰(2,2,2)',
      30: '圆骰(3,3,3)',
      31: '圆骰(4,4,4)',
      32: '圆骰(5,5,5)',
      33: '圆骰(6,6,6)',
      34: '全圆',
      35: '三军(1)',
      36: '三军(2)',
      37: '三军(3)',
      38: '三军(4)',
      39: '三军(5)',
      40: '三军(6)',
      41: '单/双(单)',
      42: '单/双(双)'
    },
    '3017': {
      1: '庄',
      2: '闲',
      3: '和',
      4: '庄对',
      5: '闲对',
      6: '大',
      7: '小',
      16: '庄保险',
      17: '闲保险'
    },
    '3018': {
      1: '龙',
      2: '凤',
      4: '对 9 以上',
      8: '順子',
      16: '同花',
      32: '同花順',
      64: '豹子',
    },
    '3019': {
      1: '1',
      2: '3',
      3: '5',
      4: '16',
      5: '24',
      6: '50 (银)',
      7: '50 (金)',
    },
    '3020': {
      1: '庄',
      2: '闲',
      3: '和',
      4: '庄对',
      5: '闲对',
      6: '大',
      7: '小',
      8: '庄单',
      9: '庄双',
      10: '闲单',
      11: '闲双',
      12: '任意对子',
      13: '完美对子',
      14: '庄同花',
      15: '闲同花',
    },
    '3021': {
      1: '高',
      2: '相同',
      3: '低',
      4: '2/3/4/5',
      5: '6/7/8/9',
      6: 'J/Q/K/A',
      7: '红',
      8: '黑',
      9: '单',
      10: '双',
    },
    '3025': {
      1: '庄',
      2: '闲',
      3: '和',
      4: '庄对',
      5: '闲对',
      6: '大',
      7: '小',
      12: '任意对子',
      13: '完美对子',
      14: '庄(免佣)',
      15: '超级六(免佣)'
    },
    '3026': {
      1: '虎',
      2: '龙',
      3: '和',
      4: '虎单',
      5: '虎双',
      6: '龙单',
      7: '龙双',
      8: '虎黑',
      9: '虎红',
      10: '龙黑',
      11: '龙红'
    },
    '3027': {
      1: '庄',
      2: '闲',
      3: '和',
      4: '庄对',
      5: '闲对',
      6: '大',
      7: '小',
      16: '庄保险',
      17: '闲保险'
    },
    '3028': {
      0: '直注(0)',
      1: '直注(1)',
      2: '直注(2)',
      3: '直注(3)',
      4: '直注(4)',
      5: '直注(5)',
      6: '直注(6)',
      7: '直注(7)',
      8: '直注(8)',
      9: '直注(9)',
      10: '直注(10)',
      11: '直注(11)',
      12: '直注(12)',
      13: '直注(13)',
      14: '直注(14)',
      15: '直注(15)',
      16: '直注(16)',
      17: '直注(17)',
      18: '直注(18)',
      19: '直注(19)',
      20: '直注(20)',
      21: '直注(21)',
      22: '直注(22)',
      23: '直注(23)',
      24: '直注(24)',
      25: '直注(25)',
      26: '直注(26)',
      27: '直注(27)',
      28: '直注(28)',
      29: '直注(29)',
      30: '直注(30)',
      31: '直注(31)',
      32: '直注(32)',
      33: '直注(33)',
      34: '直注(34)',
      35: '直注(35)',
      36: '直注(36)',
      37: '分注(0,1)',
      38: '分注(0,2)',
      39: '分注(0,3)',
      40: '分注(1,2)',
      41: '分注(1,4)',
      42: '分注(2,3)',
      43: '分注(2,5)',
      44: '分注(3,6)',
      45: '分注(4,5)',
      46: '分注(4,7)',
      47: '分注(5,6)',
      48: '分注(5,8)',
      49: '分注(6,9)',
      50: '分注(7,8)',
      51: '分注(7,10)',
      52: '分注(8,9)',
      53: '分注(8,11)',
      54: '分注(9,12)',
      55: '分注(10,11)',
      56: '分注(10,13)',
      57: '分注(11,12)',
      58: '分注(11,14)',
      59: '分注(12,15)',
      60: '分注(13,14)',
      61: '分注(13,16)',
      62: '分注(14,15)',
      63: '分注(14,17)',
      64: '分注(15,18)',
      65: '分注(16,17)',
      66: '分注(16,19)',
      67: '分注(17,18)',
      68: '分注(17,20)',
      69: '分注(18,21)',
      70: '分注(19,20)',
      71: '分注(19,22)',
      72: '分注(20,21)',
      73: '分注(20,23)',
      74: '分注(21,24)',
      75: '分注(22,23)',
      76: '分注(22,25)',
      77: '分注(23,24)',
      78: '分注(23,26)',
      79: '分注(24,27)',
      80: '分注(25,26)',
      81: '分注(25,28)',
      82: '分注(26,27)',
      83: '分注(26,29)',
      84: '分注(27,30)',
      85: '分注(28,29)',
      86: '分注(28,31)',
      87: '分注(29,30)',
      88: '分注(29,32)',
      89: '分注(30,33)',
      90: '分注(31,32)',
      91: '分注(31,34)',
      92: '分注(32,33)',
      93: '分注(32,35)',
      94: '分注(33,36)',
      95: '分注(34,35)',
      96: '分注(35,36)',
      97: '街注(1,2,3)',
      98: '街注(4,5,6)',
      99: '街注(7,8,9)',
      100: '街注(10,11,12)',
      101: '街注(13,14,15)',
      102: '街注(16,17,18)',
      103: '街注(19,20,21)',
      104: '街注(22,23,24)',
      105: '街注(25,26,27)',
      106: '街注(28,29,30)',
      107: '街注(31,32,33)',
      108: '街注(34,35,36)',
      109: '三数(0,1,2)',
      110: '三数(0,2,3)',
      111: '角注(1,2,4,5)',
      112: '角注(2,3,5,6)',
      113: '角注(4,5,7,8)',
      114: '角注(5,6,8,9)',
      115: '角注(7,8,10,11)',
      116: '角注(8,9,11,12)',
      117: '角注(10,11,13,14)',
      118: '角注(11,12,14,15)',
      119: '角注(13,14,16,17)',
      120: '角注(14,15,17,18)',
      121: '角注(16,17,19,20)',
      122: '角注(17,18,20,21)',
      123: '角注(19,20,22,23)',
      124: '角注(20,21,23,24)',
      125: '角注(22,23,25,26)',
      126: '角注(23,24,26,27)',
      127: '角注(25,26,28,29)',
      128: '角注(26,27,29,30)',
      129: '角注(28,29,31,32)',
      130: '角注(29,30,32,33)',
      131: '角注(31,32,34,35)',
      132: '角注(32,33,35,36)',
      133: '四个号码(0,1,2,3)',
      134: '线注(1,2,3,4,5,6)',
      135: '线注(4,5,6,7,8,9)',
      136: '线注(7,8,9,10,11,12)',
      137: '线注(10,11,12,13,14,15)',
      138: '线注(13,14,15,16,17,18)',
      139: '线注(16,17,18,19,20,21)',
      140: '线注(19,20,21,22,23,24)',
      141: '线注(22,23,24,25,26,27)',
      142: '线注(25,26,27,28,29,30)',
      143: '线注(28,29,30,31,32,33)',
      144: '线注(31,32,33,34,35,36)',
      145: '列一',
      146: '列二',
      147: '列三',
      148: '第一打',
      149: '第二打',
      150: '第三打',
      151: '红/黑(红)',
      152: '红/黑(黑)',
      153: '单/双(单)',
      154: '单/双(双)',
      155: '大小(1-18)',
      156: '大小(19-36)'
    },
    '3029': {
      1: '龙',
      2: '凤',
      4: '对9以上',
      8: '顺子',
      16: '同花',
      32: '同花顺',
      64: '豹子'
    },
    '3030': {
      1: '闲1赢',
      2: '闲1输',
      3: '闲1和',
      4: '闲1三公',
      5: '闲1对牌以上',
      6: '闲2赢',
      7: '闲2输',
      8: '闲2和',
      9: '闲2三公',
      10: '闲2对牌以上',
      11: '闲3赢',
      12: '闲3输',
      13: '闲3和',
      14: '闲3三公',
      15: '闲3对牌以上',
      16: '庄对牌以上'
    },
    '3031': {
      1: '闲1平倍',
      2: '闲1翻倍',
      3: '闲1预扣额度',
      4: '闲2平倍',
      5: '闲2翻倍',
      6: '闲2预扣额度',
      7: '闲3平倍',
      8: '闲3翻倍',
      9: '闲3预扣额度'
    },
    '3032': {
      1: '四白',
      2: '四红',
      3: '三白一紅',
      4: '三红一白',
      5: '单',
      6: '双'
    },
    '3033': {
      1: '大/小(小)',
      2: '大/小(大)',
      4: '点数/4点',
      5: '点数/5点',
      6: '点数/6点',
      7: '点数/7点',
      8: '点数/8点',
      9: '点数/9点',
      10: '点数/10点',
      11: '点数/11点',
      12: '点数/12点',
      13: '点数/13点',
      14: '点数/14点',
      15: '点数/15点',
      16: '点数/16点',
      17: '点数/17点',
      18: '短牌(1,2)',
      19: '短牌(1,3)',
      20: '短牌(1,4)',
      21: '短牌(1,5)',
      22: '短牌(1,6)',
      23: '短牌(2,3)',
      24: '短牌(2,4)',
      25: '短牌(2,5)',
      26: '短牌(2,6)',
      27: '短牌(3,4)',
      28: '短牌(3,5)',
      29: '短牌(3,6)',
      30: '短牌(4,5)',
      31: '短牌(4,6)',
      32: '短牌(5,6)',
      33: '长牌(1,1)',
      34: '长牌(2,2)',
      35: '长牌(3,3)',
      36: '长牌(4,4)',
      37: '长牌(5,5)',
      38: '长牌(6,6)',
      39: '围骰(1,1,1)',
      40: '围骰(2,2,2)',
      41: '围骰(3,3,3)',
      42: '围骰(4,4,4)',
      43: '围骰(5,5,5)',
      44: '围骰(6,6,6)',
      45: '全围',
      46: '三军(1)',
      47: '三军(2)',
      48: '三军(3)',
      49: '三军(4)',
      50: '三军(5)',
      51: ' 三军(6)',
      52: '单/双(单)',
      53: '单/双(双)'
    },
    '3034': {
      1: '大/小(小)',
      2: '大/小(大)',
      4: '点数/4点',
      5: '点数/5点',
      6: '点数/6点',
      7: '点数/7点',
      8: '点数/8点',
      9: '点数/9点',
      10: '点数/10点',
      11: '点数/11点',
      12: '点数/12点',
      13: '点数/13点',
      14: '点数/14点',
      15: '点数/15点',
      16: '点数/16点',
      17: '点数/17点',
      18: '指定单色(绿)',
      19: '指定单色(蓝)',
      20: '指定单色(红)',
      21: '指定双色(绿)',
      22: '指定双色(蓝)',
      23: '指定双色(红)',
      24: '指定三色(绿)',
      25: '指定三色(蓝)',
      26: '指定三色(红)',
      27: '任意三色',
      28: '围骰(1,1,1)',
      29: '围骰(2,2,2)',
      30: '围骰(3,3,3)',
      31: '围骰(4,4,4)',
      32: '围骰(5,5,5)',
      33: '围骰(6,6,6)',
      34: '全围',
      35: '三军(1)',
      36: '三军(2)',
      37: '三军(3)',
      38: '三军(4)',
      39: '三军(5)',
      40: '三军(6)',
      41: '单/双(单)',
      42: '单/双(双)'
    },
    '3036': {
      1: '高',
      2: '相同',
      3: '低',
      4: '2/3/4/5',
      5: '6/7/8/9',
      6: 'J/Q/K/A',
      7: '红',
      8: '黑',
      9: '单',
      10: '双'
    },
    '3038': {
      1: '顺门赢',
      2: '顺门输',
      3: '出门赢',
      4: '出门输',
      5: '到门赢',
      6: '到门输'
    },
    '3043': {
      1: '庄',
      2: '闲',
      3: '和',
      4: '庄对',
      5: '闲对',
      6: '庄20',
      7: '閑20',
      8: '庄BJ',
      9: '閑BJ'
    },
    '3047': {
      1: '庄',
      2: '闲',
      3: '和',
      4: '庄对',
      5: '闲对',
      6: '大',
      7: '小',
      8: '庄单',
      9: '庄双',
      10: '闲单',
      11: '闲双',
      12: '任意对子',
      13: '完美对子',
      14: '庄(免佣)',
      15: '超級六(免佣)'
    }
  }

  static getBBINGameName(gameType) {
    let name = ''
    if (this.BBINGameNames[gameType]) {
      name = this.BBINGameNames[gameType]
    }
    return name
  }

  static getBBINGameTypes() {
    return this.BBINGameNames
  }

  static getBBINGameResult(gameType, result, card) {
    if (!gameType || !result || !card) {
    }
    let _this = this
    let game_result = '--'
    if (result) {
      switch (+gameType) {
        case 3007:
        case 3008:
        case 3011:
        case 3015:
        case 3016:
        case 3019:
        case 3021:
          game_result = result
          break
        case 3002:
        case 3005:
          game_result = _this.BBINGameResult[gameType][0] + ':' + result.split(',')[0] + ',' + _this.BBINGameResult[gameType][1] + ':' + result.split(',')[1] + ',' + _this.BBINGameResult[gameType][2] + ':' + result.split(',')[2] + ',' + _this.BBINGameResult[gameType][3] + ':' + result.split(',')[3]
          break
        case 3012:
          let resultArr = []
          for (let i = 0; i < result.split(',').length; i++) {
            const play_result = {
              'No Bull': '无牛',
              'Bull1': '牛一',
              'Bull2': '牛二',
              'Bull3': '牛三',
              'Bull4': '牛四',
              'Bull5': '牛五',
              'Bull6': '牛六',
              'Bull7': '牛七',
              'Bull8': '牛八',
              'Bull9': '牛九',
              'Bull Bull': '牛牛'
            }[result.split(',')[i]]
            resultArr.push(play_result)
          }
          game_result = _this.BBINGameResult[gameType][0] + ':' + resultArr[0] + ',' + _this.BBINGameResult[gameType][1] + ':' + resultArr[1] + ',' + _this.BBINGameResult[gameType][2] + ':' + resultArr[2] + ',' + _this.BBINGameResult[gameType][3] + ':' + resultArr[3]
          break
        case 3001:
        case 3014:
        case 3017:
        case 3020:
        case 3003:
        case 3047:
          game_result = _this.BBINGameResult[gameType][0] + result.split(',')[0] + _this.BBINGameResult[gameType][1] + (result.split(',')[1] || '')
          break
        case 3006:
          let resultList = []
          for (let i = 0; i < result.split(',').length; i++) {
            const item = result.split(',')[i].slice(-1)
            const playResult = {'L': '输', 'W': '赢'}[item]
            resultList.push(playResult)
          }
          game_result = _this.BBINGameResult[gameType][0] + resultList[0] + ',' + _this.BBINGameResult[gameType][1] + resultList[1] + ',' + _this.BBINGameResult[gameType][2] + resultList[2]
          break
        case 3018: // 炸金花
          let List = []
          if (result) {
            for (let i = 0; i < result.split(',').length; i++) {
              const item = result.split(',')[i]
              const item_card_type = item.split('-')[0]
              const item_card = item.split('-')[1]
              const card_result = {
                'HighCard': '散牌',
                'AnyTripleKiller': '豹子杀手',
                'Pair': '对子',
                'Straight': '顺子',
                'Flush': '同花',
                'StraightFlush': '同花順',
                'AnyTriple': '豹子'
              }[item_card_type] + item_card
              List.push(card_result)
            }
            game_result = _this.BBINGameResult[gameType][0] + ':' + List[0] + ',' + _this.BBINGameResult[gameType][1] + ':' + List[1]
          } else {
            game_result = _this.BBINGameResult[gameType][0] + '()' + _this.BBINGameResult[gameType][1] + '()'
          }
          break
        case 3010:
          let card_list = [[], [], []]
          for (let j = 0; j < card.split('*').length; j++) {
            const temp = card.split('*')[j].split(',')
            for (let k = 0; k < temp.length; k++) {
              const index = temp[k].split('.')
              const card_type = {'H': '红桃', 'C': '梅花', 'S': '黑桃', 'D': '方块'}[index[0]]
              const card_item = {
                '1': 'A',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
                '8': '8',
                '9': '9',
                '10': '10',
                '11': 'J',
                '12': 'Q',
                '13': 'K'
              }[index[1]]
              const card_result = card_type + card_item
              card_list[j].push(card_result)
            }
          }
          game_result = _this.BBINGameResult[gameType][0] + ':' + card_list[0].toString() + ';' + _this.BBINGameResult[gameType][1] + ':' + card_list[1].toString() + ';' + _this.BBINGameResult[gameType][2] + ':' + card_list[2].toString()
          break
        case 3043:
          const resultArrData = result.split(',')
          game_result = `庄${resultArrData[0]}, 闲${resultArrData[1]}`
          break
      }
    }
    return game_result
  }

  static getBBINBetDetail(gameType, detail) {
    let _this = this
    if (!gameType || !detail) {
    }
    let betDetails = ''
    if (detail) {
      if (detail.includes('*')) {
        let detailArr = detail.split('*')
        for (let i = 0; i < detailArr.length; i++) {
          let detailItem = detailArr[i].split(',')[0]
          if (_this.BBINbetDetails[gameType] && detailItem) {
            betDetails += (_this.BBINbetDetails[gameType][detailItem] + ',')
          }
        }
        betDetails = betDetails.substr(0, betDetails.length - 1)
      } else {
        let gameIndex = Number(detail.split(',')[0])
        betDetails = _this.BBINbetDetails[gameType] ? _this.BBINbetDetails[gameType][gameIndex] : ''
      }
    }

    return betDetails
  }
}
