/* 北京赛车-经典彩 */
export default {
  plays: {
    0: {
      rule_id: 58000,
      area_id: 57000,
      play_name: 'SZP',
      play_cname: '数字盘',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    1: {
      rule_id: 34300,
      area_id: 57001,
      play_name: 'LMP',
      play_cname: '两面盘',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    2: {
      rule_id: 57002,
      area_id: 57002,
      play_name: 'GYJH',
      play_cname: '冠、亚军和',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    3: {
      rule_id: 57003,
      area_id: 57003,
      play_name: 'YWM',
      play_cname: '1~5名',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    4: {
      rule_id: 57004,
      area_id: 57004,
      play_name: 'LSM',
      play_cname: '6~10名',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    5: {
      rule_id: 34600,
      area_id: 57005,
      play_name: 'GYJZH',
      play_cname: '冠亚军组合',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    }
  }
}