import network from '../../network';
import {useHot} from '/@/kernel-mn/stores/useHot';
import {getJSON} from '/@/kernel-mn/utils/storage';
import {useUserStore} from '/@/kernel-mn/stores/user';

export default {
  // 获取用户自定义数据
  async getUserConfig() {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    const game3LoginInfo = getJSON('game3LoginInfo') || {};
    if (userInfo.value.fict_flag === 1 || !game3LoginInfo.Token) {
      return false;
    }
    // 发起请求
    const postdata = {
      tid: tenantInfo.value.tid,
      Token: game3LoginInfo.Token,
      MemberCode: game3LoginInfo.MemberCode,
      type: 'IM'
    };
    try {
      const res = await network.request({
        cmdId: 3119666,
        data: postdata,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success' && res.data) {
        this.userConfig = res.data;
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 更新用户自定义数据
  async updateUserConfig(payload = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    const game3LoginInfo = getJSON('game3LoginInfo') || {};
    // 发起请求
    const postdata = {
      tid: tenantInfo.value.tid,
      Token: game3LoginInfo.Token,
      MemberCode: game3LoginInfo.MemberCode,
      type: 'IM',
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 3119667,
        data: postdata,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success' && res.data) {
        // payload.callback && payload.callback()
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  //  获取余额日志列表
  async reqBalanceLogs(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    let data: any = {
      tid: tenantInfo.value.tid,
      acct_id: userInfo.value.acct_id,
      ...payload
    };
    if (payload.sd_flag) {
      // 刷单项目的账变记录需要传1 其他项目 0
      data.sd_flag = payload.sd_flag;
    }
    if (payload.trans_type && payload.trans_type !== '0') {
      data.trans_type = [];
      data.trans_type[0] = payload.trans_type;
    } else {
      delete data.trans_type;
    }
    try {
      const res = await network.request({
        cmdId: 340333,
        data: data,
        headerType: 2,
        paramType: 0
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 请求账户余额(汇总)
  async reqBlanceCollect() {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data = [tenantInfo.value.tid, userInfo.value.acct_id];
    try {
      const res: any = await network.request({
        cmdId: 340234,
        data: data,
        headerType: 2,
        paramType: 2
      });
      if (res && res.type === 'success') {
        this.blance = res.data.balance;
        this.freezeBalance = res.data.freezeBalance; // 冻结的钱
        this.marking_quantity_sum = res.data.marking_quantity_sum;
        if (res.data.level) {
          // 刷新余额时对用户 cust_level 等级刷新
          const userStore = useUserStore();
          userStore.saveUser({...userInfo.value, cust_level: res.data.level});
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 转换所有余额
   * @param @requires payload.type 1 只回收上分记录的第三方
   * @param payload.key 本次请求的key，默认为default
   * @param payload.options 更多参数 JSON
   * @param payload.callback 回调 function (res, err) {}
   */
  async reqTransitionAllYe(payload = {}) {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      type: 1,
      ...payload
    };
    try {
      const params = {
        cmdId: 391138,
        data: data,
        headerType: 2,
        paramType: 0
      };
      const res: any = await network.request(params);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 额度转换 所有的账户明细 type:1只回收有的 2全过一篇 data_type:1限制60秒查询一次 2不限制
  async reqBlanceAllYe(payload = {}) {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      type: 2,
      data_type: 2,
      ...payload
    };
    try {
      const params = {
        cmdId: 391137,
        data: data,
        headerType: 2,
        paramType: 0
      };
      const res: any = await network.request(params);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 回收单个第三方余额 type:3 play_id:是三方游戏的ID
  async reqTransitionOne(payload:any = {}) {
    const {hotData: {tenantInfo, userInfo}} = useHot();
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      type: 3,
      // play_id: payload.play_id,
      ...payload
    };
    try {
      const res: any = await network.request({
        cmdId: 391138,
        data: data,
        headerType: 2,
        paramType: 0
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 将钱转入到第三方 play_id
  async reqTransitionOther(payload:any = {}) {
    const { hotData: {tenantInfo, userInfo} } = useHot();
    const data = {
      play_id: payload.play_id,
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
    };
    try {
      const res = await network.request({
        cmdId: 314501,
        data: data,
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
};
