/* 幸运飞艇 */
export default {
  plays: {
    0: {
      rule_id: 58000,
      area_id: 58000,
      play_name: 'Q1ZXFS',
      play_cname: '前一直选复式',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    100: {
      rule_id: 58100,
      area_id: 58100,
      play_name: 'Q2ZXFS',
      play_cname: '前二直选复式',
      special: false,
      unit_price: 1,
      note_tag: 'qianN'
    },
    200: {
      rule_id: 58200,
      area_id: 58200,
      play_name: 'Q3ZXFS',
      play_cname: '前三直选复式',
      special: false,
      unit_price: 1,
      note_tag: 'qianN'
    },
    400: {
      rule_id: 58400,
      area_id: 58400,
      play_name: 'LH',
      play_cname: '龙虎',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    500: {
      rule_id: 58500,
      area_id: 58500,
      play_name: 'Q4ZXFS',
      play_cname: '前四直选复式',
      special: false,
      unit_price: 1,
      note_tag: 'qianN'
    },
    600: {
      rule_id: 58600,
      area_id: 58600,
      play_name: 'Q5ZXFS',
      play_cname: '前五直选复式',
      special: false,
      unit_price: 1,
      note_tag: 'qianN'
    },
    700: {
      rule_id: 34700,
      area_id: 34700,
      play_name: 'DWD',
      play_cname: '定位胆',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    800: {
      rule_id: 34800,
      area_id: 34800,
      play_name: 'GYHZDS',
      play_cname: '冠亚和单双',
      special: false,
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },
    801: {
      rule_id: 34801,
      area_id: 34801,
      play_name: 'GYHZDX',
      play_cname: '冠亚和大小',
      special: false,
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },
    802: {
      rule_id: 34802,
      area_id: 34802,
      play_name: 'GYHZZDZ',
      play_cname: '冠亚和指定',
      special: false,
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },

    803: {
      rule_id: 57000,
      area_id: 57000,
      play_name: 'SZP',
      play_cname: '数字盘',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    804: {
      rule_id: 57001,
      area_id: 57001,
      play_name: 'LMP',
      play_cname: '两面盘',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    805: {
      rule_id: 57002,
      area_id: 57002,
      play_name: 'GYJH',
      play_cname: '冠、亚军和',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    806: {
      rule_id: 57003,
      area_id: 57003,
      play_name: 'YWM',
      play_cname: '1~5名',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    807: {
      rule_id: 57004,
      area_id: 57004,
      play_name: 'LSM',
      play_cname: '6~10名',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    },
    808: {
      rule_id: 57005,
      area_id: 57005,
      play_name: 'GYJZH',
      play_cname: '冠亚军组合',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      isBpan: true
    }
  }
}
