/**
 * 彩票
 */
// import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import gameFetch from './gameFetch';
import { getJSON, removeSession, setJSON } from '/@/kernel-mn/utils/storage';
import { CN_EN_MAP, GAME_MENU } from '/@/kernel-mn/config/sports';
import config from '/@/kernel-mn/config';
import { ballsWithArea, convertSingleTextToSelectIds, singleTextFormat } from '/@/kernel-mn/helpers/helperPlay';
import helperHK from '/@/kernel-mn/helpers/helperHK';
import { createBasket, updateBasket, noteNum, getCurrentPlayRebate } from '/@/kernel-mn/helpers/helperPlay';
import { arrayRemove, checkInteger, accMul, utilsTotal } from "/@/kernel-mn/utils";

const GAME_STORGAE_KEY = 'game-state';
const sportState = {
  currentMarket: GAME_MENU[0], // 当前1级菜单选中的数据
  currentGame: 1, // 当前选中的游戏,默认足球=1
  currentBetData: null, // 当前下注项的内容
  betLeagueItemData: null,
  cross: {}, // 串关游戏, 最多10个
  cartMode: 0, // 0=不显示购物车;1=单注购物车;2=串关购物车
  currentMatchEvent: null, // 当前赛事id
  leaguSelected: [], // 联赛筛选ID
  selectedType: 1,
  selectedGameText: '',
  sportMenus: null, // 首页菜单个数
  eventsDetailLists: {}, // 所有赛事缓存列表
  guanjunDetailLists: {}, // 所有冠军赛事缓存列表
  game3LoginInfo: {}, // 登录IM体育第三方之后返回的token信息
  leagueSortType: 1, // 联赛排序 1=联赛; 2=时间
  leaguListStyle: 1, // 1=默认展开所有;2=收起明细,只展示联赛名
  hotEvents: [], // 热门赛事
  leagueDate: '', // 联赛过滤时间,适用于早盘
  currentGameShortInfo: {
    competitionName: '',
    homeTeam: '',
    awayTeam: '',
    eventDate: ''
  }, // 当前赛事简略信息
  currentHomeGameTab: 'lobby',
  GamesPlatform: null, // 381119接口返回数据 防止多次请求
}
export const useGameStore = defineStore({
  id: 'game',
  state: () => {
    return {
      ...sportState,
      hallData: [], // 彩票大厅的数据
      hallDataAll: [], // 彩票大厅所有彩票 - 不被覆盖的
      allLotteryData: {}, // 所有彩票信息
      gameTabsData: [], // 游戏二级菜单数据（开元棋牌，RT电子...）
      gameTabSelectedType: 0, // 游戏二级菜单当前选中
      gameTabSelectedData: {},
      tianChengList: [], // 天成彩票
      saveGPIList: [], // GPI
      saveDBList: [], // DB彩票
      savePC28: [], // pc蛋蛋
      menuSort: [],
      isOpenCp: false, // 是否开启彩票
      isShowGPIBtn: false,
      isShowSBCPBtn: false,
      isShowDBCPBtn: false,
      planDetail: {} as any, // 方案详情
      currentPlayPlan: getJSON('currentPlayPlan') ? getJSON('currentPlayPlan') : {}, // 投注列表中要查看详情的那一期方案
      hotGames: [], // 热门游戏
      playGames: {}, // 第三方游戏数据
      playAllStates: {}, // 所有玩法状态
      qiPaiPlayGames: [], // 棋牌页面的大玩法棋牌列表
      dictGames: {},
      allGames: [], // 所有玩法
      playPlanList: {
        page_start: 1,
        data: [],
        count: 0,
        t: {}
      },
      playPricingPlan: {},
      gameTab: {}, // 玩法销售状态
      selectSliders: {}, // 选号界面滑块
      playDetails: {},
      termHistorys: {},
      terms: {},
      termsStatus: {},
      selectedBalls: {}, // 已选中的号码
      selectedBallsAmount: {}, // 已选中的号码的自定义输入金额 [一般投注号码下的输入框] {[球id]: 自定义金额}
      selectedBallsDetails: {}, // 已选中的号码详情
      baskets: {}, // 号码蓝
      singleTexts: {}, // 单式文本
      unitNumber: {}, // 投注单位 1:元 0.1:角 0.01:分 0.001:厘
      personalRebateStatus: {}, // 个人返点控制
      chips: {}, // 筹码
      selectMutex: {}, // 选号界面倍数
      updateBasketOut: null,
      publicMultix: {}, // 所有玩法投注页面底部组件倍数
      isAssignment: {}, // 是否给拉杆赋值
      charts: {}, // 走势图
      curRoom: {} as any,
      curRoomType: {} as any,
      roomChat: {},
      roomTypeId: 1,
      roomId: -1,
      backWaterList: [],
      backWaters: { 1: [], 2: [], 3: [] },
      betLimits: { 1: [{}, {}], 2: [{}, {}], 3: [{}, {}] },
      roomTypes: {},
      version_type: [], // 后台配置的热度展示tab
      smallClassGames: {} as any,
      gameBigClass: { chess: [], electronic: [], sports: [], live: [], fishing: [] }, // 游戏大类
      newHotGames: [],
      profitList: [], // 盈利榜数据
      playRewardRank: { count: 0, data: [] }, // 中奖排行榜
      originalGamesMsg: {}, // 原创游戏推送消息
      originalGames: [], // 原创游戏
    }
  },
  getters: {
    // 获取冠军赛事列表
    getGuanjunLists: state => (eventIds: number[]) => {
      if (eventIds) {
        // 分类排序
        let _list = {};
        eventIds.map(v => {
          const _data = state.guanjunDetailLists[v];
          if (_data) {
            const title = _data.outrightEventName;
            const firstWord = title.substr(0, 1);
            if (/[A-Za-z]+/.test(firstWord) || CN_EN_MAP[firstWord]) {
              const upper = CN_EN_MAP[firstWord] || firstWord.toUpperCase();
              if (!_list[upper]) {
                _list[upper] = [];
              }
              _list[upper].push(_data);
            } else {
              if (!_list[firstWord]) {
                _list[firstWord] = [];
              }
              _list[firstWord].push(_data);
            }
          }
        });
        // 排序
        const keys = Object.keys(_list);
        const newList = {};
        keys.sort().map(v => {
          newList[v] = _list[v];
        });
        return newList;
      } else {
        return [];
      }
    },
    // 获取赛事明细列表
    getEventsLists: state => (eventIds: number[]) => {
      if (eventIds) {
        let _events = [];
        eventIds.map(v => {
          if (state.eventsDetailLists[v]) {
            _events.push(state.eventsDetailLists[v]);
          }
        });
        return _events;
      } else {
        return [];
      }
    },
    // 获取当前选中体育游戏的收藏赛事ids
    getGuanjunEventById: state => eventId => {
      return state.guanjunDetailLists[eventId];
    }
  },
  actions: {
    ...gameFetch,
    // 设置全部彩票数据 - 不覆盖的静态的
    setHallDataAll(data = []) {
      this.hallDataAll = data;
    },
    changeSelectedType(data) {
      this.selectedType = data
    },
    setSelectedText(data) {
      this.selectedGameText = data
    },
    setGameTabsData(gameTabsData: []) {
      this.gameTabsData = gameTabsData;
    },
    setSmallClassGame(data) {
      this.smallClassGames = data.data
    },
    setCurrentHomeGameTab(data) {
      this.currentHomeGameTab = data
    },
    setSelectedType(selectedType) {
      this.gameTabSelectedType = selectedType;
    },
    setSelectedGame(data) {
      this.gameTabSelectedData = data
    },
    fromStorage() {
      try {
        const localData = getJSON(GAME_STORGAE_KEY)
        this.$state = {
          ...this.$state,
          ...localData
        }
      } catch (error) {
        removeSession(GAME_STORGAE_KEY);
      }
    },
    setTenantPlays(data) {
      if (data) {
        let states = this.playAllStates;
        config.marginTenantPlayInfo(data);
        config.updateMainPlayStates(states);
        let playConfig = config.getPlayConfig();
        let arr = [];
        let dict = {};
        for (let k in playConfig) {
          let play = Object.assign({}, playConfig[k]);
          if (play.enable) {
            arr.push(play);
            dict[play.id] = play;
          }
        }
        this.dictGames = dict
        this.allGames = arr
        this.playAllStates = Object.assign({}, data)
      }
    },
    setGameAllStates(data) {
      let apaterData = {}
      if (data) {
        for (let key in data) {
          apaterData[key] = {
            play: {
              play_id: key,
              play_state: data[key]
            }
          }
        }
      }
      config.updateMainPlayStates(apaterData)
      let playConfig = config.getPlayConfig()
      let arr = []
      let dict = {}
      for (let k in playConfig) {
        let play = Object.assign({}, playConfig[k])
        if (play.enable) {
          arr.push(play)
          dict[play.id] = play
        }
      }
      this.dictGames = dict
      this.allGames = arr
      this.playAllStates = Object.assign({}, apaterData)
    },
    clearGuanJun() {
      this.guanjunDetailLists = {}
    },
    initSportGame() {
      this.fromStorage();
      this.$subscribe(() => {
        // 数据太大崩掉了,只保存体育的数据
        const sportsKeys = Object.keys(sportState)
        const tem = {}
        sportsKeys.map(v => {
          tem[v] = this.$state[v]
        })
        // Object.keys(this.$state).map(v => {
        // })
        setJSON(GAME_STORGAE_KEY, tem);
      });
      // window.addEventListener('storage', (event) => {
      //   if (event.key === GAME_STORGAE_KEY) {
      //     this.fromStorage()
      //   }
      // })
    },
    // 更新串关数据
    updateCrossData(event: any) {
      let eventId = event.eventId || event.EventId;
      let _betEvent = this.cross[eventId];
      if (!_betEvent && (this.currentBetData && eventId === this.currentBetData.EventId)) {
        _betEvent = this.currentBetData
      }
      if (_betEvent) {
        if (event.marketLines?.length) {
          event.marketLines.map(v => {
            // 找出对应的玩法
            const wagerSelections = v.WagerSelections ? JSON.parse(v.WagerSelections) : [];
            if (v.marketlineId === _betEvent.marketlineId && wagerSelections.length) {
              wagerSelections.map(bet => {
                if (bet.WagerSelectionId === _betEvent.WagerSelectionId && bet.SelectionId === _betEvent.SelectionId) {
                  const _tempOdds = bet.OddsList.find(v => v.OddsType === _betEvent.OddsType);
                  // 更新串关
                  if (this.cross[eventId]) {
                    this.cross[eventId] = {
                      ..._betEvent,
                      AwayScore: event.awayScore,
                      HomeScore: event.homeScore,
                      Handicap: bet.Handicap,
                      Odds: _tempOdds.OddsValues.A
                    };
                  }
                  // 更新当前赛事
                  if (this.currentBetData && eventId === this.currentBetData.EventId) {
                    this.currentBetData = {
                      ..._betEvent,
                      AwayScore: event.awayScore,
                      HomeScore: event.homeScore,
                      Handicap: bet.Handicap,
                      Odds: _tempOdds.OddsValues.A
                    };
                  }
                }
              });
            }
          });
        } else {
          // 清楚无效串关数据
          delete this.cross[eventId];
        }
      }
    },
    // 批量更新串关数据
    updateCrossDataPatch(arr: []) {
      arr.map((v: any) => {
        const eventId = v.eventId || v.EventId
        if (this.cross[eventId]) {
          this.cross[eventId] = {
            ...this.cross[eventId],
            ...v
          };
        }
      });
    },
    changeGame(gameId: number) {
      this.currentGame = gameId;
      this.leaguSelected = [];
      this.leagueDate = '';
    },
    changeMarket(market: any) {
      this.currentMarket = market;
      this.leaguSelected = [];
      this.leagueDate = '';
    },
    changeLeagueDate(date: any) {
      this.leagueDate = date;
    },
    changeCartStatus(obj: any) {
      this.currentBetData = obj;
      // 点击盘口默认弹出单注购物车
      this.cartMode = obj ? 1 : 0;
    },
    setBetLeagueItemData(obj: any) {
      this.betLeagueItemData = obj
    },
    // 更新单注购物车数据
    updateCurrentBetData(obj: any) {
      this.currentBetData = {
        ...this.currentBetData,
        ...obj,
        timer: new Date().getTime()
      };
      // 加一个timer用来监听对象变化
    },
    changeCartMode(mode: number) {
      this.cartMode = mode;
    },
    addCross(obj: any) {
      this.cross[obj.EventId] = obj;
    },
    removeCross(eventId: number) {
      if (this.cross[eventId]) {
        delete this.cross[eventId];
      }
    },
    clearCross() {
      this.cross = {}
    },
    setCurrentGameShortInfo(data) {
      this.currentGameShortInfo = data
    },
    setTermsStatus(data) {
      let newTermStatus = Object.assign({}, this.termsStatus)
      newTermStatus[data.key] = data.data
      this.termsStatus = newTermStatus
    },
    setTerms(data) {
      let terms = Object.assign({}, this.terms)
      terms[data.key] = data.data
      if (!terms[data.key].time_zone && terms[data.key].time_zone !== 0) { // 附上默认时区值
        terms[data.key].time_zone = 8
      } else if (typeof terms[data.key].time_zone === 'string') {
        try {
          terms[data.key].time_zone = parseInt(terms[data.key].time_zone)
        } catch (e) {
          terms[data.key].time_zone = 8
        }
      }
      this.terms = terms
    },
    setTermHistorys(data) {
      this.termHistorys = { ...this.termHistorys, ...data }
    },
    setPlayDetails(data) {
      let newPlayDetails = Object.assign({}, this.playDetails)
      let val = data.value
      for (let i in val) {
        newPlayDetails[val[i].play_id] = val[i]
      }
      this.playDetails = newPlayDetails
    },
    clearAllSelecteds() {
      this.selectedBalls = {}
      this.selectedBallsDetails = {}
      this.singleTexts = {}
    },
    setSingleTexts(payload) {
      let singleTexts = {}
      singleTexts[payload.subPlayId] = payload.text
      this.singleTexts = singleTexts
    },
    resetSelecteds(payload) {
      let newSelectedBalls = Object.assign({}, this.selectedBalls)
      let newSelectedBallsDetails = Object.assign({}, this.selectedBallsDetails)

      let ballinfo = config.getBallInfo(payload.subPlayId)
      let playSelectedBalls = []
      let playSelectedDetails = {}
      let subPlay = config.getPlayInfo(payload.subPlayId)

      if (!subPlay.simplex) {
        // 选中的号码球
        for (let i in payload.selected_ids) {
          let selectedItem = {
            play_id: payload.subPlayId,
            ball_id: payload.selected_ids[i],
            selected: true
          }
          playSelectedBalls.push(selectedItem.ball_id)
          playSelectedDetails[selectedItem.ball_id] = selectedItem
        }
      } else {
        playSelectedBalls = payload.selected_ids
      }

      let optionArrays = []
      let optionDetails = {}
      let hasOptions = false
      if (payload.resetOption) {
        optionArrays = []
        optionDetails = {}
        for (let i in ballinfo.areas) {
          let area = ballinfo.areas[i]
          if (area.isoption && area.option) {
            hasOptions = true
            for (let j in area.option.balls) {
              let checkOption = {
                play_id: payload.subPlayId,
                ball_id: area.baseballid + area.option.balls[j],
                selected: true
              }
              if (optionArrays.indexOf(checkOption.ball_id) === -1) {
                optionArrays.push(checkOption.ball_id)
              }
              optionDetails[checkOption.ball_id] = checkOption
            }
          }
        }
      } else {
        for (let i in ballinfo.areas) {
          let area = ballinfo.areas[i]
          if (area.isoption && area.option) {
            hasOptions = true
            break
          }
        }
        let oldDetails = this.selectedBallsDetails[payload.subPlayId]
        if (oldDetails == null) {
          oldDetails = {}
        }
        for (let k in oldDetails) {
          let ball = ballinfo.balls[k]
          if (ball.isoption) {
            optionArrays.push(k)
            optionDetails[k] = oldDetails[k]
          }
        }
      }
      if (subPlay.simplex) {
        let cloneOptionArrays = []
        for (let i in optionArrays) {
          cloneOptionArrays.push(ballinfo.balls[optionArrays[i]].cmd)
        }
        cloneOptionArrays = cloneOptionArrays.sort()
        if (hasOptions) {
          playSelectedBalls = [cloneOptionArrays.join('')].concat(playSelectedBalls)
        }
        playSelectedDetails = Object.assign(playSelectedDetails, optionDetails)
      } else {
        playSelectedBalls = optionArrays.concat(playSelectedBalls)
        playSelectedDetails = Object.assign(playSelectedDetails, optionDetails)
      }
      if (hasOptions) {
        if ((payload.selected_ids.length + 1) !== playSelectedBalls.length) {
          return
        }
      }
      newSelectedBalls[payload.subPlayId] = playSelectedBalls
      newSelectedBallsDetails[payload.subPlayId] = playSelectedDetails
      this.selectedBalls = newSelectedBalls
      this.selectedBallsDetails = newSelectedBallsDetails
    },
    handleSingleText(payload) {
      let formatText
      if (payload.isDel) {
        formatText = payload.text
      } else {
        formatText = singleTextFormat(payload.text, payload.main_play_id, payload.subplayid)
      }
      let selectedIds = convertSingleTextToSelectIds(formatText, payload.main_play_id, payload.subplayid)
      this.setSingleTexts({ subPlayId: payload.subplayid, text: formatText })
      this.resetSelecteds({ subPlayId: payload.subplayid, selected_ids: selectedIds, resetOption: false })
    },
    clearSelecteds(payload) {
      let newSelectedBalls = Object.assign({}, this.selectedBalls)
      let newSelectedBallsDetails = Object.assign({}, this.selectedBallsDetails)
      let subPlay = config.getPlayInfo(payload.subPlayId)
      let ballinfo = config.getBallInfo(payload.subPlayId)
      let optionBalls = []
      let optionDetails = {}
      let hasOptions = false
      for (let i in ballinfo.areas) {
        let area = ballinfo.areas[i]
        if (area.isoption && area.option) {
          hasOptions = true
          for (let j in area.option.balls) {
            let checkOption = {
              play_id: payload.subPlayId,
              ball_id: area.baseballid + area.option.balls[j],
              selected: true
            }
            if (optionBalls.indexOf(checkOption.ball_id) === -1) {
              optionBalls.push(checkOption.ball_id)
            }
            optionDetails[checkOption.ball_id] = checkOption
          }
        }
      }
      if (subPlay && subPlay.simplex) {
        if (hasOptions) {
          let cloneOptionArrays = []
          for (let i in optionBalls) {
            cloneOptionArrays.push(ballinfo.balls[optionBalls[i]].cmd)
          }
          newSelectedBalls[payload.subPlayId] = [cloneOptionArrays.join('')]
        }
      } else {
        newSelectedBalls[payload.subPlayId] = optionBalls
      }
      if (payload._clear) {
        newSelectedBalls[payload.subPlayId] = []
      }
      newSelectedBallsDetails[payload.subPlayId] = optionDetails
      this.selectedBalls = newSelectedBalls
      this.selectedBallsDetails = newSelectedBallsDetails
      let singleTexts = {}
      singleTexts[payload.subPlayId] = ''
      this.singleTexts = singleTexts
    },
    selectedBall(payload) {
      let play = config.getPlayInfo(payload.subPlayId)
      let selecetBalls = this.selectedBalls[payload.subPlayId]
      if (selecetBalls == null) {
        selecetBalls = []
      }
      let allAreaBalls = ballsWithArea(payload.subPlayId, selecetBalls)
      let ballinfo = config.getBallInfo(payload.subPlayId)
      let fanilSelected = (options = null) => {
        let data = {
          play_id: payload.subPlayId,
          ball_id: payload.ballId,
          selected: payload.selected,
          odds: payload.ballodds, // 赔率
          ball_multx: payload.ballMultx
        }
        if (options) {
          for (let k in options) {
            data[k] = options[k]
          }
        }
        let playSelectedIds = this.selectedBalls[payload.subPlayId]
        if (playSelectedIds == null) playSelectedIds = []
        playSelectedIds = Object.assign([], playSelectedIds)

        let playSelectedDetails = this.selectedBallsDetails[payload.subPlayId]
        if (playSelectedDetails == null) playSelectedDetails = {}
        playSelectedDetails = Object.assign({}, playSelectedDetails)

        let optionArrays = []
        let optionBallArrays = []
        if (play.simplex) {
          let oldDetails = this.selectedBallsDetails[payload.subPlayId]
          if (oldDetails) {
            for (let k in oldDetails) {
              let ball = ballinfo.balls[k]
              if (ball.isoption) {
                optionArrays.push(ball.cmd)
                optionBallArrays.push(ball)
              }
            }
          }
        }

        let ball = ballinfo.balls[data.ball_id]
        if (data.selected) {
          // 单式
          if (play.simplex && ball.isoption) {
            optionArrays.push(ball.cmd)
            optionArrays = optionArrays.sort()
            playSelectedIds[0] = optionArrays.join('')
          } else if (playSelectedIds.indexOf(data.ball_id) === -1) {
            playSelectedIds.push(data.ball_id)
          }
          playSelectedDetails[data.ball_id] = data
        } else {
          // 单式
          if (play.simplex && ball.isoption) {
            let area = ballinfo.areas[ball.areaidx]
            if (optionArrays.length > area.option.min) {
              arrayRemove(optionArrays, ball.cmd)
              optionArrays = optionArrays.sort()
              playSelectedIds[0] = optionArrays.join('')
              delete playSelectedDetails[data.ball_id]
            }
          } else {
            arrayRemove(playSelectedIds, data.ball_id)
            delete playSelectedDetails[data.ball_id]
          }
        }
        let newSelectedBalls = Object.assign({}, this.selectedBalls)
        let newSelectedBallsDetails = Object.assign({}, this.selectedBallsDetails)

        newSelectedBalls[payload.subPlayId] = playSelectedIds
        newSelectedBallsDetails[payload.subPlayId] = playSelectedDetails
        this.selectedBalls = newSelectedBalls
        this.selectedBallsDetails = newSelectedBallsDetails
      }
      // 时时彩包胆
      if (play.note_tag === 'ssc2CombBaoDan' || play.note_tag === 'ssc3CombBaoDan') {
        fanilSelected()
      }
      // 香港彩连肖胆拖
      else if (play.note_tag.indexOf('xiaodt') === 1) {
        if (payload.selected) {
          // 胆码
          if (String(payload.ballId).indexOf('1') === 0) {
            if (allAreaBalls[0].length < ballinfo.areas[0].maxselect) {
              fanilSelected()
              let ballid = Number('2' + String(payload.ballId).substr(1, payload.ballId.length))
              fanilSelected({ ball_id: ballid, selected: false })
            }
          }
          // 拖码
          else if (String(payload.ballId).indexOf('2') === 0) {
            if (allAreaBalls[1].length < ballinfo.areas[1].maxselect) {
              let selectedDict = {}
              for (let i in selecetBalls) {
                selectedDict[selecetBalls[i]] = true
              }
              // 胆码没选中的时候，拖码才可以选中
              let ballid = Number('1' + String(payload.ballId).substr(1, payload.ballId.length))
              if (!selectedDict[ballid]) {
                fanilSelected()
              }
            }
          }
        } else {
          fanilSelected()
        }
      } else if (play.note_tag === 'qianDX') {
        if (payload.selected) {
          let head = String(payload.ballId).indexOf('1') === 0 ? '2' : '1'
          let ballid = Number(head + String(payload.ballId).substr(1, payload.ballId.length))
          fanilSelected({ ball_id: ballid, selected: false })
          fanilSelected()
        } else {
          fanilSelected()
        }
      }
      // 胆拖
      else if (play.note_tag.indexOf('dtComb') === 0) {
        let num = Number(play.note_tag.substr(play.note_tag.length - 1))
        if (payload.selected) {
          if (String(payload.ballId).indexOf('1') === 0) {
            if (allAreaBalls[0].length >= num - 1) {
              fanilSelected({ ball_id: allAreaBalls[0][0].id, selected: false })
            }
            let ballid = Number('2' + String(payload.ballId).substr(1, payload.ballId.length))
            fanilSelected({ ball_id: ballid, selected: false })
          } else if (String(payload.ballId).indexOf('2') === 0) {
            let ballid = Number('1' + String(payload.ballId).substr(1, payload.ballId.length))
            fanilSelected({ ball_id: ballid, selected: false })
          }
          fanilSelected()
        } else {
          fanilSelected()
        }
      } else if (play.note_tag === '2hunpen') {
        if (payload.selected) {
          if (selecetBalls.length < 2) {
            fanilSelected()
          }
        } else {
          fanilSelected()
        }
      } else {
        // 各区域选号球数量限制
        if (payload.selected) {
          let index = Number(String(payload.ballId).substr(0, 1)) - 1
          if (ballinfo.areas[index] && ballinfo.areas[index].maxselect) {
            if (allAreaBalls[index].length < ballinfo.areas[index].maxselect) {
              fanilSelected()
            }
          } else {
            fanilSelected()
          }
        } else {
          fanilSelected()
        }
      }
    },
    selectAreaExtFunc(payload) {
      let selected = false
      let selectedBallsDetails = this.selectedBallsDetails
      let area = payload.area
      let extArgItem = payload.extArgItem
      let areaExt = {} as any
      if (payload.ext) {
        areaExt = config.getPlayExtItem(payload.ext.id)
      } else {
        areaExt = config.getPlayExtItem(payload.area.extid)
      }
      let onSelected = (obj) => {
        if (payload.onSelected) {
          payload.onSelected(obj)
        }
        this.selectedBall(obj)
      }
      let calendar = helperHK.generateYearNumZodiac(new Date())
      let slectedPlayNum = 'null'
      Object.keys(selectedBallsDetails)[0] && (slectedPlayNum = Object.keys(selectedBallsDetails)[0])
      let fun = (val) => {
        if (payload.isApp && !payload.quickSelectedBtn && selectedBallsDetails[slectedPlayNum]) {
          selected = false
          for (let item in selectedBallsDetails[slectedPlayNum]) {
            if (Number(item) === val) delete selectedBallsDetails[slectedPlayNum][item]
          }
        }
      }
      switch (areaExt.id) {
        // 全/大/小/奇/偶/清
        case 2:
          for (let i in area.ids) {
            let selected = false
            switch (extArgItem.tag) {
              case 'da':
                selected = Number(i) >= area.ids.length / 2
                break
              case 'xiao':
                selected = Number(i) < area.ids.length / 2
                break
              case 'ji':
                selected = area.ids[i] % 2 !== 0
                break
              case 'ou':
                selected = area.ids[i] % 2 === 0
                break
              case 'quan':
                selected = true
                break
              case 'qin':
                selected = false
                break
            }
            onSelected({ subPlayId: payload.subplayid, ballId: area.ids[i], selected: selected })
          }
          break
        case 3: {
          let yesou = ['蛇', '龙', '兔', '虎', '鼠', '猴']
          for (let i in area.ids) {
            let strID = String(area.ids[i] - area.baseballid)
            let array = strID.split('')
            let sum = 0
            let last = Number(array[array.length - 1])
            array.forEach((item) => {
              sum = sum + Number(item)
            })
            array = String(sum).split('')
            switch (extArgItem.tag) {
              case 'dan':
                selected = area.ids[i] % 2 !== 0
                if (selected) fun(area.ids[i])
                break
              case 'shuang':
                selected = area.ids[i] % 2 === 0
                if (selected) fun(area.ids[i])
                break
              case 'da':
                selected = Number(i) >= area.ids.length / 2 - 1
                if (selected) fun(area.ids[i])
                break
              case 'xiao':
                selected = Number(i) < area.ids.length / 2 - 1
                if (selected) fun(area.ids[i])
                break
              case 'hedan':
                selected = sum % 2 !== 0
                if (selected) fun(area.ids[i])
                break
              case 'heshuang':
                selected = sum % 2 === 0
                if (selected) fun(area.ids[i])
                break
              case 'jiaqin':
                if (yesou.indexOf(calendar[strID]) === -1) {
                  selected = true
                  fun(area.ids[i])
                } else {
                  selected = false
                }
                break
              case 'yesou':
                if (yesou.indexOf(calendar[strID]) !== -1) {
                  selected = true
                  fun(area.ids[i])
                } else {
                  selected = false
                }
                break
              case 'weida':
                selected = last >= 5
                if (selected) fun(area.ids[i])
                break
              case 'weixiao':
                selected = last < 5
                if (selected) fun(area.ids[i])
                break
              case 'heweida':
                last = Number(array[array.length - 1])
                selected = last >= 5
                if (selected) fun(area.ids[i])
                break
              case 'heweixiao':
                if (Number(i) !== 24) {
                  last = Number(array[array.length - 1])
                  selected = last < 5
                }
                if (selected) fun(area.ids[i])
                break
              default:
            }
            onSelected({
              subPlayId: payload.subplayid,
              ballId: area.ids[i],
              selected: selected,
              quickSelectedBtn: payload.quickSelectedBtn
            })
          }
          break
        }
        case 4:
          for (let i in area.ids) {
            let ballid = area.ids[i] - area.baseballid
            switch (extArgItem.tag) {
              case 'red':
                selected = helperHK.getBallColor(ballid) === 1
                if (selected) fun(area.ids[i])
                break
              case 'blue':
                selected = helperHK.getBallColor(ballid) === 2
                if (selected) fun(area.ids[i])
                break
              case 'green':
                selected = helperHK.getBallColor(ballid) === 3
                if (selected) fun(area.ids[i])
                break
              case 'reddan':
                selected = (helperHK.getBallColor(ballid) === 1 && ballid % 2 !== 0)
                if (selected) fun(area.ids[i])
                break
              case 'redshuang':
                selected = (helperHK.getBallColor(ballid) === 1 && ballid % 2 === 0)
                if (selected) fun(area.ids[i])
                break
              case 'redda':
                selected = (helperHK.getBallColor(ballid) === 1 && Number(i) >= area.ids.length / 2)
                if (selected) fun(area.ids[i])
                break
              case 'redxiao':
                selected = (helperHK.getBallColor(ballid) === 1 && Number(i) < area.ids.length / 2)
                if (selected) fun(area.ids[i])
                break
              case 'bluedan':
                selected = (helperHK.getBallColor(ballid) === 2 && ballid % 2 !== 0)
                if (selected) fun(area.ids[i])
                break
              case 'blueshuang':
                selected = (helperHK.getBallColor(ballid) === 2 && ballid % 2 === 0)
                if (selected) fun(area.ids[i])
                break
              case 'blueda':
                selected = (helperHK.getBallColor(ballid) === 2 && Number(i) >= area.ids.length / 2 - 1)
                if (selected) fun(area.ids[i])
                break
              case 'bluexiao':
                selected = (helperHK.getBallColor(ballid) === 2 && Number(i) < area.ids.length / 2 - 1)
                if (selected) fun(area.ids[i])
                break
              case 'greendan':
                selected = (helperHK.getBallColor(ballid) === 3 && ballid % 2 !== 0)
                if (selected) fun(area.ids[i])
                break
              case 'greenshuang':
                selected = (helperHK.getBallColor(ballid) === 3 && area.ids[i] % 2 === 0)
                if (selected) fun(area.ids[i])
                break
              case 'greenda':
                selected = (helperHK.getBallColor(ballid) === 3 && Number(i) >= area.ids.length / 2)
                if (selected) fun(area.ids[i])
                break
              case 'greenxiao':
                selected = (helperHK.getBallColor(ballid) === 3 && Number(i) < area.ids.length / 2)
                if (selected) fun(area.ids[i])
                break
              default:
            }
            onSelected({
              subPlayId: payload.subplayid,
              ballId: area.ids[i],
              selected: selected,
              quickSelectedBtn: payload.quickSelectedBtn
            })
          }
          break
        case 5:
          for (let i in area.ids) {
            let ballid = String(area.ids[i] - area.baseballid)
            let array = ballid.split('')
            let first = array[0]
            let last = array[array.length - 1]
            switch (extArgItem.tag) {
              case '0head':
                selected = ballid.length === 1
                if (selected) fun(area.ids[i])
                break
              case '1head':
                selected = first === '1' && ballid.length !== 1
                if (selected) fun(area.ids[i])
                break
              case '2head':
                selected = first === '2' && ballid.length !== 1
                if (selected) fun(area.ids[i])
                break
              case '3head':
                selected = first === '3' && ballid.length !== 1
                if (selected) fun(area.ids[i])
                break
              case '4head':
                selected = first === '4' && ballid.length !== 1
                if (selected) fun(area.ids[i])
                break
              case '0end':
                selected = last === '0'
                if (selected) fun(area.ids[i])
                break
              case '1end':
                selected = last === '1'
                if (selected) fun(area.ids[i])
                break
              case '2end':
                selected = last === '2'
                if (selected) fun(area.ids[i])
                break
              case '3end':
                selected = last === '3'
                if (selected) fun(area.ids[i])
                break
              case '4end':
                selected = last === '4'
                if (selected) fun(area.ids[i])
                break
              case '5end':
                selected = last === '5'
                if (selected) fun(area.ids[i])
                break
              case '6end':
                selected = last === '6'
                if (selected) fun(area.ids[i])
                break
              case '7end':
                selected = last === '7'
                if (selected) fun(area.ids[i])
                break
              case '8end':
                selected = last === '8'
                if (selected) fun(area.ids[i])
                break
              case '9end':
                selected = last === '9'
                if (selected) fun(area.ids[i])
                break
              default:
            }
            onSelected({
              subPlayId: payload.subplayid,
              ballId: area.ids[i],
              selected: selected,
              quickSelectedBtn: payload.quickSelectedBtn
            })
          }
          break
        case 6:
          for (let i in area.ids) {
            let ballid = String(area.ids[i] - area.baseballid)
            switch (extArgItem.tag) {
              case '1':
                selected = calendar[ballid] === '鼠'
                if (selected) fun(area.ids[i])
                break
              case '2':
                selected = calendar[ballid] === '牛'
                if (selected) fun(area.ids[i])
                break
              case '3':
                selected = calendar[ballid] === '虎'
                if (selected) fun(area.ids[i])
                break
              case '4':
                selected = calendar[ballid] === '兔'
                if (selected) fun(area.ids[i])
                break
              case '5':
                selected = calendar[ballid] === '龙'
                if (selected) fun(area.ids[i])
                break
              case '6':
                selected = calendar[ballid] === '蛇'
                if (selected) fun(area.ids[i])
                break
              case '7':
                selected = calendar[ballid] === '马'
                if (selected) fun(area.ids[i])
                break
              case '8':
                selected = calendar[ballid] === '羊'
                if (selected) fun(area.ids[i])
                break
              case '9':
                selected = calendar[ballid] === '猴'
                if (selected) fun(area.ids[i])
                break
              case '10':
                selected = calendar[ballid] === '鸡'
                if (selected) fun(area.ids[i])
                break
              case '11':
                selected = calendar[ballid] === '狗'
                if (selected) fun(area.ids[i])
                break
              case '12':
                selected = calendar[ballid] === '猪'
                if (selected) fun(area.ids[i])
                break
              default:
            }
            onSelected({
              subPlayId: payload.subplayid,
              ballId: area.ids[i],
              selected: selected,
              quickSelectedBtn: payload.quickSelectedBtn
            })
          }
          break
        default:
      }
      if (payload.isApp && selectedBallsDetails[slectedPlayNum]) {
        for (let item in selectedBallsDetails[slectedPlayNum]) {
          onSelected({ subPlayId: payload.subplayid, ballId: Number(item), selected: true })
        }
      }
    },
    setSelectSliders(data) {
      if (data.value === 'NaN') data.value = '0'
      let newSliders = Object.assign({}, this.selectSliders)
      newSliders[data.key] = Math.floor(Number(data.value))
      this.selectSliders = newSliders
    },
    setBasketItemsRebate(payload) {
      let key = payload.main_play_id
      if (payload.key) {
        key = payload.key
      }
      let cloneBaskets = Object.assign({}, this.baskets)
      let basket = cloneBaskets[key]
      if (basket == null) {
        basket = createBasket()
      }
      for (let k in basket.items) {
        let item = basket.items[k]
        item.rebate = payload.rebate
      }
      updateBasket(basket)
      cloneBaskets[key] = basket
      const newCloneBaskets = {}
      if (cloneBaskets[key]) {
        newCloneBaskets[key] = cloneBaskets[key]
      }
      if (cloneBaskets['final-' + key]) {
        newCloneBaskets['final-' + key] = cloneBaskets['final-' + key]
      }
      // helperStorage.write('baskets', JSON.stringify(newCloneBaskets))
      this.baskets = newCloneBaskets
    },
    clearBasketWithPlayId(payload) {
      let key = payload.main_play_id
      if (payload.key) {
        key = payload.key
      }
      let cloneBaskets = Object.assign({}, this.baskets)
      cloneBaskets[key] = createBasket()
      const newCloneBaskets = {}
      if (cloneBaskets[key]) {
        newCloneBaskets[key] = cloneBaskets[key]
      }
      if (cloneBaskets['final-' + key]) {
        newCloneBaskets['final-' + key] = cloneBaskets['final-' + key]
      }
      // helperStorage.write('baskets', JSON.stringify(newCloneBaskets))
      this.baskets = newCloneBaskets
    },
    setSelectMutex(data) {
      let newMutex = Object.assign({}, this.selectMutex)
      let strMutex = data.value + ''
      if (strMutex.length > 11) {
        data.value = Number(strMutex.slice(0, 11))
      }
      newMutex[data.key] = data.value
      this.selectMutex = newMutex
    },
    selectedIdsToBasket(payload) {
      let key = payload.main_play_id
      if (payload.key) {
        key = payload.key
      }
      let cloneBaskets = Object.assign({}, this.baskets)
      let mainPlay = config.getPlayInfo(payload.main_play_id)
      let subPlay = config.getPlayInfo(payload.subplayid)
      let rebate = getCurrentPlayRebate(payload.main_play_id) // 返点
      if (!rebate) {
        rebate = 0
      }
      let multx = payload.multx
      if (multx == null) {
        if (checkInteger(this.selectMutex[payload.main_play_id])) {
          multx = this.selectMutex[payload.main_play_id] // 倍数
        }
      }
      let coefficient = this.unitNumber[payload.main_play_id] // 价格系数 1：元 0.1：角 0.01：分 0.001厘
      if (coefficient == null) {
        coefficient = 1
      }
      if (multx == null) {
        multx = 1
      }
      let basket = cloneBaskets[key]
      if (basket == null) {
        basket = createBasket()
      }
      cloneBaskets[key] = basket
      // 添加号码栏中的每一个项
      let addToList = (key, data) => {
        if (data.ball_ids != null) {
          if (basket.keys.indexOf(String(key)) === -1) {
            basket.keys.push(String(key))
          }
          data.key = key;
          // 这个金额已经弃用不一定准确
          data.amount = accMul(data.multx * data.note, data.unit_price, 0);
          data.play_cname = subPlay.play_cname
          data.game_cname = mainPlay.play_cname
          data.zName = payload.zName
          basket.items[key] = data

          // 投注号字符
          data.showArray = []
          data.showString = ''
          data.chatString = ''
          // 是否单式
          if (subPlay.simplex) {
            data.showString = ' ' + data.ball_ids.join(',')
            let ballInfo = config.getBallInfo(data.subplayid)
            if (ballInfo.areas.length > 1) {
              let checkArea = ballInfo.areas[0]
              if (checkArea.isoption && data.ball_ids.length > 0) {
                data.showString = ' '
                let checkBalls = data.ball_ids[0].split('')
                for (let chIdx in checkBalls) {
                  let ballId = Number(checkArea.baseballid) + Number((checkBalls[chIdx]))
                  // 聊天室展示
                  data.showString += ballInfo.balls[ballId].name + (ballInfo.balls[ballId].name ? ',' : '')
                }
                let selectNums = []
                for (let ballIdx in data.ball_ids) {
                  if (Number(ballIdx) > 0) {
                    selectNums.push(data.ball_ids[ballIdx])
                  }
                }
                data.showString += ',' + selectNums.join(',')
              }
            }
            data.chatString = data.showString
          } else {
            let ballInfo = config.getBallInfo(data.subplayid)
            let chatString = ''
            for (let j = 0; j < ballInfo.areas.length; j++) {
              let names = []
              for (let k in data.ball_ids) {
                let ball = ballInfo.balls[data.ball_ids[k]]
                if (ball && Number(ball.areaidx) === Number(j)) {
                  // 聊天室展示
                  data.showString += ball.name + (ball.name ? ',' : '')
                  names.push(ball.name)
                }
              }
              if (mainPlay.cat === 'MARKSIX') {
                chatString += names.join(' ')
                j < (ballInfo.areas.length - 1) ? chatString += ',' : chatString += ' '
              }
              j < (ballInfo.areas.length - 1) ? data.showString += ',' : data.showString += ' '
            }
            if (chatString.length > 0) {
              data.chatString = chatString
            } else {
              data.chatString = data.showString
            }
          }
          data.amountWithMode = data.multx + '(' + (coefficient * subPlay.unit_price) + '元)'
          data.showArray = data.showString.split(',')
        }
      }
      // 选中的球的赔率
      const selected_odds = {};
      const selected_amount = {}; // 选中的自定义的钱
      if (payload.selected_odds) {
        payload.selected_ids.forEach(ids => {
          selected_odds[ids] = payload.selected_odds[ids]?.odds ?? ''
          selected_amount[ids] = +(this.selectedBallsAmount[ids] ?? '') || 0;
        })
      }

      if (subPlay.note_tag === 'ssc3CombSpecial') {
        let keyAdd = 0
        for (let i in payload.selected_ids) {
          // 每个号码为一个ticket
          addToList(Number(payload.rom_key) + keyAdd, {
            subplayid: payload.subplayid,
            subPlayName: payload.subPlayName,
            ball_ids: [payload.selected_ids[i]],
            selected_odds,
            selected_amount,
            rebate: rebate,
            multx: multx, // 投注倍数
            unit_price: subPlay.unit_price * coefficient, // 单注单价
            coefficient: coefficient,
            note: noteNum(payload.subplayid, [payload.selected_ids[i]]) // 注数
          })
          keyAdd++
        }
      } else {
        addToList(payload.rom_key, {
          subplayid: payload.subplayid,
          subPlayName: payload.subPlayName,
          ball_ids: payload.selected_ids,
          rebate: rebate,
          multx: multx, // 投注倍数
          odds: payload.odds,
          // 选中的ids的赔率
          selected_odds,
          selected_amount,
          unit_price: subPlay.unit_price * coefficient, // 单注单价
          coefficient: coefficient,
          note: noteNum(payload.subplayid, payload.selected_ids) // 注数
        })
      }
      clearTimeout(this.updateBasketOut)
      updateBasket(basket)
      const newCloneBaskets = {}
      if (cloneBaskets[key]) {
        newCloneBaskets[key] = cloneBaskets[key]
      }
      if (cloneBaskets['final-' + key]) {
        newCloneBaskets['final-' + key] = cloneBaskets['final-' + key]
      }
      // helperStorage.write('baskets', JSON.stringify(newCloneBaskets))
      this.baskets = newCloneBaskets
    },
    setPublicMultix(data) {
      let newMultix = {}
      newMultix[data.key] = data.value
      this.publicMultix = newMultix
    },
    setBasketMutex(payload) {
      let key = payload.main_play_id
      if (payload.key) {
        key = payload.key
      }
      let cloneBaskets = Object.assign({}, this.baskets)
      let basket = cloneBaskets[key]
      if (basket == null) {
        basket = createBasket()
      }
      basket.multx = payload.mutex
      updateBasket(basket)
      cloneBaskets[key] = basket
      const newCloneBaskets = {}
      if (cloneBaskets[key]) {
        newCloneBaskets[key] = cloneBaskets[key]
      }
      if (cloneBaskets['final-' + key]) {
        newCloneBaskets['final-' + key] = cloneBaskets['final-' + key]
      }
      // helperStorage.write('baskets', JSON.stringify(newCloneBaskets))
      this.baskets = newCloneBaskets
    },
    setPersonalRebateStatus(data) {
      let newData = JSON.parse(JSON.stringify(this.personalRebateStatus))
      this.personalRebateStatus = Object.assign(newData, data)
    },
    removeItemFromBasket(payload) {
      let key = payload.main_play_id
      if (payload.key) {
        key = payload.key
      }
      let cloneBaskets = Object.assign({}, this.baskets)
      let basket = cloneBaskets[key]
      if (basket == null) {
        basket = createBasket()
      }
      arrayRemove(basket.keys, String(payload.rom_key))
      delete basket.items[String(payload.rom_key)]
      updateBasket(basket)
      cloneBaskets[key] = basket
      const newCloneBaskets = {}
      if (cloneBaskets[key]) {
        newCloneBaskets[key] = cloneBaskets[key]
      }
      if (cloneBaskets['final-' + key]) {
        newCloneBaskets['final-' + key] = cloneBaskets['final-' + key]
      }
      // helperStorage.write('baskets', JSON.stringify(newCloneBaskets))
      this.baskets = newCloneBaskets
    },
    setUnitNumber(data) {
      let newUnit = Object.assign({}, this.unitNumber)
      newUnit[data.key] = data.value
      this.unitNumber = newUnit
    },
    setPullRod(data) {
      this.isAssignment[data.playId] = data.val
    },
    updateHallData(data) {
      const newData = this.hallData
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].play_id === data.play_id) {
          newData[i].begin_time = data.begin_time
          newData[i].end_time = data.end_time
          newData[i].stopTime = data.stopTime
          newData[i].sys_time = data.sys_time
          newData[i].play_state = data.play_state
          newData[i].countDownss = undefined
        }
      }
      this.hallData = newData
    },
    clearBasketsAll() {
      // helperStorage.write('baskets', JSON.stringify({}))
      this.baskets = {}
    },
    setPlayPricingPlan(data) {
      let newPlayPricingPlan = Object.assign({}, this.playPricingPlan)
      newPlayPricingPlan[data.key] = data.value
      this.playPricingPlan = newPlayPricingPlan
    },
    setPlayPlanList(data) {
      this.playPlanList = {
        page_start: data.page_start || 1,
        data: data.data || [],
        count: data.count || 0,
        t: data.t || {},
      }
    },
    setCharts(data) {
      let newCharts = Object.assign({}, this.charts)
      newCharts[data.key] = data.data
      this.charts = newCharts
    },
    setCurRoomType(data) {
      this.curRoomType = data
      if (data && data.room_type_id) {
        this.roomTypeId = data.room_type_id
      }
    },
    setCurRoom(data) {
      this.curRoom = {
        ...this.curRoom,
        ...data
      }
      if (data && data.play_room_id) {
        this.roomId = data.play_room_id
      }
    },
    setRoomCat(data) {
      this.roomChat = data
    },
    setChips(data) {
      let newChips = Object.assign({}, this.chips)
      let chip = data.value
      if (chip.length > 0) {
        chip = parseInt(chip)
        if (chip < 1) {
          chip = 1
        }
        let strChip = chip + ''
        if (strChip.length > 11) {
          strChip = strChip.slice(0, 11)
        }
        chip = Number(strChip)
        newChips[data.key] = chip
        this.chips = newChips
      } else {
        newChips[data.key] = chip
        this.chips = newChips
      }
    },
    updateBasketItems(payload) {
      let key = payload.main_play_id
      if (payload.key) {
        key = payload.key
      }
      let cloneBaskets = Object.assign({}, this.baskets)
      let basket = cloneBaskets[key]
      if (basket == null) {
        basket = createBasket()
      }
      cloneBaskets[key] = basket
      for (let k in basket.items) {
        if (payload.itemFunc) {
          payload.itemFunc(basket.items[k])
        }
      }
      updateBasket(basket)
      const newCloneBaskets = {}
      if (cloneBaskets[key]) {
        newCloneBaskets[key] = cloneBaskets[key]
      }
      if (cloneBaskets['final-' + key]) {
        newCloneBaskets['final-' + key] = cloneBaskets['final-' + key]
      }
      // helperStorage.write('baskets', JSON.stringify(newCloneBaskets))
      this.baskets = newCloneBaskets
    },
    addBasketItemsFromBasket(payload) {
      if (payload && payload.fromBaket && payload.basketKey) {
        let cloneBaskets = Object.assign({}, this.baskets)
        let basket = cloneBaskets[payload.basketKey]
        if (basket == null) {
          basket = createBasket()
        }
        cloneBaskets[payload.basketKey] = basket
        let time = new Date().getTime()
        let i = 0
        for (let k in payload.fromBaket.items) {
          let item = payload.fromBaket.items[k]
          if (item) {
            // 正常投注设置金额 跟单不需要设置金额
            if (!payload.healing) {
              item.amount = this.chips[payload.basketKey.split('final-')[1]]
            }
            let itemKey = time + i
            item = Object.assign({}, item)
            item.key = itemKey
            basket.keys.push(String(itemKey))
            basket.items[itemKey] = item
          }
          i++
        }
        updateBasket(basket)
        // helperStorage.write('baskets', JSON.stringify(cloneBaskets))
        this.baskets = cloneBaskets
      }
    },
    updateBasketItem(payload) {
      let cloneBaskets = Object.assign({}, this.baskets)
      let newBaskets = JSON.parse(JSON.stringify(cloneBaskets))
      const finalBaskets = newBaskets[payload.playid]
      if (finalBaskets) {
        finalBaskets.total_amount = 0;
        for (let key in finalBaskets.items) {
          finalBaskets.items[payload.key || key].multx = payload.chip / finalBaskets.items[key].unit_price;
          finalBaskets.items[payload.key || key].amount = payload.amount || payload.chip; // 优先取钱的字段 没有则取倍数
          // finalBaskets.total_amount += Number(finalBaskets.items[key].amount)
          // finalBaskets.bonus += finalBaskets.items[payload.key || key].amount * finalBaskets.items[payload.key || key].odds
        }
        updateBasket(finalBaskets)
        this.baskets = newBaskets
      }
    },
    resetBasket({ playid }) {
      let cloneBaskets = Object.assign({}, this.baskets)
      let newBaskets = JSON.parse(JSON.stringify(cloneBaskets))
      const finalBaskets = cloneBaskets['final-' + playid].items
      newBaskets[playid] = newBaskets['final-' + playid]
      delete newBaskets['final-' + playid]
      newBaskets[playid].keys = []
      newBaskets[playid].items = []
      for (let key in finalBaskets) {
        newBaskets[playid]['items'][finalBaskets[key].subplayid + '-' + finalBaskets[key].ball_ids[0]] = finalBaskets[key]
        newBaskets[playid].keys.push(finalBaskets[key].subplayid + '-' + finalBaskets[key].ball_ids[0])
      }
      this.baskets = newBaskets
    },
    setBackWaterList(data) {
      this.backWaterList = Object.assign({}, data)
    },
    setBackWater(data) {
      let backWaters = { 1: [], 2: [], 3: [] }
      for (let i = 0; i < data.length; i++) {
        let item = data[i]
        item.str1 = utilsTotal.moneyToFloat(item.p1) + '~' + utilsTotal.moneyToFloat(item.p2)
        item.str2 = accMul(Number(utilsTotal.moneyToFloat(item.per_charge_units)), 100, 0) + '%'
        backWaters[item.resource_id].push(item)
      }
      this.backWaters = backWaters
    },
    setBetLimit(data) {
      let betLimits = { 1: [{}, {}], 2: [{}, {}], 3: [{}, {}] }
      for (let i = 0; i < data.length; i++) {
        let item = data[i]
        item.str_per_charge_units = utilsTotal.moneyToFloat(item.per_charge_units)// 赔率*1000
        item.str_dz_min = utilsTotal.moneyToFloat(item.dz_min) // 单注最小值
        item.str_dz_max = utilsTotal.moneyToFloat(item.dz_max) // 单注最大值
        item.str_zz_fd = utilsTotal.moneyToFloat(item.zz_fd) // 总注封顶
        item.str_dxds_fd = utilsTotal.moneyToFloat(item.dxds_fd) // 大小单双单注封顶
        item.str_zhdz_fd = utilsTotal.moneyToFloat(item.zhdz_fd) // 组合单注封顶
        item.str_dddz_fd = utilsTotal.moneyToFloat(item.dddz_fd) // 单点单注封顶
        item.str_jdjxdz_fd = utilsTotal.moneyToFloat(item.jdjxdz_fd) // 极大极小单注封顶
        item.str_hll_fd = utilsTotal.moneyToFloat(item.hll_fd) // 红绿蓝封顶
        item.str_bz_fd = utilsTotal.moneyToFloat(item.bz_fd) // 豹子封顶
        let line1 = Object.assign({}, item)
        let line2 = Object.assign({}, item)
        let objId = item.conExp ? item.conExp.ref_object_id : 0
        let oddsStr = ''
        switch (Number(objId)) {
          case 102: // 大小单双总注；
            oddsStr = '大小单双赔率' + item.str_per_charge_units + '倍'
            break
          case 105: // 组合类型总注
            oddsStr = '赔率' + item.str_per_charge_units + '倍'
            break
          case 106: // 所有类型总注
            oddsStr = '大小单双和组合类型赔率均为' + item.str_per_charge_units + '倍'
            break
        }
        line1.text = '单注' + item.str_dz_min + ' - ' + item.str_dz_max + '封顶，总注' + item.str_zz_fd + '封顶；'
        line2.text = '大小单双单注' + item.str_dxds_fd + '封顶，组合单注' + item.str_zhdz_fd + '封顶，单点单注' + item.str_dddz_fd + '封顶，极大极小单注' + item.str_jdjxdz_fd + '封顶，红绿蓝' + item.str_hll_fd + '封顶，豹子' + item.str_bz_fd + '封顶'
        item.text = item.description + '，' + oddsStr
        betLimits[item.resource_id][0] = line1
        betLimits[item.resource_id][1] = line2
        betLimits[item.resource_id].push(item)
      }
      this.betLimits = betLimits
    },
    setTianChengList(data = []) {
      if (data && data.length) {
        for (let idx = 0; idx < data.length; idx++) {
          for (let i = 0; i < data[idx].games.length; i++) {
            let item = data[idx].games[i]
            item.isOtherCP = true;
            item.play_cname = item.play_cname || item.remark;
            item.play_id = item.play_id || item.playId;
          }
        }
      }
      this.tianChengList = data
    },
    setGPIList(data = []) {
      let arr = [];
      if (data && data.length) {
        for (let i = 0; i < data.length; i++) {
          let item = data[i];
          item.remark = item.play_cname;
          item.isOtherCP = true;
          item.playId = 504;
          item.upperId = 504;
          item.sub_play_id = item.play_id; // GPI是小类id
          arr.push(item);
        }
        arr.sort((a, b) => {
          const topDiff = b.is_top - a.is_top;
          const hotDiff = b.hot_val - a.hot_val;
          if (topDiff === 0) { // 一样的话再根据 hot_val 排列
            if (hotDiff === 0) { // 一样的话再根据 sub_play_id 排列
              return b.sub_play_id - a.sub_play_id;
            } else {
              return hotDiff;
            }
          } else {
            return topDiff;
          }
        })
      }
      this.saveGPIList = arr;
    },
    setDBList(data = []) {
      let arr = [];
      if (data && data.length) {
        for (let i = 0; i < data.length; i++) {
          let item = data[i];
          item.remark = item.play_cname;
          item.isOtherCP = true;
          item.playId = 1385;
          item.upperId = 1385;
          item.sub_play_id = item.play_id; // DB是小类id
          arr.push(item);
        }
        arr.sort((a, b) => {
          const topDiff = b.is_top - a.is_top;
          const hotDiff = b.hot_val - a.hot_val;
          if (topDiff === 0) { // 一样的话再根据 hot_val 排列
            if (hotDiff === 0) { // 一样的话再根据 sub_play_id 排列
              return b.sub_play_id - a.sub_play_id;
            } else {
              return hotDiff;
            }
          } else {
            return topDiff;
          }
        })
      }
      this.saveDBList = arr;
    },
    setPC28List(data) {
      this.savePC28 = data
    },
    setPlanDetail(data) {
      this.planDetail = data
    },
    setRoomTypes(data) {
      let newTypes = Object.assign({}, this.roomTypes)
      newTypes[data.key] = data.value
      this.roomTypes = newTypes
    },
    // 切换联赛排序
    changeLeagueSortType(type) {
      this.leagueSortType = type;
    },
    // 切换联赛筛选
    setLeagueSelected(data) {
      this.leaguSelected = data;
    },
    // 设置当前详情赛事
    setCurrentMatchEvent(data) {
      this.currentMatchEvent = data;
    },
    changeLeaguListStyle(type: number) {
      this.leaguListStyle = type;
    },
    // 清除体育缓存数据
    clearSportsData() {
      this.$state = {
        ...this.$state,
        ...sportState
      }
    },
    // 清空投注选中项
    clearBetData() {
      this.currentBetData = null;
      this.cross = {};
    },
    setOriginalGamesMsg(data) {
      this.originalGamesMsg = data
    },
    setOriginalGames(data) {
      this.originalGames = data.map(item => {
        return { ...item, isOriginal: true }
      }).sort((a, b) => b.hotVal - a.hotVal)
    },
  }
})
