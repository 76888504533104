// import NetworkError from '/@/kernel-mn/network/error';
import network from '/@/kernel-mn/network';
import {useHot} from '/@/kernel-mn/stores/useHot';

export default {
  // 获取所有币种金额
  async getBalanceList(payload = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 327004,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        this.balanceList = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取所有币种金额 传名字则获得对应名字金额 也支持对应账户
  // balance_type_id: 1币币账户 2期货账户 3法币账户 4信托账户
  async getBitBalanceByName(payload = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 327046,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取信托开启的币种
  async getXinTuoBits(payload = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 327048,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        this.xinTuoBits = res.data || [];
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  // 币种买卖
  // currency_name:买卖币名字 trade_medium:媒介币种 direction:1买入 2卖出 trans_num:输入的钱数
  // trans_amount:媒介种与钱数的转换数量 handling_fee:手续费
  async bitBuyAndSell(payload = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 327026,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 币种历史记录
  // cunst_name:账号 direction可不传1买入 2卖出 start_time开始时间戳 end_time结束时间戳
  // page_start页码 num_per_page每页条数 data_type:1币币 3股票
  // 股票特有的↓
  // state;//状态 0 委托 1持仓(确认) 2取消 3卖出/平仓 4设置了止盈止损，只能后台手动平仓
  async bitHistory(payload = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_name: userInfo.value.cust_name,
      page_start: 1,
      num_per_page: 15,
      ...payload,
    };
    try {
      const res = await network.request({
        cmdId: 327025,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 股票总市值 总盈亏
  // data_type:1币币 3股票
  // 股票特有的↓
  // state;//状态 0 委托 1持仓(确认) 2取消 3卖出/平仓 4设置了止盈止损，只能后台手动平仓
  async getGPshizhi(payload = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_name: userInfo.value.cust_name,
      ...payload,
    };
    try {
      const res = await network.request({
        cmdId: 3270225,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 杠杆现货币种持仓
  // cunst_name:账号 page_start页码 num_per_page每页条数 currency_name币种名称
  async GGBitCicang(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_name: userInfo.value.cust_name,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10,
    };
    try {
      const res = await network.request({
        cmdId: 327144,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 杠杆现货币种委托
  // state 状态 委托=0，历史委托=1,平仓记录=2
  // cunst_name:账号 page_start页码 num_per_page每页条数 currency_name币种名称
  async getGGBitWeituo(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_name: userInfo.value.cust_name,
      state: payload.state || 0,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10,
    };
    try {
      const res = await network.request({
        cmdId: 327143,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 杠杆现货币种撤销
  // aseq_id 订单id
  async reqGGBitCancel(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      aseq_id: payload.aseq_id || 0,
    };
    try {
      const res = await network.request({
        cmdId: 327147,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 杠杆现货币种 杠杆设置
  // aseq_id 订单id select_lever 杠杆
  async setGGBitGanggan(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      aseq_id: payload.aseq_id || 0,
      select_lever: payload.select_lever || 1,
    };
    try {
      const res = await network.request({
        cmdId: 327146,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 杠杆现货币种 设置止盈止损
  // aseq_id 订单id stop_surplus:止盈 stop_loss:止损
  async setGGSurplusAndLoss(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      state: payload.aseq_id || 0,
      stop_surplus: payload.stop_surplus || '',
      stop_loss: payload.stop_loss || '',
    };
    try {
      const res = await network.request({
        cmdId: 327145,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 期权 股票买卖
  // currency_name:股票代码 trade_medium:媒介币种 direction:1买入 2卖出 trans_price:买入价格 trans_num:输入的钱数
  // trans_amount:最终计算出来的钱 包括买入金额 手续费 印花税 handling_fee:手续费 data_type:1 币币，3股票
  // currency_cname:股票名称 select_lever:选择的杠杆倍数 || 1 stamp_duty:印花税 state:状态0委托
  async GPBuyAndSell(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_name: userInfo.value.cust_name,
      currency_name: payload.currency_name,
      trade_medium: payload.trade_medium,
      direction: payload.direction,
      trans_price: payload.trans_price,
      trans_num: payload.trans_num,
      trans_amount: payload.trans_amount,
      handling_fee: payload.handling_fee,
      data_type: payload.data_type,
      currency_cname: payload.currency_cname,
      select_lever: payload.select_lever || 1,
      stamp_duty: payload.stamp_duty,
      state: payload.state || 0,
    };
    try {
      const res = await network.request({
        cmdId: 327126,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 期权 更改股票订单状态
  // order_number:订单号, state;//状态 0 委托 1持仓(确认) 2取消 3卖出/平仓 4设置了止盈止损，只能后台手动平仓
  async setGPOrderState(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      order_number: payload.order_number,
      state: payload.state,
    };
    try {
      const res = await network.request({
        cmdId: 327127,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 期权 更改股票止盈止损
  // order_number:订单号, stop_surplus:止盈 stop_loss:止损
  async stopSurplusOrLoss(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      order_number: payload.order_number,
      stop_surplus: payload.stop_surplus || 0,
      stop_loss: payload.stop_loss || 0,
    };
    try {
      const res = await network.request({
        cmdId: 327129,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 期权 股票卖出
  // order_number:订单号, selling_price:卖出价格 selling_trans_num:卖出数量
  async gpOrderSell(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      order_number: payload.order_number,
      selling_price: payload.selling_price,
      selling_trans_num: payload.selling_trans_num || 0,
      is_front_sell: payload.is_front_sell || 1, // 区分前台还是后台卖出
    };
    try {
      const res = await network.request({
        cmdId: 327128,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 期权 新股申购
  // currency_name;股票代码 currency_cname股票名称 sg_name申购代码 send_price发行价格
  // listing_date上市日期 subscription_quantity申购数量[手数] deduction_date扣款日 top_sub:1顶格申购
  async newGPBuy(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      currency_name: payload.currency_name,
      currency_cname: payload.currency_cname,
      sg_name: payload.sg_name,
      send_price: payload.send_price,
      listing_date: payload.listing_date,
      subscription_quantity: payload.subscription_quantity,
      deduction_date: payload.deduction_date,
      trade_medium: payload.trade_medium,
      sg_date: payload.sg_date, // 申购日期
      zq_date: payload.zq_date, // 中签日期
    };
    try {
      const res = await network.request({
        cmdId: 327133,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 期权 新股申购历史记录
  //
  // page_start页码 num_per_page每页条数
  async newGPBuyOrder(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 15,
    };
    try {
      const res = await network.request({
        cmdId: 327134,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 转换所有余额
   * @param @requires payload.type 1 只回收上分记录的第三方
   */
  async reqTransitionAllYe(payload = {}) {
    const {hotData} = useHot()
    const {tenantInfo, userInfo} = hotData
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      type: 1
    }
    try {
      const params = {
        cmdId: 391138,
        data: data,
        headerType: 2,
        paramType: 0,
      }
      const res: any = await network.request(params)
      if (res && res.type === 'success') {
        return Promise.resolve(res.data)
      } else {
        return Promise.reject(res.data)
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },
  // 账变明细
  // start_time end_time page_start num_per_page trans_type
  async reqBalanceLogs(payload: any = {}) {
    const {hotData} = useHot()
    const {tenantInfo, userInfo} = hotData
    let data: any = {
      ...payload,
      tid: tenantInfo.value.tid,
      acct_id: userInfo.value.acct_id,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10,
      trans_type: payload.trans_type,
    }
    if (payload.sd_flag) { // 刷单项目的账变记录需要传1 其他项目 0
      data.sd_flag = payload.sd_flag
    }
    if (payload.trans_type && payload.trans_type !== '0') {
      data.trans_type = []
      data.trans_type[0] = payload.trans_type
    } else {
      delete data.trans_type
    }
    try {
      const res = await network.request({
        cmdId: 340333,
        data: data,
        headerType: 2,
        paramType: 0
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data)
      } else {
        return Promise.reject(res.data)
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },
  // 币种杠杆买卖
  // currency_name:买卖币名字 trade_medium:媒介币种 weituo:委托类型 1.限价委托  2 市价委托
  // direction:1开多 2开空 3平多 4平空 select_lever:杠杆倍数 trans_type//全仓 1，逐仓 2
  // trans_price买入单价 trans_num:成交数量 trans_amount成交金额 qp_amount预计强平价格
  // stop_surplus:止盈 stop_loss:止损
  async bitGGBuyAndSell(payload = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_name: userInfo.value.cust_name,
    };
    try {
      const res = await network.request({
        cmdId: 327141,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 期权 新币申购
  // currency_name;股票代码 currency_cname股票名称 sg_name申购代码 send_price发行价格
  // listing_date上市日期 subscription_quantity申购数量[手数] deduction_date扣款日 top_sub:1顶格申购
  // trade_medium媒介币种 "sg_date": "2023-01-16", "zq_date": "2023-01-18", "data_type": 4,
  async newBitBuy(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      currency_name: payload.currency_name,
      currency_cname: payload.currency_cname,
      sg_name: payload.sg_name,
      send_price: payload.send_price,
      listing_date: payload.listing_date,
      subscription_quantity: payload.subscription_quantity,
      deduction_date: payload.deduction_date,
      trade_medium: payload.trade_medium,
      sg_date: payload.sg_date, // 申购日期
      zq_date: payload.zq_date, // 中签日期
      data_type: payload.data_type || 4,
    };
    try {
      const res = await network.request({
        cmdId: 3271701,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 期权 新币申购历史记录
  // page_start页码 num_per_page每页条数
  async newBitBuyOrder(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 15,
    };
    try {
      const res = await network.request({
        cmdId: 3271726,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 指数项目 指数买卖
  // currency_name:股票代码 trade_medium:媒介币种 direction:1买入 2卖出 trans_price:买入价格 trans_num:输入的钱数
  // trans_amount:最终计算出来的钱 包括买入金额 手续费 印花税 handling_fee:手续费 data_type:1 币币，3股票
  // currency_cname:股票名称 state:状态0委托
  async ZSBuyAndSell(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo, userInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_name: userInfo.value.cust_name,
      currency_name: payload.currency_name,
      trade_medium: payload.trade_medium,
      direction: payload.direction,
      trans_price: payload.trans_price,
      trans_num: payload.trans_num,
      trans_amount: payload.trans_amount,
      handling_fee: payload.handling_fee,
      data_type: payload.data_type,
      currency_cname: payload.currency_cname,
      state: payload.state || 0,
    };
    try {
      const res = await network.request({
        cmdId: 420002,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 指数项目 指数卖出
  // order_number:订单号, selling_price:卖出价格 selling_trans_num:卖出数量
  async ZSOrderSell(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      order_number: payload.order_number,
      selling_price: payload.selling_price,
      selling_trans_num: payload.selling_trans_num || 0,
    };
    try {
      const res = await network.request({
        cmdId: 420004,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 指数项目 更改指数订单状态
  // order_number:订单号, state;//状态 0 委托 1持仓(确认) 2取消 3卖出/平仓 4设置了止盈止损，只能后台手动平仓
  async setZSOrderState(payload: any = {}) {
    const {hotData} = useHot();
    const {tenantInfo} = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      order_number: payload.order_number,
      state: payload.state,
    };
    try {
      const res = await network.request({
        cmdId: 420003,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
};