/* 北京28 */
export default {
  plays: {
    0: {
      rule_id: 40000,
      area_id: 40000,
      play_name: "PTTZ",
      play_cname: "猜数字",
      special: false,
      unit_price: 1,
      note_tag: "count",
    },
    1: {
      rule_id: 40001,
      area_id: 40001,
      play_name: "DXDS",
      play_cname: "大小单双",
      special: false,
      unit_price: 1,
      note_tag: "count",
    },
    2: {
      rule_id: 40002,
      area_id: 40002,
      play_name: "BOSE",
      play_cname: "波色",
      special: true,
      unit_price: 1,
      note_tag: "count",
    },
    3: {
      rule_id: 40003,
      area_id: 40003,
      play_name: "BAOZI",
      play_cname: "豹子",
      special: true,
      unit_price: 1,
      note_tag: "count",
    },
    4: {
      rule_id: 40004,
      area_id: 40004,
      play_name: "TEMABAO3",
      play_cname: "特码",
      special: true,
      unit_price: 1,
      note_tag: "count",
    },
    "5": {
      rule_id: 40005,
      area_id: 40005,
      play_name: "D1Q",
      play_cname: "第一球",
      special: true,
      unit_price: 1,
      note_tag: "count",
    },
    "6": {
      rule_id: 40006,
      area_id: 40006,
      play_name: "D2Q",
      play_cname: "第二球",
      special: true,
      unit_price: 1,
      note_tag: "count",
    },
    "7": {
      rule_id: 40007,
      area_id: 40007,
      play_name: "D3Q",
      play_cname: "第三球",
      special: true,
      unit_price: 1,
      note_tag: "count",
    },
    "8": {
      rule_id: 40008,
      area_id: 40008,
      play_name: "SHUNZI",
      play_cname: "顺子",
      special: true,
      unit_price: 1,
      note_tag: "count",
    },
    "9": {
      rule_id: 40009,
      area_id: 40009,
      play_name: "DUIZI",
      play_cname: "对子",
      special: true,
      unit_price: 1,
      note_tag: "count",
    },
  },
};
