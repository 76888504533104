import config from "../config"

const busiTypes = [
  { busi_type: 410, text: '利息' },
  { busi_type: 241, text: '第三方异常金币返还' },
  { busi_type: 923, text: '幸运注单' },
  { busi_type: 408, text: '钱包到余额宝' },
  { busi_type: 409, text: '余额宝到钱包' },
  { busi_type: 406, text: '充值大礼包' },
  { busi_type: 407, text: '天天得意金' },
  { busi_type: 405, text: '领取亏损金额' },
  { busi_type: 412, text: '分享领工资' },
  { busi_type: 710, text: '充值优惠-渠道优惠' },
  { busi_type: 711, text: '充值优惠-渠道优惠' },
  { busi_type: 703, text: '晋级奖励' },
  { busi_type: 702, text: '周俸禄' },
  { busi_type: 704, text: '月俸禄' },
  { busi_type: 404, text: '签到奖金' },
  { busi_type: 416, text: '虚拟币活动' },
  { busi_type: 900, text: '提现审核' },
  { busi_type: 901, text: '提现拒绝' },
  { busi_type: 902, text: '提现成功' },
  { busi_type: 100, text: '购彩支付' },
  { busi_type: 101, text: '方案保底' },
  { busi_type: 102, text: '购彩返还' },
  { busi_type: 103, text: '保底返还' },
  { busi_type: 104, text: '追号返还' },
  { busi_type: 105, text: '和局返还' },
  { busi_type: 106, text: '和局追号返还' },
  { busi_type: 110, text: '打码量扣减' },
  { busi_type: 130, text: '购买套餐' },
  { busi_type: 131, text: '套餐返还' },
  { busi_type: 132, text: '发红包' },
  { busi_type: 200, text: '预存充值' },
  { busi_type: 201, text: '充值返还' },
  { busi_type: 210, text: '钱包到AG' },
  { busi_type: 211, text: 'AG到钱包' },
  { busi_type: 212, text: '钱包到开元棋牌' },
  { busi_type: 213, text: '开元棋牌到钱包' },
  { busi_type: 214, text: '钱包到乐游棋牌' },
  { busi_type: 215, text: '乐游棋牌到钱包' },
  { busi_type: 216, text: '立马高视讯到钱包' },
  { busi_type: 217, text: '钱包到立马高视讯' },
  { busi_type: 218, text: 'JDB到钱包' },
  { busi_type: 219, text: '钱包到JDB' },
  { busi_type: 220, text: '沙巴体育到钱包' },
  { busi_type: 221, text: '钱包到沙巴体育' },
  { busi_type: 335, text: 'Sexy体育到钱包' },
  { busi_type: 334, text: '钱包到Sexy体育' },
  { busi_type: 331, text: '王者棋牌到钱包' },
  { busi_type: 330, text: '钱包到王者棋牌' },
  { busi_type: 327, text: '大唐棋牌到钱包' },
  { busi_type: 326, text: '钱包到大唐棋牌' },
  { busi_type: 222, text: 'BBIN到钱包' },
  { busi_type: 223, text: '钱包到BBIN' },
  { busi_type: 224, text: 'MG到钱包' },
  { busi_type: 225, text: '钱包到MG' },
  { busi_type: 226, text: 'IG电子游戏到钱包' },
  { busi_type: 227, text: '钱包到IG电子游戏' },
  { busi_type: 228, text: '大富翁棋牌到钱包' },
  { busi_type: 229, text: '钱包到大富翁棋牌' },
  { busi_type: 233, text: '财神棋牌到钱包' },
  { busi_type: 234, text: '钱包到财神棋牌' },
  { busi_type: 235, text: 'BG捕鱼到钱包' },
  { busi_type: 236, text: '钱包到BG捕鱼' },
  { busi_type: 237, text: '欢乐棋牌到钱包' },
  { busi_type: 238, text: '钱包到欢乐棋牌' },
  { busi_type: 245, text: '天游到钱包' },
  { busi_type: 246, text: '钱包到天游' },
  { busi_type: 239, text: '天美棋牌到钱包' },
  { busi_type: 240, text: '钱包到天美棋牌' },
  { busi_type: 247, text: '博乐棋牌到钱包' },
  { busi_type: 248, text: '钱包到博乐棋牌' },
  { busi_type: 249, text: 'BTI体育到钱包' },
  { busi_type: 250, text: '钱包到BTI体育' },
  { busi_type: 251, text: '天境棋牌到钱包' },
  { busi_type: 252, text: '钱包到天境棋牌' },
  { busi_type: 253, text: 'DG真人到钱包' },
  { busi_type: 254, text: '钱包到DG真人' },
  { busi_type: 255, text: '百胜到钱包' },
  { busi_type: 256, text: '钱包到百胜' },
  { busi_type: 230, text: '套餐卡充值' },
  { busi_type: 231, text: '聊天室红包' },
  { busi_type: 232, text: '红包返还' },
  { busi_type: 257, text: 'CQ9到钱包' },
  { busi_type: 258, text: '钱包到CQ9' },
  { busi_type: 259, text: 'BBIN电子到钱包' },
  { busi_type: 260, text: '钱包到BBIN电子' },
  { busi_type: 270, text: '钱包到新世界棋牌' },
  { busi_type: 271, text: '新世界棋牌到钱包' },
  { busi_type: 272, text: '钱包到DT' },
  { busi_type: 273, text: 'DT到钱包' },
  { busi_type: 274, text: 'BG视讯到钱包' },
  { busi_type: 275, text: '钱包到BG视讯' },
  { busi_type: 276, text: '钱包到皇冠体育' },
  { busi_type: 277, text: '皇冠体育到钱包' },
  { busi_type: 278, text: '钱包到PT电子' },
  { busi_type: 279, text: 'PT电子到钱包' },
  { busi_type: 280, text: '钱包到胜博体育' },
  { busi_type: 281, text: '胜博体育到钱包' },
  { busi_type: 282, text: '钱包到美博棋牌' },
  { busi_type: 283, text: '美博棋牌到钱包' },
  { busi_type: 284, text: '钱包到QG红包' },
  { busi_type: 285, text: 'QG红包到钱包' },
  { busi_type: 286, text: '钱包到IG真人' },
  { busi_type: 287, text: 'IG真人到钱包' },
  { busi_type: 288, text: '钱包到欧博真人' },
  { busi_type: 289, text: '欧博真人到钱包' },
  { busi_type: 290, text: '钱包到雷火电竞' },
  { busi_type: 291, text: '雷火电竞到钱包' },
  { busi_type: 292, text: '钱包到IBO真人' },
  { busi_type: 293, text: 'IBO真人到钱包' },
  { busi_type: 294, text: '钱包到天成彩票' },
  { busi_type: 295, text: '天成彩票到钱包' },
  { busi_type: 296, text: '钱包到S128斗鸡' },
  { busi_type: 297, text: 'S128斗鸡到钱包' },
  { busi_type: 298, text: '钱包到GPI彩票' },
  { busi_type: 299, text: 'GPI彩票到钱包' },
  { busi_type: 348, text: '钱包到kingmaker' },
  { busi_type: 347, text: 'kingmaker到钱包' },
  { busi_type: 304, text: '钱包到EVOPlay电子' },
  { busi_type: 305, text: 'EVOPlay电子到钱包' },
  { busi_type: 306, text: '钱包到PP电子' },
  { busi_type: 307, text: 'PP电子到钱包' },
  { busi_type: 319, text: 'SV388斗鸡到钱包' },
  { busi_type: 318, text: '钱包到SV388斗鸡' },
  { busi_type: 308, text: '钱包到kingpoker' },
  { busi_type: 309, text: 'kingpoker到钱包' },
  { busi_type: 300, text: '中奖' },
  { busi_type: 301, text: '提成' },
  { busi_type: 302, text: '中奖返还' },
  { busi_type: 847, text: '钱包到DB棋牌' },
  { busi_type: 848, text: 'DB棋牌到钱包' },
  { busi_type: 795, text: 'DB棋牌自身返点' },
  { busi_type: 796, text: 'DB棋牌代理返点' },
  { busi_type: 254, text: '开通DB棋牌' },
  { busi_type: 256, text: '开通DB彩票' },
  { busi_type: 480, text: '钱包到DB彩票' },
  { busi_type: 481, text: 'DB彩票到钱包' },
  { busi_type: 950, text: 'DB彩票自身返点' },
  { busi_type: 951, text: 'DB彩票代理返点' },
  { busi_type: 303, text: '提成返还' },
  { busi_type: 310, text: '钱包到SBO利记体育' },
  { busi_type: 311, text: 'SBO利记体育到钱包' },
  { busi_type: 312, text: '钱包到PG电子' },
  { busi_type: 313, text: 'PG电子到钱包' },
  { busi_type: 648, text: 'PG电子自身返点' },
  { busi_type: 649, text: 'PG电子代理返点' },
  { busi_type: 350, text: '回水' },
  { busi_type: 351, text: '取消回水' },
  { busi_type: 398, text: '充值优惠-手工' },
  { busi_type: 400, text: '充值优惠' },
  { busi_type: 401, text: '赠金返还' },
  { busi_type: 402, text: '注册送彩金' },
  { busi_type: 403, text: '体验金返还' },
  { busi_type: 411, text: '游戏任务' },
  { busi_type: 413, text: '代理返点-手工' },
  { busi_type: 414, text: '返水优惠-手工' },
  { busi_type: 415, text: '其他' },
  { busi_type: 417, text: '会员任务' },
  { busi_type: 800, text: '打赏' },
  { busi_type: 801, text: '取消打赏' },
  { busi_type: 802, text: '代理转账转入' },
  { busi_type: 803, text: '转点下级返还' },
  { busi_type: 804, text: '普通转账' },
  { busi_type: 805, text: '普通转账返还' },
  { busi_type: 806, text: '代理转账转出' },
  { busi_type: 808, text: '代理转账转出' },
  { busi_type: 809, text: '代理转账转入' },
  { busi_type: 700, text: '手工调整余额' },
  { busi_type: 701, text: '手工充值' },
  { busi_type: 500, text: '代理工资' },
  { busi_type: 501, text: '代理返点' },
  { busi_type: 502, text: '代理分红' },
  { busi_type: 503, text: '购彩返点' },
  { busi_type: 504, text: 'AG真人自身返点' },
  { busi_type: 505, text: 'AG真人代理返点' },
  { busi_type: 506, text: '开元棋牌自身返点' },
  { busi_type: 508, text: '乐游棋牌自身返点' },
  { busi_type: 507, text: '开元棋牌代理返点' },
  { busi_type: 509, text: '乐游棋牌代理返点' },
  { busi_type: 510, text: '立马高视讯自身返点' },
  { busi_type: 511, text: '立马高视讯代理返点' },
  { busi_type: 512, text: 'JDB电子自身返点' },
  { busi_type: 513, text: 'JDB电子代理返点' },
  { busi_type: 514, text: '沙巴体育自身返点' },
  { busi_type: 515, text: '沙巴体育代理返点' },
  { busi_type: 678, text: 'Sexy体育自身返点' },
  { busi_type: 679, text: 'Sexy体育代理返点' },
  { busi_type: 516, text: 'BBIN真人自身返点' },
  { busi_type: 517, text: 'BBIN真人代理返点' },
  { busi_type: 518, text: 'MG电子自身返点' },
  { busi_type: 519, text: 'MG电子代理返点' },
  { busi_type: 520, text: 'IG电子游戏自身返点' },
  { busi_type: 521, text: 'IG电子游戏代理返点' },
  { busi_type: 522, text: 'AG捕鱼自身返点' },
  { busi_type: 523, text: 'AG捕鱼代理返点' },
  { busi_type: 524, text: '大富翁棋牌自身返点' },
  { busi_type: 525, text: '大富翁棋牌代理返点' },
  { busi_type: 526, text: '财神棋牌自身返点' },
  { busi_type: 527, text: '财神棋牌代理返点' },
  { busi_type: 528, text: 'BG捕鱼自身返点' },
  { busi_type: 529, text: 'BG捕鱼代理返点' },
  { busi_type: 530, text: '欢乐棋牌自身返点' },
  { busi_type: 531, text: '欢乐棋牌代理返点' },
  { busi_type: 532, text: '西游捕鱼自身返点' },
  { busi_type: 533, text: '西游捕鱼代理返点' },
  { busi_type: 536, text: '天游棋牌自身返点' },
  { busi_type: 537, text: '天游棋牌代理返点' },
  { busi_type: 534, text: '天美棋牌自身返点' },
  { busi_type: 535, text: '天美棋牌代理返点' },
  { busi_type: 538, text: '博乐棋牌自身返点' },
  { busi_type: 539, text: '博乐棋牌代理返点' },
  { busi_type: 540, text: 'BIT体育自身返点' },
  { busi_type: 541, text: 'BIT体育代理返点' },
  { busi_type: 542, text: 'MG捕鱼自身返点' },
  { busi_type: 543, text: 'MG捕鱼代理返点' },
  { busi_type: 544, text: 'JDB捕鱼自身返点' },
  { busi_type: 545, text: 'JDB捕鱼代理返点' },
  { busi_type: 546, text: '天游捕鱼自身返点' },
  { busi_type: 547, text: '天游捕鱼代理返点' },
  { busi_type: 548, text: '百胜棋牌自身返点' },
  { busi_type: 549, text: '百胜棋牌代理返点' },
  { busi_type: 672, text: '王者棋牌自身返点' },
  { busi_type: 673, text: '王者棋牌代理返点' },
  { busi_type: 174, text: '开通大唐棋牌' },
  { busi_type: 666, text: '大唐棋牌自身返点' },
  { busi_type: 667, text: '大唐棋牌代理返点' },
  { busi_type: 550, text: 'DG真人自身返点' },
  { busi_type: 551, text: 'DG真人代理返点' },
  { busi_type: 183, text: '开通TRC赛马' },
  { busi_type: 345, text: '钱包到TRC赛马' },
  { busi_type: 346, text: 'TRC赛马到钱包' },
  { busi_type: 688, text: 'TRC赛马自身返点' },
  { busi_type: 689, text: 'TRC赛马代理返点' },
  { busi_type: 187, text: '开通365棋牌' },
  { busi_type: 357, text: '钱包到365棋牌' },
  { busi_type: 358, text: '365棋牌到钱包' },
  { busi_type: 690, text: '365棋牌自身返点' },
  { busi_type: 691, text: '365棋牌代理返点' },
  { busi_type: 181, text: '开通XG真人' },
  { busi_type: 340, text: '钱包到XG真人' },
  { busi_type: 341, text: 'XG真人到钱包' },
  { busi_type: 684, text: 'XG真人自身返点' },
  { busi_type: 685, text: 'XG真人代理返点' },
  { busi_type: 193, text: '开通EVO真人' },
  { busi_type: 375, text: '钱包到EVO真人' },
  { busi_type: 376, text: 'EVO真人到钱包' },
  { busi_type: 725, text: 'EVO真人自身返点' },
  { busi_type: 906, text: 'EVO真人代理返点' },
  { busi_type: 650, text: 'ebet真人自身返点' },
  { busi_type: 651, text: 'ebet真人代理返点' },
  { busi_type: 165, text: '开通ebet真人' },
  { busi_type: 314, text: '钱包到ebet真人' },
  { busi_type: 315, text: 'ebet真人到钱包' },
  { busi_type: 508, text: 'ebet真人自助返水类型' },
  { busi_type: 552, text: '新世界棋牌自身返点' },
  { busi_type: 553, text: '新世界棋牌代理返点' },
  { busi_type: 554, text: '天境棋牌自身返点' },
  { busi_type: 555, text: '天境棋牌代理返点' },
  { busi_type: 556, text: 'BG真人自身返点' },
  { busi_type: 557, text: 'BG真人代理返点' },
  { busi_type: 558, text: '百胜捕鱼自身返点' },
  { busi_type: 559, text: '百胜捕鱼代理返点' },
  { busi_type: 340, text: '钱包到大唐捕鱼' },
  { busi_type: 341, text: '大唐捕鱼到钱包' },
  { busi_type: 668, text: '大唐捕鱼自身返点' },
  { busi_type: 667, text: '大唐捕鱼代理返点' },
  { busi_type: 560, text: 'DT电子自身返点' },
  { busi_type: 561, text: 'DT电子代理返点' },
  { busi_type: 562, text: 'CQ9电子自身返点' },
  { busi_type: 563, text: 'CQ9电子代理返点' },
  { busi_type: 564, text: 'CQ9捕鱼自身返点' },
  { busi_type: 565, text: 'CQ9捕鱼代理返点' },
  { busi_type: 566, text: 'BG视讯自身返点' },
  { busi_type: 567, text: 'BG视讯代理返点' },
  { busi_type: 568, text: 'MG视讯自身返点' },
  { busi_type: 569, text: 'MG视讯代理返点' },
  { busi_type: 572, text: 'PT电子自身返点' },
  { busi_type: 573, text: 'PT电子代理返点' },
  { busi_type: 574, text: 'AG电子自身返点' },
  { busi_type: 575, text: 'AG电子代理返点' },
  { busi_type: 576, text: 'AGYP电子自身返点' },
  { busi_type: 577, text: 'AGYP电子代理返点' },
  { busi_type: 578, text: 'BBIN电子自身返点' },
  { busi_type: 579, text: 'BBIN电子代理返点' },
  { busi_type: 888, text: '红包雨红包' },
  { busi_type: 567, text: 'BG视讯代理返点' },
  { busi_type: 570, text: '皇冠体育自身返点' },
  { busi_type: 571, text: '皇冠体育代理返点' },
  { busi_type: 580, text: '胜博体育自身返点' },
  { busi_type: 581, text: '胜博体育代理返点' },
  { busi_type: 582, text: '美博棋牌自身返点' },
  { busi_type: 583, text: '美博棋牌代理返点' },
  { busi_type: 584, text: 'QG红包自身返点' },
  { busi_type: 585, text: 'QG红包代理返点' },
  { busi_type: 586, text: 'IG真人自身返点' },
  { busi_type: 587, text: 'IG真人代理返点' },
  { busi_type: 588, text: '欧博真人自身返点' },
  { busi_type: 589, text: '欧博真人代理返点' },
  { busi_type: 590, text: '雷火电竞自身返点' },
  { busi_type: 591, text: '雷火电竞代理返点' },
  { busi_type: 592, text: 'IBO真人自身返点' },
  { busi_type: 593, text: 'IBO真人代理返点' },
  { busi_type: 594, text: '天成彩票自身返点' },
  { busi_type: 595, text: '天成彩票代理返点' },
  { busi_type: 596, text: 'S128斗鸡自身返点' },
  { busi_type: 597, text: 'S128斗鸡代理返点' },
  { busi_type: 598, text: 'GPI彩票自身返点' },
  { busi_type: 599, text: 'GPI彩票代理返点' },
  { busi_type: 613, text: '答题赢彩金' },
  { busi_type: 638, text: 'kingmaker自身返点' },
  { busi_type: 639, text: 'kingmaker代理返点' },
  { busi_type: 640, text: 'EVOPlay电子自身返点' },
  { busi_type: 641, text: 'EVOPlay电子代理返点' },
  { busi_type: 642, text: 'PP电子自身返点' },
  { busi_type: 646, text: 'SBO利记体育个人返点' },
  { busi_type: 647, text: 'SBO利记体育代理返点' },
  { busi_type: 643, text: 'PP电子代理返点' },
  { busi_type: 644, text: 'kingpoker自身返点' },
  { busi_type: 645, text: 'kingpoker代理返点' },
  { busi_type: 911, text: '借呗借款' },
  { busi_type: 912, text: '借呗还款' },
  { busi_type: 913, text: '系统回收' },
  { busi_type: 914, text: '系统赎回' },
  { busi_type: 915, text: '系统赎回冻结' },
  { busi_type: 916, text: '系统赎回解冻' },
  { busi_type: 917, text: '玩家购买' },
  { busi_type: 918, text: '玩家购买冻结' },
  { busi_type: 919, text: '玩家购买解冻' },
  { busi_type: 997, text: '负盈利代理返点' },
  { busi_type: 998, text: '充值代理返点' },
  { busi_type: 999, text: '自助返水' },
  { busi_type: 712, text: '充值优惠-渠道优惠' },
  { busi_type: 399, text: '首充送' },
  { busi_type: 920, text: '强制撤单' },
  { busi_type: 921, text: '结算回滚' },
  { busi_type: 922, text: '重新计算' },
  { busi_type: 317, text: 'UPG到钱包' },
  { busi_type: 316, text: '钱包到UPG' },
  { busi_type: 924, text: '奖金派送' },
  { busi_type: 320, text: '钱包到FG' },
  { busi_type: 321, text: 'FG到钱包' },
  { busi_type: 188, text: '开通JILI' },
  { busi_type: 361, text: '钱包到JILI' },
  { busi_type: 362, text: 'JILI到钱包' },
  { busi_type: 698, text: 'JILI电子自身返点' },
  { busi_type: 699, text: 'JILI电子代理返点' },
  { busi_type: 696, text: 'JILI棋牌自身返点' },
  { busi_type: 697, text: 'JILI棋牌代理返点' },
  { busi_type: 188, text: '开通EA板球' },
  { busi_type: 370, text: '钱包到EA板球' },
  { busi_type: 371, text: 'EA板球到钱包' },
  { busi_type: 720, text: 'EA板球自身返点' },
  { busi_type: 721, text: 'EA板球代理返点' },
  { busi_type: 191, text: '开通IM体育' },
  { busi_type: 931, text: 'IM体育投注扣款' },
  { busi_type: 932, text: 'IM体育注单结算' },
  { busi_type: 903, text: 'IM体育自身返点' },
  { busi_type: 904, text: 'IM体育代理返点' },
  { busi_type: 195, text: '开通IM电竞' },
  { busi_type: 372, text: 'IM电竞投注扣款' },
  { busi_type: 373, text: 'IM电竞投注结算' },
  { busi_type: 728, text: 'IM电竞自身返点' },
  { busi_type: 729, text: 'IM电竞代理返点' },
  { busi_type: 262, text: '开通DB电竞' },
  { busi_type: 865, text: 'DB电竞投注扣款' },
  { busi_type: 864, text: 'DB电竞投注结算' },
  { busi_type: 817, text: 'DB电竞自身返点' },
  { busi_type: 818, text: 'DB电竞代理返点' },
  { busi_type: 190, text: '开通PS电子' },
  { busi_type: 359, text: '钱包到PS电子' },
  { busi_type: 360, text: 'PS电子到钱包' },
  { busi_type: 694, text: 'PS电子自身返点' },
  { busi_type: 695, text: 'PS电子代理返点' },
  { busi_type: 203, text: '开通完美真人' },
  { busi_type: 458, text: '钱包到完美真人' },
  { busi_type: 459, text: '完美真人到钱包' },
  { busi_type: 743, text: '完美真人自身返点' },
  { busi_type: 744, text: '完美真人代理返点' },
  { busi_type: 197, text: '开通开元体育' },
  { busi_type: 389, text: '钱包到开元体育' },
  { busi_type: 390, text: '开元体育到钱包' },
  { busi_type: 731, text: '开元体育自身返点' },
  { busi_type: 732, text: '开元体育代理返点' },
  { busi_type: 202, text: '开通FB体育' },
  { busi_type: 456, text: '钱包到FB体育' },
  { busi_type: 457, text: 'FB体育到钱包' },
  { busi_type: 741, text: 'FB体育自身返点' },
  { busi_type: 742, text: 'FB体育代理返点' },
  { busi_type: 192, text: '开通VT电子' },
  { busi_type: 363, text: '钱包到VT电子' },
  { busi_type: 364, text: 'VT电子到钱包' },
  { busi_type: 722, text: 'VT电子自身返点' },
  { busi_type: 723, text: 'VT电子代理返点' },
  { busi_type: 194, text: '开通RT电子' },
  { busi_type: 367, text: '钱包到RT电子' },
  { busi_type: 368, text: 'RT电子到钱包' },
  { busi_type: 726, text: 'RT电子自身返点' },
  { busi_type: 727, text: 'RT电子代理返点' },
  { busi_type: 184, text: '开通AMEBA电子' },
  { busi_type: 355, text: '钱包到Ameba电子' },
  { busi_type: 356, text: 'Ameba电子到钱包' },
  { busi_type: 692, text: 'Ameba电子自身返点' },
  { busi_type: 693, text: 'Ameba电子代理返点' },
  { busi_type: 185, text: '开通SIMPLEPLAY电子' },
  { busi_type: 343, text: '钱包到SIMPLEPLAY电子' },
  { busi_type: 344, text: 'SIMPLEPLAY电子到钱包' },
  { busi_type: 686, text: 'SimplePlay电子自身返点' },
  { busi_type: 687, text: 'SimplePlay电子代理返点' },
  { busi_type: 656, text: 'FG电子自身返点' },
  { busi_type: 657, text: 'FG电子代理返点' },
  { busi_type: 658, text: 'FG捕鱼自身返点' },
  { busi_type: 659, text: 'FG捕鱼代理返点' },
  { busi_type: 660, text: 'FG棋牌自身返点' },
  { busi_type: 661, text: 'FG棋牌代理返点' },
  { busi_type: 322, text: '钱包到GLG视讯' },
  { busi_type: 323, text: 'GLG视讯到钱包' },
  { busi_type: 662, text: 'GLG视讯自身返点' },
  { busi_type: 663, text: 'GLG视讯代理返点' },
  { busi_type: 926, text: '活动奖金' },
  { busi_type: 420, text: '异常加减分' },
  { busi_type: 418, text: '幸运大转盘' },
  { busi_type: 618, text: '商品购买冻结金额' },
  { busi_type: 619, text: '商品奖励' },
  { busi_type: 620, text: '佣金奖励' },
  { busi_type: 621, text: '上一级返佣' },
  { busi_type: 622, text: '上二级返佣' },
  { busi_type: 623, text: '上三级返佣' },
  { busi_type: 624, text: '商品购买解冻金额' },
  { busi_type: 625, text: '钱包到虚拟货币挂机' },
  { busi_type: 626, text: '虚拟货币挂机到钱包' },
  { busi_type: 627, text: '虚拟货币挂机盈利到钱包' },
  { busi_type: 628, text: '平台手续费' },
  { busi_type: 629, text: '交易手续费' },
  { busi_type: 630, text: '虚拟币一级返佣' },
  { busi_type: 631, text: '虚拟币二级返佣' },
  { busi_type: 632, text: '虚拟币三级返佣' },
  { busi_type: 633, text: '虚拟币四级返佣' },
  { busi_type: 634, text: '虚拟币五级返佣' },
  { busi_type: 821, text: '购买理财' },
  { busi_type: 822, text: '理财赎回本金' },
  { busi_type: 823, text: '理财赎回手续费扣除' },
  { busi_type: 824, text: '理财利息' },
  { busi_type: 825, text: '体验金派送' },
  { busi_type: 826, text: '体验金回收' },
  { busi_type: 324, text: '钱包到HC电子' },
  { busi_type: 325, text: 'HC电子到钱包' },
  { busi_type: 147, text: 'CRASH游戏下单' },
  { busi_type: 148, text: 'CRASH取消下单' },
  { busi_type: 149, text: 'CRASH中奖派送' },
  { busi_type: 205, text: '开通BNG电子' },
  { busi_type: 453, text: '钱包到BNG电子' },
  { busi_type: 454, text: 'BNG电子到钱包' },
  { busi_type: 747, text: 'BNG电子自身返点' },
  { busi_type: 748, text: 'BNG电子代理返点' },
  { busi_type: 204, text: '开通YGG电子' },
  { busi_type: 460, text: '钱包到YGG电子' },
  { busi_type: 461, text: 'YGG电子到钱包' },
  { busi_type: 749, text: 'YGG电子自身返点' },
  { busi_type: 750, text: 'YGG电子代理返点' },
  { busi_type: 197, text: '开通瓦力捕鱼' },
  { busi_type: 391, text: '钱包到瓦力捕鱼' },
  { busi_type: 392, text: '瓦力捕鱼到钱包' },
  { busi_type: 733, text: '瓦力捕鱼自身返点' },
  { busi_type: 734, text: '瓦力捕鱼代理返点' },
  { busi_type: 206, text: '开通JOKER电子' },
  { busi_type: 423, text: '钱包到JOKER电子' },
  { busi_type: 424, text: 'JOKER电子到钱包' },
  { busi_type: 739, text: 'JOKER电子自身返点' },
  { busi_type: 740, text: 'JOKER电子代理返点' },
  { busi_type: 207, text: '开通MT电子' },
  { busi_type: 377, text: '钱包到MT电子' },
  { busi_type: 378, text: 'MT电子到钱包' },
  { busi_type: 706, text: 'MT电子自身返点' },
  { busi_type: 707, text: 'MT电子代理返点' },
  { busi_type: 209, text: '开通NEXTSPIN电子' },
  { busi_type: 464, text: '钱包到NEXTSPIN电子' },
  { busi_type: 465, text: 'NEXTSPIN电子到钱包' },
  { busi_type: 756, text: 'NEXTSPIN电子自身返点' },
  { busi_type: 757, text: 'NEXTSPIN电子代理返点' },
  { busi_type: 208, text: '开通AESEXY真人' },
  { busi_type: 462, text: '钱包到AESEXY真人' },
  { busi_type: 463, text: 'AESEXY真人到钱包' },
  { busi_type: 758, text: 'AESEXY真人自身返点' },
  { busi_type: 759, text: 'AESEXY真人代理返点' },
  { busi_type: 210, text: '开通PRETTY真人' },  // 这里重复了，有空了再看怎么处理
  { busi_type: 466, text: '钱包到PRETTY真人' },
  { busi_type: 467, text: 'PRETTY真人到钱包' },
  { busi_type: 760, text: 'PRETTY真人自身返点' },
  { busi_type: 761, text: 'PRETTY真人代理返点' },
  { busi_type: 212, text: '开通AFB真人' },
  { busi_type: 468, text: '钱包到AFB真人' },
  { busi_type: 469, text: 'AFB真人到钱包' },
  { busi_type: 762, text: 'AFB真人自身返点' },
  { busi_type: 763, text: 'AFB真人代理返点' },
  { busi_type: 213, text: '开通SAGaming视讯' },
  { busi_type: 470, text: '钱包到SAGaming视讯' },
  { busi_type: 471, text: 'SAGaming视讯到钱包' },
  { busi_type: 765, text: 'SAGaming视讯自身返点' },
  { busi_type: 766, text: 'SAGaming视讯代理返点' },
  { busi_type: 214, text: '开通AFB电子' },
  { busi_type: 435, text: '钱包到AFB电子' },
  { busi_type: 436, text: 'AFB电子到钱包' },
  { busi_type: 747, text: 'AFB电子自身返点' },
  { busi_type: 748, text: 'AFB电子代理返点' },
  { busi_type: 215, text: '开通PGSOFT电子' },
  { busi_type: 472, text: '钱包到PGSOFT电子' },
  { busi_type: 473, text: 'PGSOFT电子到钱包' },
  { busi_type: 747, text: 'PGSOFT电子自身返点' },
  { busi_type: 768, text: 'PGSOFT电子代理返点' },
  { busi_type: 217, text: '开通AFB体育' },
  { busi_type: 440, text: '钱包到AFB体育' },
  { busi_type: 441, text: 'AFB体育到钱包' },
  { busi_type: 771, text: 'AFB体育自身返点' },
  { busi_type: 772, text: 'AFB体育代理返点' },
  { busi_type: 242, text: '开通EVOPLAY电子' },
  { busi_type: 442, text: '钱包到EVOPLAY电子' },
  { busi_type: 443, text: 'EVOPLAY电子到钱包' },
  { busi_type: 769, text: 'EVOPLAY电子自身返点' },
  { busi_type: 770, text: 'EVOPLAY电子代理返点' },
  { busi_type: 751, text: 'CRASH代理返点' },
  { busi_type: 752, text: 'MINE代理返点' },
  { busi_type: 753, text: 'DABBLE代理返点' },
  { busi_type: 451, text: '钱包到RSG电子' },
  { busi_type: 452, text: 'RSG电子到钱包' },
  { busi_type: 745, text: 'RSG电子自身返点' },
  { busi_type: 746, text: 'RSG电子代理返点' },
  { busi_type: 201, text: '开通RSG电子' },
  { busi_type: 838, text: '会员乐捐' },
  { busi_type: 219, text: '开通TP电子' },
  { busi_type: 444, text: '钱包到TP电子' },
  { busi_type: 445, text: 'TP电子到钱包' },
  { busi_type: 777, text: 'TP电子自身返点' },
  { busi_type: 778, text: 'TP电子代理返点' },
  { busi_type: 244, text: '开通PP真人' },
  { busi_type: 448, text: '钱包到PP真人' },
  { busi_type: 449, text: 'PP真人到钱包' },
  { busi_type: 781, text: 'PP真人自身返点' },
  { busi_type: 782, text: 'PP真人代理返点' },
  { busi_type: 243, text: '开通KA电子' },
  { busi_type: 446, text: '钱包到KA电子' },
  { busi_type: 447, text: 'KA电子到钱包' },
  { busi_type: 779, text: 'KA电子自身返点' },
  { busi_type: 780, text: 'KA电子代理返点' },
  { busi_type: 219, text: '开通NETENT电子' },
  { busi_type: 444, text: '钱包到NETENT电子' },
  { busi_type: 445, text: 'NETENT电子到钱包' },
  { busi_type: 777, text: 'NETENT电子自身返点' },
  { busi_type: 778, text: 'NETENT电子代理返点' },
  { busi_type: 246, text: '开通FC电子' },
  { busi_type: 474, text: '钱包到FC电子' },
  { busi_type: 475, text: 'FC电子到钱包' },
  { busi_type: 783, text: 'FC电子自身返点' },
  { busi_type: 784, text: 'FC电子代理返点' },
  { busi_type: 257, text: '开通SG电子' },
  { busi_type: 476, text: '钱包到SG电子' },
  { busi_type: 477, text: 'SG电子到钱包' },
  { busi_type: 787, text: 'SG电子自身返点' },
  { busi_type: 788, text: 'SG电子代理返点' },
  { busi_type: 114, text: '开通沙巴彩票' },
  { busi_type: 478, text: '钱包到沙巴彩票' },
  { busi_type: 479, text: '沙巴彩票到钱包' },
  { busi_type: 789, text: '沙巴彩票自身返点' },
  { busi_type: 790, text: '沙巴彩票代理返点' },
  { busi_type: 792, text: 'DICE代理返点' },
  { busi_type: 152, text: '中奖' },
  { busi_type: 156, text: '中奖' },
  { busi_type: 925, text: '幸运充值单自动发放' },
  { busi_type: 845, text: '注单结算' },
  { busi_type: 846, text: '注单结算' },
  { busi_type: 793, text: 'PG电子自身返点' },
  { busi_type: 794, text: 'PG电子代理返点' },
]
export default class helperAccount {
  static context = null

  // 获得余额日志列表项的标题
  static getBalanceLogTitle(item) {
    for (let i = 0; i < busiTypes.length; i++) {
      let busi = busiTypes[i]
      if (Number(busi.busi_type) === Number(item.busi_type)) {
        return busi.text
      }
    }
    return ''
  }

  // 转点下级列表，类型
  static getTransferBusiType(busiType) {
    let retStr = ''
    switch (busiType) {
      case 800:
        retStr = '打赏'
        break
      case 802:
        retStr = '转点下级'
        break
      case 804:
        retStr = '普能转账'
        break
      case 808:
        retStr = '转出'
        break
      case 809:
        retStr = '转入'
        break
      default:
    }
    return retStr
  }

  // 转点下级状态
  static getTransferState(state) {
    let retStr = ''
    switch (Number(state)) {
      case 1:
        retStr = '申请中'
        break
      case 2:
        retStr = '已审核'
        break
      case 3:
        retStr = '已审批'
        break
      case 4:
        retStr = '已转款'
        break
      case 5:
        retStr = '已取消'
        break
      case 6:
        retStr = '已拒绝'
        break
      case 7:
        retStr = '已返还'
        break
      case 8:
        retStr = '已锁定'
        break

      default:
    }
    return retStr
  }

  // 转账资金变动对应的字体颜色
  static getTransferColor(state) {
    let color = ''
    switch (state) {
      case 1:
        color = 'fc-blue'
        break
      case 2:
        color = 'fc-red'
        break
      case 3:
        color = 'fc-red'
        break
      case 5:
        color = 'fc-blue'
        break
      case 6:
        color = 'fc-blue'
        break
      case 7:
        color = 'fc-green'
        break

      default:
    }
    return color
  }

  // 余额日志的易类型
  static getBalanceLogTransTypes() {
    return [
      { trans_type: 1, text: '充值' },
      { trans_type: 2, text: '支付' },
      { trans_type: 3, text: '提现' },
      { trans_type: 4, text: '中奖' },
      { trans_type: 5, text: '回水' },
      { trans_type: 6, text: '赠送' },
      { trans_type: 7, text: '打赏' },
      { trans_type: 8, text: '返点' },
      { trans_type: 9, text: '调整' },
      { trans_type: 10, text: '打码量' },
      { trans_type: 11, text: '余额转换' }
    ]
  }
}
