export default class helperFGBY {
  static FGBYGameNames = {
    5001: '美人捕鱼',
    5002: '雷霆战警',
    5003: '捕鸟达人',
    5004: '欢乐捕鱼',
    5005: '天天捕鱼',
    5006: '捕鱼来了3D',
    5007: '魔法王者',
    5008: '猎鼠达人3D',
    5009: '美人鱼传说',
  }

  static FGBYTOID = {
    708001: 5001, // '美人捕鱼',
    708002: 5002, //       '雷霆战警',
    708003: 5003, // '捕鸟达人',
    708004: 5004, // '欢乐捕鱼',
    708005: 5005, // '天天捕鱼',
    708006: 5006, // '捕鱼来了3D',
    708007: 5007, // '魔法王者',
    708008: 5008, // '猎鼠达人3D',
    708009: 5009, // '美人鱼传说',

  }

  static getFGBYGameTypeNames() {
    return this.FGBYGameNames
  }

  static getGameId(id) {
    return this.FGBYTOID[id]
  }
}